import React, { useCallback } from 'react';
import { ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { array, func } from 'prop-types';
import { Container, Input, Row, Select } from '~/components';
import { useMe } from '~/services';

const MetadataForm = ({ persons, workflowGoals, onClientSelect, onUserTypeSelect }) => {
  const { t } = useTranslation('admin');
  const { user } = useMe();
  
  const options = {
    company: user.hierarquia?.map((item) => ( {
      value: item.id,
      label: item.cliente,
    } )),
    usertype: persons?.map((item) => ( {
      value: item.id,
      label: item.description,
    } )),
    goal: workflowGoals?.map((goal) => ( {
      value: goal.id,
      label: goal.name,
    } )),
  };

  // Se o combo possuir dados para seleção, e o usuário possuir algum grupo,
  // injeto esse grupo como possível selecionável no combo de clientes
  // permitindo assim filtrar por grupo
  if(options.company?.length > 1 && user.grupos && user.grupos.length > 0) {
    options.company.unshift({
      value: 0,
      label: user.grupos[ 0 ].grupo,
    });
  }

  const handleSelectClick = useCallback((name) => {
    const handles = {
      company: () => { if(onClientSelect) { onClientSelect(); } },
      usertype: () => { if(onUserTypeSelect) { onUserTypeSelect(); } },
    };
    return handles[ name ] && handles[ name ]();
  }, [ onClientSelect, onUserTypeSelect ]);
  
  return (
    <Container>
      <Row rowGap={'md'} columnGap={'sm'} flex={ 'between' }>
        <Input
          rows={ 6 }
          name={ 'name' }
          label={ t('workflows.create.fields.name.label') }
          appearance="secondary"
          placeholder={ t('workflows.create.fields.name.placeholder') }
        />

        <Select
          icon={ ChevronDown }
          name={ 'company' }
          label={ t('workflows.create.fields.company.label') }
          options={ options[ 'company' ] }
          appearance="secondary"
          placeholder={ t('workflows.create.fields.company.placeholder') }
          onChange={ () => handleSelectClick('company') }
          isSearchable={ false }
        />

        <Select
          icon={ ChevronDown }
          name={ 'usertype' }
          label={ t('workflows.create.fields.usertype.label') }
          isMulti
          options={ options[ 'usertype' ] }
          appearance="secondary"
          placeholder={ t('workflows.create.fields.usertype.placeholder') }
          onChange={ () => handleSelectClick('usertype') }
          isSearchable={ false }
        />
      </Row>
      
      <Row>
        <Input
          rows={ 6 }
          name={ 'description' }
          label={ t('workflows.create.fields.description.label') }
          textarea
          appearance="secondary"
          placeholder={ t('workflows.create.fields.description.placeholder') }
        />
      </Row>
    </Container>
  );
};

MetadataForm.defaultProps = {
  onClientSelect: null,
  onUserTypeSelect: null,
  workflowGoals: [],
};

MetadataForm.propTypes = {
  onClientSelect: func,
  onUserTypeSelect: func,
  // eslint-disable-next-line react/forbid-prop-types
  workflowGoals: array,
};

export default MetadataForm;

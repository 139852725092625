import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useFetch, masked, useApi } from "~/hooks";

import { useMe } from "~/services";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";
import { isEmpty } from "lodash";

import Select from "react-select";

import ReactFlow, { Controls, ReactFlowProvider } from "react-flow-renderer";

import {
  Button,
  Tooltip,
  RiskLevel,
  Table,
  Modal,
  Helmet,
  P2,
  Input,
  SimpleLoading,
} from "~/components";

import { Scope } from "@unform/core";

import { useParams } from "react-router-dom";

import {
  SideBarContainer,
  View,
  ChoosenEntity,
  ProcessContainer,
  ModalView,
  Search,
} from "./styles";
import {
  Briefcase,
  User,
  X,
  ChevronDown,
  Info,
  Plus,
  CornerUpRight,
  Search as SearchIcon,
} from "react-feather";

import nodePF from "./nodes/nodePF/index";
import nodePJ from "./nodes/nodePJ/index";
import firstNode from "./nodes/firstNode/index";

const connectionLineStyle = { stroke: "#000" };
const nodeTypes = {
  firstNode: firstNode,
  nodePF: nodePF,
  nodePJ: nodePJ,
};

const ID = () => {
  const { user } = useMe();
  const [reactflowInstance, setReactflowInstance] = useState(null);
  const [elements, setElements] = useState([]);
  const [showMenu, onShowMenu] = useState(false);
  const [data, setData] = useState([]);
  const [selectedWorkflows, onSelectedWorkflows] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [rowsDiligence, onRowsDiligence] = useState([]);
  const [loadingAssociateDiligence, setLoadingAssociateDiligence] =
    useState(false);
  const [loadingBatchDiligence, setLoadingBatchDiligence] = useState(false);
  const [showOpenDiligenceModal, setOpenShowDiligenceModal] = useState(false);
  const [loadingExpandir, setLoadingExpandir] = useState(false);
  const [loading, onLoading] = useState(false);
  const [show, onShow] = useState(false);
  const [firstRelation, setFirstRelation] = useState({});
  const [associatePartners, setAssociatePartners] = useState(false);
  const alert = useAlert();
  const api = useApi();
  const { group } = useMe();
  const ref = useRef();
  const { t } = useTranslation("dashboard");
  const { t: tSearch } = useTranslation("search");
  const { data: workflows } = useFetch({
    url: `/clients/getContent?workflowType=1,2&notBasic=1&content=workflows${
      group?.value ? `&clientId=${group.value}` : ""
    }`,
  });

  const { data: workflowsPf } = useFetch({
    url: `/clients/getContent?workflowType=1&notBasic=1&content=workflows${
      group?.value ? `&clientId=${group.value}` : ""
    }`,
  });

  const { data: workflowsPj } = useFetch({
    url: `/clients/getContent?workflowType=2&notBasic=1&content=workflows${
      group?.value ? `&clientId=${group.value}` : ""
    }`,
  });

  const { id } = useParams();
  const { data: relationship, mutate } = useFetch({
    url: `/relationships/${id}`,
  });

  const list_workflows = useMemo(() => {
    let typeCPF = false;
    let typePJ = false;

    selectedEntities.map((item) => {
      if (item.type == "PF") {
        typeCPF = true;
      }
      if (item.type == "PJ") {
        typePJ = true;
      }
    });
    if (typeCPF && typePJ) {
      let result = workflowsPf?.map((item) => ({
        value: item.id,
        label: item.name,
        type: "pf",
      }));
      workflowsPj.forEach((item) => {
        result.push({
          value: item.id,
          label: item.name,
          type: "pj",
        });
      });

      return result;
    } else if (typeCPF) {
      return workflowsPf?.map((item) => ({
        value: item.id,
        label: item.name,
        type: "pf",
      }));
    } else if (typePJ) {
      return workflowsPj?.map((item) => ({
        value: item.id,
        label: item.name,
        type: "pj",
      }));
    }
  }, [workflows, selectedEntities, workflowsPj, workflowsPf]);
  const arr_workflows = selectedWorkflows?.value
    ? [selectedWorkflows.value]
    : [];
  const { data: fields_risk } = useFetch({
    url:
      arr_workflows?.length > 0
        ? `/clients/getContent?${
            group.value === 0 ? "" : `clientId=${group.value}&`
          }${
            arr_workflows?.length > 0 ? `workflowId=[${arr_workflows}]&` : ""
          }content=campos_fonte_risco`
        : null,
  });

  /**
   * Filtered fields risks.
   */
  const filtered = useCallback(() => {
    const filter = fields_risk?.reduce((acc, curr) => {
      const { workflow_id, workflow_name, ...rest } = curr;

      acc[curr.workflow_id] = {
        workflow_id,
        workflow_name,
        items: [],
        ...acc[workflow_id],
      };
      acc[curr.workflow_id].items.push(rest);

      return acc;
    }, {});
    const items = [];

    // eslint-disable-next-line
    for (const index in filter) {
      items.push(filter[index]);
    }
    return items;
  }, [fields_risk]);

  const onNodeDragStop = (event, node) => {
    return true;
  };
  const onElementClick = useCallback(
    async (nodeId, nodeDocument, graphId, opened) => {
      async function getNodes() {
        try {
          setLoadingExpandir(true);
          await api()
            .post("/relationships", {
              document: nodeDocument,
              graph_node_id: graphId,
              root_node_id: Number(nodeId),
              client_id: group.value,
            })
            .then((res) => {
              const matrixPosition = [];
              const processData = [];
              const newNodes = res.data.graph.nodes.slice(
                relationship.graph.nodes.length,
                relationship.graph.nodes.length +
                  (res.data.graph.nodes.length -
                    relationship.graph.nodes.length)
              );
              const newEdges = res.data.graph.edges.slice(
                relationship.graph.edges.length,
                relationship.graph.edges.length +
                  (res.data.graph.edges.length -
                    relationship.graph.edges.length)
              );
              setElements((prevstate) => {
                const nodes = [];
                let firstRelation = [...res.data.graph.nodes];
                setFirstRelation(firstRelation.slice(0, 1).shift());
                res.data.graph.nodes.forEach((node) => {
                  let active = false;
                  const picked = selectedEntities.some(
                    (entity) => entity.document === node.document
                  );
                  res.data.graph.edges.forEach((edge, index) => {
                    if (edge.from_node_id === node.id) {
                      active = true;
                    }
                  });
                  nodes.push({
                    id: node.id.toString(),
                    type:
                      node.document.toString().length > 11
                        ? "nodePJ"
                        : "nodePF",
                    data: {
                      id: node.id,
                      company: node.company,
                      person: node.person,
                      label: node.company
                        ? node.company.company_name
                        : node.person.name,
                      document: node.document,
                      graphId: relationship.id,
                      type: node.document.toString().length > 11 ? "PJ" : "PF",
                      chooseEntity: chooseEntity,
                      expandPartners: onElementClick,
                      expandedActive: active,
                      picked,
                      dataOn: false,
                    },
                    position: { x: 0, y: 0 },
                    sourcePosition: "right",
                    isHidden: false,
                  });

                  if (node.process) {
                    processData.push({
                      id: node.process.id,
                      razaosocial: node.process.razaosocial,
                      document: node.document,
                      risk: node.process.risk.risk,
                    });
                  }
                });

                res.data.graph.edges.forEach((edge, index) => {
                  let alreadyExistsTest = false;

                  if (matrixPosition.length > 0) {
                    for (var i = 0; i < matrixPosition.length; i++) {
                      if (matrixPosition[i].includes(edge.to_node_id)) {
                        alreadyExistsTest = true;
                        if (alreadyExistsTest) break;
                      }
                    }

                    if (!alreadyExistsTest) {
                      matrixPosition.forEach((pos, index) => {
                        if (pos.includes(edge.from_node_id)) {
                          if (!matrixPosition[index + 1]) {
                            matrixPosition.push([edge.to_node_id]);
                          } else {
                            matrixPosition[index + 1].push(edge.to_node_id);
                          }
                        }
                      });
                    }
                  } else {
                    matrixPosition.push([edge.from_node_id]);
                    matrixPosition.push([edge.to_node_id]);
                  }

                  nodes.push({
                    id: `e${edge.from_node_id}-${edge.to_node_id}-${index}`,
                    source: edge.from_node_id.toString(),
                    target: edge.to_node_id.toString(),
                    sourceHandle: "a",
                    arrowHeadType: `arrow`,
                    label: edge.name,
                    labelStyle: { fontSize: "2rem" },
                    animated: false,
                    style: { stroke: "#000" },
                  });
                });
                if (nodes.length > 0) {
                  nodes[0].type = "firstNode";
                  nodes[0].isHidden = false;
                }
                if (processData) {
                  const row = processData.map((item) => ({
                    id: item.id,
                    items: [
                      {
                        label: (
                          <Tooltip label={<>{item.id}</>} width={260}>
                            <Info />
                          </Tooltip>
                        ),
                      },
                      {
                        label: String(item?.razaosocial).trim() || "-",
                        isBold: true,
                      },
                      {
                        label: item.document ? masked(item.document) : "-",
                      },
                      {
                        label: (
                          <RiskLevel
                            id={item.id}
                            risk={Math.round(
                              Number(item.risk?.probability?.probabilidade)
                            )}
                          />
                        ),
                      },
                      {
                        label: item.process?.count || "-",
                        isBold: true,
                      },
                      {
                        isArrow: true,
                      },
                    ],
                  }));

                  onRowsDiligence(row);
                }

                let arraySize = 0;
                matrixPosition.forEach((array) => {
                  if (array.length > arraySize) arraySize = array.length;
                });

                const nodeSize = 440;
                const center = (arraySize / 2) * nodeSize;

                for (var index = 0; index < matrixPosition.length; index++) {
                  let startPoint = center;
                  if (index > 0) {
                    startPoint =
                      center - (matrixPosition[index].length / 2) * nodeSize;
                  }

                  matrixPosition[index].forEach((nodePosition, i) => {
                    const nodeIndex = nodes.findIndex(
                      (node) => node.id == nodePosition
                    );
                    nodes[nodeIndex].position = {
                      x: startPoint + i * 480,
                      y: index * 600,
                    };
                  });
                }
                setLoadingExpandir(false);
                return nodes;
              });
            });
        } catch (err) {
          setElements((prevstate) => {
            let getNodesToShow = prevstate.filter(
              (el) => el.source == nodeId || el.target == nodeId
            );

            const newElements = prevstate;
            const listOfNodes = getNodesToShow.map((node) => {
              return node.target;
            });
            const listOfNodes2 = [...listOfNodes];
            const indexNodes = listOfNodes2.indexOf(nodeId);
            if (indexNodes > -1) {
              listOfNodes2.splice(indexNodes, 1);
            }
            listOfNodes2.forEach((value) => {
              const indexNodeToChange = newElements.findIndex(
                (el) => el.id === value
              );
              newElements[indexNodeToChange].data.expandedActive = false;
            });
            const indexNode = newElements.findIndex((el) => el.id == nodeId);
            newElements[indexNode].data.expandedActive = !opened;
            getNodesToShow.forEach((node) => {
              const index = newElements.findIndex((el) => el.id == node.target);
              if (index !== -1) {
                newElements[index].isHidden = false;
              }
              const indexNodeSource = newElements.findIndex(
                (el) => el.id == node.source
              );
              if (indexNodeSource !== -1) {
                newElements[indexNodeSource].expandedActive = false;
              }
              const filteredElements = newElements.filter(
                (el) =>
                  (el.target == node.target &&
                    listOfNodes.includes(el.target)) ||
                  (el.source == node.target && listOfNodes.includes(el.target))
              );

              filteredElements.forEach((el) => {
                el.isHidden = false;
                el.expandedActive = false;
              });
            });
            setLoadingExpandir(false);
            return [...newElements];
          });
        }
      }

      if (opened) {
        setLoadingExpandir(true);
        setElements((prevstate) => {
          let filteredNodes = prevstate.filter((el) => el.source == nodeId);
          let getNodesToHide = filteredNodes;

          for (var i = 0; i < 10; i++) {
            filteredNodes.forEach((node) => {
              const nodesToAdd = prevstate.filter(
                (el) => el.source == node.target
              );
              getNodesToHide = [...getNodesToHide, ...nodesToAdd];
            });
            filteredNodes = [...getNodesToHide];
          }

          const newElements = prevstate;
          const indexNode = newElements.findIndex((el) => el.id == nodeId);
          newElements[indexNode].data.expandedActive = false;
          if (getNodesToHide.length > 0) {
            let sourceNode = prevstate.find(
              (el) => el.id == getNodesToHide[0].source
            );
            getNodesToHide.forEach((node) => {
              const index = newElements.findIndex((el) => el.id == node.target);
              const indexNodeAtual = newElements.findIndex(
                (el) => el.id == node.source
              );
              newElements[indexNodeAtual].expandedActive = false;
              if (newElements[index].position.y > sourceNode.position.y) {
                if (index !== -1) {
                  newElements[index].isHidden = true;
                }
                const filteredElements = newElements.filter(
                  (el) => el.target == node.target || el.source == node.target
                );
                filteredElements.forEach((el) => {
                  el.isHidden = true;
                  el.expandedActive = false;
                });
              }
            });
          }
          setLoadingExpandir(false);
          return [...newElements];
        });
      } else {
        await getNodes();
        setLoadingExpandir(false);
      }
    },
    [data, relationship, loadingExpandir]
  );

  useEffect(() => {
    const nodes = [];
    const processData = [];

    if (relationship?.graph.nodes) {
      const nodeQuantity = relationship.graph.nodes.length;

      let change = [...relationship.graph.nodes];
      setFirstRelation(change.slice(0, 1).shift());

      relationship.graph.nodes.forEach((node) => {
        let active = false;
        const picked = selectedEntities.some(
          (entity) => entity.document === node.document
        );
        relationship.graph.edges.forEach((edge, index) => {
          if (edge.from_node_id === node.id) {
            active = true;
          }
        });
        nodes.push({
          id: node.id.toString(),
          type: node.document.toString().length > 11 ? "nodePJ" : "nodePF",
          data: {
            id: node.id,
            company: node.company,
            person: node.person,
            label: node.company ? node.company.company_name : node.person.name,
            document: node.document,
            graphId: relationship.id,
            type: node.document.toString().length > 11 ? "PJ" : "PF",
            chooseEntity: chooseEntity,
            expandPartners: onElementClick,
            expandedActive: active,
            picked,
            dataOn: false,
          },
          position: { x: 0, y: 0 },
          sourcePosition: "right",
          isHidden: false,
        });

        if (node.process) {
          processData.push({
            id: node.process.id,
            razaosocial: node.process.razaosocial,
            document: node.document,
            risk: node.process.risk.risk,
          });
        }
      });
    }

    const matrixPosition = [];

    if (relationship?.graph.nodes) {
      relationship.graph.edges.forEach((edge, index) => {
        let alreadyExistsTest = false;

        if (matrixPosition.length > 0) {
          for (var i = 0; i < matrixPosition.length; i++) {
            if (matrixPosition[i].includes(edge.to_node_id)) {
              alreadyExistsTest = true;
              if (alreadyExistsTest) break;
            }
          }

          if (!alreadyExistsTest) {
            matrixPosition.forEach((pos, index) => {
              if (pos.includes(edge.from_node_id)) {
                if (!matrixPosition[index + 1]) {
                  matrixPosition.push([edge.to_node_id]);
                } else {
                  matrixPosition[index + 1].push(edge.to_node_id);
                }
              }
            });
          }
        } else {
          matrixPosition.push([edge.from_node_id]);
          matrixPosition.push([edge.to_node_id]);
        }

        nodes.push({
          id: `e${edge.from_node_id}-${edge.to_node_id}-${index}`,
          source: edge.from_node_id.toString(),
          target: edge.to_node_id.toString(),
          sourceHandle: "a",
          arrowHeadType: `arrow`,
          label: edge.name,
          labelStyle: { fontSize: "2rem" },
          animated: false,
          style: { stroke: "#000" },
        });
      });
    }

    if (nodes.length > 0) {
      nodes[0].type = "firstNode";
      nodes[0].isHidden = false;
      nodes[0].expandedActive = true;
    }

    if (processData) {
      const row = processData.map((item) => ({
        id: item.id,
        items: [
          {
            label: (
              <Tooltip label={<>{item.id}</>} width={260}>
                <Info />
              </Tooltip>
            ),
          },
          {
            label: String(item?.razaosocial).trim() || "-",
            isBold: true,
          },
          {
            label: item.document ? masked(item.document) : "-",
          },
          {
            label: (
              <RiskLevel
                id={item.id}
                risk={Math.round(Number(item.risk?.probability?.probabilidade))}
              />
            ),
          },
          {
            label: item.process?.count || "-",
            isBold: true,
          },
          {
            isArrow: true,
          },
        ],
      }));

      onRowsDiligence(row);
    }

    let arraySize = 0;
    matrixPosition.forEach((array) => {
      if (array.length > arraySize) arraySize = array.length;
    });

    const nodeSize = 440;
    const center = (arraySize / 2) * nodeSize;

    for (var index = 0; index < matrixPosition.length; index++) {
      let startPoint = center;
      if (index > 0) {
        startPoint = center - (matrixPosition[index].length / 2) * nodeSize;
      }

      matrixPosition[index].forEach((nodePosition, i) => {
        const nodeIndex = nodes.findIndex((node) => node.id == nodePosition);
        nodes[nodeIndex].position = {
          x: startPoint + i * 480,
          y: index * 600,
        };
      });
    }

    setData(nodes);
    setElements(nodes);
  }, [relationship]);

  const tabled = {
    body: rowsDiligence,
    head: [
      "razaosocial",
      "document",
      "risk",
      "dataabertura",
      "workflows",
      "status",
      "tags",
    ],
    route: "/pesquisa/diligencia",
    onBody: onRowsDiligence,
  };

  useEffect(() => {
    if (reactflowInstance && elements.length > 0) {
      reactflowInstance.fitView();
    }
  }, [reactflowInstance, elements.length]);

  const onLoad = useCallback(
    (rfi) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
      }
    },
    [reactflowInstance]
  );

  useEffect(() => {
    onShow(filtered().length > 0);
  }, [filtered]);

  const chooseEntity = useCallback((data) => {
    setSelectedEntities((prevstate) => {
      const index = prevstate.findIndex((entity) => entity.name == data.name);
      if (index == -1) {
        return [...prevstate, data];
      } else {
        return [...prevstate.filter((entity) => entity.name != data.name)];
      }
    });
  }, []);

  const removeEntity = useCallback(
    (document) => {
      setSelectedEntities([
        ...selectedEntities.filter((entity) => entity.document != document),
      ]);
    },
    [selectedEntities]
  );

  useEffect(() => {
    const newData = elements;

    elements.forEach((el, elIndex) => {
      const index = selectedEntities.findIndex((entity) => el.id == entity.id);

      if (newData[elIndex].data) {
        if (index !== -1) {
          newData[elIndex].data.picked = true;
        } else {
          newData[elIndex].data.picked = false;
        }
      }
      setElements([...newData]);
    });
  }, [selectedEntities]);

  const handleSearchButtonClick = () => {
    if (selectedEntities.length > 0 && !isEmpty(selectedWorkflows)) {
      setOpenShowDiligenceModal(true);
    } else if (selectedEntities.length > 0 && isEmpty(selectedWorkflows)) {
      alert.error(t("error.workflow"));
    } else if (!selectedEntities.length > 0 && !isEmpty(selectedWorkflows)) {
      alert.error(t("error.entity"));
    } else {
      alert.error(t("error.workflow_entity"));
    }
  };

  const creteNewAssessment = useCallback(
    async (val) => {
      setLoadingExpandir(true);
      const uniqueRisks = val?.risks?.reduce((acc, curr) => {
        const repeated = acc.find((item) => item.id === curr.id);
        if (!repeated) {
          return acc.concat([curr]);
        }
        return acc;
      }, []);

      const workflowsPfFiltered =
        selectedWorkflows.type == "pf" ? selectedWorkflows : [];
      const formattedWorkflowsPf = [workflowsPfFiltered?.value];

      const workflowsPjFiltered =
        selectedWorkflows.type == "pj" ? selectedWorkflows : [];

      const formattedWorkflowsPj = [workflowsPjFiltered?.value];

      const formattedDiligences = [];

      selectedEntities.forEach((entity) => {
        formattedDiligences.push({
          document: masked(entity.document),
          workflows:
            entity.document.length > 11
              ? formattedWorkflowsPj
              : formattedWorkflowsPf,
          risks: uniqueRisks,
          userId: String(user.usuario.id),
          clientId: group.value === 0 ? null : String(group.value),
          personType: entity.document.length > 11 ? 2 : 1,
          relationship_graph_id: relationship.id,
          relationship_graph_node_id: entity.id,
          associate: associatePartners ? firstRelation.process_id : null,
          forceOpening: true,
        });
      });

      if (formattedDiligences.length > 0) {
        await api()
          .post("/process/store", { diligences: formattedDiligences })
          .then(async (res) => {
            if (res.data.type === "fail") {
              alert.error(res.data.data);
            } else {
              const updatedRow = rowsDiligence;
              res.data.forEach((diligence) => {
                if (masked(firstRelation.document) == diligence.document) {
                  let firstRelationChanged = { ...firstRelation };
                  firstRelationChanged.process_id = diligence.process_id;
                  setFirstRelation(firstRelationChanged);
                }
                updatedRow.push({
                  id: diligence.process_id,
                  items: [
                    {
                      label: (
                        <Tooltip
                          label={<>{diligence.process_id}</>}
                          width={260}
                        >
                          <Info />
                        </Tooltip>
                      ),
                    },
                    {
                      label: "-",
                      isBold: true,
                    },
                    {
                      label: diligence.document
                        ? masked(diligence.document)
                        : "-",
                    },
                    {
                      label: (
                        <RiskLevel
                          id={diligence.process_id}
                          risk={Math.round(Number(0))}
                        />
                      ),
                    },
                    {
                      label: "-",
                      isBold: true,
                    },
                    {
                      isArrow: true,
                    },
                  ],
                });
              });

              onRowsDiligence([...updatedRow]);
              alert.success(tSearch("evaluation.alerts.success"));
            }
          })
          .catch(({ response }) => {
            setOpenShowDiligenceModal(false);
            setLoadingBatchDiligence(false);
            setAssociatePartners(false);
            setLoadingExpandir(false);
            alert.error(
              response.data.message ?? tSearch("evaluation.alerts.error")
            );
          });
      }
      setOpenShowDiligenceModal(false);
      setLoadingBatchDiligence(false);
      setAssociatePartners(false);
      setLoadingExpandir(false);
    },
    [selectedEntities, selectedWorkflows, firstRelation, associatePartners]
  );

  return (
    <>
      {!loadingExpandir ? (
        <>
          <View>
            <Tooltip
              width={500}
              direction={{ side: "left", offset: "-52" }}
              label={tSearch("cadastral.tooltipLabel")}
              className={"menu"}
            >
              <button
                type="button"
                onClick={() => onShowMenu(!showMenu)}
                className="toggle-men menu"
              >
                {showMenu ? <X size="3.0rem" /> : <SearchIcon size="3.0rem" />}
              </button>
            </Tooltip>
            <Helmet title={tSearch("helmet.cadastral_response")} />
            <ReactFlowProvider>
              <ReactFlow
                elements={elements}
                onNodeDragStop={onNodeDragStop}
                style={{ background: "#f5f5f5" }}
                onLoad={onLoad}
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                snapToGrid={false}
                snapGrid={[380, 380]}
                defaultZoom={0.7}
                minZoom={0.1}
                maxZoom={1}
              >
                <Controls />
              </ReactFlow>
              {showMenu && (
                <SideBarContainer>
                  <div>
                    <h2>{tSearch("cadastral.workflows")}</h2>

                    {list_workflows && (
                      <Select
                        name="workflows"
                        icon={ChevronDown}
                        options={list_workflows}
                        onChange={(val) => onSelectedWorkflows(val)}
                        placeholder={t("head.workflows.placeholder")}
                      />
                    )}

                    <h2>{tSearch("cadastral.entities")}</h2>
                    {selectedEntities.map((entity) => (
                      <ChoosenEntity key={entity.document}>
                        <div>
                          {entity.document.toString().length > 11 ? (
                            <Briefcase color="#414251" size={24} />
                          ) : (
                            <User color="#414251" size={24} />
                          )}
                          <h3>{entity.name}</h3>
                        </div>
                        <button onClick={() => removeEntity(entity.document)}>
                          <X color="#414251" size={24} />
                        </button>
                      </ChoosenEntity>
                    ))}
                  </div>
                  <Button
                    onClick={handleSearchButtonClick}
                    label={tSearch("cadastral.search")}
                  >
                    {" "}
                  </Button>
                </SideBarContainer>
              )}
              <Modal
                title={t("new_assessment")}
                show={showOpenDiligenceModal}
                onShow={setOpenShowDiligenceModal}
                action={{
                  label: t("send_no_associate"),
                  loading: loadingBatchDiligence,
                  icon: <Plus />,
                  onClick: async () => {
                    await setAssociatePartners(false);
                    ref.current.submitForm();
                  },
                }}
                secondaryAction={
                  firstRelation.process_id
                    ? {
                        label: t("send"),
                        loading: loadingAssociateDiligence,
                        icon: <CornerUpRight />,
                        onClick: async () => {
                          await setAssociatePartners(true);
                          ref.current.submitForm();
                        },
                      }
                    : ""
                }
                showCancel={false}
                loading={loading}
              >
                <ModalView className="modal-md">
                  <Search ref={ref} onSubmit={(val) => creteNewAssessment(val)}>
                    {show && (
                      <div>
                        {filtered()?.map((item, index) => (
                          <>
                            <P2
                              dangerouslySetInnerHTML={{
                                __html: `${tSearch(
                                  "components.extra_field"
                                )} <b>${item.workflow_name}</b>`,
                              }}
                            />

                            <Input
                              name={`workflows[${index}]`}
                              value={item.workflow_id}
                              reveal={false}
                            />
                            <div
                              style={{
                                display: "flex row",
                                justifyContent: "spaceBetween",
                              }}
                            >
                              {item.items?.map((child, idx) => (
                                <Scope key={child.id} path={`risks[${idx}]`}>
                                  <>
                                    <Input
                                      name="id"
                                      value={child.id}
                                      reveal={false}
                                    />

                                    <Input
                                      name="value"
                                      placeholder={`${child.name_pt} ${
                                        child?.fonte_risco_name?.split(
                                          ":"
                                        )[1] ?? ""
                                      }${child.mandatory ? " *" : ""}`}
                                      style={{ margin: "10px 10px 0 0" }}
                                    />

                                    <Input
                                      name="mandatory"
                                      value={child.mandatory}
                                      reveal={false}
                                    />
                                    <Input
                                      name="risk_source_id"
                                      value={child.risk_source_id}
                                      reveal={false}
                                    />
                                  </>
                                </Scope>
                              ))}
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </Search>
                </ModalView>
              </Modal>
            </ReactFlowProvider>
          </View>
          <ProcessContainer>
            <h2>{tSearch("cadastral.assessments")}</h2>

            <Table
              body={tabled.body}
              route={tabled.route}
              onBody={tabled.onBody}
              heading={tabled.head}
              breakpoint="md"
            />
          </ProcessContainer>
        </>
      ) : (
        <div className="simple-loading" style={{ paddingTop: "300px" }}>
          <SimpleLoading />
        </div>
      )}
    </>
  );
};

export default ID;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { Accordion, H1 } from '~/components';
import { buildAddress, formatDocument, parseISO } from '~/hooks/helpers';

import { useResultData } from '../../resultContext';
import { SectionView as View } from '../../styles';
import CompanyRegistrationData from './CompanyRegistrationData';
import PartnersData from './PartnersData';
import PersonRegistrationData from './PersonRegistrationData';
import { SelectedPartnersProvider } from "./selectedPartnersContext";

const DiligenceRegistrationData = () => {
  const { t } = useTranslation('diligence');
  const { entityNames, headers, corporateData } = useResultData();

  const partnersCount = Object.keys(corporateData?.data ?? {})
    .map((key) => corporateData.data[key].length)
    .reduce((acc, curr) => acc + curr, 0);

  /**
   * Colunas que estão no "header" do accordion de dados cadastrais
   */
  const registrationDataAccordionColumns = [
    {
      payload: entityNames[0],
      type: 'TEXT',
    },
    {
      payload: formatDocument(headers?.data?.cnpj, headers?.data?.person_type),
      type: 'TEXT',
    },
    {
      payload: headers?.entity?.birthday && headers?.entity.birthday !== '0000-00-00'
        ? format(parseISO(headers?.entity?.birthday), 'dd/MM/yyyy')
        : '-',
      type: 'TEXT',
    },
    {
      payload: buildAddress(headers?.entity?.addresses[0]),
      type: 'TEXT',
    },
  ];

  /**
   * Colunas que estão no "header" do accordion de dados societários
   */
  const partnersDataAccordionColumns = [
    {
      payload: t('registration_data.partners.title') /* @TRADUÇÃO */,
      type: 'TEXT',
    },
    {
      payload: t('registration_data.partners.count', { partnersCount }) /* @TRADUÇÃO */,
      type: 'TEXT',
    },
  ];

  const registrationDataComponents = {
    1: PersonRegistrationData,
    2: CompanyRegistrationData,
    3: PersonRegistrationData,
    4: CompanyRegistrationData,
  };

  const RegistrationDataComponent = registrationDataComponents[headers?.data?.person_type] ?? null;

  return (
    <View>
      <H1>{t('registration_data.title') /* @TRADUÇÃO */}</H1>

      <Accordion columns={registrationDataAccordionColumns}>
        {
          RegistrationDataComponent
            && <RegistrationDataComponent personType={headers?.data?.person_type} />
        }
      </Accordion>

      <Accordion columns={partnersDataAccordionColumns}>
        <SelectedPartnersProvider>
          <PartnersData />
        </SelectedPartnersProvider>
      </Accordion>
    </View>
  );
};

export default DiligenceRegistrationData;

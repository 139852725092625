import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Input } from "~/components";

import { Flex, SubTitle, Title } from "../../styles";
import { Wrapper } from "./styles";

export const PrepaidAssessment = ({ check }) => {
  const { t } = useTranslation("search");

  const [checked, setChecked] = useState(false);

  return (
    <Flex dir="column" gap="2rem">
      <Wrapper>
        <Checkbox
          size="sm"
          label={t("evaluation.advanced_options.pre_paid.title")}
          onSelected={(e) => {
            setChecked(e.target.checked);
          }}
        />
        <SubTitle ml="2.35rem">
          {t("evaluation.advanced_options.pre_paid.sub_title")}
        </SubTitle>
      </Wrapper>
      <Flex w="100%" gap="2rem" dir="column">
        {checked && (
          <Input
            name="prepaid_receiver"
            onChange={(e) => {
              check(!e.target.value);
            }}
            placeholder={t("evaluation.prepaid.receiver-placeholder")}
          />
        )}
      </Flex>
    </Flex>
  );
};

import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import {
  Accordion, Button, Input, Modal, SimpleLoading, TableRow, Upload,
} from '~/components';
import { useApi, formatErrorMessage, useFetch } from '~/hooks';
import { error, useMe } from '~/services';

import { useResultData } from '../../resultContext';
import { ThemeAnalysis as ObservationView, Anex, ModalView } from '../../styles';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {colors} from "~/styles";

const Observation = () => {
  const { id: processID } = useParams();
  const observationRef = useRef();
  const observationAnex = useRef();
  const alert = useAlert();
  const api = useApi();
  
  const [showAnexModal, setShowAnexModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);
  const [value, setValue] = useState('');
  const [valueCleaned, setValueCleaned] = useState('');
  
  const { t, i18n } = useTranslation('diligence');
  const { t: tAuth } = useTranslation('auth');
  const { addMutation } = useResultData();
  const { status } = colors;
  const { user } = useMe();

  const [isOpenable, setIsOpenable] = useState(true);

  const {
    data: loadedObservation,
    mutate,
    isValidating: isValidatingLoadedObservation,
  } = useFetch({ url });

  const {
    data: attachmentData,
    mutate: mutateAttachment,
    isValidating: isValidatingAttachment
  } = useFetch({ url: url !== null ? `/process/note/${processID}/attachment` : null });

  const configCkEditor = {
    language: i18n.language === 'pt' ? 'pt-br' : i18n.language,
    toolbar: {
      items: [
        'undo', 'redo', '|', 'heading', '|', 'aligment', '|', 'bold', 
        'italic', 'numberedList', 'outdent', 'indent', 'removeFormat',
      ],
      height:'500',
      shouldNotGroupWhenFull: true
    },
  };

  useEffect(() => {
    addMutation(mutate);
    addMutation(mutateAttachment);
  }, [addMutation, mutate, mutateAttachment]);

  useEffect(() => {
    if (!!loadedObservation?.message) {
      setIsOpenable(false)
    }
    if (!isValidatingLoadedObservation && loadedObservation?.data?.length > 0) {
      setTimeout(() => {
        setValue(loadedObservation?.data[0].informacoes);
      }, 400);
    }
  }, [loadedObservation, isValidatingLoadedObservation]);
  
  const handleSubmit = useCallback(async() => {
    if(valueCleaned.length > 2000) {
      alert.error(t('seem.characteres_over_limit'));
      setLoading(false);
    } else {
      try {
        await api().post(`/process/${processID}/notes`, { note: value });
        alert.success(t('theme_analysis_note.alerts.success'));
      } catch ({ response }) {
        alert.error(
          formatErrorMessage(response, t('theme_analysis_note.alerts.error'),tAuth('expired')),
        );
      } finally {
        setLoading(false);
      }
    }
  }, [t, processID, alert, value, tAuth]);

  const handleAnexSubmit = useCallback(async ({ upload }) => {
    if (! upload) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('attachment', upload);
    try {
      await api().post(`/process/note/${processID}/attachment`, formData);
      setShowAnexModal(false);
      mutateAttachment();
    } catch ({ response }) {
      alert.error(formatErrorMessage(response, t('detail.messages.upload.error'),tAuth('expired')));
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, processID, t, tAuth]);

  const onOpen = useCallback((isOpened) => {
    if (isOpened) {
      if (! url) {
        setURL(`/process/${processID}/notes`);
      } else {
        
        mutate();
        mutateAttachment();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const isLoading = isValidatingLoadedObservation || isValidatingAttachment;

  return (
    <>
      <Accordion
        isOpenable={isOpenable}
        onChange={onOpen}
        isInfo={t('seem.tooltip')}
        columns={[{ type: 'TEXT', payload: t('seem.sub_title') }]}
      >
        {isLoading ? <SimpleLoading /> : (
          <>
            <ObservationView ref={observationRef} onSubmit={handleSubmit}>
              <div style={{fontSize: "14px"}}>
                <CKEditor id="teste-ck"
                  name="note"
                  editor={ClassicEditor}
                  data={value}
                  config={configCkEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    let dataEdited = data.replace(/<[^>]*>/g, '');
                    dataEdited = dataEdited.replace('&nbsp;', ' ');
                    setValueCleaned(dataEdited);
                    setValue(data);
                  }}
                />
                {(2000 - valueCleaned.length) > 0 && (
                  <label style={{
                    color: '#7F859D',
                    fontSize: '1.2rem'
                  }}>{t('seem.missing_characters')} {2000 - valueCleaned.length}</label>
                )}
                {(2000 - valueCleaned.length) <= 0 && (
                  <label style={{color: status.error, fontSize: '1.2rem'}}>{t('seem.missing_characters')} {0}</label>
                )}
              </div>
              <Anex>
                <Button
                  label="Anexar"
                  onClick={() => {
                    setShowAnexModal(true);
                  }}
                  disabled={user?.isVisualizador()}
                />
                <Button
                  submit
                  label={t('seem.submit')}
                  loading={loading}
                  disabled={user?.isVisualizador()}
                />
              </Anex>
            </ObservationView>
            {attachmentData?.length > 0 && (
              <table>
                <thead>
                  <TableRow
                    style={{
                      backgroundColor: '#fff',
                    }}
                    columns={[
                      { payload: t('observation.name'), type: 'HEAD' },
                      { payload: t('observation.date'), type: 'HEAD' },
                      { payload: t('observation.user'), type: 'HEAD' },
                      { payload: '', type: 'HEAD' },
                    ]}
                  />
                </thead>
                <tbody>
                  {
                    attachmentData?.map((r) => (
                      <TableRow
                        key={r.id}
                        columns={[
                          { payload: r.attachment_name, type: 'TEXT' },
                          { payload: r.created_at, type: 'TEXT' },
                          { payload: r.user_name, type: 'TEXT' },
                          {
                            payload: r.attachment_name ? {
                              types: ['FILE', 'DELETE'],
                              deleteAction: {
                                onClick: () => {
                                  api().delete(`/process/note/attachment/${r.id}`)
                                    .then(() => {
                                      mutateAttachment();
                                    });
                                },
                              },
                              fileAction: { onClick: () => { window.open(r.attachment_url, '_blank'); } },
                            } : 1,
                            type: r.attachment_name ? 'BUTTONS' : 'HEAD_BUTTONS',
                          },
                        ]}
                      />
                    ))
                  }
                </tbody>
              </table>
            )}
          </>
        )}
      </Accordion>
      {showAnexModal && (
        <Modal
          title="Anexar arquivo"
          show
          onShow={setShowAnexModal}
          action={{
            label: t('include_item.modal.action'),
            loading,
            onClick: () => {
              if (observationAnex.current) {
                observationAnex.current.submitForm();
              }
            },
          }}
          loading={loading}
        >
          <ModalView className="modal-sm">
            <Form ref={observationAnex} onSubmit={handleAnexSubmit}>
              <Upload
                name="upload"
                label={t('include_item.modal.upload')}
                isDocument
              />
            </Form>
          </ModalView>
        </Modal>
      )}
    </>
  );
};
export default Observation;

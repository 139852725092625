import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { arrayOf, number, string } from 'prop-types';

import { typograph, colors } from '~/styles';

import View from './styles';

const { gray } = colors;
const { family, size } = typograph;

const ColumnChart = ({ data, categories }) => {
  const { t } = useTranslation('components');

  const series = () => [
    {
      name: t('charts.total'),
      data,
    },
  ];

  const options = () => ({
    colors: ['#0076DE'],
    foreColor: '#B6BACD',
    chart: {
      toolbar: {
        show: false,
      },
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    xaxis: {
      style: {
        colors: '#B6BACD',
      },
      categories,
      labels: {
        style: {
          colors: '#B6BACD',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#B6BACD',
          fontSize: size.s2,
          fontFamily: family,
        },
      },
    },
    grid: {
      borderColor: gray._100,
    },
    legend: {
      show: false,
    },
  });

  return (
    <View>
      <ReactApexChart
        options={options()}
        series={series()}
        type="bar"
        height={340}
      />
    </View>
  );
};

ColumnChart.propTypes = {
  data: arrayOf(number),
  categories: arrayOf(string),
};

ColumnChart.defaultProps = {
  data: [],
  categories: [],
};

export default ColumnChart;

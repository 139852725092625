import React from 'react';
import { transitions, positions } from 'react-alert';
import {
  X,
  Info,
  XCircle,
  CheckCircle,
  AlertTriangle,
} from 'react-feather';
import { useTranslation } from 'react-i18next';

import {
  func,
  shape,
  number,
  string,
} from 'prop-types';

import { P1, P3 } from '~/components';

import View from './styles';

export const options = {
  offset: '8.0rem 1.6rem',
  timeout: 4000,
  position: positions.TOP_RIGHT,
  transition: transitions.FADE,
};

export const Alert = ({
  style,
  close,
  message,
  options: settings,
}) => {
  const { t } = useTranslation('components');

  const head = {
    info: {
      icon: <Info />,
      title: t('alert.info'),
    },
    error: {
      icon: <XCircle />,
      title: t('alert.error'),
    },
    success: {
      icon: <CheckCircle />,
      title: t('alert.success'),
    },
    warning: {
      icon: <AlertTriangle />,
      title: t('alert.warning'),
    },
  };

  return (
    <View
      type={settings.type}
      style={style}
    >
      <div className={`alert-${settings.type}`}>
        {head[settings.type].icon}

        <P1>{head[settings.type].title}</P1>
      </div>

      <P3>{message}</P3>

      <X
        size="1.6rem"
        onClick={close}
      />
    </View>
  );
};

Alert.propTypes = {
  close: func.isRequired,
  message: string.isRequired,
  style: shape({
    margin: string,
    pointerEvents: string,
  }),
  options: shape({
    type: string,
    timeout: number,
    position: string,
  }),
};

Alert.defaultProps = {
  style: {},
  options: {},
};

import styled, { css } from 'styled-components';
import { oneOf } from 'prop-types';

const flexs = {
  around: 'around',
  center: 'center',
  between: 'between',
};

const sizes = {
  sm: '1rem',
  md: '2rem',
  lg: '3rem',
  xg: '4rem'
}

const aligns = {
  top: 'top',
  center: 'center',
  start: 'start',
  bottom: 'bottom'
};

const getWidthGrid = (value) => {
  if (! value) return;

  const width = (value / 12) * 100;

  return `width: ${width}%;`; // eslint-disable-line
};

export const Container = styled.div`
  width: 100%;
  padding: 0 0.8rem;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (min-width: 1500px) {
    max-width: 1350px;
  }

  @media screen and (min-width: 1536px) {
    max-width: 1440px;
  }
  @media screen and (max-width: 1535px) {
    max-width: 1248px;
  }
  @media screen and (max-width: 1366px) {
    max-width: 1140px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 960px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 720px;
  }
  @media screen and (max-width: 768px) {
    max-width: 540px;
    overflow: hidden;
  }
`;

export const Row = styled.div`
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  display: flex;

  /* Justify Content */
  ${({ flex }) => flex === flexs.around
    && css`
      justify-content: space-around;
    `}

  ${({ flex }) => flex === flexs.center
    && css`
      justify-content: center;
    `}

  ${({ flex }) => flex === flexs.between
    && css`
      justify-content: space-between;
    `}

  /* Aligns */
  ${({ align }) => align === aligns.center
    && css`
      align-items: center;
    `}

  ${({ align }) => align === aligns.top
    && css`
      align-items: top;
    `}

  ${({ align }) => align === aligns.start
    && css`
      align-items: flex-start;
    `}
  
  ${({ align }) => align === aligns.bottom
    && css`
      align-items: flex-end;
    `}
  
  ${({ rowGap }) => rowGap && css`
    row-gap: ${sizes[rowGap]};
  `}
  
  ${({ columnGap }) => columnGap && css`
    column-gap: ${sizes[columnGap]};
  `}
`;

export const Column = styled.div`
  float: left;
  padding: 0.4rem;
  min-height: 0.1rem;
  box-sizing: border-box;

  ${({ align }) => align === aligns.top
    && css`
      align-items: flex-start;
    `}

    /* Justify Content */
  ${({ flex }) => flex === flexs.around
    && css`
      justify-content: space-around;
    `}

  ${({ flex }) => flex === flexs.center
    && css`
      justify-content: center;
    `}

  ${({ flex }) => flex === flexs.between
    && css`
      justify-content: space-between;
    `}

    /* Aligns */
  ${({ align }) => align === aligns.center
    && css`
      align-items: center;
    `}

  ${({ align }) => align === aligns.top
    && css`
      align-items: top;
    `}

  ${({ align }) => align === aligns.start
    && css`
      align-items: flex-start;
    `}

  ${({ rowGap }) => rowGap && css`
    row-gap: ${sizes[rowGap]};
  `}

  ${({ columnGap }) => columnGap && css`
    column-gap: ${sizes[columnGap]};
  `}

  ${({ rowGap }) => rowGap && css`
    row-gap: ${sizes[rowGap]};
  `}

  ${({ columnGap }) => columnGap && css`
    column-gap: ${sizes[columnGap]};
  `}

  @media only screen and (max-width: 768px) {
    ${({ sm }) => sm && getWidthGrid(sm)}
  }

  @media only screen and (min-width: 768px) {
    ${({ md }) => md && getWidthGrid(md)}
  }

  @media only screen and (min-width: 992px) {
    ${({ lg }) => lg && getWidthGrid(lg)}
  }
`;

Row.propTypes = {
  flex: oneOf([ 'around', 'center', 'between' ]),
  align: oneOf([ 'top', 'center', 'start', 'bottom' ]),
  rowGap: oneOf([ 'sm', 'md', 'lg', 'xg' ]),
  columnGap: oneOf([ 'sm', 'md', 'lg', 'xg' ]),
};

Column.propTypes = {
  flex: oneOf([ 'around', 'center', 'between' ]),
  align: oneOf([ 'top', 'center', 'start', 'bottom' ]),
  rowGap: oneOf([ 'sm', 'md', 'lg', 'xg' ]),
  columnGap: oneOf([ 'sm', 'md', 'lg', 'xg' ]),
};

import React, { useEffect, useMemo, useState } from 'react';
import { Scope } from '@unform/core';
import { array, object } from 'prop-types';
import { Column, Container, Empty, P2, Radio, Row } from '~/components';
import { useSelectedRiskSource } from '~/components/RiskSourceSelector/selectedRiskSourcesContext';
import { useTranslation } from 'react-i18next';

const WeightsForm = ({ availableRiskSources, initialValues, form }) => {
  const [ weights, setWeights ] = useState({});
  const { t } = useTranslation('admin');

  const { selectedRiskSources } = useSelectedRiskSource();

  const allAvailableThemes = useMemo(() => availableRiskSources?.reduce((acc, curr) => {
    acc[ curr.theme_name ] = acc[ curr.theme_name ] ?? new Set();
    acc[ curr.theme_name ].add(curr.theme_id);
    return acc;
  }, {}) || {}, [ availableRiskSources ]);

  const allThemesInUse = useMemo(() => Object.keys(allAvailableThemes).filter((themeName) => {
    for(const element of selectedRiskSources) {
      if(allAvailableThemes[ themeName ].has(element.theme_id)) {
        return true;
      }
    }
    return false;
  }).sort((a, b) => a.localeCompare(b)), [ allAvailableThemes, selectedRiskSources ]);

  useEffect(() => {
    if (initialValues) {
      setWeights(initialValues);
    }
  }, [ initialValues ]);

  useEffect(() => {
    if (form) {
      Object.keys(weights).forEach((theme) => {
        form.getFormRef().current.setFieldValue(`themes.${ theme }`, String(weights[theme]));
      });
    }
  }, [ weights ]);

  useEffect(() => {
    if (allThemesInUse.length > 0) {
      const newWeights = {};
      allThemesInUse.forEach((theme) => { newWeights[theme] = weights[theme] || 1; });
      setWeights(newWeights);
    }
  }, [ allThemesInUse ]);

  const availableWeights = [ 1, 2, 3, 4, 5 ].map((weight) => ( {
    id: weight,
    value: weight,
    label: weight,
  } ));

  const handleOnChange = (evt, theme) => {
    const newWeights = { ...weights };
    newWeights[ theme ] = parseInt(evt.target.value, 10);
    setWeights(newWeights);
  };

  const weightLabel = (themeName) => (
    ((( weights[ themeName ] ?? 0 ) / Object.values(weights).reduce((acc, curr) => acc + curr, 0) ) * 100 ).toFixed(0)
  );
  
  return (
    <Container>
      { allThemesInUse.length < 1 ?
        <Row flex={'center'}>
          <Empty message={t("workflows.create.empty.risk_theme")} />
        </Row>
        
        : allThemesInUse.map((themeName) => (
          <Row align={'bottom'} columnGap={'lg'} key={ themeName }>
            <Column  align={'bottom'} style={{minWidth: '20rem'}}>
              <P2>{ themeName }</P2>
            </Column>

            <Column align={'bottom'} style={{width: '12.5rem'}}>
              <P2>
                <strong>{ weightLabel(themeName) }%</strong> de 100%
              </P2>
            </Column>

            <Column style={{minWidth: '12.5rem'}}>
              <Row columnGap={'md'}>
                <Scope path="themes">
                  <Radio
                    name={ themeName }
                    options={ availableWeights }
                    onChange={ (evt) => handleOnChange(evt, themeName) }
                    initialValueSelected={ initialValues[ themeName ] || 1 }
                    containerDir="row"
                  />
                </Scope>
              </Row>
            </Column>
          </Row>
        ),
      ) }
    </Container>
  );
};

WeightsForm.defaultProps = {
  initialValues: {},
  form: null,
};

WeightsForm.propTypes = {
  availableRiskSources: array.isRequired,
  initialValues: object,
  form: object,
};

export default WeightsForm;

import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

import { Layout, Container } from '~/components';
import { P1, P2, P3 } from '~/components/Title';
import { breakpoints, colors, typograph } from '~/styles';

const { gray, support, secondary, primary } = colors;
const { weight, size } = typograph;

export const View = styled(Layout)`

  section {
    margin: 2rem auto;
  }

  .top {
    position: relative;
    top: 5rem;
  }
`;

export const Wrapper = styled(Container)``;

export const ActionsHeader = styled(Form)`
  top: 6.8rem;
  display: flex;
  z-index: 90;
  position: sticky;
  padding: 2rem;
  box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193, 193, 193, 0.29);
  align-items: center;
  border-radius: 0.4rem;
  background-color: ${gray._000};

  & > div {
    min-width: 25rem;

    &:not(:first-of-type) {
      margin-left: 1.6rem;
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    & > div {
      min-width: 20rem;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;

    & > div {
      min-width: 100%;
      margin: 0 !important;
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    position: static;
    grid-template-columns: 1fr;
  }
`;

export const Grid = styled.section`
  > div {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 2rem;
    min-height: 25rem;
    border-radius: 0.4rem;

    > * {
      overflow: hidden;
      padding: 0 2rem;
      background: ${gray._000};
      box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193, 193, 193, 0.29);
      border-radius: 0.4rem;
      display: flex;
      flex-direction: column;

      ${P3} {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: ${gray._600};
        font-weight: ${weight.semiBold};
        border-bottom: 0.2rem solid ${gray._200};
        min-height: 5rem;
        height: 5rem;
      }
    }

    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
    (num) => css`
        .col${num} {
          grid-column: span ${num};

          @media screen and (max-width: ${breakpoints.md}) {
            grid-column: span 10;
          }
        }

        .row${num} {
          grid-row: span ${num};
        }
      `,
  )}
  }

  > ${P1} {
    color: ${primary._000};
    font-weight: ${weight.semiBold};
    border-bottom: 0.2rem solid ${gray._200};
    padding: 0.8rem 0;
    margin: 3.2rem 0 2.4rem;
  }
`;

export const TotalCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 25rem;

  > * {
    margin-bottom: 1.6rem;
  }

  span {
    background: ${gray._100};
    border-radius: 50%;
    width: 5rem;
    min-height: 5rem;
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    background: ${gray._100};
    border-radius: 50%;
    width: 5rem;
    min-height: 5rem;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.7);
      color: ${primary._000};

      :first-of-type {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }

  h1 {
    font-size: ${size.l3};
  }

  ${P2} {
    color: ${gray._400};
    font-weight: ${weight.semiBold};
  }
`;

export const SectionProgressCircles = styled.div`
  > div {
    padding: 1.5rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      > *:first-of-type {
        grid-row: 1 / 2;
      }

      > *:last-of-type {
        grid-row: 1 / 2;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    > div {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const RiskPanelCharts = styled.div`
  padding: 2.5rem 2rem;
  width: 100%;
  row-gap: 2rem;
  column-gap: 6.8rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));

  @media screen and (max-width: ${breakpoints.md}) {
    column-gap: 4.8rem;
    padding: 2.5rem 1rem;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 2.5rem 0rem;
  }
`;

import styled from 'styled-components';

import { colors, easing, typograph } from '~/styles';

const {
  gray,
  primary
} = colors;

const {
  basic,
} = easing;

const {
  size,
} = typograph;

export const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > label {
    color: ${gray._500};
    font-size: ${size.s1};
    line-height: 2.4rem;
  }
`;
export const DivTrash = styled.div`
  display:flex;
  align-items: center;
  & > label {
    color: ${gray._500};
    font-size: ${size.s1};
    line-height: 2.4rem;
  }
`;
export const Empty = styled.div`
  color: ${gray._400};
  display: flex;
  transition: all .3s ${basic};
  align-items: center;

  svg {
    stroke: ${gray._400};
    transition: all .3s ${basic};
    margin-right: 1.6rem;
  }
`;

export const Trash = styled.div`
  margin-left:1rem;
  width: 30px;
  height: 30px;
  margin-top: 0.8rem;
  svg {
    padding: 0.4rem;
    cursor:pointer;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  border: .2rem dashed ${gray._200};
  height: 6.8rem;
  display: flex;
  position: relative;
  margin-top: .4rem;
  transition: all .3s ${basic};
  align-items: center;
  border-radius: .4rem;
  justify-content: center;
  background-color: ${gray._100};

  &:hover {
    border-color: ${primary._000};
  }

  img {
    width: 4.8rem;
    height: 4.8rem;
    object-fit: cover;
    border-radius: 50%;
  }

  input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
  }
`;

export const Document = styled.span`
  color: ${gray._500};
  display: flex;
  font-size: ${size.s2};
  align-items: center;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    stroke: ${gray._400};
    margin-right: 1.2rem;
  }
`;

export const Hint = styled.p`
  padding: 1rem 0;
  font-weight: 600;
  color: ${gray._500};
`

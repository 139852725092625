import styled from 'styled-components';

import { P2, P3 } from '~/components/Title';
import { breakpoints, colors, typograph } from '~/styles';

const {
  gray,
} = colors;

const {
  size,
} = typograph;

export const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;

    &:nth-of-type(3) > div {
      display: flex;
      align-items: center;
      margin-left: 1.6rem;

      ${P2} {
        color: ${gray._500};
        margin-right: 1.2rem;
      }

      &:first-of-type > div {
        width: 3.2rem;
      }

      &:nth-of-type(2) > div {
        width: 7.4rem;

        .is-small.__control,
        .is-small.__value-container,
        .is-small.__control--is-focused,
        .is-small.__control--menu-is-open {
          height: 3.2rem;
          min-height: 3.2rem;
        }

        .is-small.__single-value {
          font-size: ${size.s2};
          line-height: ${size.s2};
        }

        .is-small.__value-container {
          top: -.2rem;
          padding: 0 0 0 .8rem;
          position: relative;
          background-color: transparent;
        }

        .is-small.__indicators {
          top: -.2rem;
          padding: 0 .4rem 0 .8rem;
          position: relative;
          transform: rotate(180deg);
          background-color: transparent;

          svg {
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
  }

  ${P3} {
    color: ${gray._500};
  }


  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;

    > div:last-of-type {
      margin-top: 1.6rem;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  & > button {
    margin-left: 1.6rem;
  }
`;

import React from 'react';
import { List } from 'react-feather';

import {
  oneOf,
  string,
} from 'prop-types';

import { colors } from '~/styles';

import View from './styles';

const AlterValues = ({
  size,
  status,
}) => (
  <View
    size={size}
    status={status}
    style={{ backgroundColor: '#fff' }}
  >
    {
      status ? <List color={colors.status.error} /> : <List />
    }

  </View>
);

AlterValues.propTypes = {
  size: oneOf(['sm', 'md']),
  status: string.isRequired,
};

AlterValues.defaultProps = {
  size: 'md',
};

export default AlterValues;

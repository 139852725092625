import Keycloak from 'keycloak-js';

const keycloakCredentials = JSON.parse(process.env.REACT_APP_PORTAL_URL);

export const keycloak = Keycloak({
  url: keycloakCredentials['auth-server-url'],
  realm: keycloakCredentials.realm,
  clientId: keycloakCredentials.resource,
});

export const keycloakConfig = {
  onLoad: 'check-sso', // login-required, check-sso
};



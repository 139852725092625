import React, { useRef, useEffect } from 'react';
import { X, AlertTriangle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';

import { useField } from '@unform/core';
import {
  func,
  bool,
  oneOf,
  shape,
  object,
  string,
  element,
  oneOfType,
} from 'prop-types';

import Checkbox from '../Checkbox';
import {
  Flag,
  View,
  Error,
  theme,
  styles,
  Target,
  Action,
  RemoveTag,
} from './styles';

const animatedComponents = makeAnimated();

const Option = ({
  label, isSelected, isDisabled, ...rest
}) => (
  <components.Option {...rest}>
    {rest.isMulti
      ? (
        <Checkbox
          label={label}
          selectedProp={isSelected}
          onSelected={() => null}
          isDisabled={isDisabled}
        />
      ) : (
        <label htmlFor={label}>{label}</label>
      )}
  </components.Option>
);

const ValueContainer = ({ children, ...rest }) => {
  const selectedCount = rest.getValue().length;
  const conditional = (selectedCount < 2);

  let firstChild = [];

  if (! conditional) {
    firstChild = [children[0].shift(), children[1]];
  }

  return (
    <components.ValueContainer {...rest}>
      {conditional ? children : firstChild}
      {! conditional && <Flag>{`+ ${selectedCount - 1}`}</Flag>}
    </components.ValueContainer>
  );
};

const MultiValue = ({ data, ...rest }) => (
  <components.MultiValue {...rest}>
    <span title={data.label}>{data.label}</span>
  </components.MultiValue>
);

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <X size="1.8rem" />
  </components.ClearIndicator>
);

export const SimpleSelect = ({
  icon: Icon,
  label,
  addTags,
  disabled,
  isReveal,
  appearance,
  clearField,
  classNamePrefix,
  ...rest
}) => {
  const { t } = useTranslation('components');

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <Icon size="1.8rem" />
    </components.DropdownIndicator>
  );

  const MenuList = ({ children, ...rest }) => (
    <components.MenuList {...rest}>
      {addTags && (
        <Action onClick={addTags.onClick}>
          {addTags.icon}
          {addTags.label}
        </Action>
      )}
      {children}
      {clearField && (
        <RemoveTag onClick={clearField.onClick}>
          {clearField.icon}
          {clearField.label}
        </RemoveTag>
      )}
    </components.MenuList>
  );

  return (
    <View
      disabled={disabled}
      isReveal={isReveal}
    >
      {label && <label htmlFor={label}>{label}</label>}

      <Target
        {...rest}
        theme={(theming) => theme(theming)}
        styles={styles}
        appearance={appearance}
        isDisabled={disabled}
        blurInputOnSelect={! rest.isMulti}
        components={{
          Option,
          MenuList,
          MultiValue,
          ValueContainer,
          ClearIndicator,
          DropdownIndicator,
          animatedComponents,
        }}
        cacheOptions
        loadingMessage={() => t('select.loading')}
        classNamePrefix={`${classNamePrefix || ''} ${appearance === 'secondary' ? 'is-secondary' : ''} ${appearance === 'terciary' ? 'is-terciary' : ''}`}
        noOptionsMessage={() => t('select.message')}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
    </View>
  );
};

export const Select = ({
  name,
  icon: Icon,
  label,
  created,
  isReveal,
  disabled,
  appearance,
  isClearable,
  initialValue,
  ...rest
}) => {
  const selectRef = useRef(null);

  const { t } = useTranslation('components');

  const {
    error,
    fieldName,
    clearError,
    defaultValue,
    registerField,
  } = useField(name);

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      {error
        ? <AlertTriangle size="2.2rem" />
        : <Icon size="1.8rem" />}
    </components.DropdownIndicator>
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (! ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (! ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },

      setValue: (ref, value) => {
        if (ref.select.setValue) ref.select.setValue(value || null);
        else ref.select.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <View
      isReveal={isReveal}
      disabled={disabled}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}

      <Target
        {...rest}
        as={isClearable ? CreatableSelect : ReactSelect}
        value={initialValue}
        ref={selectRef}
        name={name}
        error={error}
        theme={(theming) => theme(theming)}
        styles={{ ...styles, ...rest.styles }}
        onFocus={clearError}
        appearance={appearance}
        isDisabled={disabled}
        blurInputOnSelect={! rest.isMulti}
        components={{
          Option,
          MultiValue,
          ValueContainer,
          ClearIndicator,
          DropdownIndicator,
          animatedComponents,
        }}
        cacheOptions
        isClearable={isClearable}
        defaultValue={initialValue ?? defaultValue}
        onCreateOption={created}
        loadingMessage={() => t('select.loading')}
        classNamePrefix={`${appearance === 'secondary' ? 'is-secondary' : ''} ${error ? 'is-error' : ''}`}
        formatCreateLabel={(res) => `${t('select.create')} ${res}`}
        noOptionsMessage={() => t('select.message')}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />

      {error && <Error>{error}</Error>}
    </View>
  );
};

const propTypes = {
  icon: oneOfType([object, element]),
  label: oneOfType([bool, string]),
  created: func,
  appearance: oneOf(['primary', 'secondary', 'terciary']),
  isClearable: bool,
  isReveal: bool,
};

const defaultProps = {
  icon: {},
  isReveal: true,
  created: () => {},
  appearance: 'primary',
  isClearable: false,
};

Select.propTypes = {
  ...propTypes,
  name: string.isRequired,

  disabled: bool,
};

Select.defaultProps = {
  ...defaultProps,
  disabled: false,
};

SimpleSelect.propTypes = {
  addTags: oneOfType([bool, shape({
    icon: element,
    label: string,
    onClick: func,
  })]),
  ...propTypes,
};

SimpleSelect.defaultProps = {
  addTags: false,
  ...defaultProps,
};

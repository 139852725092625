import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Edit, Info } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import {
  Button,
  Container,
  DatePicker,
  Flag,
  Input,
  Loading,
  Modal,
  P1,
  P2,
  RiskLevel,
  Table,
  Tooltip,
  Upload,
} from '~/components';
import PopupViewer from '~/components/PopupViewer';
import { UnauthPopupWrapper } from '~/components/PopupWrapper';
import { formatErrorMessage, reduce } from '~/hooks';
import {isHTML, parseISO} from '~/hooks/helpers';
import { ResultHead } from '~/layout';
import { api } from '~/services';
import { table } from './data';
import { ActionsButton, Card, ModalView, View } from './styles';
import UnauthTableRow from '~/components/TableRow/UnauthTableRow';

const UnAuthActionPlanResponse = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const alert = useAlert();
  const { t } = useTranslation('actionplan');
  const { t: tR } = useTranslation('results');
  const { t: tC } = useTranslation('card');
  const { t: tAuth } = useTranslation('auth');
  const { t: tD } = useTranslation('diligence');
  const navigate = useNavigate();

  const token = new URLSearchParams(search).get('jwt');

  const [ actionplan, setActionplan ] = useState(null);
  const [ diligences, setDiligences ] = useState(null);
  const [ loading, onLoading ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);
  const [ showPopup, onShowPopup ] = useState(false);
  const [ popupData, setPopupData ] = useState('');
  const [ popupTitle, setPopupTitle ] = useState('');
  const [ checkResponse, setCheckResponse ] = useState(false);
  const [ rowsDiligence, onRowsDiligence ] = useState([]);
  const [ sortDiligences, onSortingDiligences ] = useState({});
  const [ modal, setModal ] = useState({});

  const popupWrapperRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => popupWrapperRef.current,
  });

  const ref = useRef(null);

  useEffect(async () => {
    await api.get('/actionplan/answer', { headers: { Authorization: `Bearer ${ token }` } })
      .then(response => setActionplan(response.data));
  }, [ id, loading ]);
  
  useEffect(async () => {
    await api.get(`/result/actionplan?&search=${ actionplan?.data.action_plan?.process_id }`)
      .then(res => setDiligences(res.data));
  }, [ actionplan, loading ]);
  
  const popups = useMemo(() => ( popupData ? JSON.parse(popupData) : popupData ), [ popupData ]);

  useEffect(() => {
    let row = [];
    if (diligences?.data?.length > 0) {
      row = diligences?.data?.map((item) => {

        const isArrow = true;

        return {
          id: item.id,
          items: [
            {
              label: (
                <Tooltip
                  label={ (
                    <>
                      { `${ tR('evaluations.tooltips.info.0') } ${ item.supplier_code ||
                      tR('evaluations.tooltips.info.1') }` }
                      <br />
                      { tR('evaluations.tooltips.info.2') }
                      { item.show_id }
                    </>
                  ) }
                  width={ 260 }
                >
                  <Info />
                </Tooltip>
              ),
            },
            {
              label: item.razaosocial || item.nomefantasia,
              isBold: true,
            },
            {
              label: item.cnpj,
            },
            {
              label: (
                <RiskLevel
                  id={ item.risk?.probabilidade_id }
                  risk={ Math.round(Number(item.risk?.risk)) }
                />
              ),
            },
            {
              label: item.dataabertura
                ? format(parseISO(item.dataabertura), 'dd/MM/yyyy')
                : '-',
            },
            {
              label: item?.workflows.map((child) => child.name) || '',
              isMulti: true,
            },
            {
              label: <Flag id={ item.new_status.id } status={ item.new_status.name } />,
            },
            {
              label: item.tags.length > 0 ? <Flag status={ item.tags[0].name } /> : ' ',
            },
            {
              isArrow,
              notArrowMessage: !isArrow && item?.analyst?.nome
                ? `${ tC('admin_operations.being_edited_by') } ${ item?.analyst?.nome } (${ item?.analyst?.id })`
                : null,
            },
          ],
        };
      });
    }

    onRowsDiligence(row);
  }, [ diligences ]);

  const tabled = {
    diligencias: {
      body: rowsDiligence,
      head: table.diligencias(tR, onSortingDiligences),
      route: '/pesquisa/diligencia',
      onBody: onRowsDiligence,
    },
  };
  
  const { action_plan } = actionplan?.data || { action_plan: null };
  
  const historyRows = useMemo(() => {
    let checkLabel = false;
    const localHistoryRows = action_plan?.history?.map((item) => {
      let label = '';
      const date = format(parseISO(item.created_at), 'dd/MM/yyyy');

      switch (item.action_id) {
        case 1:
          label = t('detail.status.approved');
          checkLabel = true;
          break;
        case 2:
          label = t('detail.status.refused');
          checkLabel = false;
          break;
        case 3:
          label = t('detail.status.reminder');
          checkLabel = false;
          break;
        case 4:
          label = t('detail.status.comment');
          checkLabel = false;
          break;
        case 5:
          label = t('detail.status.responsible');
          checkLabel = false;
          break;
        case 6:
          label = t('detail.status.due_date');
          checkLabel = false;
          break;
        case 7:
          label = t('detail.status.cancel');
          checkLabel = true;
          break;
        case 8:
          label = t('detail.status.answered');
          checkLabel = true;
          break;
        default:
          break;
      }

      return [
        { payload: label, type: 'TEXT' },
        { payload: item.note || '-', type: 'TEXT' },
        { payload: date, type: 'TEXT' },
        {
          payload: item.attachment_name ? item.attachment_name : t('detail.data.not_have'),
          type: 'TEXT',
        },
        {
          payload: item.attachment_name ? {
            types: [ 'FILE' ],
            fileAction: { onClick: () => { window.open(item.attachment_url, '_blank'); } },
          } : 1, type: item.attachment_name ? 'BUTTONS' : 'HEAD_BUTTONS',
        },
      ];
    });

    setCheckResponse(checkLabel);
    return localHistoryRows;
  }, [ action_plan, t, loading ]);

  if (!actionplan?.data) {
    return (
      <Loading />
    );
  }

  let i = 1;
  const resultColumns = [];
  
  if (action_plan.result) {
    while (action_plan.result[`coluna${i}`]) {
      resultColumns.push({
        payload: action_plan.result[`coluna${i}`],
        type: 'TEXT',
      });
      i += 1;
    }

    resultColumns.push({
      payload: {
        types: action_plan.result.popup ? ['FILE', 'REDIRECT'] : ['REDIRECT'],
        fileAction: {
          onClick: () => {
            setPopupData(action_plan.result.popup);
            setPopupTitle(
              action_plan.subject
                .split('-')
                .slice(1, action_plan.subject.length)
                .join(': ') || t('detail.not_risk'),
            );
            onShowPopup(true);
          },
        },
      },
      type: 'BUTTONS',
    });
  } else {
    resultColumns.push({
      payload: action_plan.subject,
      type: 'TEXT',
    });
  }

  const infoData = [
    { title: t('detail.data.responsible'), value: action_plan?.responsible_name },
    { title: t('detail.data.requester'), value: action_plan?.dd_owner.nome },
    {
      title: t('detail.data.due_date'),
      value: format(parseISO(action_plan?.due_date), 'dd/MM/yyyy'),
    },
    {
      title: t('detail.data.anex'),
      value: action_plan?.attachment_name ? (
        <P2
          as="a"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          href={ action_plan?.attachment_url }
          title={ action_plan?.attachment_name }
        >
          { reduce(action_plan?.attachment_name, 16) }
        </P2>
      ) : t('detail.data.not_have'),
    },
    { title: t('detail.data.email'), value: action_plan?.responsible_email },
    { title: t('detail.data.email'), value: action_plan?.dd_owner.email },
    {
      title: t('detail.data.opened'),
      value: format(parseISO(action_plan?.created_at), 'dd/MM/yyyy'),
    },
  ];

  const actionButtonItems = () => {
    const actionModal = { inputs: [] };

    let icon = <Edit />;
    let label = t('detail.actions.answered.label');
    actionModal.description = t('detail.actions.answered.description');
    actionModal.mandatoryNote = true;
    actionModal.title = label;
    actionModal.actionId = 7;
    actionModal.buttonText = label.split(' ')[0];
    actionModal.messageCancel = false;

    return {
      id: 8,
      icon,
      label,
      modal: actionModal,
    };
  };

  const handleSubmit = async (formData) => {
    onLoading(true);

    const {
      upload, actionId, due_date, responsible_email, responsible_name, ...formDataRest
    } = formData;

    if (formData.note.length > 1000) {
      alert.error(tD('seem.characteres_over_limit'));
      onLoading(false);
      return;
    }

    const message = { success: t('detail.messages.general.success'), error: t('detail.messages.general.error') };
    await api.post('/actionplan/answer', formDataRest, { headers: { Authorization: `Bearer ${ token }` } })
      .then((res) => {
        if (upload) {
          const form = new FormData();
          form.append('attachment', upload);
          api.post(`/actionplan/answer/${ res.data.data.action.id }/attachment`,
            form, { headers: { Authorization: `Bearer ${ token }` } })
            .catch(({ response }) => {
              alert.error(formatErrorMessage(response, t('detail.messages.upload.error'), tAuth('expired')));
            });
        }

        setShowModal(false);
        alert.success(message.success);
      })
      .catch(({ response }) => {
        alert.error(formatErrorMessage(response, message.error, tAuth('expired')));
      });
    onLoading(false);
  };
  
  return (
    <Container>
      <View>
        <ResultHead
          title={ `[${ action_plan.process.id }] ${ reduce(action_plan.process.razaosocial,32) } - ${ action_plan.process.cnpj }` }
        >
          <ActionsButton>
            { ( !checkResponse ? null : ( <Flag status={ t('detail.messages.status') } /> ) ) }
            { actionButtonItems && !checkResponse &&
              <>
                <Button
                  label={t('detail.action.answered')}
                  onClick={() => {
                    setModal(() => actionButtonItems().modal);
                    setShowModal(true);
                  }}
                />
              </>}
          </ActionsButton>
        </ResultHead>

        <Modal
          title={ modal.title }
          messageCancel={ modal.messageCancel }
          show={ showModal }
          onShow={ setShowModal }
          action={ {
            label: modal.buttonText,
            onClick: async () => {
              onLoading(true);
              ref.current.submitForm();
            },
            loading,
          } }
        >
          <ModalView>
            <Form ref={ ref } onSubmit={ handleSubmit }>
              <P2>{ modal.description }</P2>

              <Input reveal={ false } value={ modal.actionId } name="actionId" />

              { modal.inputs?.includes('responsible_name') && (
                <Input
                  name="responsible_name"
                  label={ t('detail.fields.responsible_name.label') }
                  appearance="secondary"
                  placeholder={ t('detail.fields.responsible_name.placeholder') }
                  reveal={ modal.inputs?.includes('responsible_name') }
                />
              ) }

              { modal.inputs?.includes('responsible_email') && (
                <Input
                  name="responsible_email"
                  label={ t('detail.fields.responsible_email.label') }
                  appearance="secondary"
                  placeholder={ t('detail.fields.responsible_email.placeholder') }
                  reveal={ modal.inputs?.includes('responsible_email') }
                />
              ) }

              { modal.inputs?.includes('due_date') && (
                <DatePicker
                  name="due_date"
                  label={ t('detail.fields.due_date.label') }
                  appearance="secondary"
                  placeholder={ t('detail.fields.due_date.placeholder') }
                  reveal={ modal.inputs?.includes('due_date') }
                  minDate={ new Date() }
                />
              ) }

              <Input
                name="note"
                label={ `${ t('detail.fields.note.label') } ${ modal.mandatoryNote
                  ? t('detail.fields.note.required')
                  : ''
                }` }
                appearance="secondary"
                placeholder={ t('detail.fields.note.placeholder') }
                textarea
                maxLength={ 1000 }
              />

              <Upload
                name="upload"
                label={ t('detail.fields.upload') }
                isDocument
              />
            </Form>
          </ModalView>
        </Modal>

        <Card>
          <header>
            { action_plan.subject.split('-').length > 1
              ? <P1 isBold>{ `${ action_plan.subject.split('-')[0] } - ${ action_plan.subject.split(
                '-')[1] }` }</P1>
              : <P1 isBold>{ action_plan.subject }</P1> }
            <Flag status={ action_plan.status.name } />
          </header>
          
          <div>
            { resultColumns.length > 1 
              ? (
                <UnauthTableRow
                  columns={ resultColumns }
                  redirect={ () => navigate(`/pesquisa/diligencia/${ action_plan.process.id }`) }
                /> 
              )
              
              : ( 
                <Table
                  body={ tabled.diligencias.body }
                  route={ tabled.diligencias.route }
                  onBody={ tabled.diligencias.onBody }
                  heading={ tabled.diligencias.head }
                  breakpoint="md"
                  newTab 
                />
            ) }
          </div>

          <article>
            <P1>{ t('detail.information.title') }</P1>
            <div>
              { infoData.map((item) => (
                <P2 key={ `[${ item.title }]` } title={ item.value }>
                  <span>{ `${ item.title }: ` }</span>
                  { item?.value ? reduce(item.value, 56) : t('detail.information.not_have') }
                </P2>
              )) }
            </div>
            <P2>
              <span>{ `${ t('detail.information.description') } ` }</span>
              {isHTML(action_plan.description) ?
                <div dangerouslySetInnerHTML={{__html: action_plan.description}}/> :
                action_plan.description
              }
            </P2>
          </article>

          { historyRows.length > 0 && (
            <article>
              <P1>{ t('detail.moves.title') }</P1>
              <table>
                <thead>
                <tr>
                  <UnauthTableRow
                    columns={ [
                      { payload: t('detail.moves.columns.action'), type: 'HEAD' },
                      { payload: t('detail.moves.columns.observation'), type: 'HEAD' },
                      { payload: t('detail.moves.columns.date'), type: 'HEAD' },
                      { payload: t('detail.moves.columns.anex'), type: 'HEAD' },
                      { payload: 1, type: 'HEAD_BUTTONS' },
                    ] }
                  />
                </tr>
                </thead>
                <tbody>
                { historyRows?.map((historyColumns) => (
                  <UnauthTableRow
                    key={ historyColumns[2] } // document
                    columns={ historyColumns }
                    dataLabels={ [
                      t('detail.moves.columns.action'),
                      t('detail.moves.columns.observation'),
                      t('detail.moves.columns.date'),
                      t('detail.moves.columns.anex'),
                    ] }
                  />
                )) }
                </tbody>
              </table>
            </article>
          ) }
        </Card>

        <Modal
          title={ popupTitle }
          show={ showPopup }
          onShow={ onShowPopup }
          hasActions={ false }
          size="lg"
          hasPrinterIcon
          onClickPrinter={ handlePrint }
        >
          <UnauthPopupWrapper ref={ popupWrapperRef } unauthenticated>
            <PopupViewer popup={ popups } />
          </UnauthPopupWrapper>
        </Modal>
      </View>
    </Container>
  );
};

export default UnAuthActionPlanResponse;

import React from 'react';

import { string } from 'prop-types';
import styled from 'styled-components';

const Image = styled.img`
  width: 100%
`;

const ImagesViewer = ({ data }) => <a href={data} target="_blank" rel="noreferrer"><Image src={data} alt="Evidencia" /></a>;

ImagesViewer.propTypes = {
  data: string.isRequired,
};

export default ImagesViewer;

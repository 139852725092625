import React, { useEffect, useState } from 'react';
import { Container, Content } from './styles';
import { Accordion, Checkbox, Empty, P1, SimpleLoading } from '~/components';
import Colors from '~/styles/colors';
import { useApi, useDebounce, useFetch } from '~/hooks';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useMe } from '~/services';

const Blacklist = ({ termList, validatingTermList, termListMutate }) => {
    const [ termsFiltredList, setTermsFiltredList ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ isEnable, setIsEnable ] = useState(true);
    const [ themeCheck, setThemeCheck ] = useState(false);
    const [ checkAll, setCheckAll ] = useState(false);

    const { t } = useTranslation('admin');
    const { user, group } = useMe();
    const alert = useAlert();
    const api = useApi();

    const {
      data: blacklist, isValidating: validatingBlacklist, mutate: blacklistMutate,
    } = useFetch({ url: `/blacklist/client/${ group?.value }` });

    useEffect(() => {
      if(!validatingTermList && blacklist) {
        const themeNames = termList?.data.map(val => ( {
          themeId: val.theme_id,
          themeName: val.theme_name,
          personType: val.person_type,
        } ));
        const unicThemeName = [ ...new Map(themeNames?.map(item => [ item[ 'themeId' ], item ])).values() ];

        const fullList = unicThemeName.map(val => {
              const filtered = termList?.data.filter(term => term.theme_id === val.themeId);
              const normalize = filtered.map(term => (
                  {
                    ...term,
                    checked: !blacklist.data.find(
                      item => ( item.termo_id === term.id && item.theme_id === term.theme_id ),
                    ),
                  }
                ),
              );

              return {
                ...val,
                terms: normalize,
              };
            },
          )
        ;

        setTermsFiltredList(fullList);

        if(termList && blacklist) {
          setLoading(false);
        }
      }
    }, [ termList, blacklist, validatingTermList, validatingBlacklist, group?.value ]);

    useEffect(() => {
      setIsEnable(!( user.isMaster() || user.isGestor() ));
    }, [ user ]);

    const handleCheck = async (_evt, termo_id, theme_id) => {
      setLoading(true);
      
      if(_evt) {
        await api().
          delete(`/blacklist`, { data: { terms: [ { theme_id, termo_id, client_id: group?.value }] }}).
          then(async () => {
            await termListMutate();
            await blacklistMutate();
            alert.success(t('blacklist.alerts.delete.success'));
          }).
          catch(() => alert.error(t('blacklist.alerts.delete.error')));
      }
      else {
        await api().
          put(`/blacklist`, { terms: [ { theme_id, termo_id, client_id: group?.value }] }).
          then(async () => {
            await termListMutate();
            await blacklistMutate();
            alert.success(t('blacklist.alerts.save.success'));
          }).
          catch(() => alert.error(t('blacklist.alerts.save.error')));
      }
      
      setLoading(false);
    };

    const handleThemeCheck = async (_evt, terms, themeId) => {
      const filtered = terms.filter(val => val.checked !== _evt).map(val => ({ termo_id: val.id, theme_id: themeId, client_id:group?.value }));
      setLoading(true);

      if(_evt) {
        await api().
          delete(`/blacklist`, { data: { terms: filtered } }).
          then(async () => {
            await termListMutate();
            await blacklistMutate();
            alert.success(t('blacklist.alerts.delete.success'));
          }).
          catch(() => alert.error(t('blacklist.alerts.delete.error')));
      } else {
        await api().
          put(`/blacklist`, { terms: filtered }).
          then(async () => {
            await termListMutate();
            await blacklistMutate();
            alert.success(t('blacklist.alerts.save.success'));
          }).
          catch(() => alert.error(t('blacklist.alerts.save.error')));
      }

      setLoading(false);
    };

    return (
      <Container>
        <P1> { t('blacklist.description') }</P1>
        { !termsFiltredList ? <SimpleLoading size={'lg'} /> :
          termsFiltredList.map(theme => {
            return (
              <Accordion
                key={ theme.themeId.toString() }
                backgroundColor={ Colors.gray._100 }
                columns={ [
                  {
                    type: 'TEXT',
                    payload: `${ theme.themeName }, ${ t(`blacklist.person_types.${ theme.personType }`) }`,
                  } ]
                }>
                { loading ? <SimpleLoading size={'md'} /> :
                  <>
                    <Content>
                      <Checkbox
                        key={ `${ theme.themeId } - Checkbox` }
                        label={ 'Selecionar todos' }
                        selectedProp={ theme.terms.filter(val => val.checked).length === theme.terms.length }
                        onSelected={ evt => handleThemeCheck(evt.target.checked, theme.terms, theme.themeId) }
                        isDisabled={ isEnable }
                      />
                    </Content>

                    <Content>
                      {
                        theme.terms?.map((value, index) => {
                            return (
                              <Checkbox
                                key={ `${ index }` }
                                label={ value.termo }
                                selectedProp={ value.checked }
                                onSelected={ evt => handleCheck(evt.target.checked, value.id, value.theme_id) }
                                isDisabled={ isEnable }
                              /> );
                          },
                        )
                      }
                    </Content>
                  </>
                }
              </Accordion>
            );
          })
        }
      </Container>
    );
  }
;

export default Blacklist;

import React, { useRef, useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import {
  P2,
  Modal,
  Input,
  Select,
  SimpleLoading
} from '~/components';
import { useApi, formatErrorMessage } from '~/hooks';
import { error, schema_tag } from '~/services';

import View from './styles';

const CreateClientTerm = ({
  show,
  render,
  onShow,
  options,
  clientId,
  renderList,
}) => {
  const [loading, onLoading] = useState(false);

  const ref = useRef();

  const alert = useAlert();
  const [themePersonType, setThemePersonType] = useState(null);
  const [themePersonTypeOptions, setThemePersonTypeOptions] = useState([]);
  const { t, ready } = useTranslation('clientterm', { useSuspense: false });
  const { t: tAuth } = useTranslation("auth");
  const api = useApi();
  const data = {
    'client_term': {
      head: t('create.label'),
      action: t('create.action'),
      description: t('create.description'),
    },

    'edit-client-term': {
      head: t('edit.label'),
      action: t('edit.action'),
      description: t('edit.description'),
    },

    replace: {
      head: t('replace.label'),
      action: t('replace.action'),
      description: t('replace.description'),
    },
  };
  useEffect(async () => {
    await api().get('/clientTerm/temas')
    .then((res) => {
      setThemePersonTypeOptions(res.data.map((item) => (
      { 
        value: item.id,
        label: item.name + ' - ' + item.person_type,
      })));
    });
  }, []);
  /**
   * Initial data.
   */
  useEffect(() => {
    if (show) {
      if (show.type === 'edit-client-term') {
        const replace = async () => {
          await api().get(`/clientTerm/${show.id}`)
            .then((res) => {
              ref.current.setData({
                name: res.data.data[0].termo,
              });
            });
          setThemePersonType(show.theme_id);
          await api().get('/clientTerm/temas')
            .then((res) => {
              setThemePersonTypeOptions(res.data.map((item) => (
              { 
                value: item.id,
                label: item.name + ' - ' + item.person_type,
              })));
            });
        };
        replace();
      }

      if (show.type === 'replace') {
        const replace = async () => {
          await api().get(`/tag/show/${show.id}`)
            .then((res) => {
              ref.current.setData({
                name: {
                  value: res.data.data[0].id,
                  label: res.data.data[0].name,
                },
              });
            });
        };

        replace();
      }
    }
  }, [show]);
  /**
   * Reset form.
   */
  const reset = () => {
    ref.current.reset();
  };

  /**
   * Updated list tag.
   */
  const updated = async (val) => {
    onLoading(true);

    await api().post('/clientTerm', {
      name: val,
      clientId,
    })
      .then(async ({ data: res }) => {
        await ref.current.setData({
          name: {
            value: res.term.id,
            label: res.term.name,
          },
        });

        renderList();
        alert.success(t('update.response.success'));
      })
      .catch(() => alert.error(t('update.response.error')));

    onLoading(false);
  };

  /**
   * Create and
   * edit tag.
   */
  const create = async (val, type) => {
    const client_term = async () => {
      onLoading(true);

      await api().post('/clientTerm', {
        term: val.name,
        clientId,
        theme: val.theme_id
      })
        .then(() => {
          // reset();
          render();
          onShow(false);
          alert.success(t('create.response.success'));
        })
        .catch(() => {
          onShow(false);
          alert.error(t('create.response.error'));
        });

      onLoading(false);
    };



    const edit_client_term = async () => {
      onLoading(true);

      await api().patch(`/clientTerm/${show.id}`, {
        name: val.name,
        clientId,
      })
        .then(() => {
          reset();
          render();
          onShow(false);
          alert.success(t('edit.response.success'));
        })
        .catch(({ response }) => alert.error(formatErrorMessage(response, t('edit.response.error'),tAuth('expired'))));

      onLoading(false);
    };

    await Yup.object()
      .shape(schema_tag)
      .validate(val, {
        abortEarly: false,
      })
      .then(() => {
        switch (type) {
          case 'client_term':
            client_term();
            break;

          case 'edit-client-term':
            edit_client_term();
            break;

          default:
            break;
        }
      })
      .catch((err) => error(err, ref));
  };

  // /**
  //  * Deleted tag.
  //  */
  // const deleted = async () => {
  //   onLoading(true);

  //   await api().post('/process/tag/associate/remove', {
  //     tagId: show.id,
  //     processId,
  //   })
  //     .then(() => {
  //       reset();
  //       render();
  //       onShow(false);
  //       alert.success(t('disassociate.response.success'));
  //     })
  //     .catch(({ response }) => alert.error(formatErrorMessage(response, t('disassociate.response.error'),tAuth('expired'))));

  //   onLoading(false);
  // };

  if (! ready) {
    return <SimpleLoading />;
  }

  return (
    <Modal
      show={show.reveal}
      title={data[show.type]?.head || ''}
      action={{
        label: data[show.type]?.action || '',
        loading,
        onClick: () => ref.current.submitForm(),
      }}
      onShow={onShow}
      // subAction={hasDelete && (show.type === 'edit-client-term' || show.type === 'replace') && {
      //   label: t('delete.action'),
      //   onClick: () => deleted(),
      // }}
    >
      <View>
        <P2>{data[show.type]?.description || ''}</P2>

        <Form
          ref={ref}
          onSubmit={(val) => create(val, show.type)}
        >
          {(show?.type === 'replace')
            ? (
              <Select
                isClearable
                icon={ChevronDown}
                name="name"
                label={t('field.label')}
                created={updated}
                options={options}
                isLoading={loading}
                appearance="secondary"
                placeholder={t('field.placeholder')}
                menuPosition="fixed"
                onInputChange={(inputValue) => (inputValue.length <= 32
                  ? inputValue
                  : inputValue.substr(0, 32))}
              />
            ) : (
              <Input
                name="name"
                label={t('field.label')}
                maxLength={32}
                appearance="secondary"
                placeholder={t('field.placeholder')}
              />
            )}

            <Select
              icon={ChevronDown}
              value={themePersonType}
              options={themePersonTypeOptions}
              onChange={(val) => { setThemePersonType(val)}}
              isLoading={false}
              name="theme_id"
              placeholder={'Tema'}
              isSearchable={false}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
        </Form>
      </View>
    </Modal>
  );
};

CreateClientTerm.defaultProps = {
  show: false,
  render: () => {},
  onShow: () => {},
  options: [],
  clientId: 0,
  processId: 0,
  renderList: () => {},
  isReplace: false,
  hasDelete: false,
  updateTag: () => {},
};

export default CreateClientTerm;

import styled, { css } from 'styled-components';

import {
  breakpoints, colors, easing, typograph,
} from '~/styles';

const { gray, status, risks } = colors;

const { basic } = easing;

const { weight, family, size } = typograph;

export const Head = styled.div`
  position: relative;
  display: flex;
  padding: 0 2.4rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6.4rem;

  .alert {
    width: 25rem;
  }

  *:not(:last-of-type) {
    width: ${({ columnsNumber }) => (1 / columnsNumber) * 90}%;
  }

  > div {
    display: flex;
    align-items: center;
    color: ${gray._600};

    > span {
      display: flex;
      align-items: center;
      margin-left: 0.7rem;
      color: ${gray._500};
      font-weight: ${weight.semiBold};

      svg {
        cursor: pointer;
        margin-left: 0.7rem;
        transform: scale(0.8);
      }
    }
  }

  > p:first-of-type {
    width: 28rem;
    color: ${gray._600};
    font-weight: ${weight.semiBold};
  }

  > p {
    color: ${gray._500};

    > span {
      :last-of-type {
        color: ${gray._600};
      }
    }

    span.alert {
      color: ${status.success};
    }

    span.alert-danger {
      color: ${({ impactID }) => impactID === 1 && `${risks.low} !important`} ;
      color: ${({ impactID }) => impactID === 2 && `${risks.medium} !important`} ;
      color: ${({ impactID }) => impactID === 3 && `${risks.high} !important`} ;
      color: ${({ impactID }) => impactID === 4 && `${risks.critical} !important`} ;
    }
    
    span.alert-1 { color: ${risks.low} };
    span.alert-2 { color: ${risks.medium} };
    span.alert-3 { color: ${risks.high} };
    span.alert-4 { color: ${risks.critical} };
  }

  & > button {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${({ isOpenable }) => (isOpenable ? gray._100 : gray._000)};
    cursor: ${({ isOpenable }) => (isOpenable ? 'pointer' : 'default')};
    overscroll-behavior: contain;

    svg {
      width: 2rem;
      height: 2rem;
      stroke: ${gray._400};
      transform: rotate(0);
      transition: all 0.3s ${basic};
    }
  }

  & > .anchorBtn {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${({ isOpenable }) => (isOpenable ? gray._100 : gray._000)};
    cursor: ${({ isOpenable }) => (isOpenable ? 'pointer' : 'default')};

    svg {
      width: 2rem;
      height: 2rem;
      stroke: ${gray._400};
      transform: rotate(0);
      transition: all 0.3s ${basic};
    }
  }
  
  ${({ isInfo }) => isInfo
    && css`
      & > p {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
          stroke: ${gray._600};
          margin-left: 8px;
        }
      }
    `}

  .toggle {
    width: 20rem;
    align-items: center;
    justify-content: flex-start;
  }

  .modified {
    span > span {
      color: ${status.error};
    }
  }

  .progress-bar {
    width: 22rem;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    ${({ columnsNumber }) => columnsNumber > 2
      && css`
        > p:first-of-type {
          width: 13rem;
        }
      `}
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    ${({ columnsNumber }) => columnsNumber > 2
      && css`
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        height: fit-content;
        padding: 2rem 0;

        th {
          display: none !important;
        }

        & & {
          > *.alert {
            text-align: right !important;
          }
        }

        > * {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: fit-content;
          padding: 1rem 2rem;
          width: 100% !important;

          &:nth-child(even) {
            justify-content: flex-end;
            text-align: right !important;
          }

          &.alert {
            display: block;
          }
        }

        > button {
          background-color: ${gray._000};
          border-radius: 0;
          padding-top: 1.5rem;
          grid-column: 1 / -1;

          svg {
            transform: translateX(4rem);
          }

          &::before {
            content: attr(data-label);
            font-weight: ${weight.bold};
            position: absolute;
            left: 0;
            width: 50%;
            padding: 0 2rem;
            text-align: right;
            color: ${gray._500} !important;
            font-family: ${family};
            font-size: ${size.s1};
          }
        }
      `}
  }
`;

export const Body = styled.div`
  height: 0;
  padding: 0 2.4rem;
  display: flex;
  margin-top: 0;
  transition: all 0.3s ${basic};
  flex-direction: column;

  ${({ hasOverflow }) => hasOverflow
    && css`
      overflow-x: auto;
    `};

  & > * {
    opacity: 0;
    position: relative;
    transition: all 0.3s ${basic};
    pointer-events: none;
  }
`;

export const View = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({backgroundColor}) => backgroundColor ?? gray._000};
  margin: 0.5rem 0;
  ${({ sizing }) => sizing && css`width: ${ sizing } !important;`}

  & & {
    border-top: 0.1rem solid ${gray._200};
    margin: 0;
  }

  ${({ show, isRedirect }) => show
    && ! isRedirect
    && css`
      > ${Head} > button svg {
        transform: rotate(180deg);

        @media screen and (max-width: ${breakpoints.sm}) {
          ${({ columnsNumber }) => columnsNumber > 2
            && css`
              transform: rotate(180deg) translateX(-4rem);
            `}
        }
      }

      > ${Body} {
        height: 100%;
        margin-top: 0.8rem;
        padding-bottom: 2.4rem;

        > * {
          top: 0;
          opacity: 1;
          pointer-events: all;
        }
      }
    `}

  ${({ isRedirect }) => isRedirect
    && css`
      > ${Head} > button {
        background-color: ${gray._000};
        padding: 0;
      }
    `}
`;

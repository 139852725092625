import styled from 'styled-components';

import { colors, typograph } from '~/styles';

const { status, gray, risks } = colors;
const { error, success, warning_100 } = status;
const { weight } = typograph;

const Container = styled.div`
  display: flex;
  align-items: center;

  > div {
    position: relative;
    width: 7.2rem;
    border-radius: 1.0rem;
    height: .7rem;
    background: ${gray._200};
    margin-right: 1.0rem;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 1.0rem;
      width: ${({ percentage }) => `${percentage}%`};
      background: ${({ impact_id }) => impact_id === 1 && risks.low};
      background: ${({ impact_id }) => impact_id === 2 && risks.medium};
      background: ${({ impact_id }) => (impact_id === 3) && risks.high};
      background: ${({ impact_id }) => (impact_id === 4) && risks.critical};
    }
  }

  p {
    white-space: nowrap;
    font-weight: ${weight.bold};
    color: ${gray._400};
    color: ${({ impact_id }) => impact_id === 1 && risks.low};
    color: ${({ impact_id }) => impact_id === 2 && risks.medium};
    color: ${({ impact_id }) => (impact_id === 3) && risks.high};
    color: ${({ impact_id }) => (impact_id === 4) && risks.critical};
  }
`;

export default Container;

import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKView, Error } from './styles';

export const LongTextInput = ({
  name,
  id,
  label,
  language,
  appearance,
  ...rest
}) => {
  const editorRef = useRef(null);
  const { 
    error,
    fieldName,
    defaultValue,
    registerField,
    clearError,
  } = useField(name);

  useEffect(() => {
    if (editorRef.current) {
      registerField({
        name: fieldName,
        ref: editorRef.current,
        getValue: ref => {
          return ref.getData();
        },
        setValue: (ref, value) => {
          ref.setData(value);
        },
        clearValue: ref => {
          ref.setData('');
        },
      });
    }
  }, [fieldName, registerField]);

  const ckEditorConfig = {
    language: language,
    toolbar: {
      items: [
        'undo', 'redo', '|', 'heading', '|', 'alignment', '|', 'bold',
        'italic', 'numberedList', 'outdent', 'indent', 'removeFormat',
      ],
      height: '500',
      shouldNotGroupWhenFull: true,
    },
  };

  return (
    <CKView 
      error={error}
      appearance={appearance ?? 'secondary'}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <CKEditor
        id={id}
        editor={ClassicEditor}
        data={defaultValue || ''}
        onFocus={clearError}
        onReady={editor => {
          editorRef.current = editor;
          
          registerField({
            name: fieldName,
            ref: editor,
            getValue: ref => ref.getData(),
            setValue: (ref, value) => ref.setData(value),
            clearValue: ref => ref.setData(''),
          });
        }}
        config={ckEditorConfig}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </CKView>
  );
};

import { useMarkedItems } from '~/views/Search/Response/Diligence2/markedItemsContext';

const useItemMarkedFlag = (id) => {
  const { markedItems, addMarkedItems, removeMarkedItems } = useMarkedItems();
  const imMarked = markedItems.includes(id);
  return {
    markedFlagColumn: {
      type: 'MARKED',
      payload: {
        active: imMarked,
        action: {
          toggle: async () => {
            if (imMarked) {
              removeMarkedItems([id]);
            } else {
              addMarkedItems([id]);
            }
          },
        },
      },
    },
  };
};

export default useItemMarkedFlag;

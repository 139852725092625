import React from 'react';

import {
  func,
  bool,
  shape,
  element,
  arrayOf,
} from 'prop-types';

import View from './styles';

const Actions = ({ hasError, actions, disabled, red = false }) => (
  <View hasError={hasError} red={red}>
    {actions.map(({ icon, action }, index) => (
      <button
        disabled={disabled}
        key={index}
        type="button"
        onClick={action}
      >
        {icon}
      </button>
    ))}
  </View>
);

Actions.propTypes = {
  actions: arrayOf(shape({
    icon: element,
    action: func,
  })).isRequired,
  hasError: bool,
  disabled: bool,
};

Actions.defaultProps = {
  disabled: false,
  hasError: true,
};

export default Actions;

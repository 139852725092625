import styled from "styled-components";

import { colors, typograph } from "~/styles";

export const Wrapper = styled.div`
  div {
    & > label {
      color: ${colors.gray._600};
      font-size: ${typograph.size.s3};
      line-height: ${typograph.size.m1};
      font-weight: ${typograph.weight.bold};
    }
  }
`;

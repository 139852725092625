import styled, { css } from 'styled-components';

import { P2 } from '~/components/Title';
import { breakpoints, colors, typograph } from '~/styles';

const { status, gray, risks } = colors;
const { error, success, warning_100 } = status;
const { weight } = typograph;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${P2}{
    color: ${gray._500};
    margin-bottom: ${({ isSmall }) => (isSmall ? 0 : '15px')};
    font-size: ${({ isSmall }) => (isSmall ? typograph.size.s1 : typograph.size.s2)};
  }

  > div:first-of-type {
    position: relative;
    ${({ isSmall }) => isSmall && css`height: 80px;`};

    h6 {
      position: absolute;
      top: ${({ isSmall }) => (isSmall ? '60%' : '49%')};
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${gray._500};
      font-weight: ${weight.semiBold};
      font-size: ${({ isSmall }) => (isSmall ? '15px' : typograph.size.md1)};
    }

    svg {
      position: relative;
      width: 97px;
      height: 97px;
      ${({ isSmall }) => isSmall && css`transform: scale(.7);`};

      circle {
        width: 97px;
        height: 97px;
        fill: none;
        stroke-width: 7;
        transform: translate(3.5px, 3.5px);
        stroke-dasharray: 283;
        stroke-dashoffset: 283;
        stroke-linecap: round;

        &:nth-child(1) {
          stroke-dashoffset: 0;
          stroke: ${gray._200};
        }

        &:nth-child(2) {
          stroke-dashoffset: ${({ percentage }) => 283 - (283 * percentage) / 100};
          stroke: ${risks.low};
          stroke: ${({ impact_id }) => impact_id === 2 && risks.medium};
          stroke: ${({ impact_id }) => (impact_id === 3) && risks.high};
          stroke: ${({ impact_id }) => (impact_id === 4) && risks.critical};
        }
      }
    }
  }

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: 10px 0 5px;
      font-weight: ${weight.semiBold};
      font-size: ${({ isSmall }) => (isSmall ? typograph.size.s1 : typograph.size.s2)};

      :first-of-type {
        color: ${risks.low} !important;
        color: ${({ impact_id }) => impact_id === 2 && `${risks.medium} !important`};
        color: ${({ impact_id }) => (impact_id === 3) && `${risks.high} !important`};
        color: ${({ impact_id }) => (impact_id === 4) && `${risks.critical} !important`};
      }

      :last-of-type {
        color: ${gray._500};

        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
          transform: scale(0.7);
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    transform: scale(0.8);
  }
`;

export default Container;

import styled from 'styled-components';

import { H6, P2, Layout } from '~/components';
import { rgba } from '~/hooks/helpers';
import { breakpoints, colors, typograph } from '~/styles';

const { gray, primary, secondary, green } = colors;

const { size, weight } = typograph;

export const View = styled(Layout)`
  margin-bottom: 6.4rem;

  .simple-loading {
    height: calc(100vh - 30rem);
    display: flex;
    align-items: center;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  & > span {
    width: 10rem;
    height: 10rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, '.1')};

    svg {
      width: 4rem;
      height: 4rem;
      stroke: ${primary._000};
    }
  }

  & > ${H6} {
    text-align: center;
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
  }

  & > ${P2} {
    font-size: ${size.s3};
    color: ${gray._500};
    font-weight: ${weight.regular};
    text-align: center;
    margin-bottom: 3rem;
  }
`;

export const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  & > ${P2} {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;

    & > div {
      margin-top: 1.6rem;
    }
  }
`;

export const ActionHeader = styled.div`
  display: flex;
  margin-left: auto;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: flex-end;

  & > div {
    max-width: 23rem;
    margin-left: 1.6rem;

    &:first-of-type {
      margin-left: auto;
    }
  }

  & > button {
    margin-left: 1.6rem;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    grid-column: span 2;
    grid-row: 3 / span 1;
    margin: 0;

    & > div {
      &, &:first-of-type {
        margin-left: 0;
      }
    }
  }
`;

export const ViewProfile = styled.div`
  color: ${gray._000};
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  min-width: 3.2rem;
  font-size: ${size.s1};
  line-height: ${size.s3};
  font-weight: ${weight.semiBold};
  align-items: center;
  border-radius: 50%;
  text-transform: uppercase;
  justify-content: center;
  background-color: ${secondary};
  &:hover {
    background-color: ${green._600};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

`;

export const WorkflowItemsContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 2rem;
`;

export const WorkflowHeader = styled.div`
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  & > div {
    display: flex;
  }
`;

export const ButtonLeft = styled.div`
  margin-right: 1rem;
`;

export const WorkflowRightDiv = styled.div`
  display:flex; 
  width: 400px;
`;

export const WorkflowLeftSelect = styled.div`
  width: 190px;
  margin-right: 1rem;
`;

import React from 'react';
import { Route, Navigate, Outlet, Routes } from 'react-router-dom';
import { useAuth, useMe } from '~/services';

import { bool, string } from 'prop-types';

import { ResultContextProvider } from '~/views/Search/Response/Diligence2/resultContext';
import { SelectedRiskSourceProvider } from './components/RiskSourceSelector/selectedRiskSourcesContext';
import MarkedItemsProvider from './views/Search/Response/Diligence2/markedItemsContext';

import Head from './layout/Head';
import {
  Search,
  Results,
  Workflow,
  Dashboard,
  Monitoring,
  ActionPlan,
  ActionPlanDetails,
  Administration,
  ResultsEntities,
  DiligenceDetails,
  DiligenceDetails2,
  SimplifiedDetails,
  CadastralDetails,
} from './views';

const Redirect = ({ route }) => <Navigate replace to={ route } />;

const Router = () => {
  const { isAuthenticated } = useAuth();
  const { user } = useMe();

  const screenElement = process.env.REACT_APP_DILIGENCE_SCREEN === 'old'
    ? ( <DiligenceDetails /> )
    : (
      <ResultContextProvider>
        <MarkedItemsProvider>
          <DiligenceDetails2 />
        </MarkedItemsProvider>
      </ResultContextProvider>
    );

  return (
    <Routes>
      { isAuthenticated
        ? (
          <Route path="/" element={ <Head isAuthenticated={ isAuthenticated } /> }>
            { (user.isAdminICTS()) && (
              <>
                <Route path="resultados" element={ <Results /> } />
                <Route path="pesquisa">
                  <Route path="diligencia/:id"
                         element={ screenElement } />
                  <Route path="entidades/:id" element={ <ResultsEntities /> } />
                </Route>
                <Route path="plano-de-acao">
                  <Route path="" element={ <ActionPlan /> } />
                  <Route path=":id" element={ <ActionPlanDetails /> } />
                </Route>
              </>
            ) }

            { !user.isAdminICTS() && (
              <>
                <Route exact path="/" element={ <Search /> } />
                <Route path="administracao">
                  <Route path="" element={ <Administration /> } />
                  <Route path="workflow"
                         element={ <SelectedRiskSourceProvider><Workflow /></SelectedRiskSourceProvider> } />
                  <Route path="workflow/:id"
                         element={ <SelectedRiskSourceProvider><Workflow /></SelectedRiskSourceProvider> } />
                </Route>
                <Route path="pesquisa">
                  <Route path="" element={ <Search /> } />
                  <Route path="entidades/:id" element={ <ResultsEntities /> } />
                  <Route path="diligencia/:id"
                         element={ screenElement } />
                  <Route path="simplificada/:id" element={ <SimplifiedDetails /> } />
                  <Route path="cadastral/:id" element={ <CadastralDetails /> } />
                </Route>
                <Route path="resultados" element={ <Results /> } />
                <Route path="dashboard" element={ <Dashboard /> } />
                <Route path="monitoramento" element={ <Monitoring /> } />
                <Route path="plano-de-acao">
                  <Route path="" element={ <ActionPlan /> } />
                  <Route path=":id" element={ <ActionPlanDetails /> } />
                </Route>
              </>
            ) }
          </Route>)
        : (
          <>
            <Route path="/plano-de-acao/answer" element={ <Head isAuthenticated={ isAuthenticated } /> }>
              <Route path="" element={ <ActionPlanDetails /> } />
            </Route>
          </>) }
    </Routes>
  );
};

Router.propTypes = {
  isAuthenticated: bool,
};

Router.defaultProps = {
  isAuthenticated: false,
};

Redirect.propTypes = {
  route: string.isRequired,
};

export default Router;

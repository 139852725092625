import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { arrayOf, number } from 'prop-types';

import { P2 } from '~/components';

import Container from './styles';

const ProgressBar = ({ percentage, impact_id, defaultRiskLevels }) => {
  const { t } = useTranslation('components');

  const impactId = useMemo(() => {
    let response = Number(impact_id);
    if (response === 0) {
      response = defaultRiskLevels.findIndex((val, idx) => {
        if (defaultRiskLevels[idx + 1]) {
          return percentage >= val && percentage < defaultRiskLevels[idx + 1];
        }
        return true;
      }) + 1;
    }
    return response;
  }, [percentage, impact_id, defaultRiskLevels]);

  const riskLevel = useMemo(() => {
    switch (impactId) {
      case 1:
        return t('risks.low');
      case 2:
        return t('risks.medium');
      case 3:
        return t('risks.high');
      case 4:
        return t('risks.critical');
      default:
        return t('charts.search');
    }
  }, [t, impactId]);

  return (
    <Container impact_id={impactId} percentage={percentage}>
      <div>
        <span />
      </div>
      <P2>
        {impactId === 0 ? riskLevel : `${riskLevel} (${percentage}%)`}
      </P2>
    </Container>
  );
};

ProgressBar.propTypes = {
  percentage: number.isRequired,
  impact_id: number,
  defaultRiskLevels: arrayOf(number),
};

ProgressBar.defaultProps = {
  impact_id: 0,
  defaultRiskLevels: [0, 25, 40, 70],
};

export default ProgressBar;

import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import { instanceOf } from 'prop-types';

import {
  P1, Table, SimpleLoading,
} from '~/components';
import { useFetch } from '~/hooks';
import { maskMoney } from '~/hooks/helpers';
import { useMe } from '~/services';
import { Grid } from '~/views/Dashboard/styles';

const Pricing = ({ date, endDate }) => {
  const { t } = useTranslation('admin');

  const queryDate = useMemo(
    () => ({
      start: date
        ? format(date, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-01'),
      end: endDate
        ? format(endDate, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
    }),
    [date, endDate],
  );

  const { user } = useMe();

  const { data: budget } = useFetch({ url: `/budget?from=${queryDate?.start}&to=${queryDate?.end}` });
  const { data: entitiesNames } = useFetch({ url: '/budget/entitiesNames' });

  if (! entitiesNames && ! budget) {
    return <SimpleLoading />;
  }

 
  const heading = [
    { label: t('pricing.table.heading.item'), size: 65 },
    { label: t('pricing.table.heading.quantity'), size: 10, align: 'center' },
    { label: t('pricing.table.heading.value'), size: 15, align: 'center' },
    { label: t('pricing.table.heading.total'), size: 10, align: 'center' },
  ];

  const generateTableBody = (rawData, hasTotal) => {
    const body = [];

    if (! rawData) return body;

    // if (rawData.simple_search) {
    //   body.push({
    //     items: [
    //       { label: t('pricing.table.body.simple_search') },
    //       { label: rawData.simple_search?.count || 0 },
    //       {
    //         label: maskMoney(
    //           (rawData.simple_search?.total || 0) / (rawData.simple_search?.count || 1),
    //         ),
    //       },
    //       { label: maskMoney(rawData.simple_search?.total || 0) },
    //     ],
    //   });
    // }

    if (rawData.diligences) {
      body.push({
        items: [
          { label: t('pricing.table.body.diligences') },
          { label: rawData.diligences?.count || 0 },
          {
            label: maskMoney(
              (rawData.diligences?.total || 0) / (rawData.diligences?.count || 1),
            ),
          },
          { label: maskMoney(rawData.diligences?.total || 0) },
        ],
      });
    }

    if (rawData.foreign_diligences) {
      body.push({
        items: [
          { label: t('pricing.table.body.foreign_diligences') },
          { label: rawData.foreign_diligences?.count || 0 },
          {
            label: maskMoney(
              (rawData.foreign_diligences?.total || 0) / (rawData.foreign_diligences?.count || 1),
            ),
          },
          { label: maskMoney(rawData.foreign_diligences?.total || 0) },
        ],
      });
    }

    if (rawData.prepaid_diligences) {
      body.push({
        items: [
          { label: t('pricing.table.body.prepaid_diligences') },
          { label: rawData.prepaid_diligences?.count || 0 },
          {
            label: maskMoney(
              (rawData.prepaid_diligences?.total || 0) / (rawData.prepaid_diligences?.count || 1),
            ),
          },
          { label: maskMoney(rawData.prepaid_diligences?.total || 0) },
        ],
      });
    }
    if (rawData.relationship_diligences) {
      body.push({
        items: [
          { label: t('pricing.table.body.relationship_diligences') },
          { label: rawData.relationship_diligences?.count || 0 },
          {
            label: maskMoney(
              // eslint-disable-next-line
              (rawData.relationship_diligences?.total || 0) / (rawData.relationship_diligences?.count || 1),
            ),
          },
          { label: maskMoney(rawData.relationship_diligences?.total || 0) },
        ],
      });
    }

    if (rawData.risk_sources && Object.keys(rawData.risk_sources).length) {
      body.push({
        items: [
          { label: t('pricing.table.body.risk_sources'), isBold: true },
          { label: '' },
          { label: '' },
          { label: '' },
        ],
      });
      Object.keys(rawData.risk_sources).forEach((riskSourceID) => {
        const riskSourceCountInfo = rawData.risk_sources[riskSourceID];
        body.push({
          items: [
            {
              // eslint-disable-next-line
              label: entitiesNames?.data?.risk_sources?.find((o) => o.id == riskSourceID).name,
              leftSpacing: 6,
            },
            { label: riskSourceCountInfo.count || 0 },
            {
              label: maskMoney(
                (riskSourceCountInfo.total || 0) / (riskSourceCountInfo.count || 1),
              ),
            },
            { label: maskMoney(riskSourceCountInfo.total || 0) },
          ],
        });
      });
    }

    if (rawData.analyses && Object.keys(rawData.analyses).length) {
      body.push({
        items: [
          { label: t('pricing.table.body.analyses'), isBold: true },
          { label: '' },
          { label: '' },
          { label: '' },
        ],
      });
      Object.keys(rawData.analyses).forEach((analysisID) => {
        const analysisCountInfo = rawData.analyses[analysisID];
        body.push({
          items: [
            {
              // eslint-disable-next-line eqeqeq
              label: entitiesNames?.data?.analyses?.find((o) => o.id == analysisID).title,
              leftSpacing: 6,
            },
            { label: analysisCountInfo.count || 0 },
            {
              label: maskMoney(
                (analysisCountInfo.total || 0) / (analysisCountInfo.count || 1),
              ),
            },
            { label: maskMoney(analysisCountInfo.total || 0) },
          ],
        });
      });
    }

    if (rawData.foreign_analyses && Object.keys(rawData.foreign_analyses).length) {
      body.push({
        items: [
          { label: t('pricing.table.body.foreign_analyses'), isBold: true },
          { label: '' },
          { label: '' },
          { label: '' },
        ],
      });
      Object.keys(rawData.foreign_analyses).forEach((analysisID) => {
        const analysisCountInfo = rawData.foreign_analyses[analysisID];
        body.push({
          items: [
            {
              // eslint-disable-next-line eqeqeq
              label: entitiesNames?.data?.analyses?.find((o) => o.id == analysisID).title,
              leftSpacing: 6,
            },
            { label: analysisCountInfo.count || 0 },
            {
              label: maskMoney(
                (analysisCountInfo.total || 0) / (analysisCountInfo.count || 1),
              ),
            },
            { label: maskMoney(analysisCountInfo.total || 0) },
          ],
        });
      });
    }

    if (hasTotal) {
      body.push({
        items: [
          { label: 'Sub-total (A)', isBold: true },
          { label: '' },
          { label: '' },
          { label: maskMoney(rawData.total_without_descount || 0) },
        ],
      });

      body.push({
        items: [
          { label: t('pricing.table.body.monthly'), isBold: true },
          { label: '' },
          { label: '' },
          { label: maskMoney(rawData.monthly || 0) },
        ],
      });

      const total = rawData.monthly >= rawData.total ? { formula: 'B', total: rawData.monthly } : { formula: 'A', total: rawData.total };
      if (rawData.associated_diligences) {
        body.push({
          items: [
            { label: t('pricing.table.body.discount_associated_diligences'), isBold: true },
            { label: rawData.associated_diligences?.count || 0 },
            {
              // eslint-disable-next-line max-len
              label: `-${maskMoney((rawData.associated_diligences?.total || 0) / (rawData.associated_diligences?.count || 1))}`,
            },
            { label: `- ${maskMoney(rawData.associated_diligences?.total || 0)}` },
          ],
        });
      }

      if (rawData.associated_diligences_risk_sources
         && Object.keys(rawData.associated_diligences_risk_sources).length) {
        body.push({
          items: [
            { label: t('pricing.table.body.discount_risk_sources_associated_diligences'), isBold: true },
            { label: '' },
            { label: '' },
            { label: '' },
          ],
        });
        Object.keys(rawData.associated_diligences_risk_sources).forEach((riskSourceID) => {
          const riskSourceCountInfo = rawData.associated_diligences_risk_sources[riskSourceID];
          body.push({
            items: [
              {
                // eslint-disable-next-line eqeqeq
                label: entitiesNames?.data?.risk_sources?.find((o) => o.id == riskSourceID).name,
                leftSpacing: 6,
              },
              { label: riskSourceCountInfo.count || 0 },
              {
                label: `- ${maskMoney(
                  (riskSourceCountInfo.total || 0) / (riskSourceCountInfo.count || 1),
                )}`,
              },
              { label: `- ${maskMoney(riskSourceCountInfo.total || 0)}` },
            ],
          });
        });
      }
      if (rawData.monitored_diligences) {
        body.push({
          items: [
            { label: t('pricing.table.body.discount_monitored_diligences'), isBold: true },
            { label: rawData.monitored_diligences?.count || 0 },
            {
              // eslint-disable-next-line max-len
              label: `-${maskMoney((rawData.monitored_diligences?.total || 0) / (rawData.monitored_diligences?.count || 1))}`,
            },
            { label: `- ${maskMoney(rawData.monitored_diligences?.total || 0)}` },
          ],
        });
      }

      if (rawData.monitored_diligences_risk_sources
         && Object.keys(rawData.monitored_diligences_risk_sources).length) {
        body.push({
          items: [
            { label: t('pricing.table.body.discount_risk_sources_monitored_diligence'), isBold: true },
            { label: '' },
            { label: '' },
            { label: '' },
          ],
        });
        Object.keys(rawData.monitored_diligences_risk_sources).forEach((riskSourceID) => {
          const riskSourceCountInfo = rawData.monitored_diligences_risk_sources[riskSourceID];
          body.push({
            items: [
              {
                // eslint-disable-next-line eqeqeq
                label: entitiesNames?.data?.risk_sources?.find((o) => o.id == riskSourceID).name,
                leftSpacing: 6,
              },
              { label: riskSourceCountInfo.count || 0 },
              {
                label: `- ${maskMoney(
                  (riskSourceCountInfo.total || 0) / (riskSourceCountInfo.count || 1),
                )}`,
              },
              { label: `- ${maskMoney(riskSourceCountInfo.total || 0)}` },
            ],
          });
        });
      }
      body.push({
        items: [
          { label: `Total (${total.formula})`, isBold: true },
          { label: '' },
          { label: '' },
          { label: maskMoney(total.total) },
        ],
      });
    }

    return body;
  };

  const mapByClient = (clientID) => {
    // eslint-disable-next-line eqeqeq
    const clientName = user?.hierarquia?.find((o) => o.id == clientID)?.cliente;
    return (
      <>
        <P1>{t('pricing.specific', { clientName })}</P1>
        <Table
          heading={heading}
          body={generateTableBody(budget?.data?.by_client[clientID], false)}
        />
      </>
    );
  };

  return (
    <Grid>
      <P1>{t('pricing.general')}</P1>
      <Table
        heading={heading}
        body={generateTableBody(budget?.data?.total, true)}
      />
      {Object.keys(budget?.data?.by_client || {}).map(mapByClient)}
    </Grid>
  );
};

Pricing.propTypes = {
  date: instanceOf(Date).isRequired,
  endDate: instanceOf(Date).isRequired,
};

export default Pricing;

import styled from 'styled-components';

import { breakpoints, colors } from '~/styles';

export const InfoView = styled.span`
  color: ${colors.status.warning};
`;

export const SiteSettingsView = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 84px;
  column-gap: 2rem;
  
  @media screen and (max-width: ${breakpoints.sm}) {
    grid-template-areas: "a" "d" "c";
  }
  
  > div:first-child {
    grid-area: a;
  }
  
  > div:nth-child(2) {
    grid-area: b;
  }
  
  > button {
    grid-area: c;
    justify-content: center;
  }
  
  ${InfoView} {
    grid-area: d;
  }
`;

export const List = styled.li`
  position: relative;
  display: flex; 
  justify-content: flex-start;
  align-items: center;
`;

export const SelectClientSiteValues = styled.div`
  display: flex;
  flex: 1;
  width: '100%';
  justify-content: space-between;
  place-items: center;
`;

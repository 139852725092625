import React from 'react';

import { array } from 'prop-types';

import ListRegistrationDataEntry from './ListRegistrationDataEntry';
import TextRegistrationDataEntry from './TextRegistrationDataEntry';

/**
 * @typedef {(value: string) => Promise<void> | void} OnSaveCallback
 *
 * Tipo que representa uma entrada de dado cadastral
 * @typedef {Object} RegistrationDataEntry
 * @property {string} label
 * @property {import('react').ReactNode} value
 * @property {boolean?} editable
 * @property {OnSaveCallback?} onSave
 *
 * Props recebidas pelo componente
 * @typedef {Object} GenericRegistrationDataProps
 * @property {RegistrationDataEntry[]} data
 */

/**
 * @param {GenericRegistrationDataProps} props
 */
const GenericRegistrationData = ({ data }) => {
  /**
   * Dados cadastrais separados em duas colunas
   * @type {{firstColumn: RegistrationDataEntry[], secondColumn: RegistrationDataEntry[]}} byColumns
   */
  const byColumns = data.reduce((acc, current, index) => {
    if (index % 2 === 0) {
      acc.firstColumn.push(current);
    } else {
      acc.secondColumn.push(current);
    }
    return acc;
  }, {
    firstColumn: [],
    secondColumn: [],
  });

  /**
   * Função que renderiza uma entrada dos dados cadastrais
   * @param {RegistrationDataEntry} entry
   */
  const renderEntry = ({
    label, value, editable, onSave,
  }) => {
    let actualValue = value;

    if (Array.isArray(value) && value.length === 1) {
      [actualValue] = value;
    }

    if (Array.isArray(actualValue)) {
      return <ListRegistrationDataEntry key={label} label={label} value={actualValue} />;
    }

    return (
      <TextRegistrationDataEntry
        key={label}
        label={label}
        value={actualValue}
        editable={editable}
        onSave={onSave}
      />
    );
  };

  return (
    <div className="registration-data-container">
      <ul>
        <div>
          {byColumns.firstColumn.map(renderEntry)}
        </div>
        <div>
          {byColumns.secondColumn.map(renderEntry)}
        </div>
      </ul>
    </div>
  );
};

GenericRegistrationData.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: array.isRequired,
};

export default GenericRegistrationData;

import styled from 'styled-components';

import { colors, easing, typograph } from '~/styles';

const { gray, support } = colors;

const { basic } = easing;

const { size, weight } = typograph;

export const Popup = styled.div`
  top: 0;
  opacity: 0;
  position: absolute;
  min-width: 14.8rem;
  box-shadow: .0rem .0rem .4rem .1rem rgba(96, 96, 96, 0.25);
  transition: all 0.3s ${basic};
  border-radius: .4rem;
  pointer-events: none;
  background-color: ${gray._000};

  & > div {
    padding: 1.6rem;

    & > div {
      label {
        font-weight: ${weight.regular};
      }

      &:not(:first-of-type) {
        margin-top: 1.2rem;
      }
    }
  }

  & > button {
    color: ${gray._500};
    width: 100%;
    display: flex;
    padding: .8rem;
    font-size: ${size.s1};
    border-top: .1rem solid ${gray._200};
    font-weight: ${weight.semiBold};
    line-height: ${size.s3};
    justify-content: center;
  }
`;

export const View = styled.button`
  width: fit-content;
  position: relative;
  justify-content: center;

  & > svg path {
    transition: all 0.3s ${basic};
  }

  &:hover > svg path {
    fill: ${support._300};
  }

  ${Popup} {
    transform: translateX(-50%);
  }

  :focus-within {
    & > svg path {
      fill: ${support._300};
    }

    ${Popup} {
      top: 2.4rem;
      opacity: 1;
      pointer-events: all;
    }
  }
`;

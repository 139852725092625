export const head = (t) => ({
  diligencia: [
    {
      size: "xxs",
      label: t("table.0"),
    },
    {
      size: "sm",
      label: t("table.1"),
    },
    {
      label: t("table.2"),
    },
    {
      label: t("table.3"),
    },
    {
      label: t("table.4"),
    },
    {
      size: "xxs",
      label: "",
    },
  ],

  simplificada: [
    {
      size: "xxs",
      label: t("table.0"),
    },
    {
      size: "sm",
      label: t("table.1"),
    },
    {
      size: "lg",
      label: t("table.2"),
    },
    {
      label: "",
      align: "right",
    },
  ],

  cadastral: [
    {
      size: "xxs",
      label: t("table.0"),
    },
    {
      size: "lg",
      label: t("table.1"),
    },
    // {
    //   size: 'sm',
    //   label: t('table.5'),
    // },
    {
      size: "lg",
      label: t("table.2"),
    },
    {
      label: "",
      align: "right",
    },
  ],

  batches: [
    {
      size: "xs",
      label: t("table.8"),
    },
    {
      size: "xs",
      label: t("table.3"),
    },
    {
      size: "xs",
      label: t("table.1"),
    },
    {
      size: "lg",
      label: t("table.6"),
    },
    {
      size: "xxs",
      label: t("table.7"),
      align: "right",
    },
    {
      size: "xxs",
      label: t("table.0"),
      align: "right",
    },
  ],
});

export const title = (t) => ({
  diligencia: t("evaluation.title.label"),
  simplificada: t("simplified.title.label"),
  cadastral: t("cadastral.title.label"),
  batches: t("batches.title.label"),
});

export const headings = (t) => [
  // {
  // route: 'simplificada',
  //label: t('simplified.heading'),
  //},
  {
    route: "cadastral",
    label: t("cadastral.heading"),
  },
  {
    route: "diligencia",
    label: t("evaluation.heading"),
  },
  {
    route: "batches",
    label: t("batches.heading"),
  },
];

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import {
  number, string, bool, func,
} from 'prop-types';

import { Modal, TableRow } from '~/components';
import PopupViewer from '~/components/PopupViewer';
import PopupWrapper from '~/components/PopupWrapper';
import useItemMarkedFlag from '~/hooks/useItemMarkedFlag';

import { useResultData } from '../../../resultContext';
import {useSelectRisks} from "~/views/Search/Response/Diligence2/services/selectRisksContext";

const DefaultRiskItem = ({
  id,
  confirmed,
  coluna1,
  coluna2,
  coluna3,
  popup,
  subjectPrefix,
  showToggleModal,
  showReasonModal,
  confirmed_change_reason,
  riskSourceID,
  themeID,
  hasTooltip
}) => {
  const popupWrapperRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => popupWrapperRef.current,
  });

  const [showPopup,
    setShowPopup] = useState(false);
  const { id: processID } = useParams();
  const { seem, entityNames } = useResultData();
  const { t } = useTranslation('diligence');
  const { markedFlagColumn } = useItemMarkedFlag(id);
  const { selectedRisks, setSelectedRisks, isProcess, isMedia } = useSelectRisks();
  
  const [ isChecked, setIsChecked ] = useState(false);

  const parsedPopup = useMemo(() => (popup ? JSON.parse(popup) : null), [popup]);

  const parseColumn = (data) => data?.trim() ?? null;
  
  const handleCheck = useCallback((value, evt) => {
    if (evt)
      setSelectedRisks([...selectedRisks, value])
    else
      setSelectedRisks(selectedRisks.filter((item) => item !== value));
    },
    [ isChecked, selectedRisks, setSelectedRisks ],
  );

  let checkboxColumn;
  if (isProcess(subjectPrefix) || isMedia(subjectPrefix)) {
    checkboxColumn = {
      type: 'CHECKALL',
      payload: {
        value: {value: id, state: isChecked},
        action: handleCheck,
      }
    }
  } else {
    checkboxColumn = false;
  }
    
    
  useEffect(() => {
    setIsChecked(selectedRisks.includes(id))
  }, [selectedRisks]);
  
  
  return (
    <>
      <TableRow columns={[
        checkboxColumn,
        {
          payload: parseColumn(coluna1),
          type: 'TEXT',
        },
        {
          payload: parseColumn(coluna2),
          type: isProcess(subjectPrefix) || hasTooltip ? 'TOOLTIP' : 'TEXT',
        },
        {
          payload: parseColumn(coluna3),
          type: 'LINKMEDIA',
        },
        {
          type: 'TOGGLE',
          payload: {
            value: confirmed,
            action: {
              toggle () {
                if (seem?.data?.id) {
                  return alert.error(t('toggle.alerts.finalized'));
                }

                showToggleModal({
                  id,
                  themeId: themeID,
                  riskId: riskSourceID,
                  active: confirmed,
                  reason: confirmed_change_reason,
                });
              },
              edit: confirmed_change_reason ? () => {
                showReasonModal({
                  id,
                  themeId: themeID,
                  riskId: riskSourceID,
                  active: confirmed,
                  reason: confirmed_change_reason,
                });
              } : null,
            },
          },
        },
        markedFlagColumn,
        (() => {
          const types = [];
          if (popup && popup !== 'null') {
            types.push('FILE');
          }
          if (subjectPrefix) {
            types.push('ADD_CLIPBOARD');
          }
          return {
            type: 'BUTTONS',
            payload: {
              types,
              process_id: processID,
              result_id: id,
              subject: subjectPrefix ? `${subjectPrefix} - ${parseColumn(coluna1)}` : null,
              fileAction: {
                onClick: () => {
                  if (parsedPopup.type !== 'link') {
                    setShowPopup(true);
                  } else {
                    window.open(parsedPopup.href);
                  }
                },
              },
            },
          };
        })(),
      ]}
      />
      {showPopup && !! parsedPopup && (
        <Modal
          show
          title={(() => {
            if (parsedPopup.type === 'processo_judicial') {
              return [entityNames[0], parsedPopup?.data?.Number].join(' - Processo: ');
            } else if (parsedPopup.type === 'legacySurvey') {
              return [coluna1];
            }
            return [coluna1, coluna2].join(' - ');
          })()}
          onShow={setShowPopup}
          hasActions={false}
          size="lg"
          hasPrinterIcon={parsedPopup.type === 'pdf' ? false : true}
          onClickPrinter={handlePrint}
        >
          <PopupWrapper ref={popupWrapperRef}>
            <PopupViewer popup={parsedPopup} />
          </PopupWrapper>
        </Modal>
      )}
    </>
  );
};

DefaultRiskItem.propTypes = {
  id: number.isRequired,
  confirmed: bool.isRequired,
  coluna1: string,
  coluna2: string,
  coluna3: string,
  popup: string,
  subjectPrefix: string,
  showToggleModal: func.isRequired,
  showReasonModal: func.isRequired,
  confirmed_change_reason: string,
  riskSourceID: number.isRequired,
  themeID: number.isRequired,
  hasTooltip: bool
};

DefaultRiskItem.defaultProps = {
  coluna1: '-',
  coluna2: '-',
  coluna3: '-',
  popup: null,
  confirmed_change_reason: null,
  subjectPrefix: null,
  hasTooltip: false
};

export default DefaultRiskItem;

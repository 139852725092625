import React from 'react';
import { useTranslation } from 'react-i18next';

import { compareDesc, format } from 'date-fns';
import { object } from 'prop-types';

import SimpleLoading from '~/components/SimpleLoading';
import { maskMoney } from '~/hooks/helpers';

import Table from './table';

const ProcessoJudicialViewer = ({ data }) => {
  const { t, ready } = useTranslation('popups', { useSuspense: false });

  if (! ready) {
    return <SimpleLoading />;
  }

  const formatDate = (obj) => {
    try {
      return format(new Date(obj), 'dd/MM/yyyy');
    } catch {
      return null;
    }
  };

  const formatValue = (value) => (! value || value < 0 ? t('lawsuits.lawsuit.value.unavailable') : maskMoney(value));

  const formatOtherSubjects = (value) => {
    // eslint-disable-next-line no-param-reassign
    value = value ?? '-';
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value;
  };

  const multilinesReducer = (acc, current) => [...acc, ...current];

  const generateLine = ({
    key, tPrefixKey, formatData, customData, firstColumnProps, secondColumnProps,
  }) => {
    const fcProps = firstColumnProps || {};
    const scProps = secondColumnProps || {};
    const actualData = customData || data;

    let tKey = key;

    if (tPrefixKey) {
      tKey = `${tPrefixKey}.${key}`;
    }

    return [
      {
        text: t(tKey),
        ...fcProps,
      },
      {
        text: (formatData ? formatData(actualData[key]) : actualData[key]) ?? '-',
        ...scProps,
      },
    ];
  };

  const summaryItems = [
    generateLine({ key: 'Number', tPrefixKey: 'lawsuits.lawsuit', firstColumnProps: { size: 'xs' } }),
    generateLine({
      key: 'NoticeDate', tPrefixKey: 'lawsuits.lawsuit', formatData: formatDate, firstColumnProps: { size: 'xs' },
    }),
    generateLine({ key: 'CourtName', tPrefixKey: 'lawsuits.lawsuit', firstColumnProps: { size: 'xs' } }),
    generateLine({ key: 'State', tPrefixKey: 'lawsuits.lawsuit', firstColumnProps: { size: 'xs' } }),
    generateLine({ key: 'JudgingBody', tPrefixKey: 'lawsuits.lawsuit', firstColumnProps: { size: 'xs' } }),
    generateLine({ key: 'CourtType', tPrefixKey: 'lawsuits.lawsuit', firstColumnProps: { size: 'xs' } }),
    generateLine({ key: 'Nature', tPrefixKey: 'lawsuits.lawsuit', firstColumnProps: { size: 'xs' } }),
  ];

  const petitions = data.Petitions?.map((petition) => [
    generateLine({
      key: 'Type', tPrefixKey: 'lawsuits.lawsuit.petition', customData: petition, firstColumnProps: { size: 'xxs' },
    }),
    generateLine({
      key: 'CreationDate', tPrefixKey: 'lawsuits.lawsuit.petition', customData: petition, formatData: formatDate, firstColumnProps: { size: 'xxs' },
    }),
  ]).reduce(multilinesReducer, []) || [];

  const generateParties = (type, isNeutral) => {
    const linesPerParty = data.Parties?.filter(
      (party) => party.Polarity.toLowerCase() === type.toLowerCase(),
    )
      .map((party) => {
        const lines = [
          generateLine({
            key: 'Name', tPrefixKey: 'lawsuits.lawsuit.parties', customData: party, firstColumnProps: { size: 'xs' },
          }),
          generateLine({
            key: 'Doc', tPrefixKey: 'lawsuits.lawsuit.parties', customData: party, firstColumnProps: { size: 'xs' },
          }),
        ];

        if (isNeutral) {
          lines.push(generateLine({
            key: 'SpecificType', tPrefixKey: 'lawsuits.lawsuit.parties', customData: party.PartyDetails, firstColumnProps: { size: 'xs' },
          }));
        }

        return lines;
      }) || [];
    return linesPerParty.reduce(multilinesReducer, []);
  };

  const passiveParties = generateParties('passive');
  const activeParties = generateParties('active');
  const neutralParties = generateParties('neutral', true);

  const updates = data.Updates?.map((update) => [
    generateLine({
      key: 'Content', tPrefixKey: 'lawsuits.lawsuit.updates', customData: update, firstColumnProps: { size: 'xxs' },
    }),
    generateLine({
      key: 'PublishDate', tPrefixKey: 'lawsuits.lawsuit.updates', customData: update, formatData: formatDate, firstColumnProps: { size: 'xxs' },
    }),
  ]).reduce(multilinesReducer, []) || [];

  const decisions = [
    ...(data.CourtHearings || []),
    ...(data.Decisions || []),
  ]
    .map((item) => ({
      Type: item.Type || t('lawsuits.lawsuit.decision'),
      What: item.Minute || item.DecisionContent,
      When: item.HearingDate || item.DecisionDate,
    }))
    .sort((a, b) => compareDesc(new Date(a.When), new Date(b.When)))
    .map((item) => [
      generateLine({
        key: 'Type', tPrefixKey: 'lawsuits.lawsuit.decisions', customData: item, firstColumnProps: { size: 'xxs' },
      }),
      generateLine({
        key: 'When', tPrefixKey: 'lawsuits.lawsuit.decisions', customData: item, formatData: formatDate, firstColumnProps: { size: 'xxs' },
      }),
      generateLine({
        key: 'What', tPrefixKey: 'lawsuits.lawsuit.decisions', customData: item, firstColumnProps: { size: 'xxs' },
      }),
    ])
    .reduce(multilinesReducer, []);

  return (
    <>
      <Table columns={2} title={t('lawsuits.lawsuit.titles.process')} items={summaryItems} />
      {petitions.length > 0 ? <Table columns={2} title={t('lawsuits.lawsuit.titles.petitions')} items={petitions} /> : null}
      <Table
        columns={2}
        items={[generateLine({
          key: 'Value', tPrefixKey: 'lawsuits.lawsuit.value', formatData: formatValue, firstColumnProps: { size: 'xs' },
        })]}
      />
      <Table
        columns={2}
        title={t('lawsuits.lawsuit.titles.subject')}
        items={[
          generateLine({ key: 'MainSubject', tPrefixKey: 'lawsuits.lawsuit', firstColumnProps: { size: 'xs' } }),
          generateLine({
            key: 'OtherSubjects', tPrefixKey: 'lawsuits.lawsuit', formatData: formatOtherSubjects, firstColumnProps: { size: 'xs' },
          }),
        ]}
      />
      {(passiveParties.length > 0 || activeParties.length > 0 || neutralParties.length > 0) ? <Table columns={2} title={t('lawsuits.lawsuit.titles.parties')} items={[]} /> : null}
      {passiveParties.length > 0 ? <Table columns={2} title={t('lawsuits.lawsuit.titles.passiveParty')} items={generateParties('passive')} /> : null }
      {activeParties.length > 0 ? <Table columns={2} title={t('lawsuits.lawsuit.titles.activeParty')} items={generateParties('active')} /> : null }
      {neutralParties.length > 0 ? <Table columns={2} title={t('lawsuits.lawsuit.titles.neutralParty')} items={generateParties('neutral', true)} /> : null }
      {updates.length > 0 ? <Table columns={2} title={t('lawsuits.lawsuit.titles.updates')} items={updates} /> : null}
      {decisions.length > 0 ? <Table columns={2} title={t('lawsuits.lawsuit.titles.decisions')} items={decisions} /> : null}
    </>
  );
};

ProcessoJudicialViewer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object.isRequired,
};

export default ProcessoJudicialViewer;

import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox } from '~/components';
import { useMe } from '~/services';
import { useApi } from "~/hooks";

import { EmailNotification } from './style';

const NotificationSettings = () => {
  const { t } = useTranslation('settings');

  const { user } = useMe();
  const [isSubmitting, setSubmitting] = useState(false);
  const alert = useAlert();
  const api = useApi();

  const [selectedValue, setSelectedValue] = useState(user?.usuario?.notifyByEmail === 1);

  const onClick = async () => {
    setSubmitting(true);

    const payload = { notifyByEmail: selectedValue };

    try {
      await api().post('/user/manageEmailNotification', payload);

      alert.success(t('notification.success'));
    } catch (ex) {
      alert.error(ex.message);
    }
    setSubmitting(false);
  };

  return (
    <EmailNotification>
      <Checkbox
        key={1}
        id={1}
        label={t('notification.checkBox')}
        onChange={(e) => {
          setSelectedValue(e.target.checked);
        }}
        defaultChecked={selectedValue}
      />
      <Button
        loading={isSubmitting}
        disabled={selectedValue === user?.usuario?.notifyByEmail}
        label={t('notification.button')}
        onClick={onClick}
      />
    </EmailNotification>
  );
};

export default NotificationSettings;

import React, { memo, useState, useEffect } from "react";

import { Handle } from "react-flow-renderer";
import {
  NodeContainer,
  HeaderContainer,
  ButtonsContainer,
  InfoContainer,
} from "./styles";
import { User, Briefcase } from "react-feather";
import {
  HiOutlineHome,
  HiOutlineInformationCircle,
  HiCheck,
  HiOutlinePlusCircle,
} from "react-icons/hi";
import { masked } from "~/hooks/helpers";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";
import { useMe } from "~/services";


export default memo(({ data }) => {
  const { user } = useMe();
  const [showInfo, setShowInfo] = useState(false);
  const [picked, setPicked] = useState(false);
  const [expandPartners, setExpandedPartners] = useState(data.expandedActive);
  const { t } = useTranslation("search");
  const alert = useAlert();

  function ClipboardButton(props) {
    function handleClick() {
      navigator.clipboard.writeText(JSON.stringify(props));
      alert.success(t("cadastral.data_copied_successfully"));
    }

    return (
      <button
        style={{
          height: "30px",
          margin: "15px 0",
          width: "200px",
          borderRadius: "3px",
          background: "#F6871F",
          color: "#FFFFFF",
        }}
        onClick={handleClick}
      >
        {t("cadastral.clipboard")}
      </button>
    );
  }

  return (
    <>
      <NodeContainer>
        <HeaderContainer>
          <span>
            {data.type === "PJ" ? (
              <Briefcase color="white" size={32} />
            ) : (
              <User color="white" size={32} />
            )}
          </span>

          <div>
            <h4>{data.label}</h4>
            <h5>
              {data.document.length > 11 ? "CNPJ:" : "CPF"}{" "}
              {masked(data.document)}
            </h5>
          </div>
        </HeaderContainer>

        {showInfo && (
          <InfoContainer>
            <h2>{t("cadastral.information")}</h2>
            {data.person ? (
              <>
                {data.person.addresses.length > 0}
                {
                  <p>
                    <strong>{t("cadastral.address")}: </strong>
                    {data.person.addresses[0].place}{" "}
                    {data.person.addresses[0].street}{" "}
                    {data.person.addresses[0].number},{" "}
                    {data.person.addresses[0].city}
                  </p>
                }
                <p>
                  <strong>{t("cadastral.age")}: </strong>
                  {data.person.age} anos
                </p>
                <p>
                  <strong>{t("cadastral.gender")}: </strong>
                  {data.person.gender}
                </p>
                <p>
                  <strong>{t("cadastral.date_birth")}: </strong>
                  {data.person.birthday}
                </p>
                <p>
                  <strong>{t("cadastral.nationality")}: </strong>
                  {data.person.nationality.pais}
                </p>
                <p>
                  <strong>{t("cadastral.email")}:</strong>
                </p>
                {data.person.emails?.map(({ email }) => {
                  return <p key={email}>{email}</p>;
                })}
                <p>
                  <strong>{t("cadastral.phones")}:</strong>
                </p>
                {data.person.phones?.map(
                  ({ country_code, area_code, number }) => {
                    return (
                      <p key={number}>
                        +{country_code} ({area_code}) {number}
                      </p>
                    );
                  }
                )}
                <ClipboardButton
                  index={1}
                  address={data?.person?.addresses[0]}
                  age={data?.age}
                  gender={data?.gender}
                  birthday={data?.birthday}
                  nationality={data?.person?.nationality?.pais}
                  phones={data?.person?.phones}
                  email={data?.person?.emails}
                />
              </>
            ) : (
              <>
                {data.company.email && (
                  <p>
                    <strong>{t("cadastral.email")}: </strong>
                    {data.company.email}
                  </p>
                )}
                <p>
                  <strong>{t("cadastral.foundation_date")}: </strong>
                  {data.company.founded_at}
                </p>
                <p>
                  <strong>{t("cadastral.legal_nature")}: </strong>
                  {data.company.legal_nature}
                </p>
                <p>
                  <strong>{t("cadastral.main_activity")}: </strong>
                  {data.company.main_cnae?.descricao}
                </p>
                <p>
                  <strong>{t("cadastral.site")}: </strong>
                  {data.company.website}
                </p>
                <ClipboardButton
                  index={1}
                  name={data.name}
                  website={data?.company?.website}
                  mainActivity={data?.company?.main_cnae?.descricao}
                  foundedAt={data?.company?.founded_at}
                  legalNature={data?.company?.legal_nature}
                  email={data?.company?.email}
                />
              </>
            )}
          </InfoContainer>
        )}

        <ButtonsContainer>
          {data.document < 11 && (
            <button>
              <div>
                <HiOutlineHome color="#cccccc" size={32} />
              </div>
              <p>{t("cadastral.relatives")}</p>
            </button>
          )}
          {!user?.isVisualizador() && (
            <button
              onClick={() => {
                data.expandPartners(
                  data.id.toString(),
                  data.document,
                  data.graphId,
                  data.expandedActive
                );
                setExpandedPartners(!expandPartners);
              }}
            >
              <div>
                {expandPartners ? (
                  <HiOutlinePlusCircle
                    color="#2B5D7E"
                    size={32}
                  ></HiOutlinePlusCircle>
                ) : (
                  <HiOutlinePlusCircle
                    color="#cccccc"
                    size={34}
                  ></HiOutlinePlusCircle>
                )}
              </div>
              <p>{t("cadastral.expand")}</p>
            </button>
          )}
          <button onClick={() => setShowInfo(!showInfo)}>
            <div>
              {showInfo ? (
                <HiOutlineInformationCircle color="#F6871F" size={32} />
              ) : (
                <HiOutlineInformationCircle color="#cccccc" size={32} />
              )}
            </div>
            <p>{t("cadastral.data")}</p>
          </button>
          {!user?.isVisualizador() && (
            <button
              onClick={() => {
                data.chooseEntity({
                  id: data.id,
                  name: data.label,
                  document: data.document,
                  type: data.type,
                });
                setPicked(!picked);
              }}
            >
              <div>
                {picked ? (
                  <HiCheck color="#2B5D7E" size={32}></HiCheck>
                ) : (
                  <HiCheck color="#cccccc" size={34}></HiCheck>
                )}
              </div>
              <p>{t("cadastral.select")}</p>
            </button>
          )}
        </ButtonsContainer>

        <Handle
          type="source"
          position="bottom"
          id="a"
          style={{ bottom: 0, background: "#555" }}
        />
      </NodeContainer>
    </>
  );
});

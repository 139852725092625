import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import {SimpleLoading} from "~/components";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<SimpleLoading size={"lg"} />}>
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root'),
);

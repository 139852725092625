import React from 'react';

import { element, string } from 'prop-types';
import styled from 'styled-components';

import { P1 } from '~/components';

const Card = styled.div`
  display: flex !important;
  min-height: 0 !important;
  > div {
    width: 100%;
    padding: 2rem;
  }
`;

const SettingsSection = ({ label, children }) => (
  <>
    <P1>{label}</P1>
    <Card>
      <div>
        {children}
      </div>
    </Card>
  </>
);

SettingsSection.propTypes = {
  label: string.isRequired,
  children: element,
};

SettingsSection.defaultProps = {
  children: null,
};

export default SettingsSection;

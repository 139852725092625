import React, { useState, useEffect, useCallback } from 'react';
import { Edit, Search } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { format, parseISO } from 'date-fns';

import {
  Flag,
  Table,
  Empty,
  Helmet,
  Loading,
  Paginate,
  RiskLevel,
  Container,
  SimpleInput,
  RangeDatePicker,
  AlterValues,
} from '~/components';
import ClientSelect from '~/components/ClientSelect';
import {
  masked, reduce, useFetch, useDebounce,
} from '~/hooks';
import { Actions, HeaderBack, ModalMonitoring } from '~/layout';
import { useMe } from '~/services';

import table from './data';
import { View, Wrapper, ActionsHeader } from './styles';

const Monitoring = () => {
  const { user, group, newGroup } = useMe();

  const [page, onPage] = useState(1);
  const [date, onDate] = useState('');
  const [edit, onEdit] = useState(false);
  const [rows, onRows] = useState([]);
  const [size, onSize] = useState({ value: 50, label: 50 });
  // const [group, onGroup] = useState({
  //   value: user.hierarquia[0].id,
  //   label: user.hierarquia[0].cliente,
  // });
  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }
  const [search, onSearch] = useState('');
  const [endDate, onEndDate] = useState('');

  const jump = useDebounce(page);
  const debounced = useDebounce(search);

  const { t } = useTranslation('monitoring');

  const formated = {
    end: endDate ? format(endDate, 'yyyy-MM-dd') : '',
    start: date ? format(date, 'yyyy-MM-dd') : '',
  };

  const [sorting, onSorting] = useState({});

  const { data, mutate } = useFetch({
    url: `/monitoring?page=${jump}${group.value === 0 ? '' : `&clientId=${group.value}`}&pageSize=${size.value}${
      formated?.start && `&dataInicial=${formated.start}`
    }${formated?.end && `&dataFinal=${formated.end}`}${
      debounced ? `&searchField=${debounced}` : ''
    }&order=${
      JSON.stringify(sorting)
        .replace('0', 'entity')
        .replace('1', 'document')
        .replace('2', 'diligence')
        .replace('3', 'workflow')
        .replace('4', 'frequency')
        .replace('7', 'status')
    }`,
  });

  /**
   * Create row
   * monitoring table.
   */
  const row_monitoring = useCallback(async () => {
    let row = [];

    if (data !== undefined && data.length <= 0) {
      row = [];

      return;
    }

    row = data?.data?.map((item) => {
      const actions = [
        {
          icon: <Edit />,
          action: (e) => {
            e.stopPropagation();

            onEdit({
              id: item.monitoring_id,
              show: true,
            });
          },
        },
      ];

      return {
        id: item.id,
        items: [
          {
            label: item.entityName,
            isBold: true,
          },
          {
            label: item?.document ? reduce(masked(item?.document), 10) : '-',
            title: item?.document ? masked(item?.document) : '-',
          },
          {
            label: item?.show_id ? item?.show_id : '-',
          },
          {
            label: item.workflows ? reduce(item.workflows, 20) : '-',
            title: item.workflows,
          },
          {
            label: item.frequencyName,
          },
          {
            label: format(parseISO(item.dataabertura), 'dd/MM/yyyy'),
          },
          {
            label: (

              <RiskLevel
                id={item.risk?.probabilidade_id}
                risk={Math.round(item?.risk?.risk)}
                indicator={item.risk?.variation}
                hasIndicator
              />

            ),
          },
          {
            label: (
              <AlterValues
                status={item?.changedValue}
              />
            ),
          },
          {
            label: item?.new_status?.name ? (

              <Flag status={item?.new_status?.name} />

            ) : (
              ' '
            ),
          },
          {
            className: 'especial',
            label: <Actions hasError={false} actions={actions} />,
            isArrow: true,
            dataLabel: ' ',
          },
        ],
      };
    });

    onRows(row);
  }, [data]);

  useEffect(() => {
    row_monitoring();
  }, [row_monitoring]);

  return (
    <View>
      <Helmet title={t('helmet')} />

      <Container>
        <HeaderBack title={t('title')} hasArrow={false}>
          <ActionsHeader>
            <ClientSelect
              user={user}
              value={group}
              onChange={(val) => newGroup(val)}
              placeholder={t('group')}
            />

            <RangeDatePicker
              date={date}
              onDate={onDate}
              endDate={endDate}
              onEndDate={onEndDate}
            />

            <SimpleInput
              isSearch
              name="search"
              icon={<Search />}
              value={search}
              onValue={onSearch}
              onChange={(e) => onSearch(e.target.value)}
              appearance="secondary"
              placeholder={t('search')}
            />
          </ActionsHeader>
        </HeaderBack>

        <Wrapper>
          {data?.data ? (
            <>
              {data?.data?.length > 0 ? (
                <Table
                  body={rows}
                  route="/pesquisa/diligencia"
                  onBody={onRows}
                  heading={table(t, onSorting)}
                  breakpoint="md"
                />
              ) : (
                <Empty />
              )}
            </>
          ) : (
            <Loading />
          )}

          {data?.data?.length > 0 && (
            <Paginate
              size={size}
              pages={data?.last_page}
              total={data?.total}
              onSize={onSize}
              perPage={data?.data?.length}
              className="m-auto m-t-48"
              onPaginate={onPage}
              currentPage={page}
            />
          )}
        </Wrapper>
      </Container>

      <ModalMonitoring
        type="edit"
        show={edit}
        render={mutate}
        onShow={onEdit}
        hasDelete
      />
    </View>
  );
};

export default Monitoring;

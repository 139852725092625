import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { H1 } from '~/components';

import { useResultData } from '../../resultContext';
import { SectionView as View } from '../../styles';
import ItemRiskListEntry from './ItemRiskListEntry';

const ItemRiskList = () => {
  const { id: processID } = useParams();
  const { t } = useTranslation('diligence');

  const { processesItemsRisk, mediasItemsRisk, surveysItemsRisk } = useResultData();

  return (
    (surveysItemsRisk?.length > 0
      || processesItemsRisk?.results > 0
      || mediasItemsRisk?.results > 0) && (
        <View>
          <H1>{t('item_risk_list.title')}</H1>
          {
            surveysItemsRisk?.length > 0 && (
              <ItemRiskListEntry
                itemRiskType="survey"
                data={surveysItemsRisk}
                columns={[
                  { payload: t('surveys.title'), type: 'TEXT' },
                  { payload: [surveysItemsRisk?.reduce((total, { alerts }) => total + alerts, 0) ?? 0], type: 'ONLY_ALERT' },
                ]}
              />
            )
          }
          {
            processesItemsRisk?.results > 0 && (
              <ItemRiskListEntry
                showFilters
                fetchUrl={`/process/${processID}/process/results`}
                columns={[
                  { payload: t('judicial_processes_list'), type: 'TEXT' },
                  { payload: [processesItemsRisk?.alerts], type: 'ONLY_ALERT' },
                ]}
              />
            )
          }
          {
            mediasItemsRisk?.results > 0 && (
              <ItemRiskListEntry
                fetchUrl={`/process/${processID}/media/results`}
                columns={[
                  { payload: t('media_processes_list'), type: 'TEXT' },
                  { payload: [mediasItemsRisk?.alerts], type: 'ONLY_ALERT' },
                ]}
              />
            )
          }
        </View>
    ));
};

export default ItemRiskList;

const table = (t, onSorting) => [
  {
    label: t('table.entity'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
    label: t('table.document'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
    label: t('table.evaluation'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
    label: t('table.workflow'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
    label: t('table.frequency'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
    label: t('table.date'),
  },
  {
    label: t('table.risk'),
  },
  {
    label: t('table.alteration'),
  },
  {
    label: t('table.status'),
    isSort: true,
    onSorting,
  },
  {
    label: '',
    align: 'right',
    isMulti: true,
  },
];

export default table;

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { arrayOf, number, string } from 'prop-types';

import { numberMask, reduce } from '~/hooks';
import { colors, typograph } from '~/styles';

import View from './styles';

const { chart, gray } = colors;
const { family, size } = typograph;

const HorizontalBar = ({
  categories, data, height,
}) => {
  const total = data.reduce((acc, curr) => (acc + curr), 0);

  const { t } = useTranslation('components');

  const series = () => [
    {
      name: t('charts.total'),
      data,
    },
  ];

  const options = () => ({
    color: chart._100,
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barheight: data.length < 5 ? 5 * data.length : 17,
        dataLabels: {
          position: 'end',
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        fontSize: size.s1,
        fontFamily: family,
        colors: [gray._500],
      },
      dropShadow: {
        enabled: false,
      },
      formatter: (value) => `${numberMask(value || 0)} (${Math.round((value / total) * 100)}%)`,
      offsetX: 0,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories,
    },
    yaxis: {
      labels: {
        maxWidth: 150,
        style: {
          colors: [gray._600],
          fontSize: size.s2,
          fontFamily: family,
        },
        formatter: (val) => reduce(val, 20),
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
  });

  return (
    <View id="chart">
      <ReactApexChart
        options={options()}
        series={series()}
        type="bar"
        height={height}
        width="95%"
        barheight=".8rem"
      />
    </View>
  );
};

HorizontalBar.propTypes = {
  categories: arrayOf(string),
  data: arrayOf(number),
  height: number,
};

HorizontalBar.defaultProps = {
  categories: [],
  data: [],
  height: 250,
};

export default HorizontalBar;

import styled, { css } from 'styled-components';

import { Layout, H6,  SimpleLoading } from '~/components';
import { breakpoints, colors, typograph } from '~/styles';
import { rgba } from '~/hooks';

const { weight, size } = typograph;

const { gray, status, primary } = colors;
export const View = styled(Layout)`
  margin-bottom: 6.4rem;

  b {
    color: ${gray._400};
    font-weight: ${weight.bold};
  }

  @media screen and (max-width: ${breakpoints.md}) {
    .header {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      white-space: nowrap;

      & > div,
      & > a {
        margin: 0 0 1.6rem !important;
        display: flex;
        justify-content: flex-start;
        font-size: ${size.s3};

        :nth-of-type(even) {
          padding-left: 3.2rem;
        }
      }
    }
  }
`;
export const ModalView = styled.div`
  display: flex !important;
  flex-direction: column;

  & > span {
    margin: 0 auto 2rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, '.1')};

    svg {
      width: 4rem;
      height: 4rem;
      stroke: ${primary._000};
    }
  }

  & > ${H6} {
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
    text-align: left !important;
  }

  & > p {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      margin-top: 2.4rem;
    }
  }
  &.modal-sm{
    min-height: 20rem;
  }
  &.modal-md{
    min-height: 26rem;
  }
  &.modal-lg {
    min-height: 32rem;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 35rem 35rem;
  grid-row-gap: 50px;
  justify-content: space-around;
`

export const ActionsHeader = styled.div`
  width: fit-content !important;
  display: flex;
  align-items: center;
  margin-left: auto;

  & > div {
    width: 25rem;

    &:not(:first-of-type) {
      margin-left: 1.6rem;
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    & > div {
      width: 22rem;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    grid-column: span 2;
    margin-top: 3.2rem;
    flex-direction: column;
    top: 6.4rem;
    display: flex;
    padding: 1rem;
    box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193, 193, 193, 0.29);
    align-items: center;
    border-radius: 0.4rem;
    background-color: ${gray._000};
    width: 100% !important;

    & > div {
      margin: 0.8rem 0 !important;
      width: 100%;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 30rem);

  ${SimpleLoading} {
    flex: 1;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    overflow: hidden;
  }
`;

export const Icon = styled.div`
  svg {
    width: 3rem !important;
    height: 3rem !important;
  }
`;

export const ActionMonitoring = styled.span`
  display: flex;

  & > svg {
    width: 3rem !important;
    stroke: ${status.success};
  }

  ${({ actived }) => (actived?.deleted_by
    ? css`
          cursor: not-allowed;
          & > svg * {
            stroke: ${gray._300};
          }
        `
    : actived
        && css`
          & > svg * {
            stroke: ${primary._000};
          }
        `)}
`;

export const ActionAccordion = styled.span`
  display: flex;

  & > svg {
    width: 3rem !important;
    stroke: ${status.success};
  }

  ${() => (css`
    & > svg * {
      stroke: ${gray._600};
    }
  `)}
`;

/* eslint-disable react-hooks/rules-of-hooks */
import { useMe } from "~/services";
import NotificationSettings from "~/views/Administration/Settings/NotificationSettings";
import UsageSettings from "~/views/Administration/Settings/UsageSettings";
import RiskSettings from "~/views/Administration/Settings/RiskSettings";
import ConflictingSettings from "~/views/Administration/Settings/ConflictingSettings";
import GroupSettings from "~/views/Administration/Settings/GroupSettings";
import ProcessAutomationSettings from "~/views/Administration/Settings/ProcessAutomationSettings";
import PartnerSettings from "~/views/Administration/Settings/PartnerSettings";
import TagSettings from "~/views/Administration/Settings/TagSettings";

const sections = (t) => {
  const { user } = useMe();
  return [
    {
      show: user?.hierarquia?.length > 1,
      label: t("group.label"),
      content: GroupSettings,
    },
    {
      show: user?.usuario?.perfil?.id >= 3,
      label: t("partner.label"),
      content: PartnerSettings,
    },
    {
      show: user?.usuario?.perfil?.id >= 3,
      label: t("process_automation.label"),
      content: ProcessAutomationSettings,
    },
    {
      show: user?.usuario?.perfil?.id >= 1,
      label: t("notification.label"),
      content: NotificationSettings,
    },
    {
      show: user?.usuario?.perfil?.id >= 4,
      label: t("risk.label"),
      content: RiskSettings,
    },
    {
      show: user?.usuario?.perfil?.id >= 3,
      label: t("conflict.label"),
      content: ConflictingSettings,
    },
    {
      show: user?.usuario?.perfil?.id === 4,
      label: t("usage.label"),
      content: UsageSettings,
    },
    {
      show: user?.usuario?.perfil?.id >= 4,
      label: t("tag.label"),
      content: TagSettings,
    },
  ];
};

export default sections;

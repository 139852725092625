import styled, { css } from 'styled-components';

import { colors, typograph } from '~/styles';

const { weight } = typograph;
const { risks, status, primary, gray } = colors;

export const View = styled.span`
  font-weight: ${weight.bold};
  white-space: nowrap;

  ${({ type }) => (type === 4) && css`
    color: ${risks.critical} !important;
  `}
  
  ${({ type }) => (type === 3) && css`
    color: ${risks.high} !important;
  `}

  ${({ type }) => type === 2 && css`
    color: ${risks.medium} !important;
  `}

  ${({ type }) => (type === 1 || type === 0 || type === null) && css`
    color: ${risks.low} !important;
  `}

  ${({ hasIndicator }) => hasIndicator && css`
    display: flex;
    align-items: center;
  `}

  .percent_risk {
    color: ${gray._500};
  }
`;

export const Indicator = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: center;

  & > svg {
    width: 20px;
    height: 20px;
    transition: all .3s ease-in-out;
  }

  ${({ indicator }) => indicator < 0 && css`
    & > svg {
      stroke: ${status.error};
      transform: rotate(-90deg);
    }
  `}

  ${({ indicator }) => indicator === 0 && css`
    & > svg {
      stroke: ${gray._400};
    }
  `}

  ${({ indicator }) => indicator > 0 && css`
    & > svg {
      stroke: ${status.success};
      transform: rotate(90deg);
    }
  `}
`;

import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import colors from '~/styles/colors';
import {Accordion, Button, Input, Modal, SimpleLoading, TableRow, Upload} from '~/components';
import { formatErrorMessage, useApi, useFetch } from '~/hooks';
import { useMe } from '~/services';
import { Form } from '@unform/web';

import { useResultData } from '../../resultContext';
import { ThemeAnalysis as ThemeAnalysisNote, Anex, ModalView } from '../../styles';

const ThemeAnalysis = ({themeID, translation: tActionPlan}) => {
  const { id: processID } = useParams();
  const [showAnexModal, setShowAnexModal] = useState(false);
  const [value, setValue] = useState("");
  const [valueCleaned, setValueCleaned] = useState("");
  const [loading, setLoading] = useState(false);
  const [newAnalysis, setNewAnalysis] = useState("");
  const [url, setURL] = useState(null);
  const [urlAttachment, setURLAttachment] = useState(null);
  const observationRef = useRef();
  const observationAnex = useRef();
  const { t, i18n } = useTranslation('diligence');
  const { t: tAuth } = useTranslation('auth');
  const { user } = useMe();
  const alert = useAlert();
  const api = useApi();
  const { addMutation } = useResultData();
  const { status } = colors;
  const {
    data: loadedObservation,
    mutate,
    isValidating: isValidatingLoadedObservation,
  } = useFetch({url});

  const {
    data: attachmentData,
    mutate: mutateAttachment,
    isValidating: isValidatingAttachment,
  } = useFetch({ url: urlAttachment });
  
  const languageNew = i18n.language;

  const [renderCkEditor, setRenderCkEditor] = useState(false);

  const configCkEditor = { 
    language: languageNew === 'pt'? 'pt-br' : languageNew,
    toolbar: {
      items: [
        'undo', 
        'redo',
        '|',
        'heading', 
        '|',
        'aligment', '|',
        'bold', 
        'italic',  
        'numberedList', 
        'outdent', 
        'indent',
        'removeFormat',
      ],
      height:'500',
      shouldNotGroupWhenFull: true
    },
  };
  useEffect(() => {
    if(value){
      let dataEdited = value.replace(/<[^>]*>/g, '');
      dataEdited = dataEdited.replace('&nbsp;', ' ');
      setValueCleaned(dataEdited)
    }
    
  },[value])
  useEffect(() => {
    setRenderCkEditor(false);
    setTimeout(() => {
      setRenderCkEditor(true);
    }, 100);
  },[languageNew]);

  useEffect(() => {
    addMutation(mutate);
    addMutation(mutateAttachment);
  }, [addMutation, mutate, mutateAttachment]);

  useEffect(() => {
    setValue(loadedObservation?.data[0]?.information);
  }, [loadedObservation]);

  const handleSubmit = useCallback(async(evt) => {
    let dataEdited = value.replace(/<[^>]*>/g, '');
    dataEdited = dataEdited.replace('&nbsp;', ' ');
    if(dataEdited.length > 2000) {
      alert.error(t('seem.characteres_over_limit'));
      setLoading(false);
    } else {
      try {
      await api().post(`/themes/${themeID}/process/${processID}/notes`, {
          note: value
        });
        alert.success(t('theme_analysis_note.alerts.success'));
      } catch ({ response }) {
        alert.error(
          formatErrorMessage(response, t('theme_analysis_note.alerts.error'),tAuth('expired')),
        );
      } finally {
        setLoading(false);
      }
    }
        
  }, [t, processID, alert, newAnalysis, value, tAuth]);

  const handleAnexSubmit = useCallback(async ({ upload }) => {
    if (! upload) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('attachment', upload);
    try {
      await api().post(`/themes/${themeID}/process/${processID}/attachment`, formData);
      setShowAnexModal(false);
      mutateAttachment();
    } catch ({ response }) {
      alert.error(formatErrorMessage(response, t('action_plans.analysis.messages.upload.error'),tAuth('expired')));
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, processID, t, themeID, tAuth]);

  const onOpen = useCallback((isOpened) => {
    if (isOpened) {
      if (! url) {
        setURL(`/themes/${themeID}/process/${processID}/notes`);
        setURLAttachment( `/themes/${themeID}/process/${processID}/attachment`);
      } else {
        mutate();
        mutateAttachment();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, themeID]);

  const isLoading = isValidatingLoadedObservation || isValidatingAttachment;

  return (
    <>
      <Accordion onChange={onOpen} isInfo={t('theme_analysis.tooltip')} columns={[{ type: 'TEXT', payload: t('theme_analysis.sub_title') }]}>
        {isLoading ? <SimpleLoading /> : (
          <>
            <ThemeAnalysisNote  ref={observationRef} onSubmit={handleSubmit} disabled={user?.isVisualizador()}>
              {renderCkEditor && 
                <div style={{fontSize:"14px"}}>
                    <CKEditor id="teste-ck"
                        editor={ ClassicEditor }
                        data={value}
                        config={configCkEditor}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            let dataEdited = data.replace(/<[^>]*>/g, '');
                            dataEdited = dataEdited.replace('&nbsp;', ' ');

                            setValueCleaned(dataEdited);
                            setValue(data);
                        } }
                    />
                  </div>
              }
              { (2000 - valueCleaned.length) > 0 && (
                <label style={{  color: '#7F859D', fontSize: '1.2rem' }}>{t('seem.missing_characters')} { 2000 - valueCleaned.length}</label>
              )} 
              {(2000 - valueCleaned.length) <= 0 &&(
                <label  style={{ color: status.error, fontSize: '1.2rem'}}>{t('seem.missing_characters')} {0}</label>
              )}
              <Anex>
                <Button
                  label="Anexar"
                  onClick={() => {
                    setShowAnexModal(true);
                  }}
                  disabled={user?.isVisualizador()}
                />
                <Button
                  submit
                  label={t('theme_analysis.submit')}
                  loading={loading}
                  disabled={user?.isVisualizador()}
                />
              </Anex>
            </ThemeAnalysisNote>
            {attachmentData?.length > 0 && (
              <table>
                <thead>
                  <TableRow
                    style={{
                      backgroundColor: '#fff',
                    }}
                    columns={[
                      { payload: t('theme_analysis_note.name'), type: 'HEAD' },
                      { payload: t('theme_analysis_note.date'), type: 'HEAD' },
                      { payload: t('theme_analysis_note.user'), type: 'HEAD' },
                      { payload: '', type: 'HEAD' },
                    ]}
                  />
                </thead>
                <tbody>
                  {
                    attachmentData?.map((r) => (
                      <TableRow
                        key={r.id}
                        columns={[
                          { payload: r.attachment_name, type: 'TEXT' },
                          { payload: r.created_at, type: 'TEXT' },
                          { payload: r.user_name, type: 'TEXT' },
                          {
                            payload: r.attachment_name ? {
                              types: ['FILE', 'DELETE'],
                              deleteAction: {
                                onClick: () => {
                                  api().delete(`/themes/note/attachment/${r.id}`)
                                    .then(() => {
                                      mutateAttachment();
                                    });
                                },
                              },
                              fileAction: { onClick: () => { window.open(r.attachment_url, '_blank'); } },
                            } : 1,
                            type: r.attachment_name ? 'BUTTONS' : 'HEAD_BUTTONS',
                          },
                        ]}
                      />
                    ))
                  }
                </tbody>
              </table>
            )}
          </>
        )}
      </Accordion>
      {showAnexModal && (
        <Modal
          title="Anexar arquivo"
          show
          onShow={setShowAnexModal}
          action={{
            label: t('include_item.modal.action'),
            loading,
            onClick: () => {
              if (observationAnex.current) {
                observationAnex.current.submitForm();
              }
            },
          }}
          loading={loading}
        >
          <ModalView className="modal-sm">
            <Form ref={observationAnex} onSubmit={handleAnexSubmit}>
              <Upload
                name="upload"
                label={t('include_item.modal.upload')}
                isDocument
              />
            </Form>
          </ModalView>
        </Modal>
      )}
    </>
  );
};
export default ThemeAnalysis;

import React, {
  useCallback,
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button, Container, P1, P2, Row, SimpleLoading, StepViewer,
} from '~/components';
import RiskSourceSelector from '~/components/RiskSourceSelector';
import { useSelectedRiskSource } from '~/components/RiskSourceSelector/selectedRiskSourcesContext';
import { formatErrorMessage, useDebounce, useFetch, useApi } from '~/hooks';
import { maskMoney } from '~/hooks/helpers';
import { HeaderBack } from '~/layout';
import { useMe, error } from '~/services';

import MetadataForm from './MetadataForm';
import { View, Wrapper } from './styles';
import WeightsForm from './WeightsForm';

const Workflow = () => {
  const [ initialWeights, setInitialWeights ] = useState({});
  const [ isSubmitting, setSubmitting ] = useState(false);

  const { search } = useLocation();
  const formRef = useRef(null);
  const api = useApi();
  const { t } = useTranslation('admin');
  const { t: t2 } = useTranslation('search');
  const { t: tAuth } = useTranslation('auth');
  const navigate = useNavigate();
  const alert = useAlert();
  const step = new URLSearchParams(search).get('step');

  const {
    selectedRiskSources,
    setSelectedRiskSources,
    selectedPersonTypes,
    setSelectedPersonTypes,
  } = useSelectedRiskSource();
  const { id: workflowId } = useParams();
  const { user } = useMe();
  const debouncedSelectedPersonTypes = useDebounce(selectedPersonTypes);

  const { data: availableRiskSources, isValidating } = useFetch({
    url: debouncedSelectedPersonTypes.length <= 0 ? null : `/risksource/available?${ debouncedSelectedPersonTypes.map(
      (personType) => 'personType[]='+personType).join('&') }`,
  });
  const { data: persons } = useFetch({ url: '/clients/getPersonTypes' });
  const { data: workflowData, isValidating: isFetchingWorkflow } = useFetch({ url: !workflowId ? null : `/workflows/${ workflowId }` });
  
  useEffect(async () => {
    if(workflowId && workflowData) {
      const personsTypes = debouncedSelectedPersonTypes.map((personType) => 'personType[]='+personType).join('&');
      const { data: workflowDataPersonTypes } = await api().get(`/risksource/available?${ personsTypes }`);
      
      const {
        name, person_types, description, group_id, client_id, themes: themesCollection,
      } = workflowData.data;
      const usertype = [];
      let company = null;

      // carregando os tipos de pessoa
      if(person_types && person_types.length > 0) {
        setSelectedPersonTypes(person_types.map(({ id, description: label }) => {
          usertype.push({
            value: id,
            label,
          });
          return id;
        }));
      }

      // carregando a empresa (ou grupo)
      if(group_id) {
        company = {
          value: 0,
          label: user.grupos[ 0 ].grupo,
        };
      }
      else {
        company = {
          value: client_id,
          label: user.hierarquia.find((item) => item.id === client_id).cliente,
        };
      }

      // carregando as fontes de risco
      let weights = {};
      setSelectedRiskSources(
        themesCollection.map((themeItem) => {
          weights[ themeItem.theme.name ] = themeItem.weight;
          return themeItem.selected_risk_sources.map(({ id, person_type, price, cost_group }) => {
            
            return ( {
              id,
              theme_id: themeItem.theme_id,
              person_type,
              price,
              theme_name: themeItem.theme.name,
              cost_group,
            } );
          });
        }).reduce((acc, curr) => {
          
          return [ ...acc, ...curr ];
        }, []).filter((selectedRiskSource) => !!workflowDataPersonTypes?.find(
          (availableRiskSource) => availableRiskSource.id === selectedRiskSource.id,
        )),
      );

      Object.keys(weights).forEach((theme) => {
        if(!workflowDataPersonTypes?.find(
          (availableRiskSource) => availableRiskSource.theme_name === theme,
        )) {
          const { [ theme ]: _, ...rest } = weights;
          weights = rest;
        }
      });

      setInitialWeights(weights);
      
      if(formRef?.current) {
        formRef.current.getFormRef().current.setData({
          name: `${ name } (${ t('workflows.create.copy') })`,
          usertype,
          description,
          company,
        });
      }
    }
  }, [
    workflowData
  ]);

  const onUserTypeSelect = useCallback(() => {
    setTimeout(() => {
      if(formRef.current) {
        setSelectedPersonTypes(formRef.current.getFormValue('usertype'));
      }
    }, 1);
  }, []);

  const totalCost = useMemo(() => {
    const costGroupUsed = [];

    costGroupUsed[ 1 ] = [];
    costGroupUsed[ 2 ] = [];
    costGroupUsed[ 3 ] = [];
    costGroupUsed[ 4 ] = [];
    return selectedRiskSources.reduce((acc, curr) => {
        acc[ curr.person_type ] = acc[ curr.person_type ] ?? 0;
        if(curr.cost_group === null ||
          ( !costGroupUsed[ curr.person_type ].includes(curr.cost_group) && curr.price !== 0 )) {
          costGroupUsed[ curr.person_type ].push(curr.cost_group);
          acc[ curr.person_type ] += curr.price;
        }
        return acc;
      }, {},
    );
  }, [ selectedRiskSources ]);

  const handleSubmit = useCallback((data) => {
    const themes = [];
    const filteredByPersonType = selectedRiskSources.filter(value => selectedPersonTypes.includes(parseInt(value.person_type)));

    filteredByPersonType.forEach((selectedRiskSource) => {
      const index = themes.findIndex((currTheme) => currTheme.id === selectedRiskSource.theme_id);
      if(index >= 0) {
        themes[ index ].risk_sources.push(selectedRiskSource.id);
      }
      else {
        themes.push({
          id: selectedRiskSource.theme_id,
          weight: data.themes[ selectedRiskSource.theme_name ],
          risk_sources: [ selectedRiskSource.id ],
        });
      }
    });

    if(themes.length < 1) {
      return alert.error(t('workflows.create.response.alert'));
    }

    const formattedData = {
      name: data.name,
      client_id: null,
      group_id: null,
      goal_id: 1, //set default value
      description: data.description,
      person_types: data.usertype,
      themes,
    };

    if(data.company === 0) {
      formattedData.group_id = user.grupos[ 0 ].idgrupo;
    }
    else {
      formattedData.client_id = data.company;
    }

    Yup.object().shape({
      name: Yup.string().
        max(50, t('workflows.create.validate.name.max')).
        required(t('workflows.create.validate.name.required')),
      company: Yup.string().required(t('workflows.create.validate.company')),
      description: Yup.string().required(t('workflows.create.validate.description')),
    }).validate(data, {
      abortEarly: false,
    }).then(async () => {
      if(!formattedData.themes) {
        return alert.error(t('workflows.create.response.error'));
      }
      setSubmitting(true);
      await api().post('/workflows', formattedData).then(() => {
        alert.success(t('workflows.create.response.success'));
        navigate('/administracao?q=workflows');
      }).catch(({ response }) => alert.error(
        formatErrorMessage(response, t('workflows.create.response.error'), tAuth('expired'))));
      setSubmitting(false);
    }).catch((err) => {
      err.errors.forEach((er) => alert.error(er));
      error(err, formRef.current.getFormRef());
    });
  }, [ selectedRiskSources, t, user, navigate, alert, tAuth ]);

  if(!!workflowId && isFetchingWorkflow) {
    return (
      <View>
        <Wrapper><SimpleLoading/></Wrapper>
      </View>
    );
  }

  return (
    <View>
      <Container>
        <HeaderBack title={ t('workflows.create.title') } route={ -1 }>
          { Object.keys(totalCost).length > 0
            && ( user?.isMaster() || user?.usuario?.viewWorkflowCosts === 1 ) && (
              <span>
              <P1>{ t('workflows.create.total-cost') }</P1>
                { Object.keys(totalCost).map((personTypeID) => (
                  <P2 key={ personTypeID }>
                    { t('workflows.create.total-cost-entry', {
                      label: t2(`person_types.${ personTypeID - 1 }`),
                      value: maskMoney(totalCost[ personTypeID ]),
                    }) }
                  </P2>
                )) }
            </span>
            ) }
        </HeaderBack>

        <Wrapper rowGap={ 'lg' }>
          <StepViewer
            ref={ formRef }
            onSubmit={ handleSubmit }
            steps={ [
              {
                label: t('workflows.create.cols.data'),
                component: (
                  <MetadataForm
                    persons={ persons }
                    onUserTypeSelect={ onUserTypeSelect }
                  />
                ),
              },
              {
                label: t('workflows.create.cols.themes'),
                component: (
                  <RiskSourceSelector
                    emptyInfo={ t('workflows.create.select-person-type') }
                    availableRiskSources={ availableRiskSources }
                    isValidating={ isValidating }
                    newWorkflow
                  />
                ),
              },
              {
                label: t('workflows.create.cols.weights'),
                component: (
                  <WeightsForm
                    form={ formRef.current }
                    initialValues={ initialWeights }
                    availableRiskSources={ availableRiskSources }
                  /> ),
              },
            ] }
          />

          <Row>
            <Button
              label={ t('workflows.create.actions.back') }
              onClick={ () => navigate(-1) }
              appearance="tertiary"
            />

            <Button
              submit
              label={ step === '3' ? t('workflows.create.actions.create') : t('workflows.create.actions.next') }
              loading={ isSubmitting }
              onClick={ () => step === '3'
                ? formRef.current.getFormRef().current.submitForm()
                : formRef.current.gotoNextStep() }
            />
          </Row>
        </Wrapper>
      </Container>
    </View>
  );
};

export default Workflow;

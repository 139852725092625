import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAlert } from "react-alert";
import {
  Plus,
  Info,
  ChevronDown,
  CheckCircle,
  Lock,
  Unlock,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form } from "@unform/web";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import * as Yup from "yup";

import {
  H6,
  P2,
  P3,
  Modal,
  Input,
  Table,
  Empty,
  Upload,
  Select,
  Button,
  Tooltip,
  TableRow,
  MultiCheckbox,
  SimpleLoading,
  RangeDatePicker,
  DatePicker,
} from "~/components";
import { useFetch, masked, formatErrorMessage, useApi } from "~/hooks";
import { CreateTag, ModalMonitoring, DeleteMonitoring } from "~/layout";
import { error, useMe } from "~/services";

import {
  View,
  Title,
  ViewModal,
  ReportsModal,
  AnalysisIcon,
  AnalysisButton,
  ReportButton,
  ReportsValuesModal,
  ModalView,
} from "./styles";

const Card = ({
  id,
  type,
  show,
  title,
  fields,
  status,
  seemId,
  onShow,
  themes,
  surveys,
  sources,
  hasTags,
  options,
  workflows,
  clientId,
  document,
  monitored,
  processId,
  renderTags,
  isEntity,
  updateSeem,
  isDiligence,
  renderHeader,
  isSimplified,
  renderAllTags,
  status_entidade,
  diligence,
  client,
}) => {
  const { t } = useTranslation("card");
  const { t: T } = useTranslation("components");
  const { t: tAuth } = useTranslation("auth");
  const { user } = useMe();

  const [seem, onSeem] = useState(false);
  const [actionPlan, onActionPlan] = useState(false);
  const [tags, onTags] = useState([]);
  const [date, onDate] = useState("");
  const [endDate, onEndDate] = useState("");
  const [showTag, onShowTag] = useState(false);
  const [popupId, setPopupId] = useState(null);
  const [showModal, onShowModal] = useState(false);
  const [showPopup, onShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [monitoring, onMonitoring] = useState(false);
  const [reportModal, onReportModal] = useState(false);
  const [changeValuesModal, onChangeValuesModal] = useState(false);
  const [analysisItem, setAnalysisItem] = useState({});
  const [loadingStatus, onLoadingStatus] = useState(false);
  const [loading, onLoading] = useState(false);
  const [loadingReports, onLoadingReports] = useState(false);

  const seemRef = useRef(null);
  const actionPlanRef = useRef(null);
  const reports = useRef(null);
  const analysisFormRef = useRef(null);

  const analysisButtonItems = useFetch({ url: "/analysis" })?.data?.data;
  const sentAnalysis = useFetch({
    url: !isEntity && `/process/${processId}/headers`,
  })?.data?.data?.analysis;
  const popups = useFetch({
    url: popupId ? `/simplesearch/${processId}/popup/${popupId}` : "",
  })?.data?.popups;
  const changedProcessValues = useFetch({
    url: `/process/${processId}/changedvalues`,
  });

  const navigate = useNavigate();
  const alert = useAlert();
  const api = useApi();

  /**
   * List all tags
   */
  useEffect(() => {
    const listen_tags = options?.data?.map((item) => ({
      label: [item.name],
      value: item.id,
    }));

    onTags(listen_tags);
  }, [options]);

  const handleAnalysisSubmit = (data) => {
    Yup.object()
      .shape({
        note: Yup.string().required(t("analysis.alerts.note")),
      })
      .validate(data, {
        abortEarly: false,
      })
      .then(() => {
        api()
          .post(`/process/${processId}/analysis/${analysisItem.id}`, data)
          .then(() => {
            alert.success(t("analysis.alerts.success"));
            onShowModal(false);
          })
          .catch(({ response }) =>
            alert.error(
              formatErrorMessage(
                response,
                t("analysis.alerts.error"),
                tAuth("expired")
              )
            )
          );
      })
      .catch((err) => error(err, analysisFormRef));
  };

  /*
   * Create popup rows and heading
   */
  const { popupRows, popupHeading } = useMemo(() => {
    let rows = [];
    let heading = [];

    if (popups) {
      heading = Object.keys(popups[0]).map((item) => ({
        label: item,
        isSort: true,
      }));
      rows = popups.map((popup, index) => ({
        id: index,
        items: Object.values(popup).map((item) => ({
          // label: reduce(String(item), 40) || '-',
          label: item || "-",
        })),
      })); // row
    }
    return { popupRows: rows, popupHeading: heading };
  }, [popups]);

  const handleStatus = async () => {
    onLoadingStatus(true);
    await api()
      .post("entities/updateStatus", {
        entityStatusID: status_entidade === 1 ? 2 : 1,
        document: masked(document),
      })
      .then(() => {
        renderHeader();
        alert.success(
          status_entidade === 1
            ? t("status.alerts.success.0")
            : t("status.alerts.success.1")
        );
      })
      .catch(({ response }) =>
        alert.error(
          formatErrorMessage(
            response,
            t("status.alerts.error"),
            tAuth("expired")
          )
        )
      );
    onLoadingStatus(false);
  };

  /**
   * Submit seem.
   */
  const submit_seem = (data, { reset }) => {
    Yup.object()
      .shape({
        note: Yup.string().required(t("seem.modal.alerts.note")),
        status: Yup.string().required(t("seem.modal.alerts.status")),
      })
      .validate(data, {
        abortEarly: false,
      })
      .then(async () => {
        onLoadingStatus(true);

        await api()
          .patch(`/process/${processId}/conclude`, {
            note: data.note,
            status: data.status,
          })
          .then(async ({ data: res }) => {
            const archive = seemRef.current.getFieldValue("document");

            if (archive) {
              const form = new FormData();
              form.append("attachment", archive);

              await api().post(
                `/process/${processId}/seem/${res.feedback.id}/attachment`,
                form
              );
            }

            reset();
            updateSeem();
            renderAllTags();
            alert.success(t("seem.modal.alerts.success"));
          })
          .catch(({ response }) =>
            alert.error(
              formatErrorMessage(
                response,
                t("seem.modal.alerts.error"),
                tAuth("expired")
              )
            )
          );

        onSeem(false);
        onLoadingStatus(false);
      })
      .catch((err) => error(err, seemRef));
  };

  /**
   * Submit ActionPlan.
   */
  const submit_actionPlan = (data, { reset }) => {
    Yup.object()
      .shape({
        due_date: Yup.string().required(T("tablerow.modal.alerts.due_date")),
        responsible_name: Yup.string().required(
          T("tablerow.modal.alerts.responsible_name")
        ),
        responsible_email: Yup.string()
          .email(T("tablerow.modal.alerts.responsible_email.0"))
          .required(T("tablerow.modal.alerts.responsible_email.1")),
        description: Yup.string().required(
          T("tablerow.modal.alerts.description")
        ),
      })
      .validate(data, {
        abortEarly: false,
      })
      .then(async () => {
        const { upload, due_date: dueDate, ...restData } = data;
        const due_date = format(dueDate, "yyyy-MM-dd");
        const formattedData = {
          ...restData,
          due_date,
          subject: T("tablerow.modal.subject"),
          process_id: Number(processId),
        };

        onLoading(true);
        await api()
          .post("/actionplan", formattedData)
          .then(({ data: res }) => {
            if (upload) {
              const form = new FormData();
              form.append("attachment", upload);
              api()
                .post(`/actionplan/${res.id}/attachment`, form)
                .catch(({ response }) => {
                  alert.error(
                    formatErrorMessage(
                      response,
                      T("tablerow.modal.alerts.upload")
                    )
                  );
                });
            }
            onActionPlan(false);
            alert.success(T("tablerow.modal.alerts.success"));
          })
          .catch(({ response }) => {
            alert.error(
              formatErrorMessage(
                response,
                T("tablerow.modal.alerts.error"),
                tAuth("expired")
              )
            );
          });
        onLoading(false);
      })
      .catch((err) => error(err, actionPlanRef));
  };
  /**
   * List resports.
   */
  const list_themes = () => {
    if (isDiligence) {
      return themes?.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        reveal: true,
      }));
    }

    return null;
  };

  const list_reports = [
    {
      name: "themes",
      options: list_themes(),
    },
    {
      name: "general",
      options: [
        {
          id: "onlyConfirmedRisks",
          value: "onlyConfirmedRisks",
          label: t("reports.items.8"),
          reveal: isDiligence,
          selectedByDefault: true,
        },
        // {
        //   id: 'datailedReport',
        //   value: 'datailedReport',
        //   label: t('reports.items.9'),
        //   reveal: isDiligence,
        //   selectedByDefault: true,
        // },
        {
          id: "corporateData",
          value: "corporateData",
          label: t("reports.items.0"),
          reveal: true,
        },
        {
          id: "actionPlans",
          value: "actionPlans",
          label: t("reports.items.1"),
          reveal: isDiligence,
        },
        {
          id: "seem",
          value: "seem",
          label: t("reports.items.2"),
          reveal: isDiligence && seemId,
        },
        {
          id: "notes",
          value: "notes",
          label: t("reports.items.3"),
          reveal: isDiligence && seemId,
        },
        {
          id: "surveys",
          value: "surveys",
          label: t("reports.items.4"),
          reveal: isDiligence && surveys?.length > 0,
        },
        {
          id: "risksEvolution",
          value: "risksEvolution",
          label: t("reports.items.5"),
          reveal: isEntity,
        },
        {
          id: "processes",
          value: "processes",
          label: t("reports.items.6"),
          reveal: isEntity,
        },
        {
          id: "themes",
          value: "themes",
          label: t("reports.items.7"),
          reveal: isEntity && themes?.data?.length > 0,
        },
      ],
    },
  ];

  /**
   * Submit reports.
   */
  const submit_reports = (datad, typed) => {
    if (datad.general.length === 0 && datad.themes.length === 0) {
      alert.error(t("reports.alerts.error.0"));
      return;
    }

    const request = async (req) => {
      onLoadingReports(true);

      await req()
        .then(({ data: res }) => {
          const file = new Blob([res], {
            type: "application/pdf",
          });

          saveAs(
            file,
            `Relatório ${processId} ${format(new Date(), "dd_MM_yyyy")}.pdf`
          );

          onReportModal(false);
          reports.current.reset();
          alert.success(t("reports.alerts.success"));
        })
        .catch(({ response }) =>
          alert.error(
            formatErrorMessage(
              response,
              t("reports.alerts.error.1"),
              tAuth("expired")
            )
          )
        );

      onLoadingReports(false);
    };

    const checked = (word) =>
      datad.general.indexOf(word) !== -1 ? 1 : undefined;

    const entities = () => {
      const submit = () =>
        api().get(`/entities/${id}/report`, {
          params: {
            to: endDate ? format(endDate, "yyyy-MM-dd") : undefined,
            from: date ? format(date, "yyyy-MM-dd") : undefined,
            themes: checked("themes"),
            processes: checked("processes"),
            workflows: datad.workflows,
            corporateData: checked("corporateData"),
            risksEvolution: checked("risksEvolution"),
          },
          responseType: "blob",
        });

      request(submit);
    };

    const diligenceReport = () => {
      const submit = () =>
        api().get(`/process/${processId}/report`, {
          params: {
            onlyConfirmedRisks: checked("onlyConfirmedRisks"),
            datailedReport: checked("datailedReport"),
            seem: checked("seem"),
            notes: checked("notes"),
            themes: datad.themes,
            actionPlans: checked("actionPlans"),
            corporateData: checked("corporateData"),
          },
          responseType: "blob",
        });

      request(submit);
    };

    switch (typed) {
      case "diligence":
        diligenceReport();
        break;

      case "entities":
        entities();
        break;

      default:
        break;
    }
  };

  const edit = useCallback(async () => {
    try {
      if (diligence?.analyst_editor) {
        await api().delete(`/process/${processId}/analyst`);
      } else {
        await api().post(`/process/${processId}/analyst`);
      }

      renderAllTags();
    } catch ({ response }) {
      alert.error(response.data.message);
    }
  }, [alert, diligence?.analyst_editor, processId, renderAllTags]);

  return (
    <View isEntity={isEntity} isSimplified={isSimplified}>
      <article>
        <Title as={isSimplified ? H6 : P2} isBold>
          {title}
        </Title>
        {isDiligence && (
          <div>
            {user?.isAdminICTS() &&
              (client?.processes_should_be_closed_by_icts !== 0 ||
                client?.processes_analysed_by_icts !== 0) &&
              !seemId && (
                <Tooltip
                  label={`${t("admin_operations.being_edited_by")} ${
                    diligence?.analyst?.nome
                  } (${diligence?.analyst?.id})`}
                  width={300}
                  isActive={
                    diligence?.analyst_editor &&
                    user?.usuario?.id !== diligence?.analyst_editor
                  }
                >
                  <Button
                    label={
                      diligence?.analyst_editor
                        ? t("admin_operations.stop_edit")
                        : t("admin_operations.edit")
                    }
                    onClick={edit}
                    iconLeft={diligence?.analyst_editor ? <Unlock /> : <Lock />}
                    disabled={
                      diligence?.analyst_editor &&
                      user?.usuario?.id !== diligence?.analyst_editor
                    }
                    appearance="secondary"
                  />
                </Tooltip>
              )}

            <Button
              disabled={user?.isVisualizador()}
              label={hasTags ? t("tag.label.0") : t("tag.label.1")}
              onClick={() =>
                onShowTag({
                  id,
                  type: hasTags ? "replace" : "assign",
                  reveal: true,
                })
              }
              appearance="secondary"
            />
            <Tooltip
              label={t("monitoring.tooltip")}
              width={416}
              isActive={!monitored?.deleted_by}
            >
              <Button
                label={(() => {
                  if (!monitored) {
                    return t("monitoring.label.1");
                  }
                  if (!monitored?.deleted_by) {
                    return t("monitoring.label.0");
                  }
                  return t("monitoring.label.1");
                })()}
                onClick={() => {
                  // eslint-disable-next-line
                  monitored
                    ? onMonitoring({ delete: true })
                    : onMonitoring({ create: true });
                }}
                appearance="secondary"
                disabled={
                  monitored?.deleted_by || user?.isVisualizador()
                }
              />
            </Tooltip>
            <Button
              label={t("action_plan")}
              onClick={() => onActionPlan(!actionPlan)}
              disabled={user?.isVisualizador()}
              iconLeft={<Plus />}
              appearance="secondary"
            />
            <AnalysisButton>
              <Button
                label={t("analysis.label")}
                iconLeft={<Plus />}
                appearance="secondary"
                disabled={user?.isVisualizador()}
              />
              <div>
                {analysisButtonItems?.map((item) => {
                  const hasBeenSent = sentAnalysis?.find(
                    (sentAnalysisItem) => sentAnalysisItem.id === item.id
                  );

                  return (
                    <P3
                      as="button"
                      key={item.id + item.title}
                      title={item.text}
                      onClick={() => {
                        if (hasBeenSent) {
                          alert.error(t("analysis.alerts.error"));
                        } else {
                          setAnalysisItem(item);
                          onShowModal(true);
                        }
                      }}
                    >
                      <AnalysisIcon hasBeenSent={hasBeenSent}>
                        {hasBeenSent ? <CheckCircle /> : <Info />}
                      </AnalysisIcon>

                      {item.title}
                    </P3>
                  );
                })}
              </div>
            </AnalysisButton>

            <Button
              label={t("seem.label")}
              onClick={() => onSeem(!seem)}
              disabled={
                seemId ||
                user?.isVisualizador() ||
                user?.isRevisor()
              }
              appearance="secondary"
            />

            <ReportButton>
              <Button
                label={t("reports.label")}
                disabled={user?.isVisualizador()}
                iconLeft={<Plus />}
                // onClick={() => onReportModal(! reportModal)}
                appearance="secondary"
                disabled={user?.isVisualizador()}
              />
              <div>
                <>
                  <P3
                    as="button"
                    key={0}
                    title={t("reports.title")}
                    onClick={() => {
                      onChangeValuesModal(!changeValuesModal);
                    }}
                  >
                    <AnalysisIcon>
                      <Info />
                    </AnalysisIcon>
                    {t("reports.title")}
                  </P3>
                  <P3
                    as="button"
                    key={1}
                    title={t("reports.label")}
                    onClick={() => onReportModal(!reportModal)}
                  >
                    <AnalysisIcon>
                      <Info />
                    </AnalysisIcon>
                    {t("reports.label")}
                  </P3>
                </>
              </div>
            </ReportButton>
            <ViewModal>
              <Modal
                show={showModal}
                onShow={onShowModal}
                title={analysisItem.title}
                action={{
                  label: t("analysis.modal.action"),
                  onClick: () => analysisFormRef?.current.submitForm(),
                }}
              >
                <P2 as="p">{analysisItem.text}</P2>
                <Form ref={analysisFormRef} onSubmit={handleAnalysisSubmit}>
                  <Input
                    name="note"
                    label={t("analysis.modal.note.label")}
                    appearance="secondary"
                    placeholder={t("analysis.modal.note.placeholder")}
                    textarea
                  />
                </Form>
              </Modal>
            </ViewModal>

            <Modal
              show={seem}
              onShow={onSeem}
              title={t("seem.label")}
              action={{
                label: t("seem.modal.action"),
                loading: loadingStatus,
                onClick: () => seemRef?.current.submitForm(),
              }}
            >
              <Form ref={seemRef} onSubmit={submit_seem} className="form-seem">
                <Select
                  icon={ChevronDown}
                  name="status"
                  label={t("seem.modal.status.label")}
                  options={[
                    {
                      value: 3,
                      label: t("seem.modal.status.options.0"),
                    },
                    {
                      value: 7,
                      label: t("seem.modal.status.options.1"),
                    },
                    {
                      value: 8,
                      label: t("seem.modal.status.options.2"),
                    },
                  ]}
                  appearance="secondary"
                  placeholder={t("seem.modal.status.placeholder")}
                />

                <Input
                  textarea
                  name="note"
                  label={t("seem.modal.note.label")}
                  appearance="secondary"
                  placeholder={t("seem.modal.note.placeholder")}
                />

                <Upload
                  name="document"
                  label={t("seem.modal.document.label")}
                  isDocument
                />
              </Form>
            </Modal>
            <Modal
              show={actionPlan}
              onShow={onActionPlan}
              title={T("tablerow.modal.title")}
              action={{
                label: T("tablerow.modal.action"),
                loading,
                onClick: () => actionPlanRef?.current.submitForm(),
              }}
            >
              <ModalView>
                <P2>{T("tablerow.modal.text")}</P2>

                <Form ref={actionPlanRef} onSubmit={submit_actionPlan}>
                  <DatePicker
                    name="due_date"
                    label={T("tablerow.modal.datepicker.label")}
                    minDate={new Date()}
                    appearance="secondary"
                    placeholder={T("tablerow.modal.datepicker.placeholder")}
                  />
                  <Input
                    name="responsible_name"
                    label={T("tablerow.modal.responsible_name.label")}
                    appearance="secondary"
                    placeholder={T(
                      "tablerow.modal.responsible_name.placeholder"
                    )}
                  />
                  <Input
                    name="responsible_email"
                    label={T("tablerow.modal.responsible_email.label")}
                    appearance="secondary"
                    placeholder={T(
                      "tablerow.modal.responsible_email.placeholder"
                    )}
                  />
                  <Input
                    name="description"
                    label={T("tablerow.modal.description.label")}
                    appearance="secondary"
                    placeholder={T("tablerow.modal.description.placeholder")}
                    textarea
                  />
                  <Upload
                    name="upload"
                    label={T("tablerow.modal.upload.label")}
                    isDocument
                  />
                </Form>
              </ModalView>
            </Modal>
          </div>
        )}

        {isSimplified && (
          <div>
            <Button
              label={t("new_evaluation.label")}
              onClick={() =>
                navigate(
                  `/pesquisa?q=diligencia&person=${
                    type === "user" ? 1 : 2
                  }&name=${title}&document=${document}`
                )
              }
            />
          </div>
        )}

        {isEntity && (
          <div>
            <Button
              label={
                status_entidade === 1
                  ? t("status.label.0")
                  : t("status.label.1")
              }
              appearance="secondary"
              onClick={() => handleStatus()}
              loading={loadingStatus}
              disabled={user?.isVisualizador()}
            />
            <Button
              label={t("reports.label")}
              onClick={() => onReportModal(!reportModal)}
              appearance="secondary"
              disabled={user?.isVisualizador()}
            />
          </div>
        )}
      </article>

      <div className="diligence-card">
        <H6 isBold>{t("fields.cadastral_data")}</H6>

        <ul>
          <div>
            {fields?.map(({ label, value }, index) => {
              if (index % 2 === 0) {
                return (
                  <P2 as="li" key={label}>
                    {`${label} : `}
                    <span>{value}</span>
                  </P2>
                );
              }
              return null;
            })}
          </div>

          <div>
            {fields?.map(({ label, value }, index) => {
              if (index % 2 === 1) {
                return (
                  <P2 as="li" key={label}>
                    {`${label} : `}
                    <span>{value}</span>
                  </P2>
                );
              }
              return null;
            })}
          </div>
        </ul>
      </div>

      {isSimplified && (
        <div className="diligence-card">
          <H6 isBold>{t("fields.risk_sources")}</H6>
          {sources?.length > 0 ? (
            <>
              <table>
                <tbody>
                  {sources?.map((item) => (
                    <TableRow
                      key={item.fonte_risco_id}
                      columns={[
                        { payload: item.coluna1 || "", type: "TEXT" },
                        { payload: item.coluna2 || "", type: "TEXT" },
                        {
                          payload: (
                            <b
                              className={
                                item.alertas > 0 ? "alert-danger" : "alert"
                              }
                            >
                              {`${`0${item.alertas || 0}`.slice(-2)} Alertas`}
                            </b>
                          ),
                          type: "TEXT",
                        },
                        {
                          payload: {
                            types: ["FILE"],
                            fileAction: {
                              onClick: () => {
                                setPopupId(item.fonte_risco_id);
                                setPopupTitle(
                                  `${item.coluna1 || ""}${
                                    item.coluna1 && item.coluna2 ? " - " : ""
                                  }${item.coluna2 || ""}`
                                );
                                onShowPopup(true);
                              },
                            },
                            hide: !item.popup,
                          },
                          type: "BUTTONS",
                        },
                      ]}
                    />
                  ))}
                </tbody>
              </table>
              <Modal
                title={popupTitle}
                show={showPopup}
                onShow={onShowPopup}
                hasActions={false}
                size="lg"
              >
                {popupRows.length > 0 ? (
                  <Table body={popupRows} heading={popupHeading} />
                ) : (
                  <SimpleLoading />
                )}
              </Modal>
            </>
          ) : (
            <Empty hasButton={false} />
          )}
        </div>
      )}

      {isDiligence && (
        <CreateTag
          hasDelete
          show={showTag}
          render={renderAllTags}
          onShow={onShowTag}
          options={tags}
          clientId={clientId}
          processId={processId}
          renderList={renderTags}
        />
      )}

      <ModalMonitoring
        show={monitoring.create}
        render={renderAllTags}
        onShow={onMonitoring}
        processId={processId}
      />

      <DeleteMonitoring
        id={monitored?.id}
        show={monitoring.delete}
        render={renderAllTags}
        onShow={onMonitoring}
      />
      {changeValuesModal && (
        <Modal
          show={ReportsValuesModal}
          onShow={onChangeValuesModal}
          title={t("reports.title")}
          hasActions={false}
          size="lg"
        >
          <ReportsValuesModal>
            {changedProcessValues?.data?.data.length === 0 ? (
              <H6 style={{ textAlign: "center" }}>
                {t("reports.changeInformation")}
              </H6>
            ) : (
              <table style={{ fontSize: "1.5rem" }}>
                <tr>
                  <th style={{ width: "30%" }}>{t("reports.change")}</th>
                  <th>{t("reports.information")}</th>
                </tr>
                {changedProcessValues?.data?.data?.map((changeValue, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={changeValue.document + index}>
                    {changeValue.name === "Exclusão" ? (
                      <td style={{ textAlign: "center", color: "#0D79DE" }}>
                        {changeValue.name}
                      </td>
                    ) : (
                      <td style={{ textAlign: "center", color: "#F68720" }}>
                        {changeValue.name}
                      </td>
                    )}
                    <td>{changeValue.document}</td>
                  </tr>
                ))}
              </table>
            )}
          </ReportsValuesModal>
        </Modal>
      )}
      {!isSimplified && (
        <Modal
          show={reportModal}
          onShow={onReportModal}
          title={t("reports.modal.title")}
          action={{
            label: t("reports.modal.action"),
            loading: loadingReports,
            onClick: () => reports?.current.submitForm(),
            disabled: loadingReports,
          }}
        >
          <ReportsModal
            ref={reports}
            onSubmit={(datad) =>
              submit_reports(datad, isDiligence ? "diligence" : "entities")
            }
          >
            {isEntity && (
              <div>
                <RangeDatePicker
                  date={date}
                  onDate={onDate}
                  maxDate={new Date()}
                  endDate={endDate}
                  onEndDate={onEndDate}
                  popperPlacement="top-start"
                />

                <Select
                  isMulti
                  icon={ChevronDown}
                  name="workflows"
                  options={workflows}
                  appearance="secondary"
                  placeholder={t("reports.modal.workflows.placeholder")}
                  menuPosition="fixed"
                />
              </div>
            )}

            <P2>{t("reports.modal.text")}</P2>

            <div>
              {list_reports?.map((item) => {
                if (item.options?.length > 0) {
                  const initialValues = item.options
                    .filter((o) => !!o.selectedByDefault)
                    .map((o) => o.id);

                  return (
                    <MultiCheckbox
                      key={item.name}
                      name={item.name}
                      initialValues={initialValues}
                      options={item.options}
                    />
                  );
                }
                return null;
              })}
            </div>
          </ReportsModal>
        </Modal>
      )}
    </View>
  );
};
Card.defaultProps = {
  id: 0,
  type: "user",
  show: false,
  title: "",
  seemId: false,
  onShow: () => {},
  fields: [],
  options: [],
  hasTags: false,
  sources: [],
  clientId: 0,
  document: "",
  monitored: false,
  processId: 0,
  updateSeem: () => {},
  renderTags: () => {},
  isEntity: false,
  isDiligence: false,
  isSimplified: false,
  renderAllTags: () => {},
  diligence: false,
  client: false,
};

export default Card;

import styled, { css } from 'styled-components';

import { breakpoints, colors } from '~/styles';

export const InfoView = styled.span`
  color: ${colors.status.warning};
`;
export const InfoView2 = styled.span`
  color: ${colors.status.warning};
`;
export const UsageSettingsView = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${({ onlyOneClient }) => (onlyOneClient ? css`
    grid-template-areas: "b b b b x x x x z z z c" "d d d d e e e e z z z c";
  ` : css`
    grid-template-areas: "a a a a b b b x x x z c" "z1 z1 z1 z1 d d d e e e z c";
  `)}

  align-items: end;
  min-height: 84px;
  column-gap: 2rem;

  @media screen and (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
    grid-template-areas: "a" "d" "c";
  }

  > div:first-child {
    grid-area: a;
  }

  > div:nth-child(2) {
    grid-area: b;
  }

  > div:nth-child(3) {
    grid-area: x;
  }

  > button {
    margin-bottom: 1.3rem;
    grid-area: c;
    justify-content: center;
  }

  ${InfoView} {
    grid-area: d;
  }
   ${InfoView2} {
    grid-area: e;
  }

`;

import styled from "styled-components";

import { H6 } from "~/components";
import { colors, easing, typograph } from "~/styles";
import { rgba } from "~/hooks";

const { basic } = easing;
const { gray, primary } = colors;
const { weight, size } = typograph;

export const ModalView = styled.div`
  display: flex !important;
  flex-direction: column;

  transition: all 0.3s ${basic};

  & > span {
    margin: 0 auto 2rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, ".1")};

    svg {
      width: 4rem;
      height: 4rem;
      stroke: ${primary._000};
    }
  }

  & > ${H6} {
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
    text-align: left !important;
  }

  & > p {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      margin-top: 2.4rem;
    }
  }
`;

export const Title = styled.div`
  font-size: ${size.s3};
  line-height: ${size.m1};
  color: ${colors.gray._600};
  font-weight: ${weight.bold};
`;

export const SubTitle = styled.div`
  font-size: ${size.s1};
  line-height: ${size.m1};
  font-weight: ${weight.regular};
  margin-left: ${({ ml }) => ml};
`;

export const Flex = styled.div`
  display: flex;
  width: ${({ w }) => w};
  gap: ${({ gap }) => gap};
  margin-top: ${({ mt }) => mt};
  flex-direction: ${({ dir }) => dir};
`;

export const HeaderWrapper = styled.div`
  padding-bottom: 0.8rem;
  border-bottom: 0.1rem solid ${rgba(gray._400, ".2")};
`;

import { useState, useEffect } from 'react';

/**
 * Debounce an value.
 */
const useDebounce = (value, delay = 500) => {
  const [debounce, onDebounce] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      onDebounce(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debounce;
};

export default useDebounce;

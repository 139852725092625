import React from 'react';

import { P1 } from '../Title';


const AcordoLeniencia = ({ data }) => {
  return (
    <>
      {data && (
        <>
          {Object.entries(data).map((line) => {
            return Object.entries(line[1]).map((obj, idnx) => {
              return <>
                <P1 key={idnx} style={{ lineHeight: '1.6rem', padding: '1.6rem 1rem' }}>
                  <strong>{obj[0]}</strong>
                  {': '}
                  {obj[1]}
                </P1>
              </>
            })
          })}
        </>
      )}

    </>)
}
  ;


export default AcordoLeniencia;

import styled from "styled-components";

import { H6 } from "~/components";
import { colors, typograph } from "~/styles";
import { rgba } from "~/hooks";
const { gray, primary } = colors;
const { weight, size } = typograph;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InfoText = styled.span`
  color: ${gray._500};
  font-size: ${size.s3};
  line-height: ${size.m1};
`;

export const ModalView = styled.div`
  display: flex !important;
  flex-direction: column;
  gap: 1.5rem;

  & > span {
    margin: 0 auto 2rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, ".1")};

    svg {
      width: 4rem;
      height: 4rem;
      stroke: ${primary._000};
    }
  }

  & > ${H6} {
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
    text-align: left !important;
  }

  & > p {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex: 1;

    & > div {
      margin-top: 2.4rem;
    }
  }
  &.modal-sm {
    min-height: 20rem;
  }
  &.modal-md {
    min-height: 26rem;
  }
  &.modal-lg {
    min-height: 32rem;
  }
`;

import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useAlert } from 'react-alert';
import { Upload as Icon, Paperclip, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useField } from '@unform/core';
import { bool, string, oneOf } from 'prop-types';

import { P1 } from '~/components';
import { colors } from '~/styles';

import {
  View, Empty, Wrapper, Document, Trash, DivTrash, Hint
} from './styles';

const Upload = ({
  ext, name, label, hasPrefix, isDocument, onClickDelete = null, ...rest
}) => {
  const inputRef = useRef(null);
  const alert = useAlert();

  const { fieldName, registerField, defaultValue } = useField(name);

  const [preview, onPreview] = useState(defaultValue);

  const { t } = useTranslation('components');

  const extension = useMemo(
    () => ({
      media: '.jpg, .png, .jpeg, .bmp, .tiff',
      document:
        '.jpg, .jpeg, .png, .bmp, .tiff, .pdf, .doc, .docx, .xls, xlsx, .ppt, .pptx, .zip, .rar, .mp3, .wav, .mp4, .avi, .webm, .mov, .wmv, .mkv, .txt, .csv',
    }),
    [],
  );

  const handlePreview = useCallback(
    (e) => {
      let file = e.target.files?.[0];

      const type = isDocument ? 'document' : 'media';
      const types = extension[type]
        .replace(/\./g, '')
        .split(',')
        .map((item) => item.trim());
      const fileType = file.name.split('.')[file.name.split('.').length - 1];

      if (! types.includes(fileType)) {
        e.target.files = null;
        file = null;
        return alert.error(t('upload.type'));
      }
      
      if (file.size > 51200 * 1024) {
        e.target.files = null;
        file = null;
        return alert.error(t('upload.size'));
      }

      if (! file) onPreview(null);

      let previewURL = null;

      if (! isDocument) previewURL = URL.createObjectURL(file);
      else previewURL = file.name;

      onPreview(previewURL);
    },
    [alert, isDocument, t, extension],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue (ref) {
        ref.value = ""; // eslint-disable-line
        onPreview(null);
      },
      setValue (_, value) {
        onPreview(value);
      },
    });
  }, [fieldName, registerField]);

  /**
   * Disabled drag and drop.
   */
  const disabled = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
  };

  return (
    <View>
      <DivTrash>
        {label && <label htmlFor={fieldName}>{label}</label>}
        <Trash>
          {preview
            ? (
              <Trash2
                color={colors.status.error}
                onClick={() => {
                  inputRef.current.value = '';
                  onPreview(null);
                  if (onClickDelete) {
                    onClickDelete()
                  }
                }}
              />
            )
            : <></>}
        </Trash>
      </DivTrash>

      <Wrapper>
        {/* eslint-disable-next-line */}
        {preview ? (
          isDocument ? (
            <Document>
              <Paperclip />
              {preview}
            </Document>
          ) : (
            <img
              src={hasPrefix ? `data:image/png;base64,${preview}` : preview}
              alt=""
            />
          )
        ) : (
          <Empty>
            <Icon />
            <P1>
              {`${t('upload.message')} ${
                isDocument ? t('upload.anex') : t('upload.image')
              }`}
            </P1>
          </Empty>
        )}

        <input
          {...rest}
          ref={inputRef}
          type="file"
          onDrop={disabled}
          accept={extension[ext]}
          onChange={handlePreview}
          onDragOver={disabled}
          onDragEnter={disabled}
          onDragLeave={disabled}
        />
        
      </Wrapper>
      { !isDocument && <Hint>{ t('upload.image-rules') }</Hint>}
    </View>
  );
};

Upload.propTypes = {
  ext: oneOf(['media', 'document']),
  name: string.isRequired,
  label: string,
  hasPrefix: bool,
  isDocument: bool,
};

Upload.defaultProps = {
  ext: 'document',
  label: '',
  hasPrefix: false,
  isDocument: false,
};

export default Upload;

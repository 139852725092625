import styled, { css } from "styled-components";

import { P3 } from "~/components";
import { rgba } from "~/hooks";
import { colors, easing, typograph } from "~/styles";

const { gray, primary } = colors;

const { basic } = easing;

const { size, family, weight } = typograph;

export const View = styled.div`
  position: relative;
  display: ${({ reveal }) => (reveal ? "block" : "none")};

  & > * {
    z-index: 3;
  }

  .react-datepicker {
    position: relative;
    z-index: 9;
    font-size: ${size.s2};
    box-shadow: 0 0.2rem 0.4rem rgba(gray._300, ".2");
    font-family: ${family};
    border-color: ${gray._200};
    padding: 1.6rem 0.8rem 2.4rem;
    width: 100%;

    &__header {
      background-color: ${gray._000};
      border-bottom-color: ${gray._000};
    }

    &__navigation {
      top: 2.2rem;
      width: 1.2rem;
      height: 1.2rem;
      border-width: 0.5rem;
    }

    &__current {
      &-month {
        color: ${gray._600};
        font-size: ${size.s3};
        text-transform: capitalize;
      }
    }

    &__day {
      color: ${gray._400};
      width: 4rem;
      margin: 0rem;
      font-size: ${size.s2};
      line-height: 2.8rem;

      &:focus {
        outline: none !important;
      }

      &-name {
        width: 100%;
        color: ${gray._500};
        font-size: ${size.s2};
        line-height: 2.8rem;
        text-transform: capitalize;
      }

      &-names {
        display: flex;
        margin-top: 0.8rem;
      }

      &--today,
      &--keyboard-selected {
        color: ${gray.darker};
        background-color: ${gray._000};
      }

      &--in-range,
      &--selected,
      &--in-selecting-range {
        color: ${gray._000};
        font-weight: ${weight.bold};
        background-color: ${primary._000};
      }

      &--outside-month,
      &--disabled {
        color: ${gray._300};
      }

      &--disabled {
        cursor: not-allowed;
      }
    }

    &-wrapper {
      z-index: 1;
      top: 2.4rem;
      width: 100%;
      height: 4.6rem;
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }

    &__triangle {
      display: none;
    }
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 9;
    min-width: 28rem;
  }

  ${({ hasLabel }) =>
    !hasLabel &&
    css`
      .react-datepicker-wrapper {
        top: 0;
      }
    `}

  ${({ isRange }) =>
    isRange &&
    css`
      border: 0.2rem solid ${gray._200};
      height: 4.6rem;
      display: flex;
      transition: all 0.3s ${basic};
      align-items: center;
      border-radius: 0.4rem;
      background-color: ${gray._000};

      &:hover {
        border-color: ${primary._000};
      }

      ${P3} {
        z-index: 1;
        color: ${gray._600};
        font-weight: ${weight.semiBold};
      }

      .react-datepicker {
        &-wrapper {
          top: 0;
          opacity: 1;
          position: relative;
          pointer-events: all;

          .react-datepicker__input-container {
            height: 100%;

            input {
              text-align: center;
              width: 100%;
              height: 100%;
              padding: 0;
              color: ${gray._600};
              font-size: ${size.s3};
              font-family: ${family};
              background-color: transparent;

              &::placeholder {
                color: ${gray._400};
              }
            }
          }
        }
      }
    `}

  @media screen and (max-width: 560px) {
    .react-datepicker-popper {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, 10%) !important;
    }
  }
`;

export const Head = styled.div`
  display: flex;
  padding: 0 0.8rem;
  flex-direction: column;

  & > h4 {
    color: ${gray._600};
    margin-top: 0.8rem;
  }

  & > div {
    display: grid;
    column-gap: 1.6rem;
    grid-template-columns: 1.35fr 1fr;
    margin-bottom: 1rem;

    & > * {
      position: relative;
      z-index: 3;
    }
  }

  .css-1hhay09-SingleValue,
  .css-cjitqv-menu {
    font-size: ${size.s1};
  }
`;

import React from 'react';

import { bool, string } from 'prop-types';

import { ViewProfile } from '../styles';

const Profile = ({ avatar, hasImage }) => (
  <ViewProfile>
    {hasImage
      ? <img src={avatar} alt="" />
      : avatar.slice(0, 2)}
  </ViewProfile>
);

Profile.propTypes = {
  avatar: string,
  hasImage: bool,
};

Profile.defaultProps = {
  avatar: '',
  hasImage: false,
};

export default Profile;

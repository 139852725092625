import styled, { css } from 'styled-components';

import { P3 } from '~/components/Title';
import { colors, typograph } from '~/styles';

const {
  gray,
  status,
  support,
  primary,
} = colors;

const {
  size: font,
  family,
} = typograph;

const sizes = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

export const Dropdown = styled.div`
  display: flex;
  min-width: 10.8rem;

  .css-1sde4p3-control,
  .css-11isj5b-control {
    border-color: ${primary._000};
    background-color: ${primary._000};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .css-16o5eb5-indicatorContainer  {
      color: ${gray._000};
    }

    &:hover,
    &:focus {
      border-color: ${primary._100};
      background-color: ${primary._100};

      .css-1buq54w-indicatorContainer  {
        color: ${gray._000};
      }
    }
  }

  .css-1hhay09-SingleValue,
  .css-1buq54w-indicatorContainer  {
    color: ${gray._000};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  
  ${({ isSubmit }) => isSubmit && css`
    & > svg {
      width: 18px;
      height: 18px;
      stroke: ${status.success};
      cursor: pointer;
      margin-left: 8px;
    }
  `}
`;

export const Target = styled.input`
  width: 100%;
  color: ${gray._600};
  border: .2rem solid ${gray._200};
  font-size: ${font.s3};
  max-height: 4.6rem;
  font-family: ${family};
  border-radius: .4rem;
  background-color: ${gray._000};

  &::placeholder {
    color: ${gray._400};
  }

  ${({ appearance }) => appearance === 'primary' && css`
    &:hover,
    &:focus {
      border-color: ${gray._100};
      background-color: ${gray._100};
    }
  `}

  ${({ appearance }) => appearance === 'secondary' && css`
    &:hover,
    &:focus {
      border-color: ${primary._000};
    }
  `}

  ${({ error }) => error && css`
    border-color: ${status.error};

    &:hover,
    &:focus {
      border-color: ${status.error};
    }
  `}

  ${({ prefix }) => prefix === 'true' && css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}

  ${({ textarea }) => textarea === 'true' && css`
    max-height: 100%;
  `}
`;

export const Icon = styled.span`
  right: 1.6rem;
  display: flex;
  position: absolute;
  margin-left: 1.2rem;
  align-items: center;
  
  svg {
    stroke: ${gray._500};
  }

  ${({ isSearch }) => isSearch && css`
    cursor: pointer;
  `}
`;

export const Error = styled(P3)`
  color: ${status.error};
  margin-top: .4rem;
`;

export const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    color: ${gray._500};
    font-size: ${font.s1};
    line-height: 2.4rem;
  }

  ${Target},
  ${Icon} svg,
  ${Target}::placeholder {
    transition: all .3s ease-in-out;
  }

  ${({ focus }) => focus && css`
    ${Target}::placeholder {
      color: ${gray._500};
    }

    ${Icon} svg {
      stroke: ${gray._500};
    }
  `}

  ${({ size }) => size === sizes.xs && css`
    ${Target} {
      height: 3.2rem;
      padding: .2rem .4rem;
      font-size: ${font.s2};
      line-height: ${font.s2};
    }
  `}

  ${({ size, hasIcon }) => size === sizes.sm && css`
    ${Icon} {
      width: 1.6rem;
      height: 1.6rem;
    }

    ${Target} {
      padding: ${hasIcon ? '1.2rem 3.6rem 1.2rem 1.6rem' : '1.2rem 1.6rem'};
    }
  `}

  ${({ size, hasIcon }) => size === sizes.md && css`
    ${Icon} {
      width: 2.0rem;
      height: 2.0rem;
    }

    ${Target} {
      padding: ${hasIcon ? '1.6rem 4.0rem 1.6rem 1.6rem' : '1.6rem'};
    }
  `}

  ${({ size, hasIcon }) => size === sizes.lg && css`
    ${Icon} {
      right: 2.4rem;
      width: 2.0rem;
      height: 2.0rem;
    }

    ${Target} {
      padding: ${hasIcon ? '2.0rem 4.8rem 2.0rem 2.4rem' : '2.0rem 2.4rem'};
    }
  `}

  ${({ reveal }) => ! reveal && css`
    display: none !important;
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;

    ${Target} {
      cursor: not-allowed;
      background-color: ${gray._200};
    }

    ${Icon} svg {
      stroke: ${gray._400};
    }
  `}
`;

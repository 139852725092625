import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import {
  element,
} from 'prop-types';
import styled from 'styled-components';

import LogoWhite from '~/assets/riskCheck.png';
import {
  useFetch,
} from '~/hooks';
import { useMe } from '~/services';

const Header = styled.div`
  display:none;

  @media print {

    display: flex;
    padding: 0 10mm;
    height: 100px;
    margin-top: 1.5rem;
    font-size: 1.4rem;
    color: #7F859D;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
`;
const Footer = styled.div`
  display:none;
  @media print {
    font-size: 1.2rem;
    color: #7F859D;
    display: flex;
    height: 100px;
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 90%;
    padding: 0 10mm;
  }
`;
const WrapperTable = styled.table`
  width: 100%;
  & thead.wrapper, & tfoot.wrapper,& .teste,& .header,& .footer {
    display: none;
  }

  @media print {
    padding: 0 10mm;

    & thead.wrapper {
      display: table-header-group;
    }
    & tbody.wrapper {

    }
    & tfoot.wrapper {
      display: block;

    }
    .header-space {
      height:110px;
    }
    .footer-space {
      height:200px;
    }
  }

`;
const data = new Date();
const day = format(data, 'dd/MM/yyyy');
const hour = format(data, 'kk:mm:ss');

// eslint-disable-next-line react/prop-types
const PopupWrapper = ({ children }, ref) => {
  const { t } = useTranslation('components');

  const { user } = useMe();
  const { data: ip } = useFetch({ url: '/user/ip' });
  return (
    <div ref={ref}>
      <Header>
        <img style={{ width: '190px', height: '50px' }} src={LogoWhite} alt="" />
        <div>
          <p>
            {`${t('popup.header.start')} ${day} ${t('popup.header.middle')} ${hour}`}
          </p>
          <p>
            {`${t('popup.header.user')}   ${user?.usuario?.nome} / IP: ${ip?.data}`}
          </p>
        </div>
      </Header>
      <WrapperTable>
        <thead>
          <tr>
            <td className="header-space">&nbsp;</td>

          </tr>
        </thead>
        <tbody className="wrapper">
          <tr>
            <td>
              {children}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr className="footer-space"><td>&nbsp;</td></tr>
        </tfoot>

      </WrapperTable>
      <Footer>
        <p>
          {`© 2021 ICTS – ${t('popup.footer.message')}`}
        </p>
      </Footer>
    </div>
  );
};

export const UnauthPopupWrapper = forwardRef(({ children }, ref) => {
  const { t } = useTranslation('components');
  
  return (
    <div ref={ref}>
      <Header>
        <img style={{ width: '190px', height: '50px' }} src={LogoWhite} alt="" />
        <div>
          <p>
            {`${t('popup.header.start')} ${day} ${t('popup.header.middle')} ${hour}`}
          </p>
        </div>
      </Header>
      
      <WrapperTable>
        <thead>
        <tr>
          <td className="header-space">&nbsp;</td>

        </tr>
        </thead>
        
        <tbody className="wrapper">
        <tr>
          <td>
            {children}
          </td>
        </tr>
        </tbody>
        
        <tfoot>
        <tr className="footer-space"><td>&nbsp;</td></tr>
        </tfoot>
      </WrapperTable>
      
      <Footer>
        <p>
          {`© 2021 ICTS – ${t('popup.footer.message')}`}
        </p>
      </Footer>
    </div>
  );
});

export default forwardRef(PopupWrapper);

import React from 'react';
import { ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  bool,
  number,
  string,
  object,
  element,
  oneOfType,
} from 'prop-types';

import { H4 } from '~/components';

import View from './styles';

const HeaderBack = ({
  title,
  route,
  hasArrow,
  children,
  ...rest
}) => {
  const navigate = useNavigate();

  return (
    <View {...rest}>
      <H4 isBold>
        {hasArrow && (
          <ArrowLeft
            size="2.8rem"
            onClick={() => navigate(route)}
          />
        )}

        {title}
      </H4>

      {children && children}
    </View>
  );
};

HeaderBack.propTypes = {
  title: string.isRequired,
  route: oneOfType([string, number]),
  hasArrow: bool,
  children: oneOfType([bool, object, element]),
};

HeaderBack.defaultProps = {
  route: '',
  hasArrow: true,
  children: false,
};

export default HeaderBack;

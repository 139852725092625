import React from 'react';
import { useTranslation } from 'react-i18next';

import { H4, P1 } from '~/components';

import { View, Wrapper } from './styles';


const Forbbiden = ({ ...rest }) => {
  const { t } = useTranslation('components');

  return (
    <View {...rest}>
      <Wrapper>
        <H4 isBold>{t('forbbiden.title')}</H4>

        <P1 isBold>{t('forbbiden.description')}</P1>
      </Wrapper>
    </View>
  );
};

export default Forbbiden;

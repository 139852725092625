import styled from 'styled-components';

import { Layout } from '~/components';
import { breakpoints, colors } from '~/styles';

const { gray } = colors;

export const View = styled(Layout)`
  margin-bottom: 6.4rem;

  @media screen and (max-width: ${breakpoints.sm}) {
    .sc-hzMMCg.eHJfHN {
      flex-direction: column;
    }
  }
`;

export const Wrapper = styled.div``;

export const ActionsHeader = styled.div`
  display: flex;
  align-items: center;

  & > div {
    width: 25rem;
    margin-left: 1.6rem;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 3.2rem;
    flex-direction: column;
    top: 6.4rem;
    display: flex;
    padding: 1rem;
    box-shadow: 0rem 0rem 0.4rem 0.1rem #fff;
    align-items: center;
    border-radius: 0.4rem;
    background-color: ${gray._000};
    width: 100%;

    & > div {
      margin: 0.8rem 0;
      width: 100%;
    }
  }
`;

import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { Form } from "@unform/web";

import { P2, Modal, Button, Loading, Checkbox, Input } from "~/components";
import { useMe } from "~/services";
import { useApi, useFetch } from "~/hooks";

import { Container, ModalView, ErrorsWrapper, CheckboxWrapper } from "./style";
import { useValidator } from "./validator";

const ProcessAutomationSettings = () => {
  const { t } = useTranslation("settings");
  const { schema } = useValidator({ t });

  const api = useApi();
  const alert = useAlert();
  const { user, group } = useMe();

  const formRef = useRef();

  const clientId = group.value;
  const groupId = user.grupos[0]?.idgrupo;

  const {
    data: processAutomation,
    mutate,
    isValidating,
  } = useFetch({
    url: `/settings/process-automation?clientId=${clientId}&groupId=${
      groupId ? groupId : ""
    }`,
  });
  const [isOpen, onOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(false);

  const handleClick = async () => {
    await mutate();

    formRef.current.setData({
      maxApproval: processAutomation?.length
        ? processAutomation[0].max_approval
        : "",
    });
    setFieldValue(
      processAutomation?.length ? processAutomation[0].max_approval : "",
    );
    onOpen(true);
  };

  const handleClose = async () => {
    setTimeout(() => {
      if (processAutomation?.length && formRef?.current) {
        setFieldValue(processAutomation[0].max_approval);
        formRef.current.setData({
          maxApproval: processAutomation[0].max_approval,
        });
      }
    }, 500);

    onOpen(false);
  };

  const handleCheckbox = async (value) => {
    if (processAutomation?.length) {
      try {
        const {
          data: { data },
        } = await api().patch(`/settings/process-automation`, {
          clientId,
          active: value,
          id: processAutomation[0].id,
        });

        setSelectedValue(data.active);
        alert.success(
          t(
            `${
              data.active
                ? "process_automation.success_activate"
                : "process_automation.success_disable"
            }`,
          ),
        );
        return;
      } catch ({ response: { data } }) {
        alert.error(data.message);
      }
    }

    setSelectedValue((prev) => !prev);
  };

  const handleEdit = async ({ maxApproval }) => {
    if (Number(processAutomation[0].max_approval) === Number(maxApproval)) {
      return;
    }

    try {
      schema
        .validate({ maxApproval: Number(maxApproval) }, { abortEarly: false })
        .then(async () => {
          const {
            data: { data },
          } = await api().patch(`/settings/process-automation`, {
            clientId,
            maxApproval,
            id: processAutomation[0].id,
          });

          formRef.current.setFieldValue("maxApproval", data.max_approval);

          await mutate();
          onOpen(false);
          alert.success(t("process_automation.success_edit"));
        })
        .catch((err) => {
          const element = (
            <ErrorsWrapper>
              {Object.keys(err?.inner)?.map((key) => (
                <p>{err?.inner[key]?.message}</p>
              ))}
            </ErrorsWrapper>
          );

          alert.error(element);
        });
    } catch ({ response }) {
      alert.error(response?.data?.message);
    }
  };

  const handleCreate = async ({ maxApproval }) => {
    try {
      schema
        .validate({ maxApproval: Number(maxApproval) }, { abortEarly: false })
        .then(async () => {
          const payload = {
            maxApproval,
            clientId: clientId,
            groupId: groupId ? groupId : undefined,
          };

          await api().post(`/settings/process-automation`, payload);

          await mutate();
          alert.success(t("process_automation.success_create"));
          onOpen(false);
        })
        .catch((err) => {
          const element = (
            <ErrorsWrapper>
              {Object.keys(err.inner).map((key) => (
                <p>{err.inner[key].message}</p>
              ))}
            </ErrorsWrapper>
          );

          alert.error(element);
        });
    } catch ({ response: { data } }) {
      alert.error(data.message);
    }
  };

  const handleSubmit = async ({ maxApproval }) => {
    if (processAutomation?.length) {
      await handleEdit({ maxApproval });
      return;
    }

    await handleCreate({ maxApproval });
  };

  useEffect(() => {
    if (isValidating) return <Loading />;

    if (processAutomation?.length) {
      setFieldValue(processAutomation[0].max_approval);
      formRef.current.setData({
        maxApproval: processAutomation[0].max_approval,
      });
    }
  }, [processAutomation]);

  useEffect(() => {
    setSelectedValue(
      processAutomation?.length
        ? processAutomation[0].active
          ? true
          : false
        : false,
    );
  }, [processAutomation]);

  useEffect(() => {
    const input = document.querySelector("#maxApproval");

    if (input) {
      input.addEventListener("input", (e) => {
        const value = parseInt(e.target.value);
        if (isNaN(value)) setFieldValue(null);
        else setFieldValue(value);
      });
    }
  });

  return (
    <Container>
      <CheckboxWrapper>
        <Checkbox
          key={1}
          id={1}
          defaultChecked={selectedValue}
          label={t("process_automation.checkBox")}
          onChange={(e) => handleCheckbox(e.target.checked)}
        />
        <Button
          onClick={handleClick}
          disabled={!selectedValue}
          label={t("process_automation.button")}
        />
      </CheckboxWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal
          hasActions
          show={isOpen}
          onShow={onOpen}
          title={t("process_automation.modal.title")}
          action={{
            label: t(
              `${
                processAutomation?.length
                  ? "process_automation.modal.update_button"
                  : "process_automation.modal.save_button"
              }`,
            ),
            onClick: () => formRef.current.submitForm(),
          }}
          goBackOnCancel={handleClose}
        >
          <ModalView>
            <P2>{t("process_automation.modal.description")}</P2>
            <Input
              name="maxApproval"
              appearance="secondary"
              placeholder={t("process_automation.modal.placeholder")}
              label={t("process_automation.modal.input", {
                value: fieldValue ? fieldValue : "__",
              })}
            />
          </ModalView>
        </Modal>
      </Form>
    </Container>
  );
};

export default ProcessAutomationSettings;

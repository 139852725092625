import React from 'react';

import { object, string, oneOfType } from 'prop-types';

import AnpResult from './AnpResult';
import AcordoLeniencia from './AcordoLeniencia';
import ChaveValor from './ChaveValor';
import RawViewer from './RawViewer';
import TableViewer from './TableViewer';
import ImagesViewer from './ImagesViewer';
import MidiasViewer from './MidiasViewer';
import CrawlerLatam from './CrawlerLatam';
import LegacySurvey from './LegacySurvey';
import DownloadedFileViewer from './DownloadedFileViewer';
import ProcessoJudicialViewer from './ProcessoJudicialViewer';
import ProcessoJudicialResumoViewer from './ProcessoJudicialResumoViewer';
import ProcessoCompletoViewer from '~/components/PopupViewer/ProcessoCompletoViewer';
import PdfsViewer from './pdfsViewer';
import Ceis from './ceis';
import FiliacaoPartidaria from './FiliacaoPartidaria';

const PopupViewer = ({ popup }) => {
  if (typeof popup === 'string') {
    if (popup.toUpperCase().includes("CERTIDÃO NEGATIVA") || popup.toUpperCase().includes("CERTIDÃO POSITIVA"))
      return <RawViewer data={popup} cndt />
      
    return <RawViewer data={popup} />;
  }
  
  const { type, data } = popup;

  if (! type || type === 'tabela') {
    return <TableViewer data={popup} />;
  }
  if (type === 'text') {
    return <RawViewer data={data}  />;
  }

  const mapTypesToComponents = {
    // adicionar todos popups custom aqui
    midias: MidiasViewer,
    processo_judicial: ProcessoJudicialViewer,
    processo_judicial_completo: ProcessoCompletoViewer,
    jusbrasil_resumo: ProcessoJudicialResumoViewer,
    downloaded_file: DownloadedFileViewer,
    image: ImagesViewer,
    crawler_latam: CrawlerLatam,
    legacySurvey: LegacySurvey,
    anpResult: AnpResult,
    pdf: PdfsViewer,
    AcordoLeniencia: AcordoLeniencia,
    Ceis: Ceis,
    chave_valor: ChaveValor,
    FiliacaoPartidaria: FiliacaoPartidaria,
  };

  if (Object.keys(mapTypesToComponents).includes(type)) {
    const Component = mapTypesToComponents[type];
    return <Component data={data} />;
  }
  return <RawViewer data={JSON.stringify(popup)} />;
};

PopupViewer.propTypes = {
  popup: oneOfType([object, string]).isRequired,
};

export default PopupViewer;

import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Button, SimpleInput } from '~/components';
import ClientSelect from '~/components/ClientSelect';
import { useApi, useFetch } from '~/hooks';
import { useMe } from '~/services';
import { colors } from '~/styles';

import { SiteSettingsView, List, SelectClientSiteValues } from './style';

const SiteSettings = () => {
  const { t } = useTranslation('settings');

  const { user } = useMe();
  const alert = useAlert();
  const api = useApi();
  const [input, setInput] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [client, setClient] = useState(null);
  const [value, setValue] = useState([]);
  const [currentSetting, setCurrentSetting] = useState({ groupValue: [{ site: '' }], value: [{ site: '' }] });

  const { data, isValidating, mutate } = useFetch({ url: client ? `/settings?type=FILTER_MIDIA_SITE${client.value !== 0 ? `&client_id=${client.value}` : ''}` : null });

  useEffect(() => {
    const currentValue = data?.data;
    if (typeof (currentValue) === 'object' && Object.keys(currentValue).length !== 0) {
      const settingData = JSON.parse(currentValue[0]?.data || { groupValue: [{ site: '' }], value: [{ site: '' }] });
      setCurrentSetting(settingData);
    } else {
      const settingData = { groupValue: [{ site: '' }], value: [{ site: '' }] };
      setCurrentSetting(settingData);
    }
  }, [client, data]);

  useEffect(() => {
    const key = ! client || client.value === 0 ? 'groupValue' : 'value';
    setValue(currentSetting[key]);
  }, [client, currentSetting]);

  useEffect(() => {
    if (user?.hierarquia?.length === 1) {
      setClient({ value: user.hierarquia[0].id, label: user.hierarquia[0].client });
    }
  }, [user]);

  const HandleSetClient = (target) => {
    setInput();
    setValue([]);
    setClient(target);
  };
  const onSubmit = async () => {
    setSubmitting(true);
    const settingData = { ...currentSetting };
    const key = ! client || client.value === 0 ? 'groupValue' : 'value';
    let newValue;
    if (value) {
      newValue = value;
      newValue.push({ site: input });
    } else {
      newValue = [{ site: input }];
    }
    settingData[key] = newValue;
    const requestBody = {
      type: 'FILTER_MIDIA_SITE',
      data: JSON.stringify(settingData),
    };
    if (client.value !== 0) {
      requestBody.client_id = client.value;
    }
    try {
      await api().post('/settings', requestBody);
      alert.success(t('site.saved-successfully'));
      if (data[0]) {
        const newData = { ...data };
        newData.data[0].data = requestBody.data;
        setCurrentSetting(requestBody.data);
        mutate(newData);
      } else {
        mutate();
      }
      setInput();
    } catch (ex) {
      alert.error(ex.message);
    }
    setSubmitting(false);
  };
  const handleRemoveTask = async (site) => {
    setSubmitting(true);
    const settingData = { ...currentSetting };
    const newValue = value.filter((check) => check.site !== site);
    setValue(newValue);
    const key = ! client || client.value === 0 ? 'groupValue' : 'value';
    settingData[key] = newValue;
    const requestBody = {
      type: 'FILTER_MIDIA_SITE',
      data: JSON.stringify(settingData),
    };

    if (client.value !== 0) {
      requestBody.client_id = client.value;
    }

    try {
      await api().post('/settings', requestBody);
      alert.success(t('site.deleted-successfully'));
      const newData = { ...data };
      newData.data[0].data = requestBody.data;
      setCurrentSetting(requestBody.data);
      mutate(newData);
    } catch (ex) {
      alert.error(ex.message);
    }

    setSubmitting(false);
  };

  return (
    <SiteSettingsView>
      <SelectClientSiteValues>
        { user?.hierarquia?.length > 1 && (
          <div style={{ width: '30%' }}>
            <ClientSelect
              user={user}
              onChange={(target) => HandleSetClient(target)}
              placeholder={t('site.client-select-placeholder')}
              style={{ menuPortal: (base) => ({ ...base, zIndex: 999999 }) }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
        )}
        {(client && user?.hierarquia?.length > 1)
        && (
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'spaceBetween',
            marginLeft: '30px',
          }}
          >
            <SimpleInput
              value={input}
              onChange={({ target }) => setInput(target.value)}
              placeholder="www.google.com"

            />
            <Button
              label={t('site.save-btn-label')}
              loading={isSubmitting}
              onClick={onSubmit}
              style={{ marginLeft: '30px' }}
            />
          </div>
        )}
        {user?.hierarquia?.length === 1 && (
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'spaceBetween',
          }}
          >
            <SimpleInput
              value={input}
              onChange={({ target }) => setInput(target.value)}
              placeholder="www.google.com"
            />
            <Button
              label={t('site.save-btn-label')}
              loading={isSubmitting}
              onClick={onSubmit}
              style={{ marginLeft: '30px' }}
            />
          </div>
        ) }
      </SelectClientSiteValues>
      <div>
        {value && (
          <ul>
            {value.map((task) => (
              task.site !== '' && (
                <List
                  key={task.site}
                >
                  <SimpleInput
                    label="Site"
                    value={task.site}
                    onChange={({ target }) => setInput(target.value)}
                    placeholder="www.google.com"
                    disabled
                    style={{ width: '30%' }}
                  />
                  <Trash2
                    color={colors.status.error}
                    onClick={() => handleRemoveTask(task.site)}
                    size={18}
                    style={{
                      cursor: 'pointer', position: 'absolute', left: '35%', top: '50%',
                    }}
                  />
                </List>
              )
            ))}
          </ul>
        )}
      </div>
    </SiteSettingsView>
  );
};

export default SiteSettings;

/* eslint-disable react/prop-types */
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import { ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Form } from '@unform/web';
import { format } from 'date-fns';
import styled from 'styled-components';

import { Select } from '~/components';

import SurveyHistoryTable from './SurveyHistoryTable';
import SurveyHistoryTable2 from './SurveyHistoryTable2';

const View = styled(Form)``;

const SurveyHistoryView = ({ id, survey }, ref) => {
  const historyData = survey?.history;
  const forbiddenFields = useMemo(() => [
    'Response ID',
    'Date submitted',
    'Last page',
    'Start language',
    'Seed',
    'Token',
    'Date started',
    'Date last action',
    'IP address',
    'Referrer URL',
    'RISK',
    'ALERTS',
    'IMPACT',
  ], []);

  const [selectValue, setSelectValue] = useState(null);

  const { t } = useTranslation('diligence');

  const options = useMemo(() => historyData?.map(({
    name, email, responses, participant_token_id,
  }) => ({
    label: `${name} ${email ? `(${email})` : ''} - ${ responses.from === 'new'
      ? format(new Date(responses['submitdate']), 'dd/MM/yyyy')
      : format(new Date(responses['Date submitted']), 'dd/MM/yyyy')
    }`,
    participantTokenID: participant_token_id,
    value: responses,
  })), [historyData]);
  let FROM = null;
  const questions = useMemo(() => selectValue?.reduce((acc, current) => {
    FROM = current.value.from;
    if (current.value.from === 'new') {
      Object.entries(current.value.questions).forEach((question) => {
        if (!acc.includes(question)) {
          acc[question[0]] = question[1];
        }
      });
    } else {
      Object.keys(current.value).forEach((question) => {
        if (!forbiddenFields.includes(question) && !acc.includes(question)&& question !=='from') {
          acc.push(question);
        }
      });
    }
    
    return acc;
  }, []), [selectValue, forbiddenFields]);

  useEffect(() => {
    if (options && options.length > 0) {
      setSelectValue([options[0]]);
    } else {
      setSelectValue(null);
    }
  }, [options]);

  useEffect(() => {
  }, [selectValue, questions]);

  return (
    <View>
      <Select
        isMulti
        isClearable
        menuPosition="fixed"
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        name="historyEntry"
        options={options}
        icon={ChevronDown}
        value={selectValue}
        initialValue={selectValue}
        onChange={(val) => { setSelectValue(val); }}
        placeholder={t('surveys.modal.historyPlaceholder')}
      />
      {
        !!selectValue?.length && (
         FROM ==='new'
        ?(
          <SurveyHistoryTable
            ref={ref}
            id={id}
            surveyID={historyData[0]?.survey_id}
            questions={questions}
            responses={selectValue}
            alerts={survey.alerts}
            risk={`${survey.descricao_impacto} (${Math.round(survey.risk)}%)`}
            impact_id={survey.risk_impact_id}
          />
        ) : (
          <SurveyHistoryTable2
            ref={ref}
            id={id}
            surveyID={historyData[0]?.survey_id}
            questions={questions}
            responses={selectValue}
            alerts={survey.alerts}
            risk={`${survey.descricao_impacto} (${Math.round(survey.risk)}%)`}
            impact_id={survey.risk_impact_id}
          />
        ))
      }
    </View>
  );
};

export default forwardRef(SurveyHistoryView);

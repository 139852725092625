import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Flag, Empty, SimpleLoading, Table, Modal, H6, Paginate, Button, P1 } from '~/components';
import { useDebounce, useFetch } from '~/hooks';
import { useMe } from '~/services';
import { useApi } from '~/hooks';
import { table } from './data';
import {
  Trash as IconTrash,
  AlertTriangle,
} from 'react-feather';
import { Actions, CreateClientTerm } from '~/layout';
import { ModalBody } from '../../styles';
import ClientSelect from '~/components/ClientSelect';
import { useAlert } from 'react-alert';

const TableTerms = () => {
  const { user, group, newGroup } = useMe();
  const api = useApi();
  const [rowClientTerm, onRowClientTerm] = useState([]);
  const [loading, onLoading] = useState(false);
  const [ sortingClientTerms, onSortingClientTerms] = useState({});
  const { t } = useTranslation('admin');
  const [page, onPage] = useState(1);
  const [searchField, onSearchField] = useState('');
  const debounced = useDebounce(searchField);
  const jump = useDebounce(page);
  const [show, onShow] = useState(false);
  const [size, onSize] = useState({ value: 15, label: 15 });
  const [showModal, onShowModal] = useState(false);
  const alert = useAlert();
  const [clientSite, setClientSite] = useState(false);

  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }
  const { data: client_terms, mutate: render_client_terms } = useFetch({
    url:
    `/clientTerm?${group.value === 0 ? '' : `clientId=${group.value}&`}page=${jump}
    ${`&pageSize=${size.value}`}
    ${searchField && debounced ? `&searchField=${debounced}` : ''}
    ${`&order=${JSON.stringify(sortingClientTerms)
    .replace('0', 'name')
    .replace('1', 'temas')
  }`}
    `,
  });


  /**
   * Create row
   * client term table.
   */
    const row_client_terms = useCallback(async () => {
      let row = [];
      if (client_terms !== undefined && client_terms.length <= 0) {
        row = [];
  
        return;
      }
  
      row = client_terms?.data?.map((item) => {
        const actions = [
          {
            icon: <IconTrash />,
            action: () => onShowModal({
              id: item.id,
              theme_id: item.theme_id,
              type: 'delete-client-term',
              reveal: true,
            }),
          },
        ];
  
        return {
          id: item.id,
          items: [
            {
              label: <Flag status={item.termo} />,
            },
            {
              label: item?.theme,
              isBold: true,
            },
            {
              label: item?.person_type,
              isBold: true,
            },
            {
              label: <Actions actions={actions} red={true}/>,
            },
          ],
        };
      });
  
      onRowClientTerm(row);
    }, [client_terms]);
    /**
   * Sort through backend
   */
    const onSorting = useMemo(() => (
      { clientTerms: onSortingClientTerms}
    ), []);

    useEffect(() => {
      row_client_terms();
    }, [row_client_terms]);

    useEffect(async () => {
      await api().post('/settings/sites', {
        clientId: group.value,
      }).then((res) => {
        res.data.data ? setClientSite(true) : setClientSite(false);
      })
    }, []);
  return (
    <> 
      <Modal
        hasAction
        hasOverflow={false}
        show={showModal}
        title={t('client_terms.delete.title')}
        onShow={onShowModal}
        action={{
          label: t('client_terms.delete.action'),
          loading,
          onClick: async () => {
            onLoading(true);
            const data = {
              id: showModal.id,
              theme_id: showModal.theme_id,
            }
            await api().post(`/clientTerm/${showModal.id}`, {
              id: showModal.id,
              theme_id: showModal.theme_id,
              clientId: group.value
            })
              .then(() => {
                render_client_terms();
                onShowModal(false);
                alert.success(t('client_terms.delete.response.success'));
              })
              .catch(() => alert.error(t('client_terms.delete.response.error')));
            onLoading(false);
          },
        }}
      >
        <ModalBody>
          <span>
            <AlertTriangle />
          </span>

          <H6>{t('client_terms.delete.label')}</H6>
        </ModalBody>
      </Modal>
      {clientSite && <div>
        <P1>{t('specific_sites')}</P1>
      </div> }
      <div style={{display:"flex", justifyContent: "flex-end", width: '100%'}}>
        <ClientSelect
          user={user}
          value={group}
          onChange={(val) => newGroup(val)}
          placeholder={t('group')}
        />
        <Button
          label={t('buttons.client_terms')}
          onClick={() => onShow({
            type: 'client_term',
            reveal: true,
          })}
        />
      </div>
      {rowClientTerm?.length > 0 ? ( //eslint-disable-line
        <Table
          body={rowClientTerm}
          onBody={onRowClientTerm}
          heading={table['clientTerms'](t, onSorting['clientTerms'])}
        />
      ) : rowClientTerm
        ? <Empty />
        : (
          <div className="simple-loading">
            <SimpleLoading />
          </div>
        )}

      <CreateClientTerm
        show={show}
        render={render_client_terms}
        onShow={onShow}
        clientId={group.value}
      />
      <Paginate
            size={size}
            pages={client_terms?.last_page}
            total={client_terms?.total || '...'}
            onSize={onSize}
            perPage={client_terms?.data?.length}
            className="m-auto m-t-48"
            onPaginate={onPage}
            currentPage={page}
          />
    </>
  );
}


export default TableTerms;

/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  arrayOf, bool, number, string,
} from 'prop-types';

import {
  Accordion, Button, Empty, Input, Modal, P2, SimpleLoading,
} from '~/components';
import {formatErrorMessage, useApi, useFetch} from '~/hooks';
import useToggleColumn from '~/hooks/useToggleColumn';

import { useMarkedItems } from '../../markedItemsContext';
import { useResultData } from '../../resultContext';
import RiskItem from './RiskItem';
import Checkbox from "~/components/Checkbox";
import { useSelectRisks } from "~/views/Search/Response/Diligence2/services/selectRisksContext";
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";
import { ModalView } from "~/views/Search/Response/Diligence2/styles";
import * as Yup from "yup";
import { error } from "~/services";
import { useAlert } from "react-alert";

const RiskSource = ({
  riskSourceID,
  name,
  description,
  active,
  alerts,
  results,
  resultsIDs,
  themeName,
  themeID,
  active_change_reason,
  status,
  statusName,
  pendingAnalysis
}) => {
  const [ url, setURL ] = useState(null);
  const [ showToggle, setShowToggle ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ discardSelect, setDiscardSelect ] = useState(null);
  const { id: processID } = useParams();
  const { seem, addMutation, mutateEverything } = useResultData();
  const { markedItems, addMarkedItems, removeMarkedItems } = useMarkedItems();
  const { t } = useTranslation('diligence');
  const { t: tc } = useTranslation('components');
  const { toggleModals, generateToggleColumn } = useToggleColumn({
    seem, processID, onSucceed: mutateEverything,
  });
  const { selectedRisks, setSelectedRisks, isProcess, isMedia } = useSelectRisks();
  const discardRef = useRef(null);
  const api = useApi();
  const alert = useAlert();

  const { data: riskItems, isValidating: isValidatingRiskItems, mutate } = useFetch({ url });

  useEffect(() => {
    addMutation(mutate);
  }, [ addMutation, mutate, selectedRisks ]);

  const onOpen = useCallback((isOpened) => {
    if (isOpened && !url) {
      setURL(`/process/${ processID }/risks/${ riskSourceID }`);
    }
  }, [ processID, riskSourceID, url ]);

  const imMarked = (() => {
    for (let value of resultsIDs)
      if (markedItems.includes(value))
        return true;

    return false;
  })();
  
  const handleCheckAll = useCallback((checked) => {
    if (checked)
      riskItems
        .filter((item) => !selectedRisks.includes(item.id))
        .map(item => {
          setSelectedRisks(old => [...old, item.id])
        });
    else 
      setSelectedRisks([]);
  },[ selectedRisks, riskItems, setSelectedRisks ]);
  
  const handleSubmit = useCallback(async (formData) => {
    if (seem?.data?.id) {
      return alert.error(t('toggle.alerts.finalized'));
    }

    try {
      await Yup.object().shape({
        reason: Yup.string().required(t('toggle.alerts.reason')),
      }).validate(formData, { abortEarly: false });
    } catch (err) {
      return error(err, discardRef);
    }

    const {
      reason
    } = formData;

    setLoading(true);
    try {
      await api().post(`/process/${processID}/theme/${themeID}/source/${riskSourceID}/results`,
        {
          reason: reason,
          confirmed: discardSelect,
          resultIds: selectedRisks,
        });
      
      alert.success(
        `${t('toggle.alerts.success_item')} ${t('toggle.alerts.wait')}`,
      );

      setShowToggle(false);
      setSelectedRisks([]);
      setDiscardSelect(null);
    } catch ({response}) {
      alert.error(
        formatErrorMessage(response, t('toggle.alerts.error_item')),
      );
    } finally {
      setLoading(false);
      mutateEverything();
    }
  },[selectedRisks, seem, setSelectedRisks, discardSelect]);
  
  const reasonModal = 
    <Modal
      hasAction
      hasOverflow={false}
      show
      title={
        `${discardSelect ? t("risk_source.confirm") : t("risk_source.discard")} ${t('toggle.modal_toggle.title.header.primary')}`
      }
      footer={(<h3 style={{textDecoration: 'none'}}>
        * {t('toggle.modal_toggle.title.header.tertiary')}
      </h3>)}
      onShow={setShowToggle}
      action={{
        label: discardSelect ? t("risk_source.confirm") : t("risk_source.discard"),
        loading,
        onClick: () => discardRef?.current?.submitForm(),
      }}
    >
      <ModalView>
        <P2>
          {
            `${t('toggle.modal_toggle.text.main')} ${discardSelect
              ? t('toggle.modal_toggle.text.confirm')
              : t('toggle.modal_toggle.text.discard')
            } ${t('toggle.modal_toggle.text.sub')}`
          }
        </P2>
        <Form ref={discardRef} onSubmit={(submitData) => handleSubmit(submitData)}>
          <Input
            name="reason"
            label={t('toggle.modal_toggle.reason.label')}
            placeholder={t('toggle.modal_toggle.reason.placeholder')}
            appearance="secondary"
            textarea
          />
        </Form>
      </ModalView>
    </Modal>

  return (
    <>
      <Accordion
        onChange={ onOpen }
        isOpenable={ results > 0 }
        columns={ [
          { type: 'TEXT', payload: name },
          { type: 'TEXT', payload: description },
          generateToggleColumn(active, themeID, riskSourceID, active_change_reason),
          {
            payload: [ alerts, results ],
            type: 'ALERT_RESULTS',
          },
          {
            payload: {text:tc('pending_analysis_risk'), width:250 },
            type: pendingAnalysis ? 'INFO_ICON': 'FLAG_PLACEHOLDER'
          },
          (() => {
            if (results > 0) {
              return {
                type: 'MARKED',
                payload: {
                  active: imMarked,
                  action: {
                    toggle() {
                      if (resultsIDs) {
                        if (imMarked) {
                          removeMarkedItems(resultsIDs);
                        } else {
                          addMarkedItems(resultsIDs);
                        }
                      }
                    },
                  },
                },
              };
            } else if (status == 13 || status == 14) {
              return {
                type: 'ALERT_TRIANGLE',
                payload: statusName,
              };
            }

            return { type: 'FLAG_PLACEHOLDER' };
          })(),
        ].filter((item) => !!item) }
      >
        { (!riskItems && isValidatingRiskItems) ? <SimpleLoading/> : (riskItems?.length > 0 ? (
          <table>
            {
              (isProcess(name) || isMedia(name)) &&
              <div style={{ marginLeft:'2.4rem'}}>
                <Checkbox
                  label={t("risk_source.select_all")}
                  size={'sm'}
                  selectedProp={riskItems.length === selectedRisks.length}
                  onSelected={evt => handleCheckAll(evt.target.checked)}
                />
              </div>
            }
            <tbody>
            { riskItems?.map((riskItem) => (
              <RiskItem
                key={ riskItem.id }
                data={ riskItem }
                themeName={ themeName }
                riskSourceName={ name }
                riskSourceID={ riskSourceID }
                themeID={ themeID }
              />
            )) }
            </tbody>
            {
              (isProcess(name) || isMedia(name)) &&
              <tfoot>
              <div align={'right'} style={{display: "flex", flexDirection: "row-reverse", padding: "0.5rem"}}>
                <Button
                  label={t("risk_source.confirm")}
                  size={'sm'}
                  appearance={'tertiary'}
                  style={{margin: "0.5rem"}}
                  disabled={selectedRisks.length < 1}
                  onClick={() => {
                    setDiscardSelect(true);
                    setShowToggle(true)
                  }}
                />

                <Button
                  label={t("risk_source.discard")}
                  size={'sm'}
                  appearance={'tertiary'}
                  style={{margin: "0.5rem"}}
                  disabled={selectedRisks.length < 1}
                  onClick={() => {
                    setDiscardSelect(false);
                    setShowToggle(true)
                  }}
                />
              </div>
              </tfoot>
            }
          </table>
        ) : <Empty hasButton={ false }/>) }
      </Accordion>
      { toggleModals }
      { showToggle && reasonModal }
    </>
  );
};

RiskSource.propTypes = {
  riskSourceID: number.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  active: bool,
  alerts: number.isRequired,
  results: number.isRequired,
  resultsIDs: arrayOf(number),
  themeName: string.isRequired,
  themeID: number.isRequired,
  active_change_reason: string,
};

RiskSource.defaultProps = {
  resultsIDs: [],
  active: false,
  active_change_reason: undefined,
};

export default RiskSource;

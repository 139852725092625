import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { Form } from "@unform/web";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { useMe } from "~/services";
import { useApi, useFetch } from "~/hooks";
import { P2, Modal, Button, Checkbox } from "~/components";

import { Container, ModalView, CheckboxWrapper, InfoText } from "./style";

const PartnerSettings = () => {
  const formRef = useRef();

  const api = useApi();
  const alert = useAlert();
  const { user, group } = useMe();
  const { t } = useTranslation("settings");

  const [isOpen, onOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enableLimitation, setEnableLimitation] = useState(true);

  const clientId = group.value;
  const groupId = user.grupos[0]?.idgrupo;

  const type = !clientId ? "group" : "client";
  const id = type === "client" ? clientId : groupId;

  const {
    mutate,
    isValidating,
    data: partnerSettings,
  } = useFetch({
    url: `/settings/associated-assessments?${type}Id=${id}`,
  });

  const initialPartnerSettings =
    !!partnerSettings?.data?.limitar_avaliacoes_associadas;

  const handleClick = async () => {
    await mutate();

    onOpen((prev) => !prev);
  };

  const handleEdit = async () => {
    if (initialPartnerSettings === enableLimitation) {
      alert.info(t("partner.info_message"));
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        id,
        type,
        active: enableLimitation,
      };

      await api().patch(`/settings/associated-assessments`, payload);

      await mutate();
      onOpen(false);
      alert.success(
        t(
          enableLimitation
            ? "partner.success_activate"
            : "partner.success_disable"
        )
      );
    } catch ({ response: { data } }) {
      alert.error(data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async () => {
    setIsLoading(true);

    try {
      const payload = {
        id,
        type,
        active: enableLimitation,
      };

      await api().post(`/settings/associated-assessments`, payload);

      await mutate();
      alert.success(
        t(
          enableLimitation
            ? "partner.success_activate"
            : "partner.success_disable"
        )
      );
      onOpen(false);
    } catch ({ response: { data } }) {
      alert.error(data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!isEmpty(partnerSettings?.data)) {
      if (
        (type === "client" && !partnerSettings?.data?.client_id) ||
        (type === "group" && !partnerSettings?.data?.group_id)
      ) {
        await handleCreate();
        return;
      }

      await handleEdit();
      return;
    }

    await handleCreate();
  };

  useEffect(() => {
    if (!isValidating && !isEmpty(partnerSettings?.data))
      setEnableLimitation(initialPartnerSettings);
    else setEnableLimitation(true);
  }, [partnerSettings, isValidating]);

  return (
    <Container>
      <CheckboxWrapper>
        <InfoText>{t("partner.info_text")}</InfoText>
        <Button onClick={handleClick} label={t("partner.button")} />
      </CheckboxWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal
          size="lg"
          hasActions
          show={isOpen}
          onShow={onOpen}
          showCancel={false}
          title={t("partner.modal.title")}
          action={{
            loading: isLoading,
            label: t("partner.modal.save_button"),
            onClick: () => formRef.current.submitForm(),
          }}
        >
          <ModalView>
            <P2>{t("partner.modal.description")}</P2>

            <Checkbox
              id={1}
              key={1}
              defaultChecked={enableLimitation}
              label={t("partner.modal.checkbox")}
              onChange={(e) => setEnableLimitation(e.target.checked)}
            />
          </ModalView>
        </Modal>
      </Form>
    </Container>
  );
};

export default PartnerSettings;

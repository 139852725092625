import React, { useState, useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import {
  bool,
  shape,
  string,
  arrayOf,
  obj,
  number,
} from 'prop-types';
import {Tooltip} from '~/components';
import { Info } from "react-feather";
import { Error, View, Container } from './styles';

export const Radio = ({
  name,
  options,
  labelDir,
  containerDir,
  initialValueSelected,
  ...rest
}) => {
  const inputRefs = useRef([]);
  const { error, fieldName, registerField } = useField(name);
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    setSelected(initialValueSelected);
  }, [initialValueSelected]);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue (refs) {
        const checked = refs.find((ref) => ref.checked);
        return checked ? checked.value : null;
      },
      setValue (refs, value) {
        const item = refs.find((ref) => ref.value === value);
        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container dir={containerDir}>
        {options.map((option, index) => (
          <View dir={labelDir} checked={selected === option.id} key={option.id}>
            <input
              ref={(elRef) => { (inputRefs.current[index] = elRef); }}
              type="radio"
              name={fieldName}
              value={option.id}
              defaultChecked={option.id === 1}
              onClick={() => setSelected(option.id)}
              {...rest}
              style={option.description?{width:'60%'}: {width:'100%'}}
            />
            <span />
            <label htmlFor={option.id}  style={option.description?{ display:'flex', marginLeft: '20px'}: { display:'flex'}} >{option.label}
            {option.description && (
              <Tooltip width={350} label={option.description} style={{ marginLeft: '10px' }}>
                <Info size="2rem" />
              </Tooltip>
            )}  
            </label>
          </View>
        ))}
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
};

export const MultiRadio = ({
  name,
  options,
  multiple,
}) => {
  const {
    error,
    fieldName,
    defaultValue,
    registerField,
  } = useField(name);

  const [value, setValue] = useState(defaultValue);

  const threatAsCheckbox = !! (multiple || options.length === 1);

  const nativeField = threatAsCheckbox ? 'checkbox' : 'radio';

  const getValue = () => {
    if (multiple) { return Array.from(value || []).length === 0 ? undefined : value; }
    return value === '' ? undefined : value;
  };

  registerField({ name: fieldName, ref: getValue });

  const handleChange = (e) => {
    const { checked: toAdd, value: fieldVal } = e.target;

    if (multiple) {
      const newVal = toAdd
        ? [...Array.from(value || []), fieldVal]
        : Array.from(value).filter((v) => v !== e.target.value);
      setValue(newVal);
    } else {
      setValue(toAdd ? fieldVal : '');
    }
  };

  const checked = (val) => {
    if (multiple) {
      return Array.from(value || []).includes(val);
    }

    return value === val;
  };

  return (
    <Container>
      {options.map((option) => {
        const checkboxId = `${fieldName}-${option.id}`;

        return (
          <Radio
            id={checkboxId}
            key={checkboxId}
            name={fieldName}
            type={nativeField}
            value={option.id}
            label={option.label}
            checked={checked(option.id)}
            onChange={handleChange}
            containerDir="row"
          />
        );
      })}

      {error && <span>{error}</span>}
    </Container>
  );
};

Radio.propTypes = {
  labelDir: string,
  containerDir: string,
  name: string.isRequired,
  initialValueSelected: number,
  options: arrayOf(obj).isRequired,
};

Radio.defaultProps = {
  initialValueSelected: 1,
};

MultiRadio.propTypes = {
  name: string.isRequired,
  options: arrayOf(shape({
    id: string,
    value: string,
    label: string,
  })),
  multiple: bool,
};

MultiRadio.defaultProps = {
  options: [{}],
  multiple: false,
};

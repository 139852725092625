import React from "react";
import { ChevronDown } from "react-feather";

import { Checkbox, Select } from "~/components";

import { Wrapper } from "./styles";
import { Flex, SubTitle } from "../../styles";
import { useSearch } from "../../../../context/SearchProvider";

export const AutoAssessPartners = ({ checkPrepaid }) => {
  const {
    t,
    onShow,
    options,
    checked,
    setChecked,
    personWorkflows,
    entityWorkflows,
    onEntityWorkflow,
    onPersonWorkflow,
  } = useSearch();

  const handleSelected = (e) => {
    if (checked) {
      onPersonWorkflow([]);
      onEntityWorkflow([]);
    }
    setChecked(e.target.checked);
  };

  return (
    <Flex dir="column" gap="2rem">
      <Wrapper>
        <Checkbox
          size="sm"
          selectedProp={checked}
          onSelected={handleSelected}
          label={t("evaluation.advanced_options.auto_assess_partners.title")}
        />
        <SubTitle ml="2.35rem">
          {t("evaluation.advanced_options.auto_assess_partners.sub_title")}
        </SubTitle>
      </Wrapper>
      <Flex w="100%" gap="2rem" dir="column">
        {checked && checkPrepaid && (
          <Flex dir="row" gap="2rem">
            <div style={{ flex: "1", width: "100%" }}>
              <Select
                label={t("components.workflows_pf")}
                name="personWorkflows"
                icon={ChevronDown}
                options={options.personWorkflows}
                onChange={(val) => {
                  onShow(false);
                  onPersonWorkflow(val);
                }}
                isLoading={!personWorkflows}
                placeholder={t("components.workflows_pf")}
                menuPosition="fixed"
              />
            </div>

            <div style={{ flex: "1", width: "100%" }}>
              <Select
                label={t("components.workflows_pj")}
                name="entityWorkflows"
                icon={ChevronDown}
                options={options.entityWorkflows}
                onChange={(val) => {
                  onShow(false);
                  onEntityWorkflow(val);
                }}
                isLoading={!entityWorkflows}
                placeholder={t("components.workflows_pj")}
                menuPosition="fixed"
              />
            </div>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

/* eslint-disable react/prop-types */
import React, { forwardRef, useMemo, } from 'react';
import { useAlert } from 'react-alert';
import { Edit, Paperclip } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { compareDesc } from 'date-fns';
import { saveAs } from 'file-saver';
import styled from 'styled-components';

import { P2 } from '~/components';
import { colors, typograph } from '~/styles';
import { useApi } from "~/hooks";

const TableContainer = styled.div`
  width: 100%;
  padding: 2rem 0;

  @media print {
    padding: 20px;
  }
`;

const HeaderItem = styled(P2)`
  &:first-child {
    text-align: right;
  }
  grid-column-end: span 2;
  color: ${colors.gray._500};
  & span {
    color: ${({ contentColor }) => contentColor || colors.gray._600};
  }
`;

const HeaderView = styled.section`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(12, 1fr);
`;

const ResponseContentItem = styled.li`
  font-size: ${typograph.size.s2};

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  span:not(:first-child) {
    margin-left: 0.5rem;
  }

  .respondent {
    color: ${colors.secondary}
  }

`;

const ResponseRow = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;

  .label {
    grid-column-end: span 2;
    text-align: right;
    color: ${colors.gray._500};
  }

  .content {
    grid-column-end: span 10;
  }
`;

const ResponsesView = styled.section`
  margin-top: 4rem;

  > div {
    padding: 1rem 0;

    &:nth-child(even) {
      background-color: ${colors.gray._100};
    }

    ${ResponseRow}:not(:first-child) {
      margin-top: 1rem;
    }
  }
`;

const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  color: ${colors.primary._000};
  font-weight: ${typograph.weight.semiBold};

  svg {
    color: ${colors.gray._600};
  }
`;

const SurveyHistoryTable = ({
  id, surveyID, questions, responses, alerts, risk, impact_id,
}, ref) => {
  const { t } = useTranslation('diligence');
  const alert = useAlert();

  const impactIDColors = [
    null,
    colors.status.success,
    colors.primary._100,
    colors.status.error,
    colors.status.error,
  ];

  const orderedResponses = useMemo(() => responses.sort((a, b) => {

    const aDate = new Date(a.value['submitdate']);
    const bDate = new Date(b.value['submitdate']);
    return compareDesc(aDate, bDate);
  }), [responses]);

  const questionsRow = useMemo(() => questions?.map((quest, qstIdx) => ({
    question: quest.question,
    comment: !!quest.comment ? quest.comment : null ,
    responses: orderedResponses?.map((response, idx) => {
      const value = response.value.questions[qstIdx] || '-';
      const nextValue = idx + 1 >= orderedResponses.length ? null : (orderedResponses[idx + 1].value.questions[qstIdx] || '-');
      return {
        value,
        hasChanged: !! nextValue && JSON.stringify(value.answer) !== JSON.stringify(nextValue.answer),
        participantTokenID: response.participantTokenID,
      };
    }),
  })), [questions, orderedResponses]);

  const getFileAsBlob = async ({ meta, content }) => {
    const types = {
      png: 'image/png',
      gif: 'image/gif',
      doc: 'application/msword',
      docx:'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      odt: 'application/vnd.oasis.opendocument.text',
      jpg: 'image/jpeg',
      pdf: 'application/pdf',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      jpeg: 'image/jpeg',
    };

    const type = types[meta.ext];
    if (! type) {
      throw new Error(t('surveys.modal.attach-format-not-supported'));
    }

    return fetch(`data:${type};base64,${content}`).then((res) => res.blob());
  };

  const api = useApi();
  const handleFileDownload = async (link) => {
    try {
      const { data } = await api().get(link);
      const blob = await getFileAsBlob(data);
      saveAs(blob, data.meta.name);
    } catch (ex) {
      const data = ex.response?.data;
      if (data) {
        alert.error(data.message);
      } else {
        alert.error(ex.message);
      }
    }
  };

  const genResValue = (value, participantTokenID) => {
    if (!!value) {
      if (typeof value === 'object') {
        const tmp = Object.entries(value).map((subq, idnx) => {
          return (
            <div style={{ marginTop:'2rem', marginLeft:'-2.5rem' }} key={idnx}>
              <ResponseRow>
                <P2 className="label">{t('surveys.modal.subquestion')}</P2>
                <P2 className="content">{subq[1].question}</P2>
              </ResponseRow>
              <ResponseRow>
                <P2 className="label">{t('surveys.modal.answer')}</P2>
                <div className="content">
                  {subq[1].answer === null
                      ? <span style={{ color: '#D74B28' }}>{t('surveys.not_responded')}</span>
                      : <ResponseContentItem key={idnx + 1}>
                          <span>{subq[1].answer ?? t('surveys.not_responded')}</span>
                        </ResponseContentItem>
                  }
                </div>
                {subq[1].comment &&
                    <>
                      <P2 style={{ marginTop:'-1rem' }} className="label">{t('surveys.modal.comment')}</P2>
                      <div  style={{ marginTop:'-1rem' }} className="content">
                        <ResponseContentItem key={idnx+2}>
                          <span>{subq[1].comment}</span>
                        </ResponseContentItem>
                      </div>
                    </>
                }
              </ResponseRow>
            </div>
          )
        })
        value = tmp;
      } else {
        try {
          const decoded = JSON.parse(value);
          if (Array.isArray(decoded) && Object.prototype.hasOwnProperty.call(decoded[0], 'filename')) {
            const links = decoded.map(({ filename, name }) => (
              <li key={filename}>
                <DownloadButton type="button" onClick={() => handleFileDownload(`/process/${id}/surveys/${surveyID}/files?name=${filename}&tokenID=${participantTokenID}`)}>
                  <Paperclip size="16px" />
                  {name}
                </DownloadButton>
              </li>
            ));
            return <ul>{links}</ul>;
          }
        } catch {
          // não faz nada
        }
      }
      return value;
    }
    return <span style={{ color: '#D74B28' }}>{t('surveys.not_responded')}</span>;
      
  };

  return (
    <TableContainer ref={ref}>
      <HeaderView>
        <HeaderItem contentColor={impactIDColors[impact_id]}>
          {t('surveys.modal.risk_label')}
          <span>{risk}</span>
        </HeaderItem>
        <HeaderItem>
          {t('surveys.modal.alerts_label')}
          <span>{alerts}</span>
        </HeaderItem>
      </HeaderView>
      <ResponsesView>
        {
          questionsRow?.map((line, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={idx}>
              <ResponseRow>
                <P2 className="label">{t('surveys.modal.question')}</P2>
                <P2 className="content">{line.question}</P2>
              </ResponseRow>
              <ResponseRow>
                <P2 className="label">{t('surveys.modal.answers')}</P2>
                <div className="content">
                  <ul>
                    {line.responses?.map((res, resIdx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <>
                        <ResponseContentItem key={resIdx}>
                          <span className="respondent">{`${orderedResponses[resIdx].label.split('-')[0].trim()}:`}</span>
                          <span>{res.hasChanged ? <Edit size="12px" color={colors.status.error} /> : null}</span>
                          <span>{genResValue(res.value.answer, res.participantTokenID)}</span>
                        </ResponseContentItem>
                        
                      </>
                    ))}
                  </ul>
                </div>
              </ResponseRow>
              {line.comment &&
                <ResponseRow>
                  <P2 className="label">{t('surveys.modal.comment')}</P2>
                  <P2 className="content">{line.comment}</P2>
                </ResponseRow>
              }
            </div>
          ))
        }
      </ResponsesView>
    </TableContainer>
  );
};

export default forwardRef(SurveyHistoryTable);

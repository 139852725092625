import React from 'react';
import { Helmet as Head } from 'react-helmet';

import { string } from 'prop-types';

const Helmet = ({ title }) => (
  <Head titleTemplate="Aliant | %s">

    <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_CODE}`} />
    <script>
      {
          `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.REACT_APP_GA_TRACKING_CODE}');`
        }
    </script>

    <meta charSet="utf-8" />

    <title>{title}</title>

    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600;700&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap" rel="stylesheet" />
  </Head>
);

Helmet.propTypes = {
  title: string.isRequired,
};

export default Helmet;

/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Info, ChevronDown, ChevronRight, Edit, Flag as Marked, AlertTriangle,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  bool, string, func, shape, oneOfType, node, object, arrayOf,
} from 'prop-types';

import {
  ProgressBar, P1, P2, Flag, Toggle,
} from '~/components';
import { reduce } from '~/hooks';
import { useMe } from '~/services';
import { colors } from '~/styles';

import Tooltip from '../Tooltip';
import { View, Body, Head } from './styles';

const Accordion = ({
                     columns, children, initialState, redirect, onChange, bodyClassName, isOpenable,
                     hasOverflow, isInfo, showAll, ...rest
                   }) => {
  const { user } = useMe();
  const { t } = useTranslation('components');
  const navigate = useNavigate();
  const [ show, onShow ] = useState(initialState);
  const columnsNumber = useMemo(() => columns.reduce((acc, curr) => (curr.type !== 'DATA' ? acc + 1 : acc), 0), [ columns ]);
  const sentenceMaxLength = useMemo(() => 112 / columnsNumber, [ columnsNumber ]);
  const anchorRef = useRef(null);
  let instanceCount = 0;
  const onClick = () => {
    onChange(!show);
    onShow(!show);
    if (!show)
      window.scrollTo(0, (anchorRef.current.offsetTop - 68));
  };

  useEffect(() => {
    instanceCount++;
  }, []);
  
  useEffect(() => {
    onShow(showAll);
  }, [showAll]);

  return (
    <View
      { ...rest }
      show={ show }
      columnsNumber={ columnsNumber }
      ref={ anchorRef }
    >
      <Head
        key={ instanceCount.toString() }
        isInfo={ !!isInfo }
        isOpenable={ isOpenable }
        columnsNumber={ columnsNumber }
      >
        { columns.map(({ payload, type }, index) => {
          switch (type) {
            case 'TITLE':
              return (
                <P1 key={ `${ index } ${ payload }` } title={ payload } isBold>
                  { reduce(payload, sentenceMaxLength) }
                </P1>
              );
            case 'TEXT':
              return (
                <P2 key={ `${ index } ${ payload }` } title={ payload }>
                  { reduce(payload, sentenceMaxLength) }

                  { isInfo && (
                    <Tooltip label={ isInfo } width={ 480 }>
                      <Info/>
                    </Tooltip>
                  ) }
                </P2>
              );
            case 'FLAG':
              return (
                <P2 key={ `${ index } ${ payload }` }>
                  <Flag status={ payload }/>
                </P2>
              );
            case 'INFO_ICON':
              return (
                <Tooltip label={ payload.text } width={ payload.width }>
                  {payload && <AlertTriangle color={ colors.orange._400 } size="2rem"/> }
                </Tooltip>
              );
            case 'PROGRESS_BAR':
              return (
                <P2 key={ `${ index } Progress-bar` } className="progress-bar">
                  <ProgressBar
                    percentage={ Number(payload?.percentage) }
                    impact_id={ payload?.impact_id || 0 }
                  />
                </P2>
              );
            case 'ALERT':
              return (
                <P2 key={ `${ index } ${ payload }` } isBold className="alert"> 
                  <span className={ payload[2] ? `alert-${payload[2]}` : payload[0] > 0 ? `alert-danger` : 'alert' }>
                    { `${ payload[0] || 0 } ${ t('accordion.alerts.alerts') } ` }
                  </span>
                  <span>{ t('accordion.alerts.in') }</span>
                  <span>
                    { ` ${ payload[1] || 0 } ${ t('accordion.alerts.sources') }` }
                  </span>
                </P2>
              );
            case 'ONLY_ALERT':
              return (
                <P2 key={ index } isBold className="alert">
                  <span className={ payload[2] ? `alert-${payload[2]}` : payload[0] > 0 ? 'alert-danger' : 'alert' }>
                    { `${ payload[0] || 0 } ${ t('accordion.alerts.alerts') } ` }
                  </span>
                </P2>
              );
            case 'ALERT_RESULTS':
              return (
                <P2 key={ index } isBold className="alert">
                  <span className={ payload[2] ? `alert-${payload[2]}` : payload[0] > 0 ? 'alert-danger' : 'alert' }>
                    { `${ payload[0] || 0 } ${ t('accordion.alerts.alerts') } ` }
                  </span>
                  <span>{ t('accordion.alerts.in') }</span>
                  <span>
                    { ` ${ payload[1] || 0 } ${ t('accordion.alerts.results') }` }
                  </span>
                </P2>
              );
            case 'MARKED':
              return (
                <div key={ index }>
                  <button
                    type="button"
                    aria-label="action"
                    // className="clipboard"
                    onClick={
                      payload.action.toggle
                    }
                  >
                    { payload.active ? <Marked size="1.6rem" color={ colors.primary._000 }/> :
                      <Marked size="1.6rem" color="#B6BACD"/> }
                  </button>
                </div>
              );
            case 'TOGGLE':
              return (
                <P2 key={ index } as="div" className={ `toggle ${ payload.action.edit ? 'modified' : '' }` }>
                  <Toggle
                    disabled={ user?.isVisualizador() }
                    checked={ payload.value }
                    onClick={ () => {
                      payload.action.toggle();
                    } }
                    isModified={ !!payload.action.edit }
                  />
                  <span>
                    { payload.value ? (
                      <>
                        <span>
                          { t('tablerow.toggle.confirmed.label') }
                        </span>
                        { payload.action.edit && (
                          <Tooltip label={ t('tablerow.toggle.confirmed.tooltip') } width={ 250 }>
                            <Edit onClick={ payload.action.edit }/>
                          </Tooltip>
                        ) }
                      </>
                    ) : (
                      <>
                        <span>
                          { t('tablerow.toggle.discarded.label') }
                        </span>
                        { payload.action.edit && (
                          <Tooltip label={ t('tablerow.toggle.discarded.tooltip') } width={ 250 }>
                            <Edit onClick={ payload.action.edit }/>
                          </Tooltip>
                        ) }
                      </>
                    ) }
                  </span>
                </P2>
              );
            case 'FLAG_PLACEHOLDER':
              return <span style={ { width: '28px' } }>&nbsp;</span>;
            case 'ALERT_TRIANGLE':
              return (
                <Tooltip
                  label={ (
                    <>
                      { payload }
                    </>
                  ) }
                  width={ 220 }
                >
                  <AlertTriangle size="16px" color="#7F859D"/>
                </Tooltip>
              );
            default:
              break;
          }

          return null;
        }) }

        <button type="button"
                onClick={ () => {
                  onClick();
                  if (redirect)
                    navigate(redirect);
                } }
                data-label={ isOpenable ? t('arrow.see') : '' }
        >

          { isOpenable && (
            <>
              { redirect ? (<ChevronRight/>) : <ChevronDown/> }
            </>
          ) }
        </button>
      </Head>
      { isOpenable && show && (
        <Body className={ bodyClassName } hasOverflow={ hasOverflow }>{ children }</Body>
      ) }
    </View>
  );
};

Accordion.propTypes = {
  children: node,
  columns: arrayOf(shape({
    payload: oneOfType([ node, object ]),
    type: string.isRequired,
  })).isRequired,
  initialState: bool,
  redirect: string,
  onChange: func,
  bodyClassName: string,
  isOpenable: bool,
  hasOverflow: bool,
  isInfo: string,
  showAll: bool,
};

Accordion.defaultProps = {
  children: null,
  initialState: false,
  redirect: '',
  onChange: () => true,
  bodyClassName: '',
  isOpenable: true,
  hasOverflow: false,
  isInfo: null,
  showAll: false,
};

export default Accordion;

import { AuthProvider, RisksProvider, MeProvider } from './services';
import React from 'react';
import { Provider as AlertProvider } from 'react-alert';
import { I18nextProvider } from 'react-i18next';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak, keycloakConfig } from './services/keycloak';
import { Alert, options, SimpleLoading } from './components';
import { EchoProvider } from './services/echo';
import { GlobalStyles } from './styles';
import Router from './routes';
import UnAuthRouter from './UnAuthRouter';
import i18n from './i18n';
import { ResultContextProvider } from '~/views/Search/Response/Diligence2/resultContext';

const App = () => {
  if (window.location.href.includes('plano-de-acao/answer?jwt=')) {
    localStorage.setItem('dd-token', window.location.href.split('?jwt=')[ 1 ]);

    return (
      <AlertProvider template={ Alert } { ...options }>
        <I18nextProvider i18n={ i18n }>
          <GlobalStyles />
          <UnAuthRouter />
        </I18nextProvider>
      </AlertProvider>
    );
  }

  return (
    <ReactKeycloakProvider
      LoadingComponent={ <SimpleLoading size={ 'lg' } /> }
      initOptions={ keycloakConfig }
      authClient={ keycloak }>
      <AuthProvider>
        <MeProvider>
          <AlertProvider template={ Alert } { ...options }>
            <EchoProvider>
              <I18nextProvider i18n={ i18n }>
                <GlobalStyles />
                <ResultContextProvider>
                  <RisksProvider>
                    <Router />
                  </RisksProvider>
                </ResultContextProvider>
              </I18nextProvider>
            </EchoProvider>
          </AlertProvider>
        </MeProvider>
      </AuthProvider>
    </ReactKeycloakProvider>
  );
};

export default App;

import styled, { css } from 'styled-components';

import { H5 } from '~/components/Title';
import { rgba } from '~/hooks';
import { colors, easing, typograph } from '~/styles';

const {
  gray,
  primary,
  support,
} = colors;

const {
  basic,
} = easing;

const {
  size,
} = typograph;

export const Foot = styled(H5)`
  position: relative;
  color: ${primary._000};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: .8rem;
  border-top: .1rem solid rgba(182,186,205,.2);
`;

export const Head = styled(H5)`
  position: relative;
  color: ${primary._000};
  display: flex;
  align-items: center;
  padding-bottom: .8rem;
  justify-content: space-between;
  word-break: break-all;

  & > span {
    display: flex;
    & svg:not(:first-child) {
      margin-left: 1rem;
    }
  }

  ${({ titleOnly }) => ! titleOnly && css`
    border-bottom: .1rem solid ${rgba(gray._400, '.2')};
  `}

  ${({ hasTitle }) => ! hasTitle && css`
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    padding: 0;
  `};

  svg {
    stroke: ${support._100};
    cursor: pointer;

    ${({ hasTitle }) => ! hasTitle && css`
      stroke: #fff;
      position: absolute;
      padding: 1.1rem;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      width: 4.4rem;
      height: 4.4rem;
      border-radius: 2.2rem;
      background: ${gray._400};
    `}
  }
`;

export const Wrapper = styled.div`
  top: ${({ hasTitle }) => (hasTitle ? '12%' : 0)};
  width: 100%;
  padding: ${({ hasTitle }) => (hasTitle ? ' 2.4rem 2.4rem 1.6rem 2.4rem' : '0 2.4rem 1.6rem')};
  position: relative;
  max-width: ${(props) => (props.size === 'md' ? '60.0rem' : '100.0rem')};
  transition: all 0.4s ${basic};
  border-radius: .8rem;
  background-color: ${gray._000};

  ${({ titleOnly }) => ! titleOnly && css`
    min-height: 28.4rem;
  `}
  ${({noMinHeight}) => noMinHeight && css`
    min-height: unset ;
  `};
`;

export const View = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  opacity: 0;
  z-index: 99;
  position: fixed !important;
  transition: all 0.3s ${basic};
  align-items: center;
  pointer-events: none;
  justify-content: center;
  background-color: transparent;

  ${({ show }) => show && css`
    opacity: 1;
    pointer-events: all;
    background-color: ${rgba(gray._600, '.6')};

    ${Wrapper} {
      top: 0;
    }
  `}
`;

export const Body = styled.div`
  display: flex;
  padding-top: 2.4rem;
  flex-direction: column;
  
  ${({ hasFooter }) => hasFooter && css`
    min-height: 170px;
    padding-bottom: 2.4rem;
  `}

  & > div:first-of-type {
    display: flex;
    max-height: ${({ hasTitle }) => (hasTitle ? '48.0rem' : '60.0rem')};
    flex-direction: column;
  }

  & > div:first-of-type.overflow{
    overflow: auto !important;
  }
`;

export const Actions = styled.div`
  display: ${({ hasTitle }) => (hasTitle ? 'flex' : 'none')};
  border-top: .1rem solid ${gray._300};
  margin-top: 2.4rem;
  padding-top: 1.6rem;
  align-items: center;
  justify-content: ${({ subAction }) => (subAction ? 'flex-start' : 'flex-end')};

  & > * {
    margin-left: 1.6rem;
  }

  ${({ subAction }) => subAction && css`
    button:first-of-type {
      color: ${support._100};
      margin: 0 auto 0 0;
      padding: 0;
      font-size: ${size.s2};
      text-decoration: underline;
    }
  `}
`;

import React, { useMemo } from 'react';
import { ArrowRight } from 'react-feather';
import { useTranslation } from 'react-i18next';

import {
  bool,
  number,
  arrayOf,
  oneOfType,
} from 'prop-types';

import { View, Indicator } from './styles';

const RiskLevel = ({
  id,
  risk,
  maxRisk,
  is_critical,
  indicator,
  hasIndicator,
}) => {
  const { t } = useTranslation('components');

  const impactId = useMemo(() => {
    let response = Number(id);
    if (is_critical) {
      return 4;
    }
    return response;
  }, [id]);

  const status = useMemo(() => {
    switch (impactId) {
      case 1:
        return t('risks.low');
      case 2:
        return t('risks.medium');
      case 3:
        return t('risks.high');
      case 4:
        return t('risks.critical');
      default:
    }
    return null;
  }, [t, impactId]);

  return (
    <View
      type={impactId}
      hasIndicator={hasIndicator}
    >
      {maxRisk
        ? `(${risk || 0}%) - (${maxRisk || 100}%)`
        : `${status || t('risks.low')} (${risk || 0}%)`}

      {hasIndicator && (
        <Indicator indicator={indicator}>
          <ArrowRight />
        </Indicator>
      )}
    </View>
  );
};

RiskLevel.propTypes = {
  id: number,
  risk: number,
  maxRisk: oneOfType([number, bool]),
  is_critical: bool,
  indicator: number,
  hasIndicator: bool,
};

RiskLevel.defaultProps = {
  id: 0,
  risk: 0,
  maxRisk: false,
  is_critical: false,
  indicator: 0,
  hasIndicator: false,
};

export default RiskLevel;

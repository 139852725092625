import React from "react";
import { useTranslation } from "react-i18next";

import { H1 } from "~/components";

import { SectionView as View } from "../../styles";
import ActionPlan from "./ActionPlan";
import Observation from "./Observation";
import Comments from "./Comments";

const ActionAndObservation = () => {
  const { t } = useTranslation("diligence");
  return (
    <View>
      <H1>{t("action_and_observation.title")}</H1>
      <ActionPlan />
      <Observation />
      <Comments />
    </View>
  );
};

export default ActionAndObservation;

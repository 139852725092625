import React from 'react';

import { string } from 'prop-types';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

const Button = styled.button`
  width: 100%;
  height: 400px;
`;

const Embed = styled.embed`
  width: 100%;
  height: 400px;
`;

const PdfsViewer = ({ data }) => 
  <Embed style={{"width":"100%"}} src={data} />

PdfsViewer.propTypes = {
  data: string.isRequired,
};

export default PdfsViewer;

import React from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-feather";

import { Select } from "~/components";
import { useMe } from "~/services";

import { MainForm } from "./style";

const GroupSettings = () => {
  const { t } = useTranslation("settings");

  const { user, group, newGroup } = useMe();

  const grupos = user.hierarquia?.map((item) => ({
    value: item.id,
    label: item.cliente,
  }));

  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }

  if (grupos?.length > 1 && user.grupos && user.grupos.length > 0) {
    grupos.unshift({
      value: 0,
      label: user.grupos[0].grupo,
    });
  }

  return (
    <MainForm>
      <Select
        name="client"
        isReveal={user?.hierarquia?.length > 1}
        icon={ChevronDown}
        value={group}
        options={grupos}
        onChange={(val) => {
          newGroup(val);
        }}
        appearance="secondary"
        placeholder={t("risk.client.placeholder")}
        clearField={{
          label: t("conflict.client.clean"),
          onClick: () => newGroup({}),
        }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </MainForm>
  );
};

export default GroupSettings;

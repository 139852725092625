import styled from 'styled-components';

import { colors } from '~/styles';

const { status, primary, gray } = colors;
const { error, success } = status;

export const View = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Bar = styled.div`
  position: relative;
  width: 18rem;
  border-radius: 1.0rem;
  height: .7rem;
  background: ${gray._200};
  margin: .8rem 0;

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 1.0rem;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  ${Bar} {
    span {
      width: ${({ percentage }) => `${percentage}%`};
      background: ${success};
      background: ${({ riskLevel }) => riskLevel === 2 && primary._100};
      background: ${({ riskLevel }) => (riskLevel === 3 || riskLevel === 4) && error};
    }
  }

  p {
    color: ${gray._600};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    color: ${gray._500};
  }
`;

import { Form as Unform } from '@unform/web';
import styled, { css } from 'styled-components';

import { P2 } from '~/components';
import {
  breakpoints, colors, easing, rotate360, typograph,
} from '~/styles';

const {
  gray, status, support, primary,
} = colors;

const { basic } = easing;

const { size, weight } = typograph;

export const Title = styled.h6`
  color: ${gray._500};
`;

export const List = styled.ul`
  display: flex;
  margin-top: 0rem;
  flex-direction: column;

  li {
    display: none;
    padding: 0.8rem 0.6rem;
    margin-top: 0.4rem;
    align-items: center;

    &:first-of-type {
      display: flex;
    }

    ${P2} {
      color: ${gray._600};
      font-weight: ${weight.bold};
    }

    ${P2}:not(:first-child) {
      margin-left: 2rem;
    }

    &:nth-child(odd) {
      background-color: ${gray._100};
    }
  }

  ${({ show }) => show
    && css`
      li {
        display: flex;
      }
    `}
`;

export const ViewModal = styled.div`
  p {
    color: ${gray._500};
    margin-bottom: 2.5rem;
  }
`;

export const AnalysisButton = styled.div`
  position: relative;

  :focus-within {
    & > div {
      display: block;
    }
  }

  > div {
    display: none;
    position: absolute;
    top: 6rem;
    z-index: 3;
    border-radius: 0.4rem;
    width: 30rem;
    background: ${gray._000};
    left: calc(50%);
    transform: translateX(-50%);
    padding: 0 1rem;
    box-shadow: 0 0 0.5rem ${gray._300};

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      border-bottom: 0.1rem solid ${gray._200};
      color: ${gray._500};
      padding: 1rem 0;
      cursor: pointer;
    }
  }
`;

export const ReportButton = styled.div`
  position: relative;

  :focus-within {
    & > div {
      display: block;
    }
  }

  > div {
    display: none;
    position: absolute;
    top: 6rem;
    z-index: 3;
    border-radius: 0.4rem;
    width: 30rem;
    background: ${gray._000};
    left: calc(80%);
    transform: translateX(-50%);
    padding: 0 1rem;
    box-shadow: 0 0 0.5rem ${gray._300};

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: right;
      border-bottom: 0.1rem solid ${gray._200};
      color: ${gray._500};
      padding: 1rem 0;
      cursor: pointer;
    }
  }
`;

export const AnalysisIcon = styled.div`
  svg {
    color: ${({ hasBeenSent }) => (hasBeenSent ? status.success : support._100)};
    background-color: ${({ hasBeenSent }) => (hasBeenSent ? status.success : support._100)}1A;
    margin-right: 0.5rem;
    padding: 0.4rem;
    border-radius: 50%;
  }
`;

export const View = styled.div`
  display: flex;
  padding: 2.4rem;
  flex-direction: column;
  background-color: ${gray._000};

  article {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 1.6rem;
      }
    }
  }

  & .diligence-card {
    border-top: 0.1rem solid ${gray._200};
    margin-top: 1.6rem;
    padding-top: 3.2rem;

    > ul {
      margin: 2.4rem 0;
      display: grid;
      column-gap: 16rem;
      grid-template-columns: repeat(2, 1fr);

      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > ${P2} {
          margin-bottom: 1.5rem;
        }
      }

      ${P2} {
        color: ${gray._500};

        span {
          color: ${gray._600};
          margin-left: 0.4rem;
        }

        button {
          width: 100%;
          color: ${primary._000};
          display: flex;
          padding: 0.5rem;
          font-size: ${size.s2};
          font-weight: ${weight.bold};
          line-height: ${size.m1};
          align-items: center;
          justify-content: center;

          svg {
            transform: translate(0);
            transition: all 0.3s ${basic};
            margin-left: 0.8rem;
          }
        }
      }
    }

    > table {
      width: 100%;
      border-spacing: 0;
      margin-top: 3.6rem;
    }

    table {
      tbody {
        transform: translateY(-1.2rem);
      }
    }
  }

  .alert {
    color: ${status.success};
    font-size: ${weight.regular};
  }

  .alert-danger {
    color: ${status.error};
    font-size: ${weight.regular};
  }

  .loading {
    color: ${gray._600};
    animation: ${rotate360} infinite 2s;
  }

  .form-seem {
    display: flex;
    flex-direction: column;

    & > div:not(:first-of-type) {
      margin-top: 1.6rem;
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    article > div {
      button {
        margin-left: 0.8rem;
      }
    }

    & .diligence-card > ul {
      margin: 2.4rem 0;
      column-gap: 2rem;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    article {
      display: flex;

      ${({ isSimplified }) => ! isSimplified
        && css`
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          &,
          & > div,
          button {
            width: 100%;
          }
        `}

      > div {
        display: ${({ isSimplified }) => (isSimplified ? 'flex' : 'grid')};
        gap: 0.8rem;
        grid-template-columns: repeat(6, 1fr);
        margin-top: ${({ isSimplified }) => (isSimplified ? 0 : '1.6rem')};

        > *,
        button {
          grid-column: span 2;
          margin: 0;

          ${({ isEntity, isSimplified }) => ! isEntity
            && ! isSimplified
            && css`
              &:nth-child(2),
              &:nth-child(3) {
                width: 100%;
                grid-row: 2 / 3;
                grid-column: span 3;
              }
            `}

          ${({ isSimplified }) => isSimplified
            && css`
              &:first-child {
                grid-column: span 3;
              }
            `}
        }

        > button {
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    article {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      > div {
        display: grid;
        margin-top: 1.6rem;
        width: 100%;
      }
    }

    & .diligence-card > ul {
      grid-template-columns: 1fr;
    }
  }
`;

export const ReportsModal = styled(Unform)`
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  & > div {
    display: grid;
    column-gap: 1.6rem;
    grid-template-columns: repeat(2, 1fr);
  }

  & > ${P2} {
    color: ${gray._600};
  }

  & > ${P2}, & > div:first-of-type {
    margin-bottom: 1.6rem;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    & > div {
      display: grid;
      row-gap: 1.6rem;
      grid-template-columns: 1fr;

      & > div {
        width: 100%;
      }
    }
  }
`;
export const ReportsValuesModal = styled(Unform)`
  display: flex;
  flex-direction: column;

  & > div {
    display: grid;
    column-gap: 1.6rem;
    grid-template-columns: repeat(2, 1fr);


  }

  & > ${P2} {
    color: ${gray._600};
  }

  & > ${P2}, & > div:first-of-type {
    margin-bottom: 1.6rem;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    & > div {
      display: grid;
      row-gap: 1.6rem;
      grid-template-columns: 1fr;

      & > div {
        width: 100%;
      }
    }
  }
`;
export const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  & > ${P2} {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;

    & > div {
      margin-top: 1.6rem;
    }
  }
`;

export const ActionMonitoring = styled.span`
  display: flex;

  & > svg {
    width: 3rem !important;
    stroke: ${status.success};
  }

  ${({ actived }) => (actived?.deleted_by
    ? css`
          cursor: not-allowed;
          & > svg * {
            stroke: ${gray._300};
          }
        `
    : actived
        && css`
          & > svg * {
            stroke: ${primary._000};
          }
        `)}
`;

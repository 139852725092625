import React from 'react';
import {useTranslation} from 'react-i18next';

import {H1} from '~/components';

import {useResultData} from '../../resultContext';
import {SectionView as View} from '../../styles';
import ThemeRisk from './ThemeRisk';

const DiligenceRiskByTheme = () => {
  const risksByTheme = [];
  const otherRisks = [];

  const {t} = useTranslation('diligence');

  const {themes} = useResultData();

  if (themes) {
    themes.forEach((theme) => {
      if (theme.other_risk === 1) {
        otherRisks.push(theme);
      } else {
        risksByTheme.push(theme);
      }
    });
  }

  return (
    <>
      {
        risksByTheme.length > 0 && (
          <View>
            <H1>{t('risk_by_theme.title.main')}</H1>
            {
              risksByTheme.map((theme) => (
                <ThemeRisk
                  key={`[${theme.id}] ${theme.name}`}
                  themeID={theme.id}
                  themeName={theme.name}
                  risk={theme.risk}
                  impactID={theme.probabilidade_id}
                  alerts={theme.alerts}
                  sources={theme.sources}
                  status={theme.status}
                  pendigAnalysis={theme.pending_analysis}
                />
              ))
            }
          </View>
        )
      }
      {
        otherRisks.length > 0 && (
          <View>
            <H1>{t('risk_by_theme.title.other')}</H1>
            {otherRisks.map((theme) => (
              <ThemeRisk
                key={`[${theme.id}] ${theme.name}`}
                themeID={theme.id}
                themeName={theme.name}
                risk={theme.risk}
                impactID={theme.probabilidade_id}
                alerts={theme.alerts}
                sources={theme.sources}
                status={theme.status}
                hasProcesses={theme.has_processes === 1}
              />
            ))}
          </View>
        )
      }
    </>
  );
};

export default DiligenceRiskByTheme;

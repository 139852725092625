const table = [
  {
    isSort: false,
    size: 'xxs',
  },
  {
    size: 'xxs',
    isSort: false,
  },
  {
    size: 'sm',
    isSort: false,
  },
  {
    size: 'xs',
    isSort: false,
  },
  {
    size: 'xxs',
    isSort: false,
  },
  {
    size: 'xxs',
    isSort: false,
  },
];

export default table;

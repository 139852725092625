import React, { Fragment } from "react";

import { useMe } from "~/services";
import { useFetch } from "~/hooks";
import { Checkbox } from "~/components";

import { Wrapper } from "./styles";
import { Flex, SubTitle } from "../../styles";
import { useSearch } from "../../../../context/SearchProvider";

export const AssessWithoutLimitation = () => {
  const {
    t,
    forceNoLimitAssociatedAssessments,
    setForceNoLimitAssociatedAssessments,
  } = useSearch();

  const { user, group } = useMe();

  const clientId = group.value;
  const groupId = user.grupos[0]?.idgrupo;

  const type = !clientId ? "group" : "client";
  const id = type === "client" ? clientId : groupId;

  const handleChecked = (e) => {
    setForceNoLimitAssociatedAssessments(e.target.checked);
  };

  const { data: partnerSettings } = useFetch({
    url: `/settings/associated-assessments?${type}Id=${id}`,
  });

  const initialPartnerSettings =
    !!partnerSettings?.data?.limitar_avaliacoes_associadas;

  return (
    <Fragment>
      {initialPartnerSettings && (
        <Flex dir="column" gap="2rem">
          <Wrapper>
            <Checkbox
              size="sm"
              onSelected={handleChecked}
              selectedProp={forceNoLimitAssociatedAssessments}
              label={t(
                "evaluation.advanced_options.assess_without_limitation.title"
              )}
            />
            <SubTitle ml="2.35rem">
              {t(
                "evaluation.advanced_options.assess_without_limitation.sub_title"
              )}
            </SubTitle>
          </Wrapper>
        </Flex>
      )}
    </Fragment>
  );
};

import styled from "styled-components";

import { colors, typograph } from "~/styles";
import { P2 } from "../Title";

const { secondary, gray, primary, cyan } = colors;
const { weight } = typograph;

const Container = styled.section`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  right: 0;
  top: 7rem;
  box-shadow: 0 0 0.5rem ${gray._300};
  width: 32rem;

  section {
    background: ${gray._000};
  }

  section:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${gray._100};
    padding: 2rem 1.5rem;
    border-bottom: 0.2rem solid ${gray._200};

    > h5 {
      display: flex;
      align-items: center;
      color: ${primary._000};

      svg {
        opacity: 0.5;
        cursor: pointer;
      }
    }

    > p {
      color: ${cyan._500};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  section:last-of-type {
    background: ${gray._100};

    > div {
      border-bottom: 0.2rem solid ${gray._200};
      padding: 2rem 1.5rem;

      h6 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${gray._600};
        margin-bottom: 1rem;

        svg {
          padding: 0.4rem;
          cursor: pointer;
        }

        > svg {
          background: ${gray._300};
          border-radius: 50%;
          color: ${primary._000};
        }
      }

      p {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: ${gray._500};

        i {
          width: 70%;
          font-style: normal;
        }

        b {
          font-size: ${weight.bold};
        }

        :first-of-type {
          min-height: 6rem;
        }
      }

      ${P2} {
        margin-top: 1rem;
        color: ${cyan._500};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

export default Container;

import styled, { css } from 'styled-components';

import { colors, typograph, easing } from '~/styles';

const { gray, primary, white, green, secondary } = colors;

const { basic } = easing;

const { size: font, weight, family } = typograph;

const sizes = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
};

const appearances = {
  primary: 'primary',
  tertiary: 'tertiary',
  secondary: 'secondary',
};

export const View = styled.button`
  border: .1rem solid transparent;
  display: flex;
  font-size: ${font.s3};
  transition: all 0.3s ${basic};
  font-family: ${family};
  white-space: nowrap;
  line-height: 2.8rem;
  font-weight: ${weight.regular};
  align-items: center;
  border-radius: .4rem;
  background-color: transparent;
  white-space: nowrap;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  /**
   * Appearences.
   */
  ${({ appearance }) => appearance === appearances.primary
    && css`
      color: ${gray._000};
      background-color: ${primary._000};

      &:hover {
        background-color: ${primary._100};
      }

      &:focus {
        border-color: ${primary._200};
        background-color: ${primary._000};
      }
    `}

  ${({ appearance }) => appearance === appearances.secondary
    && css`
      color: ${white};
      border-color: ${green._600};
      background-color: ${secondary};
      &:hover {
        color: ${gray._000};
        background-color: ${green._600};
      }

      &:focus {
        color: ${gray._000};
        border-color: ${primary._200};
        background-color: ${green._700};
      }
    `}

  ${({ appearance }) => appearance === appearances.tertiary
    && css`
      color: ${primary._000};
      border-color: ${primary._000};

      svg {
        stroke: ${primary._000};
      }

      &:hover,
      &:focus {
        color: ${white};
        background-color: ${primary._000};

        svg {
          stroke: ${white};
        }
      }
    `}

  /**
   * Sizes.
   */
  ${({ size }) => size === sizes.xs
    && css`
      width: 3.2rem;
      height: 3.2rem;
      padding: .4rem;
      font-weight: ${weight.bold};
      justify-content: center;
    `}

  ${({ size }) => size === sizes.sm
    && css`
      padding: .8rem 1.6rem;
    `}

  ${({ size }) => size === sizes.md
    && css`
      padding: 1.4rem 2.4rem;
    `}

  ${({ isLoading }) => isLoading
    && css`
      min-width: 7.2rem;
      min-height: 4.6rem;
      justify-content: center;
      pointer-events: none;
    `}

  ${({ disabled }) => disabled
    && css`
      color: ${gray._400};
      cursor: not-allowed;
      border-color: ${gray._300};
      background-color: ${gray._300};

      svg {
        stroke: ${gray._400};
      }

      &:hover,
      &:focus {
        color: ${gray._400};
        border-color: ${gray._300};
        background-color: ${gray._300};

        svg {
          stroke: ${gray._400};
        }
      }
    `}

  /**
   * Icon.
   */
  ${({ iconLeft }) => iconLeft
    && css`
      svg {
        margin-right: 1.0rem;
      }
    `}
`;

export const Loading = styled.div``;

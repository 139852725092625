import React from "react";
import { Quick } from "./components";
import { SearchProvider } from "./context/SearchProvider";

const Search = () => {
  return (
    <SearchProvider>
      <Quick />
    </SearchProvider>
  );
};

export default Search;

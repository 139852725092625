import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { arrayOf, number, string } from 'prop-types';

import { P2 } from '~/components';

import {
  Bar,
  View,
  Content,
  Container,
} from './styles';

const ProgressBarComplex = ({
  percentage, title, value, impact_id, defaultRiskLevels,
}) => {
  const { t } = useTranslation('components');

  const impactId = useMemo(() => {
    let response = Number(impact_id);
    if (response === 0) {
      response = defaultRiskLevels.findIndex((val, idx) => {
        if (defaultRiskLevels[idx + 1]) {
          return percentage >= val && percentage < defaultRiskLevels[idx + 1];
        }
        return true;
      }) + 1;
    }
    return response;
  }, [percentage, impact_id, defaultRiskLevels]);

  const riskLevel = useMemo(() => {
    switch (impactId) {
      case 1:
        return t('risks.low');
      case 2:
        return t('risks.medium');
      case 3:
        return t('risks.high');
      case 4:
        return t('risks.critical');
      default:
    }
  }, [t, impactId]);

  return (
    <Container riskLevel={impactId} percentage={percentage}>
      {! value || value === 0
        ? <P2>{t('charts.search')}</P2>
        : (
          <View>
            <P2>{title}</P2>
            <Bar>
              <span />
            </Bar>
            <div>
              <Content>
                <P2>
                  {`${percentage}% (${value})`}
                </P2>
                <P2>{riskLevel}</P2>
              </Content>
            </div>
          </View>
        )}
    </Container>
  );
};

ProgressBarComplex.propTypes = {
  percentage: number.isRequired,
  title: string.isRequired,
  value: string.isRequired,
  impact_id: number,
  defaultRiskLevels: arrayOf(number),
};

ProgressBarComplex.defaultProps = {
  impact_id: 0,
  defaultRiskLevels: [0, 25, 40, 70],
};

export default ProgressBarComplex;

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useAlert } from 'react-alert';
import {
  Edit, Paperclip, Plus,
  ChevronDown,
  Search, CornerUpRight
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { Form } from '@unform/web';
import { format, isBefore } from 'date-fns';

import * as Yup from 'yup';

import {
  Accordion,
  Button,
  Container,
  Empty,
  Flag,
  H6,
  Input,
  ItemsList,
  Loading,
  Modal,
  P1,
  P2,
  ProgressBar,
  ProgressCircle,
  Select,
  SimpleLoading,
  Table,
  TableRow,
  Upload,
  SimpleSelect,
} from '~/components';
import PopupViewer from '~/components/PopupViewer';
import PopupWrapper from '~/components/PopupWrapper';
import {
  masked, reduce, useDebounce, useFetch, useApi
} from '~/hooks';
import { formatErrorMessage, maskMoney, parseISO } from '~/hooks/helpers';
import useWebSocketRefresh from '~/hooks/useWebSocketRefresh';
import { EditInput, ResultHead } from '~/layout';
import { error, useMe } from '~/services';
import { Card } from '../utils';
import table from './data';
import {
  AccordionHeader,
  AlignRight,
  Anex,
  BatchOpening,
  Diligences,
  ModalView,
  Observation,
  Panel,
  Risks,
  Seem,
  View
} from './styles';
import SurveyHistoryView from './SurveyHistoryView';


const ID = () => {
  
  const surveyRef = useRef();
  const observationRef = useRef();
  const includeItemRef = useRef();
  const includeProcessRef = useRef();
  const foreignModalRef = useRef();
  const anexoObservacaoRef = useRef();
  const toggleRef = useRef();
  const reasonRef = useRef();
  const popupWrapperRef = useRef();
  const surveyHistoryViewRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => popupWrapperRef.current,
  });
  const handleSurveyHistoryPrint = useReactToPrint({
    content: () => surveyHistoryViewRef.current,
  });
  const batchOpeningRef = useRef();

  const { user } = useMe();
  const alert = useAlert();
  const api = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation('entities');
  const { t: T } = useTranslation('diligence');
  const { t: tSearch } = useTranslation('search');
  const { t: tAuth } = useTranslation('auth');

  const [showSurveyHistory, onShowSurveyHistory] = useState(false);

  const [update, onUpdate] = useState(0);
  const [rows, onRows] = useState([]);
  const [loading, onLoading] = useState(false);
  const [show, onShow] = useState(false);
  const [showDiscardReason, onShowDiscardReason] = useState(false);
  const [showToggle, onShowToggle] = useState(false);
  const [toggleData, onToggleData] = useState({});
  const [showIncludeItem, onShowIncludeItem] = useState(false);
  const [showPickDiligence, onShowPickDiligence] = useState(false);
  const [nameOptions, setNameOptions] = useState([]);
  const [choosenNames, setChoosenNames] = useState([]);
  const [showAnexoObservacao, onShowAnexoObservacao] = useState(false);
  const [workflow, onWorkflow] = useState();
  const [partners, setPartners] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [showOpenDiligenceModal, setOpenShowDiligenceModal] = useState(false);
  const [loadingBatchDiligence, setLoadingBatchDiligence] = useState(false);
  const [loadingAssociateDiligence, setLoadingAssociateDiligence] = useState(false);
  const [surveyHistoryData, setSurveyHistoryData] = useState(null);
  const [resendSurveyID, setResendSurveyID] = useState(null);
  const [markedSource, setMarkedSource] = useState(null);
  const [markedSourceItem, setMarkedSourceItem] = useState({});
  const [showResendSurvey, onShowResendSurvey] = useState(false);
  const [showPopup, onShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState(false);
  const [popupData, setPopupData] = useState(false);
  const [showSeem, onShowSeem] = useState(false);
  const [lastSelectedThemeId, setLastSelectedThemeId] = useState(null);
  const [lastSelectedThemeRisk, setLastSelectedThemeRisk] = useState(null);
  const [allThemeRiskItems, setAllThemeRiskItems] = useState([]);
  const [processThemeRisk, setProcessThemeRisk] = useState([]);
  const [allThemeRisks, setAllThemeRisks] = useState([]);
  const [showEditContact, onShowEditContact] = useState(false);
  const [showEditEmail, onShowEditEmail] = useState(false);
  const [showEditSupplierCode, onShowEditSupplierCode] = useState(false);
  const [associatePartners, setAssociatePartners] = useState(false);
  const [diligenceSearch, setDiligenceSearch] = useState(null);
  const [selectedDiligence, setSelectedDiligence] = useState(null);
  const [showIncludeProcess, setShowIncludeProcess] = useState(false);
  const diligenceSearchDebounced = useDebounce(diligenceSearch);

  const { id } = useParams();

  const mutations = [];

  useWebSocketRefresh({
    channel: 'App.Events.ProcessStatusUpdated',
    events: [`App\\Events\\ProcessStatusUpdated\\${id}`],
    mutations,
  }, [id]);

  const { data, mutate: render } = useFetch({
    url: `/process/${id}/headers`,
    mutations,
  });

  const { data: personWorkflows } = useFetch({
    url:
      `/clients/getContent?clientId=${data?.data?.idcliente}&workflowType=1&notBasic=1&content=workflows`,
    mutations,
  });

  const { data: companyWorkflows } = useFetch({ url: `/clients/getContent?clientId=${data?.data?.idcliente}&workflowType=2&notBasic=1&content=workflows`, mutations });

  const { data: searchedDiligences } = useFetch({
    url: diligenceSearchDebounced ? `/process?search=${diligenceSearchDebounced}` : '', mutations,
  });

  const options = useMemo(() => ({
    personWorkflows: personWorkflows?.filter((item) => item.active !== 0).map((item) => ({
      value: item.id,
      label: item.name,
    })),
    companyWorkflows: companyWorkflows?.filter((item) => item.active !== 0).map((item) => ({
      value: item.id,
      label: item.name,
    })),
    queryedDiligences: searchedDiligences?.data?.map((item) => ({
      value: item.id,
      label: `${item.razaosocial} (${item.id})`,
    })),
  }), [companyWorkflows, personWorkflows, searchedDiligences?.data]);

  const canCurrentUserCloseDiligence = !(
    !!data?.client[0]?.processes_should_be_closed_by_icts
    && !user?.isAdminICTS()
  );

  const { data: tags, mutate: render_tags } = useFetch({
    mutations,
    url: `/tag?clientId=${data?.data.idcliente}`,
  });

  const { data: risks, mutate: render_workflow_risks } = useFetch({
    mutations,
    url: `/process/${id}/risks`,
  });

  const { data: observs, mutate: render_observs } = useFetch({
    mutations,
    url: `process/${id}/notes`,
  });

  const { data: themes, mutate: render_themes } = useFetch({
    mutations,
    url: `/process/${id}/themes`,
  });

  const { data: lastSelectedThemeRisks, mutate: render_risk_source } = useFetch({
    mutations,
    url: lastSelectedThemeId
      ? `/process/${id}/themes/${lastSelectedThemeId}/risks`
      : '',
  });

  const {
    data: lastSelectedThemeRiskItems,
    mutate: render_risk_item,
  } = useFetch({
    mutations,
    url: lastSelectedThemeRisk ? `/process/${id}/risks/${lastSelectedThemeRisk}` : '',
  });

  const corporateData = useFetch({
    mutations,
    url: `/process/${id}/corporateData`,
  })?.data?.data;
  useEffect(() => {
    const corporateDataPartners = corporateData?.employees ?? corporateData?.shareholding;
    if (corporateDataPartners !== undefined) {
      const selectedPartners = [];

      corporateDataPartners.forEach((corporateDataPartner) => {
        selectedPartners.push({
          document: corporateDataPartner.document,
          isChecked: false,
        });
      });
      setPartners(selectedPartners);
    }
  }, [corporateData, corporateData?.employees]);

  const actionPlansData = useFetch({
    mutations,
    url: `/process/${id}/actionPlans`,
  })?.data?.data;
  const { data: noteAttachment, mutate: render_attachment } = useFetch({
    mutations,
    url: `/process/note/${id}/attachment`,
  });
  const { data: seem, mutate: render_seem } = useFetch({
    mutations,
    url: `/process/${id}/seem`,
  });

  const { data: courts, mutate: render_courts } = useFetch({
    mutations,
    url: `/process/${id}/process`,
  });

  const { data: medias, mutate: render_medias } = useFetch({
    mutations,
    url: `/process/${id}/media`,
  });

  const { data: courtsResults, mutate: render_courts_results } = useFetch({
    mutations,
    url: `/process/${id}/process/results`,
  });

  const { data: mediasResults, mutate: render_medias_results } = useFetch({
    mutations,
    url: `/process/${id}/media/results`,
  });

  const courtResultsList = useCallback(
    () => courtsResults?.map((result, index) => {
      const columns = [];
      columns.push({
        payload: result.coluna1,
        type: 'TEXT',
      });
      columns.push({
        payload: result.coluna2,
        type: 'TEXT',
      });
      columns.push({
        payload: result.coluna3,
        type: 'TEXT',
      });
      columns.push({
        payload: {
          value: !! result.confirmed,
          action: {
            toggle: () => {
              if (seem?.data?.id) {
                return alert.error(T('toggle.alerts.finalized'));
              }
              setLastSelectedThemeId(result.themeId);
              setLastSelectedThemeRisk(result.riskId);
              onToggleData({
                themeId: result.themeId,
                riskId: result.riskId,
                id: result.id,
                active: result.confirmed,
                reason: result.confirmed_change_reason,
              });
              onShowToggle(true);
            },
            edit: result.confirmed_change_reason
              ? () => {
                onToggleData({
                  themeId: result.themeId,
                  riskId: result.riskId,
                  id: result.id,
                  active: result.confirmed,
                  reason: result.confirmed_change_reason,
                });
                onShowDiscardReason(true);
              }
              : null,
          },
        },
        type: 'TOGGLE',
      });
      columns.push({
        payload: {
          active: result.marked,
          action: {
            toggle: async () => {
              const statusMarked = ! result.marked;
              result.marked = statusMarked;
              setProcessThemeRisk(statusMarked);
              await setAllThemeRiskItems((prevState) => {
                const state = prevState;
                state.forEach((value) => {
                  if (value[0].themeId === result.themeId) {
                    value.forEach((source) => {
                      const lastSource = source;
                      if (lastSource.id === result.riskId) {
                        if (statusMarked) {
                          lastSource.marked = true;
                        } else {
                          let stateItem = 0;
                          const checkMarked = courtsResults.forEach((item) => {
                            if (item.id !== result.id) {
                              if (item.marked) {
                                stateItem = 1;
                              }
                            }
                          });
                          lastSource.marked = stateItem;
                        }
                      }
                    });
                  }
                });
                return prevState;
              });
              // }
              if (allThemeRisks.length > 0) {
                await setAllThemeRisks((prevState) => {
                  const state = prevState;
                  state.forEach((value) => {
                    if (value[0].themeId === result.themeId) {
                      value.forEach((source) => {
                        const lastSource = source;
                        if (lastSource.id === result.riskId) {
                          if (statusMarked) {
                            lastSource.marked = true;
                          } else {
                            let stateItem = 0;
                            const checkMarked = courtsResults.forEach((item) => {
                              if (item.id !== result.id) {
                                if (item.marked) {
                                  stateItem = 1;
                                }
                              }
                            });
                            lastSource.marked = stateItem;
                          }
                        }
                      });
                    }
                  });
                  return prevState;
                });
              }

              Promise.resolve(
                api().put(`process/${id}/theme/${result.themeId}/source/${result.riskId}/result/${result.id}`,
                  {
                    marked: ! result.marked,
                  }),
              )
                .then(async () => {
                  onUpdate((val) => val + 1);
                  onLoading(false);
                });
            },
          },
        },
        type: 'MARKED',
      });
      const result_id = result.id;
      const subject = null;
      if (result.attachment_name.length === 0) {
        columns.push({
          payload: {
            types:
              result.popup && result.popup !== 'null'
                ? ['FILE', 'ADD_CLIPBOARD']
                : ['ADD_CLIPBOARD'],
            process_id: id,
            result_id,
            subject,
            fileAction: {
              onClick: () => {
                setPopupData(result.popup);
                let title = `${result.coluna1 || ''}${result.coluna1 && result.coluna2
                  ? ' - '
                  : ''
                }${result.coluna2 || ''}`;

                const popup = JSON.parse(result.popup || {});

                if (popup.type === 'processo_judicial') {
                  title = `${data?.entity.name || ''} - Processo: ${popup.data.Number}`;
                }

                setPopupTitle(title);
                onShowPopup(true);
              },
            },
          },
          type: 'BUTTONS',
        });
      } else {
        columns.push({
          payload: result.attachment_name ? {
            types: ['FILE', 'ADD_CLIPBOARD'], result_id, subject, process_id: id, fileAction: { onClick: () => { window.open(result.attachment_url, '_blank'); } },
          } : 1,
          type: result.attachment_name ? 'BUTTONS' : 'HEAD_BUTTONS',
        });
      }
      return columns;
    }),
    [courtsResults, seem, data, allThemeRisks, allThemeRiskItems, processThemeRisk],
  );

  const mediaResultsList = useCallback(
    () => mediasResults?.map((result, index) => {
      const columns = [];
      columns.push({
        payload: result.coluna1,
        type: 'TEXT',
      });
      columns.push({
        payload: result.coluna2,
        type: 'TEXT',
      });
      columns.push({
        payload: result.coluna3,
        type: 'TEXT',
      });
      columns.push({
        payload: {
          value: !! result.confirmed,
          action: {
            toggle: () => {
              if (seem?.data?.id) {
                return alert.error(T('toggle.alerts.finalized'));
              }
              setLastSelectedThemeId(result.themeId);
              setLastSelectedThemeRisk(result.riskId);
              onToggleData({
                themeId: result.themeId,
                riskId: result.riskId,
                id: result.id,
                active: result.confirmed,
                reason: result.confirmed_change_reason,
              });
              onShowToggle(true);
            },
            edit: result.confirmed_change_reason
              ? () => {
                onToggleData({
                  themeId: result.themeId,
                  riskId: result.riskId,
                  id: result.id,
                  active: result.confirmed,
                  reason: result.confirmed_change_reason,
                });
                onShowDiscardReason(true);
              }
              : null,
          },
        },
        type: 'TOGGLE',
      });
      columns.push({
        payload: {
          active: result.marked,
          action: {
            toggle: async () => {
              const statusMarked = ! result.marked;
              result.marked = statusMarked;
              setProcessThemeRisk(statusMarked);
              await setAllThemeRiskItems((prevState) => {
                const state = prevState;
                state.forEach((value) => {
                  if (value[0].themeId === result.themeId) {
                    value.forEach((source) => {
                      const lastSource = source;
                      if (lastSource.id === result.riskId) {
                        if (statusMarked) {
                          lastSource.marked = true;
                        } else {
                          let stateItem = 0;
                          const checkMarked = mediasResults.forEach((item) => {
                            if (item.id !== result.id) {
                              if (item.marked) {
                                stateItem = 1;
                              }
                            }
                          });
                          lastSource.marked = stateItem;
                        }
                      }
                    });
                  }
                });
                return prevState;
              });
              // }
              if (allThemeRisks.length > 0) {
                await setAllThemeRisks((prevState) => {
                  const state = prevState;
                  state.forEach((value) => {
                    if (value[0].themeId === result.themeId) {
                      value.forEach((source) => {
                        const lastSource = source;
                        if (lastSource.id === result.riskId) {
                          if (statusMarked) {
                            lastSource.marked = true;
                          } else {
                            let stateItem = 0;
                            const checkMarked = mediasResults.forEach((item) => {
                              if (item.id !== result.id) {
                                if (item.marked) {
                                  stateItem = 1;
                                }
                              }
                            });
                            lastSource.marked = stateItem;
                          }
                        }
                      });
                    }
                  });
                  return prevState;
                });
              }

              Promise.resolve(
                api().put(`process/${id}/theme/${result.themeId}/source/${result.riskId}/result/${result.id}`,
                  {
                    marked: ! result.marked,
                  }),
              )
                .then(async () => {
                  onUpdate((val) => val + 1);
                  onLoading(false);
                });
            },
          },
        },
        type: 'MARKED',
      });
      const result_id = result.id;
      const subject = null;
      if (result.attachment_name.length === 0) {
        columns.push({
          payload: {
            types:
              result.popup && result.popup !== 'null'
                ? ['FILE', 'ADD_CLIPBOARD']
                : ['ADD_CLIPBOARD'],
            process_id: id,
            result_id,
            subject,
            fileAction: {
              onClick: () => {
                setPopupData(result.popup);
                let title = `${result.coluna1 || ''}${result.coluna1 && result.coluna2
                  ? ' - '
                  : ''
                }${result.coluna2 || ''}`;

                const popup = JSON.parse(result.popup || {});
                if (popup.type === 'midias') {
                  title = `${result?.coluna1 || ''} - ${result?.coluna2}`;
                }

                setPopupTitle(title);
                onShowPopup(true);
              },
            },
          },
          type: 'BUTTONS',
        });
      } else {
        columns.push({
          payload: result.attachment_name ? {
            types: ['FILE', 'ADD_CLIPBOARD'], result_id, subject, process_id: id, fileAction: { onClick: () => { window.open(result.attachment_url, '_blank'); } },
          } : 1,
          type: result.attachment_name ? 'BUTTONS' : 'HEAD_BUTTONS',
        });
      }
      return columns;
    }),
    [mediasResults, seem, data, allThemeRisks, allThemeRiskItems, processThemeRisk],
  );

  const { data: diligences } = useFetch({
    mutations,
    url: `/entities/${`${data?.data?.cnpjrecebido?.replace(/\D/g, '')}/` || ''
    }processes`,
  });

  const deleteAnexoObservacao = async (id) => {
    await api().delete(`/process/note/attachment/${id}`);
    render_attachment();
  };
  const surveys = useFetch({
    mutations,
    url: `/process/${id}/surveys`,
  })?.data?.data;
  const { data: recommendations } = useFetch({ url: `/process/${id}/recommendations` });

  /*
   * Create survey rows
   */
  const surveyRow = useMemo(
    () => surveys?.map((survey) => [
      { payload: survey.risk_source_id, type: 'DATA' },
      { payload: survey.name, type: 'TEXT' },
      {
        payload: { percentage: Math.round(Number(survey?.risk || 0)) },
        type: 'PROGRESS_BAR',
      },
      {
        payload: <span className="error">{survey.alerts}</span>,
        type: 'TEXT',
      },
      {
        payload: survey.answered_at
          ? survey.answered_at
          : T('surveys.not_responded'),
        type: 'TEXT',
      },
      {
        payload: {
          types: ['ADD_CLIPBOARD', 'REPEAT', 'FILE'],
          process_id: id,
          process_source_id: survey.id,
          subject: survey.name.split(':').join(' -'),
          repeatAction: {
            onClick: () => {
              onShowResendSurvey(true);
            },
          },
          fileAction: {
            onClick: () => {
              setSurveyHistoryData(survey);
              onShowSurveyHistory(true);
            },
          },
        },
        type: 'BUTTONS',
      },
    ]),
    [surveys, T, id],
  );

  const handleBatchProcessesSubmit = useCallback(async (batchOpeningData) => {
    if (selectedPeople.length > 0 && batchOpeningData.peopleWorkflows.length === 0) {
      alert.error(formatErrorMessage(T('open_associate.modal.error.title'), T('open_associate.modal.error.message'),tAuth('expired')));
      return;
    }

    if (associatePartners) {
      setLoadingAssociateDiligence(true);
    } else setLoadingBatchDiligence(true);

    const peopleDiligences = selectedPeople.map((document) => ({
      document,
      workflows: batchOpeningData.peopleWorkflows,
      clientId: data?.data?.idcliente,
      personType: 1,
      associate: associatePartners ? id : null,
    }));

    const companiesDiligences = selectedCompanies.map((document) => ({
      document,
      workflows: batchOpeningData.companiesWorkflows,
      clientId: data?.data?.idcliente,
      personType: 2,
      associate: associatePartners ? id : null,
    }));

    const formattedDiligences = {
      diligences: {
        ...peopleDiligences,
        ...companiesDiligences,
      },
    };

    await api().post('/process/store', formattedDiligences)
      .then(async (res) => {
        if (res.data.type === 'fail') {
          alert.error(res.data.data);
        } else {
          alert.success(tSearch('evaluation.alerts.success'));
          batchOpeningRef.current.reset();

          setOpenShowDiligenceModal(false);
          setPartners(partners.map((partnerElement) => {
            partnerElement.isChecked = false;
            return partnerElement;
          }));
          setSelectedCompanies([]);
          setSelectedPeople([]);
        }
        setLoadingAssociateDiligence(false);
        setLoadingBatchDiligence(false);
        setAssociatePartners(false);
      }).catch(({ response }) => {
        setLoadingAssociateDiligence(false);
        setLoadingBatchDiligence(false);
        setAssociatePartners(false);
        alert.error(formatErrorMessage(response, tSearch('evaluation.alerts.error'),tAuth('expired')));
      });

    Promise.all([
      render_themes(),
      render_workflow_risks(),
      render_risk_source(),
    ]).then(() => {
      onUpdate((val) => val + 1);
      onLoading(false);
    });
  }, [T, alert, associatePartners, data?.data?.idcliente, id, partners, render_risk_source, render_themes, render_workflow_risks, selectedCompanies, selectedPeople, tSearch, tAuth]);

  const handleReSendSurvey = useCallback(
    async (surveyData) => {
      const { surveyRiskSourceId, ...surveyDataRest } = surveyData;
      Yup.object()
        .shape({
          name: Yup.string().required(T('surveys.alerts.name')),
          email: Yup.string().required(T('surveys.alerts.email')),
        })
        .validate(surveyData, {
          abortEarly: false,
        })
        .then(async () => {
          onLoading(true);
          await api().post(`/surveys/${surveyRiskSourceId}/reSend/${id}`, surveyDataRest)
            .then(() => alert.success(T('surveys.alerts.success')))
            .catch(({ response }) => alert.error(
              formatErrorMessage(response, T('surveys.alerts.error'),tAuth('expired')),
            ));
          onLoading(false);
        })
        .catch((err) => error(err, surveyRef));
    },
    [T, alert, id, tAuth],
  );
  /*
   * Create popup rows and heading
   */
  const popups = useMemo(() => (popupData ? JSON.parse(popupData) : popupData));

  /**
   * Remove actual diligence.
   */
  const removed = useMemo(
    () => diligences?.data?.filter((item) => item.id !== Number(id)),
    [diligences?.data, id],
  );

  /**
   * Create row realized
   * diligences table.
   */
  const row_diligences = useCallback(async () => {
    if (removed !== undefined && removed?.length <= 0) {
      return;
    }

    const row = removed?.map((item) => ({
      id: item.id,
      items: [
        {
          label: item?.show_id || item.id,
          dataLabel: t('diligences.dataLabels.0'),
        },
        {
          label: format(parseISO(item.dataabertura), 'dd/MM/yyyy'),
          dataLabel: t('diligences.dataLabels.1'),
        },
        {
          label:
            item?.workflows?.length > 0
              ? item?.workflows?.map((subitem) => subitem.name)
              : '-',
          isBold: true,
          isMulti: item?.workflows?.length > 0,
          dataLabel: t('diligences.dataLabels.2'),
        },
        {
          label: (
            <ProgressBar
              impact_id={item?.risk?.probabilidade_id || 1}
              percentage={Math.round(item?.risk?.risk) || 0}
            />
          ),
          dataLabel: t('diligences.dataLabels.3'),
        },
        {
          label: <Flag status={item?.new_status?.name || 'Não informado'} />,
          dataLabel: t('diligences.dataLabels.4'),
        },
        {
          isArrow: true,
        },
      ],
    }));
    onRows(row);
  }, [removed, t]);

  useEffect(() => {
    row_diligences();
  }, [row_diligences]);

  /*
   * Seem
   */
  const seems = useMemo(
    () => [
      [
        {
          label: T('seem.fields.0'),
          value: seem?.data?.user?.nome || '',
        },
        {
          label: T('seem.fields.1'),
          value: seem?.data
            ? format(parseISO(seem.data?.created_at), 'dd/MM/yyyy')
            : '',
        },
      ],
      [
        {
          label: T('seem.fields.2'),
          value: seem?.data?.note || '',
          document: {
            name: seem?.data?.attachment_name,
            route: seem?.data?.attachment_url,
          },
          hasDocument: seem?.data?.attachment_name !== null && seem?.data?.attachment_url !== null,
        },
      ],
    ],
    [T, seem?.data],
  );

  const selectPartner = useCallback((document) => {
    const selectedPartners = [...partners];
    const partnerIndex = selectedPartners.findIndex((partner) => partner.document === document);
    selectedPartners[partnerIndex].isChecked = !selectedPartners[partnerIndex].isChecked;

    setSelectedPeople(Object.values(selectedPartners)
      .filter((partner) => partner.document.length === 11 && partner.isChecked === true)
      .map((partner) => masked(partner.document)));

    setSelectedCompanies(Object.values(selectedPartners)
      .filter((partner) => partner.document.length === 14 && partner.isChecked === true)
      .map((partner) => masked(partner.document)));

    setPartners(selectedPartners);
  }, [partners]);

  /*
   * Employee row
   */
  const employeeRow = useMemo(
    () => corporateData?.employees?.map((employee) => [
      {
        payload: {
          value: {
            value: employee.document,
            state: partners?.filter((partner) => partner.document === employee.document)[0]?.isChecked,
          },
          action: selectPartner,
        },
        type: 'CHECKBOX',
      },
      {
        payload: employee.name || t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
      {
        payload: employee.document ? masked(employee.document) : '-',
        type: 'DOCUMENT',
      },
      { payload: employee.shareholding_percentage ? `${employee.shareholding_percentage}%` : 'N/A', type: 'TEXT' },
      {
        payload: employee.relationship_name,
        type: 'TEXT',
      },
      {
        payload: employee.relationship_start_date
          ? format(parseISO(employee.relationship_start_date), 'dd/MM/yyyy')
          : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
    ]),
    [corporateData?.employees, partners, selectPartner, t],
  );

  /*
   * Partnership participation row
   */
  const partnershipParticipationRow = useMemo(
    () => corporateData?.shareholding?.map((company) => [
      {
        payload: {
          value: {
            value: company.document,
            state: partners?.filter((partner) => partner.document === company.document)[0]?.isChecked,
          },
          action: selectPartner,
        },
        type: 'CHECKBOX',
      },
      {
        payload: company.status === 'ATUAL' ? t('cadastral_data.fields.current')
          : company.status === 'ANTERIOR' ? t('cadastral_data.fields.previous')
            : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
      { payload: company.company_name, type: 'TEXT' },
      { payload: company.relationship_name, type: 'TEXT' },
      {
        payload: company.document ? masked(company.document) : '-',
        type: 'DOCUMENT',
      },
      {
        payload: company.relationship_start_date
          ? format(parseISO(company.relationship_start_date), 'dd/MM/yyyy')
          : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
      {
        payload: company.relationship_end_date
          ? format(parseISO(company.relationship_end_date), 'dd/MM/yyyy')
          : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
    ]),
    [corporateData?.shareholding, partners, selectPartner, t],
  );
  /*
   * Workflow average risk
   */
  const averageRisk = useMemo(
    () => Number(
      risks?.data?.workflows.reduce(
        (acc, curr) => acc + Number(curr.risk),
        0,
      ) / risks?.data?.workflows.length,
    ),
    [risks],
  );

  /*
   * Action Plans
   */
  const actionPlans = useMemo(() => {
    let columns = [];

    if (actionPlansData) {
      columns = actionPlansData.map((item) => [
        {
          payload: item.id,
          type: 'DATA',
        },
        {
          payload: item.subject || '-',
          type: 'TEXT',
        },
        {
          payload: item.due_date
            ? format(parseISO(item.due_date), 'dd/MM/yyyy')
            : '-',
          type: 'TEXT',
          className: isBefore(
            new Date(item?.due_date),
            new Date().setDate(new Date().getDate() - 1),
          )
            ? 'is-due-date'
            : undefined,
        },
        {
          payload: item.portal_owner?.name || '-',
          type: 'TEXT',
        },
        {
          payload: item.responsible_name || '-',
          type: 'TEXT',
        },
        {
          payload: item.status?.name,
          type: 'FLAG',
        },
        {
          payload:
          item.result_id
            ? {
              types: ['REDIRECT', 'FILE'],
              fileAction: {
                onClick: () => {
                  setPopupData(item.result?.popup);
                  setPopupTitle(
                    `${item.result?.coluna1 || ''}${item.result?.coluna1 && item.result?.coluna2 ? ' - ' : ''
                    }${item.result?.coluna2 || ''}`,
                  );
                  onShowPopup(true);
                },
              },
            }
            : item.attachment_url ? {
              types: ['REDIRECT', 'FILE'],
              fileAction: { onClick: () => { window.open(item.attachment_url, '_blank'); } },
            } : { types: ['REDIRECT'] },
          type: 'BUTTONS',
        },
      ]);
    }
    return columns;
  }, [actionPlansData]);

  /*
   * Themes accordion data
   */
  const storeThemeRisks = useCallback(async () => {
    render_themes();
    if (lastSelectedThemeRisks?.length > 0) {
      const themeIndex = allThemeRisks.findIndex(
        (item) => item[0].theme_id === lastSelectedThemeRisks[0].theme_id,
      );

      if (themeIndex === -1) {
        await setAllThemeRisks(() => [
          ...allThemeRisks,
          lastSelectedThemeRisks.map((obj) => ({
            ...obj,
            themeId: lastSelectedThemeId,
          })),
        ]);
      } else if (themeIndex > -1) {
        await setAllThemeRisks(() => {
          const newItems = allThemeRisks;
          newItems[themeIndex] = lastSelectedThemeRisks.map((obj) => ({
            ...obj,
            themeId: lastSelectedThemeId,
          }));

          return newItems;
        });
      }
    }
  }, [allThemeRisks, lastSelectedThemeId, lastSelectedThemeRisks, render_themes]);

  const onPickNameFormSubmit = useCallback(async (pickedData) => {
    await api().post(`/process/${id}/pickForeignEntity`, { entities: pickedData.nameOptions })
      .then(() => {
        mutations.forEach((mutation) => { mutation(); });
        onShowPickDiligence(false);
      })
      .catch(({ response }) => {
        alert.error(formatErrorMessage(response, T('detail.messages.upload.error'),tAuth('expired')));
        error(response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [T, alert, id, tAuth]);

  useEffect(() => {
    if (data?.options) {
      const formattedNameOptions = data.options.map((option) => (
        {
          value: option.entityID,
          label: option.name,
        }
      ));

      setNameOptions(formattedNameOptions);
      onShowPickDiligence(true);
    }
  }, [data]);

  useEffect(() => {
    storeThemeRisks();
    onUpdate((val) => val + 1);
    onLoading(false);
  }, [storeThemeRisks]);

  const storeRiskItems = useCallback(() => {
    if (lastSelectedThemeRiskItems?.length > 0) {
      const riskItemIndex = allThemeRiskItems.findIndex(
        (item) => item[0].id === lastSelectedThemeRiskItems[0].id,
      );

      if (riskItemIndex === -1) {
        setAllThemeRiskItems((items) => [
          ...items,
          lastSelectedThemeRiskItems.map((obj) => ({
            ...obj,
            riskId: lastSelectedThemeRisk,
            themeId: lastSelectedThemeId,
          })),
        ]);
      } else {
        setAllThemeRiskItems(() => {
          const newItems = allThemeRiskItems;
          newItems[riskItemIndex] = lastSelectedThemeRiskItems.map((obj) => ({
            ...obj,
            riskId: lastSelectedThemeRisk,
            themeId: lastSelectedThemeId,
          }));
          return newItems;
        });
      }
    }
  }, [
    lastSelectedThemeRiskItems,
    lastSelectedThemeRisk,
    lastSelectedThemeId,
  ]);

  useEffect(() => {
    storeRiskItems();
  }, [storeRiskItems]);

  const riskRowsList = useMemo(
    () => allThemeRisks?.map((themeRisks, indexThemeRisks) => themeRisks?.map((risk, index) => {
      const columns = [];
      if (risk.cnpj !== undefined) {
        const process = risk;
        columns.push({ payload: process.razaosocial || '-', type: 'TEXT' });
        columns.push({ payload: process.cnpj || '-', type: 'DOCUMENT' });
        columns.push({
          payload: {
            percentage: Math.round(process?.risk?.risk) || 0,
            impact_id: process?.risk?.probabilidade_id || 1,
          },
          type: 'PROGRESS_BAR',
        });
        columns.push({
          payload: {
            value: !! process.associated_risk_source.composicao_processo_fontes.active,
            action: {
              toggle: () => {
                if (seem?.data?.id) {
                  return alert.error(T('toggle.alerts.finalized'));
                }
                setLastSelectedThemeId(process.themeId);
                setLastSelectedThemeRisk(process.associated_risk_source.id);
                onToggleData({
                  id: process.associated_risk_source.id,
                  themeId: process.themeId,
                  active: process.associated_risk_source.composicao_processo_fontes.active,
                  riskId: 0,
                  reason: process.associated_risk_source.composicao_processo_fontes.active_change_reason,
                });
                onShowToggle(true);
              },
              edit: process.associated_risk_source.composicao_processo_fontes.active_change_reason
                ? () => {
                  onToggleData({
                    id: process.associated_risk_source.id,
                    themeId: process.themeId,
                    active: process.associated_risk_source.composicao_processo_fontes.active,
                    riskId: 0,
                    reason: process.associated_risk_source.composicao_processo_fontes.active_change_reason,
                  });
                  onShowDiscardReason(true);
                }
                : null,
            },
          },
          type: 'TOGGLE',
        });
        columns.push({ payload: process?.new_status?.name || 'Não informado', type: 'FLAG' });
        columns.push({
          payload: {
            active: risk.associated_risk_source.composicao_processo_fontes.marked,
            action: {
              toggle: async () => {
                const statusMarked = ! risk.associated_risk_source.composicao_processo_fontes.marked;
                setMarkedSource(statusMarked ? 1 : 0);
                await setAllThemeRisks((prevState) => {
                  prevState[indexThemeRisks][index].associated_risk_source.composicao_processo_fontes.marked = statusMarked;
                  return prevState;
                });
                Promise.resolve(api().put(`process/${id}/theme/${risk.themeId}/source/${risk.associated_risk_source.composicao_processo_fontes.id}`,
                  {
                    marked: risk.associated_risk_source.composicao_processo_fontes.marked,
                  }))
                  .then(async () => {
                    onUpdate((val) => val + 1);
                    onLoading(false);
                  });
              },
            },
          },
          type: 'MARKED',
        });
        columns.push({
          payload: `/pesquisa/diligencia/${process?.id}`,
          type: 'LINK',
        });
      } else {
        let column2 = risk.description;
        if (risk.attachment_name.length > 0) {
          column2 = risk.attachment_name;
        }
        columns.push({ payload: risk.name || '-', type: 'TEXT' });
        columns.push({ payload: column2 || '-', type: 'TEXT' });
        columns.push({
          payload: {
            value: !!risk.active,
            action: {
              toggle: () => {
                if (seem?.data?.id) {
                  return alert.error(T('toggle.alerts.finalized'));
                }
                setLastSelectedThemeId(risk.themeId);
                setLastSelectedThemeRisk(risk.id);
                onToggleData({
                  id: risk.id,
                  themeId: risk.themeId,
                  active: risk.active,
                  riskId: 0,
                  reason: risk.active_change_reason,
                });
                onShowToggle(true);
              },
              edit: risk.active_change_reason
                ? () => {
                  onToggleData({
                    id: risk.id,
                    themeId: risk.themeId,
                    active: risk.active,
                    riskId: 0,
                    reason: risk.active_change_reason,
                  });
                  onShowDiscardReason(true);
                }
                : null,
            },
          },
          type: 'TOGGLE',
        });
        columns.push({
          payload: [risk.alerts, risk.results],
          type: 'ALERT_RESULTS',
        });
        columns.push({
          payload: {
            active: risk.marked,
            action: {
              toggle: async () => {
                const statusMarked = ! risk.marked;
                await setAllThemeRisks((prevState) => {
                  prevState[indexThemeRisks][index].marked = statusMarked;
                  return prevState;
                });
                if (allThemeRiskItems.length > 0) {
                  await setAllThemeRiskItems((prevState) => {
                    prevState.forEach((state) => {
                      if (state.length === risk.results) {
                        if (state[0].riskId === risk.id) {
                          state.forEach((value) => {
                            value.marked = statusMarked;
                          });
                        }
                      }
                    });
                    return prevState;
                  });
                }
                Promise.resolve(api().put(`process/${id}/theme/${risk.themeId}/source/${risk.id}`,
                  {
                    marked: ! risk.marked,
                  }))
                  .then(async () => {
                    onUpdate((val) => val + 1);
                    onLoading(false);
                  });
              },
            },
          },
          type: 'MARKED',
        });
      }
      return { columns, riskId: risk.id, themeId: risk.themeId };
    })),
    [allThemeRisks, allThemeRiskItems, update, markedSource] //eslint-disable-line
  );

  /*
   * Risk accordion data
   */
  const riskItemRowsList = useCallback(
    () => allThemeRiskItems?.map((themeRiskItems, themeRiskItemsIndex) => themeRiskItems?.map((themeRiskItem, themeRiskItemIndex) => {
      let i = 1;
      const columns = [];
      const { themeId, riskId, id: result_id } = themeRiskItem;
      columns.push({ payload: { themeId, riskId }, type: 'DATA' });
      if (themeRiskItem.attachment_name.length === 0) {
        while (themeRiskItem[`coluna${i}`] !== undefined) {
          const isLink = themeRiskItem[`coluna${i}`]?.split(':');
          if (isLink && (isLink.includes('https') || isLink.includes('http'))) {
            columns.push({
              payload: (
                <a
                  href={themeRiskItem[`coluna${i}`]}
                  title={themeRiskItem[`coluna${i}`]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {reduce(themeRiskItem[`coluna${i}`], 40)}
                </a>
              ),
              type: 'TEXT',
            });
          } else {
            columns.push({
              payload: themeRiskItem[`coluna${i}`] ?? '',
              type: 'TEXT',
            });
          }
          i += 1;
        }
      } else {
        columns.push({
          payload: themeRiskItem.coluna1,
          type: 'TEXT',
        });
        columns.push({
          payload: reduce(themeRiskItem.attachment_name, 12),
          type: 'TEXT',
        });
        columns.push({
          payload: `Risco:${themeRiskItem.descricao_impacto}`,
          type: 'TEXT',
        });
        i = 3;
      }
      const rowHasColumns = i > 1;
      if (!rowHasColumns) {
        return columns;
      }

      columns.push({
        payload: {
          value: !!themeRiskItem.confirmed,
          action: {
            toggle: () => {
              if (seem?.data?.id) {
                return alert.error(T('toggle.alerts.finalized'));
              }
              setLastSelectedThemeId(themeRiskItem.themeId);
              setLastSelectedThemeRisk(themeRiskItem.riskId);
              onToggleData({
                themeId: themeRiskItem.themeId,
                riskId: themeRiskItem.riskId,
                id: themeRiskItem.id,
                active: themeRiskItem.confirmed,
                reason: themeRiskItem.confirmed_change_reason,
              });
              onShowToggle(true);
            },
            edit: themeRiskItem.confirmed_change_reason
              ? () => {
                onToggleData({
                  themeId: themeRiskItem.themeId,
                  riskId: themeRiskItem.riskId,
                  id: themeRiskItem.id,
                  active: themeRiskItem.confirmed,
                  reason: themeRiskItem.confirmed_change_reason,
                });
                onShowDiscardReason(true);
              }
              : null,
          },
        },
        type: 'TOGGLE',
      });

      const subject = `${themes?.find((theme) => theme.id === themeId)?.name
      } - ${riskRowsList
        ?.find((riskRows) => riskRows.find((riskRow) => riskRow.riskId === riskId))
        ?.find((riskRow) => riskRow.riskId === riskId)?.columns[0].payload
      } - ${themeRiskItem.coluna1 || ''}`;
      columns.push({
        payload: {
          active: themeRiskItem.marked,
          action: {
            toggle: async () => {
              const statusMarked = ! themeRiskItem.marked;
              await setAllThemeRiskItems((prevState) => {
                const state = prevState;
                state[themeRiskItemsIndex][themeRiskItemIndex].marked = statusMarked;
                return state;
              });
              await setAllThemeRisks((prevState) => {
                const state = prevState;
                state.forEach((value) => {
                  if (value[0].themeId === themeRiskItem.themeId) {
                    value.forEach((source) => {
                      const lastSource = source;
                      if (lastSource.id === themeRiskItem.riskId) {
                        if (statusMarked) {
                          lastSource.marked = true;
                        } else {
                          let stateItem = 0;
                          const checkMarked = themeRiskItems.forEach((item) => {
                            if (item.id !== themeRiskItem.id) {
                              if (item.marked) {
                                stateItem = 1;
                              }
                            }
                          });
                          lastSource.marked = stateItem;
                        }
                      }
                    });
                  }
                });
                return prevState;
              });

              Promise.resolve(
                api().put(`process/${id}/theme/${themeRiskItem.themeId}/source/${themeRiskItem.riskId}/result/${themeRiskItem.id}`,
                  {
                    marked: ! themeRiskItem.marked,
                  }),
              )
                .then(async () => {
                  onUpdate((val) => val + 1);
                  onLoading(false);
                });
            },
          },
        },
        type: 'MARKED',
      });
      if (themeRiskItem.attachment_name.length === 0) {
        columns.push({
          payload: {
            types:
              themeRiskItem.popup && themeRiskItem.popup !== 'null'
                ? ['FILE', 'ADD_CLIPBOARD']
                : ['ADD_CLIPBOARD'],
            process_id: id,
            result_id,
            subject,
            fileAction: {
              onClick: () => {
                setPopupData(themeRiskItem.popup);
                let title = `${themeRiskItem.coluna1 || ''}${themeRiskItem.coluna1 && themeRiskItem.coluna2
                  ? ' - '
                  : ''
                }${themeRiskItem.coluna2 || ''}`;

                const popup = JSON.parse(themeRiskItem.popup || {});

                if (popup.type === 'processo_judicial') {
                  title = `${data?.entity.name || ''} - Processo: ${popup.data.Number}`;
                }

                setPopupTitle(title);
                onShowPopup(true);
              },
            },
          },
          type: 'BUTTONS',
        });
      } else {
        columns.push({
          payload: themeRiskItem.attachment_name ? {
            types: ['FILE', 'ADD_CLIPBOARD'], result_id, subject, process_id: id, fileAction: { onClick: () => { window.open(themeRiskItem.attachment_url, '_blank'); } },
          } : 1,
          type: themeRiskItem.attachment_name ? 'BUTTONS' : 'HEAD_BUTTONS',
        });
      }

      return columns;
    })),
    [allThemeRiskItems, allThemeRisks, themes, riskRowsList, id, update] //eslint-disable-line
  );

  const handleAnexoObservacao = useCallback(
    async (AnexoObservacao) => {
      const { upload } = AnexoObservacao;
      const form = new FormData();
      form.append('attachment', upload);
      await api().post(`/process/note/${id}/attachment`,
        form).then(() => {
          onShowAnexoObservacao(false);
          render_attachment();
        })
        .catch(({ response }) => {
          alert.error(formatErrorMessage(response, T('detail.messages.upload.error'),tAuth('expired')));
          error(response, anexoObservacaoRef);
        });
    },
    [id, render_attachment, alert, T, tAuth],
  );

  const handleIncludeItem = useCallback(
    (includeItemData) => {
      const { upload, ...formDataRest } = includeItemData;
      Yup.object()
        .shape({
          impactLevel: Yup.string().required(
            T('include_item.alerts.impact_level'),
          ),
          riskSourceName: Yup.string()
            .required(T('include_item.alerts.risk_source_name.0'))
            .min(6, T('include_item.alerts.risk_source_name.1')),
        })
        .validate(formDataRest, {
          abortEarly: false,
        })
        .then(async () => {
          onLoading(true);
          await api().post(
              `/process/${id}/theme/${lastSelectedThemeId}`,
              formDataRest,
            )
            .then(async (res) => {
              alert.success(T('include_item.alerts.success'));
              onShowIncludeItem(false);
              if (upload) {
                const form = new FormData();
                form.append('attachment', upload);
                await api().post(`/process/${res.data.id}/attachment`,
                  form)
                  .then(() => {
                  })
                  .catch(({ response }) => {
                    alert.error(formatErrorMessage(response, T('detail.messages.upload.error'),tAuth('expired')));
                  });
              }
              Promise.all([
                render_themes(),
                render_workflow_risks(),
                render_risk_source(),
                render_risk_item(),
              ]).then(() => {
                onUpdate((val) => val + 1);
                onLoading(false);
              });
            })
            .catch(({ response }) => alert.error(
              formatErrorMessage(response, T('include_item.alerts.error'),tAuth('expired')),
            ));
          onLoading(false);
        })
        .catch((err) => error(err, includeItemRef));
    },
    [
      T,
      id,
      lastSelectedThemeId,
      alert,
      render_themes,
      render_workflow_risks,
      render_risk_source,
      render_risk_item,
      tAuth
    ],
  );

  const handleIncludeProcess = useCallback(async (formData) => {
    onLoading(true);

    await api().put('/process/attach', {
        son_process_id: formData.attached_process_id,
        father_process_id: +id,
      }).then(async () => {
        alert.success(T('include_item.alerts.success'));
        setShowIncludeProcess(false);

        Promise.all([
          render_themes(),
          render_workflow_risks(),
          render_risk_source(),
          render_risk_item(),
        ]).then(() => {
          onUpdate((val) => val + 1);
          onLoading(false);
        });
      })
      .catch(({ response }) => alert.error(
        formatErrorMessage(response, T('include_item.alerts.error'),tAuth('expired')),
      ));

    onLoading(false);
  }, [T, alert, id, render_risk_item, render_risk_source, render_themes, render_workflow_risks, tAuth]);

  /**
   * Submit observation.
   */
  const observation = (datad) => {
    Yup.object()
      .shape({
        note: Yup.string().required(T('observation.alerts.note')),
      })
      .validate(datad, {
        abortEarly: false,
      })
      .then(async () => {
        onLoading(true);
        await api().post(`/process/${id}/notes`, datad)
          .then(() => {
            render_observs();
            render_attachment();
            alert.success(T('observation.alerts.success'));
          })
          .catch(({ response }) => alert.error(
            formatErrorMessage(response, T('observation.alerts.error'),tAuth('expired')),
          ));
        onLoading(false);
      })
      .catch((err) => error(err, observationRef));
  };

  /**
   * Populate observation.
   */
  useEffect(() => {
    if (observs?.data?.length > 0 && observationRef.current) {
      const notes = observs.data[0].informacoes;

      observationRef.current.setData({ note: notes });
    }
  }, [observs]);

  /**
   * Handle Risk source/item Discard and Confirmation
   */
  const handleToggle = useCallback(
    async (handleToggleData) => {
      if (seem?.data?.id) {
        return alert.error(T('toggle.alerts.finalized'));
      }

      Yup.object()
        .shape({
          reason: Yup.string().required(T('toggle.alerts.reason')),
        })
        .validate(handleToggleData, {
          abortEarly: false,
        })
        .then(async () => {
          const { reason } = handleToggleData;
          onLoading(true);
          if (Number(handleToggleData.riskId)) {
            await api().post(
                `/process/${id}/theme/${handleToggleData.themeId}/source/${handleToggleData.riskId}/result/${handleToggleData.id}`,
                { reason },
              )
              .then(async () => {
                alert.success(
                  `${T('toggle.alerts.success_item')} ${T(
                    'toggle.alerts.wait',
                  )}`,
                );
                onShowToggle(false);
                onShowDiscardReason(false);
                onLoading(false);
                Promise.all([
                  render_themes(),
                  render_workflow_risks(),
                  render_risk_source(),
                  render_risk_item(),
                  render_courts_results(),
                  render_medias_results(),
                ]).then(() => {
                  onUpdate((val) => val + 1);
                });
              })
              .catch(({ response }) => alert.error(
                formatErrorMessage(response, T('toggle.alerts.error_item'),tAuth('expired')),
              ));
          } else {
            await api().post(
                `/process/${id}/theme/${handleToggleData.themeId}/source/${handleToggleData.id}`,
                { reason },
              )
              .then(async () => {
                alert.success(
                  `${T('toggle.alerts.success_source')} ${T(
                    'toggle.alerts.wait',
                  )}`,
                );
                onShowToggle(false);
                onShowDiscardReason(false);
                onLoading(false);
                Promise.all([
                  render_themes(),
                  render_workflow_risks(),
                  render_risk_source(),
                  render_risk_item(),
                  render_courts_results(),
                  render_medias_results(),
                ]).then(() => {
                  onUpdate((val) => val + 1);
                });
              })
              .catch(({ response }) => {
                alert.error(
                  formatErrorMessage(response, T('toggle.alerts.error_source'),tAuth('expired')),
                );
              });
          }
          onLoading(false);
        })
        .catch((err) => error(err, toggleRef));
    },
    [
      T,
      alert,
      id,
      render_risk_item,
      render_risk_source,
      render_themes,
      render_workflow_risks,
      seem?.data?.id,
      tAuth
    ],
  );

  const handleReason = useCallback(
    async (reasonData) => {
      Yup.object()
        .shape({
          reason: Yup.string().required(T('toggle.alerts.reason')),
        })
        .validate(reasonData, {
          abortEarly: false,
        })
        .then(async () => {
          const { reason } = reasonData;
          onLoading(true);
          if (Number(reasonData.riskId)) {
            await api().post(
                `/process/${id}/theme/${reasonData.themeId}/source/${reasonData.riskId}/result/${reasonData.id}/reason`,
                { reason },
              )
              .then(async () => {
                alert.success(
                  'Motivo alterado com sucesso. Aguarde um pouco, estamos atualizando nosso os dados.',
                );
                onShowToggle(false);
                onShowDiscardReason(false);
                onLoading(false);
                Promise.all([
                  render_themes(),
                  render_workflow_risks(),
                  render_risk_source(),
                  render_risk_item(),
                  render_courts_results(),
                  render_medias_results(),
                ]).then(() => {
                  onUpdate((val) => val + 1);
                });
              })
              .catch(({ response }) => alert.error(
                formatErrorMessage(response, 'Erro ao alterar motivo',tAuth('expired')),
              ));
          } else {
            await api().post(
                `/process/${id}/theme/${reasonData.themeId}/source/${reasonData.id}/reason`,
                { reason },
              )
              .then(async () => {
                alert.success(
                  `${T('toggle.alerts.success_motive')} ${T(
                    'toggle.alerts.wait',
                  )}`,
                );
                onShowToggle(false);
                onShowDiscardReason(false);
                onLoading(false);
                Promise.all([
                  render_themes(),
                  render_workflow_risks(),
                  render_risk_source(),
                  render_risk_item(),
                  render_courts_results(),
                  render_medias_results(),
                ]).then(() => {
                  onUpdate((val) => val + 1);
                });
              })
              .catch(({ response }) => {
                alert.error(
                  formatErrorMessage(response, T('toggle.alerts.error_motive'),tAuth('expired')),
                );
              });
          }
          onLoading(false);
        })
        .catch((err) => error(err, reasonRef));
    },
    [
      T,
      alert,
      id,
      render_risk_item,
      render_risk_source,
      render_themes,
      render_workflow_risks,
      tAuth
    ],
  );

  const entityNames = useMemo(() => {
    const names = [];

    if (! data) {
      return names;
    }

    const { entity } = data;

    if (entity && entity.custom_data && entity.custom_data.length > 0) {
      entity.custom_data.forEach((customData) => {
        if (customData.type === 'RAZAO_SOCIAL') {
          names.push(customData.value);
        }
      });
    }

    const nameKeyByPersonType = [
      null, /* 0 - não existe */
      'name', /* 1 - PESSOA_FISICA_NACIONAL */
      'company_name', /* 2 - PESSOA_JURIDICA_NACIONAL */
      'name', /* 3 - PESSOA_FISICA_ESTRANGEIRA */
      'company_name', /* 4 - PESSOA_JURIDICA_ESTRANGEIRA */
    ];

    if (entity && entity[nameKeyByPersonType[data.data.person_type]]) {
      names.unshift(entity[nameKeyByPersonType[data.data.person_type]]);
    }

    return names;
  }, [data]);

  const filiais = useMemo(() => {
    let _filiais = [];

    if (! data) {
      return _filiais;
    }

    const { entity } = data;

    if (entity) {
      if (entity.filiais && entity.filiais.length > 0) {
        _filiais = entity.filiais;
      } else if (entity && entity.custom_data && entity.custom_data.length > 0) {
        entity.custom_data.forEach((customData) => {
          if (customData.type === 'FILIAL') {
            const obj = JSON.parse(customData.value);

            _filiais.push({
              company_name: obj.primaryName,
              document: '',
              state: '',
            });
          }
        });
      }
    }

    return _filiais;
  }, [data]);

  if (! data) {
    return <Loading />;
  }

  const clientsHeader = (clients) => {
    let data = '';
    for (let i = 0; i < clients.length; i++) {
      if (i === clients.length) {
        data += clients[i].cliente;
      } else {
        data += `${clients[i].cliente} / `;
      }
    }
    return data;
  };

  const {
    idcliente, dataencerramento, cnpj,
  } = data?.data;

  const { entity, data: process } = data;
  const typeArr = [
    null, /* 0 - não existe */
    'person', /* 1 - PESSOA_FISICA_NACIONAL */
    'company', /* 2 - PESSOA_JURIDICA_NACIONAL */
    'person', /* 3 - PESSOA_FISICA_ESTRANGEIRA */
    'company', /* 4 - PESSOA_JURIDICA_ESTRANGEIRA */
  ];
  const type = typeArr[process.person_type];

  /**
   * Fields.
   */
  const fields = {
    person: [
      {
        label: t('cadastral_data.fields.person.0'),
        value: (<ItemsList type="name" items={entityNames} />),
      },
      {
        label: t('cadastral_data.fields.person.1'),
        value:
          entity?.death_record === 0
            ? t('cadastral_data.fields.no')
            : t('cadastral_data.fields.yes'),
      },
      {
        label: t('cadastral_data.fields.person.2'),
        value: masked(cnpj),
      },
      {
        label: `${t('cadastral_data.fields.person.3')} (${entity?.addresses.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.addresses}
            keys={[
              'place',
              'street',
              'number',
              'complement',
              'neighborhood',
              'city',
              'state',
              'country_name',
            ]}
            type="address"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.person.4'),
        value:
          entity?.birthday && entity.birthday !== '0000-00-00'
            ? format(parseISO(entity.birthday), 'dd/MM/yyyy')
            : t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.person.5')} (${entity?.phones?.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.phones}
            keys={['country_code', 'area_code', 'number']}
            type="phone"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.person.6'),
        value: entity?.age
          ? `${entity?.age} anos`
          : t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.person.7')} (${entity?.emails?.length || 0
        })`,
        value: <ItemsList items={entity?.emails} keys={['email']} />,
      },
      {
        label: t('cadastral_data.fields.person.8'),
        value: entity?.gender || t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.person.9')} (${entity?.family?.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.family}
            keys={['name', 'documento', 'relationship_type']}
            type="family"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.person.10'),
        value:
          entity?.nationality?.pais || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.person.11'),
        value: entity?.custom_data?.find((customData) => customData.type === 'SOBRE')?.value || t('cadastral_data.fields.not_found'),
      },
    ],

    company: [
      {
        label: t('cadastral_data.fields.company.0'),
        value: (<ItemsList type="name" items={entityNames} />),
      },
      {
        label: `${t('cadastral_data.fields.company.1')} (${entity?.addresses.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.addresses}
            keys={[
              'place',
              'street',
              'number',
              'complement',
              'neighborhood',
              'city',
              'state',
              'country_name',
            ]}
            type="address"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.company.2'),
        value: entity?.trade_name || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.3'),
        value:
          (
            <>
              <span
                className="data"
                title={entity?.contact || t('cadastral_data.fields.not_found')}
              >
                {(entity?.contact && reduce(entity?.contact, 30))
                  || t('cadastral_data.fields.not_found')}
              </span>
              <Edit
                className="edit"
                onClick={() => onShowEditContact(!showEditContact)}
              />
              <EditInput
                id={id}
                name="contact"
                type="process"
                show={showEditContact}
                render={render}
                onShow={onShowEditContact}
                placeholder={t('cadastral_data.fields.edit.contact')}
              />
            </>
          ) || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.4'),
        value: entity?.document
          ? masked(entity.document)
          : t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.5'),
        value:
          (
            <>
              <span className="data">
                {entity?.email || t('cadastral_data.fields.not_found')}
              </span>
              <Edit
                className="edit"
                onClick={() => onShowEditEmail(!showEditEmail)}
              />
              <EditInput
                id={id}
                name="email"
                type="process"
                show={showEditEmail}
                render={render}
                onShow={onShowEditEmail}
                placeholder={t('cadastral_data.fields.edit.email')}
              />
            </>
          ) || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.6'),
        value:
          (
            <>
              <span
                className="data"
                title={
                  entity?.supplier_code || t('cadastral_data.fields.not_found')
                }
              >
                {(entity?.supplier_code && reduce(entity?.supplier_code, 50))
                  || t('cadastral_data.fields.not_found')}
              </span>
              <Edit
                className="edit"
                onClick={() => onShowEditSupplierCode(!showEditSupplierCode)}
              />
              <EditInput
                id={id}
                name="supplier_code"
                type="process"
                show={showEditSupplierCode}
                render={render}
                onShow={onShowEditSupplierCode}
                placeholder={t('cadastral_data.fields.edit.supplier_code')}
              />
            </>
          ) || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.7'),
        value: entity?.website || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.8'),
        value: (() => {
          try {
            return format(parseISO(entity.founded_at), 'dd/MM/yyyy');
          } catch {
            return t('cadastral_data.fields.not_found');
          }
        })(),
      },
      {
        label: t('cadastral_data.fields.company.9'),
        value: entity?.main_cnae?.codigo
          ? `${entity?.main_cnae?.codigo} / ${entity?.main_cnae?.descricao}`
          : t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.company.10')} (${entity?.filiais?.length || 0
          })`,
        value: (
          <ItemsList
            items={filiais}
            keys={['company_name', 'document', 'state']}
            type="filiais"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.company.11'),
        value:
          type === 'company' && entity?.share_capital
            ? maskMoney(entity.share_capital, 'BRL')
            : t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.12'),
        value: entity?.legal_nature || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.13'),
        value: (
          <ItemsList
            items={entity?.secondary_cnaes}
            keys={['codigo', 'descricao']}
            type="cnae"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.company.14'),
        value: entity?.custom_data?.find((customData) => customData.type === 'SOBRE')?.value || t('cadastral_data.fields.not_found'),
      },
    ],
  };

  return (
    <View>
      <Container>
        <ResultHead
          title={`[${data?.data?.show_id || id}] ${reduce(
            entityNames.join(' / '),
            32,
          )}`}
          statusName={data?.data?.entity_status && data?.data?.entity_status.id !== 1
            ? data?.data?.entity_status?.description : false}
        >

          {data?.tag.map((item) => (
            <Flag key={item.tag.id} status={item.tag.name} />
          ))}

          <Flag status={data?.data?.new_status?.name} />
        </ResultHead>

        <Card
          id={data?.tag.length > 0 ? data?.tag[0].tag.id : Number(id)}
          show={show}
          title={(
            <>
              <span>
                {`${T('card.2')}: ${data?.data?.requester?.nome
                  || t('cadastral_data.fields.not_found')
                }`}
              </span>
              <><br /></>
              <span>
                {`${T('card.3')}: ${data?.client?.length > 1
                  ? clientsHeader(data.client) : data.client[0].cliente
                  || t('cadastral_data.fields.not_found')
                  }`}
              </span>
              <><br /></>
              <span>
                {`${T('card.0')}: ${data?.data?.dataabertura
                  ? format(parseISO(data?.data?.dataabertura), 'dd/MM/yyyy  hh:mm')
                  : T('card.1')
                }`}
              </span>
              <><br /></>
              <span>
                {`${T('card.4')}:  ${data?.data?.dataencerramento
                  ? format(parseISO(data?.data?.dataencerramento), 'dd/MM/yyyy hh:mm')
                  : T('card.1')
                }`}
              </span>
            </>
          )}
          fields={fields[type]}
          themes={themes}
          onShow={onShow}
          options={tags}
          surveys={surveys}
          seemId={seem?.data?.id || !canCurrentUserCloseDiligence || false}
          hasTags={data?.tag.length > 0}
          clientId={idcliente}
          client={data?.client[0]}
          updateSeem={render_seem}
          monitored={data.monitoring}
          processId={Number(id)}
          renderTags={render_tags}
          renderAllTags={render}
          diligence={data?.data}
          isDiligence
        />

        {seem?.data && (
          <Accordion
            columns={[{ payload: T('seem.title'), type: 'TITLE' }]}
            initialState
          >
            <Seem>
              {seems?.map((item) => (
                <div key={item[0].label}>
                  {item.map((child) => {
                    const length = (child.value.length >= 248 || child.hasDocument);

                    return (
                      <P2 as="span" key={child.label}>
                        {child.label}
                        <span>
                          {length && !showSeem
                            ? reduce(child.value, 248)
                            : child.value}
                        </span>

                        {showSeem && child.hasDocument && (
                          <a
                            rel="noreferrer"
                            href={child?.document?.route}
                            target="_blank"
                          >
                            <Paperclip />
                            {`${T('seem.download')} ${child?.document?.name}`}
                          </a>
                        )}

                        {length && (
                          <p
                            role="presentation"
                            onClick={() => onShowSeem(!showSeem)}
                          >
                            {`${showSeem ? T('seem.less') : T('seem.more')}`}
                          </p>
                        )}
                      </P2>
                    );
                  })}
                </div>
              ))}
            </Seem>
          </Accordion>
        )}

        <Accordion
          columns={[{ payload: T('risks.title'), type: 'TITLE' }]}
          initialState
          hasOverflow
        >
          {isNaN(averageRisk) ? ( //eslint-disable-line
            <Empty hasButton={false} />
          ) : (
            <Risks>
              <ProgressCircle
                title={T('risks.general')}
                percentage={Math.round(Number(averageRisk))}
                impact_id={risks?.data?.probabilidade_id}
              />
              {risks?.data?.workflows.map(
                ({
                  name, goal, risk, probabilidade_id, monitoring_id,
                }) => (
                  <ProgressCircle
                    key={name}
                    title={name}
                    subTitle={goal}
                    impact_id={probabilidade_id}
                    percentage={Math.round(Number(risk))}
                    hasMonitoring={monitoring_id}
                  />
                ),
              )}
            </Risks>
          )}
        </Accordion>

        {/* <Accordion
          columns={[{ payload: t('recommendations.title'), type: 'TITLE' }]}
        >
          {Object.keys(recommendations?.data || {}).length ? (
            Object.keys(recommendations.data).map((finalidade, fIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <RecommendationGoalContainer key={fIdx}>
                <P1>{finalidade}</P1>
                { recommendations.data[finalidade].map(
                  // eslint-disable-next-line react/no-array-index-key
                  (recommendation, idx) => <P2 key={idx}>{recommendation}</P2>,
                )}
              </RecommendationGoalContainer>
            ))
          ) : <P2>Nenhuma recomendação automática foi identificada para essa avaliação.</P2>}
        </Accordion> */}

        <Accordion
          columns={[{ payload: t('corporate_data.title'), type: 'TEXT' }]}
        >
          {corporateData
            && Object.keys(corporateData)
              .map((key) => corporateData[key])
              .reduce((acc, curr) => acc + curr.length, 0) > 0 ? (
                <>
                  {corporateData?.employees?.length > 0 && (
                    <Panel>
                      <H6>{t('corporate_data.socios.title')}</H6>
                      <table>
                        <thead>
                          <TableRow
                            as="th"
                            columns={[
                              {
                                payload: '',
                                type: 'HEAD',
                              },
                              {
                                payload: t('corporate_data.socios.columns.0'),
                                type: 'HEAD',
                              },
                              {
                                payload: t('corporate_data.socios.columns.1'),
                                type: 'HEAD',
                              },
                              {
                                payload: t('corporate_data.socios.columns.2'),
                                type: 'HEAD',
                              },
                              {
                                payload: t('corporate_data.socios.columns.3'),
                                type: 'HEAD',
                              },
                              {
                                payload: t('corporate_data.socios.columns.4'),
                                type: 'HEAD',
                              },
                              {
                                payload: t('corporate_data.socios.columns.5'),
                                type: 'HEAD',
                              },
                            ]}
                          />
                        </thead>
                        <tbody>
                          {employeeRow?.map((partnerColumn) => (
                            <TableRow
                              key={partnerColumn[2]} // document
                              columns={partnerColumn}
                            />
                          ))}
                        </tbody>
                      </table>
                    </Panel>
                  )}
                  {corporateData?.shareholding?.length > 0 && (
                    <Panel>
                      <H6>{t('corporate_data.participacoes_societarias.title')}</H6>
                      <table>
                        <thead>
                          <tr>
                            <TableRow
                              columns={[
                                {
                                  payload: '',
                                  type: 'HEAD',
                                },
                                {
                                  payload: t(
                                    'corporate_data.participacoes_societarias.columns.0',
                                  ),
                                  type: 'HEAD',
                                },
                                {
                                  payload: t(
                                    'corporate_data.participacoes_societarias.columns.1',
                                  ),
                                  type: 'HEAD',
                                },
                                {
                                  payload: t(
                                    'corporate_data.participacoes_societarias.columns.2',
                                  ),
                                  type: 'HEAD',
                                },
                                {
                                  payload: t(
                                    'corporate_data.participacoes_societarias.columns.3',
                                  ),
                                  type: 'HEAD',
                                },
                                {
                                  payload: t(
                                    'corporate_data.participacoes_societarias.columns.4',
                                  ),
                                  type: 'HEAD',
                                },
                                {
                                  payload: t(
                                    'corporate_data.participacoes_societarias.columns.5',
                                  ),
                                  type: 'HEAD',
                                },
                              ]}
                            />
                          </tr>
                        </thead>
                        <tbody>
                          {partnershipParticipationRow?.map(
                            (partnershipParticipationColumn) => (
                              <TableRow
                                key={partnershipParticipationColumn[2]} // document
                                columns={partnershipParticipationColumn}
                              />
                            ),
                          )}
                        </tbody>
                      </table>
                    </Panel>
                  )}

                  <AlignRight>
                    <Button
                      iconLeft={<Search className="icon-search" />}
                      label={T('partners.evaluate')}
                      onClick={() => setOpenShowDiligenceModal(true)}
                      disabled={Object.values(partners).filter((partner) => partner.isChecked).length === 0}
                    />
                  </AlignRight>

                </>
            ) : (
              <Empty hasButton={false} />
            )}
        </Accordion>

        <Accordion
          columns={[{ payload: T('action_plans.title'), type: 'TEXT' }]}
        >
          {actionPlans?.length > 0 ? (
            <Panel>
              <table>
                <thead>
                  <TableRow
                    columns={[
                      { payload: T('action_plans.columns.0'), type: 'HEAD' },
                      { payload: T('action_plans.columns.1'), type: 'HEAD' },
                      { payload: T('action_plans.columns.2'), type: 'HEAD' },
                      { payload: T('action_plans.columns.3'), type: 'HEAD' },
                      { payload: T('action_plans.columns.4'), type: 'HEAD' },
                      { payload: 2, type: 'HEAD_BUTTONS' },
                    ]}
                    as="th"
                  />
                </thead>
                <tbody>
                  {actionPlans?.map((actionPlanColumns) => (
                    <TableRow
                      key={actionPlanColumns[0].payload} // id
                      columns={actionPlanColumns}
                      redirect={() => navigate(
                        `/plano-de-acao/${actionPlanColumns[0].payload}`,
                      )}
                      dataLabels={[
                        '',
                        T('action_plans.columns.0'),
                        T('action_plans.columns.1'),
                        T('action_plans.columns.2'),
                        T('action_plans.columns.3'),
                        T('action_plans.columns.4'),
                      ]}
                    />
                  ))}
                </tbody>
              </table>
            </Panel>
          ) : (
            <Empty hasButton={false} />
          )}
        </Accordion>

        {themes?.map(
          ({
            id: themeId,
            name,
            status,
            alerts,
            sources,
            risk,
            probabilidade_id,
            has_processes,
          }) => has_processes ? (
            <Accordion
              key={name}
              columns={[
                { payload: name, type: 'TEXT' },
                {
                  payload: {
                    percentage: Math.round(Number(risk)),
                    impact_id: probabilidade_id,
                  },
                  type: 'PROGRESS_BAR',
                },
                { payload: [alerts, sources], type: 'ALERT' },
                { payload: status, type: 'FLAG' },
              ]}
              isOpenable={sources > 0}
              onChange={() => setLastSelectedThemeId(themeId)}
              id={themeId}
            >
              {riskRowsList.reduce(
                (acc, riskRows) => acc
                  + Number(
                    riskRows.find((riskRow) => riskRow.themeId === themeId)
                      ?.columns.length > 0,
                  ),
                0,
              ) > 0 ? (
                <>
                  <AccordionHeader>
                    <P2 isBold>{T('include_item.sub_title')}</P2>
                    <Button
                      size="sm"
                      submit
                      label={T('associate_process.button')}
                      appearance="secondary"
                      onClick={() => {
                        setShowIncludeProcess(true);
                        setLastSelectedThemeId(themeId);
                      }}
                      disabled={seem?.data?.id || user?.isVisualizador()}
                    />
                  </AccordionHeader>
                  {riskRowsList?.map((riskRows) => riskRows.map(
                    (riskRow) => riskRow.themeId === themeId && (
                      <TableRow
                        key={riskRow.riskId}
                        columns={riskRow.columns}
                        onChange={() => setLastSelectedThemeRisk(riskRow.riskId)}
                        className="hide-loading"
                      />
                    ),
                  ))}
                </>
              ) : (
                <>{lastSelectedThemeRisks ? <tr /> : <SimpleLoading />}</>
              )}
            </Accordion>
          ) : (
              <Accordion
                key={name}
                columns={[
                  { payload: name, type: 'TEXT' },
                  {
                    payload: {
                      percentage: Math.round(Number(risk)),
                      impact_id: probabilidade_id,
                    },
                    type: 'PROGRESS_BAR',
                  },
                { payload: [alerts, sources], type: 'ALERT' },
                { payload: status, type: 'FLAG' },
              ]}
              isOpenable={sources > 0}
              onChange={() => setLastSelectedThemeId(themeId)}
              id={themeId}
            >
              {riskRowsList.reduce(
                (acc, riskRows) => acc
                  + Number(
                    riskRows.find((riskRow) => riskRow.themeId === themeId)
                      ?.columns.length > 0,
                  ),
                0,
              ) > 0 ? (
                <>
                  <AccordionHeader>
                    <P2 isBold>{T('include_item.sub_title')}</P2>
                    <Button
                      size="sm"
                      submit
                      label={T('include_item.title')}
                      appearance="primary"
                      onClick={() => {
                        onShowIncludeItem(true);
                        setLastSelectedThemeId(themeId);
                      }}
                      disabled={seem?.data?.id || user?.isVisualizador()}
                    />
                  </AccordionHeader>
                  {riskRowsList?.map((riskRows) => riskRows.map(
                    (riskRow) => riskRow.themeId === themeId && (
                      <Accordion
                        key={riskRow.riskId}
                        columns={riskRow.columns}
                        onChange={() => setLastSelectedThemeRisk(riskRow.riskId)}
                        isOpenable={riskRow.columns[3].payload[1] > 0}
                        className="hide-loading"
                      >
                        {riskItemRowsList()
                          && riskItemRowsList().map((riskItemRows) => riskItemRows.map(
                            (riskItemColumns) => riskRow.riskId
                              === riskItemColumns[0].payload.riskId && (
                                <>
                                  {riskItemColumns[1] ? (
                                    <TableRow
                                      key={
                                        riskItemColumns[0].payload
                                          .result_id
                                      }
                                      columns={riskItemColumns}
                                    />
                                  ) : (
                                    <tr />
                                  )}
                                </>
                            ),
                          ))}
                        <SimpleLoading
                          as="tr"
                          hasButton={false}
                          hasMessage={false}
                        />
                      </Accordion>
                    ),
                  ))}
                </>
                ) : (
                  <>{lastSelectedThemeRisks ? <tr /> : <SimpleLoading />}</>
                )}
              </Accordion>
            ),
        )}

        {surveyRow?.length > 0 && (
          <Accordion columns={[{ payload: T('surveys.title'), type: 'TEXT' }]}>
            <Panel>
              <table>
                <thead>
                  <TableRow
                    columns={[
                      { payload: T('surveys.columns.0'), type: 'HEAD' },
                      { payload: T('surveys.columns.1'), type: 'HEAD' },
                      { payload: T('surveys.columns.2'), type: 'HEAD' },
                      { payload: T('surveys.columns.3'), type: 'HEAD' },
                      { payload: 3, type: 'HEAD_BUTTONS' },
                    ]}
                    as="th"
                  />
                </thead>
                <tbody>
                  {surveyRow?.map((surveyColumns) => (
                    <>
                      <TableRow
                        key={surveyColumns[0].payload}
                        columns={surveyColumns}
                        dataLabels={[
                          '',
                          T('surveys.columns.0'),
                          T('surveys.columns.1'),
                          T('surveys.columns.2'),
                          T('surveys.columns.3'),
                        ]}
                      />
                      <Modal
                        title={T('surveys.modal.title')}
                        show={showResendSurvey}
                        onShow={onShowResendSurvey}
                        action={{
                          label: T('surveys.modal.action'),
                          loading,
                          onClick: surveyRef?.current?.submitForm,
                        }}
                      >
                        <ModalView>
                          <P2>{T('surveys.modal.text')}</P2>
                          <Form ref={surveyRef} onSubmit={handleReSendSurvey}>
                            <Input
                              name="surveyRiskSourceId"
                              value={surveyColumns[0].payload}
                              reveal={false}
                            />
                            <Input
                              name="name"
                              label={T('surveys.modal.name.label')}
                              placeholder={T('surveys.modal.name.placeholder')}
                            />
                            <Input
                              name="email"
                              label={T('surveys.modal.email.label')}
                              placeholder={T('surveys.modal.email.placeholder')}
                            />
                          </Form>
                        </ModalView>
                      </Modal>
                    </>
                  ))}
                </tbody>
              </table>
            </Panel>
          </Accordion>
        )}
        {! courts?.data && (
          <Accordion
            columns={[{ payload: T('judicial_processes_list'), type: 'TEXT' }, { payload: [courts?.alerts], type: 'ONLY_ALERT' },
            ]}
          >
            {
              courtResultsList() && 
              courtResultsList().map((riskItemRows) => (
                <>
                  {
                    riskItemRows[ 1 ] 
                      ? ( <TableRow 
                          key={ riskItemRows[ 0 ].payload.result_id } 
                          columns={ riskItemRows }
                        /> ) 
                      : ( <tr/> )
                  }
                </>
              ))
            }

          </Accordion>
        )}
        {! medias?.data && (
          <Accordion
            columns={[{ payload: T('media_processes_list'), type: 'TEXT' }, { payload: [medias?.alerts], type: 'ONLY_ALERT' },
            ]}
          >
            {mediaResultsList()
                && mediaResultsList().map((riskItemRows) => (
                  <>
                    {riskItemRows[1] ? (
                      <TableRow
                        key={
                            riskItemRows[0].payload
                              .result_id
                            }
                        columns={riskItemRows}
                      />
                    ) : (
                      <tr />
                    )}
                  </>
                ))}
          </Accordion>
        )}
        {! seem?.data?.id && (
          <Accordion
            isInfo={T('seem.tooltip')}
            columns={[{ payload: T('seem.sub_title'), type: 'TEXT' }]}
          >
            <Observation ref={observationRef} onSubmit={observation}>
              <Input
                textarea
                name="note"
                rows={8}
                appearance="secondary"
                placeholder={T('seem.note')}
                disabled={user?.isVisualizador()}
              />
              <Anex>
                <Button
                  label="Anexar"
                  onClick={() => {
                    onShowAnexoObservacao(true);
                  }}
                  disabled={user?.isVisualizador()}
                />
                <Button
                  submit
                  label={T('seem.submit')}
                  loading={loading}
                  disabled={user?.isVisualizador()}
                />
              </Anex>
            </Observation>
            {!noteAttachment?.data && (
              <table style={{ marginTop: '10px' }}>
                <thead>
                  <TableRow
                    columns={[
                      { payload: T('observation.name'), type: 'HEAD' },
                      { payload: T('observation.date'), type: 'HEAD' },
                      { payload: T('observation.user'), type: 'HEAD' },
                    ]}
                    as="th"
                  />
                </thead>
                <tbody>
                  {noteAttachment?.map((r) => (
                    <>
                      <TableRow
                        key={r.id}
                        columns={[
                          { payload: r.attachment_name, type: 'TEXT' },
                          { payload: r.created_at, type: 'TEXT' },
                          { payload: r.user_name, type: 'TEXT' },
                          {
                            payload: r.attachment_name ? { types: ['FILE'], fileAction: { onClick: () => { window.open(r.attachment_url, '_blank'); } } } : 1, type: r.attachment_name ? 'BUTTONS' : 'HEAD_BUTTONS',
                          },
                        ]}
                        dataLabels={[
                          '',
                          T('observation.name'),
                          T('observation.date'),
                          T('observation.user'),
                          T('observation.archive'),
                        ]}
                      />

                    </>
                  ))}
                </tbody>
              </table>

            )}
          </Accordion>
        )}

        {removed?.length > 0 && (
          <Diligences>
            <H6>{t('diligences.title')}</H6>
            <Table
              body={rows}
              route="/pesquisa/diligencia"
              onBody={onRows}
              heading={table}
            />
          </Diligences>
        )}

        <Modal
          title={T('modal_foreign.title')}
          show={showPickDiligence}
          onShow={onShowPickDiligence}
          action={{
            label: T('include_item.modal.action'),
            loading,
            onClick: foreignModalRef?.current?.submitForm,
          }}
          goBackOnCancel={() => navigate('/pesquisa?q=diligencia')}
          size="lg"
          loading={loading}
        >
          <ModalView className="modal-lg">
            <Form ref={foreignModalRef} onSubmit={onPickNameFormSubmit}>
              <P2>{T('modal_foreign.text')}</P2>
              <Select
                isMulti
                name="nameOptions"
                icon={ChevronDown}
                options={nameOptions}
                onChange={(val) => {
                  setChoosenNames(val);
                }}
                placeholder={T('modal_foreign.placeholder')}
              />
            </Form>
          </ModalView>
        </Modal>

        <Modal
          title={popupTitle}
          show={showPopup}
          onShow={onShowPopup}
          hasActions={false}
          size="lg"
          hasPrinterIcon
          onClickPrinter={handlePrint}
        >
          <PopupWrapper ref={popupWrapperRef}>
            <PopupViewer popup={popups} />
          </PopupWrapper>
        </Modal>

        <Modal
          title={T('include_item.modal.title')}
          show={showIncludeItem}
          onShow={onShowIncludeItem}
          action={{
            label: T('include_item.modal.action'),
            loading,
            onClick: includeItemRef?.current?.submitForm,
          }}
          loading={loading}
        >
          <ModalView className="modal-lg">
            <Form ref={includeItemRef} onSubmit={handleIncludeItem}>
              <P2>{T('include_item.modal.text')}</P2>
              <Select
                icon={ChevronDown}
                name="impactLevel"
                label={T('include_item.modal.impact_level.label')}
                options={[
                  {
                    value: 1,
                    label: T('include_item.modal.impact_level.options.0'),
                  },
                  {
                    value: 2,
                    label: T('include_item.modal.impact_level.options.1'),
                  },
                  {
                    value: 3,
                    label: T('include_item.modal.impact_level.options.2'),
                  },
                  {
                    value: 4,
                    label: T('include_item.modal.impact_level.options.3'),
                  },
                ]}
                placeholder={T('include_item.modal.impact_level.placeholder')}
                appearance="secondary"
              />
              <Input
                name="riskSourceName"
                label={T('include_item.modal.risk_source_name.label')}
                placeholder={T(
                  'include_item.modal.risk_source_name.placeholder',
                )}
                appearance="secondary"
              />
              <Upload
                name="upload"
                label={T('include_item.modal.upload')}
                isDocument
              />
            </Form>
          </ModalView>
        </Modal>

        <Modal
          title={T('associate_process.modal.title')}
          show={showIncludeProcess}
          onShow={setShowIncludeProcess}
          action={{
            label: T('associate_process.modal.action'),
            loading,
            onClick: includeProcessRef?.current?.submitForm,
          }}
          loading={loading}
        >
          <ModalView className="modal-lg">
            <Form ref={includeProcessRef} onSubmit={handleIncludeProcess}>
              <P2>{T('associate_process.modal.subtitle')}</P2>
              <Input
                name="attached_process_id"
                value={selectedDiligence?.value}
                reveal={false}
              />

              <SimpleSelect
                icon={Search}
                options={options.queryedDiligences}
                placeholder={T('associate_process.diligence')}
                value={selectedDiligence
                  ? { label: selectedDiligence?.label, value: selectedDiligence?.value }
                  : null}
                onChange={({ label, value }) => setSelectedDiligence({ label, value })}
                onInputChange={(val) => { setDiligenceSearch(val); }}
                clearField={{
                  label: T('associate_process.clear'),
                  onClick: () => setDiligenceSearch(null),
                }}
              />
            </Form>
          </ModalView>
        </Modal>

        <Modal
          title="Anexar arquivo"
          show={showAnexoObservacao}
          onShow={onShowAnexoObservacao}
          action={{
            label: T('include_item.modal.action'),
            loading,
            onClick: anexoObservacaoRef?.current?.submitForm,
          }}
          loading={loading}
        >
          <ModalView className="modal-sm">
            <Form ref={anexoObservacaoRef} onSubmit={handleAnexoObservacao}>
              <Upload
                name="upload"
                label={T('include_item.modal.upload')}
                isDocument
              />
            </Form>
          </ModalView>
        </Modal>

        <Modal
          title={`${T('toggle.modal_edit.title.main')} ${toggleData.active
            ? T('toggle.modal_edit.title.confirm')
            : T('toggle.modal_edit.title.discard')
          }`}
          show={showDiscardReason}
          onShow={onShowDiscardReason}
          action={{
            label: T('toggle.modal_edit.action'),
            loading,
            onClick: reasonRef?.current?.submitForm,
          }}
          loading={loading}
        >
          <ModalView>
            <P2>
              {`${T('toggle.modal_edit.text.main')} ${toggleData.active
                ? T('toggle.modal_edit.text.confirm')
                : T('toggle.modal_edit.text.discard')
              }.`}
            </P2>
            <Form
              ref={reasonRef}
              onSubmit={handleReason}
              initialData={{ reason: toggleData.reason }}
            >
              <Input name="id" reveal={false} value={toggleData.id} />
              <Input name="themeId" reveal={false} value={toggleData.themeId} />
              <Input name="riskId" reveal={false} value={toggleData.riskId} />
              <Input
                name="reason"
                label={T('toggle.modal_edit.reason.label')}
                placeholder={`${T(
                  'toggle.modal_edit.reason.placeholder.main',
                )} ${toggleData.active
                  ? T('toggle.modal_edit.reason.placeholder.confirm')
                  : T('toggle.modal_edit.reason.placeholder.discard')
                }`}
                appearance="secondary"
                textarea
              />
            </Form>
          </ModalView>
        </Modal>

        <Modal
          hasAction
          hasOverflow={false}
          show={showToggle}
          title={`${toggleData.active
            ? T('toggle.modal_toggle.title.discard')
            : T('toggle.modal_toggle.title.confirm')
          } ${toggleData.riskId
            ? T('toggle.modal_toggle.title.item')
            : T('toggle.modal_toggle.title.source')
          } ${T('toggle.modal_toggle.title.main')}`}
          onShow={onShowToggle}
          action={{
            label: `${T('toggle.modal_toggle.action.main')} ${toggleData.active
              ? T('toggle.modal_toggle.action.discard')
              : T('toggle.modal_toggle.action.confirm')
            }`,
            loading,
            onClick: toggleRef?.current?.submitForm,
          }}
        >
          <ModalView>
            <P2>
              {`${T('toggle.modal_toggle.text.main')} ${toggleData.active
                ? T('toggle.modal_toggle.text.discard')
                : T('toggle.modal_toggle.text.confirm')
              } ${T('toggle.modal_toggle.text.sub')}`}
            </P2>
            <Form ref={toggleRef} onSubmit={handleToggle}>
              <Input name="id" reveal={false} value={toggleData.id} />
              <Input name="themeId" reveal={false} value={toggleData.themeId} />
              <Input name="riskId" reveal={false} value={toggleData.riskId} />
              <Input
                name="reason"
                label={T('toggle.modal_toggle.reason.label')}
                placeholder={T('toggle.modal_toggle.reason.placeholder')}
                appearance="secondary"
                textarea
              />
            </Form>
          </ModalView>
        </Modal>

        <Modal
          title="Abrir avaliação"
          show={showOpenDiligenceModal}
          onShow={setOpenShowDiligenceModal}
          action={{
            label: T('open_associate.modal.open_without_associate'),
            loading: loadingBatchDiligence,
            icon: <Plus />,
            onClick: async () => {
              await setAssociatePartners(false);
              batchOpeningRef.current.submitForm();
            },
          }}
          secondaryAction={{
            label: T('open_associate.modal.open_associate'),
            loading: loadingAssociateDiligence,
            icon: <CornerUpRight />,
            onClick: async () => {
              await setAssociatePartners(true);
              batchOpeningRef.current.submitForm();
            },
          }}
          showCancel={false}
          loading={loading}
        >
          <ModalView className="modal-md">
            <BatchOpening
              ref={batchOpeningRef}
              onSubmit={handleBatchProcessesSubmit}
            >
              {selectedCompanies.length > 0 && (
                <>
                  <P1>{T('open_associate.modal.companies_workflows')}</P1>
                  {type === 'company' ? (
                    <Select
                      isMulti
                      name="companiesWorkflows"
                      icon={ChevronDown}
                      options={options.companyWorkflows}
                      disabled
                      initialValue={risks?.data?.workflows
                        .filter((workflowData) => workflowData.active === 1)
                        .map((workflowData) => ({ label: workflowData.name, value: workflowData.workflow_id }))}
                      placeholder={tSearch('components.workflows')}
                    />
                  ) : (
                    <Select
                      isMulti
                      name="companiesWorkflows"
                      icon={ChevronDown}
                      options={options.companyWorkflows}
                      onChange={(val) => {
                        onShow(false);
                        onWorkflow(val);
                      }}
                      isLoading={companyWorkflows === undefined}
                      placeholder={tSearch('components.workflows')}
                    />
                  )}
                </>
              )}

              {selectedPeople.length > 0 && (
                <>
                  <P1>{T('open_associate.modal.people_workflows')}</P1>
                  <Select
                    isMulti
                    name="peopleWorkflows"
                    icon={ChevronDown}
                    options={options.personWorkflows}
                    onChange={(val) => {
                      onShow(false);
                      onWorkflow(val);
                    }}
                    isLoading={personWorkflows === undefined}
                    placeholder={tSearch('components.workflows')}
                  />
                </>
              )}
            </BatchOpening>
          </ModalView>
        </Modal>

        <Modal
          title={T('surveys.modal.title')}
          show={showResendSurvey}
          onShow={onShowResendSurvey}
          action={{
            label: T('surveys.modal.action'),
            loading,
            onClick: surveyRef?.current?.submitForm,
          }}
        >
          <ModalView>
            <P2>{T('surveys.modal.text')}</P2>
            <Form ref={surveyRef} onSubmit={handleReSendSurvey}>
              <Input
                name="surveyRiskSourceId"
                value={resendSurveyID}
                reveal={false}
              />
              <Input
                name="name"
                label={T('surveys.modal.name.label')}
                placeholder={T('surveys.modal.name.placeholder')}
              />
              <Input
                name="email"
                label={T('surveys.modal.email.label')}
                placeholder={T('surveys.modal.email.placeholder')}
              />
            </Form>
          </ModalView>
        </Modal>

        <Modal
          title={T('surveys.modal.historyTitle')}
          show={showSurveyHistory}
          onShow={onShowSurveyHistory}
          hasActions={false}
          size="lg"
          // hasPrinterIcon
          // onClickPrinter={handleSurveyHistoryPrint}
        >
          <SurveyHistoryView ref={surveyHistoryViewRef} id={id} survey={surveyHistoryData} />
        </Modal>
      </Container>
    </View>
  );
};

export default ID;

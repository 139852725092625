import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { Form } from '@unform/web';
import { format } from 'date-fns';
import { any, number, string } from 'prop-types';
import * as Yup from 'yup';

import {
  Input, Modal, P2, TableRow,Radio
} from '~/components';
import { useApi, formatErrorMessage, parseISO } from '~/hooks';
import useItemMarkedFlag from '~/hooks/useItemMarkedFlag';
import { error } from '~/services';
import SurveyHistoryView from '~/views/Search/Response/Diligence/SurveyHistoryView';

import { ModalView } from '../../../styles';
import {
 ReportsModal, ReportsValuesModal,
} from '../../../../utils/Card/styles';

const SurveyRiskItem = ({
  id, name, risk, alerts, riskSourceID, answered_at, history, descricao_impacto, risk_impact_id,
}) => {
  const { t } = useTranslation('diligence');
  const { t: tAuth } = useTranslation('auth');
  const { t: T } = useTranslation('card');
  const { id: processID } = useParams();
  const { markedFlagColumn } = useItemMarkedFlag(id);
  const [loading, setLoading] = useState(false);
  const surveyHistoryViewRef = useRef(null);
  const [showResendSurvey, setShowResendSurvey] = useState(false);
  const [showSurveyHistory, setShowSurveyHistory] = useState(false);
  const [surveyHistoryData, setSurveyHistoryData] = useState(null);
  const [getRespondent, setGetRespondent] = useState(false);
  const [reportModal, onReportModal] = useState(false);
  const surveyRef = useRef(null);
  const reportsRef = useRef(null);
  const alert = useAlert();
  const api = useApi();

  useEffect(() => {
    if (getRespondent && surveyRef.current) {
      api().get(`/surveys/${riskSourceID}/lastResponder/${processID}`).then((res) => {
        surveyRef.current.setFieldValue("name", res?.data?.data?.name);
        surveyRef.current.setFieldValue("email", res?.data?.data?.email);
      })
    }
    setGetRespondent(false);
  }, [getRespondent]);

  const onChangeField = (event, field) => {
    surveyRef.current.setFieldValue(field, event.target.value)  
  }

  let answeredAt = null;

  try {
    answeredAt = format(parseISO(answered_at), 'dd/MM/yyyy');
  } catch { /* faz nada */ }

  const handleSurveyHistoryPrint = useReactToPrint({
    content: () => surveyHistoryViewRef.current,
  });
  const list_reports = [
    {
      name: 'general',
      options: [
        {
          id: 'executive',
          value: 'executive',
          placeholder: 'asd',
          label: T('reports.items.10'),
          description: T('description.executive'),
          reveal: true,
          selectedByDefault: true,
        },
        {
          id: 'summary',
          value: 'summary',
          label: T('reports.items.12'),
          description: T('description.summary'),
          reveal: true,
        },
        {
          id: 'full',
          value: 'full',
          label: T('reports.items.14'),
          description: T('description.full'),
          reveal: true,
        },
      ],
    },
  ];


  const list_langs = [
    {
      name: 'langs',
      options: [
        {
          id: 'pt',
          value: 'pt',
          placeholder: 'asd',
          label: 'PT',
          reveal: true,
          selectedByDefault: true,
        },
        {
          id: 'en',
          value: 'en',
          label: 'EN',
          reveal: true,
        },
        {
          id: 'es',
          value: 'es',
          label: 'ES',
          reveal: true,
        },
      ],
    },
  ];

  const handleReSendSurvey = useCallback(
    async (surveyData, { reset }) => {
      const { surveyRiskSourceId, ...surveyDataRest } = surveyData;
      Yup.object()
        .shape({
          name: Yup.string().required(t('surveys.alerts.name')),
          email: Yup.string().required(t('surveys.alerts.email')),
        })
        .validate(surveyData, {
          abortEarly: false,
        })
        .then(async () => {
          setLoading(true);
          try {
            await api().post(`/surveys/${riskSourceID}/reSend/${processID}`, surveyDataRest);
            alert.success(t('surveys.alerts.success'));
            setShowResendSurvey(false);
            reset();
          } catch ({ response }) {
            alert.error(formatErrorMessage(response, t('surveys.alerts.error'),tAuth('expired')));
          } finally {
            setLoading(false);
          }
        })
        .catch((err) => error(err, surveyRef));
    },
    [t, alert, processID, riskSourceID, tAuth],
  );


  const handleReportsSubmit = useCallback(async (datad) => {
    const surveyId = history[0].survey_id;

    if (!datad.langs) {
      alert.error(T('reports.alerts.error.0'));
      return;
    }

     setLoading(true);

    try {
      const res = await api().get(`process/${processID}/generate-pdf-survey/${surveyId}`, {
        params: {
          language: datad.langs,
        }
      });

      onReportModal(false);
      alert.success(res?.data?.message);
    } catch ({ response }) {
      alert.error(formatErrorMessage(response, T('reports.alerts.error.1'),tAuth('expired')));
    } finally {
      setLoading(false);
    }
  }, [alert, processID, T, tAuth]);

  return (
    <>
      <TableRow columns={[{ payload: name, type: 'TEXT' },
        {
          payload: { percentage: Math.round(Number(risk || 0)) },
          type: 'PROGRESS_BAR',
        },
        {
          className: `survey-status survey-status--${alerts > 0 ? 'error' : 'info'}`,
          payload: alerts > 0 ? `${alerts} alerta${alerts > 1 ? 's' : ''}` : 'Sem alertas',
          type: 'TEXT',
        },
        {
          payload: answeredAt || t('surveys.not_responded'),
          type: 'TEXT',
        },
        markedFlagColumn,
        (() => {
          const types = ['ADD_CLIPBOARD', 'REPEAT'];

          if (history) {
            types.push('FILE');
            types.push('GENERATE_PDF');
          }

          return {
            payload: {
              types,
              process_id: processID,
              result_id: id,
              subject: name.split(':').join(' -'),
              repeatAction: {
                onClick: () => {
                  setGetRespondent(true);
                  setShowResendSurvey(true);
                },
              },
              fileAction: {
                onClick: () => {
                  setSurveyHistoryData({
                    alerts,
                    risk,
                    history,
                    descricao_impacto,
                    risk_impact_id,
                  });
                  setShowSurveyHistory(true);
                },
              },
              generatePdfAction: {
                onClick: () => {
                  onReportModal(true);
                },
              },
              props: {
                pdf: {
                  state: {
                    disabled: Boolean(!answeredAt),
                    text: t('surveys.alerts.survey_not_answered')
                  }
                }
              }
            },
            type: 'BUTTONS',
          };
        })()]}
      />

    {reportModal && (
        <Modal
          show
          onShow={onReportModal}
          title={T('reports.modal.title')}
          action={{
            label: T('reports.modal.action'),
            loading,
            onClick: () => reportsRef?.current.submitForm(),
          }}
          size="lg"
        >
          <ReportsModal
            ref={reportsRef}
            onSubmit={handleReportsSubmit}
          >
              <P2>{T('reports.modal.select_report_language')}</P2>
              <div style={{ display: 'flex'}}>
                {list_langs?.map((item) => {
                  if (item.options?.length > 0) {
                    const initialValues = item.options
                      .filter((o) => !! o.selectedByDefault)
                      .map((o) => o.id);

                    return (
                      <Radio
                        containerDir="row"
                        name={item.name}
                        options={item.options}
                        initialValueSelected={initialValues}
                      />
                    );
                  }
                  return null;
                })}
              </div>
          </ReportsModal>
        </Modal>
      )}

      {showResendSurvey && (
        <Modal
          title={t('surveys.modal.title')}
          show
          onShow={setShowResendSurvey}
          action={{
            label: t('surveys.modal.action'),
            loading,
            onClick: () => surveyRef?.current?.submitForm(),
          }}
        >
          <ModalView>
            <P2>{t('surveys.modal.text')}</P2>
            <Form ref={surveyRef} onSubmit={handleReSendSurvey}>
              <Input
                name="name"
                label={t('surveys.modal.name.label')}
                onChange={(e)=>onChangeField(e, 'name')}
                placeholder={t('surveys.modal.name.placeholder')}
              />
              <Input
                name="email"
                label={t('surveys.modal.email.label')}
                onChange={(e) => onChangeField(e, 'email')}
                placeholder={t('surveys.modal.email.placeholder')}
              />
            </Form>
          </ModalView>
        </Modal>
      )}
      { showSurveyHistory && (
        <Modal
          title={t('surveys.modal.historyTitle')}
          show={showSurveyHistory}
          onShow={setShowSurveyHistory}
          hasActions={false}
          size="lg"
          // hasPrinterIcon
          // onClickPrinter={handleSurveyHistoryPrint}
        >
          <SurveyHistoryView ref={surveyHistoryViewRef} id={processID} survey={surveyHistoryData} />
        </Modal>
      )}
    </>
  );
};

SurveyRiskItem.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  risk: number.isRequired,
  alerts: number.isRequired,
  riskSourceID: number.isRequired,
  answered_at: string,
  // eslint-disable-next-line react/forbid-prop-types
  history: any,
  descricao_impacto: string,
  risk_impact_id: number,
};

SurveyRiskItem.defaultProps = {
  answered_at: '',
  history: null,
  descricao_impacto: null,
  risk_impact_id: null,
};

export default SurveyRiskItem;

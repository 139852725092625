import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-feather";
import { format } from "date-fns";

import { Modal, Button, Upload, P2, Select } from "~/components";
import { error, useMe } from "~/services";
import { useApi } from "~/hooks";

import { Form } from "@unform/web";

import * as Yup from "yup";

import { ModalView, MainForm, ErrorWrapper } from "./style";

const NotificationSettings = () => {
  const { t } = useTranslation("settings");

  const api = useApi();
  const alert = useAlert();
  const { user, group, newGroup } = useMe();

  const [isSubmitting, setSubmitting] = useState({
    upload: false,
    download: false,
  });
  const [loadingBatches, onLoadingBatches] = useState(false);
  const [attechmentFile, setAttechmentFile] = useState(null);
  const [showAttechmentModal, setShowAttechmentModal] = useState(false);

  const uploadRef = useRef();

  const FR = new FileReader();

  const grupos = user.hierarquia?.map((item) => ({
    value: item.id,
    label: item.cliente,
  }));

  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }

  if (grupos?.length > 1 && user.grupos && user.grupos.length > 0) {
    grupos.unshift({
      value: 0,
      label: user.grupos[0].grupo,
    });
  }

  const download = async () => {
    setSubmitting({ download: true });

    const now = format(new Date(), "dd/MM/yyyy");

    try {
      await api()
        .post(`/settings/download/spreadsheet`, null, {
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res?.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `risk_spreadsheet_${now}.xls`);
          document.body.appendChild(link);
          link.click();

          alert.success(t("risk.download.success"));
        });
    } catch (error) {
      alert.error(`${t("risk.download.error")}: ${error}`);
    } finally {
      setSubmitting({ download: false });
    }
  };

  const upload = async (data) => {
    await Yup.object()
      .shape({
        attechment: Yup.mixed().required(t("risk.validations.file_required")),
      })
      .validate(data, { abortEarly: false })
      .then(async () => {
        setShowAttechmentModal(false);
        const clientId = group.value;
        const groupId = user.grupos[0]?.idgrupo;

        const toSubmit = {
          file: attechmentFile,
          size: data.attechment.size,
          mimeType: data.attechment.type,
          originalName: data.attechment.name,
          groupId: groupId ? groupId : undefined,
          clientId: clientId ? clientId : undefined,
          extension: data.attechment.name.split(".")[1],
        };

        await api()
          .post("/settings/upload/spreadsheet", toSubmit)
          .then(() => {
            setAttechmentFile(null);
            onLoadingBatches(false);
            alert.success(t("risk.upload.success"));
          })
          .catch(({ response }) => {
            let error = null;

            if (response?.data?.errors) {
              Object.keys(response.data.errors).forEach((item) => {
                error = (
                  <ErrorWrapper>
                    <span>{t("risk.upload.error")}:</span>
                    {response.data.errors[item].map((item) => (
                      <span>{item}</span>
                    ))}
                  </ErrorWrapper>
                );
              });
            }

            if (typeof response?.data.data === "string") {
              error = `${t("risk.upload.error")}: ${response?.data.data}`;
            } else if (response?.data.data) {
              error = (
                <ErrorWrapper>
                  <span>{t("risk.upload.error")}:</span>
                  {response.data.data.map((item) => (
                    <span>{item}</span>
                  ))}
                </ErrorWrapper>
              );
            }
            alert.error(error);
            onLoadingBatches(false);
          });
      })
      .catch((err) => {
        alert.error(err.message);
        error(err, uploadRef);
        onLoadingBatches(false);
      });
  };

  FR.onload = (ev) => {
    onLoadingBatches(true);
    setAttechmentFile(ev.target.result);
  };

  return (
    <MainForm>
      <Button
        onClick={download}
        loading={isSubmitting.download}
        label={t("risk.download_button")}
      />

      <Button
        loading={isSubmitting.upload}
        label={t("risk.upload_button")}
        onClick={() => {
          uploadRef.current.reset();
          setShowAttechmentModal(true);
        }}
      />

      <Form ref={uploadRef} onSubmit={upload}>
        <Modal
          show={showAttechmentModal}
          onShow={setShowAttechmentModal}
          hasActions
          title={t("risk.modal.title")}
          action={{
            label: t("risk.modal.primary_button"),
            onClick: async () => {
              onLoadingBatches(true);
              uploadRef.current.submitForm();
            },
            loadingBatches,
          }}
        >
          <ModalView>
            <P2>{t("risk.modal.description")}</P2>

            <Upload
              ext="document"
              isDocument
              onInput={(evt) => {
                FR.readAsDataURL(evt.target.files[0]);
              }}
              name={"attechment"}
            />
          </ModalView>
        </Modal>
      </Form>
    </MainForm>
  );
};

export default NotificationSettings;

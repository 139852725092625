import React from "react";

import { isEmpty } from "lodash";

import { P1 } from "../Title";

const FiliacaoPartidaria = ({ data }) => {
  const name = !isEmpty(data[0]) ? data[0].name : "";

  const situation = !isEmpty(data[1]?.situation) ? data[1].situation : "";

  const votersCard = !isEmpty(data[2]?.voters_card) ? data[2].voters_card : "";

  const occurrenceData = !isEmpty(data[3]?.occurrenceData)
    ? data[3]?.occurrenceData?.map((item) => ({
        partido: item.partido,
        uf: item.uf,
        municipio: item.municipio,
        dataCadastroFiliacao: item.dataHistorico,
        dataFiliacao: item.dataFiliacao,
        situacao: item.situacao,
      }))
    : {};

  const heading = !isEmpty(occurrenceData)
    ? Object.keys(occurrenceData[0])?.map((item) => ({
        label: item,
      }))
    : {};

  const handleNames = (label) => {
    let newLabel = "";

    switch (label) {
      case "partido":
        newLabel = "Partido";
        break;
      case "uf":
        newLabel = "UF";
        break;
      case "municipio":
        newLabel = "Município";
        break;
      case "dataCadastroFiliacao":
        newLabel = "Data Cadastro Filiação";
        break;
      case "dataFiliacao":
        newLabel = "Data Filiação";
        break;
      case "situacao":
        newLabel = "Situação";
        break;
    }

    return newLabel;
  };

  return (
    <div>
      <P1 style={{ lineHeight: "1.6rem", padding: "1.6rem 0rem" }}>
        <strong>Nome do Eleitor(a)</strong>
        {": "}
        <span>{name}</span>
      </P1>

      <P1 style={{ lineHeight: "1.6rem", padding: "1.6rem 0rem" }}>
        <strong>Título Eleitoral</strong>
        {": "}
        <span>{votersCard}</span>
      </P1>

      <P1 style={{ lineHeight: "1.6rem", padding: "1.6rem 0rem" }}>
        <strong>Situação</strong>
        {": "}
        <span>{situation}</span>
      </P1>
      {!isEmpty(occurrenceData) && (
        <P1 style={{ lineHeight: "1.6rem", padding: "1.6rem 0rem" }}>
          <strong style={{ paddingBottom: "0.5rem" }}>
            Dados da Filiação Partidária:
          </strong>
          <table
            style={{
              width: "100%",
              fontSize: "15px",
              marginTop: "12px",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                {Object.keys(heading).map((item) => {
                  const label = heading[item].label;
                  return (
                    <th style={{ border: "1px solid #dddddd" }}>
                      <span>{handleNames(label)}</span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(occurrenceData).map((key) => {
                if (key === 0) delete occurrenceData[key];

                return (
                  <tr
                    style={{ border: "1px solid #dddddd", textAlign: "center" }}
                  >
                    {Object.values(occurrenceData[key]).map((item) => {
                      if (key === 0) delete occurrenceData[key];

                      return (
                        <td style={{ border: "1px solid #dddddd" }}>
                          <span>{item ? item : "Não verificado "}</span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </P1>
      )}
    </div>
  );
};

export default FiliacaoPartidaria;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Flag, Modal, P2 } from '~/components';
import { formatErrorMessage, reduce, useApi } from '~/hooks';
import { ResultHead } from '~/layout';

import { useResultData } from '../../resultContext';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useMe } from '~/services';

const DiligenceResultHeader = () => {
  const { headers, entityNames, mutateHeaders } = useResultData();
  const { t } = useTranslation('results');
  const { id: processID } = useParams();
  const { user } = useMe();
  const api = useApi();
  const alert = useAlert();
  
  const [ showChangeStatusModal, setShowChangeStatusModal ] = useState(false);
  const [ processId, setProcessId ] = useState(false);
  // headers?.data?.id

  const changeStatus = async () => {
    await api().post(`/services/process/${processId}/changeStatus`)
      .then( async () => {
        alert.success(t('modal.alerts.success'))
        setShowChangeStatusModal(false);
        setProcessId(null);
        await mutateHeaders();
      })
      .catch( async error => {
        alert.error(formatErrorMessage(error.response));
      })
  }

  const changeStatusModal = () => (
    <Modal
      show={ showChangeStatusModal }
      onShow={ setShowChangeStatusModal }
      hasAction
      action={{
        label: t('modal.actions.confirm'),
        onClick: () => changeStatus(),
      }}
      goBackOnCancel={() => {
        setProcessId(null);
        setShowChangeStatusModal(false);
      }}
      title={ t('modal.tittle') }
    >
      <P2>{t('modal.description')}</P2>
    </Modal>
  );
  
  return (
    <ResultHead
      title={`[${headers?.data?.show_id || processID}] ${entityNames.length > 0 ? reduce(entityNames.join(' / '), 32) : headers?.data?.cnpj}`}
      statusName={headers?.data?.entity_status && headers?.data?.entity_status.id !== 1
        ? headers?.data?.entity_status?.description : undefined}
      pageTitle={{title: entityNames ?? null}}
    >
      {headers?.tag.map((item) => (
        <Flag key={item.tag.id.toString()} status={item.tag.name} />
      ))}

      { headers?.data?.new_status?.name && 
        <Flag 
          status={headers?.data?.new_status?.name} 
          isLink={
            ( user.isMaster() || user.isGestor() || user.isAdminICTS() ) && 
            [4, 15].includes(headers?.data?.new_status?.id)
          } 
          action={ () => {
            setProcessId(headers?.data?.id);
            setShowChangeStatusModal(true);
          } } 
        />
      }

      { changeStatusModal() }
    </ResultHead>
  );
};

export default DiligenceResultHeader;

import React, {
  forwardRef, useImperativeHandle, useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { H6, Row } from '~/components';
import { Step } from './styles';
import { Form } from '@unform/web';
import { array, func } from 'prop-types';

/**
 * Function responsable to validate the step's number.
 * @param search
 * @param stepLenght
 * @returns {number|number}
 */
const getCurrentStep = (search, stepLenght) => {
  const searchParams = new URLSearchParams(search);
  const parsedStep = Number(searchParams.get('step') ?? 1);

  return ( isNaN(parsedStep) || parsedStep < 0 || parsedStep > stepLenght || !parsedStep )
    ? 1
    : parsedStep;
};

const StepViewer = forwardRef(({ steps, onSubmit }, ref) => {
  const formRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  
  useImperativeHandle(ref, () => ( {
    gotoNextStep: () => {
      const currentStep = getCurrentStep(location.search, steps.length);
      if(currentStep > steps.length) {
        return false;
      }
      navigate(`${ location.pathname }?step=${ currentStep + 1 }`);
    },
    
    getFormValue: (fieldName) => {
      return formRef.current?.getFieldValue(fieldName);
    },
    
    getFormData: () => {
      return formRef.current?.getData();
    },
    
    getFormRef: () => {
      return formRef;
    },
  } ));

  const currentStep = getCurrentStep(location.search, steps.length);

  if(steps.length === 0) {
    return 0;
  }

  return (
    <>
      <Row flex={ 'center' }>
        { steps.map((step, index) => (
          <Step
            key={ index }
            active={ currentStep > index }
            imNext={ currentStep === index }
          >
            <div
              className={ 'dot' }
              onClick={ () => {
                if(currentStep - 1 !== index) {
                  navigate(`${ location.pathname }?step=${ index + 1 }`);
                }
              } }
            >
              { index + 1 }
            </div>
            <H6>
              { step.label ?? `Etapa ${ index + 1 }` }
            </H6>
          </Step>
        )) }
      </Row>

      <Form
        ref={ formRef }
        onSubmit={ (...args) => {
          onSubmit(...args);
        } }>
        {
          steps.map(({ component }, index) => (
            <div style={ { display: index === currentStep - 1 ? 'flex' : 'none' } }>
              { component }
            </div>
          ))
        }
      </Form>
    </>
  );
});

StepViewer.propTypes = {
  steps: array,
  onSubmit: func,
};

StepViewer.displayName = 'StepView';

export default StepViewer;

import React from 'react';
import { ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  bool,
  array,
  string,
  object,
  oneOfType,
} from 'prop-types';

import {Flag, H4, Helmet} from '~/components';

import View from './styles';

const Head = ({
  title,
  statusName,
  children,
  pageTitle,
  hasArrow
}) => {
  const navigate = useNavigate();

  return (
    <View>
      {pageTitle && <Helmet title={pageTitle.title} />}
      <H4 isBold>
        { hasArrow && <ArrowLeft
          size="2.8rem"
          onClick={ () => navigate(-1) }
        /> }
        {title}

        {statusName && statusName.length > 0
          && <Flag size="md" status={statusName} />}
      </H4>

      {children && <div>{children}</div>}
    </View>
  );
};

Head.propTypes = {
  title: string,
  statusName: string,
  children: oneOfType([bool, object, array]),
  pageTitle: object,
  hasArrow: bool
};

Head.defaultProps = {
  title: '',
  statusName: '',
  children: false,
  pageTitle: null,
  hasArrow: false
};

export default Head;

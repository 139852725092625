import { useEffect } from 'react';

import { useEcho } from '~/services/echo';

const useWebSocketRefresh = ({
  channel, events, mutations,
}, deps = []) => {
  const echo = useEcho();
  const chan = echo.channel(channel);

  useEffect(() => {
    if (events) {
      events.forEach((event) => {
        chan.listen(event, () => {
          mutations.forEach((mutation) => { mutation(); });
        });
      });
    }

    return () => {
      if (events) {
        events.forEach((event) => { chan.stopListening(event); });
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useWebSocketRefresh;

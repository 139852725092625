/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAlert } from "react-alert";
import { CornerUpRight, Plus, Search, ChevronDown } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { format } from "date-fns";

import { Scope } from "@unform/core";

import {
  Button,
  Checkbox,
  H6,
  Input,
  Modal,
  P1,
  P2,
  Radio,
  Select,
  SimpleLoading,
  TableRow,
} from "~/components";
import {
  formatErrorMessage,
  masked,
  parseISO,
  useApi,
  useFetch,
} from "~/hooks";
import { useMe } from "~/services";

import { useResultData } from "../../resultContext";
import { AlignRight, BatchOpening, ModalView, Panel } from "../../styles";
import { useSelectedPartner } from "./selectedPartnersContext";

const PartnersData = () => {
  const { group } = useMe();
  const { id: processID } = useParams();
  const { t: tAuth } = useTranslation("auth");
  const { t, ready } = useTranslation(["entities", "diligence", "search"], {
    useSuspense: false,
  });

  const api = useApi();
  const alert = useAlert();
  const batchOpeningRef = useRef(null);

  const {
    corporateData,
    getProcessType,
    headers,
    generalRisks,
    mutateEverything,
  } = useResultData();

  const {
    selectedPeople,
    setSelectedPeople,
    selectedCompanies,
    setSelectedCompanies,
    selectedPeopleEx,
    setSelectedPeopleEx,
    selectedCompaniesEx,
    setSelectedCompaniesEx,
  } = useSelectedPartner();

  const [loading, setLoading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [diligenceType, setDiligenceType] = useState(null);
  const [peopleWorkflow, setPeopleWorkflow] = useState(null);
  const [companyWorkflow, setCompanyWorkflow] = useState(null);
  const [showOpenDiligenceModal, setShowOpenDiligenceModal] = useState(false);

  const clientId = headers?.data?.idcliente;
  const labelToMatchPartners = "Avaliação básica de sócios";

  const submitButtonIsDisabled =
    !diligenceType ||
    (selectedPeople.length && !peopleWorkflow) ||
    (selectedCompanies.length && !companyWorkflow);

  const { employees, shareholding } = corporateData?.data ?? {};

  const { data: personWorkflows, isValidating: isValidatingPersonWorkflows } =
    useFetch({
      url:
        showOpenDiligenceModal && selectedPeople.length > 0
          ? `/clients/list/workflows?${
              group.value === 0 ? "" : "clientId=" + clientId
            }&type=1`
          : null,
    });

  const { data: companyWorkflows, isValidating: isValidatingCompanyWorkflows } =
    useFetch({
      url:
        showOpenDiligenceModal && selectedCompanies.length > 0
          ? `/clients/list/workflows?${
              group.value === 0 ? "" : "clientId=" + clientId
            }&type=2`
          : null,
    });

  const {
    data: personExWorkflows,
    isValidating: isValidatingPersonExWorkflows,
  } = useFetch({
    url:
      showOpenDiligenceModal && selectedPeopleEx.length > 0
        ? `/clients/list/workflows${
            group.value === 0 ? "" : "clientId=" + clientId
          }&type=3&notBasic=1`
        : null,
  });

  const {
    data: companyExWorkflows,
    isValidating: isValidatingCompanyExWorkflows,
  } = useFetch({
    url:
      showOpenDiligenceModal && selectedCompaniesEx.length > 0
        ? `/clients/list/workflows?${
            group.value === 0 ? "" : "clientId=" + clientId
          }&type=4&notBasic=1`
        : null,
  });

  const isWorkflowsLoading =
    isValidatingPersonWorkflows ||
    isValidatingCompanyWorkflows ||
    isValidatingPersonExWorkflows ||
    isValidatingCompanyExWorkflows;

  const initialValueWorkflow = () => {
    const element = generalRisks?.data?.workflows.filter(
      (workflowData) => workflowData.active === 1
    );

    return {
      value: element[0]?.workflow_id || null,
      label:
        element[0]?.name ||
        "O workflow da avaliação foi desativado, reative-o!",
    };
  };

  const initialOptions = useMemo(
    () => ({
      personWorkflows:
        personWorkflows
          ?.filter((item) => item.active !== 0)
          .map((item) => ({
            value: item.id,
            label: item.name,
          })) ?? [],
      companyWorkflows:
        companyWorkflows
          ?.filter((item) => item.active !== 0)
          .map((item) => ({
            value: item.id,
            label: item.name,
          })) ?? [],
      personExWorkflows:
        personExWorkflows
          ?.filter((item) => item.active !== 0)
          .map((item) => ({
            value: item.id,
            label: item.name,
          })) ?? [],
      companyExWorkflows:
        companyExWorkflows
          ?.filter((item) => item.active !== 0)
          .map((item) => ({
            value: item.id,
            label: item.name,
          })) ?? [],
    }),
    [personWorkflows, companyWorkflows, personExWorkflows, companyExWorkflows]
  );

  const [options, setOptions] = useState({});

  const selectPartner = useCallback(
    (document, checked) => {
      let changes;
      if (getProcessType() === "person" || getProcessType() === "company") {
        changes =
          document.length === 11 ? setSelectedPeople : setSelectedCompanies;
      } else {
        changes =
          document.type === "person_ex"
            ? setSelectedPeopleEx
            : setSelectedCompaniesEx;
      }
      const setFn = changes;

      if (checked) {
        setFn((old) => [...old, document]);
      } else {
        setFn((old) => old.filter((doc) => doc !== document));
      }
    },
    [getProcessType, allChecked]
  );

  const handleCheckAll = useCallback(
    (evt, data) => {
      if (evt.target.checked) {
        data
          .filter((value) => !selectedCompanies.includes(value.document))
          .filter((value) => !selectedPeopleEx.includes(value.document))
          .filter((value) => !selectedCompaniesEx.includes(value.document))
          .filter((value) => !selectedPeople.includes(value.document))
          .map((value) => selectPartner(value.document, evt.target.checked));
      } else {
        setSelectedPeople([]);
        setSelectedCompanies([]);
        setSelectedPeopleEx([]);
        setSelectedCompaniesEx([]);
      }
    },
    [selectedCompanies, selectedPeopleEx, selectedCompaniesEx, selectedPeople]
  );

  const findValue = (doc, type) => {
    if (type === "person" || type === "company")
      return doc.document.length === 11
        ? selectedPeople?.includes(doc.document)
        : selectedCompanies?.includes(doc.document);
    else
      return doc.type === "person_ex"
        ? selectedPeopleEx?.includes(doc.name)
        : selectedCompaniesEx?.includes(doc.name);
  };

  useEffect(() => {
    employees &&
      setAllChecked(
        selectedPeople?.length +
          selectedCompanies?.length +
          selectedPeopleEx?.length +
          selectedCompaniesEx?.length ===
          employees?.length
      );

    shareholding &&
      setAllChecked(
        selectedPeople?.length +
          selectedCompanies?.length +
          selectedPeopleEx?.length +
          selectedCompaniesEx?.length ===
          shareholding?.length
      );
  }, [
    selectedPeople,
    selectedCompanies,
    selectedPeopleEx,
    selectedCompaniesEx,
  ]);

  const employeeRow = useMemo(
    () =>
      employees?.map((employee) => {
        const tryParseDate = (date) => {
          try {
            return format(parseISO(date), "dd/MM/yyyy");
          } catch {
            return t("entities:cadastral_data.fields.not_found");
          }
        };

        return [
          {
            payload: {
              value: {
                value:
                  getProcessType() === "person" ||
                  getProcessType() === "company"
                    ? employee.document
                    : {
                        value: employee.name,
                        type: "person_ex",
                      },
                state: findValue(employee, getProcessType()),
              },
              action: selectPartner,
            },
            type: "CHECKALL",
          },
          {
            payload:
              employee.status === "ATUAL"
                ? t("entities:cadastral_data.fields.current")
                : employee.status === "ANTERIOR"
                ? t("entities:cadastral_data.fields.previous")
                : t("entities:cadastral_data.fields.not_found"),
            type: "TEXT",
          },
          {
            payload:
              employee.name || t("entities:cadastral_data.fields.not_found"),
            type: "TEXT",
          },
          {
            payload: employee.relationship_name,
            type: "TEXT",
          },
          {
            payload: employee.document ? masked(employee.document) : "-",
            type: "DOCUMENT",
          },
          {
            payload: employee.relationship_start_date
              ? tryParseDate(employee.relationship_start_date)
              : t("entities:cadastral_data.fields.not_found"),
            type: "TEXT",
          },
          {
            payload: employee.relationship_end_date
              ? format(parseISO(employee.relationship_end_date), "dd/MM/yyyy")
              : t("entities:cadastral_data.fields.not_found"),
            type: "TEXT",
          },
        ];
      }),
    [
      employees,
      t,
      selectPartner,
      getProcessType,
      allChecked,
      selectedPeople,
      selectedCompanies,
      selectedPeopleEx,
      selectedCompaniesEx,
    ]
  );

  const partnershipParticipationRow = useMemo(
    () =>
      shareholding?.map((company) => [
        {
          payload: {
            value: {
              value:
                getProcessType() === "person" || getProcessType() === "company"
                  ? company.document
                  : {
                      value: company.name,
                      type: "company_ex",
                    },
              state: findValue(company, getProcessType()),
            },
            action: selectPartner,
          },
          type: "CHECKALL",
        },
        {
          payload:
            company.status === "ATUAL"
              ? t("entities:cadastral_data.fields.current")
              : company.status === "ANTERIOR"
              ? t("entities:cadastral_data.fields.previous")
              : t("entities:cadastral_data.fields.not_found"),
          type: "TEXT",
        },
        { payload: company.company_name, type: "TEXT" },
        { payload: company.relationship_name, type: "TEXT" },
        {
          payload: company.document ? masked(company.document) : "-",
          type: "DOCUMENT",
        },
        {
          payload: company.relationship_start_date
            ? format(parseISO(company.relationship_start_date), "dd/MM/yyyy")
            : t("entities:cadastral_data.fields.not_found"),
          type: "TEXT",
        },
        {
          payload: company.relationship_end_date
            ? format(parseISO(company.relationship_end_date), "dd/MM/yyyy")
            : t("entities:cadastral_data.fields.not_found"),
          type: "TEXT",
        },
      ]),
    [
      shareholding,
      t,
      selectPartner,
      getProcessType,
      allChecked,
      selectedPeople,
      selectedCompanies,
      selectedPeopleEx,
      selectedCompaniesEx,
    ]
  );

  const handleSubmit = useCallback(
    async (associate) => {
      const {
        peopleWorkflows,
        companiesWorkflows,
        companiesExWorkflows,
        peopleExWorkflows,
        risks,
      } = batchOpeningRef.current.getData();
      if (
        (selectedPeople.length &&
          (!peopleWorkflows || peopleWorkflows?.length === 0)) ||
        (selectedCompanies.length &&
          (!companiesWorkflows || companiesWorkflows?.length === 0)) ||
        (selectedPeopleEx.length &&
          (!peopleExWorkflows || peopleExWorkflows?.length === 0)) ||
        (selectedCompaniesEx.length &&
          (!companiesExWorkflows || companiesExWorkflows?.length === 0))
      ) {
        alert.error(t("diligence:open_associate.modal.error.title"));
        return;
      }

      setLoading(true);

      const risksFormatted = risks?.reduce((acc, curr) => {
        const repeated = acc.find((item) => item.id === curr.id);
        if (!repeated) {
          return acc.concat([curr]);
        }
        return acc;
      }, []);

      const peopleDiligences = selectedPeople.map((document) => ({
        document,
        workflows: [peopleWorkflows],
        clientId: clientId,
        personType: 1,
        associate: associate ? processID : null,
        risks: risksFormatted,
        originFromQsa: true,
      }));

      const companiesDiligences = selectedCompanies.map((document) => ({
        document,
        workflows: [companiesWorkflows],
        clientId: clientId,
        personType: 2,
        associate: associate ? processID : null,
        risks: risksFormatted,
        originFromQsa: true,
      }));

      const peopleExDiligences = selectedPeopleEx.map((document) => ({
        name: document.value,
        name_jurisdiction: document.value,
        workflows: [peopleExWorkflows],
        clientId: clientId,
        jurisdiction: headers?.data?.jurisdicao_id,
        personType: 3,
        associate: associate ? processID : null,
      }));

      const companiesExDiligences = selectedCompaniesEx.map((document) => ({
        name: document.value,
        name_jurisdiction: document.value,
        workflows: [companiesExWorkflows],
        clientId: clientId,
        jurisdiction: headers?.data?.jurisdicao_id,
        personType: 4,
        associate: associate ? processID : null,
      }));

      const formattedDiligences = {
        diligences: [
          ...peopleDiligences,
          ...companiesDiligences,
          ...peopleExDiligences,
          ...companiesExDiligences,
        ],
      };

      try {
        await api().post("/process/store", formattedDiligences);
        handleClose();
        alert.success(t("search:evaluation.alerts.success"));
        mutateEverything();
      } catch ({ response }) {
        alert.error(
          formatErrorMessage(
            response,
            t("search:evaluation.alerts.error"),
            tAuth("expired")
          )
        );
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      batchOpeningRef,
      selectedPeople,
      selectedCompanies,
      selectedPeopleEx,
      selectedCompaniesEx,
      alert,
      t,
      headers,
      processID,
      tAuth,
    ]
  );

  const handleClose = () => {
    setDiligenceType(null);
    setShowOpenDiligenceModal(false);
  };

  const handleCheckboxChange = (option) => {
    setDiligenceType(option);

    if (batchOpeningRef?.current) {
      batchOpeningRef.current.setFieldValue("peopleWorkflows", "");
      batchOpeningRef.current.setFieldValue("companiesWorkflows", "");
    }

    if (selectedCompanies.length) {
      if (option === "openAssociate") {
        const partnerWorkflow = initialOptions.companyWorkflows?.filter(
          (item) => item.label === labelToMatchPartners
        );

        if (Array.isArray(partnerWorkflow))
          partnerWorkflow.push(initialValueWorkflow());

        setOptions((prev) => ({
          ...prev,
          companyWorkflows: partnerWorkflow,
        }));
      }

      if (option === "openWithoutAssociate") {
        const workflowsWithoutPartner = initialOptions.companyWorkflows?.filter(
          (item) => item.label !== labelToMatchPartners
        );

        setOptions((prev) => ({
          ...prev,
          companyWorkflows: workflowsWithoutPartner,
        }));
      }
    }

    if (selectedPeople.length) {
      if (option === "openAssociate") {
        setOptions((prev) => ({
          ...prev,
          personWorkflows: initialOptions.personWorkflows,
        }));
      }

      if (option === "openWithoutAssociate") {
        const workflowsWithoutPartner = initialOptions.personWorkflows?.filter(
          (item) => item.label !== labelToMatchPartners
        );

        setOptions((prev) => ({
          ...prev,
          personWorkflows: workflowsWithoutPartner,
        }));
      }
    }
  };

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  if (!ready) {
    return <SimpleLoading />;
  }

  return (
    <>
      {employees?.length > 0 && (
        <Panel>
          <div style={{ marginLeft: "2.4rem" }}>
            <Checkbox
              size={"sm"}
              label={t("entities:corporate_data.socios.select_all")}
              selectedProp={allChecked}
              onSelected={(event) => {
                handleCheckAll(event, employees);
              }}
            />
          </div>
          <table>
            <thead>
              <TableRow
                style={{ backgroundColor: "#fff" }}
                columns={[
                  {
                    payload: "",
                    type: "HEAD",
                  },
                  {
                    payload: t("entities:corporate_data.socios.columns.0"),
                    type: "HEAD",
                  },
                  {
                    payload: t("entities:corporate_data.socios.columns.1"),
                    type: "HEAD",
                  },
                  {
                    payload: t("entities:corporate_data.socios.columns.2"),
                    type: "HEAD",
                  },
                  {
                    payload: t("entities:corporate_data.socios.columns.3"),
                    type: "HEAD",
                  },
                  {
                    payload: t("entities:corporate_data.socios.columns.4"),
                    type: "HEAD",
                  },
                  {
                    payload: t("entities:corporate_data.socios.columns.5"),
                    type: "HEAD",
                  },
                ]}
              />
            </thead>
            <tbody>
              {employeeRow?.map((partnerColumn) => {
                const { payload: document } = partnerColumn.find(
                  ({ type }) => type === "DOCUMENT"
                );
                return <TableRow key={document} columns={partnerColumn} />;
              })}
            </tbody>
          </table>
        </Panel>
      )}

      {shareholding?.length > 0 && (
        <Panel>
          <H6>
            {t("entities:corporate_data.participacoes_societarias.title")}
          </H6>
          <div style={{ marginLeft: "2.4rem" }}>
            <Checkbox
              size={"sm"}
              label={t("entities:corporate_data.socios.select_all")}
              selectedProp={allChecked}
              onSelected={(event) => {
                handleCheckAll(event, shareholding);
              }}
            />
          </div>
          <table>
            <thead>
              <TableRow
                style={{ backgroundColor: "#fff" }}
                columns={[
                  {
                    payload: "",
                    type: "HEAD",
                  },
                  {
                    payload: t(
                      "corporate_data.participacoes_societarias.columns.0"
                    ),
                    type: "HEAD",
                  },
                  {
                    payload: t(
                      "corporate_data.participacoes_societarias.columns.1"
                    ),
                    type: "HEAD",
                  },
                  {
                    payload: t(
                      "corporate_data.participacoes_societarias.columns.2"
                    ),
                    type: "HEAD",
                  },
                  {
                    payload: t(
                      "corporate_data.participacoes_societarias.columns.3"
                    ),
                    type: "HEAD",
                  },
                  {
                    payload: t(
                      "corporate_data.participacoes_societarias.columns.4"
                    ),
                    type: "HEAD",
                  },
                  {
                    payload: t(
                      "corporate_data.participacoes_societarias.columns.5"
                    ),
                    type: "HEAD",
                  },
                ]}
              />
            </thead>
            <tbody>
              {partnershipParticipationRow?.map((partnerColumn) => {
                const { payload: document } = partnerColumn.find(
                  ({ type }) => type === "DOCUMENT"
                );
                return <TableRow key={document} columns={partnerColumn} />;
              })}
            </tbody>
          </table>
        </Panel>
      )}

      <AlignRight>
        <Button
          iconLeft={<Search className="icon-search" />}
          label={t("diligence:partners.evaluate")}
          onClick={() => setShowOpenDiligenceModal(true)}
          disabled={
            !selectedCompanies.length &&
            !selectedPeople.length &&
            !selectedPeopleEx.length &&
            !selectedCompaniesEx.length
          }
        />
      </AlignRight>
      {showOpenDiligenceModal && (
        <Modal
          size="lg"
          loading
          showCancel={false}
          goBackOnCancel={handleClose}
          show={showOpenDiligenceModal}
          onShow={setShowOpenDiligenceModal}
          title={t("diligence:open_associate.modal.title")}
          action={{
            loading,
            disabled: submitButtonIsDisabled,
            label: t("diligence:partners.evaluate"),
            icon: <Search className="icon-search" />,
            onClick: () => handleSubmit(diligenceType === "openAssociate"),
          }}
        >
          <ModalView className="modal-lg" size="150rem">
            {isWorkflowsLoading ? (
              <SimpleLoading />
            ) : (
              <BatchOpening ref={batchOpeningRef}>
                <div
                  style={{
                    gap: "24px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontSize: "1.6rem" }}>
                      {t("diligence:open_associate.modal.radio_group_label")}
                    </span>
                    <div
                      style={{
                        gap: "8rem",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Radio
                        labelDir={"row"}
                        containerDir="row"
                        name={"themeName"}
                        options={[
                          {
                            id: "openAssociate",
                            value: "openAssociate",
                            label: t(
                              "diligence:open_associate.modal.open_associate"
                            ),
                          },
                          {
                            id: "openWithoutAssociate",
                            value: "openWithoutAssociate",
                            label: t(
                              "diligence:open_associate.modal.open_without_associate"
                            ),
                          },
                        ]}
                        onChange={(evt) =>
                          handleCheckboxChange(evt.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      gap: "16px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        gap: "16px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {selectedCompanies.length > 0 && (
                        <div
                          style={{
                            flex: 1,
                            gap: "8px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontSize: "1.4rem" }}>
                            {t(
                              "diligence:open_associate.modal.select_label_pj"
                            )}
                          </span>
                          <div style={{ maxWidth: "462px" }}>
                            <Select
                              icon={ChevronDown}
                              menuPosition="fixed"
                              name="companiesWorkflows"
                              disabled={!diligenceType}
                              menuPortalTarget={document.body}
                              options={options.companyWorkflows}
                              isLoading={isValidatingCompanyWorkflows}
                              onChange={(val) => setCompanyWorkflow(val)}
                              placeholder={t(
                                "diligence:open_associate.modal.select_placeholder"
                              )}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {selectedPeople.length > 0 && (
                        <div
                          style={{
                            flex: 1,
                            gap: "8px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontSize: "1.4rem" }}>
                            {t(
                              "diligence:open_associate.modal.select_label_pf"
                            )}
                          </span>
                          <div style={{ maxWidth: "462px" }}>
                            <Select
                              icon={ChevronDown}
                              menuPosition="fixed"
                              name="peopleWorkflows"
                              disabled={!diligenceType}
                              menuPortalTarget={document.body}
                              options={options.personWorkflows}
                              isLoading={isValidatingPersonWorkflows}
                              onChange={(val) => setPeopleWorkflow(val)}
                              placeholder={t(
                                "diligence:open_associate.modal.select_placeholder"
                              )}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {selectedCompaniesEx.length > 0 && (
                    <>
                      <P1>
                        {t(
                          "diligence:open_associate.modal.companies_workflows"
                        )}
                      </P1>
                      <Select
                        menuPosition="fixed"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        name="companiesExWorkflows"
                        icon={ChevronDown}
                        options={options.companyExWorkflows}
                        disabled={getProcessType() === "company_ex"}
                        initialValue={
                          getProcessType() === "company_ex"
                            ? initialValueWorkflow()
                            : undefined
                        }
                        placeholder={t("search:components.workflows")}
                        isLoading={isValidatingCompanyExWorkflows}
                      />
                    </>
                  )}

                  {selectedPeopleEx.length > 0 && (
                    <>
                      <P1>
                        {t("diligence:open_associate.modal.people_workflows")}
                      </P1>
                      <Select
                        menuPosition="fixed"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        name="peopleExWorkflows"
                        icon={ChevronDown}
                        options={options.personExWorkflows}
                        isLoading={isValidatingPersonExWorkflows}
                        placeholder={t("search:components.workflows")}
                      />
                    </>
                  )}
                </div>
              </BatchOpening>
            )}
          </ModalView>
        </Modal>
      )}
    </>
  );
};

export default PartnersData;

import React, {useState} from 'react';
import { Button, H6 } from '~/components';
import { ButtonLeft } from './styles';
import { useTranslation } from 'react-i18next';
import SettingsSection from '~/views/Administration/Settings/SettingsSection';
import SiteSettings from '../Settings/SiteSettings';
import { Grid } from '~/views/Dashboard/styles';
import TableTerms from './TableTerms';

const Terms = () => {
  const { t } = useTranslation('admin');
  const [activeMenu, setActiveMenu] = useState(1);
  const { t : tSettings } = useTranslation('settings');
  return (
    <div>
      <div style={{display: 'flex'}}>
        <ButtonLeft>
          <Button
            size="sm"
            label={t("terms")}
            appearance={activeMenu === 1 ? "primary" : "tertiary" }
            onClick={() => setActiveMenu(1)}
          />
        </ButtonLeft>
        <div >
          <Button
            size="sm"
            label={t("site")}
            appearance={activeMenu === 0 ? "primary" : "tertiary" }
            onClick={() => setActiveMenu(0)}
          />
        </div>
      </div>
      {activeMenu === 1 ? 
        <Grid >
          <SettingsSection label={t('terms')}>
            <TableTerms />
          </SettingsSection>
        </Grid>
        : 
        <Grid >
          <SettingsSection label={tSettings('site.label')}>
            <div style={{marginBottom: "10px"}}>
              <H6>{tSettings('description')}</H6>
              <H6>{tSettings('second_description')}</H6>
            </div>
            <SiteSettings/>
          </SettingsSection>
        </Grid>
      }
    </div>
  );
}

export default Terms;

export const head = (t) => [
  {
    route: 'tags',
    label: t('head.tag'),
  },
  {
    route: 'workflows',
    label: t('head.workflows'),
  },
  {
    route: 'usuarios',
    label: t('head.users'),
  },
  {
    route: 'historico',
    label: t('head.historic'),
  },
  {
    route: 'cnaes',
    label: "CNAEs",
  },
  {
    route: 'terms',
    label: t('head.terms'),
  },
  {
    route: 'pricing',
    label: t('head.pricing'),
  },
  {
    route: 'risk-sources',
    label: t('head.risk-sources'),
  },
  {
    route: 'settings',
    label: t('head.settings'),
  },
];

export const action = (t) => ({
  tags: t('buttons.tags'),
  usuarios: t('buttons.users'),
  workflows: t('buttons.workflows'),
});

export const table = {
  tags: (t, onSorting) => [
    {
      size: 'xxs',
      label: t('table.tags.0'),
      isSort: true,
      onSorting,
    },
    {
      size: 'sm',
      label: t('table.tags.1'),
      isSort: true,
      onSorting,
    },
    {
      label: '',
      isMulti: true,
    },
  ],

  usuarios: (t, onSorting) => [
    {
      size: 'xxs',
      label: '',
    },
    {
      label: t('table.users.0'),
      isSort: true,
      onSorting,
    },
    {
      label: t('table.users.1'),
      isSort: true,
      onSorting,
    },
    {
      label: t('table.users.2'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('table.users.3'),
      isSort: true,
      onSorting,
    },
    {
      label: t('table.users.4'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: '',
      align: 'right',
      isMulti: true,
    },
  ],

  historico: (t, onSorting) => [
    {
      label: t('table.historic.0'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('table.historic.1'),
      isSort: true,
      onSorting,
    },
    {
      size: 'sm',
      label: t('table.historic.2'),
      isSort: true,
      onSorting,
    },
    {
      label: t('table.historic.3'),
      isSort: true,
      onSorting,
    },
  ],
};

export const fields = (t) => [
  {
    type: 'INPUT',
    name: 'name',
    label: t('users.fields.name.label'),
    placeholder: t('users.fields.name.placeholder'),
  },
  {
    type: 'INPUT',
    name: 'email',
    label: t('users.fields.email.label'),
    placeholder: t('users.fields.email.placeholder'),
  },
  {
    type: 'INPUT',
    name: 'phone',
    label: t('users.fields.phone.label'),
    mask: '(99) 99999-9999',
    hasPrefix: true,
    placeholder: t('users.fields.phone.placeholder'),
  },
  {
    type: 'SELECT',
    name: 'idcliente',
    label: t('users.fields.client.label'),
    placeholder: t('users.fields.client.placeholder'),
  },
  {
    type: 'SELECT',
    name: 'usertype',
    label: t('users.fields.type.label'),
    placeholder: t('users.fields.type.placeholder'),
  },
  {
    type: 'CHECKBOX',
    name: 'viewWorkflowCosts',
    label: t('users.fields.viewWorkflowCosts.label'),
  },
  {
    type: 'CHECKBOX',
    name: 'canOpenLots',
    label: t('users.fields.canOpenLots.label'),
  },
  {
    type: 'SELECT',
    name: 'preferred_language',
    label: t('users.fields.language.label'),
    placeholder: t('users.fields.language.placeholder'),
  },
  {
    type: 'UPLOAD',
    name: 'image',
    label: t('users.fields.image'),
  },
];

export const modal = (t) => ({
  usuarios: {
    head: t('users.create.title'),
    action: t('users.create.action'),
    description: t('users.create.description'),
  },

  'edit-usuario': {
    head: t('users.edit.title'),
    action: t('users.edit.action'),
    description: t('users.edit.description'),
  },
});

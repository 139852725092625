import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import { Loading, Container, ItemsList } from '~/components';
import { masked, useFetch, getArrayOfUniqueObjectsByKey } from '~/hooks';
import { maskMoney, parseISO } from '~/hooks/helpers';
import { ResultHead } from '~/layout';

import { Card } from '../utils';
import View from './styles';

const ID = () => {
  const [show, onShow] = useState(false);
  const { t } = useTranslation('entities');
  const { t: T } = useTranslation('simplified');

  const { id } = useParams();

  const { data } = useFetch({ url: `/simplesearch/${id}` });

  if (! data) {
    return <Loading />;
  }

  const { entity: entityData, sources: sourcesData } = data?.data;

  const sources = getArrayOfUniqueObjectsByKey(sourcesData, 'fonte_risco_id');

  const type = entityData.company ? 'company' : 'user';
  const entity = entityData.company ? entityData.company : entityData.person;

  const fields = {
    user: [
      {
        label: t('cadastral_data.fields.person.0'),
        value: entity?.name || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.person.1'),
        value: entity?.death_record === 0 ? t('cadastral_data.fields.no') : t('cadastral_data.fields.yes'),
      },
      {
        label: t('cadastral_data.fields.person.2'),
        value: entity?.document ? masked(entity?.document) : t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.person.3')} (${
          entity?.addresses.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.addresses}
            keys={[
              'place',
              'street',
              'number',
              'complement',
              'neighborhood',
              'city',
              'state',
              'country_name',
            ]}
            type="address"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.person.4'),
        value:
          entity?.birthday && entity.birthday !== '0000-00-00'
            ? format(parseISO(entity.birthday), 'dd/MM/yyyy')
            : t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.person.5')} (${
          entity?.phones?.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.phones}
            keys={['country_code', 'area_code', 'number']}
            type="phone"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.person.6'),
        value: entity?.age
          ? `${entity?.age} anos`
          : t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.person.7')} (${
          entity?.emails?.length || 0
        })`,
        value: <ItemsList items={entity?.emails} keys={['email']} />,
      },
      {
        label: t('cadastral_data.fields.person.8'),
        value: entity?.gender || t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.person.9')} (${
          entity?.family?.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.family}
            keys={['name', 'documento', 'relationship_type']}
            type="family"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.person.10'),
        value:
          entity?.nationality?.pais || t('cadastral_data.fields.not_found'),
      },
    ],

    company: [
      {
        label: t('cadastral_data.fields.company.0'),
        value: entity?.company_name || t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.company.1')} (${
          entity?.addresses.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.addresses}
            keys={[
              'place',
              'street',
              'number',
              'complement',
              'neighborhood',
              'city',
              'state',
              'country_name',
            ]}
            type="address"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.company.2'),
        value: entity?.trade_name || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.2'),
        value: entity?.contact || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.4'),
        value: entity?.document
          ? masked(entity.document)
          : t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.5'),
        value: entity?.email || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.6'),
        value: entity?.supplier_code || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.7'),
        value: entity?.website || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.8'),
        value: (() => {
          try {
            return entity?.founded_at
              ? format(entity.founded_at, 'dd/MM/yyyy')
              : t('cadastral_data.fields.not_found');
          } catch {
            return t('cadastral_data.fields.not_found');
          }
        })(),
      },
      {
        label: t('cadastral_data.fields.company.9'),
        value: entity?.main_cnae?.codigo
          ? `${entity?.main_cnae?.codigo} / ${entity?.main_cnae?.descricao}`
          : t('cadastral_data.fields.not_found'),
      },
      {
        label: `${t('cadastral_data.fields.company.10')} (${
          entity?.filiais?.length || 0
        })`,
        value: (
          <ItemsList
            items={entity?.filiais}
            keys={['company_name', 'document', 'state']}
            type="filiais"
          />
        ),
      },
      {
        label: t('cadastral_data.fields.company.11'),
        value:
          type === 'company' && entity?.share_capital
            ? maskMoney(entity.share_capital, 'BRL')
            : t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.12'),
        value: entity?.legal_nature || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.13'),
        value: (
          <ItemsList
            items={entity?.secondary_cnaes}
            keys={['codigo', 'descricao']}
            type="cnae"
          />
        ),
      },
    ],
  };

  return (
    <View>
      <Container>
        <ResultHead
          title={`${T('title')}: ${masked(
            entity?.document || '-',
          )}`}
        />

        <Card
          type={type}
          show={show}
          title={type === 'company' ? entity?.company_name : entity?.name}
          fields={fields[type]}
          onShow={onShow}
          sources={sources}
          document={entity?.document}
          processId={id}
          isSimplified
        />
      </Container>
    </View>
  );
};

export default ID;

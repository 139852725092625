/**
 * Media query's breakpoints
 */
const breakpoints = {
  xxl: '1536px',
  xl: '1366px',
  lg: '1280px',
  md: '1024px',
  sm: '768px',
  xs: '600px',
  xxs: '400px',
};

export default breakpoints;

import styled from 'styled-components';

import { Container, P1 } from '~/components';
import { colors } from '~/styles';

export const FABContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 2rem;
  right: 50%;
  transform: translateX(50%);
  padding: 0 2.8rem;
`;

export const Card = styled.div`
  display: flex !important;
  min-height: 0 !important;
  > div {
    > ${P1} {
      color: ${colors.gray._600};
      margin-bottom: 2rem;
    }
    width: 100%;
    padding: 2rem;
    .checkbox-select-all {
      margin-top: 2.4rem;
    }
  }
`;

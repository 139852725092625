/**
 * Typograph
 */
const typography = {
  family: "'Fira Sans', 'Ubuntu', sans-serif",
  title: "'Ubuntu', sans-serif",
  weight: {
    bold: 700,
    regular: 400,
    semiBold: 600,
  },

  size: {
    s1: '1.2rem',
    s2: '1.4rem',
    s3: '1.6rem',
    m1: '2.0rem',
    m2: '2.4rem',
    m3: '2.8rem',
    l1: '3.2rem',
    l2: '4.0rem',
    l3: '4.8rem',
  },
};

export default typography;

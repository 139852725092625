import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { format } from 'date-fns';
import { arrayOf, object, string } from 'prop-types';

import { parseISO } from '~/hooks/helpers';
import { colors, typograph } from '~/styles';

import View from './styles';

const { gray } = colors;
const { size, weight } = typograph;

const HorizontalBar = ({
  data,
  initialDate,
  finalDate,
}) => {
  const items = [];

  const yaxis = data?.reduce((acc, curr) => {
    acc[curr.status_name] = {
      name: curr.status_name,
      data: [],
      date: [],
      ...acc[curr.status_name],
    };

    acc[curr.status_name].data.push(Math.round(Number(curr.average_risk)));
    acc[curr.status_name].date.push(format(parseISO(curr.date), 'dd/MM/yyyy'));

    return acc;
  }, []);

  let categories = [];
  initialDate = initialDate ? format(parseISO(initialDate), 'dd/MM/yyyy') : null;
  finalDate = finalDate ? format(parseISO(finalDate), 'dd/MM/yyyy') : null;
  const formattedDate = data?.map((item) => format(parseISO(item.date), 'dd/MM/yyyy'));

  if (initialDate && finalDate) {
    categories = [...new Set([initialDate, ...formattedDate, finalDate])];
  } else if (initialDate) {
    categories = [...new Set([initialDate, ...formattedDate])];
  } else if (finalDate) {
    categories = [...new Set([...formattedDate, finalDate])];
  } else {
    categories = [...new Set(formattedDate)];
  }

  // eslint-disable-next-line
  for (const index in yaxis) {
    items.push({
      name: yaxis[index].name,
      data: yaxis[index].data,
      date: yaxis[index].date,
    });
  }

  items.forEach((item, index) => {
    const formattedData = [];
    item.date.forEach(
      (itemDate, idx) => {
        const categoryIndex = categories.findIndex((category) => category === itemDate);
        formattedData[categoryIndex] = item.data[idx];
      },
    );
    items[index] = { name: items[index].name, data: formattedData, date: categories };
    items[index].data = categories.map((category, idx) => {
      let i = idx;
      while (i > -1) {
        if (Number(items[index].data[i])) {
          return items[index].data[i];
        }
        i -= 1;
      }
      return items[index].data[idx];
    });
    items[index].data = items[index].data.map((dataItem) => (
      dataItem || 0
    ));
  });

  const options = () => ({
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false,
      },
      defaultLocale: 'en',
    },
    colors: ['#F6871F', '#00A28F', '#0076DE', '#D74B29'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 0,
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: (val) => `${val}%`,
        style: {
          colors: [gray._400],
          fontSize: size.s1,
          fontWeight: weight.bold,
        },
      },
    },
    xaxis: {
      type: 'category',
      categories,
      title: {
        offsetX: 10,
        offsetY: 10,
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
    },
  });

  return (
    <View>
      <ReactApexChart
        options={options()}
        series={items}
        type="line"
        height={450}
      />
    </View>
  );
};

HorizontalBar.propTypes = {
  data: arrayOf(object),
  initialDate: string,
  finalDate: string,
};

HorizontalBar.defaultProps = {
  data: [],
  initialDate: null,
  finalDate: null,
};

export default HorizontalBar;

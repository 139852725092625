import React from "react";

import { bool, oneOf, number, string, element, oneOfType } from "prop-types";

import { Spinner } from "~/components";

import { View } from "./styles";

const Button = ({
  size,
  label,
  submit,
  loading,
  iconLeft,
  disabled,
  appearance,
  children,
  ...rest
}) => (
  <View
    {...rest}
    type={submit ? "submit" : "button"}
    size={size}
    disabled={disabled}
    iconLeft={iconLeft}
    appearance={appearance}
    isLoading={loading}
  >
    {iconLeft && iconLeft}

    {loading ? <Spinner /> : label}
    {children}
  </View>
);

Button.propTypes = {
  size: oneOf(["xs", "sm", "md"]),
  label: oneOfType([string, number, element]).isRequired,
  submit: bool,
  loading: bool,
  iconLeft: oneOfType([bool, element]),
  disabled: bool,
  appearance: oneOf(["primary", "secondary", "tertiary"]),
  children: element,
};

Button.defaultProps = {
  size: "sm",
  submit: false,
  loading: false,
  iconLeft: false,
  disabled: false,
  appearance: "primary",
  children: null,
};

export default Button;

import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import {
  func,
  shape,
  number,
  string,
  oneOfType,
} from 'prop-types';

import {
  P2,
  P3,
  Button,
  SimpleInput,
  SimpleSelect,
} from '~/components';

import factory from './factory';
import { View, Actions } from './styles';

const Paginate = ({
  size,
  total,
  pages,
  limit,
  onSize,
  margin,
  perPage,
  onPaginate,
  currentPage,
  ...rest
}) => {
  const [left, onLeft] = useState(false);
  const [right, onRight] = useState(false);

  const { t } = useTranslation('components');

  if (pages <= 1) return null;

  const pagination = factory({
    pages,
    limit,
    margin,
    currentPage,
  });

  const go = (page) => {
    if (page === currentPage || page === '...') return;

    onPaginate(page);
  };

  const next = () => {
    if (currentPage === pages) return;

    onPaginate(currentPage + 1);
  };

  const previous = () => {
    if (currentPage === 1) return;

    onPaginate(currentPage - 1);
  };

  const elements = [];

  // eslint-disable-next-line
  for (const index in pagination) {
    const page = pagination[index];

    if (page === 'left') {
      elements.push(
        <Button
          key={page}
          size="xs"
          label={left ? <ChevronsLeft /> : '...'}
          onClick={() => go(currentPage - limit)}
          className="is-back"
          appearance="secondary"
          onMouseEnter={() => onLeft(true)}
          onMouseLeave={() => onLeft(false)}
        />,
      );

      continue; // eslint-disable-line
    }

    if (page === 'right') {
      elements.push(
        <Button
          key={page}
          size="xs"
          label={right ? <ChevronsRight /> : '...'}
          onClick={() => go(currentPage + limit)}
          className="is-go"
          appearance="secondary"
          onMouseEnter={() => onRight(true)}
          onMouseLeave={() => onRight(false)}
        />,
      );

      continue; // eslint-disable-line
    }

    elements.push(
      <Button
        key={`${index}${page}`}
        size="xs"
        label={page}
        actived={page === currentPage}
        onClick={() => go(page)}
        appearance={page === currentPage ? 'primary' : 'tertiary'}
      />,
    );
  }

  const sizes = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <View {...rest}>
      <div />

      <div>
        <P3 isBold>
          <Trans
            i18nKey="components:paginate.in"
            values={{ total, perPage }}
          />
        </P3>

        <Actions>
          <Button
            size="xs"
            label={<ChevronLeft />}
            onClick={() => previous()}
            disabled={currentPage === 1}
            className="is-previous"
            appearance="tertiary"
          />

          {elements}

          <Button
            size="xs"
            label={<ChevronRight />}
            onClick={() => next()}
            disabled={currentPage === pages}
            className="is-next"
            appearance="tertiary"
          />
        </Actions>
      </div>

      <div>
        <div>
          <P2>{t('paginate.go')}</P2>

          <SimpleInput
            size="xs"
            value={currentPage}
            onChange={({ target }) => onPaginate(Number(target.value))}
            appearance="secondary"
            placeholder="1"
          />
        </div>

        <div>
          <P2>{t('paginate.rows')}</P2>

          <SimpleSelect
            icon={ChevronDown}
            value={size}
            options={sizes}
            onChange={(value) => {
              onPaginate(1);
              onSize(value);
            }}
            appearance="secondary"
            placeholder="50"
            menuPlacement="top"
            classNamePrefix="is-small"
          />
        </div>
      </div>
    </View>
  );
};

Paginate.propTypes = {
  size: shape({ value: number, label: number }),
  pages: number,
  total: oneOfType([number, string]).isRequired,
  limit: number,
  margin: number,
  onSize: func,
  perPage: number,
  onPaginate: func,
  currentPage: number.isRequired,
};
Paginate.defaultProps = {
  size: { value: 50, label: 50 },
  pages: 0,
  limit: 3,
  margin: 1,
  onSize: () => {},
  perPage: 10,
  onPaginate: () => null,
};

export default Paginate;

import styled, { css } from "styled-components";

import { P2 } from "~/components/Title";
import { colors, typograph } from "~/styles";

const { gray, status: state, support } = colors;

const { size: font } = typograph;

const main = [
  "Gestor",
  "Answered",
  "Corrupção",
  "Ambiental",
  "Respondido",
  "Em análise",
  "ICTS Analysis",
  "Pending answer",
  "Em análise ICTS",
  "Pendente conexão",
  "En análisis ICTS",
  "Pendente resposta",
  "Pendiente respuesta",
  "Disponível para análise",
  "Pendente análise",
  "Análisis pendiente",
  "Pending analysis"
];

const error = [
  "Erro",
  "Error",
  "Canceled",
  "Reproved",
  "Reprovada",
  "Cancelada",
  "Cancelado",
  "Rechazado",
  "Suspenso",
  "Suspended",
  "Suspendido",
  "Dado bloqueado",
  "Data blocked",
  "Dato bloqueado",
];

const success = [
  "Imagem",
  "Fiscal",
  "Aprobado",
  "Approved",
  "Aprovada",
  "Executor",
  "Concluido",
  "Concluído",
  "Concluded",
  "Aprobado con reservas",
  "Aprovada com ressalvas",
  "Approved with reservations",
];

const secondary = [
  "Open",
  "Aberto",
  "Abierto",
  "Processing",
  "Procesando",
  "Processando",
  "Trabalhista",
  "Em andamento",
  "Visualizador",
  "Esperando revisión",
  "Aguardando análise",
  "Waiting for analysis",
  "Pesquisa concluída",
  "Búsqueda completada",
  "Search completed",
];

const terciary = ["Financeiro", "Internacionais"];

const automaticApproval = [
  "Aprovada automaticamente",
  "Aprobación automática",
  "Automatic approval",
];

const View = styled(P2)`
  width: fit-content;
  color: ${gray._500};
  margin: 0.4rem 0.2rem !important;
  padding: ${({ size }) => (size === "md" ? ".8rem 1.2rem" : ".4rem .6rem")};
  display: inline-block;
  font-size: ${({ size }) => (size === "md" ? font.s2 : font.s1)};
  border-radius: 0.4rem;
  background-color: ${gray._200};
  white-space: nowrap;

  ${({ status }) =>
    success.indexOf(status) !== -1 &&
    css`
      color: ${state.success} !important;
      background: rgba(0, 162, 143, 0.1);
    `}

  ${({ status }) =>
    automaticApproval.indexOf(status) !== -1 &&
    css`
      color: ${state.automatic_approval} !important;
      background: rgba(0, 162, 143, 0.1);
    `}

  ${({ status }) =>
    secondary.indexOf(status) !== -1 &&
    css`
      color: ${support._300} !important;
      background: rgba(58, 116, 228, 0.08);
    `}

  ${({ status }) =>
    main.indexOf(status) !== -1 &&
    css`
      color: ${state.warning_100} !important;
      background: rgba(246, 135, 31, 0.1);
    `}

  ${({ status }) =>
    terciary.indexOf(status) !== -1 &&
    css`
      color: ${support._100} !important;
      background: rgba(62, 120, 152, 0.1);
    `}

  ${({ status }) =>
    error.indexOf(status) !== -1 &&
    css`
      color: ${state.error};
      background: rgba(215, 75, 41, 0.15);
    `}
  
  ${({ isLink }) =>
    isLink &&
    css`
      cursor: pointer;
    `}
`;

export default View;

export const useTranslateText = ({ t, query }) => {
  let helmetText;
  let tooltipLabelText;

  switch (query) {
    case "batches":
      helmetText = t("helmet.batches");
      break;
    case "cadastral":
      helmetText = t("helmet.cadastral");
      break;
    default:
      helmetText = t("helmet.evaluation");
  }

  switch (query) {
    case "simplificada":
      tooltipLabelText = t("simplified.title.tooltip");
      break;
    case "cadastral":
      tooltipLabelText = t("cadastral.title.tooltip");
      break;
    case "batches":
      tooltipLabelText = t("batches.title.tooltip");
      break;
    default:
      tooltipLabelText = t("evaluation.title.tooltip");
  }

  return {
    helmetText,
    tooltipLabelText,
  };
};

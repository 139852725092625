import React, {
  createContext, useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useParams } from 'react-router';

import { element } from 'prop-types';

import { useFetch } from '~/hooks';
import useWebSocketRefresh from '~/hooks/useWebSocketRefresh';

const ResultContext = createContext(null);

export const ResultContextProvider = ({ children }) => {
  const { id: processID } = useParams();

  const mutations = [];

  useWebSocketRefresh({
    channel: 'App.Events.ProcessStatusUpdated',
    events: [`App\\Events\\ProcessStatusUpdated\\${processID}`],
    mutations,
  }, [processID]);

  const { data: headers, isValidating: isValidatingHeaders, mutate: mutateHeaders } = useFetch({ url: processID ? `/process/${processID}/headers` : null, mutations });
  const { data: seem, isValidating: isValidatingSeem, mutate: mutateSeem } = useFetch({ url: processID ? `/process/${processID}/seem` : null, mutations });
  const { data: corporateData, isValidating: isValidatingCorporateData } = useFetch({ url: processID ? `/process/${processID}/corporateData` : null, mutations });
  const { data: themes, isValidating: isValidatingThemes } = useFetch({ url: processID ? `/process/${processID}/themes` : null, mutations });
  const { data: generalRisks, isValidating: isValidatingGeneralRisks, mutate: mutateRisks } = useFetch({ url: processID ? `/process/${processID}/risks` : null, mutations });
  const { data: processesItemsRisk, isValidating: isValidatingProcessesItemsRisk } = useFetch({ url: processID ? `/process/${processID}/process` : null, mutations });
  const { data: mediasItemsRisk, isValidating: isValidatingMediasItemsRisk } = useFetch({ url: processID ? `/process/${processID}/media` : null, mutations });
  const { data: surveysItemsRisk, isValidating: isValidatingSurveysItemsRisk } = useFetch({ url: processID ? `/process/${processID}/surveys` : null, mutations });
  const { data: previousDiligences, isValidating: isValidatingPreviousDiligences } = useFetch({
    url: headers?.data?.cnpjrecebido && headers?.data?.person_type != 3 && headers?.data?.person_type != 4
      ? `/entities/${headers?.data.cnpjrecebido.replace(/\D/g, '')}/processes`
      : `/entities/${headers?.data.cnpj}/processes`,
    mutations,
  });

  const addMutation = (mutation) => {
    mutations.push(mutation);
  };

  const mutateEverything = () => {
    mutations.forEach((mutation) => { mutation(); });
  };

  const getProcessType = useCallback(() => {
    const typeArr = [
      null, /* 0 - não existe */
      'person', /* 1 - PESSOA_FISICA_NACIONAL */
      'company', /* 2 - PESSOA_JURIDICA_NACIONAL */
      'person_ex', /* 3 - PESSOA_FISICA_ESTRANGEIRA */
      'company_ex', /* 4 - PESSOA_JURIDICA_ESTRANGEIRA */
    ];
    return typeArr[headers?.data?.person_type];
  }, [headers]);

  /**
   * Array contendo todos os nomes da entidade buscada
   */
  const entityNames = useMemo(() => {
    const names = [];

    if (! headers) {
      return names;
    }

    const { entity } = headers;

    if (entity && entity.custom_data && entity.custom_data.length > 0) {
      entity.custom_data.forEach((customData) => {
        if (customData.type === 'RAZAO_SOCIAL') {
          names.push(customData.value);
        }
      });
    }

    const nameKeyByPersonType = [
      null, /* 0 - não existe */
      'name', /* 1 - PESSOA_FISICA_NACIONAL */
      'company_name', /* 2 - PESSOA_JURIDICA_NACIONAL */
      'name', /* 3 - PESSOA_FISICA_ESTRANGEIRA */
      'company_name', /* 4 - PESSOA_JURIDICA_ESTRANGEIRA */
    ];

    if (entity && entity[nameKeyByPersonType[headers.data.person_type]]) {
      names.unshift(entity[nameKeyByPersonType[headers.data.person_type]]);
    }

    return names;
  }, [headers]);

  const isLoading = (! headers && isValidatingHeaders)
    || (! seem && isValidatingSeem)
    || (! corporateData && isValidatingCorporateData)
    || (! themes && isValidatingThemes)
    || (! generalRisks && isValidatingGeneralRisks)
    || (! processesItemsRisk && isValidatingProcessesItemsRisk)
    || (! mediasItemsRisk && isValidatingMediasItemsRisk)
    || (! surveysItemsRisk && isValidatingSurveysItemsRisk)
    || (! previousDiligences && isValidatingPreviousDiligences);

  return (
    <ResultContext.Provider value={{
      headers,
      mutateHeaders,
      seem,
      mutateSeem,
      entityNames,
      corporateData,
      themes,
      generalRisks,
      mutateRisks,
      processesItemsRisk,
      mediasItemsRisk,
      surveysItemsRisk: surveysItemsRisk?.data,
      previousDiligences,
      isLoading,
      addMutation,
      mutateEverything,
      getProcessType,
    }}
    >
      { children }
    </ResultContext.Provider>
  );
};

ResultContextProvider.defaultProps = {
  children: null,
};

ResultContextProvider.propTypes = {
  children: element,
};

/**
 * @typedef {Object} ResultData
 * @property {boolean} isLoading
 * @property {any} headers - Cabeçalho da avaliação
 * @property {() => void} mutateHeaders - recarrega o endpoint headers
 * @property {any} seem - Parecer da avaliação
 * @property {() => void} mutateSeem - recarrega o endpoint parecer
 * @property {any} corporateData - Dados societários da avaliação
 * @property {any[]} themes - Temas da avaliação
 * @property {any} generalRisks - Riscos gerais da avaliação
 * @property {any} processesItemsRisk - Todos os questionários da avaliação
 * @property {any} mediasItemsRisk - Todas as mídias da avaliação
 * @property {any} surveysItemsRisk - Todos os questionários da avaliação
 * @property {any} previousDiligences
 * @property {string[]} entityNames
 * @property {(mutation) => void} addMutation
 * @property {() => void} mutateEverything
 * @property {() => string} getProcessType
 */

/**
 * Contexto que contém toda informação compartilhada entre os
 * componentes da tela de apresentação de resultado de avaliações
 *
 * @returns {ResultData}
 */
export const useResultData = () => useContext(ResultContext);

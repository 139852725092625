import { useEffect } from 'react';
import { useMe } from '~/services';
import { useEcho } from '~/services/echo';
import useFetch from './useFetch';

const useNotifications = () => {
  const { user } = useMe();
  const echo = useEcho();

  const { data: notifications, mutate } = useFetch({ url: '/user/notifications' });
  const revalidateNotifications = () => mutate(notifications, true);

  useEffect(() => {
    if (echo && user?.usuario?.id) {
      echo.private(`App.Models.DdUser.${user.usuario.id}`)
        .notification(() => { revalidateNotifications(); });
      echo.private(`update-notifications.${user.usuario.id}`)
        .listen('App\\Events\\UpdateNotifications', revalidateNotifications);
    }

    return () => {
      if (echo && user?.usuario?.id) {
        echo.private(`App.Models.DdUser.${user.usuario.id}`)
          .stopListening('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated');
        echo.private(`update-notifications.${user.usuario.id}`)
          .stopListening('App\\Events\\UpdateNotifications');
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [echo, user]);

  return {
    notifications: notifications?.data?.notifications || [],
    notificationCount: notifications?.data?.total_unread || 0,
    revalidateNotifications,
  };
};

export default useNotifications;

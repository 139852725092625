import React, {useState, useContext, createContext} from "react";

import {element} from 'prop-types';

const SelectRisksContext = createContext(null);

export const SelectRisksProvider = ({ children }) => {
  const [ selectedRisks, setSelectedRisks] = useState([]);

  const isProcess = (name) => (
    name && (
      name?.toLowerCase().includes("process")
      || name?.toLowerCase().includes("lawsuits")
      || name?.toLowerCase().includes("lawsuit")
      || name?.toLowerCase().includes("processo")
      || name?.toLowerCase().includes("processos")
      || name?.toLowerCase().includes("procesos")
      || name?.toLowerCase().includes("proceso")
    )
  );
  
  const isMedia = (name) => (
    name && (
      name?.toLowerCase().includes("mediare")
      || name?.toLowerCase().includes("mídias")
      || name?.toLowerCase().includes("mídia")
    )
  );

  return (
    <SelectRisksContext.Provider value={{
      selectedRisks, setSelectedRisks, isProcess, isMedia
    }}
    >
      {children}
    </SelectRisksContext.Provider>
  );
};

SelectRisksProvider.defaultProps = {
  children: null,
};

SelectRisksProvider.propTypes = {
  children: element,
};

export const useSelectRisks = () => useContext(SelectRisksContext);

import React from 'react';

import { P1 } from '../Title';


const CrawlerLatam = ({ data }) => {
  return (
  <>
    {data?.map((values, count) => (
      <>
      {Object.entries(values).map((subq, idnx) => (
        <P1 key={idnx} style={{ lineHeight: '1.6rem',padding: '1.6rem 1rem' }}>
          <strong>{subq[0]}</strong>
          :
          {' '}
          {subq[1]}
        </P1>
      ))}
      </>
    ))}
    
  </>)
}
;


export default CrawlerLatam;

import styled from 'styled-components';

import typography from '~/styles/typograph';

// eslint-disable-next-line import/prefer-default-export
export const ChangeLogContainer = styled.div`
  font-weight: ${typography.weight.regular};
  font-size: ${typography.size.s3};
  
  h1 {
    font-weight: ${typography.weight.regular};
    font-size: ${typography.size.l1};
  }
`;

import useSWR from 'swr';
import {useApi} from "~/hooks/useApi";

const useFetch = ({ url, initialData, swrOptions, customFetcher, mutations }) => {
  const api = useApi();
  const fetcher = customFetcher || (async (params) => {
    const response = await api().get(params);
    return response.data;
  });

  const { data, error, mutate, isValidating } = useSWR(url, fetcher, {
    initialData,
    revalidateOnFocus: false,
    errorRetryInterval: 10000,
    ...swrOptions,
  });

  if (mutations && Array.isArray(mutations))
    mutations.push(mutate);

  return {
    data, error, mutate, isValidating,
  };
};

export default useFetch;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Empty, H1, P2, ProgressCircle } from '~/components';

import { useResultData } from '../../resultContext';
import { CardView, CustomNotes, Risks, SectionView as View } from '../../styles';
import { reduce } from '~/hooks';
import { formatDate } from '~/components/PopupViewer/utils';

const risksComponent = (t, children) => (
  <View>
    <H1>{ t('risks.title') }</H1>
    <CardView>
      { children }
    </CardView>
  </View>
);

const DiligenceRisks = () => {
  const { t } = useTranslation('diligence');
  const { generalRisks } = useResultData();

  if (!generalRisks) {
    return risksComponent(t, <Empty hasButton={ false } />);
  }
  
  const noteMessages = generalRisks?.data?.custom_notes?.length > 0
   ? generalRisks.data.custom_notes
    : false;

  return risksComponent(t,
    <>
      <Risks>
        <ProgressCircle
          title={ t('risks.general') }
          percentage={ Math.round(Number(generalRisks?.data?.risk)) }
          impact_id={ generalRisks?.data?.probabilidade_id }
        />
        { generalRisks?.data?.probabilidade_id_custom &&
          <ProgressCircle
            title={ t('risks.custom') }
            percentage={ Math.round(Number(generalRisks?.data?.risk)) }
            impact_id={ generalRisks?.data?.probabilidade_id_custom }
          />
        }
  
        { generalRisks?.data?.workflows.map(({
            name, risk, probabilidade_id, monitoring_id,
          }) => (
            <ProgressCircle
              key={ name }
              title={ name }
              impact_id={ probabilidade_id }
              percentage={ Math.round(Number(risk)) }
              hasMonitoring={ monitoring_id }
            />
          ),
        )
        }
      </Risks>

      { noteMessages &&
        <CustomNotes>
          <P2>
            <span className="title">{ t('risks.note') }</span>:
          </P2>
  
          { noteMessages.map(
            ({ created_at, note }) => (
              <P2 style={ { marginLeft: '3rem' } }>
                { `${ formatDate(created_at) } - ${ reduce(note, 150) }` }
              </P2>
            ))
          }
        </CustomNotes>
      }
    </>);
};

export default DiligenceRisks;

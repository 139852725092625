import styled from 'styled-components';

import { H4, P1 } from '~/components/Title';
import { colors } from '~/styles';

const {
  gray,
} = colors;

export const View = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: ${({ onTop }) => (onTop ? 1000000 : 10)};
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60rem;
  ${H4} {
    margin: 0 auto 1.2rem auto;
  }

  ${P1},
  ${H4} {
    color: ${gray._500};
    text-align: center;
  }
`;

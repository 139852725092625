import React from 'react';

import {
  number, string, arrayOf, object,
} from 'prop-types';

import { Item, TR, View } from '~/components/Table/styles';

const Table = ({ columns, title, items }) => (
  <View>
    {title && (
      <thead>
        <TR>
          <Item as="th" colSpan={columns} isBold size="xxl" align="center" fontSize="m1">{title}</Item>
        </TR>
      </thead>
    )}
    <tbody>
      {
        items.map((rowColumns, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <TR key={idx}>
            {
              rowColumns.map(
                (column, columnIdx) => (
                  <Item
                    // eslint-disable-next-line react/no-array-index-key
                    key={columnIdx}
                    {...column}
                    height={4}
                  >
                    {column.text}
                  </Item>
                ),
              )
            }
          </TR>
        ))
      }
    </tbody>
  </View>
);

Table.defaultProps = {
  title: null,
};

Table.propTypes = {
  columns: number.isRequired,
  title: string,
  items: arrayOf(arrayOf(object)).isRequired,
};

export default Table;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleLoading } from '~/components';
import { formatDate, formatValue } from '~/components/PopupViewer/utils';
import { Table } from './table';

const ProcessoCompletoViewer = ({ data }) => {
  const { t, ready } = useTranslation('popups', { useSuspense: false });

  if (!ready) {
    return <SimpleLoading />;
  }

  const sumary = [
    data?.numeroProcessoUnico && [ { text: t('lawsuits.complete.numeroProcessoUnico') }, { text: data?.numeroProcessoUnico } ],
    data?.urlProcesso         && [ { text: t('lawsuits.complete.url') }, { link: data?.urlProcesso } ],
    data?.dataDistribuicao    && [ { text: t('lawsuits.complete.dataDistribuicao') }, { text: formatDate(data?.dataDistribuicao) } ],
    data?.tribunal            && [ { text: t('lawsuits.complete.tribunal') }, { text: data?.tribunal } ],
    data?.uf                  && [ { text: t('lawsuits.complete.uf') }, { text: data?.uf } ],
    data?.valorCausa          && [ { text: t('lawsuits.complete.valor') }, { text: formatValue(data?.valorCausa.valor, t) } ],
    data?.orgaoJulgador       && [ { text: t('lawsuits.complete.orgaoJulgador') }, { text: data?.orgaoJulgador } ],
    data?.area                && [ { text: t('lawsuits.complete.area') }, { text: data?.area } ],
    data?.grauProcesso        && [ { text: t('lawsuits.complete.grau')}, { text: data?.grauProcesso} ]
  ];

  const parts = data?.partes?.map(part => {
    let lawers;
    if (part.advogados) {
      lawers = part.advogados.map(lawer => `${ lawer.nome } (${ part.tipo })`).join(', ');
    } else {
      lawers = '-';
    }
    
    return [
      part.nome && [ { text: t('lawsuits.complete.partes.nome') }, { text: part.nome } ],
      lawers && [ { text: t('lawsuits.complete.partes.advogados') }, { text: lawers } ],
    ];
  });

  const updates = data?.movimentos && data?.movimentos?.map(movimento => {
    return [
      movimento.nomeOriginal ? { text: movimento.nomeOriginal.join(' / ') } : { text: '-' },
      movimento.descricao ? { text: movimento.descricao } : { text: '-' },
      movimento.data ? { text: formatDate(movimento.data) } : { text: '-' },
    ];
  });
  
  const processStatus = [
    data?.statusPredictus?.statusProcesso   && [ { text: t('lawsuits.complete.statusPredictus.statusProcesso') }, { text: data?.statusPredictus?.statusProcesso } ],
  ];
  
  const CNJs = data?.assuntosCNJ && data?.assuntosCNJ.map(cnj => {
    return [ { text: cnj.codigoCNJ }, { text: cnj.titulo } ];
  });
  
  return (
    <>
      { sumary && <Table title={ t('lawsuits.complete.titles.sumary') } columns={ 2 } items={ sumary } /> }
      { CNJs && <Table title={ t('lawsuits.complete.titles.CNJ') } columns={ 2 } items={ CNJs } /> }
      { parts && <Table title={ t('lawsuits.complete.titles.parts') } columns={ 2 } items={ parts } /> }
      { updates && <Table title={ t('lawsuits.complete.titles.updates') } columns={ 3 } items={ updates } /> }
      { processStatus && <Table title={ t('lawsuits.complete.titles.status') } columns={ 2 } items={ processStatus } /> }
    </>
  )

};

export default ProcessoCompletoViewer;

import * as Yup from "yup";

export const useValidator = ({ t }) => {
  const schema = Yup.object().shape({
    maxApproval: Yup.number()
      .typeError(t("process_automation.invalid_value"))
      .min(1, t("process_automation.min_value"))
      .max(100, t("process_automation.max_value"))
      .required(),
  });

  return {
    schema,
  };
};

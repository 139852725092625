import React from 'react';
import Charts from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { P3 } from '~/components/Title';
import { colors, typograph } from '~/styles';

import { View, Levels } from './styles';

const { gray } = colors;
const { size, family } = typograph;

const Chart = ({ data }) => {
  const { t } = useTranslation('components');
  const { t: T } = useTranslation('entities');
  const items = [];

  // eslint-disable-next-line
  for (const index in data?.data) {
    items.push({
      name: T('diligences.dataLabels.3'),
      data: {
        x: index,
        y: data.data[index][0].general || 0,
      },
    });
  }

  const yaxis = items?.reduce((acc, curr) => {
    acc[curr.name] = {
      name: curr.name,
      data: [],
      ...acc[curr.name],
    };

    acc[curr.name].data.push(Math.round(curr.data.y));

    return acc;
  }, []);

  const yseries = [];

  // eslint-disable-next-line
  for (const index in yaxis) {
    yseries.push({
      name: yaxis[index].name,
      data: yaxis[index].data,
    });
  }

  const options = () => ({
    chart: {
      height: 350,
      stacked: true,
      type: 'line',
      width: '100%',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: 'straight',
      dashArray: 0,
    },
    legend: {
      tooltipHoverFormatter (val, opts) {
        return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`;
      },
    },
    markers: {
      size: 5,
      stacked: true,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: items.map((item) => {
        try {
          return format(new Date(item.data.x), 'dd MMM');
        } catch {
          return item.data.x;
        }
      }),
      labels: {
        style: {
          colors: [gray._500],
          fontSize: size.s1,
          fontFamily: family,
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        style: {
          colors: [gray._500],
          fontSize: size.s1,
        },
      },
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  });

  return (
    <View>
      <Charts
        options={options()}
        series={yseries}
        height={500}
      />
      <Levels>
        <P3 as="span">{t('risks.high')}</P3>
        <P3 as="span">{t('risks.medium')}</P3>
        <P3 as="span">{t('risks.low')}</P3>
      </Levels>
    </View>
  );
};

export default Chart;

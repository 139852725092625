import styled from 'styled-components';

import { colors } from '~/styles';

const {
  chart,
} = colors;

const View = styled.div`
  * {
    overflow: visible !important;
  }

  .apexcharts {
    &-data-labels {
      transform: translateY(-1.2rem);
    }

    &-series path:nth-child(even) {
      fill: ${chart._200};
    }
  }
`;

export default View;

import styled from "styled-components";

import { Form } from "@unform/web";

export const MainForm = styled(Form)`
  display: flex;
  align-items: center;
  z-index: 90;
  gap: 2.5rem;

  & > div {
    min-width: 25rem;

    &:not(:first-of-type) {
      margin-left: 1.6rem;
    }
  }
`;

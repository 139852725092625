import React from 'react';
import {reduce} from "~/hooks";
import {isHTML} from "~/hooks/helpers";

const NoteRenderer = ({ opened, seem }) => {
  const noteContent = seem?.data?.note ?? '';

  const renderNote = (content, isFull) => {
    const processedContent = isHTML(content) ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content;

    return isFull ? processedContent : (
      <div dangerouslySetInnerHTML={{ __html: reduce(content, 248) }} />
    );
  };

  return opened ? renderNote(noteContent, true) : renderNote(noteContent, false);
};

export default NoteRenderer;

import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { AlertTriangle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { func, number, bool } from 'prop-types';

import { H6, Modal, SimpleLoading } from '~/components';
import { formatErrorMessage, useApi } from '~/hooks';

import { CancelModal } from './styles';

const Disabled = ({
  id,
  show,
  render,
  onShow,
}) => {
  const [loading, onLoading] = useState(false);

  const alert = useAlert();

  const api = useApi();
  const { t, ready } = useTranslation('monitoring', { useSuspense: false });
  const { t: tAuth} = useTranslation('auth');
  const submit = async () => {
    onLoading(true);

    await api().delete(`/monitoring/${id}`)
      .then(() => {
        render();
        onShow(false);
        alert.success(t('delete.response.success'));
      })
      .catch(({ response }) => alert.error(formatErrorMessage(response, t('delete.response.error'),tAuth('expired'))));

    onLoading(false);
  };

  if (! ready) {
    return <SimpleLoading />;
  }

  return (
    <Modal
      hasAction
      show={show}
      title={t('delete.title')}
      onShow={onShow}
      action={{
        label: t('delete.action'),
        onClick: () => submit(),
        loading,
      }}
    >
      <CancelModal>
        <span>
          <AlertTriangle />
        </span>

        <H6>{t('delete.description')}</H6>
      </CancelModal>
    </Modal>
  );
};

Disabled.propTypes = {
  id: number,
  show: bool,
  render: func,
  onShow: func,
};

Disabled.defaultProps = {
  id: 0,
  show: false,
  render: () => {},
  onShow: () => {},
};

export default Disabled;

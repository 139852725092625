export const table = {

  diligencias: (t, onSorting) => ([
    {
      size: 'xxs',
      label: '',
      align: 'center',
    },
    {
      label: t('evaluations.table.0'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('evaluations.table.1'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('evaluations.table.2'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('evaluations.table.3'),
      isSort: true,
      onSorting,
    },
    {
      label: t('evaluations.table.4'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('evaluations.table.5'),
      isSort: true,
      onSorting,
      align: 'left',
    },
    {
      size: 'xxs',
      label: t('evaluations.table.6'),
      isSort: true,
      onSorting,
      align: 'left',
    },

    {
      label: '',
      align: 'right',
    },
  ]),
};

import React, { useState, useMemo, useEffect } from 'react';
import { useAlert } from 'react-alert';
import {
  Copy,
  XCircle,
  MinusCircle,
  CheckCircle,
  MoreVertical,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  number, string, arrayOf, objectOf, object, bool, func,
} from 'prop-types';

import {
  Flag, P1, P2, P3, H5, Modal,
} from '~/components';
import { formatErrorMessage, reduce, useFetch, useApi } from '~/hooks';
import { useMe } from '~/services';

import { Container, CardContainer, ModalContainer } from './styles';

const Card = ({
  id,
  name,
  client,
  themes,
  hidden,
  version,
  user_name,
  createdAt,
  processes,
  description,
  personTypes,
  renderWorkflowsList,
}) => {
  const navigate = useNavigate();
  const { user } = useMe();
  const alert = useAlert();
  const api = useApi();
  const { t, i18n } = useTranslation('admin');
  const { t: tAuth } = useTranslation('auth');
  
  const { language } = i18n;
  const [loading, onLoading] = useState(false);
  const [showModal, onShowModal] = useState(false);
  const [newName, setNewName ] = useState('name'+language);
  
  const person_types = useFetch({ url: '/clients/getPersonTypes' })?.data;

  const filteredThemes = useMemo(() => {
    const filtered = themes?.reduce((acc, curr) => {
      acc[curr.theme.name] = {
        name: curr.theme.name,
        weight: curr.weight,
        person_types: [],
        ...(acc[curr.theme.name] || {}),
      };
      acc[curr.theme.name].person_types[curr.theme.person_type - 1] = {
        id: curr.theme.person_type,
        name: person_types?.find((person_type) => (
          person_type.id === curr.theme.person_type
        ))?.description,
        risk_sources: [...curr.selected_risk_sources].map((risk_source) => (
          { ...risk_source, theme_id: curr.theme.id, name: risk_source[newName]}
        )) || [],
      };
      return acc;
    }, {});
    const data = [];
    // eslint-disable-next-line
    for (const index in filtered) {
      data.push(filtered[index]);
    }
    return data;
  }, [themes, person_types, newName]);

  const totalWeight = useMemo(() => {
    const weights = filteredThemes.map((theme) => theme.weight || 1);
    return weights.length > 0 ? weights.reduce((acc, curr) => acc + curr) : 1;
  }, [filteredThemes]);

  const change = async (type) => {
    const types = {
      delete: {
        label: t('workflows.response.delete'),
        param: 'delete',
      },
      activate: {
        label: t('workflows.response.activate'),
        param: 'activate',
      },
      deactivate: {
        label: t('workflows.response.deactivate'),
        param: 'deactivate',
      },
    };

    onLoading(true);

    await api().put(`/workflows/${id}/changeactive`, { active: types[type].param }).then(() => {
      alert.success(`${t('workflows.response.success.one')} ${types[type].label} ${t('workflows.response.success.two')}`);

      renderWorkflowsList();
    }).catch(({ response }) => alert.error(formatErrorMessage(response, `${t('workflows.response.error.one')} ${types[type].label} ${t('workflows.response.error.two')}` ,tAuth('expired'))));

    onLoading(false);
  };
  useEffect(()=> {
    setNewName('name_'+language);
  }, [language])
  return (
    <Container>
      <CardContainer hidden={hidden} loading={loading} client={client}>
        <div>
          <H5>
            <span title={name}>{reduce(name, 20)}</span>
            {user?.isMaster() || user?.isGestor()
              ? (
                <button type="button">
                  <MoreVertical />
                  <div>
                    <P3 onClick={() => navigate(`/administracao/workflow/${id}`)}>
                      <Copy />
                      {t('workflows.duplicate')}
                    </P3>

                    {client !== 'ICTS' && (
                      <P3 onClick={() => change(hidden ? 'activate' : 'deactivate')}>
                        {hidden ? <CheckCircle /> : <MinusCircle />}
                        {hidden ? t('workflows.activate') : t('workflows.suspense')}
                      </P3>
                    )}

                    {client !== 'ICTS' && ! processes && (
                      <P3 onClick={() => change('delete')}>
                        <XCircle />
                        {t('workflows.delete')}
                      </P3>
                    )}
                  </div>
                </button>
              )
              : null}
          </H5>
          <span>
            <P2>
              { client === 'ICTS' ? `${t('workflows.version')} ` : `${t('workflows.createdin')} `}
              <b>{` ${client === 'ICTS' ? version : createdAt}`}</b>
            </P2>
            <P2>
              {t('workflows.type')}
              <b>{` ${personTypes}`}</b>
            </P2>
          </span>
          <P2>
            {t('workflows.created')}
            <b>{` ${client !== 'ICTS' ? ((user_name && user_name) || client) : client}`}</b>
          </P2>
          {client !== 'ICTS' && (
            <P2>
              {`${t('workflows.company')}: `}
              <b>{client}</b>
            </P2>
          )}
          {description && (
            <P2>
              {t('workflows.description')}
              <span title={description}>{` ${reduce(description, 148)}`}</span>
            </P2>
          )}
        </div>

        <div>
          <div>
            {filteredThemes.length > 0 && (
              <>
                <P3>{t('workflows.themes')}</P3>
                {filteredThemes.map((theme, index) => (index === 8 ? (
                  <Flag key={theme.name} status="..." size="sm" />
                ) : index < 8 && (
                  <Flag
                    key={theme.name}
                    status={theme.name}
                    suffix={`P${theme.weight || 1}`}
                    size="sm"
                  />
                )))}
              </>
            )}
          </div>
          <button type="button" onClick={() => (hidden ? null : onShowModal(! showModal))}>
            {t('workflows.view')}
          </button>
        </div>
      </CardContainer>

      <ModalContainer>
        <Modal
          id={`workflow-modal-${id}`}
          show={showModal}
          action={{
            label: 'Fechar',
            onClick: () => onShowModal(false),
          }}
          onShow={onShowModal}
          hasAction={false}
        >
          <H5>{name}</H5>
          <P2>
            { client === 'ICTS' ? `${t('workflows.version')} ` : `${t('workflows.createdin')} `}
            <b>{` ${client === 'ICTS' ? version : createdAt}`}</b>
          </P2>
          <P2>
            {t('workflows.type')}
            <b>{` ${personTypes}`}</b>
          </P2>
          <P2>
            {t('workflows.created')}
            <b>{` ${client !== 'ICTS' ? ((user_name && user_name) || client) : client}`}</b>
          </P2>
          {description && (
            <P2>
              {t('workflows.description')}
              <span>{` ${description}`}</span>
            </P2>
          )}
          {filteredThemes.length > 0 && (
            <>
              <hr />
              <section>
                <hr />
                <P2>
                  <span>{t('workflows.themes')}</span>
                  <span>{`${t('workflows.total')} ${totalWeight}`}</span>
                </P2>
                {filteredThemes.map((theme) => (
                  <div key={theme.name}>
                    <P1>
                      <span />
                      <span>
                        {`${theme.name} `}
                        -
                      </span>
                      {` ${t('workflows.size')} 0${theme.weight || 1} `}
                      {`(${(((theme.weight || 1) / totalWeight) * 100).toFixed(0)} ${t('workflows.percentage')})`}
                    </P1>

                    {theme.person_types.map((person_type) => person_type
                      .risk_sources.length > 0 && (
                        <div key={person_type.name}>
                          <P2>{person_type.name}</P2>
                          {person_type.risk_sources.map((risk_source) => (
                            <P2 key={risk_source.name}>
                              -
                              {' '}
                              {risk_source.name}
                            </P2>
                          ))}
                        </div>
                    ))}
                  </div>
                ))}
              </section>
            </>
          )}
        </Modal>
      </ModalContainer>
    </Container>
  );
};

Card.propTypes = {
  id: number.isRequired,
  client: string.isRequired,
  name: string.isRequired,
  version: number.isRequired,
  description: string,
  createdAt: string.isRequired,
  personTypes: string.isRequired,
  themes: objectOf(arrayOf(number)).isRequired,
  hidden: bool,
  renderWorkflowsList: func.isRequired,
  processes: bool,
  activateWorkflow: object.isRequired, // eslint-disable-line
  user_name: string.isRequired,
};

Card.defaultProps = {
  description: '',
  hidden: false,
  processes: true,
};

export default Card;

import styled from 'styled-components';
import colors from '~/styles/colors';

export const NodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193,193,193,0.29);
    margin-left: auto;
    place-items: center;
    border-radius: 32px;
    opacity: 1;
    min-height: 235px;
    justify-content: space-between;
    position: relative;


    &:hover{
        border: 2px solid ${colors.primary._000};
    }

    p{
        margin-left: 1rem;
        font-size: 1.4rem;
    }
`;

export const HeaderContainer = styled.div`
    place-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    span{
        border-radius: 50%;
        background-color: #F6871F;
        display: flex;
        place-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        margin-top: -52px;
        margin-bottom: 1rem;
    }

    h4{
        margin-left: 1rem;
        font-size: 2rem;
        font-weight: 700;
    }

    h5{
        margin-left: 1rem;
        font-size: 1.6rem;
        margin-top: 2px;
        font-weight: 400;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    
    h2{
        color: #4F4F4F;
        margin-bottom: 1rem;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;

    div{
        background-color: #e5e5e5;
        height: 45px;
        width: 45px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        place-items: center;

        &:hover{
            filter: brightness(0.85);
        }
    }

    button{
        display: flex;
        flex-direction: column;
        place-items: center;
        margin:0 1rem;

        p{
            color: #646363;
            font-size: 1rem;
            font-weight: 600;
            margin-left: 0;
            margin-top: 4px;
        }
    }
`;


import React from 'react';

import { Container, SimpleLoading } from '~/components';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'

import {
  DiligenceActionPanel,
  DiligenceResultHeader,
  DiligenceSeem,
  DiligenceRisks,
  DiligenceRegistrationData,
  DiligenceRiskByTheme,
  DiligenceItemRiskList,
  DiligenceActionAndObservation,
  DiligencePreviousDiligences,
} from './components';
import { useResultData } from './resultContext';
import { View } from './styles';
import ModalForeign from './components/ModalForeign';
import {SelectRisksProvider} from "~/views/Search/Response/Diligence2/services/selectRisksContext";

const Diligence = () => {
  const { isLoading } = useResultData();

  return (
    <View>
      <Container>
        { isLoading ? <SimpleLoading /> : (
          <>
            <SelectRisksProvider>
              <DiligenceResultHeader />
              <DiligenceActionPanel />
              <DiligenceSeem />
              <DiligenceRisks />
              <DiligenceRegistrationData />
              <DiligenceRiskByTheme />
              <DiligenceItemRiskList />
              <DiligenceActionAndObservation />
              <DiligencePreviousDiligences />
              <ModalForeign />
            </SelectRisksProvider>
          </>
        )}
      </Container>
    </View>
  );
};

export default Diligence;

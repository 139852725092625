import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { ChevronDown, Search } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form } from "@unform/web";
import { bool, number, string } from "prop-types";
import * as Yup from "yup";

import {
  Accordion,
  Button,
  Empty,
  Input,
  Modal,
  P2,
  Select,
  SimpleLoading,
  SimpleSelect,
  Upload,
} from "~/components";
import { ModalView } from "~/components/TableRow/styles";
import { formatErrorMessage, useDebounce, useFetch, useApi } from "~/hooks";
import { error, useMe } from "~/services";

import { useResultData } from "../../resultContext";
import { AccordionHeader } from "../../styles";
import AssociatedRiskSource from "./AssociatedRiskSource";
import RiskSource from "./RiskSource";
import Analisis from "./Analisis";

const ThemeRisk = ({
  themeID,
  themeName,
  risk,
  impactID,
  alerts,
  sources,
  status,
  hasProcesses,
  pendigAnalysis,
}) => {
  const { seem, mutateEverything } = useResultData();
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);
  const { t } = useTranslation("diligence");
  const { t: tAuth } = useTranslation("auth");
  const { t: tc } = useTranslation("components");
  const { id: processID } = useParams();
  const { user } = useMe();
  const alert = useAlert();
  const api = useApi();

  // Modal de incluir item
  const includeItemRef = useRef(null);
  const [showIncludeItem, setShowIncludeItem] = useState(false);
  const handleIncludeItem = useCallback(
    async (data) => {
      const { upload, ...formDataRest } = data;

      try {
        await Yup.object()
          .shape({
            impactLevel: Yup.string().required(
              t("include_item.alerts.impact_level")
            ),
            riskSourceName: Yup.string()
              .required(t("include_item.alerts.risk_source_name.0"))
              .min(6, t("include_item.alerts.risk_source_name.1")),
          })
          .validate(formDataRest, {
            abortEarly: false,
          });
      } catch (err) {
        error(err, includeItemRef);
        return;
      }

      setLoading(true);
      try {
        const res = await api().post(
          `/process/${processID}/theme/${themeID}`,
          formDataRest
        );
        if (upload) {
          const formData = new FormData();
          formData.append("attachment", upload);
          await api().post(`/process/${res.data.id}/attachment`, formData);
        }
        alert.success(t("include_item.alerts.success"));
        mutateEverything();
        setShowIncludeItem(false);
      } catch ({ response }) {
        alert.error(
          formatErrorMessage(
            response,
            t("include_item.alerts.error"),
            tAuth("expired")
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [t, includeItemRef, themeID, processID, mutateEverything, alert, tAuth]
  );

  // Modal adicionar avaliação associada
  const includeProcessRef = useRef(null);
  const [showIncludeProcess, setShowIncludeProcess] = useState(false);
  const [selectedDiligence, setSelectedDiligence] = useState(null);
  const [diligenceSearch, setDiligenceSearch] = useState(null);
  const debouncedDiligenceSearch = useDebounce(diligenceSearch);

  const { data: searchedDiligences } = useFetch({
    url: debouncedDiligenceSearch
      ? `/process?search=${debouncedDiligenceSearch}`
      : null,
  });
  const { data: riskSources, isValidating: isValidatingRiskSources } = useFetch(
    { url }
  );

  const handleIncludeProcess = useCallback(async () => {
    const { value: son_process_id } = selectedDiligence;
    if (son_process_id) {
      setLoading(true);
      try {
        await api().put("/process/attach", {
          son_process_id,
          father_process_id: processID,
        });
        mutateEverything();
        setShowIncludeProcess(false);
        alert.success(t("include_item.alerts.success"));
      } catch ({ response }) {
        alert.error(
          formatErrorMessage(response, t("include_item.alerts.error")),
          tAuth("expired")
        );
      } finally {
        setLoading(false);
      }
    }
  }, [selectedDiligence, alert, processID, t, mutateEverything, tAuth]);
  const onOpen = useCallback(
    (isOpened) => {
      if (isOpened && !url)
        setURL(`/process/${processID}/themes/${themeID}/risks`);
    },
    [url, processID, themeID]
  );

  let instanceCount = 0;

  useEffect(() => {
    instanceCount++;

    if (!showIncludeProcess) {
      setDiligenceSearch(null);
    }
  }, [showIncludeProcess]);

  return (
    <Accordion
      key={instanceCount.toString()}
      isOpenable={sources > 0}
      initialState={false}
      onChange={onOpen}
      columns={[
        { payload: themeName, type: "TEXT" },
        {
          payload: {
            percentage: Math.round(Number(risk)),
            impact_id: impactID,
          },
          type: "PROGRESS_BAR",
        },
        { payload: [alerts, sources, impactID], type: "ALERT" },
        { payload: { text: tc('pending_analysis_theme'), width: 350 }, type: pendigAnalysis? "INFO_ICON" :  'FLAG_PLACEHOLDER' },
        { payload: status, type: "FLAG" },
      ]}
    >
      {
        // eslint-disable-next-line no-nested-ternary
        !riskSources && isValidatingRiskSources ? (
          <SimpleLoading />
        ) : riskSources?.length > 0 ? (
          hasProcesses ? (
            <>
              <AccordionHeader>
                <P2 isBold>{t("include_item.sub_title")}</P2>
                {showIncludeProcess && (
                  <Modal
                    title={t("associate_process.modal.title")}
                    show
                    onShow={setShowIncludeProcess}
                    action={{
                      label: t("associate_process.modal.action"),
                      loading,
                      onClick: includeProcessRef?.current?.submitForm,
                    }}
                    loading={loading}
                  >
                    <ModalView className="modal-lg">
                      <Form
                        ref={includeProcessRef}
                        onSubmit={handleIncludeProcess}
                      >
                        <P2>{t("associate_process.modal.subtitle")}</P2>

                        <SimpleSelect
                          menuPosition="fixed"
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          icon={Search}
                          options={
                            searchedDiligences?.data?.map((item) => ({
                              value: item.id,
                              label: `${item.razaosocial} (${item.id})`,
                            })) ?? []
                          }
                          placeholder={t("associate_process.diligence")}
                          value={
                            selectedDiligence
                              ? {
                                  label: selectedDiligence?.label,
                                  value: selectedDiligence?.value,
                                }
                              : null
                          }
                          onChange={({ label, value }) => {
                            setSelectedDiligence({ label, value });
                          }}
                          onInputChange={(val) => {
                            setDiligenceSearch(val);
                          }}
                          clearField={{
                            label: t("associate_process.clear"),
                            onClick: () => setDiligenceSearch(null),
                          }}
                        />
                      </Form>
                    </ModalView>
                  </Modal>
                )}
              </AccordionHeader>
              <table>
                <tbody>
                  {riskSources.map((process) => {
                    const { associated_risk_source: riskSource } = process;
                    return (
                      <AssociatedRiskSource
                        key={process.id.toString()}
                        id={process.id}
                        razaoSocial={process.razaosocial}
                        cnpj={process.cnpj}
                        risk={process.risk}
                        status={process.new_status?.name}
                        active={riskSource.composicao_processo_fontes.active}
                        marked={
                          riskSource.composicao_processo_fontes.marked === 1
                        }
                        compositionID={riskSource.composicao_processo_fontes.id}
                        riskSourceID={riskSource.id}
                        reason={
                          riskSource.composicao_processo_fontes
                            .active_change_reason
                        }
                        themeID={themeID}
                      />
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <AccordionHeader>
                <P2 isBold>{t("include_item.sub_title")}</P2>
                <Button
                  size="sm"
                  submit
                  label={t("include_item.title")}
                  appearance="tertiary"
                  onClick={() => {
                    setShowIncludeItem(true);
                  }}
                  disabled={seem?.data?.id || user.isVisualizador()}
                />
                {showIncludeItem && (
                  <Modal
                    title={t("include_item.modal.title")}
                    show
                    onShow={setShowIncludeItem}
                    action={{
                      label: t("include_item.modal.action"),
                      loading,
                      onClick: () => includeItemRef?.current?.submitForm(),
                    }}
                    loading={loading}
                  >
                    <ModalView className="modal-lg">
                      <Form ref={includeItemRef} onSubmit={handleIncludeItem}>
                        <P2>{t("include_item.modal.text")}</P2>
                        <Select
                          icon={ChevronDown}
                          name="impactLevel"
                          label={t("include_item.modal.impact_level.label")}
                          options={[
                            {
                              value: 1,
                              label: t(
                                "include_item.modal.impact_level.options.0"
                              ),
                            },
                            {
                              value: 2,
                              label: t(
                                "include_item.modal.impact_level.options.1"
                              ),
                            },
                            {
                              value: 3,
                              label: t(
                                "include_item.modal.impact_level.options.2"
                              ),
                            },
                            {
                              value: 4,
                              label: t(
                                "include_item.modal.impact_level.options.3"
                              ),
                            },
                          ]}
                          placeholder={t(
                            "include_item.modal.impact_level.placeholder"
                          )}
                          appearance="secondary"
                        />
                        <Input
                          name="riskSourceName"
                          label={t("include_item.modal.risk_source_name.label")}
                          placeholder={t(
                            "include_item.modal.risk_source_name.placeholder"
                          )}
                          appearance="secondary"
                        />
                        <Upload
                          name="upload"
                          label={t("include_item.modal.upload")}
                          isDocument
                        />
                      </Form>
                    </ModalView>
                  </Modal>
                )}
              </AccordionHeader>
              {riskSources.map((riskSource) => (
                <RiskSource
                  key={`[${riskSource.id}] ${riskSource.name}`}
                  riskSourceID={riskSource.id}
                  name={riskSource.name}
                  description={riskSource.description}
                  active={riskSource.active === 1}
                  alerts={riskSource.alerts}
                  results={riskSource.results}
                  resultsIDs={
                    riskSource.results_id?.split(",").map(Number) ?? []
                  }
                  active_change_reason={riskSource.active_change_reason}
                  themeName={themeName}
                  themeID={themeID}
                  status={riskSource.status}
                  statusName={riskSource.status_name}
                  toDebug={riskSource}
                  pendingAnalysis={riskSource.pending_analysis}
                />
              ))}
              <Analisis themeID={themeID} />
            </>
          )
        ) : (
          <Empty hasButton={false} />
        )
      }
    </Accordion>
  );
};

ThemeRisk.propTypes = {
  themeID: number.isRequired,
  themeName: string.isRequired,
  risk: number.isRequired,
  impactID: number.isRequired,
  alerts: number.isRequired,
  sources: number.isRequired,
  status: string.isRequired,
  hasProcesses: bool,
  anchorId: string,
};

ThemeRisk.defaultProps = {
  hasProcesses: false,
};

export default ThemeRisk;

export const head = (t) => [
  {
    route: 'diligencias',
    label: t('head.evaluations'),
  },
  {
    route: 'entidades',
    label: t('head.entities'),
  },
];

export const table = {
  entidades: (t, onSorting) => ([
    {
      size: 'xxs',
      label: '',
    },
    {
      label: t('entities.table.0'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('entities.table.1'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('entities.table.2'),
    },
    {
      size: 'xxs',
      label: t('entities.table.3'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('entities.table.4'),
      isSort: true,
      onSorting,
      align: 'center',
    },
    {
      size: 'xxs',
      label: t('entities.table.5'),
      isSort: true,
      onSorting,
    },
    {
      label: t('entities.table.6'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: '',
      align: 'right',
    },
  ]),

  diligencias: (t, onSorting) => ([
    {
      size: 'xxs',
      label: '',
      align: 'center',
    },
    {
      size: 'xxs',
      label: '',
    },
    {
      label: t('evaluations.table.0'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xs',
      label: t('evaluations.table.1'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('evaluations.table.2'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('evaluations.table.3'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xs',
      label: t('evaluations.table.4'),
      isSort: true,
      onSorting,
    },
    {
      size: 'xxs',
      label: t('evaluations.table.5'),
      isSort: true,
      onSorting,
      align: 'left',
    },
    {
      size: 'xxs',
      label: t('evaluations.table.6'),
      isSort: true,
      onSorting,
      align: 'left',
    },
    {
      label: '',
      align: 'right',
    },
    {
      label: '',
      align: 'right',
    },
  ]),
  diligenciasAssociadas: (t) => ([
    {
      size: 'xxs',
      label: '',
      align: 'left',
    },
    {
      size: 'xs',
      label: t('evaluations.table.0'),
    },
    {
      size: 'xs',
      label: t('evaluations.table.1'),
      align: 'left',
    },
    {
      size: 'xxs',
      label: t('evaluations.table.2'),
    },
    {
      size: 'xxs',
      label: t('evaluations.table.3'),
    },
    {
      size: 'xxs',
      label: t('evaluations.table.5'),
      align: 'left',
    },
    {
      size: 'xxs',
      label: t('evaluations.table.6'),
      align: 'left',
    },
    {
      label: '',
    },
    {
      size: 'xxs',
      label: '',
      align: 'right',
    },
  ]),
};

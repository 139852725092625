import styled, { css } from 'styled-components';

import { colors, typograph } from '~/styles';

const { weight, size, family } = typograph;
const { gray, support, primary, secondary, cyan } = colors;

export const Container = styled.div`
  h5 {
    color: ${primary._000};
    font-weight: ${weight.semiBold};
    margin-bottom: .4rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    color: ${gray._500};
    margin-top: 1.2rem;

    span {
      font-weight: ${weight.semiBold};
    }
  }

  hr {
    border: 0;
    border-top: .1rem solid ${gray._200};
  }
`;

export const CardContainer = styled.div`
  cursor: ${({ loading }) => (loading ? 'wait !important' : 'auto')};
  ${({ hidden }) => hidden
    && css`
      > div > *:not(h5) {
        opacity: 0.5;
      }
      h5 > *:not(button){
        opacity: 0.5;
      }
    `};

  background-color: #fff;
  border-radius: .4rem;
  padding: 2.6rem 3.0rem;
  min-height: 45.0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: .0rem .5rem 1.5rem rgba(132, 137, 155, 0.15);

  h5 > button {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > svg:first-of-type {
      border-radius: 2.0rem;
      padding: .5rem;
      transform: scale(1.5);
      background: transparent;
      cursor: pointer;
    }

    div {
      position: absolute;
      display: none;
      opacity: 0;
      flex-direction: column;
      justify-content: space-between;
      top: 3.5rem;
      right: 0;
      transform: translateX(40%);
      width: 15.0rem;
      padding: 1.5rem 2.0rem;
      border-radius: .4rem;
      background: #fff;
      box-shadow: .0rem .5rem 1.5rem rgba(132, 137, 155, 0.15);
      cursor: ${({ loading }) => (loading ? 'wait !important' : 'auto')};

      p {
        display: flex;
        align-items: center;
        cursor: ${({ loading }) => (loading ? 'wait !important' : 'pointer')};
        margin: 0;
        padding: 1.0rem 0;

        svg {
          margin-right: .2rem;
          padding: .3rem;
        }
      }

      span.space {
        display: block;
        width: 100%;
        height: 1.5rem;
      }
    }

    :focus-within {
      > svg:first-of-type {
        background: ${gray._100};
      }

      div {
        display: flex;
        opacity: 1;
      }
    }
  }

  > div > span {
    display: flex;
    justify-content: space-between;
  }

  b {
    font-weight: ${weight.bold};
  }

  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18.0rem;
    border-top: .1rem solid ${gray._200};

    > div > p:first-of-type {
      padding: .8rem 0 1.2rem;
      font-weight: ${weight.semiBold};
      color: ${gray._600};
    }

    button {
      font-family: ${family};
      font-size: ${size.s2};
      line-height: ${size.s3};
      text-decoration-line: underline;
      color: ${cyan._500};
      width: 100%;
    }
  }
`;

export const ModalContainer = styled.div`
  section {
    > p:first-of-type {
      color: black;
      display: flex;
      justify-content: space-between;
      padding-right: 1.0rem;
    }

    > div {
      padding: 1.2rem 0;

      > p {
        display: flex;
        align-items: center;

        span:first-of-type {
          display: inline-block;
          width: .8rem;
          height: .8rem;
          border-radius: .4rem;
          background-color: ${primary._000};
          margin: 0 .8rem;
        }

        span:last-of-type {
          color: black;
          margin-right: .4rem;
        }
      }

      > div {
        margin-left: 3.6rem;

        p:first-of-type {
          font-weight: ${weight.semiBold};
          color: ${gray._500};
          margin-top: 2.4rem;
          margin-left: .0rem;
          list-style-type: none;
        }
        p {
          font-weight: ${weight.normal};
          margin-top: 1.2rem;
          margin-left: 1.5rem;
        }
      }
    }
  }
`;

import React from 'react';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import ptBr from 'date-fns/locale/pt';
import {
  bool,
  func,
  string,
  oneOfType,
  instanceOf,
} from 'prop-types';

import { P3 } from '~/components';

import Header from './Header';
import { View } from './styles';

registerLocale('pt', ptBr);
setDefaultLocale('pt', ptBr);

const RangeDatePicker = ({
  date,
  onDate,
  endDate,
  onEndDate,
  placeholderEnd,
  placeholderStart,
  ...rest
}) => {
  const { t } = useTranslation('components');

  return (
    <View
      reveal
      isRange
      hasLabel={false}
    >
      <ReactDatePicker
        selected={date}
        onChange={onDate}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholderStart || t('datepicker.start')}
        renderCustomHeader={(props) => <Header {...props} />}
        shouldCloseOnSelect={false}
        {...rest}
      />

      <P3>{t('datepicker.to')}</P3>

      <ReactDatePicker
        selected={endDate}
        onChange={onEndDate}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholderEnd || t('datepicker.end')}
        renderCustomHeader={(props) => <Header {...props} />}
        shouldCloseOnSelect={false}
        {...rest}
      />
    </View>
  );
};

RangeDatePicker.propTypes = {
  date: oneOfType([string, instanceOf(Date)]),
  onDate: func.isRequired,
  endDate: oneOfType([string, instanceOf(Date)]),
  onEndDate: func.isRequired,
  placeholderEnd: oneOfType([bool, string]),
  placeholderStart: oneOfType([bool, string]),
};

RangeDatePicker.defaultProps = {
  date: '',
  endDate: '',
  placeholderEnd: false,
  placeholderStart: false,
};

export default RangeDatePicker;

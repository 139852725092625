import React, { useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { Button, SimpleInput } from '~/components';
import ClientSelect from '~/components/ClientSelect';
import { useApi, useFetch } from '~/hooks';
import { maskMoney } from '~/hooks/helpers';
import { useMe } from '~/services';

import { InfoView, InfoView2, UsageSettingsView } from './style';

const UsageSettings = () => {
  const { t } = useTranslation('settings');

  const { user } = useMe();
  const alert = useAlert();
  const api = useApi();

  const [selectedClient, setSelectedClient] = useState(null);
  const [value, setValue] = useState('R$ 0,00');
  const [isSubmitting, setSubmitting] = useState(false);
  const [currentQuota, setcurrentQuota] = useState(0);
  const [currentSetting, setCurrentSetting] = useState({
    groupValue: 0,
    value: 0,
  });

  const { data, isValidating, mutate } = useFetch({ url: selectedClient ? `/settings?type=LIMIT_MONTHLY_COST${selectedClient.value !== 0 ? `&client_id=${selectedClient.value}` : ''}` : null });

  const onlyOneClient = useMemo(() => user?.hierarquia?.length === 1, [user]);

  useEffect(() => {
    if (onlyOneClient) {
      setSelectedClient({
        value: user?.hierarquia[0].id,
        lable: user?.hierarquia[0].clients,
        maxDiligencesQuota: user?.hierarquia[0].maxDiligencesQuota,
      });
    }
  }, [onlyOneClient, user]);

  useEffect(() => {
    const currentValue = data?.data;
    if (currentValue) {
      const settingData = JSON.parse(currentValue[0]?.data || '{"groupValue": 0, "value": 0}');
      setCurrentSetting(settingData);
    }
  }, [data, selectedClient]);

  useEffect(() => {
    const key = ! selectedClient || selectedClient.value === 0 ? 'groupValue' : 'value';
    setValue(maskMoney(currentSetting[key]));
    setcurrentQuota(selectedClient?.maxDiligencesQuota);
  }, [selectedClient, currentSetting]);

  const onInput = (evt) => {
    let cleanValue = evt.target.value.replace(/\D/g, '').replace(/^0+/, '');
    while (cleanValue.length < 3) {
      cleanValue = `0${cleanValue}`;
    }

    let cuttingIndex = Math.max(cleanValue.length - 2, 0);
    let formattedValue = `,${cleanValue.substr(cuttingIndex)}`;
    cleanValue = cleanValue.substr(0, cuttingIndex);

    while (cleanValue.length > 0) {
      cuttingIndex = Math.max(cleanValue.length - 3, 0);
      formattedValue = `.${cleanValue.substr(cuttingIndex)}${formattedValue}`;
      cleanValue = cleanValue.substr(0, cuttingIndex);
    }
    setValue(`R$ ${formattedValue.substr(1)}`);
  };

  const onInputQuota = (e) => {
    setcurrentQuota(e.target.value)
  }
  const onSubmit = async () => {
    setSubmitting(true);
    const numberValue = (parseFloat(value.replace(/\D/g, '').replace(/^0+/, '')) || 0) / 100.0;
    const settingData = { ...currentSetting };
    const key = ! selectedClient || selectedClient.value === 0 ? 'groupValue' : 'value';
    settingData[key] = numberValue;

    const requestBody = {
      type: 'LIMIT_MONTHLY_COST',
      maxDiligencesQuota: currentQuota,
      data: JSON.stringify(settingData),
    };

    if (selectedClient.value !== 0) {
      requestBody.client_id = selectedClient.value;
    }

    try {
      await api().post('/settings', requestBody).then((resp) => {
        alert.success(t('usage.saved-successfully'));
        setSubmitting(false);
      });
    } catch (ex) {
      alert.error(ex.message);
    }
    
  };

  return (
    <UsageSettingsView onlyOneClient={onlyOneClient}>
      <ClientSelect
        user={user}
        placeholder={t('usage.client-select-placeholder')}
        onChange={setSelectedClient}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999999 }) }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
      {selectedClient && (
        <>
          <SimpleInput
            label={t('usage.max-usage-label')}
            value={value}
            onInput={onInput}
            disabled={isValidating || isSubmitting}
          />
          <SimpleInput
            label={t('usage.max-quota-label') }
            value={currentQuota}
            onInput={onInputQuota}
            disabled={isValidating || isSubmitting}
          />
          <Button
            loading={isValidating || isSubmitting}
            label={t('usage.save-btn-label')}
            onClick={onSubmit}
          />
          <InfoView>{t('usage.info-content')}</InfoView>
          <InfoView2>{t('usage.info-quota-content')}</InfoView2>
        </>
      )}
    </UsageSettingsView>
  );
};

export default UsageSettings;

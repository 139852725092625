import React, {
  createContext, useContext, useState,
} from 'react';

import { element } from 'prop-types';

const SelectedRiskSourceContext = createContext(null);

export const SelectedRiskSourceProvider = ({ children }) => {
  const [ selectedRiskSources, setSelectedRiskSources ] = useState([]);
  const [ selectedPersonTypes, setSelectedPersonTypes ] = useState([]);

  const addNewSelectedRiskSource = (themeID, sourceID, price, person_type, theme_name, cost_group) => {
    const newObj = {
      id: sourceID, theme_id: themeID, price, person_type, theme_name, cost_group
    };
    setSelectedRiskSources((old) => [...old, newObj]);
  };

  const removeSelectedRiskSource = (themeID, sourceID) => {
    const filtered = selectedRiskSources.filter(({ id, theme_id }) => {
      return !( themeID === theme_id && ( id === sourceID || sourceID === undefined ) );
    });
    setSelectedRiskSources([...filtered]);
  };

  return (
    <SelectedRiskSourceContext.Provider value={{
      selectedRiskSources,
      addNewSelectedRiskSource,
      removeSelectedRiskSource,
      setSelectedRiskSources,
      selectedPersonTypes,
      setSelectedPersonTypes
    }}
    >
      {children}
    </SelectedRiskSourceContext.Provider>
  );
};

SelectedRiskSourceProvider.defaultProps = {
  children: null,
};

SelectedRiskSourceProvider.propTypes = {
  children: element,
};

export const useSelectedRiskSource = () => useContext(SelectedRiskSourceContext);

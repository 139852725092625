import React, { useCallback, useState } from 'react';
import { Edit, CheckCircle } from 'react-feather';

import {
  string, node, bool, func,
} from 'prop-types';

import { P2, SimpleInput } from '~/components';

const TextRegistrationDataEntry = ({
  label, value: receivedValue, editable, onSave,
}) => {
  const [value, setValue] = useState('');
  const [submittedValue, setSubmittedValue] = useState('');
  const [showEditField, setShowEditField] = useState(false);

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault();

    const success = await onSave(value);
    if (success) {
      setSubmittedValue(value);
      setShowEditField(false);
    }
  }, [value, onSave]);

  return (
    <P2 as="li">
      {`${label}: `}
      <span>{submittedValue || receivedValue}</span>
      {!! editable && <Edit className="edit-mark" onClick={() => setShowEditField(! showEditField)} /> }
      {showEditField && (
        <form className="edit-form" onSubmit={handleSubmit}>
          <SimpleInput appearance="secondary" value={value} onInput={(evt) => setValue(evt.target.value)} />
          <button type="submit">
            <CheckCircle />
          </button>
        </form>
      )}
    </P2>
  );
};

TextRegistrationDataEntry.defaultProps = {
  editable: false,
  onSave: undefined,
};

TextRegistrationDataEntry.propTypes = {
  label: string.isRequired,
  value: node.isRequired,
  editable: bool,
  onSave: func,
};

export default TextRegistrationDataEntry;

import React from 'react';

import { string, node } from 'prop-types';

import { P2 } from '~/components';

import ItemList from './ItemList';

const ListRegistrationDataEntry = ({ label, value }) => (
  <P2 as="li">
    {`${label} (${value.length}): `}
    <ItemList data={value} />
  </P2>
);

ListRegistrationDataEntry.propTypes = {
  label: string.isRequired,
  value: node.isRequired,
};

export default ListRegistrationDataEntry;

import React, { useRef, useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import * as Yup from 'yup';

import {
  Modal,
  Select,
  Tooltip,
  Checkbox,
  DatePicker,
  SimpleLoading,
} from '~/components';
import { formatErrorMessage, useApi, useFetch } from '~/hooks';
import { parseISO } from '~/hooks/helpers';
import { error, useMe } from '~/services';

import DeleteMonitoring from './Disable';
import { View, CheckboxLabel, CheckboxInput, CheckboxRisk } from './styles';

const Monitoring = ({
  type,
  show,
  render,
  onShow,
  hasDelete,
  processId,
}) => {
  const [value, onValue] = useState('60%');
  const [checked, onChecked] = useState(false);
  const [loading, onLoading] = useState(false);
  const [deleted, onDeleted] = useState(false);

  const { user } = useMe();

  const ref = useRef(null);

  const { t, ready } = useTranslation('monitoring', { useSuspense: false });
  const { t:tAuth } = useTranslation("auth");
  const { data: frequency } = useFetch({ url: '/monitoring/availableFrequencies' });
  
  const api = useApi()
  const alert = useAlert();

  /**
   * Initial data.
   */
  useEffect(() => {
    if (show && type === 'edit') {
      const replace = async () => {
        await api().get(`/monitoring/${show.id}`)
          .then(({ data }) => {
            const freq = frequency?.data?.filter((item) => item.id === data?.data.frequency_id);
            ref.current.setData({
              frequency: {
                value: freq[0]?.id,
                label: freq[0]?.name,
              },
              start_date: parseISO(data?.data?.start_date),
            });
            onValue(data?.data?.risk || '50%');
            onChecked(data?.data?.automatic_approval === 1);
          }).catch(({ response }) => alert.error(formatErrorMessage(response, t('delete.init'),tAuth('expired'))));
      };
      replace();
    }
  }, [t, show, type, frequency, alert, tAuth]);

  const submit = (val, typed) => {
    const reset = () => {
      onShow(false);
      onValue('60%');
      onChecked(false);
      if (ref.current) {
        ref.current.reset();
      }
    };

    const create = async () => {
      Yup.object()
        .shape({
          frequency: Yup.string().required(t('fields.frequency.required')),
          start_date: Yup.string().required(t('fields.start_date.required')),
        })
        .validate(val, {
          abortEarly: false,
        })
        .then(async () => {
          onLoading(true);

          await api().post('/monitoring', {
            risk: value,
            frequency: val.frequency,
            start_date: format(val.start_date, 'yyyy-MM-dd'),
            process_id: processId,
            automatic_approval: checked,
          }).then(() => {
            reset();
            render();
            alert.success(t('create.response.success'));
          }).catch(({ response }) => alert.error(formatErrorMessage(response, t('create.response.error'),tAuth('expired'))));

          onLoading(false);
        })
        .catch((err) => error(err, ref));
    };

    const edit = async () => {
      onLoading(true);

      await api().patch(`/monitoring/${show.id}`, {
        risk: value,
        frequency: val.frequency,
        start_date: format(val.start_date, 'yyyy-MM-dd'),
        automatic_approval: checked,
      })
        .then(() => {
          reset();
          render();
          alert.success(t('edit.response.success'));
        })
        .catch(({ response }) => alert.error(formatErrorMessage(response, t('edit.response.error'),tAuth('expired'))));

      onLoading(false);
    };

    switch (typed) {
      case 'create':
        create();
        break;

      case 'edit':
        edit();
        break;

      default:
        break;
    }
  };

  const Element = () => (
    <CheckboxLabel>
      <p>{t('fields.checkbox.one')}</p>
      <CheckboxRisk>
        <p>
          {value}
        </p>
      </CheckboxRisk>
    </CheckboxLabel>
  );

  if (!ready) {
    return <SimpleLoading />;
  }

  return (
    <Modal
      hasAction
      show={show && !user?.isVisualizador()}
      title={type === 'edit' ? t('edit.title') : t('create.title')}
      onShow={onShow}
      action={{
        label: type === 'edit' ? t('edit.action') : t('create.title'),
        onClick: () => ref.current.submitForm(),
        loading,
      }}
      subAction={hasDelete && {
        label: t('delete.sub_action'),
        onClick: () => onDeleted(!deleted),
      }}
      hasOverflow={false}
    >
      <View
        ref={ref}
        onSubmit={(val) => submit(val, type)}
      >
        <Select
          icon={ChevronDown}
          name="frequency"
          label={t('fields.frequency.label')}
          options={frequency?.data?.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          appearance="secondary"
          placeholder={t('fields.frequency.placeholder')}
          menuPosition="fixed"
        />

        <DatePicker
          name="start_date"
          label={t('fields.start_date.label')}
          minDate={new Date()}
          maxDate={new Date((new Date()).getFullYear() + 5, (new Date()).getMonth(), (new Date()).getDay())}
          appearance="secondary"
          placeholder={t('fields.start_date.placeholder')}
        />

       <div style={{display:'flex'}}> <Checkbox
          size="sm"
          name="automatic_approval"
          label={<Element />}
          onChange={({ target }) => onChecked(target.checked)}
        />

        <CheckboxInput>
          <Tooltip
            label={t('fields.checkbox.tooltip')}
            width={400}
          >
            <input
              value={value}
              onChange={({ target }) => onValue(target.value)}
            />
          </Tooltip>
        </CheckboxInput>
        </div>
      </View>
      

      {hasDelete && (
        <DeleteMonitoring
          id={show?.id}
          show={deleted}
          render={render}
          onShow={() => {
            onShow(!show);
            onDeleted(!deleted);
          }}
        />
      )}
    </Modal>
  );
};

Monitoring.defaultProps = {
  type: 'create',
  show: false,
  render: () => { },
  onShow: () => { },
  hasDelete: false,
  processId: 0,
};

export default Monitoring;

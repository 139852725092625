import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  arrayOf, bool, number, oneOf, oneOfType, string,
} from 'prop-types';

import { ReactComponent as IconMonitoring } from '~/assets/icons/icon-workflows.svg';
import {
  P2, P3, H6, Tooltip,
} from '~/components';
import { reduce } from '~/hooks';

import Container from './styles';

const ProgressCircle = ({
  percentage,
  impact_id,
  isTitleTop,
  title,
  subTitle,
  isSmall,
  hasMonitoring,
  defaultRiskLevels,
  ...rest
}) => {
  const { t } = useTranslation('components');

  const impactId = useMemo(() => {
    let response = Number(impact_id);
    if (response === 0) {
      response = defaultRiskLevels.findIndex((value, index) => {
        if (defaultRiskLevels[index + 1]) {
          return percentage >= value && percentage < defaultRiskLevels[index + 1];
        }
        return true;
      }) + 1;
    }
    return response;
  }, [percentage, impact_id, defaultRiskLevels]);

  const riskLevel = useMemo(() => {
    switch (impactId) {
      case 1:
        return t('risks.low');
      case 2:
        return t('risks.medium');
      case 3:
        return t('risks.high');
      case 4:
        return t('risks.critical');
      default:
    }
  }, [t, impactId]);

  return (
    <Container impact_id={impactId} percentage={percentage} isSmall={isSmall} {...rest}>
      {isTitleTop && (
        <P2 title={title}>
          {title}
        </P2>
      )}
      <div>
        <svg>
          <circle cx="45" cy="45" r="45" />
          <circle cx="45" cy="45" r="45" />
        </svg>
        <H6>
          {percentage}
          %
        </H6>
      </div>

      <div>
        <P2>{riskLevel}</P2>
        {! isTitleTop && (
          <>
            <P2 title={title}>
              {reduce(title, 12)}

              {hasMonitoring && (
                <Tooltip label={t('workflow')} width={224}>
                  <IconMonitoring />
                </Tooltip>
              )}
            </P2>
            {subTitle && (
              <P3>
                (
                {subTitle}
                )
              </P3>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

ProgressCircle.propTypes = {
  title: string.isRequired,
  subTitle: string,
  percentage: number.isRequired,
  impact_id: number,
  isTitleTop: bool,
  isSmall: bool,
  hasMonitoring: oneOfType([bool, number]),
  defaultRiskLevels: arrayOf(number),
};

ProgressCircle.defaultProps = {
  subTitle: undefined,
  isTitleTop: false,
  isSmall: false,
  impact_id: 0,
  hasMonitoring: false,
  defaultRiskLevels: [0, 25, 40, 70],
};

export default ProgressCircle;

import React, { Fragment, useMemo, useState } from "react";
import { X } from "react-feather";
import { isEmpty } from "lodash";

import { Head } from "~/components/Modal/styles";
import { Button, Modal } from "~/components";

import { useSearch } from "../../context/SearchProvider";

import {
  PrepaidAssessment,
  AutoAssessPartners,
  AssessWithoutLimitation,
} from "./components";

import { Flex, HeaderWrapper, ModalView, SubTitle } from "./styles";
import { useAlert } from "react-alert";

const Header = ({
  t,
  setChecked,
  tComponents,
  handleClick,
  onEntityWorkflow,
  onPersonWorkflow,
  setForceNoLimitAssociatedAssessments,
}) => {
  const handleClose = () => {
    handleClick(false);

    setTimeout(() => {
      setChecked(null);
      onEntityWorkflow([]);
      onPersonWorkflow([]);
      setForceNoLimitAssociatedAssessments(null);
    }, 500);
  };

  return (
    <HeaderWrapper>
      <Head isBold hasTitle={true} titleOnly={true}>
        {t("evaluation.advanced_options.title")}
        <span>
          <X onClick={handleClose} />
        </span>
      </Head>
      <SubTitle>{tComponents("modal.information.description")}</SubTitle>
    </HeaderWrapper>
  );
};

export const AssociateModal = () => {
  const {
    t,
    person,
    checked,
    loading,
    setChecked,
    tComponents,
    entityWorkflow,
    personWorkflow,
    showPrePaidMenu,
    onEntityWorkflow,
    onPersonWorkflow,
    setForceNoLimitAssociatedAssessments,
  } = useSearch();

  const toast = useAlert();

  const workflowsIsNotEmpty = useMemo(
    () => !isEmpty(entityWorkflow) || !isEmpty(personWorkflow),
    [entityWorkflow, personWorkflow]
  );

  const showAssessWithoutLimitation = useMemo(() => {
    return (
      workflowsIsNotEmpty &&
      (entityWorkflow.label === "Avaliação básica de sócios" ||
        personWorkflow.label === "Avaliação básica de sócios")
    );
  }, [entityWorkflow, personWorkflow, workflowsIsNotEmpty]);

  const [checkPrepaid, setCheckPrepaid] = useState(true);
  const [showOpenDiligenceModal, setOpenShowDiligenceModal] = useState(false);

  const action = {
    label: t("evaluation.conclude"),
    onClick: async () => {
      if (isEmpty(entityWorkflow) || isEmpty(personWorkflow)) {
        toast.error(t("evaluation.required_workflows_message"));
        return;
      }

      setOpenShowDiligenceModal(false);
    },
  };

  return (
    <Fragment>
      {person &&
        (person?.value === 1 || person?.value === 2 || showPrePaidMenu) && (
          <div
            className={"advanced-options"}
            style={{ display: "grid", marginTop: "1.6rem" }}
          >
            <Button
              disabled={false}
              appearance="secondary"
              label={t("evaluation.advanced_options.title")}
              onClick={() => setOpenShowDiligenceModal(true)}
            />
          </div>
        )}

      <Modal
        size={"lg"}
        action={action}
        loading={loading}
        showCancel={false}
        noMinHeight={false}
        show={showOpenDiligenceModal}
        onShow={setOpenShowDiligenceModal}
        classWrapper={"modal-advanced-options"}
        title={t("evaluation.advanced_options.title")}
        header={
          <Header
            t={t}
            setChecked={setChecked}
            tComponents={tComponents}
            onEntityWorkflow={onEntityWorkflow}
            onPersonWorkflow={onPersonWorkflow}
            handleClick={setOpenShowDiligenceModal}
            setForceNoLimitAssociatedAssessments={
              setForceNoLimitAssociatedAssessments
            }
          />
        }
      >
        <ModalView className="modal-sm">
          <div>
            {checkPrepaid && (
              <Flex gap="2rem" dir="column">
                {showPrePaidMenu && (
                  <PrepaidAssessment check={setCheckPrepaid} />
                )}
                <AutoAssessPartners checkPrepaid={checkPrepaid} />
                {checked && showAssessWithoutLimitation && (
                  <AssessWithoutLimitation />
                )}
              </Flex>
            )}
          </div>
        </ModalView>
      </Modal>
    </Fragment>
  );
};

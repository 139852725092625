import styled from 'styled-components';

import { breakpoints, colors, typograph } from '~/styles';

const { gray } = colors;
const { size, weight } = typograph;

const View = styled.div`
  .apexcharts-xaxis-label {
    & > title,
    & > tspan {
      fill: ${gray._400} !important;
      font-size: ${size.s1} !important;
      font-weight: ${weight.bold} !important;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    position: relative;
    top: 20px;
    transform: scaleX(1.1);

    .apexcharts-legend {
      max-height: 5rem !important;
    }
  }
`;

export default View;

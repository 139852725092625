import React, { useState, forwardRef, useEffect } from 'react';
import { Check } from 'react-feather';

import {
  func,
  bool,
  string,
  element,
  oneOfType,
} from 'prop-types';

import { View } from './styles';

const Checkbox = forwardRef(({
  size,
  label,
  className,
  onSelected,
  selectedProp,
  defaultChecked,
  isDisabled,
  space,
  rtl,
  ...rest
}, ref) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(defaultChecked);
  }, [defaultChecked]);

  return (
    <View
      size={size}
      space={space}
      onClick={() => setSelected(! selected)}
      selected={selectedProp === undefined ? selected : selectedProp}
      className={className}
      isDisabled={isDisabled}
      rtl={rtl}
    >
      {! rtl && <label htmlFor={label}>{label}</label>}
      <input
        type="checkbox"
        // eslint-disable-next-line
        checked={! isDisabled ? 
          (selectedProp === undefined ? selected : selectedProp)
          : true}
        onChange={onSelected}
        ref={ref}
        {...rest}
      />

      <span>
        {(selectedProp === undefined ? selected : selectedProp)
      && ! isDisabled
      && <Check size="1.4rem" />}

      </span>
      {rtl && <label htmlFor={label}>{label}</label>}
    </View>
  );
});

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  size: string,
  label: oneOfType([element, string]).isRequired,
  className: string,
  onSelected: func,
  selectedProp: bool,
  defaultChecked: bool,
  isDisabled: bool,
  rtl: bool,
  space: string
};

Checkbox.defaultProps = {
  size: 'md',
  className: '',
  onSelected: () => {},
  selectedProp: undefined,
  defaultChecked: false,
  isDisabled: false,
  rtl: true,
  space: ''
};

export default Checkbox;

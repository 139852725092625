import React from "react";

import { ChevronDown, Search as IconSearch } from "react-feather";

import { useMe } from "~/services";
import { Input, SimpleSelect } from "~/components";

import { useSearch } from "../../context/SearchProvider";

export const Cadastral = () => {
  const { group } = useMe();

  const { t, person, persons, options, mask, onPerson } = useSearch();

  const isGroup = !group?.value;

  return (
    <div>
      <SimpleSelect
        value={person}
        disabled={isGroup}
        icon={ChevronDown}
        isSearchable={false}
        isLoading={!persons}
        onChange={(val) => onPerson(val)}
        placeholder={t("components.person")}
        options={options.person?.filter((item, index) => index <= 1)}
      />

      <Input
        name="key"
        icon={<IconSearch />}
        disabled={!person || isGroup}
        mask={mask[person?.value] || ""}
        placeholder={
          person?.value === 1 ? t("components.key.0") : t("components.key.1")
        }
      />
    </div>
  );
};

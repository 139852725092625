import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  func,
  shape,
  number,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';

import { ReactComponent as IconFilter } from '~/assets/icons/icon-filter.svg';
import { Checkbox } from '~/components';
import { isChecked, onChecked } from '~/hooks';

import { View, Popup } from './styles';

const Filter = ({ fields, onFiltered }) => {
  const { t } = useTranslation('components');

  const clear = () => fields.onState([], fields?.items[0].prop);

  return (
    <View>
      <IconFilter />
      <Popup>
        {fields.items?.map(({ value, label, prop = '' }) => (
          <Checkbox
            key={value}
            size="sm"
            label={label}
            selectedProp={isChecked(value, fields.state)}
            onSelected={() => onChecked(value, fields.state, fields.onState, prop)}
          />
        ))}

        <button
          type="button"
          onClick={() => onFiltered(false)}
        >
          {t('filter.filter')}
        </button>
        <button
          type="button"
          onClick={() => {
            clear();
            onFiltered(true);
          }}
        >
          {t('filter.clear')}
        </button>
      </Popup>
    </View>
  );
};

Filter.propTypes = {
  fields: shape({
    state: arrayOf(oneOfType([string, number])),
    onState: func,
    items: arrayOf(shape({
      value: oneOfType([string, number]),
      label: string,
    })),
  }).isRequired,
  onFiltered: func,
};

Filter.defaultProps = {
  onFiltered: () => {},
};

export default Filter;

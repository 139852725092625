import styled, { css } from 'styled-components';

import { colors, rotate360 } from '~/styles';

const {
  gray,
  secondary,
} = colors;

const sizes = {
  sm: 'sm',
  lg: 'lg',
};

const appearances = {
  primary: 'primary',
  secondary: 'secondary',
};

export const Bar = styled.span`
  position: relative;
  animation: 1s ${rotate360} linear infinite;
  border-radius: 50%;
  stroke-linecap: round;
`;

export const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ size }) => size === sizes.sm && css`
    width: 2.0rem;
    height: 2.0rem;

    ${Bar} {
      width: 2.0rem;
      height: 2.0rem;
    }
  `}

  ${({ size }) => size === sizes.lg && css`
    width: 15.0rem;
    height: 15.0rem;
  `}

  ${({ appearance }) => {
    if (appearance === appearances.primary) {
      return css`
        ${Bar} {
          border: .2rem solid ${gray._000};
          border-bottom-color: transparent;
        }
      `;
    }
    if (appearance === appearances.secondary) {
      return css`
        ${Bar} {
          border: .2rem solid ${secondary};
          border-bottom-color: transparent;
        }
      `;
    }
  }}
`;

import styled from 'styled-components';
import { colors } from '~/styles';

const { white } = colors

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0;
  box-sizing: border-box;
  background-color: ${white};
  border-radius: 5px;
  padding: 2rem;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  padding: 2rem;
  gap: 1rem;
`

import styled, {css} from "styled-components";
import {colors, typograph} from "~/styles";
import {P3} from "~/components";

const {
  gray,
  status,
  primary
} = colors;

const {
  size: font,
} = typograph;

export const Error = styled(P3)`
  color: ${status.error};
  margin-top: .4rem;
`;

export const CKView = styled.div`
  display: flex;
  margin-top: 8px;
  font-size: ${font.s2};
  flex-direction: column;
  transition: all .3s ease-in-out !important;

  label {
    color: ${gray._500};
    font-size: ${font.s1};
    line-height: 2.4rem;
  }

  .ck-editor__editable_inline {
    min-height: 100px;
    border-radius: 0 0 .4rem .4rem !important;
    transition: all .3s ease-in-out !important;
  }

  ${({ error }) => error && css`
    .ck-editor__main {
      border: 2px solid ${status.error};
      border-radius: 0 0 .4rem .4rem !important;
    }
  `}

  ${({ appearance }) => appearance === 'primary' && css`
    .ck-editor__editable_inline:hover,
    .ck-editor__editable_inline:focus {
      border: 2px solid ${gray._100} !important;
      background-color: ${gray._100} !important;
    }
  `}

  ${({ appearance }) => appearance === 'secondary' && css`
    .ck-editor__editable_inline:hover,
    .ck-editor__editable_inline:focus {
      border: 2px solid ${primary._000} !important;
    }
  `}
`;

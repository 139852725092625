import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {useKeycloak} from "@react-keycloak/web";

export const isAxiosError = axios.isAxiosError

export const useApi = () => {
  const { keycloak } = useKeycloak()
  const { i18n } = useTranslation();

  const axiosConfig = {
    baseURL: `${process.env.REACT_APP_API_URL}/api/v2`,
    headers: {
      'Accept-Language': i18n.language,
      'locale': i18n.language,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  }

  return () => {
    if (keycloak.token)
      axiosConfig.headers.Authorization = `Bearer ${keycloak.token}`;

    return axios.create(axiosConfig)
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import {
  Flag, H1, ProgressBar, Table,
} from '~/components';
import { parseISO } from '~/hooks';

import { useResultData } from '../../resultContext';
import { SectionView as View } from '../../styles';

const PreviousDiligences = () => {
  const { id: processID } = useParams();
  const { t } = useTranslation(['diligence', 'entities']);
  const { previousDiligences } = useResultData();

  const rows = (previousDiligences?.data?.filter(({ id }) => id !== Number(processID)) ?? [])
    .map((item) => ({
      id: item.id,
      items: [{
        label: item.show_id || item.id,
        dataLabel: t('entities:diligences.dataLabels.0'),
      }, {
        label: format(parseISO(item.dataabertura), 'dd/MM/yyyy'),
        dataLabel: t('entities:diligences.dataLabels.1'),
      }, {
        label:
          item?.workflows?.length > 0
            ? item?.workflows?.map((subitem) => subitem.name)
            : '-',
        isBold: true,
        isMulti: item?.workflows?.length > 0,
        dataLabel: t('entities:diligences.dataLabels.2'),
      }, {
        label: (
          <ProgressBar
            impact_id={item?.risk?.probabilidade_id || 1}
            percentage={Math.round(item?.risk?.risk) || 0}
          />
        ),
        dataLabel: t('entities:diligences.dataLabels.3'),
      },
      {
        label: <Flag status={item?.new_status?.name || 'Não informado'} />,
        dataLabel: t('entities:diligences.dataLabels.4'),
      },
      {
        isArrow: true,
      }],
    }));

  return (
    rows?.length > 0 && (
      <View>
        <H1>{t('diligence:previous_diligences.title')}</H1>
        <Table
          withoutHeader
          route="/pesquisa/diligencia"
          body={rows}
          heading={[
            {
              isSort: false,
              size: 'xxs',
            },
            {
              size: 'xxs',
              isSort: false,
            },
            {
              size: 'sm',
              isSort: false,
            },
            {
              size: 'xs',
              isSort: false,
            },
            {
              size: 'xxs',
              isSort: false,
            },
            {
              size: 'xxs',
              isSort: false,
            },
          ]}
        />
      </View>
    )
  );
};

export default PreviousDiligences;

import React, { useEffect, useState, useMemo } from 'react';
import {
  Eye,
  Maximize,
  Clipboard,
  ChevronDown,
  AlertTriangle,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { format } from 'date-fns';

import { ReactComponent as IconEntities } from '~/assets/icons/icon-entities.svg';
import { ReactComponent as IconMonitoring } from '~/assets/icons/icon-workflows.svg';
import {
  H1,
  P1,
  P2,
  P3,
  Empty,
  Helmet,
  ColumnChart,
  StackedChart,
  Select,
  SimpleLoading,
  HorizontalBar,
  ProgressCircle,
  RangeDatePicker,
  LineChartMultiple,
  ProgressBarComplex,
} from '~/components';
import { useFetch, numberMask } from '~/hooks';
import { Header } from '~/layout';
import { useMe } from '~/services';
import { breakpoints } from '~/styles';

import { headings } from './data';
import {
  View,
  Wrapper,
  Grid,
  ActionsHeader,
  TotalCard,
  RiskPanelCharts,
  SectionProgressCircles,
} from './styles';

const Home = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { user, group, newGroup } = useMe();

  const { t } = useTranslation('dashboard');

  // // const [group, onGroup] = useState(null);
  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }
  const [selectedWorkflows, onSelectedWorkflows] = useState([]);
  const [selectedTags, onSelectedTags] = useState([]);

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const [date, onDate] = useState(() => new Date().setDate(1));
  const [endDate, onEndDate] = useState(() => new Date());

  const queryDate = useMemo(
    () => ({
      start: date
        ? format(date, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-01'),
      end: endDate
        ? format(endDate, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
    }),
    [date, endDate],
  );

  const workflows = useFetch({ url: `/workflows?allWithoutPagination=1${group?.value ? `&clientId=${group.value}` : ''}` });
  
  const { data: tags } = useFetch({ url: `/tag${group?.value ? `?clientId=${group.value}` : ''}` });

  const managementPanel = useFetch({
    url: `/management-panel/processes?
  from=${queryDate.start}${queryDate?.end ? `&to=${queryDate?.end}` : ''}
  ${group?.value ? `&clientId=${group.value}` : ''}
  ${selectedWorkflows?.length > 0 ? selectedWorkflows.map(({ value }) => `&workflows_id[]=${value}`) : ''}
  ${selectedTags?.length > 0 ? selectedTags.map(({ value }) => `&tags_id[]=${value}`) : ''}`,
  })?.data?.data;

  const managementPanelActions = useFetch({
    url:`/management-panel/actionPlans?
    from=${queryDate.start}&to=${queryDate.end}
    ${group?.value ? `&clientId=${group.value}` : ''}
    ${selectedWorkflows?.length > 0 ? selectedWorkflows.map(({ value }) => `&workflows_id[]=${value}`) : ''}
    ${selectedTags?.length > 0 ? selectedTags.map(({ value }) => `&tags_id[]=${value}`) : ''}`,
  })?.data?.data;

  const managementPanelEntities = useFetch({
    url: `management-panel/entities?
  from=${queryDate.start}&to=${queryDate.end}
  ${group?.value ? `&clientId=${group.value}` : ''}
  ${selectedWorkflows?.length > 0 ? selectedWorkflows.map(({ value }) => `&workflows_id[]=${value}`) : ''}
  ${selectedTags?.length > 0 ? selectedTags.map(({ value }) => `&tags_id[]=${value}`) : ''}
  `,
  })?.data?.data;

  const riskPanelProcesses = useFetch({
    url: `/risk-panel/processes?
    from=${queryDate.start}&to=${queryDate.end}
    ${group?.value ? `&clientId=${group.value}` : ''}
    ${selectedWorkflows?.length > 0 ? selectedWorkflows.map(({ value }) => `&workflows_id[]=${value}`) : ''}
    ${selectedTags?.length > 0 ? selectedTags.map(({ value }) => `&tags_id[]=${value}`) : ''}`,
  })?.data?.data;

  const riskPanelEntities = useFetch({
    url: `/risk-panel/entities?
    from=${queryDate.start}&to=${queryDate.end}
    ${group?.value ? `&clientId=${group.value}` : ''}
    ${selectedWorkflows?.length > 0 ? selectedWorkflows.map(({ value }) => `&workflows_id[]=${value}`) : ''}
    ${selectedTags?.length > 0 ? selectedTags.map(({ value }) => `&tags_id[]=${value}`) : ''}`,
  })?.data?.data;

  const riskPanelThemes = useFetch({
    url: `/risk-panel/themes?
    from=${queryDate.start}&to=${queryDate.end}
    ${group?.value ? `&clientId=${group.value}` : ''}
    ${selectedWorkflows?.length > 0 ? selectedWorkflows.map(({ value }) => `&workflows_id[]=${value}`) : ''}
    ${selectedTags?.length > 0 ? selectedTags.map(({ value }) => `&tags_id[]=${value}`) : ''}
    `,
  })?.data?.data;

  const riskPanelWorkflows = useFetch({
    url: `/risk-panel/workflows?
    from=${queryDate.start}&to=${queryDate.end}
    ${group?.value ? `&clientId=${group.value}` : ''}
    ${selectedWorkflows?.length > 0 ? selectedWorkflows.map(({ value }) => `&workflows_id[]=${value}`) : ''}
    ${selectedTags?.length > 0 ? selectedTags.map(({ value }) => `&tags_id[]=${value}`) : ''}`,
  })?.data?.data;

  const risk = useFetch({
    url: `/clients/getClientRisks?client_id=${
      group?.value ? group.value : user.hierarquia[0].id
    }`,
  })?.data?.data;

  const riskLevels = useMemo(() => {
    if (risk) {
      const {
        baixo, medio, alto, critico,
      } = risk;
      return [baixo, medio, alto, critico];
    }
    return [];
  });

  const query = new URLSearchParams(search).get('q');

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenSize(window.innerWidth);
    });
  }, []);

  /**
   * List workflows selected.
   */
  const list_workflows = useMemo(() => workflows?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  })), [workflows]);

  useEffect(() => {
    if (! query) {
      navigate('/?q=painel-de-gestao');
    }
  }, [query, navigate]);

  const actionPlans = useMemo(() => {
    let categories = null;
    let actionPlanData = null;

    if (managementPanelActions) {
      categories = [];
      actionPlanData = [];

      const keys = [
        'due_in_two_days',
        'in_time',
        'late',
        'answered',
        'concluded',
        'canceled',
      ];
      const categoriesKeys = [
        t('control.action_plan.labels.0'),
        t('control.action_plan.labels.1'),
        t('control.action_plan.labels.2'),
        t('control.action_plan.labels.3'),
        t('control.action_plan.labels.4'),
        t('control.action_plan.labels.5'),
      ];

      keys.map((key, index) => {
        if (managementPanelActions[key] > 0) {
          categories.push(categoriesKeys[index]);
          actionPlanData.push(managementPanelActions[key]);
        }
      });

      return {
        categories,
        data: actionPlanData,
      };
    }
    return { categories, data: actionPlanData };
  }, [managementPanelActions, t]);

  const {
    concludedProcesses,
    runningProcesses,
    openedProcessesOverTime,
    concludedProcessesOverTime,
  } = useMemo(() => {
    if (managementPanel) {
      const openedProcessesOverTimeObj = managementPanel.openedProcessesOverTime.reduce(
        (acc, curr) => {
          let formattedDate = '';
          if (managementPanel.openedProcessesOverTime.length > 30) {
            // MM/yyyy
            formattedDate = `${curr.date.split('-')[1]}/${
              curr.date.split('-')[0]
            }`;
          } else {
            // dd/MM
            formattedDate = `${curr.date.split('-')[2]}/${
              curr.date.split('-')[1]
            }`;
          }

          if (! acc[formattedDate]) {
            acc[formattedDate] = Number(curr.count);
          } else {
            acc[formattedDate] += Number(curr.count);
          }
          return acc;
        },
        {},
      );

      const concludedProcessesFormattedByDay = managementPanel.concludedProcessesOverTime.map(
        (item) => ({
          name: item.status_name,
          data: item.count,
          category: `${item.date.split('-')[2]}/${item.date.split('-')[1]}`,
        }),
      );

      let concludedProcessesFormattedByMonth = managementPanel.concludedProcessesOverTime.map(
        (item) => ({
          name: item.status_name,
          data: item.count,
          category: `${item.date.split('-')[1]}/${item.date.split('-')[0]}`,
        }),
      );

      concludedProcessesFormattedByMonth = concludedProcessesFormattedByMonth.reduce(
        (acc, curr) => {
          const accRepeatedIndex = acc.findIndex(
            (item) => item.category === curr.category && item.name === curr.name,
          );
          if (accRepeatedIndex > -1) {
            acc[accRepeatedIndex].data += curr.data;
          } else {
            acc.push(curr);
          }
          return acc;
        },
        [],
      );

      const formattedConcludedProcessesOverTime = concludedProcessesFormattedByDay.length > 30
        ? concludedProcessesFormattedByMonth
        : concludedProcessesFormattedByDay;

      return {
        concludedProcesses: {
          categories: managementPanel.concludedProcesses.map(
            (item) => item.status,
          ),
          data: managementPanel.concludedProcesses.map((item) => item.count),
        },
        runningProcesses: {
          categories: managementPanel.runningProcesses.map(
            (item) => item.status,
          ),
          data: managementPanel.runningProcesses.map((item) => item.count),
        },
        openedProcessesOverTime: {
          categories: Object.keys(openedProcessesOverTimeObj).map((item) => (item === '00/0000' || item === '00/00' ? 'Não registrado' : item)),
          data: Object.values(openedProcessesOverTimeObj),
        },
        concludedProcessesOverTime: {
          categories: [
            ...new Set(
              formattedConcludedProcessesOverTime.map((item) => item.category),
            ),
          ],
          data: formattedConcludedProcessesOverTime.map((item) => ({
            name: item.name,
            data: item.data,
          })),
        },
      };
    }
    return {
      concludedProcesses: null,
      runningProcesses: null,
      openedProcessesOverTime: null,
    };
  }, [managementPanel]);

  const entitiesByWorkflow = useMemo(() => {
    let categories = [];
    let entitiesData = [];
    let total = 0;

    if (managementPanelEntities) {
      categories = [];
      entitiesData = [];

      managementPanelEntities.entitiesByWorkflow.map((item) => {
        categories.push(item.workflow_name);
        entitiesData.push(item.entities_count);
        total += item.entities_count;
      });

      return {
        categories,
        data: entitiesData,
        total,
      };
    }
    return { categories, data: entitiesData, total };
  }, [managementPanelEntities]);

  const entitiesByStatus = useMemo(() => {
    let categories = [];
    let entitiesData = [];
    let total = 0;

    if (managementPanelEntities) {
      categories = [];
      entitiesData = [];

      managementPanelEntities.entitiesByStatus.map((item) => {
        if (item.entities_count > 0) {
          categories.push(item.status);
          entitiesData.push(item.entities_count);
          total += item.entities_count;
        }
      });

      return {
        categories,
        data: entitiesData,
        total,
      };
    }
    return { categories, data: entitiesData, total };
  }, [managementPanelEntities]);

  const concludedProcessesRisk = useMemo(
    () => riskPanelProcesses?.concludedProcessesRisk.map((item) => ({
      ...item,
    })),
    [riskPanelProcesses],
  );

  const workflowsRisk = useMemo(
    () => riskPanelWorkflows?.overTimeRisk.map((item) => ({
      date: item.date,
      status_name: item.workflow_name,
      status_id: item.workflow_id,
      average_risk: Number(item.avg_risk).toFixed(0),
      count_processes: item.count_processes,
    })),
    [riskPanelWorkflows],
  );

  const grupos = user.hierarquia?.map((item) => ({
    value: item.id,
    label: item.cliente,
  }));

  if (grupos?.length > 1 && user.grupos && user.grupos.length > 0) {
    grupos.unshift({
      value: 0,
      label: user.grupos[0].grupo,
    });
  }

  return (
    <View>
      <Helmet
        title={
          query === 'painel-de-gestao'
            ? t('helmet.control')
            : t('helmet.risk')
        }
      />

      <Wrapper>
        <Header head={headings(t)} query={query} />

        <ActionsHeader>
          <Select
            name="client"
            isReveal={user?.hierarquia?.length > 1}
            icon={ChevronDown}
            value={group}
            options={grupos}
            onChange={(val) => {
              onSelectedTags([]);
              newGroup(val);
            }}
            appearance="secondary"
            placeholder={t('head.client.placeholder')}
            clearField={{
              label: t('head.client.clean'),
              onClick: () => newGroup({}),
            }}
          />

          <RangeDatePicker
            date={date}
            onDate={onDate}
            endDate={endDate}
            onEndDate={onEndDate}
            maxDate={new Date()}
          />

          <Select
            name="workflows"
            isMulti
            icon={ChevronDown}
            value={selectedWorkflows}
            options={list_workflows}
            onChange={(val) => onSelectedWorkflows(val)}
            appearance="secondary"
            placeholder={t('head.workflows.placeholder')}
          />

          <Select
            name="tags"
            icon={ChevronDown}
            value={selectedTags}
            options={tags?.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(val) => onSelectedTags(val)}
            appearance="secondary"
            placeholder={t('head.tags.placeholder')}
            isMulti
          />
        </ActionsHeader>

        {query === 'painel-de-gestao' && ! managementPanel ? <SimpleLoading className="top" /> : (
          <>
            {(query === 'painel-de-gestao' && (
              <>
                <Grid>
                  <P1>{t('control.assessments.title')}</P1>

                  <div>
                    <TotalCard className="col2">
                      <span>
                        <Maximize />
                        <Eye />
                      </span>
                      <H1>{numberMask(managementPanel?.openedProcesses || 0)}</H1>
                      <P2>{t('control.assessments.charts.opened')}</P2>
                    </TotalCard>

                    <div className="col4">
                      <P3>
                        {`${t('control.assessments.charts.processing')} (${numberMask(
                          runningProcesses.data.reduce((acc, curr) => acc + curr, 0),
                        )})`}
                      </P3>
                      {runningProcesses.data.reduce((acc, curr) => acc + curr, 0)
                  > 0 ? (
                    <HorizontalBar
                      categories={runningProcesses.categories}
                      data={runningProcesses.data}
                    />
                        ) : (
                          <Empty
                            hasButton={false}
                            hasMessage={false}
                            className="empty"
                          />
                        )}
                    </div>

                    <div className="col4">
                      <P3>
                        {`${t('control.assessments.charts.completed')} (${numberMask(concludedProcesses.data.reduce(
                          (acc, curr) => acc + curr, 0,
                        ))})`}
                      </P3>
                      {concludedProcesses.data.reduce(
                        (acc, curr) => acc + curr,
                        0,
                      ) > 0 ? (
                        <HorizontalBar
                          categories={concludedProcesses.categories}
                          data={concludedProcesses.data}
                        />
                        ) : (
                          <Empty
                            hasButton={false}
                            hasMessage={false}
                            className="empty"
                          />
                        )}
                    </div>

                    <div className="col5">
                      <P3>{t('control.assessments.charts.open_in_time')}</P3>
                      {openedProcessesOverTime.data.length > 0 ? (
                        <ColumnChart
                          categories={openedProcessesOverTime.categories}
                          data={openedProcessesOverTime.data}
                        />
                      ) : (
                        <Empty
                          hasButton={false}
                          hasMessage={false}
                          className="empty"
                        />
                      )}
                    </div>

                    <div className="col5">
                      <P3>{t('control.assessments.charts.completed_in_time')}</P3>
                      {concludedProcessesOverTime.data.length > 0 ? (
                        <StackedChart
                          categories={concludedProcessesOverTime.categories}
                          data={concludedProcessesOverTime.data}
                          height={350}
                        />
                      ) : (
                        <Empty
                          hasButton={false}
                          hasMessage={false}
                          className="empty"
                        />
                      )}
                    </div>
                  </div>
                </Grid>

                {actionPlans.data && (
                  <Grid>
                    <P1>{t('control.action_plan.title')}</P1>

                    <div>
                      <TotalCard className="col2">
                        <span>
                          <Clipboard />
                        </span>

                        <H1>{managementPanelActions?.due_in_two_days}</H1>

                        <P2>{t('control.action_plan.charts.to_defeat')}</P2>
                      </TotalCard>

                      <div className="col8">
                        <P3>{`${t('control.action_plan.charts.status')} (${numberMask(managementPanelActions?.total || 0)})`}</P3>
                        {actionPlans.data ? (
                          <>
                            {actionPlans.data.length > 0 ? (
                              <HorizontalBar
                                categories={actionPlans.categories}
                                data={actionPlans.data}
                              />
                            ) : (
                              <Empty
                                hasButton={false}
                                hasMessage={false}
                                className="empty"
                              />
                            )}
                          </>
                        ) : (
                          <SimpleLoading />
                        )}
                      </div>
                    </div>
                  </Grid>
                )}

                {managementPanelEntities && (
                  <Grid>
                    <P1>{t('control.entities.title')}</P1>

                    <div>
                      <TotalCard className="col2 row2">
                        <span>
                          <IconEntities />
                        </span>
                        <H1>
                          {numberMask(managementPanelEntities?.registeredEntities || 0)}
                        </H1>
                        <P2>{t('control.entities.charts.access')}</P2>
                      </TotalCard>

                      <div className="col8">
                        <P3>{`${t('control.entities.charts.status')} (${numberMask(entitiesByStatus.total)})`}</P3>
                        <HorizontalBar
                          categories={entitiesByStatus.categories}
                          data={entitiesByStatus.data}
                        />
                      </div>

                      <div className="col8">
                        <P3>{`${t('control.entities.charts.workflow')} (${numberMask(entitiesByWorkflow.total)})`}</P3>
                        <HorizontalBar
                          categories={entitiesByWorkflow.categories}
                          data={entitiesByWorkflow.data}
                          height={
                        entitiesByWorkflow.data.length < 6
                          ? 250
                          : entitiesByWorkflow.data.length * 50
                      }
                        />
                      </div>
                    </div>
                  </Grid>
                )}
              </>
            ))}
          </>
        )}

        {query === 'painel-de-risco' && ! riskPanelProcesses ? <SimpleLoading className="top" /> : (
          <>
            {(query === 'painel-de-risco' && (
              <>
                <Grid>
                  <P1>{t('risk.assessments.title')}</P1>

                  <div>
                    <TotalCard className="col2">
                      <span>
                        <Maximize />
                        <Eye />
                      </span>

                      <H1>
                        {numberMask(riskPanelProcesses
                          ?.processesAverageRisk?.concluded?.count || 0)}
                      </H1>

                      <P2>{t('risk.assessments.charts.completed')}</P2>
                    </TotalCard>

                    <SectionProgressCircles className="col8">
                      <P3>{t('risk.assessments.charts.average')}</P3>

                      <div>
                        <ProgressCircle
                          isTitleTop
                          title={`${t('risk.assessments.charts.completed')} (${
                            riskPanelProcesses?.processesAverageRisk?.concluded?.count || 0
                          })`}
                          percentage={(
                            riskPanelProcesses?.processesAverageRisk?.concluded?.avgRisk || 0
                          ).toFixed(0)}
                          defaultRiskLevels={riskLevels}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={`${t('risk.assessments.charts.awaiting')} (${
                            riskPanelProcesses?.processesAverageRisk?.waitingForAnalysis?.count || 0
                          })`}
                          percentage={(
                            riskPanelProcesses?.processesAverageRisk
                              ?.waitingForAnalysis?.avgRisk || 0
                          ).toFixed(0)}
                          isSmall={screenSize > Number(breakpoints.sm.replace('px', ''))}
                          defaultRiskLevels={riskLevels}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={`${t('risk.assessments.charts.approved')} (${
                            riskPanelProcesses?.processesAverageRisk?.approved?.count || 0
                          })`}
                          percentage={(
                            riskPanelProcesses?.processesAverageRisk?.approved.avgRisk || 0
                          ).toFixed(0)}
                          isSmall={screenSize > Number(breakpoints.sm.replace('px', ''))}
                          defaultRiskLevels={riskLevels}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={`${t('risk.assessments.charts.approved_with_risks')} (${
                            riskPanelProcesses?.processesAverageRisk
                              ?.approvedWithReservations?.count || 0
                          })`}
                          percentage={(
                            riskPanelProcesses?.processesAverageRisk
                              ?.approvedWithReservations?.avgRisk || 0
                          ).toFixed(0)}
                          isSmall={screenSize > Number(breakpoints.sm.replace('px', ''))}
                          defaultRiskLevels={riskLevels}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={`${t('risk.assessments.charts.disproved')} (${
                            riskPanelProcesses?.processesAverageRisk?.reproved
                              ?.count || 0
                          })`}
                          percentage={(
                            riskPanelProcesses?.processesAverageRisk?.reproved
                              ?.avgRisk || 0
                          ).toFixed(0)}
                          isSmall={screenSize > Number(breakpoints.sm.replace('px', ''))}
                          defaultRiskLevels={riskLevels}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={`${t('risk.assessments.charts.monitoring')} (${
                            riskPanelProcesses?.processesAverageRisk?.monitored
                              ?.count || 0
                          })`}
                          percentage={(
                            riskPanelProcesses?.processesAverageRisk?.monitored
                              ?.avgRisk || 0
                          ).toFixed(0)}
                          defaultRiskLevels={riskLevels}
                        />
                      </div>
                    </SectionProgressCircles>

                    <div className="col10">
                      <P3>{t('risk.assessments.charts.risks_of_completed')}</P3>
                      <LineChartMultiple
                        data={concludedProcessesRisk}
                        initialDate={date ? queryDate.start : null}
                        finalDate={endDate ? queryDate.end : null}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid>
                  <P1>{t('risk.entities.title')}</P1>

                  <div>
                    <TotalCard className="col2">
                      <span>
                        <IconEntities />
                      </span>
                      <H1>{numberMask(riskPanelEntities?.total?.count || 0)}</H1>
                      <P2>{t('risk.entities.title')}</P2>
                    </TotalCard>

                    <SectionProgressCircles className="col8">
                      <P3>{t('risk.entities.charts.average')}</P3>
                      <div>
                        <ProgressCircle
                          isTitleTop
                          title={t('risk.entities.average.general')}
                          percentage={(
                            riskPanelEntities?.total?.avgRisk || 0
                          ).toFixed(0)}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={t('risk.entities.average.pf_local')}
                          percentage={(
                            riskPanelEntities?.by_person_type?.['1'].avgRisk || 0
                          ).toFixed(0)}
                          isSmall={screenSize > Number(breakpoints.sm.replace('px', ''))}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={t('risk.entities.average.pj_local')}
                          percentage={(
                            riskPanelEntities?.by_person_type?.['2'].avgRisk || 0
                          ).toFixed(0)}
                          isSmall={screenSize > Number(breakpoints.sm.replace('px', ''))}
                          defaultRiskLevels={riskLevels}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={t('risk.entities.average.pf_foreign')}
                          percentage={(
                            riskPanelEntities?.by_person_type?.['3'].avgRisk || 0
                          ).toFixed(0)}
                          isSmall={screenSize > Number(breakpoints.sm.replace('px', ''))}
                          defaultRiskLevels={riskLevels}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={t('risk.entities.average.pj_foreign')}
                          percentage={(
                            riskPanelEntities?.by_person_type?.['4'].avgRisk || 0
                          ).toFixed(0)}
                          isSmall={screenSize > Number(breakpoints.sm.replace('px', ''))}
                          defaultRiskLevels={riskLevels}
                        />
                        <ProgressCircle
                          isTitleTop
                          title={`${t('risk.entities.average.monitoring')} (${
                            riskPanelEntities?.monitored?.count || 0
                          })`}
                          percentage={(
                            riskPanelEntities?.monitored?.avgRisk || 0
                          ).toFixed(0)}
                          defaultRiskLevels={riskLevels}
                        />
                      </div>
                    </SectionProgressCircles>
                  </div>
                </Grid>

                <Grid>
                  <P1>{t('risk.workflows.title')}</P1>

                  <div>
                    <TotalCard className="col2">
                      <span>
                        <IconMonitoring />
                      </span>
                      <H1>{numberMask(riskPanelWorkflows?.avgRisk?.length || 0)}</H1>
                      <P2>{t('risk.workflows.title')}</P2>
                    </TotalCard>

                    <div className="col8">
                      <P3>{t('risk.workflows.charts.average')}</P3>
                      {riskPanelWorkflows?.avgRisk?.length > 0 ? (
                        <RiskPanelCharts>
                          {riskPanelWorkflows?.avgRisk.map(
                            ({
                              id: workflowId, name, avg_risk, count,
                            }) => (
                              <ProgressBarComplex
                                key={workflowId}
                                percentage={
                                avg_risk ? Number(avg_risk).toFixed(0) : 0
                              }
                                value={count || 0}
                                title={name && name}
                                defaultRiskLevels={riskLevels}
                              />
                            ),
                          )}
                        </RiskPanelCharts>
                      ) : (
                        <Empty
                          hasButton={false}
                          hasMessage={false}
                          className="empty"
                        />
                      )}
                    </div>

                    <div className="col10">
                      <P3>{t('risk.workflows.charts.risks_workflows')}</P3>
                      <LineChartMultiple
                        data={workflowsRisk}
                        initialDate={date ? queryDate.start : null}
                        finalDate={endDate ? queryDate.end : null}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid>
                  <P1>{t('risk.themes.title')}</P1>

                  <div>
                    <TotalCard className="col2">
                      <span>
                        <AlertTriangle />
                      </span>
                      <H1>{numberMask(riskPanelThemes?.themesCount || 0)}</H1>
                      <P2>{t('risk.themes.charts.risk_themes')}</P2>
                    </TotalCard>

                    <div className="col8">
                      <P3>{t('risk.themes.charts.average')}</P3>
                      {riskPanelThemes?.themesAverageRisk?.themes.length > 0 ? (
                        <RiskPanelCharts>
                          {riskPanelThemes?.themesAverageRisk?.themes.map(
                            ({ name, risk_average, processes_count }) => (
                              <ProgressBarComplex
                                key={name}
                                percentage={
                                risk_average
                                  ? Number(risk_average).toFixed(0)
                                  : 0
                              }
                                value={processes_count || 0}
                                title={name && name}
                                defaultRiskLevels={riskLevels}
                              />
                            ),
                          )}
                        </RiskPanelCharts>
                      ) : (
                        <Empty
                          hasButton={false}
                          hasMessage={false}
                          className="empty"
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              </>
            ))}
          </>
        )}
      </Wrapper>
    </View>
  );
};

export default Home;

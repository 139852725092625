import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';
import {
  bool,
  number,
  object, oneOf, string,
} from 'prop-types';
import * as Yup from 'yup';

import { Input, Modal, P2 } from '~/components';
import { useApi, formatErrorMessage } from '~/hooks';
import { error } from '~/services';

import { useResultData } from '../../../resultContext';
import { ModalView } from '../../../styles';
import DefaultRiskItem from './DefaultRiskItem';
import SurveyRiskItem from './SurveyRiskItem';
import {useSelectRisks} from "~/views/Search/Response/Diligence2/services/selectRisksContext";

const mapSearchTypeToRiskItemType = {
  4: 'survey',
};

const RiskItem = ({
  type, data, themeName, riskSourceName, riskSourceID, themeID, isProcess
}) => {
  const { id: processID } = useParams();
  const { seem, mutateEverything } = useResultData();
  const alert = useAlert();
  const api = useApi();
  const { t } = useTranslation('diligence');
  const { t: tAuth } = useTranslation('auth');
  const { selectedRisks } = useSelectRisks();
  const [loading, setLoading] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [toggleData, setToggleData] = useState(null);
  const [showChangeReason, setShowChangeReason] = useState(false);
  const [coluna3, setColuna3] = useState(null);
  const toggleRef = useRef(null);
  const reasonRef = useRef(null);
  const actualType = type || mapSearchTypeToRiskItemType[data.search_type_id] || 'default';

  useEffect(() => {
    if(data.search_type_id == 3){
      setColuna3(`Risco: ${data.descricao_impacto}`);
    } else if (data.search_type_id == 19){
      setColuna3(null);
    } else if (data.search_type_id == 11) {
        const hidden = 'global.factiva.com';
        if(data?.coluna3?.includes(hidden)){
            setColuna3(null);
        } else {
          setColuna3(data.coluna3);
        }
    } else {
      setColuna3(data.coluna3);
    }
  },[])
  useEffect(() => {
    if (! showToggle && ! showChangeReason) {
      setToggleData(null);
    }
  }, [showToggle, showChangeReason]);

  const showToggleModal = useCallback((newToggleData) => {
    setToggleData(newToggleData);
    setShowToggle(true);
  }, []);

  const showReasonModal = useCallback((newToggleData) => {
    setToggleData(newToggleData);
    setShowChangeReason(true);
  }, []);

  const handleSubmit = useCallback(async (submitData, reasonOnly) => {
    if (seem?.data?.id) {
      return alert.error(t('toggle.alerts.finalized'));
    }

    try {
      await Yup.object().shape({
        reason: Yup.string().required(t('toggle.alerts.reason')),
      }).validate(submitData, { abortEarly: false });
    } catch (err) {
      return error(err, toggleRef);
    }

    const {
      reason, themeId, riskId, id: resultID,
    } = submitData;

    let url = `/process/${processID}/theme/${themeId}/source/${riskId}/result/${resultID}`;
    if (reasonOnly) {
      url = `${url}/reason`;
    }

    setLoading(true);
    try {
      await api().post(url, { reason });
      alert.success(
        `${t('toggle.alerts.success_item')} ${t('toggle.alerts.wait')}`,
      );
      setShowToggle(false);
      setShowChangeReason(false);

      mutateEverything();
    } catch ({ response }) {
      alert.error(
        formatErrorMessage(response, t('toggle.alerts.error_item'),tAuth('expired')),
      );
    } finally {
      setLoading(false);
    }
  }, [seem, t, alert, processID, mutateEverything, tAuth, selectedRisks]);

  let riskItem;
  switch (actualType) {
    case 'default':
      let resultado_coluna2;
      let result = JSON.parse(data.popup) ?? null;
      let source_type = result && result.type ? result.type : null;
      if (source_type === "processo_judicial") {
        let num_processo = result.data.Number == null || result.data.Number == undefined ? '' : result.data.Number + " - "; //NUMERO DO PROCESSO
        let assunto = result.data.MainSubject == null || result.data.MainSubject == undefined ? '' : result.data.MainSubject + " - "; // ASSUNTO
        let classe = result.data.CourtType == null || result.data.CourtType == undefined ? '' : result.data.CourtType + " - "; // CLASSE
        let vara = result.data.JudgingBody == null || result.data.JudgingBody == undefined ? '' : result.data.JudgingBody + " - "; // VARA
        let data_distribuicao = result.data.NoticeData == null || result.data.NoticeData == undefined ? '' : result.data.NoticeData + " - "; // DATA DA DISTRIBUICAO
        let valor = result.data.Value == null || result.data.Value == undefined ? '' : result.data.Value + " - "; //VALOR DA CAUSA
        let natureza = result.data.Nature == null || result.data.Nature == undefined ? '' : result.data.Nature // NATUREZA DO PROCESSO
        
        resultado_coluna2 = num_processo + assunto + classe + vara + data_distribuicao + valor + natureza ;
        
      } else if( data.search_type_id === 3 && data.attachment_name ) {
        resultado_coluna2 = data.attachment_name;
      } else {
        resultado_coluna2 = data.coluna2;
      }
      
      riskItem = (
        <DefaultRiskItem
          id={data.id}
          confirmed={data.confirmed === 1}
          coluna1={data.coluna1}
          coluna2={
            // data.search_type_id === 3 && data.attachment_name ? data.attachment_name : data.coluna2
            resultado_coluna2
          }
          hasTooltip={isProcess}
          coluna3={coluna3}
          popup={
            data.search_type_id === 3 && data.attachment_url
              ? JSON.stringify({
                type: 'link',
                href: data.attachment_url,
              }) : data.popup
          }
          marked={data.marked}
          subjectPrefix={themeName && riskSourceName ? `${themeName} - ${riskSourceName}` : null}
          confirmed_change_reason={data.confirmed_change_reason}
          showToggleModal={showToggleModal}
          showReasonModal={showReasonModal}
          riskSourceID={riskSourceID}
          themeID={themeID}
        />
      );
      break;
    case 'survey':
      // eslint-disable-next-line no-case-declarations
      const parsedPopup = data.popup ? JSON.parse(data.popup) : null;
      riskItem = parsedPopup && (
        <SurveyRiskItem
          id={data.id}
          name={parsedPopup.data.sourceName}
          risk={parsedPopup.data.risk}
          alerts={parsedPopup.data.alerts}
          answered_at={parsedPopup.data.answeredAt}
          marked={data.marked}
          confirmed_change_reason={data.confirmed_change_reason}
          showToggleModal={showToggleModal}
          showReasonModal={showReasonModal}
          riskSourceID={riskSourceID}
          themeID={themeID}
          history={data.history}
          descricao_impacto={data.descricao_impacto}
          risk_impact_id={data.risk_impact_id}
        />
      );
      break;
    default:
      riskItem = null;
  }

  return (
    <>
      {riskItem}
      {showToggle && (
        <Modal
          hasAction
          hasOverflow={false}
          show
          title={`${toggleData.active
            ? t('toggle.modal_toggle.title.discard')
            : t('toggle.modal_toggle.title.confirm')
          } ${toggleData.riskId
            ? t('toggle.modal_toggle.title.item')
            : t('toggle.modal_toggle.title.source')
          } ${t('toggle.modal_toggle.title.main')}`}
          onShow={setShowToggle}
          action={{
            label: `${t('toggle.modal_toggle.action.main')} ${toggleData.active
              ? t('toggle.modal_toggle.action.discard')
              : t('toggle.modal_toggle.action.confirm')
            }`,
            loading,
            onClick: () => toggleRef?.current?.submitForm(),
          }}
        >
          <ModalView>
            <P2>
              {`${t('toggle.modal_toggle.text.main')} ${toggleData.active
                ? t('toggle.modal_toggle.text.discard')
                : t('toggle.modal_toggle.text.confirm')
              } ${t('toggle.modal_toggle.text.sub')}`}
            </P2>
            <Form ref={toggleRef} onSubmit={(submitData) => handleSubmit(submitData, false)}>
              <Input name="id" reveal={false} value={toggleData.id} />
              <Input name="themeId" reveal={false} value={toggleData.themeId} />
              <Input name="riskId" reveal={false} value={toggleData.riskId} />
              <Input
                name="reason"
                label={t('toggle.modal_toggle.reason.label')}
                placeholder={t('toggle.modal_toggle.reason.placeholder')}
                appearance="secondary"
                textarea
              />
            </Form>
          </ModalView>
        </Modal>
      )}
      {showChangeReason && (
        <Modal
          title={`${t('toggle.modal_edit.title.main')} ${toggleData.active
            ? t('toggle.modal_edit.title.confirm')
            : t('toggle.modal_edit.title.discard')
          }`}
          show
          onShow={setShowChangeReason}
          action={{
            label: t('toggle.modal_edit.action'),
            loading,
            onClick: () => reasonRef?.current?.submitForm(),
          }}
          loading={loading}
        >
          <ModalView>
            <P2>
              {`${t('toggle.modal_edit.text.main')} ${toggleData.active
                ? t('toggle.modal_edit.text.confirm')
                : t('toggle.modal_edit.text.discard')
              }.`}
            </P2>
            <Form
              ref={reasonRef}
              onSubmit={(submitData) => handleSubmit(submitData, true)}
              initialData={{ reason: toggleData.reason }}
            >
              <Input name="id" reveal={false} value={toggleData.id} />
              <Input name="themeId" reveal={false} value={toggleData.themeId} />
              <Input name="riskId" reveal={false} value={toggleData.riskId} />
              <Input
                name="reason"
                label={t('toggle.modal_edit.reason.label')}
                placeholder={`${t(
                  'toggle.modal_edit.reason.placeholder.main',
                )} ${toggleData.active
                  ? t('toggle.modal_edit.reason.placeholder.confirm')
                  : t('toggle.modal_edit.reason.placeholder.discard')
                }`}
                appearance="secondary"
                textarea
              />
            </Form>
          </ModalView>
        </Modal>
      )}
    </>
  );
};

RiskItem.propTypes = {
  type: oneOf(['default', 'survey']),
  // eslint-disable-next-line react/forbid-prop-types
  data: object,
  themeName: string,
  riskSourceName: string,
  riskSourceID: number.isRequired,
  themeID: number.isRequired,
  isProcess: bool,
};

RiskItem.defaultProps = {
  type: undefined,
  themeName: null,
  riskSourceName: null,
  data: null,
  isProcess: false
};

export default RiskItem;

import styled from 'styled-components'
import Colors from '~/styles/colors';
import { Container, H6 } from '~/components';

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  position: relative;
  width: 29.6rem;

  &:not(:first-of-type):before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background: ${({ active, imNext }) =>
  active
    ? Colors.primary._000
    : imNext
      ? 'linear-gradient(89.99deg, #420080 0.45%, #C4C4C4 99.55%)'
      : Colors.gray._400};
    left: -50%;
    z-index: 1;
    top: 3.6rem;
  }

  ${H6} {
    color: ${({ active }) => (active ? Colors.gray._500 : Colors.gray._300)};
    transition: all 0.3s ease-in-out;
  }

  .dot {
    z-index: 10;
    user-select: none;
    cursor: pointer;
    border-radius: 50%;
    width: 7.2rem;
    height: 7.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    font-size: 3.2rem;
    line-height: 3.7rem;
    color: #ffffff;

    background-color: ${({ active }) =>
  active ? Colors.primary._000 : Colors.gray._400};
  }
`

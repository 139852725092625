import React from 'react';

import { object } from 'prop-types';

import { P1 } from '../Title';

const ProcessoJudicialResumoViewer = ({ data }) => (
  <>
    {data?.court?.map(({ name, count }) => (
      <P1 key={name}>
        <strong>{name}</strong>
        :
        {' '}
        {count}
      </P1>
    ))}
  </>
);

ProcessoJudicialResumoViewer.propTypes = {
  data: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ProcessoJudicialResumoViewer;

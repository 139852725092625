import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import Animate from '~/assets/animations/loader.json';
import { H4, P1 } from '~/components';

import { View, Wrapper } from './styles';

const lottie = {
  loop: true,
  autoplay: true,
  animationData: Animate,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid',
  },
};

const Loading = ({ ...rest }) => {
  const { t } = useTranslation('components');

  return (
    <View {...rest}>
      <Wrapper>
        <Lottie
          options={lottie}
          height="20.0rem"
        />

        <H4 isBold>{t('loading.title')}</H4>

        <P1 isBold>{t('loading.description')}</P1>
      </Wrapper>
    </View>
  );
};

export default Loading;

import React, { useRef, useState, useEffect } from 'react';
import { X, CheckCircle, ChevronDown } from 'react-feather';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { useField } from '@unform/core';
import {
  func,
  bool,
  oneOf,
  string,
  object,
  oneOfType,
} from 'prop-types';

import { SimpleSelect } from '~/components';
import { colors } from '~/styles';

import {
  Icon,
  View,
  Error,
  Target,
  Wrapper,
  Dropdown,
} from './styles';

export const Input = ({
  icon,
  size,
  name,
  label,
  reveal,
  prefix,
  options,
  textarea,
  onPrefix,
  disabled,
  className,
  hasPrefix,
  appearance,
  handleSubmit,
  maxLength = false,
  watchValueLength = null,
  ...rest
}) => {
  const [value, onValue] = useState('');
  const { t } = useTranslation('diligence');
  const ref = useRef(null);
  const { status } = colors;
  const {
    error,
    fieldName,
    clearError,
    defaultValue,
    registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if(watchValueLength) {
      watchValueLength(value.length)
    }
  }, [value])

  return (
    <View
      size={size}
      focus={value.length > 0}
      reveal={reveal}
      hasIcon={icon}
      disabled={disabled}
      className={className}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}

      <Wrapper isSubmit={!! handleSubmit}>
        {hasPrefix && (
          <Dropdown>
            <SimpleSelect
              icon={ChevronDown}
              value={prefix}
              options={options}
              onChange={(val) => onPrefix(val)}
              appearance="terciary"
            />
          </Dropdown>
        )}

        <Target
          {...rest}
          as={textarea ? 'textarea' : ReactInputMask}
          id={fieldName}
          ref={ref}
          error={error}
          prefix={hasPrefix.toString()}
          onFocus={clearError}
          textarea={textarea.toString()}
          onChange={(e) =>  {
            if(maxLength) {
              if(e.target.value.length <= maxLength) {
                onValue(e.target.value)
              }
            } else {
              onValue(e.target.value)
            }
          }}
          disabled={disabled}
          appearance={appearance}
          defaultValue={defaultValue}
        />

        {icon && <Icon>{icon}</Icon>}

        {handleSubmit && <CheckCircle onClick={handleSubmit} />}
      </Wrapper>
        {maxLength &&  (maxLength - value.length) > 5 && (
          <label >{t('seem.missing_characters')} { maxLength - value.length}</label>
        )} 
        {maxLength &&  (maxLength - value.length) <= 5 &&(
          <label  style={{ color: status.error}}>{t('seem.missing_characters')} { maxLength - value.length}</label>
        )
      }

      {error && <Error>{error}</Error>}
    </View>
  );
};

export const SimpleInput = ({
  icon,
  size,
  name,
  value,
  label,
  isCPF,
  reveal,
  onValue,
  textarea,
  isSearch,
  disabled,
  className,
  appearance,
  ...rest
}) => (
  <View
    size={size}
    focus={value.length > 0}
    reveal={reveal}
    hasIcon={icon}
    disabled={disabled}
    className={className}
  >
    {label && <label htmlFor={name}>{label}</label>}

    <Wrapper>
      <Target
        {...rest}
        id={name}
        value={value}
        disabled={disabled}
        appearance={appearance}
      />

      {(icon || isSearch) && (
        <Icon
          onClick={() => value && isSearch && onValue('')}
          isSearch={value && isSearch}
        >
          {value && isSearch ? <X /> : icon}
        </Icon>
      )}
    </Wrapper>

  </View>
);

const propTypes = {
  icon: oneOfType([bool, object]),
  size: oneOf(['xs', 'sm', 'md', 'lg']),
  name: string.isRequired,
  label: oneOfType([bool, string]),
  reveal: bool,
  prefix: oneOfType([bool, object]),
  textarea: bool,
  disabled: bool,
  onPrefix: oneOfType([bool, func]),
  hasPrefix: bool,
  className: string,
  appearance: oneOf(['primary', 'secondary']),
};

const defaultProps = {
  name: '',
  icon: false,
  size: 'sm',
  label: false,
  prefix: false,
  reveal: true,
  textarea: false,
  disabled: false,
  onPrefix: () => {},
  hasPrefix: false,
  className: '',
  appearance: 'primary',
};

Input.propTypes = { ...propTypes };

Input.defaultProps = { ...defaultProps };

SimpleInput.propTypes = { ...propTypes };

SimpleInput.defaultProps = {
  ...defaultProps,
  value: '',
};

import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v2`,
  headers: {
    locale: localStorage.getItem('i18nextLng') || 'pt',
    contentType: 'application/json',
    common: {
      Authorization: `Bearer ${localStorage.getItem('dd-token')}`,
    }
  },
});

api.interceptors.response.use((response) => {
  const refreshedToken = response.headers['rc-refreshed-token'];
  if (refreshedToken) {
    api.defaults.headers.common.Authorization = `Bearer ${refreshedToken}`;
    sessionStorage.setItem('dd-token', refreshedToken);
  }
  return response;
});

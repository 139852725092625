import { Form as Unform } from '@unform/web';
import styled from 'styled-components';

import { P2, H6, Layout } from '~/components';
import { rgba } from '~/hooks';
import { breakpoints, colors, typograph } from '~/styles';

const { weight, size } = typograph;
const { gray, primary, status } = colors;

export const View = styled(Layout)`
  margin-bottom: 4.8rem;

  .select {
    position: relative;
    z-index: 1000;
  }

  .error {
    color: ${status.error};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg.edit {
    top: 0.3rem;
    width: 16px;
    cursor: pointer;
    stroke: ${colors.gray._400};
    height: auto;
    position: relative;
    margin-left: 8px;
  }

  a {
    color: ${primary._000};
  }

  .hide-loading {
    tr:last-of-type {
      display: none;
    }

    tr:first-of-type {
      display: flex !important;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    thead {
      display: none;
    }
  }
`;

export const Seem = styled.div`
  display: grid;
  column-gap: 8.8rem;
  grid-template-columns: repeat(2, 1fr);

  & > div {
    display: flex;
    flex-direction: column;

    ${P2} {
      color: ${gray._500};

      span {
        color: ${gray._600};
        margin-left: 0.4rem;
      }

      & > p {
        color: ${primary._000};
        cursor: pointer;
        text-decoration: underline;
      }

      & > a {
        color: ${gray._500};
        margin: 1.6rem 0;
        display: flex;
        font-size: ${size.s2};
        align-items: center;

        svg {
          width: 1.6rem;
          height: 1.6rem;
          stroke: ${gray._500};
          margin-right: 1.2rem;
        }
      }

      &:not(:first-of-type) {
        margin-top: 1.6rem;
      }
    }
  }

  .header-title {
    * {
      display: inline-block;
      margin-left: 3.6rem;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;

    & > div:last-of-type {
      margin-top: 1.6rem;
    }
  }
`;

export const Risks = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 19.5rem);
  row-gap: 3rem;

  > div {
    width: 100%;

    :first-of-type {
      border-right: 0.2rem solid ${gray._200};
    }
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(auto-fit, 21rem);
    row-gap: 3rem;
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(auto-fit, 19.5rem);
  }

  @media screen and (max-width: ${breakpoints.md}) {
    row-gap: 2rem;
    grid-template-columns: repeat(auto-fit, 18rem);
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    row-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));

    > div {
      :first-of-type {
        border: 0;
      }
    }
  }
`;

export const Panel = styled.div`
  margin-bottom: 4.5rem;

  h6 {
    width: 100%;
    color: ${gray._600};
    padding: 2.4rem 0 1.6rem;
    text-align: left;
    font-size: ${size.s3};
    border-top: 0.1rem solid ${gray._200};
    font-weight: ${weight.semiBold};
  }

  table {
    width: 100%;
    border-spacing: 0;
    vertical-align: middle;

    thead th {
      height: auto;
      display: flex;
      align-items: center;
      background-color: ${gray._000} !important;

      & > th {
        height: auto;
        padding: 1rem 2.4rem;
      }
    }
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem 2rem;

  p {
    color: ${gray._500};
  }
`;

export const Diligences = styled.div`
  margin-top: 6rem;

  ${H6} {
    margin-bottom: 2.6rem;
    color: ${gray._500};
  }

  table thead {
    display: none;
  }
`;

export const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    margin: 0 auto 2rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, '.1')};

    svg {
      width: 4rem;
      height: 4rem;
      stroke: ${primary._000};
    }
  }

  & > ${H6} {
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
    text-align: left !important;
  }

  & > p {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      margin-top: 2.4rem;
    }
  }
  &.modal-sm{
    min-height: 20rem;
  }
  &.modal-md{
    min-height: 26rem;
  }
  &.modal-lg {
    min-height: 32rem;
  }
`;

export const Observation = styled(Unform)`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
`;

export const BatchOpening = styled(Unform)``;

export const Anex = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button {
    margin: 16px 10px;
  }
`;

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const RecommendationGoalContainer = styled.div`
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

import React, { useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';
import { oneOf } from 'prop-types';
import { useApi } from "~/hooks";

import {
  formatDocument, formatErrorMessage, mapAddress, maskMoney, parseISO, reduce,
} from '~/hooks/helpers';

import { useResultData } from '../../resultContext';
import GenericRegistrationData from './GenericRegistrationData';

const CompanyRegistrationData = ({ personType }) => {
  const { id: processID } = useParams();
  const { t } = useTranslation('entities');
  const { t: T } = useTranslation('components');
  const { t: tAuth } = useTranslation('auth');
  const { headers, entityNames } = useResultData();
  const alert = useAlert();
  const api = useApi();

  const filiais = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    let _filiais = [];

    if (! headers) {
      return _filiais;
    }

    const { entity } = headers;

    if (entity) {
      if (entity.filiais && entity.filiais.length > 0) {
        _filiais = entity.filiais;
      } else if (entity && entity.custom_data && entity.custom_data.length > 0) {
        entity.custom_data.forEach((customData) => {
          if (customData.type === 'FILIAL') {
            const obj = JSON.parse(customData.value);

            _filiais.push({
              company_name: obj.primaryName,
              document: '',
              state: '',
            });
          }
        });
      }
    }

    return _filiais;
  }, [headers]);

  const saveEditedField = async (url, data, successMessage, errorMessage) => {
    try {
      await api().post(url, data);
      alert.success(successMessage);
      return true;
    } catch ({ response }) {
      alert.error(formatErrorMessage(response, errorMessage,tAuth('expired')));
      return false;
    }
  };

  const data = [{
    label: t('cadastral_data.fields.company.0'),
    value: entityNames,
    editable: personType == 4 ? true: false,
    onSave: (company_name) => saveEditedField(`/process/${processID}/headers`, { company_name }, T('edit_process.company_name.success'), T('edit_process.company_name.error')),
  }, {
    label: t('cadastral_data.fields.company.1'),
    value: headers?.entity?.addresses.map((address) => mapAddress(T('items_list.address.tooltip'), address)),
  }, {
    label: t('cadastral_data.fields.company.2'),
    value: headers?.entity?.trade_name || t('cadastral_data.fields.not_found'),
    editable: personType == 4 ? true: false,
    onSave: (trade_name) => saveEditedField(`/process/${processID}/headers`, { trade_name }, T('edit_process.trade_name.success'), T('edit_process.trade_name.error')),
  }, {
    label: t('cadastral_data.fields.company.3'),
    value: (headers?.entity?.contact && reduce(headers?.entity?.contact, 30)) || t('cadastral_data.fields.not_found'),
    editable: true,
    onSave: (contact) => saveEditedField(`/process/${processID}/headers`, { contact }, T('edit_process.contact.success'), T('edit_process.contact.error')),
  }, {
    label: t('cadastral_data.fields.company.4'),
    value: formatDocument(headers?.entity?.document, personType) ?? t('cadastral_data.fields.not_found'),
    editable: personType == 4 ? true: false,
    onSave: (document) => saveEditedField(`/process/${processID}/headers`, { document }, T('edit_process.document.success'), T('edit_process.document.error')),
  }, {
    label: t('cadastral_data.fields.company.5'),
    value: headers?.entity?.email || t('cadastral_data.fields.not_found'),
    editable: true,
    onSave: (email) => saveEditedField(`/process/${processID}/headers`, { email }, T('edit_process.email.success'), T('edit_process.email.error')),
  }, {
    label: t('cadastral_data.fields.company.6'),
    value: (
      headers?.data?.new_dd
      ? headers?.entity?.supplier_code && reduce(headers?.entity?.supplier_code, 50)
      : headers?.data?.numerochamadointerno && reduce(headers?.data?.numerochamadointerno, 50)
    ) || t('cadastral_data.fields.not_found'),
    editable: headers?.data?.new_dd,
    onSave: (supplier_code) => saveEditedField(`/process/${processID}/headers`, { supplier_code }, T('edit_process.supplier_code.success'), T('edit_process.supplier_code.error')),
  }, {
    label: t('cadastral_data.fields.company.7'),
    value: headers?.entity?.website || t('cadastral_data.fields.not_found'),
  }, {
    label: t('cadastral_data.fields.company.8'),
    value: (() => {
      try {
        return format(parseISO(headers?.entity.founded_at), 'dd/MM/yyyy');
      } catch {
        return t('cadastral_data.fields.not_found');
      }
    })(),
  }, {
    label: t('cadastral_data.fields.company.9'),
    value: headers?.entity?.main_cnae?.codigo
      ? `${headers?.entity?.main_cnae?.codigo} / ${headers?.entity?.main_cnae?.descricao}`
      : t('cadastral_data.fields.not_found'),
  }, {
    label: t('cadastral_data.fields.company.10'),
    value: filiais.length > 0
      ? filiais.map(({ company_name, document, state }) => `${company_name}, CNPJ: ${formatDocument(document, personType)}, ${state})}`)
      : t('cadastral_data.fields.not_found'),
  }, {
    label: t('cadastral_data.fields.company.11'),
    value:
    headers?.entity?.share_capital
      ? maskMoney(headers?.entity.share_capital, 'BRL')
      : t('cadastral_data.fields.not_found'),
  }, {
    label: t('cadastral_data.fields.company.12'),
    value: headers?.entity?.legal_nature || t('cadastral_data.fields.not_found'),
  }, {
    label: t('cadastral_data.fields.company.13'),
    value: headers?.entity?.secondary_cnaes.map(({ codigo, descricao }) => `${codigo} ${descricao}`),
  },
  {
    label: t('cadastral_data.fields.company.14'),
    value: headers?.entity?.custom_data?.find((customData) => customData.type === 'SOBRE')?.value || t('cadastral_data.fields.not_found'),
  }];
  return <GenericRegistrationData data={data} />;
};

CompanyRegistrationData.propTypes = {
  personType: oneOf([2, 4]).isRequired, // PJ Nacional ou estrageiro
};

export default CompanyRegistrationData;

/* eslint-disable react/no-array-index-key */
import React from 'react';

import { arrayOf, string } from 'prop-types';

import { P1 } from '~/components';

/**
 * Popup viewer para as fontes de arquivos baixados
 * @param {object} props
 * @param {string[]} props.data
 */
const DownloadedFileViewer = ({ data }) => (
  <>
    {data.map((line, index) => <P1 key={index}>{line}</P1>)}
  </>
);

DownloadedFileViewer.propTypes = {
  data: arrayOf(string).isRequired,
};

export default DownloadedFileViewer;

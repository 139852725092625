import React from "react";
import { ChevronDown, Search as IconSearch } from "react-feather";

import { Input, Select, SimpleSelect } from "~/components";
import { useSearch } from "../../context/SearchProvider";

export const Simplified = () => {
  const { t, mask, risks, person, options, persons, onPerson } = useSearch();
  return (
    <div>
      <SimpleSelect
        icon={ChevronDown}
        value={person}
        options={options.person?.filter((item, index) => index <= 1)}
        onChange={(val) => onPerson(val)}
        isLoading={persons === undefined}
        placeholder={t("components.person")}
        isSearchable={false}
      />
      <Input
        name="key"
        icon={<IconSearch />}
        mask={mask[person?.value] || ""}
        disabled={person === undefined}
        placeholder={
          person?.value === 1 ? t("components.key.0") : t("components.key.1")
        }
      />

      <Select
        name="riskSource"
        icon={ChevronDown}
        isMulti
        options={options.allRisks}
        disabled={person === undefined}
        isLoading={risks === undefined}
        placeholder={t("components.risk_source")}
      />
    </div>
  );
};

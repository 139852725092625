import React, { memo, useEffect, useState } from "react";

import { Handle } from "react-flow-renderer";
import {
  NodeContainer,
  HeaderContainer,
  ButtonsContainer,
  InfoContainer,
  ButtonNode,
} from "./styles";
import { User, Briefcase } from "react-feather";
import {
  HiOutlineHome,
  HiOutlinePlusCircle,
  HiOutlineInformationCircle,
  HiCheck,
} from "react-icons/hi";
import { masked } from "~/hooks/helpers";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";
import { useMe } from "~/services";

export default memo(({ data }) => {
  const { user } = useMe();
  const [showInfo, setShowInfo] = useState(false);
  const [picked, setPicked] = useState(false);
  const [expandPartners, setExpandedPartners] = useState(data.expandedActive);
  const alert = useAlert();
  const { t } = useTranslation("search");
  function ClipboardButton(props) {
    function handleClick() {
      navigator.clipboard.writeText(JSON.stringify(props));
      alert.success(t("cadastral.data_copied_successfully"));
    }

    return (
      <button
        style={{
          height: "30px",
          margin: "15px 0",
          width: "200px",
          borderRadius: "3px",
          background: "#F6871F",
          color: "#FFFFFF",
        }}
        onClick={handleClick}
      >
        {t("cadastral.clipboard")}
      </button>
    );
  }
  return (
    <>
      <NodeContainer>
        <HeaderContainer>
          <span>
            {data.type === "PJ" ? (
              <Briefcase color="white" size={32} />
            ) : (
              <User color="white" size={32} />
            )}
          </span>

          <div>
            <h4>{data.label}</h4>
            <h5>
              {data.document > 11 ? "CNPJ:" : "CPF"} {masked(data.document)}
            </h5>
          </div>
        </HeaderContainer>

        {showInfo && (
          <InfoContainer>
            <h2>{t("cadastral.information")}</h2>
            {data.person ? (
              <p>{data.name}</p>
            ) : (
              <>
                {data.company.email && (
                  <p>
                    <strong>{t("cadastral.email")}: </strong>
                    {data.company.email}
                  </p>
                )}
                <p>
                  <strong>{t("cadastral.foundation_date")}: </strong>
                  {data.company.founded_at}
                </p>
                <p>
                  <strong>{t("cadastral.legal_nature")}: </strong>
                  {data.company.legal_nature}
                </p>
                <p>
                  <strong>{t("cadastral.main_activity")}: </strong>
                  {data.company.main_cnae?.descricao}
                </p>
                <p>
                  <strong>{t("cadastral.site")}: </strong>
                  {data.company.website}
                </p>
              </>
            )}
            <ClipboardButton
              index={1}
              name={data.name}
              website={data?.company?.website}
              mainActivity={data?.company?.main_cnae?.descricao}
              foundedAt={data?.company?.founded_at}
              legalNature={data?.company?.legal_nature}
              email={data?.company?.email}
            />
          </InfoContainer>
        )}

        <ButtonsContainer>
          {data.document < 11 && (
            <button>
              <div>
                <HiOutlineHome color="#cccccc" size={32} />
              </div>
              <p>{t("cadastral.relatives")}</p>
            </button>
          )}
          {!user?.isVisualizador() && (
            <button
              onClick={() => {
                setExpandedPartners(!data.expandedActive);
                data.expandPartners(
                  data.id.toString(),
                  data.document,
                  data.graphId,
                  data.expandedActive
                );
              }}
            >
              <div>
                {expandPartners ? (
                  <HiOutlinePlusCircle
                    color="#2B5D7E"
                    size={32}
                  ></HiOutlinePlusCircle>
                ) : (
                  <HiOutlinePlusCircle
                    color="#cccccc"
                    size={34}
                  ></HiOutlinePlusCircle>
                )}
              </div>
              <p>{t("cadastral.expand")}</p>
            </button>
          )}
          <button onClick={() => setShowInfo(!showInfo)}>
            <div>
              {showInfo ? (
                <HiOutlineInformationCircle color="#2B5D7E" size={32} />
              ) : (
                <HiOutlineInformationCircle color="#cccccc" size={32} />
              )}
            </div>
            <p>{t("cadastral.data")}</p>
          </button>
          {!user?.isVisualizador() && (
            <button
              onClick={() => {
                data.chooseEntity({
                  id: data.id,
                  name: data.label,
                  document: data.document,
                  type: data.type,
                });
                setPicked(!picked);
              }}
            >
              <div>
                {picked ? (
                  <HiCheck color="#2B5D7E" size={32}></HiCheck>
                ) : (
                  <HiCheck color="#cccccc" size={34}></HiCheck>
                )}
              </div>
              <p>{t("cadastral.select")}</p>
            </button>
          )}
        </ButtonsContainer>

        <Handle
          type="source"
          position="top"
          id="a"
          style={{ top: 10, background: "none", border: "none" }}
        />
      </NodeContainer>
    </>
  );
});

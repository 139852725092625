/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from 'react';
import { ChevronUp, ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { arrayOf, node as ReactNode } from 'prop-types';
import styled from 'styled-components';

import { colors, typograph } from '~/styles';

const ItemListView = styled.div`
  > ul {
    li {
      padding: .8rem .6rem .8rem 2.6rem;
      &:nth-child(odd) {
        background-color: ${colors.gray._100};
      }

      a.address {
        font-weight: ${typograph.weight.bold};
        &:hover {
          cursor: pointer;
          color: ${colors.support._000};
        }
      }
    }
  }
  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.8rem;
    padding: 0.5rem;
    color: ${colors.primary._000};
    font-size: ${typograph.size.s2};
    font-weight: ${typograph.weight.bold};
  }
  
`;

/**
 * @typedef {Object} ItemListProps
 * @property {import('react').ReactNode} data
 */

/**
 * @param {ItemListProps} props
 */
const ItemList = ({ data }) => {
  const { t } = useTranslation('components');
  const [show, setShow] = useState(false);

  const actualData = useMemo(() => (show ? data : [data[0]]), [show, data]);

  return (
    <ItemListView>
      <ul>
        {actualData.map((node, index) => <li key={index}>{node}</li>)}
      </ul>
      <button type="button" onClick={() => setShow(! show)}>
        {show ? (
          <>
            {t('items_list.button.less')}
            <ChevronUp size="2rem" />
          </>
        ) : (
          <>
            {t('items_list.button.more')}
            <ChevronDown size="2rem" />
          </>
        )}
      </button>
    </ItemListView>
  );
};

ItemList.propTypes = {
  data: arrayOf(ReactNode).isRequired,
};

export default ItemList;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';

import SettingsSection from '~/views/Administration/Settings/SettingsSection';
import { Grid } from '~/views/Dashboard/styles';

import sections from './sections';

const Settings = () => {
  const { t } = useTranslation('settings');

  return (
    <Grid>
      {sections(t).map((item, index) => (
        item.show
          ? (
            <SettingsSection
              key={index}
              label={item.label}
            >
              <item.content />
            </SettingsSection>
          )
          : null
      ))}
    </Grid>
  );
};

export default Settings;

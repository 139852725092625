import React, { useCallback, useRef, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  Button,
  Modal,
  P2,
  SimpleLoading,
  Upload,
} from "~/components";
import { useMe } from "~/services";
import { useTranslation } from "react-i18next";
import { useApi } from "~/hooks";
import { Form } from "@unform/web";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  CommentsWrapper,
  CommentRow,
  CommentBubble,
  CommentOwner,
  CommentOptions,
  CommentOptionsDropdown,
  CommentOptionsDropdownItem,
  CommentAttachment,
} from "./styles";
import { useAlert } from "react-alert";
import {
  ThemeAnalysis as CommentView,
  Anex as CommentActions,
  ModalView,
} from "../../styles";
import { MoreHorizontal, AlertCircle, Paperclip } from "react-feather";

const MAX_CHARACTERS = 2000;

const Comments = () => {
  const { id: processId } = useParams();
  const api = useApi();
  const toast = useAlert();
  const commentAttachmentRef = useRef();
  const { user } = useMe();
  const { t, i18n } = useTranslation("diligence");
  const [message, setMessage] = useState("");
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [updateCommentId, setUpdateCommentId] = useState(null);
  const [deleteCommentId, setDeleteCommentId] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [comments, setComments] = useState([]);
  const [isOpenable, setIsOpenable] = useState(true);

  const fetchComments = async () => {
    setIsValidating(true);
    try {
      const { data } = await api().get(`/process/${processId}/comments`);
      console.log(data)
      if (!!data?.message) {
        setIsOpenable(false)
      }

      if (data && data.comments?.length > 0) {
        setComments([...data.comments]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsValidating(false);
    }
  };

  const onOpen = useCallback((isOpened) => {
    if (isOpened) {
      fetchComments();
    }
  }, []);

  const handleClearCommentForm = () => {
    setMessage("");
    setAttachment(null);
    setUpdateCommentId(null);
    setDeleteCommentId(null);
  };

  const handleCreateComment = async () => {
    setIsLoading(true);
    try {
      const data = new FormData();
      data.append("message", message);

      if (attachment) {
        data.append("attachment", attachment, attachment?.name);
      }

      const { data: createdComment } = await api().post(
        `/process/${processId}/comments`,
        data
      );

      setComments((prevState) =>
        [...prevState].concat([createdComment.comment])
      );

      handleClearCommentForm();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditComment = (comment) => {
    setUpdateCommentId(comment.id);
    setMessage(comment.message);
  };

  const handleUpdateComment = async (commentId) => {
    setIsLoading(true);
    try {
      const data = new FormData();
      data.append("_method", "PUT");
      data.append("message", message);

      if (attachment) {
        data.append("attachment", attachment, attachment?.name);
      }

      const { data: updatedComment } = await api().post(
        `/process/${processId}/comments/${commentId}?_method=PUT`,
        data
      );

      setComments((prevState) => {
        const index = prevState.findIndex(
          (comment) => comment.id === commentId
        );

        if (index > -1)
          prevState.splice(index, 1, { ...updatedComment.comment });

        return prevState;
      });

      handleClearCommentForm();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (!deleteCommentId || deleteCommentId !== commentId) {
      setDeleteCommentId(commentId);
      return;
    }

    setIsLoading(true);
    try {
      await api().delete(`/process/${processId}/comments/${deleteCommentId}`);

      setComments((prevState) => {
        const index = prevState.findIndex(
          (comment) => comment.id === deleteCommentId
        );

        if (index > -1) prevState.splice(index, 1);

        return prevState;
      });

      setDeleteCommentId(null);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAttachmentSubmit = ({ upload }) => {
    setShowAttachmentModal(false);

    if (!upload) {
      return;
    }

    setAttachment(upload);
  };

  const handleSubmit = async () => {
    if (updateCommentId) {
      await handleUpdateComment(updateCommentId);
      return;
    }

    await handleCreateComment();
  };

  const attachmentButtonLabel = useMemo(() => {
    return attachment?.name
      ? `Remover anexo: ${attachment?.name}`
      : t("comments.attach");
  }, [attachment]);

  const charactersRemaining = useMemo(() => {
    return (
      MAX_CHARACTERS -
      message.replace(/<[^>]*>/g, "").replace("&nbsp;", " ").length
    );
  }, [message]);

  const handleAttachmentButtonAction = () => {
    if (!attachment) {
      setShowAttachmentModal(true);
      return;
    }

    setAttachment(false);
  };

  const compactAttachmentName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) {
      return fileName;
    }

    const parts = fileName.split(".");
    const extension = parts.pop();
    const name = parts.join(".");

    const charsToShow = maxLength - 3 - extension.length;
    const startChars = Math.ceil(charsToShow / 2);
    const endChars = Math.floor(charsToShow / 2);

    const compactedName =
      name.slice(0, startChars) +
      "...." +
      name.slice(-endChars) +
      "." +
      extension;

    return compactedName;
  };

  const handleAttachmentDownload = async (attachment) => {
    try {
      const { data } = await api().get(attachment.url, {
        responseType: "blob",
      });

      const urlBlob = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = urlBlob;
      link.setAttribute("download", attachment.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.error(e.response);
      toast.error(
        "Houve um erro ao abrir o arquivo, tente novamente mais tarde."
      );
    }
  };

  const handleEditorChange = (_, editor) => {
    const data = editor.getData();
    const onlyText = data.replace(/<[^>]*>/g, "").replace("&nbsp;", " ");

    if (onlyText.length <= MAX_CHARACTERS) {
      setMessage(data);
      return;
    }

    editor.setData(data.substring(0, MAX_CHARACTERS));
  };

  const CkEditorSetting = {
    language: i18n.language === "pt" ? "pt-br" : i18n.language,
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "aligment",
        "|",
        "bold",
        "italic",
        "numberedList",
        "outdent",
        "indent",
        "removeFormat",
      ],
      height: "500",
      shouldNotGroupWhenFull: true,
    },
  };

  return (
    <>
      <Accordion
        isOpenable={isOpenable}
        onChange={onOpen}
        columns={[{ type: "TEXT", payload: t("comments.title") }]}
      >
        {isValidating ? (
          <SimpleLoading />
        ) : (
          <>
            <CommentsWrapper>
              {comments?.map((comment) => (
                <CommentRow id={comment.id}>
                  <CommentBubble
                    selected={updateCommentId && updateCommentId === comment.id}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: comment.message }}
                    />
                    {comment.attachment && (
                      <CommentAttachment
                        href="javascript:;"
                        onClick={() =>
                          handleAttachmentDownload(comment.attachment)
                        }
                      >
                        <Paperclip size="12px" />
                        <span>
                          {compactAttachmentName(comment.attachment.name, 50)}
                        </span>
                      </CommentAttachment>
                    )}
                  </CommentBubble>
                  <CommentOwner>
                    <span>
                      <strong>{comment.user.name}</strong> -{" "}
                      {comment.created_at}
                    </span>
                    {user?.isMaster() || user?.isGestor() ? (
                      <CommentOptions title="Opções">
                        <MoreHorizontal size="14px" />
                        <CommentOptionsDropdown>
                          <CommentOptionsDropdownItem
                            disabled={isLoading}
                            onClick={() => handleEditComment(comment)}
                          >
                            Editar
                          </CommentOptionsDropdownItem>
                          <CommentOptionsDropdownItem
                            disabled={isLoading}
                            onClick={() => handleDeleteComment(comment.id)}
                          >
                            {deleteCommentId &&
                            deleteCommentId === comment.id ? (
                              <div
                                style={{
                                  color: "red",
                                  fontWeight: "600",
                                  whiteSpace: "nowrap",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <AlertCircle size="14px" /> Deletar, tem certeza
                                ?
                              </div>
                            ) : (
                              "Deletar"
                            )}
                          </CommentOptionsDropdownItem>
                        </CommentOptionsDropdown>
                      </CommentOptions>
                    ) : null}
                  </CommentOwner>
                </CommentRow>
              ))}

              {comments.length === 0 && (
                <P2>Não há comentários adicionais para exibir</P2>
              )}
            </CommentsWrapper>
            {user?.isMaster() || user?.isGestor() ? (
              <>
                <CommentView>
                  <div style={{ fontSize: "14px" }}>
                    <CKEditor
                      id="ck-additional-comments"
                      name="comment"
                      editor={ClassicEditor}
                      data={message}
                      config={CkEditorSetting}
                      onChange={handleEditorChange}
                    />
                    {charactersRemaining > 0 && (
                      <label
                        style={{
                          color: "#7F859D",
                          fontSize: "1.2rem",
                        }}
                      >
                        {t("seem.missing_characters")} {charactersRemaining}
                      </label>
                    )}
                    {charactersRemaining <= 0 && (
                      <label style={{ color: "red", fontSize: "1.2rem" }}>
                        {t("seem.missing_characters")} {0}
                      </label>
                    )}
                  </div>
                </CommentView>
                <CommentActions>
                  {updateCommentId && (
                    <Button
                      label={t("comments.cancel")}
                      onClick={handleClearCommentForm}
                      loading={isLoading}
                      appearance="tertiary"
                      style={{ marginRight: "auto" }}
                    />
                  )}
                  <Button
                    label={attachmentButtonLabel}
                    onClick={handleAttachmentButtonAction}
                    loading={isLoading}
                    appearance="tertiary"
                  />
                  <Button
                    onClick={handleSubmit}
                    label={t("comments.submit")}
                    loading={isLoading}
                    disabled={!message}
                  />
                </CommentActions>
              </>
            ) : null}
          </>
        )}
      </Accordion>
      {showAttachmentModal && (
        <Modal
          title="Anexar arquivo"
          show
          onShow={setShowAttachmentModal}
          action={{
            label: t("include_item.modal.action"),
            isLoading,
            onClick: () => {
              if (commentAttachmentRef.current) {
                commentAttachmentRef.current.submitForm();
              }
            },
          }}
          loading={isLoading}
        >
          <ModalView className="modal-sm">
            <Form ref={commentAttachmentRef} onSubmit={handleAttachmentSubmit}>
              <Upload
                name="upload"
                label={t("include_item.modal.upload")}
                isDocument
              />
            </Form>
          </ModalView>
        </Modal>
      )}
    </>
  );
};

export default Comments;

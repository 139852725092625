import { format } from 'date-fns';
import { maskMoney } from '~/hooks/helpers';

export const formatDate = (obj) => {
  try {
    return format(new Date(obj), 'dd/MM/yyyy');
  } catch {
    return null;
  }
};

export const formatValue = (value, t) => (! value || value < 0 ? t('lawsuits.lawsuit.value.unavailable') : maskMoney(value));

export const formatOtherSubjects = (value) => {
  value = value ?? '-';
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
};

export const generateLine = ({
  key, tPrefixKey, formatData, customData, firstColumnProps, secondColumnProps, t, data
}) => {
  const fcProps = firstColumnProps || {};
  const scProps = secondColumnProps || {};
  let actualData = customData || data;

  let tKey = key;

  if (tPrefixKey) {
    tKey = `${tPrefixKey}.${key}`;
  }
  
  if(Array.isArray(actualData[key])) {
    actualData[key] = actualData[key].join(', ');
  }

  return [
    {
      text: t(tKey),
      ...fcProps,
    },
    {
      text: (formatData ? formatData(actualData[key]) : actualData[key]) ?? '-',
      ...scProps,
    },
  ];
};

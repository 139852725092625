import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { H4 } from '~/components';
import {
  breakpoints, colors, easing, typograph,
} from '~/styles';

const { gray, primary, green} = colors;

const { basic, rubber } = easing;

const { size, weight } = typograph;

export const Heading = styled(NavLink)`
  min-height: 3.6rem;
  display: flex;
  align-items: center;

  ${H4} {
    color: ${gray._400};
    font-size: ${size.m2};
    transition: all 0.3s ${basic};
    white-space: nowrap;
    font-weight: ${weight.regular};

    &::after {
      width: 0;
      height: 0.2rem;
      display: flex;
      content: "";
      margin-top: 1.2rem;
      transition: all 0.6s ${rubber};
      background-color: transparent;
    }
  }

  ${({ type }) => type === 'true'
    && css`
      ${H4} {
        color: ${primary._000};
        font-size: ${size.m3};
        font-weight: ${weight.semiBold};

        &::after {
          width: 48%;
          background-color: ${green._500};
        }
      }
    `}
`;

export const View = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  ${Heading}:not(:first-child) {
    margin-left: 4rem;
  }

  & > div {
    width: 100%;
    margin-left: auto;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    white-space: nowrap;

    & > div,
    ${Heading} {
      margin: 0 0 1.6rem !important;
      display: flex;
      justify-content: flex-start;
      font-size: ${size.s3};

      :nth-of-type(even) {
        padding-left: 3.2rem;
      }
    }
  }
`;

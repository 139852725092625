import { useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { useAuth } from '~/services';

const useIdleLogout = () => {
  const maxIdleTime = process.env.REACT_APP_MAX_IDLE_TIME_IN_MINUTES || 15;
  const waitTimeBeforeKick = process.env.REACT_APP_WAIT_SECONDS_BEFORE_KICK || 300;

  const [showIdleAlert, onShowIdleAlert] = useState(false);
  const [timeLeft, setTimeLeft] = useState(waitTimeBeforeKick);
  const [stopwatchEnabled, setStopwatchEnabled] = useState(false);
  const [intervalID, setIntervalID] = useState(null);

  const { logout, isAuthenticated } = useAuth();

  const timer = useIdleTimer({
    timeout: 1000 * maxIdleTime * 60,
    onIdle: () => { setStopwatchEnabled(true); onShowIdleAlert(true); },
    onActive: () => { setStopwatchEnabled(false); onShowIdleAlert(false); },
    debounce: 500,
  });

  const formattedTime = useMemo(() => {
    const minutes = parseInt(timeLeft / 60, 10);
    const seconds = parseInt(timeLeft % 60, 10);
    return `${minutes > 9 ? `${minutes}` : `0${minutes}`}:${seconds > 9 ? `${seconds}` : `0${seconds}`}`;
  }, [timeLeft]);

  useEffect(() => {
    if (! isAuthenticated) {
      timer.reset();
      timer.pause();
    } else {
      timer.resume();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (stopwatchEnabled) {
      const newIntervalID = setInterval(() => {
        setTimeLeft((old) => {
          const newTime = old - 1;

          if (newTime <= 0) {
            clearInterval(intervalID);
            return 0;
          }

          return newTime;
        });
      }, 1000);
      setIntervalID(newIntervalID);
    } else {
      clearInterval(intervalID);
      setTimeLeft(waitTimeBeforeKick);
      setIntervalID(null);
    }
    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopwatchEnabled]);

  useEffect(() => {
    if (timeLeft <= 0) {
      setStopwatchEnabled(false);
      onShowIdleAlert(false);
      logout();
    }
  }, [timeLeft]);

  return { showIdleAlert, onShowIdleAlert, formattedTime };
};

export default useIdleLogout;

import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

import { useMe } from "~/services";
import { Button } from "~/components";
import { useApi, validate } from "~/hooks";

import { useSearch } from "../../../context/SearchProvider";
import { Simplified } from "../../Simplified";

import { Search } from "../../styles";

export const SimplifiedHeader = () => {
  const api = useApi();
  const { user } = useMe();
  const alert = useAlert();
  const navigate = useNavigate();

  const { t, ref, query, person, loading, onLoading } = useSearch();

  const [selectedDocument, onSelectedDocument] = useState(null);

  const submit_quick = async (data) => {
    const verify = validate(data.key);

    if (person?.value === 1 && !verify) {
      alert.error(t("alerts.cpf"));

      return;
    }

    onLoading(true);
    try {
      const res = await api().post("/simplesearch", {
        ...data,
        key:
          data?.key?.replace(/\D/g, "") ||
          selectedDocument?.value?.replace(/\D/g, ""),
        clientId: user.hierarquia[0].id,
        entityType: person.value,
        jurisdiction: data.jurisdiction || undefined,
      });
      navigate(`/pesquisa/simplificada/${res.data.busca_id}`);
    } catch (error) {
      alert.error(error.response.data.message);
    }
    onLoading(false);
  };

  return (
    <Search ref={ref} type={query} onSubmit={submit_quick}>
      <Simplified />
      <Button
        size="sm"
        submit
        label={t("components.search")}
        loading={loading}
        disabled={person === undefined || person === null}
        appearance="primary"
      />
    </Search>
  );
};

import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import {
  Button, P1,
} from '~/components';
import RiskSourceSelector from '~/components/RiskSourceSelector';
import { useSelectedRiskSource } from '~/components/RiskSourceSelector/selectedRiskSourcesContext';
import { useApi, useFetch } from '~/hooks';

import { Card, FABContainer } from './styles';

const { Grid } = require('~/views/Dashboard/styles');

const RiskSources = () => {
  const { t } = useTranslation('admin');
  const [isSubmitting, setSubmitting] = useState(false);
  const alert = useAlert();
  const api = useApi();

  const { selectedRiskSources, setSelectedRiskSources } = useSelectedRiskSource();

  const { data: availableRiskSources, isValidating: isValidatingRiskSources } = useFetch({ url: '/risksource' });
  const { data: requestData, isValidating: isValidatingConfigs } = useFetch({ url: '/settings?type=HIDDEN_RISK_SOURCES' });

  useEffect(() => {
    let hiddenSources = [];

    try {
      const { groupValue } = JSON.parse(requestData?.data[0].data);
      if (groupValue) {
        hiddenSources = [...groupValue];
      }
    } catch { /* empty */ }

    if (availableRiskSources?.length > 0) {
      setSelectedRiskSources(
        availableRiskSources
          .filter(({ id }) => ! hiddenSources.includes(id))
          .map(({
            id, theme_id, price, person_type, theme_name,
          }) => ({
            id, theme_id, price, person_type, theme_name,
          })),
      );
    }
  }, [requestData, availableRiskSources, setSelectedRiskSources]);

  const submit = async () => {
    setSubmitting(true);

    const selectedIDs = selectedRiskSources.map(({ id }) => id);
    const settingData = {
      groupValue: availableRiskSources
        .filter(({ id }) => ! selectedIDs.includes(id))
        .reduce((acc, { id }) => [...acc, id], []),
    };

    const requestBody = {
      type: 'HIDDEN_RISK_SOURCES',
      data: JSON.stringify(settingData),
    };

    try {
      await api().post('/settings', requestBody);
      alert.success(t('risk-sources.saved-succesfully'));
    } catch (ex) {
      alert.error(ex.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid>
      <Card>
        <div>
          <P1>{t('risk-sources.info')}</P1>
          <RiskSourceSelector
            availableRiskSources={ availableRiskSources }
            isValidating={ isValidatingRiskSources || isValidatingConfigs }
          />
          <FABContainer>
            <Button loading={isSubmitting} onClick={submit} label={t('risk-sources.save')} />
          </FABContainer>
        </div>
      </Card>
    </Grid>
  );
};

export default RiskSources;

import styled from 'styled-components';
import { colors, easing, typograph } from '~/styles';

const { gray, status, primary } = colors;
const { basic } = easing;
const { family } = typograph;

export const Container = styled.div`
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 2rem;
  -webkit-box-shadow: 0px 0px 5px rgb(182 186 205);
  -moz-box-shadow: 0px 0px 5px rgb(182, 186, 205);
  box-shadow: 0px 0px 5px rgb(182 186 205);
`;

export const Header = styled.h1`
  font-weight: 600;
  font-size: 2rem;
  padding: 1rem;
  color: ${ primary._000 };
`;

export const HR = styled.div`
  margin-top: -3rem;
  border-bottom: 0.2rem solid ${gray._200};
`

export const Row = styled.div`
  font-family: ${family};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 20px;
  column-gap: 20px;
  margin: 3rem 0;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 1rem;

  > thead * {
    font-size: 1.8rem;
    color: ${ gray._500 };
  }

  > tbody * {
    font-size: 1.3rem;
    text-align: center;
    padding: 0.8rem 0;
  }

  thead th:nth-child(1), tbody td:nth-child(1) {
    width: ((100%/12) * 2)%;
  }

  thead th:nth-child(2), tbody td:nth-child(2) {
    width: ((100%/12) * 1)%;
  }

  thead th:nth-child(3), tbody td:nth-child(3) {
    width: ((100%/12) * 1)%;
  }

  thead th:nth-child(4), tbody td:nth-child(4) {
    width: ((100%/12) * 1)%;
  }

  thead th:nth-child(5), tbody td:nth-child(5) {
    width: ((100%/12) * 1)%;
  }

  thead th:nth-child(6), tbody td:nth-child(6) {
    width: ((100%/12) * 1)%;
  }

  thead th:nth-child(7), tbody td:nth-child(7) {
    width: ((100%/12) * 3)%;
  }

  thead th:nth-child(8), tbody td:nth-child(8) {
    width: ((100%/12) * 1)%;
  }

  thead th:nth-child(9), tbody td:nth-child(9) {
    display: flex;
    justify-content: center;
    width: ((100%/12) * 1)%;
  }
  
  td.action > * {
    display: flex;
    align-items: center;
    
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 3.6rem;
      cursor: pointer;
      height: 3.6rem;
      padding: 0;
      transition: all 0.3s ${ basic };
      border-radius: 50%;
      background-color: transparent;
      
      &.delete {
        color: ${ status.error };
        border: 0.1rem solid ${ status.error };

        &:hover {
          color: ${ gray._000 };
          background-color: ${ status.error };
        }
      }
    }
  }
  
  & .hide {
    opacity: 0;
    cursor: default !important;
  }

  & .icon-search {
    color: ${gray._500};
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
    min-width: 1.4rem;
    margin-left: 0.8rem;
  }

  .toggle {
    width: 25rem;
    justify-content: flex-start;
  }

  .link {
    flex: 0;
    & a {
      color: gray;
    }
  }

  .modified {
    span > span {
      color: ${status.error};
    }
  }
`;

import styled from 'styled-components';

import { H4, P2 } from '~/components/Title';
import { rgba } from '~/hooks';
import { colors, typograph } from '~/styles';

const {
  gray,
} = colors;

const {
  weight,
} = typograph;

const View = styled.div`
  display: flex;
  margin-top: 4.8rem;
  align-items: center;
  flex-direction: column;
  transform: ${({ hasButton }) => ! hasButton && 'translateY(-3.0rem)'};

  & > span,
  & > span > span {
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgba(gray._500, '.05');
  }

  & > span {
    width: 12.0rem;
    height: 12.0rem;

    & > span {
      width: 8.4rem;
      height: 8.4rem;

      svg {
        color: ${gray._500};
        width: 4.8rem;
        height: 4.8rem;
      }
    }
  }

  ${H4} {
    color: ${gray._500};
    margin: 3.2rem 0 1.2rem 0;
    text-align: center;
    font-weight: ${weight.semiBold};
  }

  ${P2} {
    color: ${gray._500};
  }

  & > button {
    width: fit-content;
    margin-top: 5.2rem;
  }
`;

export default View;

import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';
import { Calendar } from 'react-feather';

import { useField } from '@unform/core';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import {
  func, bool, string, oneOfType, instanceOf,
} from 'prop-types';

import { SimpleInput } from '~/components';

import Header from './Header';
import { View } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt', ptBr);
setDefaultLocale('pt', ptBr);

export const DatePicker = ({
  name,
  label,
  appearance,
  placeholder,
  reveal,
  ...rest
}) => {
  const datepickerRef = useRef(null);

  const {
    error, fieldName, defaultValue, registerField,
  } = useField(name);

  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
      setValue: (e, v) => setDate(v),
      getValue: () => datepickerRef.current.props.selected,
    });
  }, [fieldName, registerField]);

  return (
    <View hasLabel={label} reveal={reveal}>
      <SimpleInput
        icon={<Calendar />}
        label={label}
        error={error}
        value={(() => {
          try {
            return format(date, 'dd/MM/yyyy');
          } catch {
            return defaultValue;
          }
        })()}
        readOnly
        onClick={() => datepickerRef.current.setOpen(true)}
        appearance={appearance}
        placeholder={placeholder}
      />

      <ReactDatePicker
        {...rest}
        ref={datepickerRef}
        selected={date}
        onChange={setDate}
        renderCustomHeader={(props) => <Header {...props} />}
      />
    </View>
  );
};

export const SimpleDatePicker = ({
  id,
  name,
  date,
  label,
  onDate,
  isRange,
  reveal,
  endDate,
  selected,
  onEndDate,
  appearance,
  placeholder,
  ...rest
}) => {
  const ref = useRef(null);

  const onChange = (dates) => {
    const [start, end] = dates;

    onDate(start);
    onEndDate(end);
  };

  // eslint-disable-next-line
  const formated = date && endDate && ! selected? `${format(date, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}` : selected || date ? `${format(selected || date, 'dd/MM/yyyy')}` : '';

  return (
    <View hasLabel={label} reveal={reveal}>
      <SimpleInput
        icon={<Calendar />}
        label={label}
        value={formated}
        readOnly
        onClick={() => ref.current.setOpen(true)}
        appearance={appearance}
        placeholder={placeholder}
      />

      <ReactDatePicker
        {...rest}
        id={id}
        ref={ref}
        onChange={isRange ? onChange : onDate}
        selected={date}
        startDate={date}
        endDate={endDate}
        selectsRange={isRange}
        renderCustomHeader={(props) => <Header {...props} />}
        shouldCloseOnSelect={! isRange}
      />
    </View>
  );
};

const propTypes = {
  name: string.isRequired,
  label: oneOfType([bool, string]),
  appearance: string,
  placeholder: string,
  reveal: bool,
};

const defaultProps = {
  label: '',
  appearance: 'secondary',
  placeholder: '',
  reveal: true,
};

DatePicker.propTypes = { ...propTypes };
DatePicker.defaultProps = { ...defaultProps };

SimpleDatePicker.propTypes = {
  id: string,
  date: oneOfType([string, instanceOf(Date)]),
  onDate: func,
  isRange: bool,
  ...propTypes,
};
SimpleDatePicker.defaultProps = {
  id: '',
  date: Date,
  onDate: () => {},
  isRange: false,
  ...defaultProps,
};

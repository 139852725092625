import React from 'react';
import { useTranslation } from 'react-i18next';
import { H4 } from '~/components';
import { View, Heading } from './styles';
import { useMe } from '~/services';

import {
  bool,
  shape,
  string,
  element,
  arrayOf,
  oneOfType,
} from 'prop-types';

const Header = ({
  head, query, children, ...rest
}) => {
  const { user } = useMe();
  const { t } = useTranslation('routes');

  return (
    <View { ...rest }>
      { head?.map(({ route, label }) => {
        const typed = query === route;

        const filtered = () => {
          let filter = false;
          if(!user?.isMaster()
            && ( label === t('routes.administration.childs.users')
              || label === t('routes.administration.childs.pricing')
              || label === 'CNAEs' )) {
            filter = true;
          }
          if (user?.isVisualizador() && (
            label === t('routes.administration.childs.tags')||
            label === t('routes.administration.childs.workflows')||
            label === t('routes.administration.childs.media-terms')||
            label === t('routes.administration.childs.risk-sources')
            )
          ) {
            filter = true;
          }
          if (user?.isRevisor() && (
            label === t('routes.administration.childs.workflows')||
            label === t('routes.administration.childs.media-terms')||
            label === t('routes.administration.childs.risk-sources')
            )
          ) {
            filter = true;
          }
          if (user?.isGestor() && (
            label === t('routes.administration.childs.media-terms')||
            label === t('routes.administration.childs.risk-sources')
            )
          ) {
            filter = true;
          }
          return filter;
        };

        return ( filtered() ? '' : (
            <Heading
              to={{ search: `?q=${ route }` }}
              key={ route }
              type={ typed.toString() }
            >
              <H4>{ label }</H4>
            </Heading>
          )
        );
      }) }

      { children }
    </View>
  );
};

Header.propTypes = {
  head: arrayOf(shape({
    route: string,
    label: string,
  })).isRequired,
  query: string.isRequired,
  children: oneOfType([ bool, element ]),
};

Header.defaultProps = {
  children: false,
};

export default Header;

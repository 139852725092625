import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next"; 
import { P2, Modal, Button, Loading, Checkbox } from "~/components";
import { useMe } from "~/services";
import { useApi, useFetch } from "~/hooks";
import { Container, CheckboxWrapper } from '~/views/Administration/Settings/TagSettings/style'


const TagSettings = () => {
    const { t } = useTranslation("settings"); 
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [clientId, setClientId] = useState(undefined)
    const [groupId, setGroupId] = useState(undefined)
    const [fetchUrl, setFetchUrl] = useState(undefined)
    const api = useApi();
    const alert = useAlert();
    const { user, group } = useMe();
    const baseUrl = '/settings/tag-replication'


    useEffect(() => {
        setClientId(group.value);
        setGroupId(user.grupos[0]?.idgrupo);

        setFetchUrl(() => {

            let url = baseUrl

            if (group.value) {
                url = `${url}?client_id=${group.value}`
            } else if (user.grupos[0]?.idgrupo) {
                url = `${url}?group_id=${user.grupos[0]?.idgrupo}`
            }
        
            return url
        })

    }, [group]);

    const { 
        isValidating,
        data: fetchResponse,
    } = useFetch({
        url: fetchUrl,
    });

    useEffect(() => {
        if (fetchResponse) {
            const { data } = fetchResponse

            setChecked(data.enabled ?? false)
        }
    }, [fetchResponse])

    const handleClick = async () => {
        setLoading(true)

        try {

            let payload = {
                enabled: checked
            }

            if(clientId) {
                payload = {
                    ...payload,
                    client_id: clientId
                }
            } else if (groupId) {
                payload = {
                    ...payload,
                    group_id: groupId
                }
            }

            await api().post(baseUrl, payload)

            alert.success(t(checked ? 'tag.success_activate' : 'tag.success_disable'));

        } catch(e) {
            alert.error(e)
        } finally {
            setLoading(false)
        }
    }

    return ( 
        <Container>
            <CheckboxWrapper>
                <Checkbox
                    key={1}
                    id={1}
                    defaultChecked={checked}
                    label={t("tag.checkBox")}
                    onChange={(e) => setChecked(e.target.checked)}
                />
                <Button
                    onClick={handleClick}
                    loading={loading}
                    label={t("tag.button")}
                />
            </CheckboxWrapper>
        </Container> 
    );
}

export default TagSettings;
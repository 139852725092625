import React from "react";
import { ChevronDown, Plus, Search as IconSearch } from "react-feather";

import { useMe } from "~/services";
import { Button, Input, Modal, P2, Select, SimpleSelect } from "~/components";
import { AssociateModal } from "../AssociateModal";
import { useSearch } from "../../context/SearchProvider";

import { BatchSearch } from "../styles";

export const Batches = () => {
  const { user } = useMe();

  const {
    t,
    ref,
    tag,
    tags,
    setTag,
    onShow,
    person,
    options,
    persons,
    onModal,
    uploadRef,
    workflow,
    onPerson,
    workflows,
    onWorkflow,
    setChecked,
    jurisdiction,
    hasJurisdiction,
    loadingBatches,
    setShowAttechmentModal,
  } = useSearch();

  return (
    <BatchSearch>
      <div>
        <SimpleSelect
          icon={ChevronDown}
          value={person}
          options={options.person}
          onChange={(val) => {
            onPerson(val);
            onWorkflow([]);
            setChecked(false);
            ref.current.setFieldValue("workflow", []);
            ref.current.setFieldValue("personWorkflows", []);
            ref.current.setFieldValue("entityWorkflows", []);
            ref.current.setFieldValue("jurisdiction", []);
          }}
          isLoading={!persons}
          placeholder={t("components.person")}
          isSearchable={false}
        />

        {hasJurisdiction && (
          <Select
            name="jurisdiction"
            icon={IconSearch}
            isLoading={!jurisdiction}
            options={options.jurisdiction}
            placeholder={t("components.jurisdiction")}
          />
        )}

        <Select
          name="workflow"
          icon={ChevronDown}
          options={options.workflows}
          disabled={!person}
          onChange={(val) => {
            onShow(false);
            onWorkflow(val);
          }}
          isLoading={!workflows}
          placeholder={t("components.workflows")}
        />

        <SimpleSelect
          icon={ChevronDown}
          value={tag}
          options={options.tags}
          disabled={!person}
          onChange={(val) => setTag(val)}
          isLoading={!tags}
          placeholder={t("components.tag.0")}
          addTags={{
            icon: <Plus />,
            label: t("components.tag.1"),
            onClick: () =>
              onModal({
                type: "tags",
                reveal: true,
              }),
          }}
          clearField={{
            label: t("components.tag.2"),
            onClick: () => setTag(""),
          }}
        />

        <Input name="lines" placeholder={t("components.lines")} type="number" />
      </div>

      <div className={"button"}>
        {(user.isMaster() || user.canOpenBatches()) && (
          <Button
            size="sm"
            label={t("batches.actions.button.uploading")}
            appearance="secondary"
            onClick={() => {
              uploadRef.current.reset();
              setShowAttechmentModal(true);
            }}
            disabled={loadingBatches}
          />
        )}

        <Button
          size="sm"
          submit
          label={t("batches.actions.button.spreadsheet")}
          loading={loadingBatches}
          disabled={!workflow || workflow?.length <= 0 || !person}
          appearance="secondary"
        />
        <AssociateModal />
      </div>
    </BatchSearch>
  );
};

import React from 'react';

import { oneOf } from 'prop-types';

import { Bar, View } from './styles';

const Spinner = ({
  size,
  appearance,
}) => (
  <View
    size={size}
    appearance={appearance}
  >
    <Bar />
  </View>
);

Spinner.propTypes = {
  size: oneOf(['sm', 'lg']),
  appearance: oneOf(['primary', 'secondary']),
};

Spinner.defaultProps = {
  size: 'sm',
  appearance: 'primary',
};

export default Spinner;

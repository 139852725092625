import styled, { css } from 'styled-components';

import { P2 } from '~/components/Title';
import { colors, typograph } from '~/styles';

const {
  gray,
  status: state,
  primary,
  support,
} = colors;

const { size: font } = typograph;

const main = [
  'Gestor',
  'Answered',
  'Corrupção',
  'Ambiental',
  'Respondido',
  'Em análise',
  'ICTS Analysis',
  'Pending answer',
  'Em análise ICTS',
  'Pendente conexão',
  'En análisis ICTS',
  'Pendente resposta',
  'Pendiente respuesta',
  'Disponível para análise',
];

const error = [
  'Erro',
  'Error',
  'Canceled',
  'Reproved',
  'Reprovada',
  'Cancelada',
  'Cancelado',
  'Rechazado',
  'Suspenso',
  'Suspended',
  'Suspendido',
];

const success = [
  'Imagem',
  'Fiscal',
  'Aprobado',
  'Approved',
  'Aprovada',
  'Executor',
  'Concluido',
  'Concluído',
  'Concluded',
  'Aprobado con reservas',
  'Aprovada com ressalvas',
  'Approved with reservations',
];

const secondary = [
  'Open',
  'Aberto',
  'Abierto',
  'Processing',
  'Procesando',
  'Processando',
  'Trabalhista',
  'Em andamento',
  'Visualizador',
  'Esperando revisión',
  'Aguardando análise',
  'Waiting for analysis',
];

const terciary = [
  'Financeiro',
  'Internacionais',
];

const View = styled(P2)`
  width: fit-content;
  color: ${gray._500};
  margin: .4rem .2rem !important;
  padding: ${({ size }) => (size === 'md' ? '.8rem 1.2rem' : '.4rem .6rem')};
  display: inline-block;
  font-size: ${({ size }) => (size === 'md' ? font.s2 : font.s1)};
  border-radius: .4rem;
  background-color: ${gray._200};
  white-space: nowrap;

  ${({ status }) => (success.indexOf(status) !== -1) && css`
    color: ${state.success} !important;
    background: #fff;
  `}

  ${({ status }) => (secondary.indexOf(status) !== -1) && css`
    color: ${support._300} !important;
    background: #fff;
  `}

  ${({ status }) => (main.indexOf(status) !== -1) && css`
    color: ${primary._000} !important;
    background: #fff;
  `}

  ${({ status }) => (terciary.indexOf(status) !== -1) && css`
    color: ${support._100} !important;
    background: rgba(62, 120, 152, 0.1);
  `}

  ${({ status }) => (error.indexOf(status) !== -1) && css`
    color: ${state.error};
    background: #fff;
  `}
`;

export default View;

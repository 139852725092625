import React, {
  createContext, useContext, useState,
} from 'react';

import { element } from 'prop-types';

const SelectedPartnersContext = createContext(null);

export const SelectedPartnersProvider = ({ children }) => {
  const [ selectedPeople, setSelectedPeople ] = useState([]);
  const [ selectedCompanies, setSelectedCompanies ] = useState([]);
  const [ selectedPeopleEx, setSelectedPeopleEx ] = useState([]);
  const [ selectedCompaniesEx, setSelectedCompaniesEx ] = useState([]);

  return (
    <SelectedPartnersContext.Provider value={{
      selectedPeople, setSelectedPeople,
      selectedCompanies, setSelectedCompanies,
      selectedPeopleEx, setSelectedPeopleEx,
      selectedCompaniesEx, setSelectedCompaniesEx,
    }}
    >
      {children}
    </SelectedPartnersContext.Provider>
  );
};

SelectedPartnersProvider.defaultProps = {
  children: null,
};

SelectedPartnersProvider.propTypes = {
  children: element,
};

export const useSelectedPartner = () => useContext(SelectedPartnersContext);

import React from 'react';
import { ChevronDown } from 'react-feather';

import {
  object, any, func, bool, string,
} from 'prop-types';

import { Select, SimpleSelect } from '../Select';

const ClientSelect = ({
  user, value, onChange, hideIfOne, placeholder, ...rest
}) => {
  const grupos = user.hierarquia?.map((item) => ({
    value: item.id,
    label: item.cliente,
    maxDiligencesQuota: item.maxDiligencesQuota,
  }));

  // Se o combo possuir dados para seleção, e o usuário possuir algum grupo,
  // injeto esse grupo como possível selecionável no combo de clientes
  // permitindo assim filtrar por grupo
  if (grupos?.length > 1 && user.grupos && user.grupos.length > 0) {
    grupos.unshift({
      value: 0,
      label: user.grupos[0].grupo,
    });
  }

  return (
    <SimpleSelect
      isReveal={! hideIfOne || grupos?.length > 1}
      icon={ChevronDown}
      value={value}
      options={grupos}
      onChange={onChange}
      appearance="secondary"
      placeholder={placeholder}
      {...rest}
    />
  );
};

ClientSelect.propTypes = {
  user: object.isRequired, // eslint-disable-line react/forbid-prop-types
  value: any.isRequired, // eslint-disable-line react/forbid-prop-types
  onChange: func.isRequired,
  hideIfOne: bool,
  placeholder: string.isRequired,
};

ClientSelect.defaultProps = {
  hideIfOne: true,
};

export default ClientSelect;

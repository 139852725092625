import React, { useState } from "react";
import { ChevronRight, AlertCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  bool,
  func,
  oneOf,
  array,
  shape,
  number,
  string,
  object,
  element,
  arrayOf,
  oneOfType,
} from "prop-types";

import { ReactComponent as IconSort } from "~/assets/icons/icon-sort.svg";
import { Filter, Tooltip } from "~/components";
import { reduce } from "~/hooks";

import { TR, View, Item } from "./styles";

const Table = ({
  body,
  route,
  onBody,
  heading,
  filtered,
  onFiltered,
  breakpoint,
  withoutHeader,
  children,
  newTab,
}) => {
  const [order, onOrder] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("components");

  const sort = (filter) => {
    let copy = body?.slice();
    const sorting = (a, b, type) => {
      const af = a[filter]?.label;
      const bf = b[filter]?.label;

      if (af === bf) return 0;

      switch (type) {
        case "asc": {
          if (af[0] <= bf[0]) return -1;
          return 1;
        }
        case "desc": {
          if (af[0] >= bf[0]) return -1;
          return 1;
        }
        default:
          break;
      }
      return 0;
    };

    if (!order) {
      onOrder(true);
      copy = copy.sort((a, b) => sorting(a.items, b.items, "desc"));
    } else {
      onOrder(false);
      copy = copy.sort((a, b) => sorting(a.items, b.items, "asc"));
    }
    onBody(copy);
  };

  const status = heading.map((item) => ({
    size: item.size || "xs",
    align: item.align || "left",
    isMulti: item.isMulti || false,
    className: item.className || "",
  }));

  /**
   * Submit filters.
   */
  const onFilters = (clear) => {
    if (clear) onFiltered([]);
    else onFiltered(filtered);
  };

  const multipleLabels = (labels) => {
    let label = "";
    labels.forEach((val) => (label += `${val[0]}: ${val[1]}. `));

    return reduce(label, 36);
  };

  return (
    <View breakpoint={breakpoint}>
      {!withoutHeader && (
        <thead>
          <tr>
            {heading?.map(
              (
                { label, isSort, onSorting = false, filters, className },
                index
              ) => (
                <Item
                  as="th"
                  key={index} //eslint-disable-line
                  size={status[index].size}
                  align={status[index].align}
                  className={[className, status[index].className]}
                >
                  <span>
                    {label}
                    {body?.length > 1 && isSort && (
                      <IconSort
                        onClick={() => {
                          if (onSorting) {
                            return onSorting((val) => ({
                              [index]: val[index] === "asc" ? "desc" : "asc",
                            }));
                          }
                          return sort(index);
                        }}
                      />
                    )}
                    {filters && (
                      <Filter
                        fields={filters}
                        onFiltered={(clear) => onFilters(clear)}
                      />
                    )}
                  </span>
                </Item>
              )
            )}
          </tr>
        </thead>
      )}

      <tbody>
        {body?.map(({ id, items, disabled }, index) => {
          const itemAssociated = items.find((item) => !!item.associated);
          let checkAssociatedOpened = {};
          if (itemAssociated?.associatedProcessOpen.length > 0) {
            let result = itemAssociated.associatedProcessOpen.filter(
              (value) => {
                if (value.id === id) {
                  return value;
                }
              }
            );
            if (result.length > 0) {
              checkAssociatedOpened = result[0];
            }
          }
          const condition =
            itemAssociated?.associatedProcessOpen.length > 0 &&
            Object.keys(checkAssociatedOpened).length > 0 &&
            checkAssociatedOpened?.status == true &&
            checkAssociatedOpened?.id == id;
          return (
            <>
              <TR
                key={index} //eslint-disable-line
                isDisabled={disabled}
              >
                {items?.map(
                  (
                    {
                      title,
                      label,
                      isBold,
                      isArrow,
                      isMulti,
                      className,
                      dataLabel,
                      notArrowMessage,
                      leftSpacing,
                      isManyResults,
                      prependIcon,
                    },
                    idx
                  ) => (
                    <>
                      <Item
                        as="td"
                        data-label={
                          isArrow
                            ? t("arrow.see")
                            : dataLabel || heading[idx].label
                        }
                        key={`${idx}-${label}`} //eslint-disable-line
                        isLink={!!route}
                        onClick={
                          isArrow
                            ? () =>
                                route &&
                                (newTab
                                  ? window.open(`${route}/${id}`, "_blank")
                                  : navigate(`${route}/${id}`))
                            : () => ""
                        }
                        size={status[idx].size}
                        title={
                          title ||
                          (typeof label === "string" ||
                          typeof label === "number"
                            ? label
                            : "")
                        }
                        align={status[idx].align}
                        isBold={isBold}
                        status={label}
                        isMulti={status[idx].isMulti}
                        leftSpacing={leftSpacing}
                        className={
                          isArrow
                            ? [className, status[idx].className, "arrow"]
                            : [className, status[idx].className]
                        }
                      >
                        {prependIcon}
                        {isMulti
                          ? reduce(label.join(", "), 24)
                          : isManyResults
                          ? multipleLabels(label)
                          : reduce(label || "", 36)}
                        {isArrow && <ChevronRight />}
                        {!isArrow && notArrowMessage && (
                          <Tooltip label={notArrowMessage} width={300}>
                            <AlertCircle />
                          </Tooltip>
                        )}
                      </Item>
                    </>
                  )
                )}
              </TR>
              {condition && (
                <TR>
                  <td colSpan={20} className="accordeonTable">
                    {itemAssociated.associated}
                  </td>
                </TR>
              )}
            </>
          );
        })}
      </tbody>
    </View>
  );
};

Table.propTypes = {
  body: arrayOf(
    shape({
      id: number,
      items: arrayOf(
        shape({
          label: oneOfType([string, array, object, number, element]),
          align: oneOf(["left", "center", "right"]),
          items: arrayOf(string),
          isBold: bool,
          action: func,
          isMulti: bool,
          isManyResults: bool,
          prependIcon: string,
        })
      ),
      disabled: bool,
    })
  ),
  route: oneOfType([bool, string]),
  heading: arrayOf(
    shape({
      label: string,
      action: func,
    })
  ),
  onBody: func,
  filtered: oneOfType([bool, object]),
  onFiltered: func,
  breakpoint: string,
  withoutHeader: bool,
  newTab: bool,
};

Table.defaultProps = {
  body: [],
  route: false,
  onBody: () => {},
  heading: [],
  filtered: false,
  onFiltered: () => {},
  breakpoint: "sm",
  withoutHeader: false,
  newTab: false,
};

export default Table;

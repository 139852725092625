import React, { useEffect, useContext } from 'react';

import { element } from 'prop-types';

import { useReducer, useStoreModule, useApi } from '~/hooks';

import { useMe } from '~/services';
import reducer from './reducer';

/**
 * Module context.
 */
export const RisksContext = React.createContext();

/**
 * Module provider.
 */
export const RisksProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    risks: [],
  });
  const { user } = useMe();
  const api = useApi();

  useEffect(() => {
    if (user) {
      const load_risks = async () => {
        const { data } = await api().get(user ? `/clients/getClientRisks?client_id=${user.hierarquia[0].id}` : '');
        if (data?.data) {
          const {
            baixo, medio, alto, critico,
          } = data.data;
          dispatch('merge', {
            risks: [baixo, medio, alto, critico],
          });
        }
      };
      load_risks();
    }
  }, [user]);

  /**
   * Provider value.
   */
  const value = useStoreModule(state);

  return (
    <RisksContext.Provider value={value}>
      {children}
    </RisksContext.Provider>
  );
};

/**
 * Module hook.
 */
export const useRisks = () => useContext(RisksContext);

RisksProvider.propTypes = {
  children: element.isRequired,
};

import styled, { css } from 'styled-components';

import { P2 } from '~/components';
import {
  breakpoints, colors, easing, rotate360, typograph,
} from '~/styles';

const { basic } = easing;
const { size, weight } = typograph;

const {
  gray, status, primary,
} = colors;

export const View = styled.div`
  display: flex;
  padding: 2.4rem;
  flex-direction: column;
  overflow: hidden;
  background-color: ${gray._000};

  article {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 1.6rem;
      }
    }
  }
`;

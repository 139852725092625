import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { CheckCircle, ChevronDown, Info, Minus, Plus, Search, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

import { ReactComponent as IconMonitoring } from '~/assets/icons/icon-monitoring.svg';
import {
  Button,
  Container,
  Empty,
  Flag,
  Helmet,
  Input,
  Modal,
  P2,
  Paginate,
  RangeDatePicker,
  RiskLevel,
  Select,
  SimpleInput,
  SimpleLoading,
  Table,
  Tooltip,
} from '~/components';
import ClientSelect from '~/components/ClientSelect';
import { reduce, useApi, useDebounce, useFetch } from '~/hooks';
import { formatErrorMessage, masked, parseISO } from '~/hooks/helpers';
import useWebSocketRefresh from '~/hooks/useWebSocketRefresh';
import { Actions, Header, ModalMonitoring } from '~/layout';
import { useMe } from '~/services';
import { Form } from '@unform/web';
import { head, table } from './data';
import {
  ActionAccordion,
  ActionMonitoring,
  ActionsHeader,
  Container as ModalContainer,
  ModalView,
  View,
  Wrapper,
} from './styles';

registerLocale('pt', ptBr);
setDefaultLocale('pt', ptBr);

const Results = () => {
  const { t, i18n } = useTranslation('results');
  const { t: tS } = useTranslation('search');
  const { t: tC } = useTranslation('card');
  const { t: tAuth } = useTranslation('auth');
  const { user, group, newGroup } = useMe();
  const api = useApi();
  
  const [page, onPage] = useState(1);
  const [date, onDate] = useState('');
  const [size, onSize] = useState({ value: 20, label: 20 });
  const [selectedProcessId, onSelectedProcessId] = useState();
  const [selectedProcessIdAccordion, onSelectedProcessIdAccordion] = useState();
  const [fieldSearch, onFieldSearch] = useState('');
  const [showAdvancedOptionsModal, setShowAdvancedOptionsModal] = useState(false);
  const [dateRange, onDateRange] = useState(() => new Date().setDate(1));
  const [endDateRange, onEndDateRange] = useState(() => new Date());
  const [tag,setTag] = useState(null);
  const [workflow,setWorkflow] = useState(null);
  const [entityForm,setEntityForm] = useState(null);
  const [documentValue,setDocumentValue] = useState(null);
  const [risk,setRisk] = useState(null);
  const [status,setStatus] = useState(null);
  const [diligences, setDiligences] = useState({})
  const [url, updatedUrl] = useState('')
  const [isFiltering, setFiltering] = useState(false);
  const advancedOptionsRef = useRef(null);
  
  const { data: risks } = useFetch({ url: "/risk-impact" });
  const { data: statusName } = useFetch({ url: "/status-name" });

  const mask = {
    1: "999.999.999-99",
    2: "99.999.999/9999-99",
  }
  const statusFiltered = statusName?.data?.filter(statusItem => (statusItem.id !== 10 && statusItem.id !== 13 && statusItem.id !== 14));
  
  const options = useMemo(
    () => ({
      risk: risks?.data?.map((item) => ({
        value: item.id,
        label: item.descricao_impacto,
      })),
      statusName: statusFiltered?.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    }));
  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }
  const [endDate, onEndDate] = useState('');
  const [rowsDiligence, onRowsDiligence] = useState([]);
  const [rowsDiligenceAssociated,onRowsDiligenceAssociated] = useState([]);
  const [rowsEntity, onRowsEntity] = useState([]);
  const [monitoring, onMonitoring] = useState(false);
  const [associated, onAssociated] = useState([]);
  const [listAssociated, onListAssociated] = useState([]);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [sortEntities, onSortingEntities] = useState({});
  const [sortDiligences, onSortingDiligences] = useState({});
  const [processId, setProcessId] = useState(null);

  const { search } = useLocation();

  const query = new URLSearchParams(search).get('q');

  const dates = {
    start: date ? format(date, 'yyyy-MM-dd') : '',
    end: endDate ? format(endDate, 'yyyy-MM-dd') : '',
  };

  const jump = useDebounce(page);
  const debounced = useDebounce(fieldSearch);

  const processMutations = [];
  const entityMutations = [];
  
  const handleAdvancedOptions = async (formData) => {
    const {
      entityForm: advancedEntity, document, risk: advancedRisk, status: advancedStatus, workflow: advancedWorkflow, tag: advancedTag
    } = formData;

    setEntityForm(advancedEntity);
    setDocumentValue(document);
    setRisk(advancedRisk);
    setStatus(advancedStatus);
    setWorkflow(advancedWorkflow);
    setTag(advancedTag);
  }
  
  const cleanForm = async () => {
    await setEntityForm(null);
    await setDocumentValue(null);
    await setRisk(null);
    await setStatus(null); 
    await setWorkflow(null);
    await setTag(null);

    advancedOptionsRef.current.reset();
  }
  
  useEffect(() => {
    onAssociated([]);
  },[i18n.language]);
  
  const dataRageFrom = dateRange ? format(dateRange, 'yyyy-MM-dd') : '';
  const dataRageTo = endDateRange ? format(endDateRange, 'yyyy-MM-dd') : '';

  useEffect(() => {
    const sortValues = JSON.stringify(sortDiligences)
        .replace('2', 'razaosocial')
        .replace('3', 'document')
        .replace('4', 'risk')
        .replace('5', 'dataabertura')
        .replace('6', 'workflows')
        .replace('7', 'status')
        .replace('8', 'tags');
    
    !isFiltering
      ? updatedUrl(`/process?&search=${debounced}&page=${jump}${group.value?`&clientId=${group.value}`:''}${query==='diligencias'?`&pageSize=${size.value}`:''}${sortValues ? `&order=${sortValues}` : ''}`)

      : updatedUrl(`/process/results?&page=${jump}${query==='diligencias'?`&pageSize=${size.value}`:''}${group.value?`&clientId=${group.value}`:''}&from=${dataRageFrom}&to=${dataRageTo}${documentValue ? `&document=${masked(documentValue)}`:''}${entityForm?`&entity=${entityForm}`:''}${workflow?`&workflow=${workflow}`:''}${risk?`&risk=${risk}`:''}${status?`&status=${status}`:''}${tag?`&tag=${tag}`:''}${sortValues ? `&order=${sortValues}` : ''}`);
  }, [isFiltering, page, jump, debounced, sortDiligences, group, size]);

    const { data: fetchDiligences, mutate: renderDiligences } = useFetch({
      mutations: processMutations,
      url: url
    });

  useEffect(() => {
    setDiligences(fetchDiligences)
  },[isFiltering, page, fetchDiligences, documentValue, entityForm, workflow, risk, status, tag, debounced, group, size]);
  
  const { data: entities } = useFetch({
    mutations: entityMutations,
    url:
    `/entities/results?page=${jump}${group.value ? `&clientId=${group.value}` : ''}${query === 'entidades' && fieldSearch && debounced ? `&search=${debounced}` : ''}${dates?.start ? `&startDate=${dates.start}` : ''}${dates?.end ? `&endDate=${dates.end}` : ''}${query === 'entidades' ? `&pageSize=${size.value}` : ''}${query === 'entidades' ? `&order=${
    JSON.stringify(sortEntities)
      .replace('1', 'entity')
      .replace('2', 'document')
      .replace('4', 'avg_risk')
      .replace('5', 'processes_count')
      .replace('6', 'last_process_date')
      .replace('7', 'workflows')}` : ''}`,
  });

  const alert = useAlert();

  useWebSocketRefresh({
    channel: 'App.Events.ProcessStatusUpdated',
    events: diligences?.data?.map((process) => `App\\Events\\ProcessStatusUpdated\\${process.id}`),
    mutations: processMutations,
  }, [diligences]);

  useWebSocketRefresh({
    channel: 'App.Events.EntityUpdated',
    events: entities?.data?.data?.map((entity) => `App\\Events\\EntityUpdated\\${entity.id}`),
    mutations: entityMutations,
  }, [entities]);

  useEffect(() => {
    if(rowsDiligenceAssociated?.length > 0){
      if(listAssociated.length > 0) {
        let oldListAssociated = listAssociated.filter((value) => value.idFather != selectedProcessIdAccordion);
        let newListAssociated = [...oldListAssociated,  { idFather: selectedProcessIdAccordion, associated: rowsDiligenceAssociated}];
        onListAssociated(newListAssociated);
      } else {
        onListAssociated([{ idFather: selectedProcessIdAccordion, associated: rowsDiligenceAssociated}]);
      }
    }
  },[selectedProcessIdAccordion,rowsDiligenceAssociated]);
  
  const changeStatus = async () => {
    await api().post(`/services/process/${processId}/changeStatus`)
      .then( async () => {
        alert.success(t('modal.alerts.success'))
        setShowChangeStatusModal(false);
        setProcessId(null);
        await renderDiligences();
      })
      .catch( async error => {
        alert.error(formatErrorMessage(error.response));
      })
  }
  
  // Create table diligence.
  const diligence = useCallback(async () => {
    let row = null;
    let intRow = null ;

    if (diligences?.data?.length === 0) {
      row = [];
    }
    
    if (diligences?.data?.length > 0) {
      row = diligences?.data?.map((item) => {
        const deleted = async () => {
          await api().delete(`/monitoring/${item.monitoring_id}`)
            .then(() => {
              renderDiligences();
              alert.success(t('alerts.monitoring.success'));
            })
            .catch(({ response }) => alert.error(formatErrorMessage(response, t('alerts.monitoring.error'),tAuth('expired'))));
        };
        
        if( item.hasAssociatedProcesses > 0 ){
          intRow = [];
        }

        const validateEntityName = (process) => {
          const names = [];
          const { entity: _entity } = process;

          if (_entity && _entity.custom_data && _entity.custom_data.length > 0) {
            _entity.custom_data.forEach((customData) => {
              if (customData.type === 'RAZAO_SOCIAL') {
                names.push(customData.value);
              }
            });
          }

          const nameKeyByPersonType = [
            null, /* 0 - não existe */
            'name', /* 1 - PESSOA_FISICA_NACIONAL */
            'company_name', /* 2 - PESSOA_JURIDICA_NACIONAL */
            'name', /* 3 - PESSOA_FISICA_ESTRANGEIRA */
            'company_name', /* 4 - PESSOA_JURIDICA_ESTRANGEIRA */
          ];

          if (_entity && _entity[nameKeyByPersonType[process?.person_type]]) {
            names.unshift(_entity[nameKeyByPersonType[process?.person_type]]);
          }

          return reduce(names.join(' / '), 32);
        };
        
        const associatedProcess = async () => {
          const { data: associatedProcesses } = await api().get(`process/${item.id}/associations`);
          
          intRow = associatedProcesses?.map((value) => {
            return {
              id: value.id,
              idFather: item.id,
              items: [
                {
                  label: (
                    <Tooltip 
                      label={(
                        <>
                          {`${t('evaluations.tooltips.info.0')} ${value.supplier_code || t('evaluations.tooltips.info.1')}`}
                          <br />
                          {t('evaluations.tooltips.info.2')}
                          {value.show_id}
                        </>
                      )}
                      width={260}
                    >
                      <Info />
                    </Tooltip>
                  ),
                },
                {
                  label: validateEntityName(value) || value.cnpj,
                  isBold: true,
                },
                {
                  label: masked(value.cnpj),
                },
                {
                  label: (
                    <RiskLevel
                      id={value.risk?.probabilidade_id_custom || value.risk?.probabilidade_id}
                      risk={Math.round(Number(value.risk?.risk))}
                    />
                  ),
                },
                {
                  label: value.dataabertura
                    ? format(parseISO(value.dataabertura), 'dd/MM/yyyy')
                    : '-',
                },
                {
                  label: <Flag id={value.new_status.id} status={value.new_status.name} />,
                },
                {
                  label: value.tags.length > 0 ? <Flag status={value.tags[0].name} /> : ' ',
                },
                {
                  label: null
                },
                {
                  isArrow,
                  notArrowMessage: ! isArrow && value?.analyst?.nome
                    ? `${tC('admin_operations.being_edited_by')} ${value?.analyst?.nome} (${value?.analyst?.id})`
                    : null,
                  align: 'right',
                },
              ],
            };
          });

          onRowsDiligenceAssociated(intRow);
        }

        const toMonitor = [
          {
            icon: (
              <ActionMonitoring actived={item?.monitoring}>
                <IconMonitoring />
              </ActionMonitoring>
            ),
            action: () => {
              if (! item?.monitoring) {
                onMonitoring({ create: true });
                onSelectedProcessId(item.id);
              } else if (! item?.monitoring?.deleted_by) {
                deleted();
              }
            },
          },
        ];
        let checkAssociatedOpened = {};
        
        if(associated.length > 0 && item.hasAssociatedProcesses > 0) {
          let ok = associated.filter((value) => value.id === item.id);
          if(ok.length > 0) {
            checkAssociatedOpened = ok[0];
          }
        }
        const condition = (associated.length === 0 || Object.keys(checkAssociatedOpened).length === 0) || 
          (checkAssociatedOpened?.status === false && checkAssociatedOpened?.id === item.id);
        
        const toOpenAccordion = [
          {
            icon: (
              <ActionAccordion actived={true}>
                { 
                  condition 
                  ? <Plus size={'17px'}/>
                  : <Minus size={'17px'}/>
                }
              </ActionAccordion>
            ),
            action: () => {
              if ( item?.hasAssociatedProcesses > 0) {
                if(!condition) {
                  let newAssociated = associated.filter((value) => value.id != item.id);
                  onAssociated([...newAssociated, {id:item.id, status:false}]);
                } else {
                  let oldAssociated = associated.filter((value) => value.id != item.id);
                  let newAssociated = [...oldAssociated, { id: item.id, status: true }];
                  onAssociated(newAssociated);
                }
                onSelectedProcessIdAccordion(item.id);
                associatedProcess();
              } 
            },
          },
        ];

        const resultBody = () => {
          if ( item?.hasAssociatedProcesses > 0) {
            const result = listAssociated.filter((value) => value.idFather == item.id);
            return result[0]?.associated;
          }
          return [];
        }

        const isArrow = !user?.isAdminICTS()
          || item.analyst_editor === null
          || item.analyst_editor === user.usuario.id;
        
        return {
          id: item.id,
          items: [
            {
              label: (
                <Tooltip
                  label={(
                    <>
                      {`${t('evaluations.tooltips.info.0')} ${item.supplier_code || t('evaluations.tooltips.info.1')}`}
                      <br />
                      {t('evaluations.tooltips.info.2')}
                      {item.show_id}
                    </>
                  )}
                  width={260}
                >
                  <Info />
                </Tooltip>
              ),
            },
            {
              noLink: true,

              label: (
                <Tooltip
                  label={(() => {
                    if (condition) {
                      return t('evaluations.tooltips.show_associated_diligence');
                    } else {
                      return t('evaluations.tooltips.hide_associated_diligence');
                    }
                  })()}
                  width={200}
                >
                  { item?.hasAssociatedProcesses > 0 ?
                    <Actions
                      disabled={user?.isVisualizador()}
                      actions={toOpenAccordion}
                    />
                    :
                    <>
                    </>
                  }
                </Tooltip>
              ),
              associatedProcessOpen: associated,//BOOL DE ABRIR O ACORDEON
              selectedProcessIdAccordion: selectedProcessIdAccordion, // ID DO PROCESSO
              associated: (
                <Table
                  body={resultBody()}
                  idFather={item.id}
                  route={'/pesquisa/diligencia'}
                  onBody={onRowsDiligenceAssociated()}
                  heading={table.diligenciasAssociadas(t)}
                  breakpoint="md"
                  withoutHeader
                /> 
              ), // CONTEUDO DO ACORDEON
            },
            {
              label: validateEntityName(item) || item?.cnpj,
              isBold: true,
            },
            {
              label: item.cnpj,
            },
            {
              label: (
                <RiskLevel
                  id={item.risk?.probabilidade_id_custom || item.risk?.probabilidade_id}
                  risk={Math.round(Number(item.risk?.risk))}
                />
              ),
            },
            {
              label: item.dataabertura
                ? format(parseISO(item.dataabertura), 'dd/MM/yyyy')
                : '-',
            },
            {
              label: item?.workflows.map((child) => child.name) || '',
              isMulti: true,
            },
            {
              label: <Flag 
                id={item.new_status.id} 
                status={item.new_status.name}
                isLink={ 
                  ( user.isMaster() || user.isGestor() || user.isAdminICTS() ) && 
                  [4, 15].includes(item.new_status.id) 
                }
                action={ () => {
                  setProcessId(item.id);
                  setShowChangeStatusModal(true);
                } }
              />,
            },
            {
              label: item.tags.length > 0 ? <Flag status={item.tags[0].name} /> : ' ',
            },
            {
              noLink: true,
              label: (
                <Tooltip
                  label={(() => {
                    if (! item?.monitoring) {
                      return t('evaluations.tooltips.monitoring.1');
                    }
                    if (! item?.monitoring?.deleted_by) {
                      return t('evaluations.tooltips.monitoring.0');
                    }
                    return t('evaluations.tooltips.monitoring.2');
                  })()}
                  width={200}
                >
                  <Actions
                    disabled={user?.isVisualizador()}
                    actions={toMonitor}
                  />
                </Tooltip>
              ),
            },
            {
              isArrow,
              notArrowMessage: ! isArrow && item?.analyst?.nome
                ? `${tC('admin_operations.being_edited_by')} ${item?.analyst?.nome} (${item?.analyst?.id})`
                : null,
            },
          ],
        };
      });
    }

    onRowsDiligence(row);
  }, [alert, diligences?.data, renderDiligences, t, user.usuario.id, selectedProcessIdAccordion, associated, listAssociated]);

  // Create table entities.
  const entity = useCallback(async () => {
    let row = null;

    if (entities?.data?.data?.length === 0) {
      row = [];
    }

    const status = {
      1: {
        icon: <CheckCircle />,
        label: t('entities.tooltips.0'),
        className: 'success',
      },
      2: {
        icon: <XCircle />,
        label: t('entities.tooltips.1'),
        className: 'error',
      },
    };

    if (entities?.data?.data?.length > 0) {
      row = entities?.data?.data?.map((item) => ({
        id: item.document,
        items: [
          {
            label: (
              <Tooltip
                label={status[item.entity_status].label}
                width={180}
                className="tooltip"
              >
                <span className={status[item.entity_status].className}>
                  {status[item.entity_status].icon}
                </span>
              </Tooltip>
            ),
          },
          {
            label: String(item?.name).trim() || '-',
            isBold: true,
          },
          {
            label: item.document ? masked(item.document) : '-',
          },
          {
            label: <RiskLevel
              id={item.probability}
              risk={Math.round(item.min_risk)}
              maxRisk={Math.round(item.max_risk)}
              is_critical={item.is_critical}
            />,
          },
          {
            label: <RiskLevel
              id={item.probability}
              risk={Math.round(item.avg_risk)}
              is_critical={item.is_critical}
            />,
          },
          {
            label: item.process_count || '-',
            isBold: true,
          },
          {
            label: item?.last_opening_date
              ? (() => {
                try {
                  return format(parseISO(item.last_opening_date), 'dd/MM/yyyy');
                } catch {
                  return item?.last_opening_date;
                }
              })()
              : '-',
          },
          {
            label: item.workflows.length > 0 ? item.workflows.map((workflow) => workflow.name).join(', ') : '-',
          },
          {
            isArrow: true,
          },
        ],
      }));
    }

    onRowsEntity(row);
  }, [entities?.data?.data, t]);

  useEffect(() => {
    entity();
    diligence();
  }, [entity, diligence]);

  const tabled = {
    entidades: {
      body: rowsEntity,
      head: table.entidades(t, onSortingEntities),
      route: '/pesquisa/entidades',
      onBody: onRowsEntity,
    },
    diligencias: {
      body: rowsDiligence,
      head: table.diligencias(t, onSortingDiligences),
      route: '/pesquisa/diligencia',
      onBody: onRowsDiligence,
    },
  };

  /**
   * Reset search and paginate.
   */
  useEffect(() => {
    onPage(1);
    onSize({ value: 20, label: 20 });
    onFieldSearch('');
  }, [query, isFiltering, documentValue, entityForm, workflow, risk, status, tag]);
  
  const grupos = user.hierarquia?.map((item) => ({
    value: item.id,
    label: item.cliente,
  }));

  // Se o combo possuir dados para seleção, e o usuário possuir algum grupo,
  // injeto esse grupo como possível selecionável no combo de clientes
  // permitindo assim filtrar por grupo
  if (grupos?.length > 1 && user.grupos && user.grupos.length > 0) {
    grupos.unshift({
      value: 0,
      label: user.grupos[0].grupo,
    });
  }
  
  const [maskValue, setMaskValue] = useState(null );
  useEffect(() => {
    if (documentValue?.length === 11)
      setMaskValue(1);
    else if (documentValue?.length === 14)
      setMaskValue(2);
    else 
      setMaskValue(null);
  }, [documentValue]);

  const changeStatusModal = () => (
    <Modal
      show={ showChangeStatusModal }
      onShow={ setShowChangeStatusModal }
      hasAction
      action={{
        label: t('modal.actions.confirm'),
        onClick: () => changeStatus(),
      }}
      goBackOnCancel={() => {
        setProcessId(null);
        setShowChangeStatusModal(false);
      }}
      title={ t('modal.tittle') }
    >
      <P2>{t('modal.description')}</P2>
    </Modal>
  );

  return (
    <View>
      <Helmet title={query === 'diligencias' ? t('head.evaluations') : t('head.entities')} />

      <ModalMonitoring
        show={monitoring.create}
        render={renderDiligences}
        onShow={onMonitoring}
        processId={selectedProcessId}
      />

      { changeStatusModal() }

      <Container>
        <Header head={head(t)} query={query} className="header">
          <ActionsHeader>
            {query === 'entidades' && (
              <RangeDatePicker
                date={date}
                onDate={onDate}
                endDate={endDate}
                onEndDate={onEndDate}
              />
            )}

            <ClientSelect
              user={user}
              value={group}
              onChange={(val) => newGroup(val)}
              placeholder={t('components.group')}
            />

            {!isFiltering && 
              <SimpleInput
                isSearch
                name="search"
                icon={<Search/>}
                value={fieldSearch}
                onValue={onFieldSearch}
                onChange={({target}) => onFieldSearch(target.value)}
                placeholder={t('components.search')}
                appearance="secondary"
              />}
            
             {query !== 'entidades' && !isFiltering && (
              <Button
                  label={tS('advanced_search.label')}
                  onClick={() => setShowAdvancedOptionsModal(true) }
                  disabled={false}
                  appearance="secondary"
                  style={{marginLeft: '1.5rem'}}
                />
             )}
            {query !== 'entidades' && isFiltering && (
              <Button
                label={tS('clean_filter')}
                onClick={async () => {
                  setDiligences(fetchDiligences);
                  await cleanForm();
                  setFiltering(false);
                }}
                disabled={false}
                style={{marginLeft: '1.5rem'}}
              />
             )}
          </ActionsHeader>
        </Header>

        <Wrapper>
          {tabled[query]?.body ? (
            <>
              {tabled[query].body.length > 0 ? (
                <Table
                  body={tabled[query].body}
                  route={tabled[query].route}
                  onBody={tabled[query].onBody}
                  heading={tabled[query].head}
                  breakpoint="md"
                  newTab
                /> 
              ) : <Empty hasButton={false} />}

              {query === 'diligencias' && diligences && (
                <Paginate
                  size={size}
                  pages={diligences?.last_page}
                  total={diligences?.total}
                  onSize={onSize}
                  perPage={diligences?.to}
                  className="m-auto m-t-48"
                  onPaginate={onPage}
                  currentPage={page}
                />
              )}

              {query === 'entidades' && entities && (
                <Paginate
                  size={size}
                  pages={entities?.data?.last_page}
                  total={entities?.data?.total}
                  onSize={onSize}
                  perPage={entities?.data?.to}
                  className="m-auto m-t-48"
                  onPaginate={onPage}
                  currentPage={page}
                />
              )}
            </>
          ) : (
            <SimpleLoading />
          )}
          <Modal
            prepaidDescription={tS("advanced_search.description")}
            title={tS("advanced_search.label")}
            show={showAdvancedOptionsModal}
            onShow={setShowAdvancedOptionsModal}
            action={{
              label: tS('conclude'),
              onClick: async () => {
                setShowAdvancedOptionsModal(false);
                advancedOptionsRef.current.submitForm();
                setFiltering(true);
              },
            }}
            showCancel={false}
            loading={false}
            classWrapper={"modal-advanced-options"}
            size={'lg'}
            information={false}
          >
            <ModalView className="modal-lg" style={{height: '460px'}}>
              <Form ref={advancedOptionsRef} onSubmit={handleAdvancedOptions}>
                <ModalContainer>
                  <div style={{marginTop: '25px'}}>
                    <RangeDatePicker
                      date={dateRange}
                      onDate={onDateRange}
                      endDate={endDateRange}
                      onEndDate={onEndDateRange}
                      maxDate={new Date()}
                    />
                  </div>
                  
                  <Input
                    name="entityForm"
                    value={entityForm}
                    reveal={true}
                    label={tS('table.1')}
                    placeholder={tS('components.name_jurisdiction')}
                  />


                  <Select
                    isClearable
                    menuPosition="fixed"
                    styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                    menuPortalTarget={document.body}
                    name="risk"
                    value={risk}
                    label={t('evaluations.table.2')}
                    icon={ChevronDown}
                    options={options.risk}
                    isLoading={false}
                    placeholder={tS("components.risk")}
                  />
                  
                  <Select
                    isClearable
                    menuPosition="fixed"
                    styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                    menuPortalTarget={document.body}
                    name="status"
                    value={status}
                    label={t('evaluations.table.5')}
                    icon={ChevronDown}
                    options={options.statusName}
                    isLoading={false}
                    placeholder={tS("components.status")}
                  />

                  <Input
                    name="document"
                    onChange={(ev) => setDocumentValue(ev.target.value.replace(/\D/g, ''))}
                    onFocus={() => { setMaskValue(null) }}
                    mask={mask[maskValue] || ""}
                    reveal={true}
                    label={t('evaluations.table.1')}
                    placeholder={tS('components.key.1')}
                  />
                  
                  <Input
                    name="workflow"
                    value={workflow}
                    reveal={true}
                    label={t('evaluations.table.4')}
                    placeholder={tS("components.insert_workflow")}
                  />
                  
                  <Input
                    name="tag"
                    value={tag}
                    reveal={true}
                    label={t('evaluations.table.6')}
                    placeholder={tS("components.insert_tag")}
                  />
                </ModalContainer>
              </Form>
            </ModalView>
          </Modal>
        </Wrapper>
      </Container>
    </View>
  );
};

export default Results;

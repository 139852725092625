import React, { useMemo, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { arrayOf, objectOf, string } from 'prop-types';

import { masked, removeFalsies } from '~/hooks';

import { P2 } from '../Title';
import Tooltip from '../Tooltip';
import View from './styles';

const ItemsList = ({ items, keys, type }) => {
  const [show, onShow] = useState(false);

  const { t } = useTranslation('entities');
  const { t: T } = useTranslation('components');

  const formattedText = useMemo(() => {
    if (! items?.length) {
      return t('cadastral_data.fields.not_found');
    }

    switch (type) {
      case 'address':
        return items.map((item) => removeFalsies(keys.map((key) => {
          switch (key) {
            case 'place':
              return item[key] ? `${item[key]} ` : '';
            case 'city':
              return item[key] || '';
            case 'state':
              return item[key] ? ` - ${item[key]}` : '';
            case 'country_name':
              return item[key] ? `, ${item[key]}` : '';
            default:
              return item[key] ? `${item[key]}, ` : '';
          }
        })).join(''));

      case 'phone':
        return items.map((item) => removeFalsies(keys.map((key) => {
          switch (key) {
            case 'country_code':
              return `+${item[key]}`;
            case 'area_code':
              return `(${item[key]})`;
            default:
              return item[key];
          }
        })).join(' '));

      case 'family':
        return items.map((item) => removeFalsies(keys.map((key) => (
          (key === 'documento' && item[key]) ? `CPF: ${masked(item[key]) || t('cadastral_data.fields.not_found')}` : item[key]
        ))).join(', '));

      case 'filiais':
        return items.map((item) => removeFalsies(keys.map((key) => (
          (key === 'document' && item[key]) ? `CNPJ: ${masked(item[key])}` : item[key]
        ))).join(', '));
      case 'cnae':
        return items.map((item) => removeFalsies(keys.map((key) => item[key])).join(' '));
      case 'name':
        return items;
      default:
        break;
    }

    return items.map((item) => removeFalsies(keys.map((key) => item[key])).join(', '));
  }, [items, keys, type]);

  if (! items?.length || (items?.length === 1 && type !== 'address')) {
    return formattedText;
  }

  return (
    <View show={show}>
      <ul>
        {formattedText?.map((text) => (
          <>
            {type === 'address' ? (
              <li key={text}>
                <Tooltip key={text} label={T('items_list.address.tooltip')} width={250}>
                  <P2 as="a" className="address" target="_blank" href={`https://www.google.com/maps/place/"${text}"`}>{text}</P2>
                </Tooltip>
              </li>
            ) : (
              <li key={text} title={text}>
                <P2>{text}</P2>
              </li>
            )}
          </>
        ))}
      </ul>
      {! (items?.length === 1 && type === 'address') && (
        <button type="button" onClick={() => onShow(! show)}>
          {show ? (
            <>
              {T('items_list.button.less')}
              <ChevronUp size="2rem" />
            </>
          ) : (
            <>
              {T('items_list.button.more')}
              <ChevronDown size="2rem" />
            </>
          )}
        </button>
      )}
    </View>
  );
};

ItemsList.propTypes = {
  items: arrayOf(objectOf(string)).isRequired,
  keys: arrayOf(string).isRequired,
  type: string,
};

ItemsList.defaultProps = {
  type: '',
};

export default ItemsList;

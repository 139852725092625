import { Form as Unform } from '@unform/web';
import styled from 'styled-components';

import { P1, P2, Layout, Container, Column, Row } from '../../../components';
import { breakpoints, colors, typograph } from '../../../styles';

const { gray, primary } = colors;

const { weight, size } = typograph;

export const View = styled(Layout)`
  margin-bottom: 6.4rem;
`;

export const WeightContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const Form = styled(Unform)`
  display: grid;
  grid-template-columns: ${(({ twoColumns }) => (twoColumns === true ? '1fr 2fr' : 'repeat(3, 1fr)'))};

  ${P1} {
    color: ${gray._500};
    font-weight: ${weight.semiBold};
    margin-bottom: 2.4rem;
  }

  & > section {
    display: flex;
    flex-direction: column;

    ${P2} {
      color: ${gray._500};
    }

    &:first-of-type > div:not(:first-of-type) {
      margin-top: 2.4rem;
    }

    &:not(:first-of-type) {
      border-left: 0.2rem solid ${gray._300};
      margin-left: 2.4rem;
      padding-left: 2.4rem;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    grid-template-columns: 1fr;

    & > section {

    &:first-of-type > div:not(:first-of-type) {
      margin-top: 2.4rem;
    }

    &:not(:first-of-type) {
      border: 0;
      border-top: 0.2rem solid ${gray._300};
      margin-left: 0rem;
      margin-top: 2.4rem;
      padding-top: 2.4rem;
    }
  }
  }
`;

export const Wrapper = styled(Row)`
  display: flex;
  padding: 2.4rem;
  flex-direction: column;
  background-color: ${gray._000};
  box-shadow: 0px 2px 15px ${gray._300};
  border-radius: 5px;

  & > div:last-of-type {
    display: flex;
    border-top: 0.1rem solid ${gray._300};
    margin-top: 2.4rem;
    padding-top: 1.6rem;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 1.6rem;
    }
  }
`;

export const CheckedBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  span {
    border: 0.1rem solid ${gray._400};
    border-color: ${primary._000};
    background-color: ${primary._000};
    height: 1.4rem;
    min-width: 1.4rem;
    border-radius: 0.7rem;
    margin-right: 1.8rem;
    margin-left: 0.5rem;
  }

  p {
    font-size: ${size.s3};
    color: ${gray._500};
    line-height: ${size.m1};
  }
`;

export default View;

export const ThemeWeightContainer = styled.section`
  color: ${gray._500};

  > div {
    font-size: ${size.l1};
    display: flex;
    align-items: flex-start;
    column-gap: 2rem;

    > div {
      display: flex;

      > p {
        margin-right: 5rem;
      }

      > div {
        display: flex;
        width: 12rem;
        transform: translateY(-15%);
      }
    }
  }

  hr {
    width: 100%;
    border: 0;
    border-bottom: 0.2rem solid ${gray._200};

    :not(:last-of-type) {
      margin-bottom: 3rem;
    }
  }
`;

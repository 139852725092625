import React, { useRef, useEffect } from 'react';
import { Check } from 'react-feather';

import { useField } from '@unform/core';
import {
  bool,
  shape,
  number,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';

import { View, Container } from './styles';

const MultiCheckbox = ({
  name,
  options,
  initialValues,
  ...rest
}) => {
  const inputRefs = useRef([]);

  const {
    fieldName,
    registerField,
    defaultValue = initialValues || [],
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => refs.filter((ref) => ref?.checked).map((ref) => ref.value),
      clearValue: (refs) => {
        refs.forEach((ref) => {
          if (defaultValue.includes(ref.id)) {
            ref.checked = true; // eslint-disable-line
          } else {
            ref.checked = false; // eslint-disable-line
          }
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true; // eslint-disable-line
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container>
      {options?.map(({
        id,
        label,
        value,
        reveal,
      }, index) => (
        <View
          key={id}
          size="md"
          style={{ display: reveal ? 'flex' : 'none' }}
        >
          <input
            {...rest}
            id={id}
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
            type="checkbox"
            value={value}
            defaultChecked={defaultValue.find((dv) => dv === id)}
          />

          <span>
            <Check name="check" size="1.4rem" />
          </span>

          <label htmlFor={id}>{label}</label>
        </View>
      ))}
    </Container>
  );
};
MultiCheckbox.defaultProps = {
  initialValues: [],
};

MultiCheckbox.propTypes = {
  name: string.isRequired,
  initialValues: arrayOf(string),
  options: arrayOf(shape({
    id: string,
    label: string,
    value: oneOfType([string, number]),
    reveal: bool,
  })).isRequired,
};

export default MultiCheckbox;

import React, { useState } from 'react';

import {
  string,
  number,
  element,
  oneOfType,
  bool,
} from 'prop-types';

import { View, Bubble } from './styles';

const Tooltip = ({
  label,
  width,
  children,
  isActive, 
  direction,
  ...rest
}) => {
  const [show, onShow] = useState(false);

  return (
    <View
      onMouseLeave={() => onShow(false)}
      onMouseEnter={() => onShow(true)}
      {...rest}
    >
      <Bubble
        style={{ width: `${width / 10}rem` }}
        show={show}
        isActive={isActive}
        direction={direction}
      >
        {label}
      </Bubble>

      {children}
    </View>
  );
};

Tooltip.propTypes = {
  label: string.isRequired,
  width: number,
  children: oneOfType([element, string]),
  isActive: bool,
  direction: Object,
};

Tooltip.defaultProps = {
  width: 64,
  children: {},
  isActive: true,
  direction: {},
};

export default Tooltip;

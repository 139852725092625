import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useAlert } from 'react-alert';
import {
  MinusCircle,
  PlusCircle,
  ChevronDown,
  Edit as IconEdit,
  Trash as IconTrash,
  Search as IconSearch,
  AlertTriangle,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Form } from '@unform/web';
import { format, startOfMonth } from 'date-fns';
import * as Yup from 'yup';

import {
  H6,
  P2,
  Flag,
  Input,
  Table,
  Modal,
  Empty,
  Button,
  Select,
  Helmet,
  Upload,
  Paginate,
  Container,
  SimpleInput,
  SimpleSelect,
  SimpleLoading,
  RangeDatePicker,
} from '~/components';
import ClientSelect from '~/components/ClientSelect';
import { SelectedRiskSourceProvider } from '~/components/RiskSourceSelector/selectedRiskSourcesContext';
import { useApi, useFetch, useDebounce, formatErrorMessage } from '~/hooks';
import { parseISO } from '~/hooks/helpers';
import { Actions, Header, CreateTag } from '~/layout';
import {
  error, schema_user, useMe,
} from '~/services';
import Settings from '~/views/Administration/Settings';

import CheckWorkflow from './checkbox';
import {
  head, table, modal, fields, action,
} from './data';
import Pricing from './Pricing';
import RiskSources from './RiskSources';
import {
  View,
  ModalBody,
  ModalView,
  ActionHeader,
  WorkflowItemsContainer,
  WorkflowHeader,
  ButtonLeft,
  WorkflowRightDiv,
  WorkflowLeftSelect
} from './styles';
import { Profile, Card } from './utils';
import Cnaes from "~/views/Administration/CNAEs";
import Blacklist from './Blacklist';
import Terms from './Terms';

const Administration = () => {
  const { user, group, newGroup } = useMe();

  const { t } = useTranslation('admin');
  const { t:tAuth} = useTranslation('auth');
  const [page, onPage] = useState(1);
  const [show, onShow] = useState(false);
  const [mask, onMask] = useState({
    value: 'pt_BR',
    label: 'pt_BR',
  });
  const [size, onSize] = useState({ value: 15, label: 15 });
  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }
  const [rowTag, onRowTag] = useState([]);
  const [rowUser, onRowUser] = useState([]);
  const [loading, onLoading] = useState(false);
  const [searchField, onSearchField] = useState('');
  const [rowHistoric, onRowHistoric] = useState([]);
  const [historicGroup, onHistoricGroup] = useState();
  const [showModal, onShowModal] = useState(false);

  const [date, onDate] = useState(startOfMonth(new Date()));
  const [endDate, onEndDate] = useState(new Date());

  const [sortingTags, onSortingTags] = useState({});
  const [sortingHistoric, onSortingHistoric] = useState({});
  const [sortingUsers, onSortingUsers] = useState({});
  const [typeSelected, setTypeSelected] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeWorkflow, setActiveWorkflow] = useState(1);
  const [orderWorkflow, setOrderWorkflow] = useState(null);
  const [searchWorkflow, setSearchWorkflow] = useState('');
  const [maskedInputLength, setMaskedInputLength] = useState(0);
  const [deleteAvatarFlag, setDeleteAvatarFlag] = useState(false)

  const ref = useRef();
  const api = useApi();
  const alert = useAlert();

  const jump = useDebounce(page);
  const debounced = useDebounce(searchField);
  
  const { search } = useLocation();

  const query = new URLSearchParams(search).get('q') || 'tags';

  const filtered_historic = historicGroup?.map((item) => item.value);

  const { data: userType } = useFetch({ url: '/user/types' });
  const { data: languages } = useFetch({ url: '/user/languages' });
  const { data: historicList } = useFetch({
    url: `/user/historic?${group.value === 0 ? '' : `clientId=${group.value}&`}
    ${filtered_historic?.length > 0 ? `typeId=[${filtered_historic}]&` : ''}
    page=${jump}${query === 'historico' ? `&pageSize=${size.value}` : ''}
    ${query === 'historico' && searchField && debounced ? `&searchField=${debounced}` : ''}
    ${query === 'historico' ? `&order=${JSON.stringify(sortingHistoric)
    .replace('0', 'user')
    .replace('1', 'email')
    .replace('2', 'action')
    .replace('3', 'data')
  }` : ''}
    `,
  });
  const { data: historicTypes } = useFetch({ url: '/user/historicTypes' });
  const { data: tags, mutate: render_tags } = useFetch({
    url:
    `/tag?${group.value === 0 ? '' : `clientId=${group.value}&`}page=${jump}
    ${query === 'tags' ? `&pageSize=${size.value}` : ''}
    ${query === 'tags' && searchField && debounced ? `&searchField=${debounced}` : ''}
    ${query === 'tags' ? `&order=${JSON.stringify(sortingTags)
    .replace('0', 'name')
    .replace('1', 'processes_count')
  }` : ''}
    `,
  });
  const { data: userList, mutate: render_users } = useFetch({
    url: `/user?${group.value === 0 ? '' : `clientId=${group.value}&`}page=${jump}${query === 'usuarios' ? `&pageSize=${size.value}` : ''}
    ${query === 'usuarios' && searchField && debounced ? `&searchField=${debounced}` : ''}
    ${query === 'usuarios' ? `&order=${JSON.stringify(sortingUsers)
    .replace('1', 'name')
    .replace('2', 'email')
    .replace('3', 'telefone')
    .replace('4', 'empresa')
    .replace('5', 'tipo')
  }` : ''}
    `,
  });
  const { data: workflowsList, mutate: renderWorkflowsList } = useFetch({
    url: `/workflows/search?active=${activeWorkflow}&page=${jump}${query === 'workflows' ? `&pageSize=${size.value}` : ''}${orderWorkflow ? `&order=${orderWorkflow.value}` : ''}${searchWorkflow ? `&search=${searchWorkflow}` : ''}`,
  });
  const { data: termList, isValidating: validatingTermList, mutate: termListMutate } = useFetch({ url: `/blacklist/terms${debounced && searchField && '?searchField='+debounced}` });

  /**
   * Sort through backend
   */
  const onSorting = useMemo(() => (
    { tags: onSortingTags, usuarios: onSortingUsers, historico: onSortingHistoric }
  ), []);

  /**
   * Header options.
   */
  const options = {
    idcliente: user.hierarquia?.map((item) => ({
      value: item.id,
      label: item.cliente,
    })),
    usertype: userType?.data?.map((item) => ({
      value: item.id,
      label: item.perfil,
    })),
    preferred_language: languages?.data?.map((item) => ({
      value: item,
      label: item,
    })),
  };

  /**
   * Workflow options. 
   */
  const workflowOptions = {
    order: [
      { value: 'alphabetical', label: t("alphabetical_order") },
      { value: 'asc', label: t("newest_first") },
      { value: 'desc', label: t("oldest_first") },
    ]
  }

  /**
   * Paginate.
   */
  const paginate = {
    tags: {
      pages: tags?.last_page,
      total: tags?.total || '...',
      perPage: tags?.data?.length,
    },
    usuarios: {
      pages: userList?.last_page,
      total: userList?.total || '...',
      perPage: userList?.data?.length,
    },
    historico: {
      pages: historicList?.last_page,
      total: historicList?.total || '...',
      perPage: historicList?.data?.length,
    },
    workflows: {
      pages: workflowsList?.last_page,
      total: workflowsList?.total || '...',
      perPage: workflowsList?.data?.length,
    },
  };

  /**
   * Reset paginate.
   */
  useEffect(() => {
    onPage(1);
    onSize({ value: 15, label: 15 });
    onSearchField('');
  }, [query]);

  /**
   * Reset form.
   */
  const reset = () => {
    ref.current.reset();
  };

  /**
   * Add user avatar.
   */
  const addUserAvatar = async (image, userId) => {
    const form = new FormData();
    form.append('avatar', image);

    await api().post(`/user/${userId}/avatar`, form)
      .catch(({ response }) => alert.error(formatErrorMessage(response, 'Falha ao salvar avatar',tAuth('expired'))));
  }

  /**
   * Remove user avatar.
   */
  const deleteUserAvatar = async (userId) => {
    await api().delete(`/user/${userId}/avatar`)
      .catch(({ response }) => alert.error(formatErrorMessage(response, 'Falha ao deletar avatar',tAuth('expired'))))
  }

  /**
   * Create users and tags.
   * Edit users and tags.
   */
  const create = (val, type) => {
    const users = async () => {
      onLoading(true);
      await api()
        .post('/user/register', {
          name: val.name,
          email: val.email,
          phone: val.phone,
          active: true,
          username: val.email,
          usertype: val.usertype,
          viewWorkflowCosts: val.viewWorkflowCosts,
          canOpenLots: val.canOpenLots,
          idcliente: ! val.idcliente ? undefined : val.idcliente,
          idgrupo: ! val.idcliente ? user.grupos[0].idgrupo : undefined,
          email_language: val.preferred_language,
          preferred_language: val.preferred_language,
        })
        .then(async ({data}) => {
          const image = ref.current.getFieldValue('image');

          if (image) {
            await addUserAvatar(image, data?.data?.ddUser?.id)
          }

          reset();
          render_users();
          onShow(false);
          alert.success(t('users.create.response.success'));
        })
        .catch(({ response }) => alert.error(formatErrorMessage(response, t('users.create.response.error'),tAuth('expired'))));

      onLoading(false);
    };

    const edit_user = async () => {
      onLoading(true);

      await api()
        .patch(`/user/${show.id}`, {
          id_portal: show.id,
          name: val.name,
          phone: val.phone,
          usertype: val.usertype,
          viewWorkflowCosts: val.viewWorkflowCosts,
          canOpenLots: val.canOpenLots,
          idcliente: ! val.idcliente ? undefined : val.idcliente,
          idgrupo: ! val.idcliente ? user.grupos[0].idgrupo : undefined,
          email_language: val.preferred_language,
          preferred_language: val.preferred_language,
        })
        .then(async (res) => {
          const image = ref.current.getFieldValue('image');

          if (image) { 
            await addUserAvatar(image, show.id)
          } else if(deleteAvatarFlag) {
            await deleteUserAvatar(show.id)
          }

          alert.success(t('users.edit.response.success'));
          reset();
          render_users();
          onShow(false);
        })
        .catch(({ response }) => alert.error(formatErrorMessage(response, t('users.edit.response.error'),tAuth('expired'))));
      onLoading(false);
    };

    Yup.object()
      .shape(schema_user(t, type, user.usuario))
      .validate({ ...val, mask: mask.value }, {
        abortEarly: false,
      })
      .then(() => {

        switch (type) {
          case 'usuarios':
            users();
            break;

          case 'edit-usuario':
            edit_user();
            break;

          default:
            break;
        }
      })
      .catch((err) => error(err, ref));
  };


  /**
   * Initial data edit form.
   */
  useEffect(() => {
    if (show) {
      const replace = async () => {
        if (show.type === 'edit-usuario') {
          await api().get(`/user/${show.id}`).then(({ data }) => {
            setTypeSelected(data?.perfil?.id);
            setSelectedUser(data);
            ref.current.setData({
              name: data.nome,
              phone: data.telefone,
              image: data.avatar,
              idcliente: {
                value: data.client?.id,
                label: data.client?.cliente,
              },
              usertype: {
                value: data.perfil?.id,
                label: data.perfil?.perfil,
              },
              preferred_language: {
                value: data?.preferred_language || data?.email_language,
                label: data?.preferred_language || data?.email_language,
              },
            });
          });
        }
      };

      replace();
    }
  }, [show]);

  /**
   * Create row
   * tag table.
   */
  const row_tag = useCallback(async () => {
    let row = [];

    if (tags !== undefined && tags.length <= 0) {
      row = [];

      return;
    }

    row = tags?.data?.map((item) => {
      const actions = [
        {
          icon: <IconEdit />,
          action: () => onShow({
            id: item.id,
            type: 'edit-tag',
            reveal: true,
          }),
        },
        {
          icon: <IconTrash />,
          action: () => onShowModal({
            id: item.id,
            type: 'delete-tag',
            reveal: true,
          }),
        },
      ];

      return {
        id: item.id,
        items: [
          {
            label: <Flag status={item.name} />,
          },
          {
            label: item?.processes_count || '0',
            isBold: true,
          },
          {
            label: <Actions actions={actions} />,
          },
        ],
      };
    });

    onRowTag(row);
  }, [tags]);

  useEffect(() => {
    row_tag();
  }, [row_tag]);

  /**
   * Create row
   * user table.
   */
  const row_user = useCallback(async () => {
    let row = [];
    if (userList !== undefined && userList.length <= 0) {
      row = [];
      return;
    }
    // eslint-disable-next-line
    userList?.data?.sort((a, b) => a.ativo === b.ativo
      ? 0
      : a.ativo === 'S'
        ? -1
        : 0);
    row = userList?.data?.map((item) => {
      const actions = [
        {
          icon: <IconEdit />,
          action: () => onShow({
            id: item?.id,
            type: 'edit-usuario',
            reveal: true,
          }),
        },
        {
          icon: (item?.perfil?.perfil === 'Master' && <MinusCircle className="disabled" />)
            || (item?.ativo !== 'S' && <PlusCircle className="reactive" />)
            || <MinusCircle />,
          action: item?.perfil?.perfil !== 'Master' && (async () => {
            await api()
              .patch(`/user/${item?.id}`, {
                name: item?.nome,
                // phone: item?.telefone,
                idcliente: ! item?.client?.id ? undefined : item?.client?.id,
                idgrupo: ! item?.idcliente ? user.grupos[0].idgrupo : undefined,
                usertype: item?.perfil?.id,
                active: item?.ativo !== 'S',
              })
              .then(() => {
                render_users();
                alert.success(t('users.edit.response.success'));
              })
              .catch(() => alert.error(t('users.edit.response.error')));
          }),
        },
      ];
      return {
        id: item.id,
        items: [
          {
            label: (
              <Profile
                avatar={item?.avatar || item?.nome}
                hasImage={!! item?.avatar}
              />
            ),
          },
          {
            label: item.nome,
            isBold: true,
          },
          {
            label: item.email,
          },
          {
            label: item.telefone,
          },
          {
            label: item?.client?.cliente,
          },
          {
            label: <Flag status={item?.perfil?.perfil} />,
          },
          {
            label: <Actions actions={actions} />,
          },
        ],
        disabled: item.ativo !== 'S',
      };
    });
    onRowUser(row);
  }, [t, alert, userList, render_users]);

  useEffect(() => {
    row_user();
  }, [row_user]);

  /**
   * Create row
   * user table.
   */
  const row_historic = useCallback(async () => {
    let row = [];
    if (historicList !== undefined && historicList.length <= 0) {
      row = [];
      return;
    }
    row = historicList?.data?.map((item) => ({
      id: item.id,
      items: [
        {
          label: item.user_name,
          isBold: true,
        },
        {
          label: item.email,
        },
        {
          label: item.description,
        },
        {
          label: format(parseISO(item.data), 'dd/MM/yyyy - HH:mm:ss'),
          isBold: true,
        },
      ],
    }));
    onRowHistoric(row);
  }, [historicList]);

  useEffect(() => {
    row_historic();
  }, [row_historic]);

  const formattedWorkflowsList = useMemo(
    () => workflowsList?.data?.map((workflowData) => {
      const {
        id,
        name,
        users,
        themes,
        active,
        version,
        user_id,
        client_id,
        group: groupData,
        created_at,
        description,
        person_types,
      } = workflowData;

      let clientName = user.hierarquia?.find((item) => item.id === client_id)
        ?.cliente;

      clientName = clientName || groupData?.grupo || 'ICTS';

      const formattedCreatedAt = format(parseISO(created_at), 'dd/MM/yyyy');
      const personTypesIds = person_types.map((personType) => personType.id);
      let formattedPersonTypes = person_types.map((personType) => personType.description.match(/\b(\w)/g).join(''));
      formattedPersonTypes = formattedPersonTypes
        .map((word) => `${word.slice(0, 2)}(${word.slice(-1)})`)
        .join(', ');
      const hidden = ! active;
      const formattedThemes = themes.map(
        ({
          weight, theme, selected_risk_sources,
        }) => {
          const formattedRiskSources = {};
          selected_risk_sources.forEach(
            ({ name: riskSourceName, person_type }) => {
              const personTypeDescription = person_types.find(
                (personType) => personType.id === person_type,
              )?.description;
              if (formattedRiskSources[personTypeDescription]) {
                formattedRiskSources[personTypeDescription].push(
                  riskSourceName,
                );
              } else {
                formattedRiskSources[personTypeDescription] = [
                  riskSourceName,
                ];
              }
            },
          );
          return {
            id: theme.id,
            name: theme.name,
            weight,
            riskSources: formattedRiskSources,
          };
        },
      );

      return {
        id,
        client: clientName,
        name,
        version,
        description,
        hidden,
        createdAt: formattedCreatedAt,
        personTypes: formattedPersonTypes,
        personTypesIds,
        formattedThemes,
        themes,
        renderWorkflowsList,
        user_id,
        user_name: users?.nome,
        activateWorkflow: {
          name,
          description,
          client_id,
          person_types: person_types.map((item) => item.id),
          themes: themes.map(({ theme_id, weight, selected_risk_sources }) => (
            { id: theme_id, weight, risk_sources: selected_risk_sources.map((item) => item.id) }
          )),
          active: 1,
        },
      };
    }),
    [workflowsList, user.hierarquia, renderWorkflowsList],
  );
  /**
   * Body table.
   */
  const body = {
    tags: {
      state: rowTag,
      onState: onRowTag,
    },
    usuarios: {
      state: rowUser,
      onState: onRowUser,
    },
    historico: {
      state: rowHistoric,
      onState: onRowHistoric,
    },
    workflows: {
      state: null,
    },
    pricing: {
      state: null,
    },
    cnaes: {
      state: null,
    },
    terms: {
      state: null,
    },
    blacklist: {
      state: null,
    },
    'risk-sources': {
      state: null,
    },
    settings: {
      state: null,
    },
  };

  const noBodyScreens = Object.keys(body).filter((key) => ! body[key].state);

  const sortHistoricTypes = historicTypes?.data?.sort((a, b) => (a.action < b.action) && -1);

  const generateActionHeader = () => {
    if (query === 'settings' || query === 'risk-sources') {
      return null;
    }

    const clientSelect = 
      query !== 'workflows' && 
      query !== 'pricing' && (
      <ClientSelect
        user={user}
        value={group}
        onChange={(val) => newGroup(val)}
        placeholder={t('group')}
      />
    );
    
    const searchFieldComponent = 
      query !== 'workflows' && 
      query !== 'pricing' && 
      query !== 'cnaes' &&
      query !== 'terms' && (
      <SimpleInput
        isSearch
        name="search"
        icon={<IconSearch />}
        value={searchField}
        onValue={onSearchField}
        onChange={({ target }) => onSearchField(target.value)}
        placeholder={t('search')}
        appearance="secondary"
      />
    );
    let actionComponent = null;

    if (
      query !== 'pricing' &&
      query !== 'cnaes' &&
      query !== 'blacklist' &&
      query !== 'terms'
    ) {
      if (query !== 'historico') {
        actionComponent = (
          (query === 'tags' && user.isRevisor()) ||
          user.isGestor() ||
          user.isMaster()
        ) ? (
            <Button
              as={ query === 'workflows' ? Link : 'button' }
              to={ query === 'workflows' ? 'workflow' : '' }
              label={action(t)[query]}
              onClick={() => query !== 'workflows' && onShow({
                type: query,
                reveal: true,
              })}
            />
          ) : null;
      } else {
        actionComponent = (
          <SimpleSelect
            isMulti
            icon={ChevronDown}
            value={historicGroup}
            options={sortHistoricTypes?.map((item) => ({
              value: item.id,
              label: item.action,
            }))}
            onChange={(val) => onHistoricGroup(val)}
            isLoading={historicTypes === undefined}
            appearance="secondary"
            placeholder={t('action')}
          />
        );
      }
    }

    const dateFilter = query === 'pricing' && (
      <RangeDatePicker
        date={date}
        onDate={onDate}
        endDate={endDate}
        onEndDate={onEndDate}
        maxDate={new Date()}
      />
    );

    return (
      <>
        {clientSelect}
        {searchFieldComponent}
        {actionComponent}
        {dateFilter}
      </>
    );
  };

  return (
    <View>
      <Modal
        hasAction
        hasOverflow={false}
        show={showModal}
        title={t('tags.delete.title')}
        onShow={onShowModal}
        action={{
          label: t('tags.delete.action'),
          loading,
          onClick: async () => {
            onLoading(true);
            await api().delete(`/tag/${showModal.id}`)
              .then(() => {
                render_tags();
                onShowModal(false);
                alert.success(t('tags.delete.response.success'));
              })
              .catch(() => alert.error(t('tags.delete.response.error')));
            onLoading(false);
          },
        }}
      >
        <ModalBody>
          <span>
            <AlertTriangle />
          </span>

          <H6>{t('tags.delete.label')}</H6>
          <P2>{t('tags.delete.description')}</P2>
        </ModalBody>
      </Modal>

      <Helmet title={t('helmet')} />

      <Container>
        <Header head={head(t)} query={query} />
        
        <ActionHeader>
          { generateActionHeader() }
        </ActionHeader>

        { query === 'pricing' && <Pricing date={date} endDate={endDate} /> }

        { query === 'cnaes' && <Cnaes /> }

        { query === 'blacklist' &&
          <Blacklist
            termList={ termList }
            validatingTermList={ validatingTermList }
            termListMutate={ termListMutate }
          /> }

        { query === 'terms' && <Terms /> }

        { query === 'settings' && <Settings /> }

        { query === 'risk-sources' && <SelectedRiskSourceProvider><RiskSources /></SelectedRiskSourceProvider> }

        {query === 'workflows' && (
          <>
            <WorkflowHeader>
              <div>
                <ButtonLeft>
                  <Button
                    size="sm"
                    label={t("actives")}
                    appearance={activeWorkflow === 1 ? "primary" : "tertiary" }
                    onClick={() => setActiveWorkflow(1)}
                  />
                </ButtonLeft>
                <div >
                  <Button
                    size="sm"
                    label={t("suspended")}
                    appearance={activeWorkflow === 0 ? "primary" : "tertiary" }
                    onClick={() => setActiveWorkflow(0)}
                  />
                </div>
              </div>
              <WorkflowRightDiv>
                <WorkflowLeftSelect>
                  <SimpleSelect
                    icon={ChevronDown}
                    value={orderWorkflow}
                    options={workflowOptions.order?.filter((item, index) => index <= 2)}
                    onChange={(val) => { setOrderWorkflow(val)}}
                    isLoading={false}
                    placeholder={t('order_by')}
                    isSearchable={false}
                  />
                </WorkflowLeftSelect>
                <div style={{width: '200px'}}>
                  <SimpleInput
                    isSearch
                    name="search"
                    icon={<IconSearch />}
                    value={searchWorkflow}
                    onValue={setSearchWorkflow}
                    onChange={({ target }) => setSearchWorkflow(target.value)}
                    placeholder={t('search')}
                    appearance="secondary"
                  />
                </div>
              </WorkflowRightDiv>
            </WorkflowHeader>
            <WorkflowItemsContainer>
              {/* eslint-disable-next-line */}
              {formattedWorkflowsList?.length > 0
                ? formattedWorkflowsList?.sort((a, b) => (a.hidden === b.hidden ? 0 : a.hidden ? 1 : -1)).map((item) => ( //eslint-disable-line
                  <Card key={item.id} {...item} />
                ))
                : formattedWorkflowsList
                  ? <Empty />
                  : (
                    <div className="simple-loading">
                      <SimpleLoading />
                    </div>
                  )}
            </WorkflowItemsContainer>
          </>
        )}
        
        { (! noBodyScreens.includes(query) && query !== 'workflows') && (
          <>
            {body[query].state?.length > 0 ? ( //eslint-disable-line
              <Table
                body={body[query].state}
                onBody={body[query].onState}
                heading={table[query](t, onSorting[query])}
              />
            ) : body[query].state
              ? <Empty />
              : (
                <div className="simple-loading">
                  <SimpleLoading />
                </div>
              )}
          </>
        )}
        
        {(((query === 'tags' || query === 'usuarios' || query === 'historico') && body[query].state)
        || (query === 'workflows')) && (
          <Paginate
            size={size}
            pages={paginate[query].pages}
            total={paginate[query].total}
            onSize={onSize}
            perPage={paginate[query].perPage}
            className="m-auto m-t-48"
            onPaginate={onPage}
            currentPage={page}
          />
        )}
      </Container>

      {query === 'usuarios' && (
        <Modal
          show={show.reveal}
          title={modal(t)[show.type]?.head || ''}
          action={{
            label: modal(t)[show.type]?.action || '',
            loading,
            onClick: () => ref.current.submitForm(),
          }}
          onShow={() => {
            reset();
            setDeleteAvatarFlag(false)
            onShow(! show);
          }}
        >
          <ModalView>
            <P2>{modal(t)[show.type]?.description || ''}</P2>

            <Form ref={ref} onSubmit={(val) => create(val, show.type)}>
              {fields(t).map(
                ({
                  type, name, label, hasPrefix, placeholder,
                }) => {

                  const masked = {
                    pt_BR: maskedInputLength > 18 ? '+55 (99) 99999-9999' : '+55 (99) 9999-99999',
                    en_US: '+1 (999) 999-9999',
                    es_ES: '+34 999-999-999',
                  };

                  switch (type) {
                    case 'INPUT':
                      return (
                        <Input
                          key={name}
                          name={name}
                          mask={name === 'phone' ? masked[mask.value] : ''}
                          maskPlaceholder=""
                          label={label}
                          prefix={name === 'phone' && mask}
                          reveal={
                            ! (name === 'email' && show.type === 'edit-usuario')
                          }
                          options={
                            name === 'phone' && options.preferred_language
                          }
                          onPrefix={name === 'phone' && onMask}
                          hasPrefix={hasPrefix}
                          appearance="secondary"
                          placeholder={placeholder}
                          disabled={user.usuario.id_portal === show.id && name !== 'phone'}
                          watchValueLength={(length) => setMaskedInputLength(length)} 
                        />
                      );
                    case 'SELECT':
                      return (
                        <Select
                          key={name}
                          name={name}
                          icon={ChevronDown}
                          label={label}
                          options={options[name]}
                          onChange={(e) => {
                            if (name === 'usertype') {
                              setTypeSelected(e.value);
                            }
                          }}
                          appearance="secondary"
                          placeholder={placeholder}
                          isClearable={name === 'idcliente' && user.grupos.length > 0}
                          disabled={user.usuario.id_portal === show.id && name !== 'preferred_language'}
                        />
                      );
                    case 'UPLOAD':
                      return (
                        <Upload
                          ext="media"
                          key={name}
                          name={name}
                          label={label}
                          onClickDelete={() => setDeleteAvatarFlag(true)}
                        />
                      );
                    case 'CHECKBOX':
                      return user?.isMaster() && typeSelected === 3 ? (
                        <CheckWorkflow
                          key={name}
                          name={name}
                          label={label}
                          defaultChecked={selectedUser?.viewWorkflowCosts === 1}
                        />
                      ) : null;

                    default:
                      break;
                  }

                  return null;
                },
              )}
            </Form>
          </ModalView>
        </Modal>
      )}

      {query === 'tags' && (
        <CreateTag
          show={show}
          render={render_tags}
          onShow={onShow}
          clientId={group.value}
        />
      )}
    </View>
  );
};

export default Administration;

import React, { useState, useContext } from 'react';
import { useAlert } from 'react-alert';

import Echo from 'laravel-echo';
import { element } from 'prop-types';
import Pusher from 'pusher-js';
import {useAuth} from "~/services/store/auth";


const EchoContext = React.createContext();

export const EchoProvider = ({ children }) => {

  const { token } = useAuth();
  const [ echo, setEcho ] = useState(null);

  const alert = useAlert();

  if (token) {
    const client = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      wsHost: process.env.REACT_APP_PUSHER_HOST,
      wsPort: 6001,
      forceTLS: false,
      disableStats: true,
      authEndpoint: `${ process.env.REACT_APP_API_URL }/broadcasting/auth?accessToken=${ token }`,
    });

    try {
      if (!echo)
        setEcho(new Echo({
          broadcaster: 'pusher',
          namespace: '',
          client,
        }));
    } catch (ex) {
      alert.error('Missing PUSHER key');
    }
  } else {
    return () => {
      if (echo) {
        echo.disconnect();
        setEcho(null);
      }
    };
  }

  return <EchoContext.Provider value={ echo }>{ children }</EchoContext.Provider>;
};

/**
 * @type {() => Echo}
 */
export const useEcho = () => useContext(EchoContext);

EchoProvider.propTypes = {
  children: element.isRequired,
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { format } from 'date-fns';
import { oneOf } from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  formatDocument,formatErrorMessage, mapAddress, parseISO,reduce,
} from '~/hooks/helpers';
import { useApi } from "~/hooks";

import { useResultData } from '../../resultContext';
import GenericRegistrationData from './GenericRegistrationData';

const PersonRegistrationData = ({ personType }) => {
  const { id: processID } = useParams();
  const { t } = useTranslation('entities');
  const { t: T } = useTranslation('components');
  const { t: tAuth } = useTranslation('auth');
  const alert = useAlert();
  const api = useApi();

  const { entityNames, headers } = useResultData();

  const saveEditedField = async (url, data, successMessage, errorMessage) => {
    try {
      await api().post(url, data);
      alert.success(successMessage);
      return true;
    } catch ({ response }) {
      alert.error(formatErrorMessage(response, errorMessage,tAuth('expired')));
      return false;
    }
  };

  const data = [
    { label: t('cadastral_data.fields.person.0'), value: entityNames ,
      editable: personType == 3 ? true : false, 
      onSave: (name) => saveEditedField(`/process/${processID}/headerspf`, { name }, T('edit_process.name.success'), T('edit_process.name.error')),
    },
    {
      label: t('cadastral_data.fields.person.1'),
      value:
        headers?.entity?.death_record === 0
          ? t('cadastral_data.fields.no')
          : t('cadastral_data.fields.yes'),
    },
    {
      label: t('cadastral_data.fields.person.2'),
      value: formatDocument(headers?.entity?.document, personType),
      editable: personType == 3 ? true : false,
      onSave: (document) => saveEditedField(`/process/${processID}/headerspf`, { document }, T('edit_process.document.success'), T('edit_process.document.error')),
    },
    {
      label: t('cadastral_data.fields.person.3'),
      value: headers?.entity?.addresses.map((address) => mapAddress(T('items_list.address.tooltip'), address)),
    },
    {
      label: t('cadastral_data.fields.person.4'),
      value:
        headers?.entity?.birthday && headers?.entity.birthday !== '0000-00-00'
          ? format(parseISO(headers.entity.birthday), 'dd/MM/yyyy')
          : t('cadastral_data.fields.not_found'),
    },
    {
      label: t('cadastral_data.fields.person.5'),
      value: headers?.entity?.phones.map(({ country_code, area_code, number }) => `+${country_code} (${area_code}) ${number}`),
    },
    {
      label: t('cadastral_data.fields.person.6'),
      value: headers?.entity?.age
        ? `${headers?.entity?.age} anos`
        : t('cadastral_data.fields.not_found'),
    },
    {
      label: t('cadastral_data.fields.person.7'),
      value: headers?.entity?.emails.map(({ email }) => email),
    },
    {
      label: t('cadastral_data.fields.company.6'),
      value: (
        headers?.data?.new_dd
          ? headers?.entity?.supplier_code && reduce(headers?.entity?.supplier_code, 50)
          : headers?.data?.numerochamadointerno && reduce(headers?.data?.numerochamadointerno, 50)
        ) || t('cadastral_data.fields.not_found'),
      editable: headers?.data?.new_dd,
      onSave: (supplier_code) => saveEditedField(`/process/${processID}/headerspf`, { supplier_code }, T('edit_process.supplier_code.success'), T('edit_process.supplier_code.error')),
    },
    {
      label: t('cadastral_data.fields.person.8'),
      value: headers?.entity?.gender ?? t('cadastral_data.fields.not_found'),
    },
    {
      label: t('cadastral_data.fields.person.9'),
      value: headers?.entity?.family.map(({ name, documento, relationship_type }) => `${name}, CPF: ${formatDocument(documento, personType)}, ${relationship_type}`),
    },
    {
      label: t('cadastral_data.fields.person.10'),
      value: (headers?.entity?.document_name === 'estrangeiro' ? headers?.entity?.country : headers?.entity?.nationality?.pais) ?? t('cadastral_data.fields.not_found'),
    },
    {
      label: t('cadastral_data.fields.person.11'),
      value: headers?.entity?.custom_data?.find((customData) => customData.type === 'SOBRE')?.value || t('cadastral_data.fields.not_found'),
    },
  ];
  return <GenericRegistrationData data={data} />;
};



PersonRegistrationData.propTypes = {
  personType: oneOf([1, 3]).isRequired, // PF Nacional ou PF Estrageira
};

export default PersonRegistrationData;

import React, {
  useRef,
  useEffect,
} from 'react';

import { useField } from '@unform/core';
import { string, bool } from 'prop-types';

import { Checkbox } from '~/components';
import { useMe } from '~/services';

const CheckWorkflow = ({
  name,
  label,
  isDisabled,
  defaultChecked,
}) => {
  const { user } = useMe();

  const ref = useRef();

  const {
    fieldName,
    registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <Checkbox
      ref={ref}
      name={name}
      label={label}
      isDisabled={isDisabled}
      defaultChecked={defaultChecked}
    />
  );
};
CheckWorkflow.propTypes = {
  name: string.isRequired,
  defaultChecked: bool.isRequired,
  label: string.isRequired,
};

export default CheckWorkflow;

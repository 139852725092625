import React from 'react';

import { string, bool } from 'prop-types';

const RawViewer = ({ data, cndt }) => cndt
  ? (<pre style={{fontSize: "1.5rem"}}>{data}</pre>)
  : (<span style={{ fontSize: "1.5rem" }} >{data}</span>);

RawViewer.propTypes = {
  data: string.isRequired,
  cndt: bool
};

export default RawViewer;

import { Form as Unform } from '@unform/web';
import styled, { css } from 'styled-components';

const View = styled(Unform)`
  display: none;
  margin-top: 8px;
  align-items: center;

  ${({ show }) => show && css`
    display: flex;
  `}
`;

export default View;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { UnAuthActionPlanDetails } from './views';
import UnAuthHead from '~/layout/Head/UnAuthHead';

const UnAuthRouter = () => {
  return (
    <Routes>
      <Route path="/" element={ <UnAuthHead isAuthenticated={ false } /> }>
          <Route path="plano-de-acao/answer" element={ <UnAuthActionPlanDetails /> } />
      </Route>
    </Routes>);
};

export default UnAuthRouter;

import React, { useRef } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import {
  func,
  bool,
  string,
  number,
  oneOfType,
} from 'prop-types';

import { Input } from '~/components';
import { edited } from '~/hooks';

import View from './styles';

const EditInput = ({
  id,
  name,
  type,
  show,
  onShow,
  render,
  placeholder,
}) => {
  const ref = useRef();

  const alert = useAlert();

  const { t } = useTranslation('components');

  return (
    <View
      ref={ref}
      show={show}
      onSubmit={(value) => edited(t, id, value, name, alert, type, render, onShow)}
    >
      <Input
        name={name}
        size="xs"
        appearance="secondary"
        handleSubmit={() => ref.current.submitForm()}
        placeholder={placeholder}
      />
    </View>
  );
};

EditInput.propTypes = {
  id: oneOfType([string, number]),
  name: string,
  type: string,
  show: bool,
  render: func,
  onShow: func,
  placeholder: string,
};

EditInput.defaultProps = {
  id: 0,
  name: '',
  type: '',
  show: false,
  render: () => {},
  onShow: () => {},
  placeholder: '',
};

export default EditInput;

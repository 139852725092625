import styled from 'styled-components';

import { breakpoints } from '~/styles';

const View = styled.div`
  .apexcharts-legend-marker {
    border-radius: 50% !important;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    transform: scaleX(1.1);
  }
`;

export default View;

import React, { useMemo } from "react";
import { ChevronDown } from "react-feather";
import { useTranslation } from "react-i18next";

import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { func, string, oneOfType, instanceOf } from "prop-types";

import { SimpleSelect } from "~/components";

import { Head } from "./styles";

const Header = ({ date, changeYear, changeMonth }) => {
  const { t } = useTranslation("components");
  const months = useMemo(
    () => [
      t("datepicker.months.0"),
      t("datepicker.months.1"),
      t("datepicker.months.2"),
      t("datepicker.months.3"),
      t("datepicker.months.4"),
      t("datepicker.months.5"),
      t("datepicker.months.6"),
      t("datepicker.months.7"),
      t("datepicker.months.8"),
      t("datepicker.months.9"),
      t("datepicker.months.10"),
      t("datepicker.months.11"),
    ],
    [t]
  );

  const selectMonths = useMemo(
    () =>
      months.map((label, value) => ({
        label,
        value,
      })),
    [months]
  );

  const years = useMemo(
    () =>
      range(1940, getYear(new Date()) + 6, 1)
        .map((year) => ({
          value: year,
          label: year,
        }))
        .reverse(),
    []
  );

  const year = useMemo(() => getYear(date), [date]);
  const month = useMemo(() => getMonth(date), [date]);

  return (
    <Head>
      <div>
        <SimpleSelect
          icon={ChevronDown}
          value={{
            value: month,
            label: months[month],
          }}
          options={selectMonths}
          onChange={({ value }) => changeMonth(value)}
          appearance="secondary"
        />

        <SimpleSelect
          icon={ChevronDown}
          value={{
            value: year,
            label: year,
          }}
          options={years}
          onChange={({ value }) => changeYear(value)}
          appearance="secondary"
        />
      </div>

      <h4>{`${months[month]} ${year}`}</h4>
    </Head>
  );
};

Header.propTypes = {
  date: oneOfType([string, instanceOf(Date)]),
  changeYear: func,
  changeMonth: func,
};

Header.defaultProps = {
  date: "",
  changeYear: () => {},
  changeMonth: () => {},
};

export default Header;

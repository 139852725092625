import React from 'react';
import { AlignLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  bool,
  string,
  element,
  oneOfType,
} from 'prop-types';

import { Button, H4, P2 } from '~/components';

import View from './styles';

const Empty = ({
  icon,
  title,
  message,
  hasButton,
  hasMessage,
  ...rest
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation('components');

  return (
    <View {...rest} hasButton={hasButton}>
      <span>
        <span>
          {icon}
        </span>
      </span>

      <H4>{title || t('empty.title')}</H4>
      {hasMessage && (<P2>{message || t('empty.message')}</P2>)}

      {hasButton && (
        <Button
          label={t('empty.action')}
          onClick={() => navigate(-1)}
        />
      )}

    </View>
  );
};

Empty.propTypes = {
  icon: element,
  title: oneOfType([bool, string]),
  message: oneOfType([bool, string]),
  hasButton: bool,
  hasMessage: bool,
};

Empty.defaultProps = {
  icon: <AlignLeft />,
  title: false,
  message: false,
  hasButton: true,
  hasMessage: true,
};

export default Empty;

import styled from 'styled-components';

import { typograph } from '~/styles';

const { size, weight } = typograph;

const styles = {
  fontWeight: ({ isBold }) => (isBold ? weight.semiBold : weight.regular),
  textTransform: ({ isUppercase }) => (isUppercase ? 'uppercase' : 'inherit'),
};

export const H1 = styled.h1`
  ${{ ...styles }}
  font-family: ${typograph.title};
  font-size: ${size.l1};
  line-height: 5.2rem;
`;

export const H2 = styled.h2`
  ${{ ...styles }}
  font-family: ${typograph.title};
  font-size: ${size.l2};
  line-height: ${size.l3};
`;

export const H3 = styled.h3`
  ${{ ...styles }}
  font-family: ${typograph.title};
  font-size: ${size.l1};
  line-height: 3.6rem;
`;

export const H4 = styled.h4`
  ${{ ...styles }}
  font-family: ${typograph.title};
  font-size: ${size.m3};
  line-height: 3.6rem;
`;

export const H5 = styled.h5`
  ${{ ...styles }}
  font-family: ${typograph.title};
  font-size: ${size.m2};
  line-height: ${size.l1};
`;

export const H6 = styled.h6`
  ${{ ...styles }}
  font-family: ${typograph.title};
  font-size: ${size.m1};
  line-height: ${size.m1};
`;

export const P1 = styled.p`
  ${{ ...styles }}
  font-size: ${size.s3};
  line-height: ${size.m1};
`;

export const P2 = styled.p`
  ${{ ...styles }}
  font-size: ${size.s2};
  line-height: ${size.s3};
`;

export const P3 = styled.p`
  ${{ ...styles }}
  font-size: ${size.s1};
  line-height: ${size.s3};
`;

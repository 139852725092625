export const table = {
  clientTerms: (t, onSorting) => [
    {
      size: 'xxs',
      label: t('table.client_terms.0'),
      isSort: false,
      onSorting,
    },
    {
      size: 'sm',
      label: t('table.client_terms.1'),
      isSort: false,
      onSorting,
    },
    {
      size: 'sm',
      label: t('table.client_terms.2'),
      isSort: false,
      onSorting,
    },
    {
      label: '',
      isMulti: true,
    },
  ]
};

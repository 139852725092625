import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import { P2, H6,Layout } from '~/components';
import { breakpoints, colors, typograph } from '~/styles';
import { rgba } from '~/hooks';
// import colors from '~/styles/colors';
const { weight, size } = typograph;
const { gray, primary } = colors;

export const View = styled(Layout)`
    display: flex;
    place-items: center;
    flex-direction: row;
    padding:0 4rem;
    background-color: #FFF;
    height: 100%;
    padding-top: 11.2rem;
    margin-top: 0;

    .react-flow{
        height: 85vh;
        width: 100vw;
        box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193,193,193,0.29);
    }

    .react-flow__edge-textbg{
        fill: #f5f5f5;
    }

    h1{
        color: #FFF;
    }

    .react-flow__node{
        border: none;
    }
  
    .menu {
      position: fixed;
      width: 80px;
      height: 80px;
      top: 87px;
      right: 20px;
      background-color: ${colors.purple._500};
      color: #FFF;
      border-radius: 50px;
      child-align: middle;
      box-shadow: 2px 2px 3px #999;
      z-index: 10;
    }
  
    .menu > .my-float {
      margin-top:22px;
    }
`;

export const SideBarContainer = styled.div`
    position: fixed;
    width: 20vw;
    background: #f5f5f5;
    box-shadow: 0rem 0rem 1rem 0.5rem rgba(193,193,193,0.29);
    border-radius: 0.4rem;
    height: calc(100vh - 20rem);
    margin: 2rem;
    padding: 2rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    z-index: 9;
    right: 0;
    bottom: 0.5rem;

    h2{
        color: #00426B;
        font-weight: 600;
        border-bottom: 0.2rem solid #E9EEF3;
        margin: 1rem 0;
    }
`;

export const ChoosenEntity = styled.div`
    display: flex;
    box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193,193,193,0.29);
    place-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    justify-content: space-between;
    background: white;
    
    div {
        display: flex;
        place-items: center;
    }

    h3{
        margin-left: 1rem;
        color: #414251;
    }
`;

export const ProcessContainer = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    padding-bottom: 12rem;


    h2{
        color: #00426B;
        font-weight: 600;
        font-size: 2.4rem;
        margin-left: 4rem;
        margin-bottom: 2rem;
    }

    tr{
        padding: 2rem;
        margin-left: 4rem;
        margin-right: 7.5rem;
        display: flex;
        justify-content: space-between;
        color: #414251;
        height: 6rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
    }

    tbody{
        tr{
        box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193,193,193,0.29);
        margin-top: 1.4rem;
            td{
                border-top: none!important;
            }

        }
    }
`;
export const Search = styled(Unform)`
  display: flex;
  padding: 2.4rem;
  margin: 0 0 4rem 0;
  align-items: center;
  border-radius: 0.4rem;

  & > div {
    width: 100%;
    margin-right: 1.6rem;
  }
  
 



  button {
    height: fit-content;
    margin-top: auto;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;

    & > div {
      margin-right: 0;
    }

    & > button {
      width: 100%;
      margin-top: 1.6rem !important;
      justify-content: center;
    }

    
  }


`;
export const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    margin: 0 auto 2rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, '.1')};

    svg {
      width: 4rem;
      height: 4rem;
      stroke: ${primary._000};
    }
  }

  & > ${H6} {
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
    text-align: left !important;
  }

  & > p {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      margin-top: 2.4rem;
    }
  }
  &.modal-sm{
    min-height: 20rem;
  }
  &.modal-md{
    min-height: 26rem;
  }
  &.modal-lg {
    min-height: 32rem;
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { object } from 'prop-types';
import styled from 'styled-components';

import { P1, P3 } from '../Title';

const Snippet = styled(P1)`margin-bottom: 24px;`;

const MidiasViewer = ({ data }) => {
  const { t } = useTranslation('diligence');
  const displayData = Object.keys(data).map((url) => ({ url, snippet: data[url] }));
  return (
    <div>
      <Snippet>{displayData[0].snippet}</Snippet>
      <P3>
        {`${t('popup_source')}: `}
        <a href={displayData[0].url} target="_blank" rel="noreferrer">{displayData[0].url}</a>
      </P3>
    </div>
  );
};

MidiasViewer.propTypes = {
  data: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default MidiasViewer;

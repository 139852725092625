import React, { Fragment } from "react";
import * as Yup from "yup";

import { useAlert } from "react-alert";
import { Form } from "@unform/web";

import { useMe, error } from "~/services";
import { Modal, P2, Upload } from "~/components";
import { formatErrorMessage, useApi } from "~/hooks";

import { useSearch } from "../../../context/SearchProvider";
import { Batches } from "../../Batches";
import { useValidator } from "./validator";

import { ModalView, Search } from "../../styles";

export const BatchesHeader = () => {
  const api = useApi();
  const alert = useAlert();
  const { user, group } = useMe();
  const { schema } = useValidator();

  const FR = new FileReader();

  const {
    t,
    ref,
    tag,
    query,
    person,
    checked,
    workflow,
    uploadRef,
    renderBatches,
    attechmentFile,
    entityWorkflow,
    loadingBatches,
    personWorkflow,
    onLoadingBatches,
    setAttechmentFile,
    showAttechmentModal,
    setShowAttechmentModal,
  } = useSearch();

  const submitBatch = async (data) => {
    let fields = [];
    await api()
      .get(
        `/clients/getContent?content=campos_fonte_risco&workflowType=${person.value}&workflowId=[${workflow.value}]`
      )
      .then((res) => {
        res.data.map((val) => {
          let field = {
            id: val.id,
            name: val.search_type_id === 4
              ? `${val.name} ${val.fonte_risco_name.split(": ")[1]}`
              : val.name,
            risk_source_id: val.risk_source_id,
            fonte_risco_name: val.fonte_risco_name,
            mandatory: val.mandatory,
          };
          fields.push(field);
        });
      })
      .catch((err) => {
        alert.error(formatErrorMessage(err.response));
      });

    const temp = {
      associate: checked || false,
      lines: Number(data?.lines),
      person_type: person?.value,
      workflow_id: data?.workflow,
      fields: JSON.stringify(fields),
      jurisdiction_id: data?.jurisdiction || null,
      tag_id: tag?.value || null,
      person_workflow_id: personWorkflow?.value || null,
      entity_workflow_id: entityWorkflow?.value || null,
    };

    const queryUrl = `?client_id=${group?.value}&lines=${
      temp.lines
    }&person_type=${temp.person_type}&workflow_id=${temp.workflow_id}&fields=${
      temp.fields
    }&associate=${checked}&jurisdiction_id=${
      temp.jurisdiction_id || "null"
    }&tag_id=${temp.tag_id || "null"}&person_workflow_id=${
      temp.person_workflow_id || "null"
    }&entity_workflow_id=${temp.entity_workflow_id || "null"}`;

    await schema
      .validate(temp, { abortEarly: false })
      .then(async () => {
        onLoadingBatches(true);
        await api()
          .get("/batches/getPaternExcel" + queryUrl, { responseType: "blob" })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res?.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Spreadsheet.xls");
            document.body.appendChild(link);
            link.click();

            alert.success(t("batches.validations.responses.success"));
          });
        onLoadingBatches(false);
      })
      .catch((err) => {
        error(err, ref);
        onLoadingBatches(false);
      });
  };

  const submitUpload = async (data) => {
    await Yup.object()
      .shape({
        attechment: Yup.mixed().required(
          t("batches.validations.file_required")
        ),
      })
      .validate(data, { abortEarly: false })
      .then(async () => {
        setShowAttechmentModal(false);
        const toSubmit = {
          originalName: data.attechment.name,
          file: attechmentFile,
          mimeType: data.attechment.type,
          extension: data.attechment.name.split(".")[1],
          size: data.attechment.size,
        };

        await api()
          .post("/batches/create", toSubmit)
          .then(() => {
            setAttechmentFile(null);
            onLoadingBatches(false);
            alert.success(t("batches.creating.success"));
          })
          .catch(({ response }) => {
            let errs = "";
            if (!response?.data.message) {
              errs = Object.keys(response.data.data)
                .map((key) => response.data.data[key])
                .join(", ");
            } else {
              errs = response?.data.message;
            }
            alert.error(`${t("batches.creating.error")}: ${errs}`);
            onLoadingBatches(false);
          });

        await renderBatches();
      })
      .catch((err) => {
        alert.error(err.message);
        error(err, uploadRef);
        onLoadingBatches(false);
      });
  };

  FR.onload = (ev) => {
    onLoadingBatches(true);
    setAttechmentFile(ev.target.result);
  };

  return (
    <Fragment>
      <Search ref={ref} type={query} onSubmit={submitBatch}>
        <Batches />
      </Search>

      {(user.isMaster() || user.canOpenBatches()) && (
        <Form ref={uploadRef} onSubmit={submitUpload}>
          <Modal
            show={showAttechmentModal}
            onShow={setShowAttechmentModal}
            hasActions
            title={t("batches.modal.tittle")}
            action={{
              label: t("batches.actions.button.create_batch"),
              onClick: async () => {
                onLoadingBatches(true);
                uploadRef.current.submitForm();
              },
              loadingBatches,
            }}
          >
            <ModalView>
              <P2>{t("batches.modal.description")}</P2>
              <Upload
                onInput={(evt) => {
                  FR.readAsDataURL(evt.target.files[0]);
                }}
                name={"attechment"}
                isDocument
              />
            </ModalView>
          </Modal>
        </Form>
      )}
    </Fragment>
  );
};

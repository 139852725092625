import styled from 'styled-components';

import { Layout } from '~/components';
import colors from '~/styles/colors';

const View = styled(Layout)`
    span.view-items{
        color: ${colors.primary._000} !important;
        text-decoration: underline !important;
        cursor: pointer;
    }

    span.item{
        width: 100%;
        display: block;
    }
`;

export default View;

import React, {
  useContext, useState, createContext, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';

import { element } from 'prop-types';

import { useFetch, useApi } from '~/hooks';

import { useResultData } from './resultContext';

const MarkedItemsContext = createContext(null);

const MarkedItemsProvider = ({ children }) => {
  const { id: processID } = useParams();
  const [markedItems, setMarkedItems] = useState([]);
  const { addMutation } = useResultData();
  
  const api = useApi();

  const { data: fetchedMarkedItems, mutate } = useFetch({ url: `/process/${processID}/markedItems` });

  useEffect(() => {
    addMutation(mutate);
  }, [addMutation, mutate]);

  useEffect(() => {
    if (fetchedMarkedItems?.length > 0) {
      setMarkedItems(fetchedMarkedItems);
    }
  }, [fetchedMarkedItems]);

  const addMarkedItems = (items) => {
    if (items?.length > 0) {
      api().post('/riskItems/mark', {
        items,
      });

      setMarkedItems((old) => Array.from(new Set([...old, ...items])));
    }
  };

  const removeMarkedItems = (items) => {
    if (items?.length > 0) {
      api().delete('/riskItems/mark', {
        data: {
          items,
        },
      });

      setMarkedItems((old) => old.filter((item) => ! items.includes(item)));
    }
  };

  return (
    <MarkedItemsContext.Provider value={{
      markedItems,
      addMarkedItems,
      removeMarkedItems,
    }}
    >
      {children}
    </MarkedItemsContext.Provider>
  );
};

MarkedItemsProvider.defaultProps = {
  children: null,
};

MarkedItemsProvider.propTypes = {
  children: element,
};

export default MarkedItemsProvider;

/**
 * @typedef {Object} MarkedItems
 * @property {number[]} markedItems - lista de itens marcados
 * @property {(items: number[]) => void} addMarkedItems - função para marcar itens
 * @property {(items: number[]) => void} removeMarkedItems - função para desmarcar itens
 */

/**
 * Contexto que contém informações sobre os itens marcados
 *
 * @returns {MarkedItems}
 */
export const useMarkedItems = () => useContext(MarkedItemsContext);

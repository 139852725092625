import styled, { css } from "styled-components";

import { Layout, P1, P2 } from "~/components";
import { breakpoints, colors, typograph } from "~/styles";

const { gray, primary, status } = colors;
const { weight } = typograph;

export const View = styled(Layout)`
  margin-bottom: 4.8rem;

  .link {
    color: ${primary._000};
    text-decoration: underline;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    .sc-bQdQlF.lfnkct {
      align-items: flex-start;

      > *:first-of-type {
        margin-bottom: 1.6rem;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    thead {
      display: none;
    }
  }
`;

export const ActionsButton = styled.div`
  position: relative;

  :focus-within {
    & > div {
      display: block;
    }
  }

  > div {
    display: none;
    position: absolute;
    z-index: 10;
    top: 6rem;
    border-radius: 0.4rem;
    width: 21.6rem;
    background: ${gray._000};
    left: 50%;
    transform: translateX(-50%);
    padding: 0 1rem;
    box-shadow: 0 0 0.5rem ${gray._300};

    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      color: ${gray._500};
      padding: 1rem 0;
      cursor: pointer;

      :not(:last-of-type) {
        border-bottom: 0.1rem solid ${gray._200};
      }
    }
  }
`;

export const ActionsIcon = styled.div`
  svg {
    color: ${colors.support._100};
    background-color: ${colors.support._100}1A;
    margin-right: 0.5rem;
    padding: 0.4rem;
    border-radius: 50%;
  }
`;

export const Card = styled.div`
  background-color: ${gray._000};
  padding: 2.4rem 2.4rem 3.2rem 2.4rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid ${gray._200};
    padding-bottom: 1.6rem;

    p {
      color: ${gray._500};
    }
  }

  > div {
    padding: 2.4rem 0;
    border-bottom: 0.1rem solid ${gray._200};
  }

  > article {
    padding: 2.4rem 0;

    :not(:last-of-type) {
      border-bottom: 0.1rem solid ${gray._200};
    }

    p {
      color: ${gray._600};
    }

    ${P1} {
      font-weight: ${weight.semiBold};
    }

    ${P2} {
      span {
        color: ${gray._500};
      }
    }

    > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 3.2rem 0 2.4rem;
      gap: 2.4rem;
    }

    > table {
      margin-top: 1.6rem;
      width: 100%;
      border-collapse: collapse;

      thead,
      tbody {
        display: table-row-group;

        > tr {
          display: table-row !important;

          th,
          td {
            width: auto !important;
            flex: 1 !important;
            display: table-cell !important;
          }
        }
      }

      thead,
      thead > tr,
      thead > tr > * {
        background-color: ${gray._000} !important;
      }

      thead th {
        display: flex;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    > article > div {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    > article > div {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  & > ${P2} {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;

    & > div {
      margin-top: 1.6rem;
    }
  }
`;

export const ModalMessage = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${colors.status.error};
    margin-right: 1rem;
  }
`;

export const ActionMonitoring = styled.span`
  display: flex;

  & > svg {
    width: 3rem !important;
    stroke: ${status.success};
  }

  ${({ actived }) =>
    actived?.deleted_by
      ? css`
          cursor: not-allowed;
          & > svg * {
            stroke: ${gray._300};
          }
        `
      : actived &&
        css`
          & > svg * {
            stroke: ${primary._000};
          }
        `}
`;

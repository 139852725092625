import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-feather";
import { format } from "date-fns";

import { Modal, Button, Upload, P2, Select } from "~/components";
import { error, useMe } from "~/services";
import { useApi } from "~/hooks";

import { Form } from "@unform/web";

import * as Yup from "yup";

import { ModalView, MainForm, ErrorWrapper } from "./style";

const ConflictingSettings = () => {
  const { t } = useTranslation("settings");

  const api = useApi();
  const alert = useAlert();
  const { user, group, newGroup } = useMe();

  const [isLoading, setLoading] = useState({
    upload: false,
    download: false,
  });

  const [attechmentFile, setAttechmentFile] = useState(null);
  const [successText, setSuccessText] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const uploadRef = useRef();

  const FR = new FileReader();

  const grupos = user.hierarquia?.map((item) => ({
    value: item.id,
    label: item.cliente,
  }));

  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }

  if (grupos?.length > 1 && user.grupos && user.grupos.length > 0) {
    grupos.unshift({
      value: 0,
      label: user.grupos[0].grupo,
    });
  }

  const clearSuccessText = () => setTimeout(() => setSuccessText(null), 500);

  const handleClick = () => {
    if (!successText) {
      uploadRef.current.submitForm();
      return;
    }

    setShowUploadModal(false);
    clearSuccessText();
  };

  const handleClose = () => {
    setShowUploadModal(false);
    clearSuccessText();
  };

  const download = async () => {
    setLoading({ download: true });

    const now = format(new Date(), "dd/MM/yyyy");

    try {
      await api()
        .get(`/settings/download/conflict-spreadsheet`, {
          responseType: "blob",
        })
        .then(async (res) => {
          const buffer = await res.data.arrayBuffer();

          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = "conflict_spreadsheet.xlsx";
          document.body.appendChild(link);

          link.click();

          alert.success(t("conflict.download.success"));

          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        });
    } catch (error) {
      alert.error(`${t("conflict.download.error")}: ${error}`);
    } finally {
      setLoading({ download: false });
    }
  };

  const upload = async (data) => {
    setLoading({ upload: true });
    await Yup.object()
      .shape({
        attechment: Yup.mixed().required(
          t("conflict.validations.file_required")
        ),
      })
      .validate(data, { abortEarly: false })
      .then(async () => {
        const clientId = group.value;
        const groupId = user.grupos[0]?.idgrupo;

        if (!clientId && !groupId) {
          alert.error(t("conflict.upload.error"));
          return;
        }

        const toSubmit = {
          file: attechmentFile,
          size: data.attechment.size,
          mimeType: data.attechment.type,
          originalName: data.attechment.name,
          groupId: groupId ? groupId : undefined,
          clientId: clientId ? clientId : undefined,
          extension: data.attechment.name.split(".")[1],
        };

        await api()
          .post(`/settings/upload/conflict-spreadsheet`, toSubmit)
          .then(({ data }) => {
            if (data.type === "success") setSuccessText(data.message);
            setAttechmentFile(null);
          })
          .catch(({ response }) => {
            let error = null;

            if (response?.data?.errors) {
              Object.keys(response.data.errors).forEach((item) => {
                error = (
                  <ErrorWrapper>
                    <span>{t("conflict.upload.error")}:</span>
                    {response.data.errors[item].map((item) => (
                      <span>{item}</span>
                    ))}
                  </ErrorWrapper>
                );
              });
            }

            if (typeof response?.data.data === "string") {
              error = `${t("conflict.upload.error")}: ${response?.data.data}`;
            } else if (response?.data.data) {
              error = (
                <ErrorWrapper>
                  <span>{t("conflict.upload.error")}:</span>
                  {response.data.data.map((item) => (
                    <span>{item}</span>
                  ))}
                </ErrorWrapper>
              );
            }
            alert.error(error);
          })
          .finally(() => {
            setLoading({ upload: false });
          });
      })
      .catch((err) => {
        alert.error(err.message);
        error(err, uploadRef);
      })
      .finally(() => {
        setLoading({ upload: false });
      });
  };

  FR.onload = (ev) => {
    setAttechmentFile(ev.target.result);
  };

  return (
    <MainForm>
      <Button
        onClick={() => setShowDownloadModal(true)}
        label={t("conflict.download_button")}
      />

      <Button
        label={t("conflict.upload_button")}
        onClick={() => {
          uploadRef.current.reset();
          setShowUploadModal(true);
        }}
      />

      <Form ref={uploadRef} onSubmit={upload}>
        <Modal
          hasActions
          show={showUploadModal}
          goBackOnCancel={handleClose}
          onShow={setShowUploadModal}
          title={t(
            `${
              successText
                ? "conflict.upload.success"
                : "conflict.upload_modal.title"
            }`
          )}
          showCancel={successText ? false : true}
          action={{
            onClick: handleClick,
            loading: isLoading.upload,
            label: t(
              `${
                successText
                  ? "conflict.upload_modal.close_button"
                  : "conflict.upload_modal.primary_button"
              }`
            ),
          }}
        >
          <ModalView>
            {!successText ? (
              <>
                <P2>{t("conflict.upload_modal.description")}</P2>
                <Upload
                  ext="xlsx"
                  isDocument
                  onInput={(evt) => {
                    FR.readAsDataURL(evt.target.files[0]);
                  }}
                  name={"attechment"}
                />
              </>
            ) : (
              <P2>{successText}</P2>
            )}
          </ModalView>
        </Modal>
      </Form>

      <Modal
        show={showDownloadModal}
        onShow={setShowDownloadModal}
        hasActions
        title={t("conflict.download_modal.title")}
        action={{
          label: t("conflict.download_modal.primary_button"),
          onClick: async () => download(),
          loading: isLoading.download,
        }}
      >
        <ModalView>
          <P2>{t("conflict.download_modal.description")}</P2>
        </ModalView>
      </Modal>
    </MainForm>
  );
};

export default ConflictingSettings;

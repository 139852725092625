import * as Yup from "yup";
import { useSearch } from "../../../context/SearchProvider";

export const useValidator = () => {
  const { t } = useSearch();

  const schema = Yup.object().shape({
    associate: Yup.boolean(),
    lines: Yup.number()
      .min(1, t("batches.validations.lines.min", { min: 1 }))
      .max(3000, t("batches.validations.lines.max", { max: 3000 }))
      .required(t("batches.validations.lines.required")),
    person_type: Yup.number().required(t("batches.validations.personType")),
    workflow_id: Yup.number().required(t("batches.validations.workflow")),
    jurisdiction_id: Yup.number().nullable(),
    tag_id: Yup.number().nullable(),
    person_workflow_id: Yup.number().nullable(),
    entity_workflow_id: Yup.number().nullable(),
  });

  return {
    schema,
  };
};

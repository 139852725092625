import styled from 'styled-components';

import { colors, typograph } from '~/styles';

import { P2 } from '../Title';

const { gray, support } = colors;
const { weight, size } = typograph;

const View = styled.ul`
  > ul {
    display: flex;
    margin-top: .0rem;
    flex-direction: column;

    li {
      display: ${({ show }) => (show ? 'flex' : 'none')};
      padding: .8rem .6rem;
      margin-top: .4rem;
      align-items: center;

      span {
        color: ${gray._000} !important;
      }

      .address:hover {
        cursor: pointer;
        color: ${support._000};
      }

      &:first-of-type {
        display: flex;
      }

      ${P2} {
        color: ${gray._600};
        font-weight: ${weight.bold};
        line-height: ${size.m1};
      }

      ${P2}:not(:first-child) {
        margin-left: 2.0rem;
      }

      &:nth-child(odd) {
        background-color: ${gray._100};
      }
    }
  }

  & .chevron {
    transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

export default View;

import styled from 'styled-components';

import { breakpoints, colors } from '~/styles';

const { status, primary, gray } = colors;

export const View = styled.div`
  display: flex;
  width: 100%;

  > *:first-of-type {
    flex: 1;
  }

  .apexcharts-xaxis-label {
    fill: ${gray._500};
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    transform: scaleX(1.1);
  }
`;

export const Levels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-height: 47rem;

  span {
    padding: 0 1.6rem;
    display: flex;
    white-space: pre;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 33%;

    :nth-of-type(1) {
      color: ${status.error};
    }

    :nth-of-type(2) {
      color: ${primary._000};
    }

    :nth-of-type(3) {
      color: ${status.success};
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    display: none !important;
  }
`;

/* eslint-disable no-nested-ternary */
import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import { ChevronDown, Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import {
  H6,
  Flag,
  Table,
  Empty,
  Loading,
  TableRow,
  Container,
  ItemsList,
  Accordion,
  ProgressBar,
  SimpleLoading,
  ChartEvolution,
  SimpleSelect,
  RangeDatePicker,
} from '~/components';
import { masked, useFetch } from '~/hooks';
import { reduce, maskMoney } from '~/hooks/helpers';
import useWebSocketRefresh from '~/hooks/useWebSocketRefresh';
import { EditInput, ResultHead } from '~/layout';

import { Card } from '../../Search/Response/utils';
import table from './data';
import { Panel, View, Diligences } from './styles';

const Entities = () => {
  const [show, onShow] = useState(false);
  const [rows, onRows] = useState([]);
  const [selectedThemeId, setSelectedThemeId] = useState(null);
  const [allThemeDiligences, setAllThemeDiligences] = useState([]);
  const [showEditContact, onShowEditContact] = useState(false);
  const [showEditEmail, onShowEditEmail] = useState(false);
  const [showEditSupplierCode, onShowEditSupplierCode] = useState(false);
  const [selectedWorkflows, onSelectedWorkflows] = useState([]);
  const [date, onDate] = useState('');
  const [endDate, onEndDate] = useState('');

  const queryDate = useMemo(
    () => ({
      start: date
        ? format(date, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-01'),
      end: endDate
        ? format(endDate, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
    }),
    [date, endDate],
  );

  const navigate = useNavigate();
  const { t } = useTranslation('entities');

  const { id } = useParams();

  const mutations = [];

  const { data, mutate: render } = useFetch({ url: `/entities/${id}/headers`, mutations });
  const corporateData = useFetch({ url: `/entities/${id}/corporateData`, mutations })?.data?.data;
  const { data: diligences } = useFetch({ url: `/entities/${id}/processes`, mutations });
  const { data: status } = useFetch({ url: '/entities/getStatuses', mutations });
  const { data: themes } = useFetch({ url: `/entities/${id}/themes`, mutations });
  const themeDiligences = useFetch({
    url: selectedThemeId ? `/entities/${id}/themes/${selectedThemeId}/processes` : '',
    mutations,
  })?.data?.data;
  const { data: riskEvolution } = useFetch({
    url: `/entities/${data?.data?.cnpjrecebido?.replace(/\D/g, '')}/riskEvolution?from=${queryDate.start}&to=${queryDate.end}${selectedWorkflows?.length > 0 ? selectedWorkflows.map((item) => `&workflows[]=${item.value}`) : ''}`.replace(/,/g, ''),
    mutations,
  });
  const { data: workflows } = useFetch({ url: '/workflows', mutations });

  useWebSocketRefresh({
    channel: 'App.Events.ProcessStatusUpdated',
    events: diligences?.data?.map((process) => `App\\Events\\ProcessStatusUpdated\\${process.id}`),
    mutations,
  }, [diligences]);

  /**
   * Create row realized
   * diligences table.
   */
  const row_diligences = useCallback(async () => {
    let row = [];
    if (diligences !== undefined && diligences.length <= 0) {
      row = [];
      return;
    }
    row = diligences?.data?.map((item) => ({
      id: item.id,
      items: [
        {
          label: item?.show_id || item.id,
          dataLabel: t('diligences.dataLabels.0'),
        },
        {
          label: format(parseISO(item.dataabertura), 'dd/MM/yyyy'),
          dataLabel: t('diligences.dataLabels.1'),
        },
        {
          label: item.workflows?.map((subitem) => subitem.name),
          isBold: true,
          isMulti: true,
          dataLabel: t('diligences.dataLabels.2'),
        },
        {
          label: (
            <ProgressBar
              impact_id={item?.risk?.probabilidade_id || 1}
              percentage={Math.round(item?.risk?.risk || 0)}
            />
          ),
          dataLabel: t('diligences.dataLabels.3'),
        },
        {
          label: <Flag status={item?.new_status?.name || t('cadastral_data.fields.not_found')} />,
          dataLabel: t('diligences.dataLabels.4'),
        },
        {
          isArrow: true,
        },
      ],
    }));
    onRows(row);
  }, [diligences, t]);

  useEffect(() => {
    row_diligences();
  }, [row_diligences]);

  /*
   * Employee row
   */
  const employeeRow = useMemo(
    () => corporateData?.employees?.map((employee) => [
      {
        payload: employee.status === 'ATUAL' ? t('cadastral_data.fields.current')
          : employee.status === 'ANTERIOR' ? t('cadastral_data.fields.previous')
            : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
      {
        payload: employee.name || t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
      {
        payload: employee.relationship_name,
        type: 'TEXT',
      },
      {
        payload: employee.document ? masked(employee.document) : '-',
        type: 'DOCUMENT',
        action: () => navigate(
          `/pesquisa?q=diligencia&person=${
            employee.document.length <= 11 ? 1 : 2
          }&name=${employee.name}&document=${employee.document}`,
        ),
      },
      {
        payload: employee.relationship_start_date
          ? format(parseISO(employee.relationship_start_date), 'dd/MM/yyyy')
          : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
      {
        payload: employee.relationship_end_date
          ? format(parseISO(employee.relationship_end_date), 'dd/MM/yyyy')
          : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
    ]),
    [corporateData?.employees, t, navigate],
  );

  /*
   * Partnership participation row
   */
  const partnershipParticipationRow = useMemo(
    () => corporateData?.shareholding?.map((company) => [
      {
        payload: company.status === 'ATUAL' ? t('cadastral_data.fields.current')
          : company.status === 'ANTERIOR' ? t('cadastral_data.fields.previous')
            : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
      { payload: company.company_name, type: 'TEXT' },
      { payload: company.relationship_name, type: 'TEXT' },
      {
        payload: company.document ? masked(company.document) : '-',
        type: 'DOCUMENT',
        action: () => navigate(
          `/pesquisa?q=diligencia&person=${
            company?.document?.length <= 11 ? 1 : 2
          }&name=${company.company_name}&document=${company.document}`,
        ),
      },
      {
        payload: company.relationship_start_date
          ? format(parseISO(company.relationship_start_date), 'dd/MM/yyyy')
          : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
      {
        payload: company.relationship_end_date
          ? format(parseISO(company.relationship_end_date), 'dd/MM/yyyy')
          : t('cadastral_data.fields.not_found'),
        type: 'TEXT',
      },
    ]),
    [corporateData?.shareholding, t, navigate],
  );

  /*
   * Themes accordion data
   */
  useEffect(() => {
    if (themeDiligences) {
      if (allThemeDiligences.length > 0) {
        setAllThemeDiligences((items) => (items.find((item) => item.themeId === selectedThemeId)
          ? items
          : [
            ...items,
            { diligences: themeDiligences, themeId: selectedThemeId },
          ]));
      } else {
        setAllThemeDiligences((items) => [
          ...items,
          { diligences: themeDiligences, themeId: selectedThemeId },
        ]);
      }
    }
  }, [allThemeDiligences.length, selectedThemeId, themeDiligences]);

  /**
   * List workflows selected.
   */
  const list_workflows = useMemo(() => workflows?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  })), [workflows]);

  const allDiligencesRows = useMemo(
    () => allThemeDiligences?.map((item) => item?.diligences.map((diligence) => {
      const columns = [];
      columns.push({
        payload: `${t('diligences.dataLabels.5')}: ${diligence.id}`,
        type: 'TEXT',
      });
      columns.push({
        payload: { percentage: Math.round(diligence.risk) },
        type: 'PROGRESS_BAR',
      });
      columns.push({
        payload: (
          <>
            {`${t('diligences.dataLabels.1')}: `}
            <b>{format(parseISO(diligence.dataabertura), 'dd/MM/yyyy')}</b>
          </>
        ),
        type: 'TEXT',
      });
      return { columns, id: diligence.id, themeId: item.themeId };
    })),
    [allThemeDiligences, t],
  );

  if (! data) {
    return <Loading />;
  }

  const { cnpj } = data?.data;

  const { entity } = data;

  const type = cnpj.length === 14 ? 'person' : 'company';

  /**
   * Fields.
   */
  const fields = {
    person: [
      {
        label: t('cadastral_data.fields.person.0'),
        value: entity?.name || t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.person.1'),
        value: entity?.death_record === 0 ? t('cadastral_data.fields.no') : t('cadastral_data.fields.yes'),
      },
      {

        label: t('cadastral_data.fields.person.2'),
        value: masked(cnpj),
      },
      {

        label: `${t('cadastral_data.fields.person.3')} (${entity?.addresses.length || 0})`,
        value: (
          <ItemsList
            items={entity?.addresses}
            keys={[
              'place',
              'street',
              'number',
              'complement',
              'neighborhood',
              'city',
              'state',
              'country_name',
            ]}
            type="address"
          />
        ),
      },
      {

        label: t('cadastral_data.fields.person.4'),
        value:
          entity?.birthday && entity.birthday !== '0000-00-00'
            ? format(parseISO(entity.birthday), 'dd/MM/yyyy')
            : t('cadastral_data.fields.not_found'),
      },
      {

        label: `${t('cadastral_data.fields.person.5')} (${entity?.phones?.length || 0})`,
        value: (
          <ItemsList
            items={entity?.phones}
            keys={['country_code', 'area_code', 'number']}
            type="phone"
          />
        ),
      },
      {

        label: t('cadastral_data.fields.person.6'),
        value: entity?.age ? `${entity?.age} anos` : t('cadastral_data.fields.not_found'),
      },
      {

        label: `${t('cadastral_data.fields.person.7')} (${entity?.emails?.length || 0})`,
        value: <ItemsList items={entity?.emails} keys={['email']} />,
      },
      {

        label: t('cadastral_data.fields.person.8'),
        value: entity?.gender || t('cadastral_data.fields.not_found'),
      },
      {

        label: `${t('cadastral_data.fields.person.9')} (${entity?.family?.length || 0})`,
        value: (
          <ItemsList
            items={entity?.family}
            keys={['name', 'documento', 'relationship_type']}
            type="family"
          />
        ),
      },
      {

        label: t('cadastral_data.fields.person.10'),
        value: entity?.nationality?.pais || t('cadastral_data.fields.not_found'),
      },
    ],

    company: [
      {

        label: t('cadastral_data.fields.company.0'),
        value: entity?.company_name || t('cadastral_data.fields.not_found'),
      },
      {

        label: `${t('cadastral_data.fields.company.1')} (${entity?.addresses.length || 0})`,
        value: (
          <ItemsList
            items={entity?.addresses}
            keys={[
              'place',
              'street',
              'number',
              'complement',
              'neighborhood',
              'city',
              'state',
              'country_name',
            ]}
            type="address"
          />
        ),
      },
      {

        label: t('cadastral_data.fields.company.2'),
        value: entity?.trade_name || t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.company.3'),
        value:
          (
            <>
              <span className="data" title={entity?.contact || t('cadastral_data.fields.not_found')}>
                {(entity?.contact && reduce(entity?.contact, 30)) || t('cadastral_data.fields.not_found')}
              </span>
              <Edit
                className="edit"
                onClick={() => onShowEditContact(! showEditContact)}
              />
              <EditInput
                id={id}
                name="contact"
                type="entities"
                show={showEditContact}
                render={render}
                onShow={onShowEditContact}
                placeholder={t('cadastral_data.fields.edit.contact')}
              />
            </>
          ) || t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.company.4'),
        value: entity?.document ? masked(entity.document) : t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.company.5'),
        value:
          (
            <>
              <span className="data">{entity?.email || t('cadastral_data.fields.not_found')}</span>
              <Edit
                className="edit"
                onClick={() => onShowEditEmail(! showEditEmail)}
              />
              <EditInput
                id={id}
                name="email"
                type="entities"
                show={showEditEmail}
                render={render}
                onShow={onShowEditEmail}
                placeholder={t('cadastral_data.fields.edit.email')}
              />
            </>
          ) || t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.company.6'),
        value:
          (
            <>
              <span className="data" title={entity?.supplier_code || t('cadastral_data.fields.not_found')}>
                {(entity?.supplier_code && reduce(entity?.supplier_code, 50)) || t('cadastral_data.fields.not_found')}
              </span>
              <Edit
                className="edit"
                onClick={() => onShowEditSupplierCode(! showEditSupplierCode)}
              />
              <EditInput
                id={id}
                name="supplier_code"
                type="entities"
                show={showEditSupplierCode}
                render={render}
                onShow={onShowEditSupplierCode}
                placeholder={t('cadastral_data.fields.edit.supplier_code')}
              />
            </>
          ) || t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.company.7'),
        value: entity?.website || t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.company.8'),
        value:
          entity?.founded_at
          && ! (
            entity?.founded_at.split('-').includes('00')
            || entity?.founded_at.split('-').includes('0000')
          )
            ? format(new Date(entity.founded_at), 'dd/MM/yyyy')
            : t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.company.9'),
        value: entity?.main_cnae?.codigo
          ? `${entity?.main_cnae?.codigo} / ${entity?.main_cnae?.descricao}`
          : t('cadastral_data.fields.not_found'),
      },
      {

        label: `${t('cadastral_data.fields.company.10')} (${entity?.filiais?.length || 0})`,
        value: (
          <ItemsList
            items={entity?.filiais}
            keys={['company_name', 'document', 'state']}
            type="filiais"
          />
        ),
      },
      {

        label: t('cadastral_data.fields.company.11'),
        value:
          type === 'company' && entity?.share_capital
            ? maskMoney(entity.share_capital, 'BRL')
            : t('cadastral_data.fields.not_found'),
      },
      {

        label: t('cadastral_data.fields.company.12'),
        value: entity?.legal_nature || t('cadastral_data.fields.not_found'),
      },
      {
        label: t('cadastral_data.fields.company.13'),
        value: (
          <ItemsList
            items={entity?.secondary_cnaes}
            keys={['codigo', 'descricao']}
            type="cnae"
          />
        ),
      },
    ],
  };

  return (
    <View>
      <Container>
        <ResultHead
          title={data?.data?.razaosocial ? data.data.razaosocial : t('cadastral_data.fields.not_found')}
          pageTitle={{title: entity.name ?? null}}
        />

        <Card
          id={id}
          show={show}
          title={(
            <span>
              {`${t('cadastral_data.card_title')} ${
                data
                  ? format(parseISO(data.data.dataabertura), 'dd/MM/yyyy')
                  : ''
              }`}
            </span>
          )}
          fields={fields[type]}
          onShow={onShow}
          hasTags={false}
          processId={Number(id)}
          isEntity
          themes={themes}
          status={status?.data}
          document={entity?.document || cnpj}
          workflows={list_workflows}
          renderHeader={render}
          status_entidade={data?.data?.status_entidade}
        />

        <Accordion columns={[{ payload: t('corporate_data.title'), type: 'TEXT' }]}>
          {corporateData
          && Object.keys(corporateData)
            .map((key) => corporateData[key])
            .reduce((acc, curr) => acc + curr.length, 0) > 0 ? (
              <>
                {corporateData?.employees?.length > 0 && (
                  <Panel>
                    <H6>{t('corporate_data.socios.title')}</H6>
                    <table>
                      <thead>
                        <TableRow
                          as="th"
                          columns={[
                            {
                              payload: t('corporate_data.socios.columns.0'),
                              type: 'HEAD',
                            },
                            {
                              payload: t('corporate_data.socios.columns.1'),
                              type: 'HEAD',
                            },
                            {
                              payload: t('corporate_data.socios.columns.2'),
                              type: 'HEAD',
                            },
                            {
                              payload: t('corporate_data.socios.columns.3'),
                              type: 'HEAD',
                            },
                            {
                              payload: t('corporate_data.socios.columns.4'),
                              type: 'HEAD',
                            },
                            {
                              payload: t('corporate_data.socios.columns.5'),
                              type: 'HEAD',
                            },
                          ]}
                        />
                      </thead>
                      <tbody>
                        {employeeRow?.map((partnerColumn) => (
                          <TableRow
                            key={partnerColumn[2]} // document
                            columns={partnerColumn}
                          />
                        ))}
                      </tbody>
                    </table>
                  </Panel>
                )}
                {corporateData?.shareholding?.length > 0 && (
                  <Panel>
                    <H6>{t('corporate_data.participacoes_societarias.title')}</H6>
                    <table>
                      <thead>
                        <tr>
                          <TableRow
                            columns={[
                              {
                                payload: t(
                                  'corporate_data.participacoes_societarias.columns.0',
                                ),
                                type: 'HEAD',
                              },
                              {
                                payload: t(
                                  'corporate_data.participacoes_societarias.columns.1',
                                ),
                                type: 'HEAD',
                              },
                              {
                                payload: t(
                                  'corporate_data.participacoes_societarias.columns.2',
                                ),
                                type: 'HEAD',
                              },
                              {
                                payload: t(
                                  'corporate_data.participacoes_societarias.columns.3',
                                ),
                                type: 'HEAD',
                              },
                              {
                                payload: t(
                                  'corporate_data.participacoes_societarias.columns.4',
                                ),
                                type: 'HEAD',
                              },
                              {
                                payload: t(
                                  'corporate_data.participacoes_societarias.columns.5',
                                ),
                                type: 'HEAD',
                              },
                            ]}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {partnershipParticipationRow?.map(
                          (partnershipParticipationColumn) => (
                            <TableRow
                              key={partnershipParticipationColumn[2]} // document
                              columns={partnershipParticipationColumn}
                            />
                          ),
                        )}
                      </tbody>
                    </table>
                  </Panel>
                )}
              </>
            ) : (
              <Empty hasButton={false} />
            )}
        </Accordion>

        <Accordion
          initialState
          columns={[{ payload: t('chart.title'), type: 'TEXT' }]}
        >
          <div className="inputs">
            <RangeDatePicker
              date={date}
              onDate={onDate}
              endDate={endDate}
              onEndDate={onEndDate}
              maxDate={new Date()}
            />

            <SimpleSelect
              isMulti
              icon={ChevronDown}
              value={selectedWorkflows}
              options={list_workflows}
              onChange={(val) => onSelectedWorkflows(val)}
              appearance="secondary"
              placeholder={t('chart.workflow')}
            />
          </div>
          <ChartEvolution data={riskEvolution} />
        </Accordion>

        {themes?.data?.map(
          ({
            id: themeId,
            name,
            alerts,
            processes_count,
          }) => (
            <Accordion
              key={name}
              columns={[
                { payload: name, type: 'TEXT' },
                { payload: { percentage: 0 }, type: 'PROGRESS_BAR' },
                {
                  payload: [Math.round(alerts), processes_count],
                  type: 'ALERT',
                },
              ]}
              onChange={() => {
                setSelectedThemeId(themeId);
              }}
            >
              {allDiligencesRows?.find((diligenceRows) => diligenceRows
                .find((row) => row.themeId === themeId)) ? (
                  <>
                    {allDiligencesRows?.map((diligenceRows) => diligenceRows.map(
                      (row) => row.themeId === themeId && (
                        <Accordion
                          key={row.id}
                          columns={row.columns}
                          bodyClassName="hide-empty"
                          redirect={`/pesquisa/diligencia/${row.id}`}
                        />
                      ),
                    ))}
                  </>
                ) : (
                  <SimpleLoading />
                )}
            </Accordion>
          ),
        )}

        {diligences?.data?.length > 0 && (
          <Diligences>
            <H6>{t('diligences.title')}</H6>
            <Table
              body={rows}
              route="/pesquisa/diligencia"
              onBody={onRows}
              heading={table}
            />
          </Diligences>
        )}
      </Container>
    </View>
  );
};

export default Entities;

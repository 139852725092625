import styled, { css } from 'styled-components';

import { colors, typograph, easing } from '~/styles';

const { gray, primary, support, secondary } = colors;

const { basic } = easing;

const { size: font, weight } = typograph;

export const View = styled.div`
  width: fit-content;
  display: flex;
  position: relative;
  align-items: center;
  ${({space}) => space && css`
    padding-bottom: ${space};
  `}

  input {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }

  label,
  & > span {
    transition: all 0.3s ${basic};
  }

  & > span {
    border: .1rem solid ${gray._400};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    color: ${gray._500};
    line-height: ${font.m1};
  }

  &:hover {
    & > span {
      border-color: ${primary._000};
    }

    label {
      color: ${primary._000};
    }
  }

  ${({ selected }) => selected && css`
    & > span {
      border-color: ${secondary};
      background-color: ${secondary};

      svg {
        color: ${gray._000};
      }
    }

    label {
      font-weight: ${weight.semiBold};
    }

    &:hover {
      & > span {
        border-color: ${secondary};
      }

      label {
        color: ${gray._500};
      }
    }
  `}

  ${({ isDisabled }) => isDisabled && css`
    & > span {
      border-radius: 50% !important;
      transform: scale(0.6);
    }
  `}

  ${({ size }) => size === 'sm' && css`
    & > span {
      height: 1.6rem;
      min-width: 1.6rem;
      margin-${({ rtl }) => (rtl ? 'right' : 'left')}: .8rem;
      border-radius: .4rem;
    }

    label {
      font-size: ${font.s2};
    }
  `}

  ${({ size }) => size === 'md' && css`
    & > span {
      height: 2.4rem;
      width: 2.4rem;
      min-width: 2.4rem;
      margin-${({ rtl }) => (rtl ? 'right' : 'left')}: 1.2rem;
      border-radius: .6rem;
    }

    label {
      font-size: ${font.s3};
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${View} {
    & > span > svg {
      display: none;
    }

    &:not(:first-of-type) {
      margin-top: 1.6rem;
    }

    input[type=checkbox]:checked ~ {
      span {
        border-color: ${primary._000};
        background-color: ${primary._000};

        svg {
          color: ${gray._000};
          display: flex;
        }
      }

      label {
        font-weight: ${weight.semiBold};
      }
    }

    input[type=checkbox]:checked:hover ~ {
      span {
        border-color: ${primary._000};
      }

      label {
        color: ${gray._500};
      }
    }
  }
`;

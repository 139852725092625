import React, { useRef, useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { ChevronDown } from "react-feather";
import { useTranslation } from "react-i18next";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { P2, Modal, Input, Select, SimpleLoading, Radio } from "~/components";
import { useApi, formatErrorMessage } from "~/hooks";
import { error, schema_tag } from "~/services";

import View from "./styles";

const CreateTag = ({
  show,
  render,
  onShow,
  options,
  clientId,
  updateTag,
  hasDelete,
  processId,
  isReplace,
  renderList,
  hasAssociatedDiligences,
}) => {
  const [loading, onLoading] = useState(false);

  const ref = useRef();

  const alert = useAlert();

  const { t, ready } = useTranslation("tags", { useSuspense: false });
  const { t: tAuth } = useTranslation("auth");
  const api = useApi();
  const data = {
    tags: {
      head: t("create.label"),
      action: t("create.action"),
      description: t("create.description"),
    },

    assign: {
      head: t("assign.label"),
      action: t("assign.action"),
      description: t("assign.description"),
    },

    "edit-tag": {
      head: t("edit.label"),
      action: t("edit.action"),
      description: t("edit.description"),
    },

    replace: {
      head: t("replace.label"),
      action: t("replace.action"),
      description: t("replace.description"),
    },
  };

  /**
   * Initial data.
   */
  useEffect(() => {
    if (show) {
      if (show.type === "edit-tag") {
        const replace = async () => {
          await api()
            .get(`/tag/show/${show.id}`)
            .then((res) => {
              ref.current.setData({
                name: res.data.data[0].name,
              });
            });
        };

        replace();
      }

      if (show.type === "replace") {
        const replace = async () => {
          await api()
            .get(`/tag/show/${show.id}`)
            .then((res) => {
              ref.current.setData({
                name: {
                  value: res.data.data[0].id,
                  label: res.data.data[0].name,
                },
              });
            });
        };

        replace();
      }
    }
  }, [show]);

  /**
   * Reset form.
   */
  const reset = () => {
    ref.current.reset();
  };

  /**
   * Updated list tag.
   */
  const updated = async (val) => {
    onLoading(true);

    await api()
      .post("/tag", {
        name: val,
        clientId,
      })
      .then(async ({ data: res }) => {
        await ref.current.setData({
          name: {
            value: res.tag.id,
            label: res.tag.name,
          },
        });

        renderList();
        alert.success(t("update.response.success"));
      })
      .catch(() => alert.error(t("update.response.error")));

    onLoading(false);
  };

  /**
   * Create and
   * edit tag.
   */
  const create = async (val, type) => {
    const tag = async () => {
      onLoading(true);

      await api()
        .post("/tag", {
          name: val.name,
          clientId,
        })
        .then(({ data: res }) => {
          reset();
          render();

          if (isReplace) updateTag({ value: res.tag.id, label: res.tag.name });

          onShow(false);
          alert.success(t("create.response.success"));
        })
        .catch(() => {
          onShow(false);
          alert.error(t("create.response.error"));
        });

      onLoading(false);
    };

    const assign = async () => {
      onLoading(true);

      await api()
        .post("/process/tag/associate", {
          tagId: val.name,
          hasAssociatedDiligences,
          replicationType: val.tagActionReplication,
          processId,
        })
        .then(() => {
          reset();
          render();
          onShow(false);
          alert.success(t("assign.response.success"));
        })
        .catch(({ response }) => {
          onShow(false);
          alert.error(
            formatErrorMessage(
              response,
              t("assign.response.error"),
              tAuth("expired")
            )
          );
        });

      onLoading(false);
    };

    const replace = async () => {
      onLoading(true);

      await api()
        .post("/process/tag/associate/update", {
          tagId: val.name,
          hasAssociatedDiligences,
          replicationType: val.tagActionReplication,
          process: processId,
        })
        .then(() => {
          reset();
          render();
          onShow(false);
          alert.success(t("replace.response.success"));
        })
        .catch(({ response }) => {
          onShow(false);
          alert.error(
            formatErrorMessage(
              response,
              t("replace.response.error"),
              tAuth("expired")
            )
          );
        });

      onLoading(false);
    };

    const edit_tag = async () => {
      onLoading(true);

      await api()
        .patch(`/tag/${show.id}`, {
          name: val.name,
          clientId,
        })
        .then(() => {
          reset();
          render();
          onShow(false);
          alert.success(t("edit.response.success"));
        })
        .catch(({ response }) =>
          alert.error(
            formatErrorMessage(
              response,
              t("edit.response.error"),
              tAuth("expired")
            )
          )
        );

      onLoading(false);
    };

    await Yup.object()
      .shape(schema_tag)
      .validate(val, {
        abortEarly: false,
      })
      .then(() => {
        switch (type) {
          case "tags":
            tag();
            break;

          case "assign":
            assign();
            break;

          case "replace":
            replace();
            break;

          case "edit-tag":
            edit_tag();
            break;

          default:
            break;
        }
      })
      .catch((err) => error(err, ref));
  };

  /**
   * Deleted tag.
   */
  const deleted = async () => {

    let replicationType = ref.current.getFieldValue('tagActionReplication')
    if (typeof replicationType === 'boolean')
      replicationType = 'all'

    if (hasAssociatedDiligences) {
      try {
        await Yup.object()
          .shape({
            hasAssociatedDiligences: schema_tag.hasAssociatedDiligences,
            tagActionReplication: schema_tag.tagActionReplication
          })
          .validate({
            tagActionReplication: ref.current.getFieldValue('tagActionReplication'),
            hasAssociatedDiligences: ref.current.getFieldValue('hasAssociatedDiligences')
          }, {
            abortEarly: false,
          })

      } catch (err)  {
          error(err, ref)
          return
      }
    }

    onLoading(true);

    await api()
      .post("/process/tag/associate/remove", {
        tagId: show.id,
        hasAssociatedDiligences,
        replicationType,
        processId,
      })
      .then(() => {
        reset();
        render();
        onShow(false);
        alert.success(t("disassociate.response.success"));
      })
      .catch(({ response }) =>
        alert.error(
          formatErrorMessage(
            response,
            t("disassociate.response.error"),
            tAuth("expired")
          )
        )
      );

    onLoading(false);
  };

  if (!ready) {
    return <SimpleLoading />;
  }

  return (
    <Modal
      show={show.reveal}
      title={data[show.type]?.head || ""}
      action={{
        label: data[show.type]?.action || "",
        loading,
        onClick: () => ref.current.submitForm(),
      }}
      goBackOnCancel={() => {
        onShow(false);
        reset();
      }}
      onShow={onShow}
      subAction={
        hasDelete &&
        (show.type === "edit-tag" || show.type === "replace") && {
          label: t("delete.action"),
          onClick: () => deleted(),
        }
      }
    >
      <View>
        <P2>{data[show.type]?.description || ""}</P2>

        <Form ref={ref} onSubmit={(val) => create(val, show.type)}>
          <Input
            reveal={false}
            name="hasAssociatedDiligences"
            value={hasAssociatedDiligences}
          />
          {show?.type === "assign" || show?.type === "replace" ? (
            <Select
              isClearable
              icon={ChevronDown}
              name="name"
              label={t("field.label")}
              created={updated}
              options={options}
              isLoading={loading}
              appearance="secondary"
              placeholder={t("field.placeholder")}
              menuPosition="fixed"
              onInputChange={(inputValue) =>
                inputValue.length <= 32 ? inputValue : inputValue.substr(0, 32)
              }
            />
          ) : (
            <Input
              name="name"
              label={t("field.label")}
              maxLength={32}
              appearance="secondary"
              placeholder={t("field.placeholder")}
            />
          )}

          {hasAssociatedDiligences && (
            <div className="has-main-diligence"> 
              <Radio
                name="tagActionReplication"
                labelDir="row"              
                options={[
                  {
                    id: "all",
                    value: "all",
                    label: t("field.radio.all"),
                  },
                  {
                    id: "originals",
                    value: "originals",
                    label: t("field.radio.originals"),
                  },
                ]}
              /> 
            </div>
          )}
        </Form>
      </View>
    </Modal>
  );
};

CreateTag.defaultProps = {
  show: false,
  render: () => {},
  onShow: () => {},
  options: [],
  clientId: 0,
  processId: 0,
  renderList: () => {},
  isReplace: false,
  hasDelete: false,
  updateTag: () => {},
  hasAssociatedDiligences: false,
};

export default CreateTag;

import styled, { css } from 'styled-components';

import {
  Layout, P1, H4, P2,
} from '~/components';
import { breakpoints, colors } from '~/styles';

const {
  gray, primary, secondary,
} = colors;
const display = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 0.8rem;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (min-width: 1500px) {
    max-width: 1350px;
  }

  @media screen and (min-width: 1536px) {
    max-width: 1440px;
  }
  @media screen and (max-width: 1535px) {
    max-width: 1248px;
  }
  @media screen and (max-width: 1366px) {
    max-width: 1140px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 960px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 720px;
  }
  @media screen and (max-width: 768px) {
    max-width: 540px;
    overflow: hidden;
  }
`;

export const View = styled(Layout)`
  margin-bottom: 4.8rem;

  .link {
    color: ${primary._000};
    text-decoration: underline;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    .sc-bQdQlF.lfnkct {
      align-items: flex-start;

      > *:first-of-type {
        margin-bottom: 1.6rem;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    thead {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 1rem 3.2rem;
  ${display};
  height: 100%;
  border-radius: 0.2rem;
  flex-direction: column;
  background-color: ${gray._000};

  ${H4},
  ${P2} {
    text-align: center;
  }

  ${H4} {
    color: ${primary} !important;
    margin: 4rem auto 1.2rem auto;
  }

  ${P2} {
    color: ${primary._000};
    margin-bottom: 5.2rem;
  }
`;

import styled, { css } from 'styled-components';

import { colors, typograph, easing } from '~/styles';
import { P3 } from '../Title';

const {
  gray,
  primary,
  status,
} = colors;

const {
  basic,
  rubber,
} = easing;

const {
  size,
} = typograph;

export const View = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => props.dir ?? 'column-reverse'};

  input {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    position: absolute;
  }

  label {
    color: '#414251';
    font-size: ${size.s2};
    line-height: ${size.m1};
  }

  > span {
    width: 2.0rem;
    height: 2.0rem;
    border: .1rem solid ${gray._400};
    display: flex;
    flex-shrink: 0;
    transition: all .4s ${basic};
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: '#414251';

    &::after {
      width: .8rem;
      height: .8rem;
      content: '';
      transition: all .4s ${rubber};
      border-radius: 50%;
      background-color: transparent;
    }
  }

  ${({ checked }) => checked && css`
    span {
      border-color: ${primary._000};

      &::after {
        width: 1.2rem;
        height: 1.2rem;
        background-color: ${primary._000};
      }
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.dir ?? 'column'};
  width: 100%;
  align-items: flex-start;
  justify-content: ${(props) => props.justify ?? 'space-around'};
  gap: 2.4rem;
`;

export const Error = styled(P3)`
  color: ${status.error};
  margin-top: .4rem;
`;

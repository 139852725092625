const table = (t, onSorting) => [
  {
    label: t('table.head.entity'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
    label: t('table.head.evaluation'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
    label: t('table.head.opened'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
    label: t('table.head.due_date'),
    isSort: true,
    onSorting,
  },
  {
    label: t('table.head.responsible'),
    isSort: true,
    onSorting,
  },
  {
    label: t('table.head.subject'),
    isSort: true,
    onSorting,
  },
  {
    label: t('table.head.status'),
    isSort: true,
    onSorting,
  },
  {
    size: 'xxs',
  },
];

export default table;

import styled, { css } from 'styled-components';

import { P1, P3 } from '~/components/Title';
import { colors, typograph } from '~/styles';

const { gray, status, support } = colors;

const { weight } = typograph;

const types = {
  info: 'info',
  error: 'error',
  warning: 'warning',
  success: 'success',
};

const View = styled.div`
  width: 37.2rem;
  padding: 2rem;
  position: relative;
  box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(96, 96, 96, 0.25);
  border-radius: 0.8rem;
  background-color: ${gray._000};

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    svg {
      margin-right: 1.6rem;
    }

    ${P1} {
      font-weight: ${weight.semiBold};
    }
  }

  ${P3} {
    color: ${gray._500};
    margin-left: 4rem;
  }

  & > svg {
    top: 0.8rem;
    right: 0.8rem;
    cursor: pointer;
    stroke: ${gray._400};
    position: absolute;
  }

  ${({ type }) => type === types.info
    && css`
      & > div {
        svg {
          stroke: ${support._300};
        }

        ${P1} {
          color: ${support._300};
        }
      }
    `}

  ${({ type }) => type === types.success
    && css`
      & > div {
        svg {
          stroke: ${status.success};
        }

        ${P1} {
          color: ${status.success};
        }
      }
    `}

  ${({ type }) => type === types.error
    && css`
      & > div {
        svg {
          stroke: ${status.error};
        }

        ${P1} {
          color: ${status.error};
        }
      }
    `}

  ${({ type }) => type === types.warning
    && css`
      & > div {
        svg {
          stroke: ${status.warning};
        }

        ${P1} {
          color: ${status.warning};
        }
      }
    `}
`;

export default View;

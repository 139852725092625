import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  transition: all .3s ease-in-out;
  flex-direction: column;
`;

export const Layout = styled.div`
  display: flex;
  margin-top: 11.2rem;
  flex-direction: column;
`;

import React, { useMemo } from 'react';

import { object } from 'prop-types';

import Table from '../Table';

const TableViewer = ({ data }) => {
  const { popupRows, popupHeading } = useMemo(() => {
    let popRow = [];
    let heading = [];

    if (data) {
      heading = Object.keys(data).map((item) => ({
        label: item,
        isSort: true,
      }));
      popRow = [
        {
          id: 0,
          items: Object.values(data).map((item) => ({
            label: item?.join ? JSON.stringify(item) : item || '-',
          })),
        },
      ]; // row
    }
    return { popupRows: popRow, popupHeading: heading };
  }, [data]);

  return <Table body={popupRows} heading={popupHeading} />;
};

TableViewer.propTypes = {
  data: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default TableViewer;

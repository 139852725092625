import React, { useState } from "react";
import { useAlert } from "react-alert";

import { useNavigate } from "react-router-dom";

import { useMe } from "~/services";
import { Button } from "~/components";
import { useApi, validarCNPJ, validate, formatErrorMessage } from "~/hooks";

import { Cadastral } from "../../Cadastral";

import { useSearch } from "../../../context/SearchProvider";

import { Search } from "../../styles";

export const CadastralHeader = () => {
  const api = useApi();
  const alert = useAlert();
  const { group } = useMe();
  const navigate = useNavigate();
  const { t, tAuth, ref, query, loading, onLoading, person } = useSearch();

  const isGroup = !group?.value;

  const submit_cadastral = async (data) => {
    if (isGroup) return;

    const verify = validate(data.key);
    const verifyCnpj = validarCNPJ(data.key);

    if (person?.value === 1 && !verify) {
      alert.error(t("alerts.cpf"));

      return;
    }

    if (person?.value === 2 && !verifyCnpj) {
      alert.error(t("alerts.cnpj"));

      return;
    }

    onLoading(true);
    try {
      const dados = {
        depth: 1,
        key: data.key,
        entity_type: person.value,
        document: data?.key?.replace(/\D/g, ""),
        client_id: group?.value ? group.value : null,
      };
      const res = await api().post("/relationships", dados);
      navigate(`/pesquisa/cadastral/${res.data.id}`);
    } catch (error) {
      alert.error(
        formatErrorMessage(
          error.response,
          error.response.data.message,
          tAuth("expired")
        )
      );
    }

    onLoading(false);
  };

  return (
    <Search ref={ref} type={query} onSubmit={submit_cadastral}>
      <Cadastral />
      <Button
        submit
        size="sm"
        loading={loading}
        appearance="secondary"
        disabled={!person || isGroup}
        label={t("components.search")}
      />
    </Search>
  );
};

import styled from "styled-components";

import { Form } from "@unform/web";

import { P2, H6, Layout } from "~/components";
import { colors, typograph, breakpoints } from "~/styles";
import { rgba } from "~/hooks";
const { gray, support, secondary, primary, green } = colors;
const { weight, size } = typograph;

export const MainForm = styled(Form)`
  display: flex;
  align-items: center;
  gap: 2.5rem;

  & > div {
    min-width: 25rem;

    &:not(:first-of-type) {
      margin-left: 1.6rem;
    }
  }
`;

export const ModalView = styled.div`
  display: flex !important;
  flex-direction: column;

  & > span {
    margin: 0 auto 2rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, ".1")};

    svg {
      width: 4rem;
      height: 4rem;
      stroke: ${primary._000};
    }
  }

  & > ${P2} {
    text-align: justify
  }

  & > ${H6} {
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
    text-align: left !important;
  }

  & > p {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      margin-top: 2.4rem;
    }
  }
  &.modal-sm {
    min-height: 20rem;
  }
  &.modal-md {
    min-height: 26rem;
  }
  &.modal-lg {
    min-height: 32rem;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

import ReactSelect from 'react-select';

import styled, { css } from 'styled-components';

import { P1, P3 } from '~/components/Title';
import { rgba } from '~/hooks';
import { colors, easing, typograph } from '~/styles';

const {
  gray,
  status,
  support,
  primary,
} = colors;

const {
  basic,
} = easing;

const {
  size,
} = typograph;

export const View = styled.div`
  width: 100%;

  .__control {
    z-index: 0;
  }

  & > label {
    color: ${gray._500};
    font-size: ${size.s1};
    line-height: 2.4rem;
  }

  ${({ isReveal }) => ! isReveal && css`
    display: none;
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;

    & > label {
      color: ${gray._300};
    }

    .css-1buq54w-indicatorContainer svg {
      color: ${gray._400};
    }
  `}
`;

export const Target = styled(ReactSelect)`
  ${({ appearance }) => appearance === 'secondary' && css`
    .is-secondary:hover {
      border-color: ${primary._000};
      background-color: ${gray._000};
    }
  `}

  ${({ appearance }) => appearance === 'terciary' && css`
    .is-terciary__control,
    .is-terciary__control:hover {
      color: ${gray._000};
      border-color: ${primary._000};
      background-color: ${primary._000};

      svg {
        color: ${gray._000};
      }
    }
  `}

  ${({ error }) => error && css`
    .is-error,
    .is-error__control {
      border-color: ${status.error} !important;

      &:hover {
        border-color: ${status.error} !important;
      }

      &__indicators svg {
        color: ${status.error};
      }
    }
  `}
`;

export const Action = styled(P1)`
  color: ${primary._000};
  cursor: pointer;
  display: flex;
  padding: 1.6rem;
  align-items: center;
  background-color: rgba(gray._400, '.2');

  svg {
    fill: ${primary._000};
    width: 2.0rem;
    margin-right: 1.2rem;
  }
`;

export const RemoveTag = styled.button`
  color: ${gray._500};
  width: 100%;
  display: flex;
  padding: .8rem;
  font-size: ${size.s1};
  border-top: .1rem solid ${gray._200};
  font-weight: ${typograph.weight.semiBold};
  line-height: ${size.s3};
  justify-content: center;
`;

export const Flag = styled.span`
  color: ${support._100};
  padding: .4rem 1.2rem;
  display: flex;
  font-size: ${size.s1};
  align-items: center;
  border-radius: .2rem;
  background-color: rgba(62, 120, 152, 0.1);
`;

export const Error = styled(P3)`
  color: ${status.error};
  margin-top: .4rem;
`;

export const theme = (theming) => ({
  ...theming,
  borderRadius: 4,
  colors: {
    ...theming.colors,
    primary25: support._200,
    primary50: support._200,
    primary75: support._100,
    primary: support._000,
  },
});

export const styles = {
  menu: (provided, { menuPosition, menuPlacement }) => ({
    ...provided,
    top: menuPosition === 'fixed' ? 0 : menuPlacement !== 'top' ? 44 : undefined, //eslint-disable-line
    zIndex: 3,
    marginTop: 0,
    boxShadow: '.0rem .4rem 1.2rem rgba(0, 0, 0, 0.09);',
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),

  control: (provided, { isDisabled }) => ({
    ...provided,
    height: '4.6rem',
    zIndex: 2,
    border: `.2rem solid ${gray._200}`,
    boxShadow: 'none',
    transition: `all .3s ${basic}`,
    backgroundColor: isDisabled ? gray._200 : gray._000,

    ':hover': {
      borderColor: gray._100,
      backgroundColor: gray._100,
    },
  }),

  input: (provided) => ({
    ...provided,
    padding: 0,
  }),

  placeholder: (provided) => ({
    ...provided,
    color: gray._400,
    margin: 0,
    fontSize: size.s3,
    lineHeight: size.m1,
  }),

  multiValue: (provided) => ({
    ...provided,
    margin: '0 .8rem 0 0',
    display: 'flex',
    padding: '.4rem 1.2rem',
    alignItems: 'center',
    backgroundColor: 'rgba(62, 120, 152, 0.1)',

    '> div': {
      color: support._100,
      padding: 0,

      '&:first-of-type': {
        fontSize: size.s1,
        marginRight: 8,
      },

      '&:last-child:hover': {
        color: support._000,
        backgroundColor: 'transparent',
      },
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    width: '90%',

    label: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),

  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    padding: '.8rem .8rem .8rem 1.6rem',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    fontSize: size.s3,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  clearIndicator: (provided) => ({
    ...provided,
    color: gray._500,
    cursor: 'pointer',
    padding: '0 1.6rem 0 0',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    color: gray._500,
    cursor: 'pointer',
    padding: '0 1.6rem 0 0',
  }),

  option: (provided, { isSelected }) => ({
    ...provided,
    color: isSelected ? primary._000 : gray._500,
    padding: 16,
    fontSize: size.s2,
    lineHeight: size.s3,
    backgroundColor: isSelected ? 'rgba(0, 118, 222, .1)' : gray._000,

    ':hover': {
      color: primary._000,
      backgroundColor: 'rgba(0, 118, 222, .1)',

      '& span': {
        borderColor: ! isSelected && primary._000,
      },

      '& label': {
        color: ! isSelected && primary._000,
      },
    },

    label: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },

    ':last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: size.s3,
    color: gray._400,
    padding: 24,
  }),
};

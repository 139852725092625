import styled from 'styled-components';

import { H4 } from '~/components';
import { breakpoints, colors, typograph } from '~/styles';

const {
  primary,
} = colors;

const {
  size,
} = typograph;

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.2rem;

  ${H4} {
    color: ${primary._000};
    display: flex;
    align-items: center;

    svg {
      top: .2rem;
      cursor: pointer;
      position: relative;
      margin-right: 2.4rem;
    }
    
    p {
      margin-left: 1.8rem !important;
    }
  }

  & > div > * {
    margin-left: .8rem;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;

    ${H4} {
      font-size: ${size.m1};
    }
  }
`;

export default View;

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { array, bool, string } from 'prop-types';
import Theme from './Theme';
import Colors from '~/styles/colors';
import {
  Accordion, Button, Container, Empty, Row, SimpleLoading,
} from '~/components';

const RiskSourceSelector = ({
  availableRiskSources, isValidating, emptyInfo, newWorkflow,
}) => {
  const [ showAll, setShowAll ] = useState(false);
  
  const { t } = useTranslation('admin');

  const byTheme = useMemo(() => availableRiskSources?.reduce((acc, curr) => {
    const defaultTheme = {
      name: curr.theme_name,
      person_type: {},
    };

    acc[ curr.theme_name ] = acc[ curr.theme_name ] || { ...defaultTheme };
    acc[ curr.theme_name ].person_type[ curr.person_type ] = acc[ curr.theme_name ].person_type[ curr.person_type ] || {
      theme_id: curr.theme_id,
      risk_sources: [],
    };

    acc[ curr.theme_name ].person_type[ curr.person_type ].risk_sources.push({
      id: curr.id,
      name: curr.name,
      description: curr.description,
      price: curr.price,
      theme_name: curr.theme_name,
      group: curr.group,
      order: curr.order,
      cost_group: curr.cost_group,
    });
    return acc;
  }, {}) ?? {}, [ availableRiskSources ]);

  if(isValidating) {
    return (
      <Row flex={ 'center' }>
        <SimpleLoading/>
      </Row>
    );
  }

  return (
    <Container>
      { !availableRiskSources?.length && !!emptyInfo
        && (
          <Row flex={ 'center' }>
            <Empty message={ t('workflows.create.empty.entity_type') } />
          </Row>
        )
      }

      {
        Object.keys(byTheme).length > 0 &&
        <Button
          label={ showAll ? t('workflows.create.hide-all') : t('workflows.create.show-all') }
          iconLeft = {true}
          onClick={() => {
            setShowAll(!showAll);
          }}
          style={{margin: "2rem 0 2rem auto"}}
        />
      }
      
      {
        Object.keys(byTheme).length > 0
        && Object.keys(byTheme).sort((a, b) => a.localeCompare(b)).map((theme) => (
          <Row flex={ 'center' }>
            <Accordion showAll={showAll} sizing={'140rem'} backgroundColor={ Colors.gray._100 } columns={[
              {
                type: 'TEXT',
                payload: byTheme[theme].name,
              },
            ]}>
              <Theme newWorkflow={newWorkflow} key={ byTheme[ theme ].id } theme={ byTheme[ theme ] }/>
            </Accordion>
          </Row>
        ))
      }
    </Container>
  );
};

RiskSourceSelector.defaultProps = {
  isValidating: true,
  hiddenTitle: false,
  emptyInfo: null,
};

RiskSourceSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  availableRiskSources: array.isRequired,
  isValidating: bool,
  hiddenTitle: bool,
  emptyInfo: string,
};

export default RiskSourceSelector;
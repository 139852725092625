import * as Yup from 'yup';

export const schema_tag = {
  name: Yup.string().max(32, 'Tag com nome maior do que 32 caracteres.').required('Digite o nome da tag'),
  hasAssociatedDiligences: Yup.boolean().optional(),
  tagActionReplication: Yup.string().when('hasAssociatedDiligences', (hasAssociatedDiligences) => {
    if(hasAssociatedDiligences) {
      return Yup.mixed().oneOf(['all', 'originals'], 'Selecione uma opção').required('Selecione uma opção')
    }
    
    return undefined
  })
};

export const schema_user = (t, type, usuario = 0) => {

  const phoneRegExpPT_BR = /^\+\d{2}\s\(\d{2}\)\s\d{4,5}-\d{4}$/;
  const phoneRegExpES_ES = /^\+\d{2}\s\d{3}-\d{3}-\d{3}$/;
  const phoneRegExpEN_US = /^\+\d{1}\s\(\d{3}\)\s\d{3}-\d{4}$/;
 
  return {
    name: Yup.string().max(255, t('users.validate.name.max')).required(t('users.validate.name.required')),
    email: type === 'usuarios'
      ? Yup.string()
        .email(t('users.validate.email.valid'))
        .required(t('users.validate.email.required'))
      : undefined,
    mask: Yup.string(),
    phone: Yup.string()
      .required(t('users.validate.phone.required'))
      .when('mask', (mask) => {
        switch(mask) {
          case 'es_ES': 
            return Yup.string().matches(phoneRegExpES_ES, t('users.validate.phone.valid'))
          case 'en_US':
            return Yup.string().matches(phoneRegExpEN_US, t('users.validate.phone.valid'))
          case 'pt_BR':
          default:
            return Yup.string().matches(phoneRegExpPT_BR, t('users.validate.phone.valid'))
        }
      }),
    usertype: Yup.string().required(t('users.validate.usertype')),
    idcliente: Yup.string()
      .when('usertype', {
        is: '4', //MASTER
        otherwise: Yup.string().required(t('users.validate.idcliente')),
      }),
    preferred_language: Yup.string().required(t('users.validate.language')),
  }
};

export const schema_quick = {
  // key: Yup.string().min(11, 'Digite um documento válido').required('Esse campo é obrigatório'),
  riskSource: Yup.array()
    .of(Yup.string().min(1))
    .required('Esse campo é obrigatório'),
};

export const schema_diligence = {
  document: Yup.string().min(11, 'Digite um documento válido').required('Esse campo é obrigatório'),
};

export const error = (err, ref) => {
  if (err instanceof Yup.ValidationError) {
    const errorMessages = {};

    err.inner.forEach((validate) => {
      errorMessages[validate.path] = validate.message;
    });

    ref.current.setErrors(errorMessages);
  }
};

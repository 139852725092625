import styled from "styled-components";

import { P2 } from "~/components";
import { breakpoints, colors, easing, typograph } from "~/styles";

const { gray, primary, purple, status, cyan, orange } = colors;
const { basic } = easing;
const { weight, size } = typograph;

export const Container = styled.tr`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  position: relative;
  width: 100%;
  height: 6.4rem;
  padding-right: 2.4rem;

  .toggle {
    display: flex;
    align-items: center;
    color: ${gray._600};

    > span {
      display: flex;
      align-items: center;
      margin-left: 0.7rem;
      color: ${gray._500};
      font-weight: ${weight.semiBold};

      svg {
        cursor: pointer;
        margin-left: 0.7rem;
        transform: scale(0.8);
      }
    }
  }

  td,
  th {
    height: 6.4rem;
    padding: 2.4rem;
    min-width: ${({ columnsNumber }) => (1 / columnsNumber) * 100}% !important;
  }

  th {
    display: flex;
    align-items: center;
    color: ${gray._500};
    text-align: left;
  }

  td {
    display: flex;
    align-items: center;
    color: ${gray._600};

    > span {
      display: flex;
      align-items: center;
      margin-left: 0.7rem;
      color: ${gray._500};
      font-weight: ${weight.semiBold};

      svg {
        margin-left: 0.7rem;
        transform: scale(0.8);
        cursor: pointer;
      }
    }

    &.is-due-date {
      color: ${status.error} !important;
      font-weight: ${weight.bold} !important;
    }
  }

  &:nth-child(odd) {
    background-color: ${gray._100};
  }

  > div:not(.action-plan-modal),
  > td:not(.action-plan-modal) {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-left: 1rem;
      width: 3.6rem;
      cursor: pointer;
      height: 3.6rem;
      padding: 0;
      transition: all 0.3s ${basic};
      border-radius: 50%;
      background-color: transparent;

      span {
        position: absolute;
        font-size: ${size.s1};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.repeat {
        color: ${orange._500};
        border: 0.1rem solid ${orange._500};

        &:hover {
          color: ${gray._000};
          background-color: ${orange._600};
        }
      }

      &.generate_pdf {
        color: ${status.error};
        border: 0.1rem solid ${status.error};

        &:hover {
          color: ${gray._000};
          background-color: ${status.error};
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      &.clipboard {
        cursor: pointer;
        color: ${cyan._600};
        border: 0.1rem solid ${cyan._600};

        &:hover {
          color: ${gray._000};
          background-color: ${cyan._700};
        }
      }

      &.file {
        color: ${primary._000};
        border: 0.1rem solid ${primary._000};

        &:hover {
          color: ${gray._000};
          background-color: ${purple._500};
        }
      }

      &.redirect {
        color: ${gray._400};
        cursor: pointer;
      }

      &.delete {
        color: ${status.error};
        border: 0.1rem solid ${status.error};

        &:hover {
          color: ${gray._000};
          background-color: ${status.error};
        }
      }
    }
  }

  & .hide {
    opacity: 0;
    cursor: default !important;
  }

  & .icon-search {
    color: ${gray._500};
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
    min-width: 1.4rem;
    margin-left: 0.8rem;
  }

  .toggle {
    width: 25rem;
    justify-content: flex-start;
  }

  .link {
    flex: 0;
    & a {
      color: gray;
    }
  }

  .modified {
    span > span {
      color: ${status.error};
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    display: block !important;
    height: fit-content;
    padding: 2rem 0;

    th {
      display: none !important;
    }

    > td,
    > div {
      display: flex;
      justify-content: flex-${({ hasDataLabels }) => (hasDataLabels ? "end" : "start")};
      height: fit-content;
      padding: 1rem 2rem;
      width: 100%;

      &::before {
        content: attr(data-label);
        color: ${gray._600} !important;
        font-weight: ${weight.bold};
        position: absolute;
        left: 0;
        width: 50%;
        text-align: left;
        padding: 0 2rem;
      }
    }

    > div {
      justify-content: flex-end;
    }
  }
`;

export const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  & > ${P2} {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;

    & > div {
      margin-top: 1.6rem;
    }
  }
`;

import styled from 'styled-components';

import { Layout } from '~/components';
import { breakpoints, colors, typograph } from '~/styles';

const { size, weight } = typograph;
const { gray } = colors;

export const View = styled(Layout)`
  margin-bottom: 4.8rem;

  .hide-empty {
    > div:last-of-type {
      display: none;
    }

    > div:nth-of-type(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      h4 {
        margin-left: 2.5rem;
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      tr:first-of-type {
        background-color: white !important;
      }
    }
  }

  div.input-edit {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
    flex-direction: row;
    justify-content: flex-start;
  }

  svg.edit {
    top: 3px;
    width: 16px;
    cursor: pointer;
    height: auto;
    stroke: ${colors.gray._400};
    position: relative;
    margin-left: 8px;
  }

  .inputs {
    display: flex;
    align-items: center;
    margin-left: auto;

    & > div {
      width: 28rem;

      :first-of-type {
        margin-right: 1.6rem;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .inputs {
      flex-direction: column;

      & > div {
        width: 100%;

        :first-of-type {
          margin-right: 0;
          margin-bottom: 1.6rem;
        }
      }
    }
  }
`;

export const Panel = styled.div`
  margin-bottom: 4.5rem;

  h6 {
    width: 100%;
    color: ${gray._600};
    padding: 2.4rem 0 1.6rem;
    text-align: left;
    font-size: ${size.s3};
    border-top: 0.1rem solid ${gray._200};
    font-weight: ${weight.semiBold};
  }

  table {
    width: 100%;
    border-spacing: 0;
    vertical-align: middle;

    thead th {
      height: auto;
      display: flex;
      align-items: center;
      background-color: ${gray._000} !important;

      & > th {
        height: auto;
        padding: 1rem 2.4rem;
      }
    }
  }
`;

export const Diligences = styled.div`
  margin-top: 6rem;

  h6 {
    margin-bottom: 2.6rem;
    color: ${gray._500};
  }

  table thead {
    display: none;
  }
`;

/* eslint-disable no-nested-ternary */

import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { format, isBefore } from 'date-fns';

import {
  Accordion, Empty, Modal, SimpleLoading, TableRow,
} from '~/components';
import PopupViewer from '~/components/PopupViewer';
import PopupWrapper from '~/components/PopupWrapper';
import { parseISO, useFetch } from '~/hooks';

import { useResultData } from '../../resultContext';
import { Panel } from '../../styles';

const ActionPlan = () => {
  const { id: processID } = useParams();
  const popupWrapperRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => popupWrapperRef.current,
  });
  const [url, setURL] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const { t } = useTranslation('diligence');
  const navigate = useNavigate();
  const { addMutation } = useResultData();
  const alert = useAlert();

  const { data: actionPlans, isValidating: isValidatingActionPlans, mutate } = useFetch({ url });

  useEffect(() => {
    addMutation(mutate);
  }, [addMutation, mutate]);

  const closePopup = useCallback((state) => {
    setShowPopup(state);
    setPopupData(null);
  }, []);

  const onOpen = useCallback((isOpened) => {
    if (isOpened) {
      if (! url) {
        setURL(`/process/${processID}/actionPlans`);
      } else {
        mutate();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <>
      <Accordion onChange={onOpen} columns={[{ type: 'TEXT', payload: t('action_plans.title') }]}>
        {isValidatingActionPlans ? <SimpleLoading /> : (
          actionPlans?.data?.length > 0
            ? (
              <Panel>
                <table>
                  <thead>
                    <TableRow
                      style={{ backgroundColor: '#fff' }}
                      columns={[
                        { payload: t('action_plans.columns.0'), type: 'HEAD' },
                        { payload: t('action_plans.columns.1'), type: 'HEAD' },
                        { payload: t('action_plans.columns.2'), type: 'HEAD' },
                        { payload: t('action_plans.columns.3'), type: 'HEAD' },
                        { payload: t('action_plans.columns.4'), type: 'HEAD' },
                        { payload: 2, type: 'HEAD_BUTTONS' },
                      ]}
                    />
                  </thead>
                  <tbody>
                    {actionPlans?.data?.map((actionPlan) => (
                      <TableRow
                        key={actionPlan.id}
                        redirect={() => navigate(
                          `/plano-de-acao/${actionPlan.id}`,
                        )}
                        columns={[
                          { type: 'TEXT', payload: actionPlan.subject },
                          {
                            payload: actionPlan.due_date
                              ? format(parseISO(actionPlan.due_date), 'dd/MM/yyyy')
                              : '-',
                            type: 'TEXT',
                            className: isBefore(
                              new Date(actionPlan?.due_date),
                              new Date().setDate(new Date().getDate() - 1),
                            )
                              ? 'is-due-date'
                              : undefined,
                          },
                          {
                            payload: actionPlan.portal_owner?.name || '-', // remover essa linha e abrir a comentada quando vier o keycloak
                            // payload: actionPlan.portal_owner?.nome || '-',
                            type: 'TEXT',
                          },
                          {
                            payload: actionPlan.responsible_name || '-',
                            type: 'TEXT',
                          },
                          {
                            payload: actionPlan.status?.name,
                            type: 'FLAG',
                          },
                          {
                            payload:
                            actionPlan.result_id
                              ? {
                                types: ['REDIRECT', 'FILE'],
                                fileAction: {
                                  onClick: () => {
                                    const parsedPopup = JSON.parse(actionPlan.result?.popup);
                                    if (parsedPopup) {
                                      setPopupData(parsedPopup);
                                      setShowPopup(true);
                                    } else {
                                      alert.error(t('action_plans.unavailable_popup'));
                                    }
                                  },
                                },
                              }
                              : actionPlan.attachment_url ? {
                                types: ['REDIRECT', 'FILE'],
                                fileAction: { onClick: () => { window.open(actionPlan.attachment_url, '_blank'); } },
                              } : { types: ['REDIRECT'] },
                            type: 'BUTTONS',
                          },
                        ]}
                        dataLabels={[
                          t('action_plans.columns.0'),
                          t('action_plans.columns.1'),
                          t('action_plans.columns.2'),
                          t('action_plans.columns.3'),
                          t('action_plans.columns.4'),
                        ]}
                      />
                    ))}
                  </tbody>
                </table>
              </Panel>
            ) : <Empty hasButton={false} hasMessage={false} title={t('action_plans.none')} />
        )}
      </Accordion>
      {showPopup && !! popupData && (
        <Modal
          show
          title="teste"
          onShow={closePopup}
          hasActions={false}
          size="lg"
          hasPrinterIcon
          onClickPrinter={handlePrint}
        >
          <PopupWrapper ref={popupWrapperRef}>
            <PopupViewer popup={popupData} />
          </PopupWrapper>
        </Modal>
      )}
    </>
  );
};
export default ActionPlan;

import React, { useCallback } from "react";
import { Printer, X } from "react-feather";
import { useTranslation } from "react-i18next";

import {
  func,
  bool,
  oneOf,
  array,
  shape,
  object,
  string,
  element,
  oneOfType,
} from "prop-types";

import { Button, P1, P3 } from "~/components";

import { Head, Body, View, Actions, Wrapper } from "./styles";

const Modal = ({
  show,
  size,
  title,
  action,
  secondaryAction,
  showCancel,
  goBackOnCancel,
  onShow,
  children,
  hasAction,
  hasActions,
  subAction,
  hasOverflow,
  titleOnly,
  hasPrinterIcon,
  onClickPrinter,
  header,
  footer,
  messageCancel,
  className,
  classWrapper,
  information,
  showPrepaidMsg,
  prepaidDescription,
  cancelName,
  noMinHeight,
}) => {
  const { t } = useTranslation("components");

  const handleClick = useCallback(() => {
    if (goBackOnCancel) {
      goBackOnCancel();
    } else {
      onShow(false);
    }
  }, [goBackOnCancel, onShow]);

  const defaultHeader = (
    <Head isBold hasTitle={!!title} titleOnly={titleOnly}>
      {title}
      <span>
        {hasPrinterIcon && <Printer onClick={onClickPrinter} />}
        <X onClick={() => handleClick()} />
      </span>
    </Head>
  );

  return (
    <View show={show} className={className}>
      <Wrapper
        hasTitle={!!title}
        size={size}
        titleOnly={titleOnly}
        noMinHeight={true}
        className={classWrapper}
      >
        {header || defaultHeader}

        {titleOnly ? null : (
          <Body hasFooter={footer !== null} hasTitle={!!title}>
            {prepaidDescription && <P1> {prepaidDescription}</P1>}
            <div className={hasOverflow ? "overflow" : ""}>{children}</div>
            {information && (
              <div>
                {showPrepaidMsg && <P3>{t("modal.information.prepaid")}</P3>}
                <P3>{t("modal.information.partner")}</P3>
              </div>
            )}
            {hasActions && (
              <Actions hasTitle={!!title} subAction={!!subAction}>
                {subAction && (
                  <button type="button" onClick={subAction.onClick}>
                    {subAction.label}
                  </button>
                )}
                {hasAction && showCancel && (
                  <Button
                    size="sm"
                    label={
                      messageCancel
                        ? t("modal.new_cancel")
                        : cancelName ?? t("modal.cancel")
                    }
                    appearance="tertiary"
                    onClick={() => handleClick()}
                  />
                )}

                {secondaryAction && (
                  <Button
                    size="sm"
                    appearance="primary"
                    disabled={action.loading}
                    label={secondaryAction.label}
                    loading={secondaryAction.loading}
                    onClick={secondaryAction.onClick}
                    iconLeft={secondaryAction.icon ?? false}
                  />
                )}

                <Button
                  size="sm"
                  label={action.label}
                  appearance="primary"
                  loading={action.loading}
                  onClick={action.onClick}
                  disabled={action?.disabled}
                  iconLeft={action.icon ?? false}
                />
              </Actions>
            )}
          </Body>
        )}

        {footer}
      </Wrapper>
    </View>
  );
};

Modal.propTypes = {
  show: oneOfType([bool, object]),
  size: oneOf(["md", "lg"]),
  title: string,
  action: shape({
    disabled: bool,
    label: string,
    loading: bool,
    onClick: func,
  }),
  secondaryAction: shape({
    label: string,
    loading: bool,
    onClick: func,
  }),
  showCancel: bool,
  goBackOnCancel: func,
  onShow: func,
  children: oneOfType([element, array]).isRequired,
  subAction: oneOfType([
    bool,
    shape({
      label: string,
      onClick: func,
    }),
  ]),
  hasAction: bool,
  hasActions: bool,
  hasOverflow: bool,
  titleOnly: bool,
  hasPrinterIcon: bool,
  onClickPrinter: func,
  header: element,
  footer: element,
  messageCancel: bool,
  className: string,
  prepaidDescription: string,
  noMinHeight: bool,
  cancelName: string,
};

Modal.defaultProps = {
  show: false,
  size: "md",
  title: "",
  action: {},
  secondaryAction: null,
  showCancel: true,
  goBackOnCancel: null,
  onShow: () => null,
  subAction: false,
  hasAction: true,
  hasActions: true,
  hasOverflow: true,
  titleOnly: false,
  hasPrinterIcon: false,
  onClickPrinter: () => null,
  header: null,
  footer: null,
  messageCancel: false,
  className: null,
  prepaidDescription: null,
  noMinHeight: false,
  cancelName: null,
};

export default Modal;

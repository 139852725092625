import React from "react";
import { useSearch } from "../../context/SearchProvider";

import { BatchesHeader } from "./BatchesHeader";
import { CadastralHeader } from "./CadastralHeader";
import { DiligenceHeader } from "./DiligenceHeader";
import { SimplifiedHeader } from "./SimplifiedHeader";

export const SearchHeader = () => {
  const { query } = useSearch();

  if (query) {
    switch (query) {
      case "batches":
        return <BatchesHeader />;
      case "diligencia":
        return <DiligenceHeader />;
      case "cadastral":
        return <CadastralHeader />;
      case "simplificada":
        return <SimplifiedHeader />;
      default:
        return null;
    }
  }

  return null;
};

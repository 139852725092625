import styled from 'styled-components';

import { colors } from '~/styles';

const { gray } = colors;

const View = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  * {
    stroke: ${gray._300};
  }
`;

export default View;

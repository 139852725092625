import styled, { css } from 'styled-components';

import { colors, typograph, easing } from '~/styles';

const { gray } = colors;
const { rubber } = easing;
const { size } = typograph;

export const View = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

export const Bubble = styled.span`
  ${({ direction }) => {
    switch (direction.side) {
      case 'left':
        return (css`left: -2.4rem;`);
      case 'right':
        return (css`right: -2.4rem;`);
      case 'bottom':
        return (css`bottom: -2.4rem;`);
      default:
        return (css`top: -2.4rem;`);
    }
  }}
  
  color: ${gray._000} !important;
  z-index:100000;
  width: fit-content;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  padding: .8rem .8rem;
  opacity: 0;
  font-size: ${size.s1};
  position: absolute;
  min-width: 18.4rem;
  transition: all .6s ${rubber};
  text-align: center;
  align-items: center;
  line-height: ${size.m1};
  border-radius: .4rem;
  pointer-events: none;
  justify-content: center;
  background-color: ${gray._600};

  &::before {
    ${({direction}) => {
      switch (direction.side) {
        case 'left':
          return (
            css`right: -1rem;
            transform: rotate(-90deg);`
          );
        case 'right':
          return (
            css`left: -.6rem;
            transform: rotate(90deg);`);
        case 'bottom':
          return (
            css`top: -.6rem;
            transform: rotate(180deg);`
          );
        case 'up':
          return (
            css`bottom: -.6rem;`
          );
        default:
          return (
            css`bottom: -.6rem;`
          );
      }
    }}
    width: 0;
    height: 0;
    border: 0 solid transparent;
    content: '';
    position: absolute;
    border-top: .8rem solid ${gray._600};
    border-left-width: .8rem;
    border-right-width: .8rem;
  }

  ${({ show }) => show && css`
    ${({direction}) => {
      switch (direction.side) {
        case 'left':
          return (css`left: ${direction.offset || '-4.8'}rem;`);
        case 'right':
          return (css`right: ${direction.offset || '-4.8'}rem;`);
        case 'bottom':
          return (css`bottom: ${direction.offset || '-4.8'}rem;`);
        case 'up':
          return (css`top: ${direction.offset || '-4.8'}rem;`);
        default:
          return (css`top: ${direction.offset || '-4.8'}rem;`);
      }
    }}
    opacity: 1;
    pointer-events: all;
  `}
`;

import styled from 'styled-components';


export const AlignDivs = styled.div`
  display: flex;
  justify-content : flex-start;

  > div {
    max-width: 17.5%;
    margin-right 1.6rem;
  }
`;

import styled from 'styled-components';

import { P2 } from '~/components';
import { colors } from '~/styles';

const {
  gray,
} = colors;

const View = styled.div`
  display: flex;
  flex-direction: column;

  & ${P2} {
    color: ${gray._600};
  }

  & .has-main-diligence {
    padding: 2rem 0;

    & > ${P2} {
      margin-bottom: 2rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    & > div {
      margin-top: 1.6rem;
    }
  }
`;

export default View;

import React, { useEffect, useRef, useState } from 'react';
import {
  Container, Header, HR, Row, Table,
} from './styles';
import {
  Button,
  Empty,
  Select, SimpleInput,
  SimpleLoading,
  Tooltip,
} from '~/components';
import { Form } from '@unform/web';
import { ChevronDown, Search as IconSearch, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useApi, useDebounce, useFetch } from '~/hooks';
import { useMe } from '~/services';
import { useAlert } from 'react-alert';

const Cnaes = () => {
  const [ company, setCompany ] = useState(null);
  const [ probability, setProbability ] = useState(null);
  const [ cnaes, setCnaes ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ cnaesOptions, setCnaesOptions ] = useState(null);
  const [ clientsOptions, setClientsOptions ] = useState([]);
  const [ probabilityOptions, setProbabilityOptions ] = useState([]);
  const [ searchField, onSearchField ] = useState('');
  const [ filtredProb, setFiltredProb ] = useState([]);

  const { t } = useTranslation('cnaes');
  const { user } = useMe();
  const api = useApi();
  const toast = useAlert();
  const formRef = useRef();
  const deboucedSearch = useDebounce(searchField);

  /**
   * Fetching datas
   */
  const { data: cnaesList } = useFetch({ url: `/probability/cnaes` });
  const { data: probabilities } = useFetch({ url: `/probability/paterns` });
  const { data: probabilityData, isValidating, mutate } = useFetch({ url: `/probability` });

  /**
   * Pushing values into option's select
   */
  useEffect(() => {
    let formated = user?.hierarquia?.map((value) => (
      {
        value: value.id,
        label: value.cliente,
      } ));
    setClientsOptions(formated);
  }, [ user ]);

  useEffect(() => {
    let formated = probabilities?.data?.map((prob) => (
      {
        value: prob.id,
        label: prob.descricao,
      } ));
    setProbabilityOptions(formated);
  }, [ probabilities ]);

  useEffect(() => {
    let formated = cnaesList?.data?.map((cnae) => (
      {
        value: cnae.id,
        label: `${ cnae.sesao || '' } ${ cnae.divisao || '' } ${ cnae.grupo ||
        '' } ${ cnae.classe || '' } ${ cnae.subclasse ||
        '' } ${ cnae.codigo || '' } ${ cnae.denominacao || '' }`,
      } ));
    setCnaesOptions(formated);
  }, [ cnaesList ]);

  useEffect(() => {
    setIsLoading(!( cnaesOptions && clientsOptions && probabilityOptions ));
  }, [ cnaesOptions, clientsOptions, probabilityOptions, probabilityData ]);

  useEffect(() => {
    if (searchField) {
      let filtred = probabilityData?.data?.filter(value => {
        return value.classe.toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
          value.cliente.toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
          value.denominacao.toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
          value.descricao.toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
          value.divisao.toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
          value.grupo.toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
          value.secao.toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
          value.subclasse.toLowerCase().indexOf(searchField.toLowerCase()) > -1;
      })
      setFiltredProb(filtred);
    }
    else {
      setFiltredProb(probabilityData?.data)
    }
  }, [probabilityData, deboucedSearch])
  /**
   * Method to save values on db
   * @param formData,
   * @returns {Promise<void>}
   */
  const handleSubmit = async (formData) => {
    try {
      const data = {
        'client_id': formData.company,
        'estrutura_cnae_id': formData.cnaes,
        'probabilidade_id': formData.probability,
      };

      const { data: response } = await api().put('/probability', data);

      response.type === 'success'
        ? toast.success(t('form.alert.success.saved'))
        : Error(response);

      await mutate();
      formRef.current.reset();
    }
    catch(err) {
      toast.error(err.response.data.message);
    }
  };

  /**
   * Deleting a probability
   * @param id
   * @param clientId
   * @returns {Promise<void>}
   */
  const deleteProbability = async (id, clientId) => {
    try {
      const { data: response } = await api().
        delete('/probability', { data: { 'id': id, 'client_id': clientId } });

      response.type === 'success'
        ? toast.success(t('form.alert.success.deleted'))
        : Error(response);

      await mutate();
    }
    catch(err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Container>
      <Row>
        <Header>{ t('title') }</Header>
        { probabilityData?.data?.length > 0 &&
          <div style={{width: "30%", marginTop: "-1rem"}}>
            <SimpleInput
              size={'lg'}
              isSearch
              name="search"
              icon={<IconSearch />}
              value={searchField}
              onValue={onSearchField}
              onChange={({ target }) => {
                onSearchField(target.value)
              }}
              placeholder={t('search')}
              appearance="secondary"
            />
          </div>
        }
      </Row>
      <HR />
      <Form ref={ formRef } onSubmit={ handleSubmit }>
        <Row>
          <Select
            label={ t('form.select.label.company') }
            name="company"
            value={ company }
            onChange={ (evt) => setCompany(evt.value) }
            options={ clientsOptions }
            icon={ ChevronDown }
            isLoading={ isLoading }
            placeholder={ t('form.select.placeholder') }
          />

          <Select
            label={ t('form.select.label.cnaes') }
            name="cnaes"
            value={ cnaes }
            onChange={ (evt) => setCnaes(evt.value) }
            options={ cnaesOptions }
            icon={ ChevronDown }
            isLoading={ isLoading }
            placeholder={ t('form.select.placeholder') }
          />

          <Select
            label={ t('form.select.label.probability') }
            name="probability"
            value={ probability }
            onChange={ (evt) => setProbability(evt.value) }
            options={ probabilityOptions }
            icon={ ChevronDown }
            isLoading={ isLoading }
            placeholder={ t('form.select.placeholder') }
          />

          <div style={ { marginTop: 'auto' } }>
            <Button
              size={ 'sm' }
              disabled={ !probability || !cnaes || !company }
              onClick={ () => { formRef.current.submitForm(); } }
              label={ t('form.button.label') }/>
          </div>
        </Row>
      </Form>

      { isValidating && isLoading
        ? ( <SimpleLoading/> )
        : filtredProb?.length === 0 ? ( <Empty/> ) :
          ( <Table>
            <thead>
            <tr>
              <th>{ t('table.header.company') }</th>
              <th>{ t('table.header.section') }</th>
              <th>{ t('table.header.divison') }</th>
              <th>{ t('table.header.group') }</th>
              <th>{ t('table.header.class') }</th>
              <th>{ t('table.header.subclass') }</th>
              <th>{ t('table.header.denomination') }</th>
              <th>{ t('table.header.probability') }</th>
              <th>{ t('table.header.action') }</th>
            </tr>
            </thead>

            <tbody>
            { filtredProb &&
              filtredProb?.map((value, index) => {
                return (
                  <tr>
                    <td>{ value.cliente }</td>
                    <td>{ value.secao || '-' }</td>
                    <td>{ value.divisao || '-' }</td>
                    <td>{ value.grupo || '-' }</td>
                    <td>{ value.classe || '-' }</td>
                    <td>{ value.subclasse || '-' }</td>
                    <td>{ value.denominacao }</td>
                    <td>{ value.descricao }</td>
                    <td className={ 'action' }>
                      <Tooltip key={ `[${ index }]` } label={ t('table.tooltip.label') } width={ 180 }>
                        <button
                          type="button"
                          className={ `delete` }
                          onClick={ () => { deleteProbability(value.id, value.client_id); } }>
                          <Trash2 key={ `[${ index }]` } size={ '3rem' }/>
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                );
              }) }
            </tbody>
          </Table> ) }
    </Container>
  );
};

export default Cnaes;

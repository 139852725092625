import styled, { css } from 'styled-components';

import { colors } from '~/styles';

const {
  gray,
  status,
} = colors;

const View = styled.div`
  justify-content: flex-end;

  button {
    padding: 0;

    svg {
      stroke: ${gray._500};
    }
  }

  ${({ hasError }) => hasError && css`
    button:nth-child(2) svg {
      stroke: ${status.error};
    }
  `}

  ${({ red }) => red && css`
    button svg {
      stroke: ${status.error};
    }
  `}
`;

export default View;

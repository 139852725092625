import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import { Checkbox, Column, Container, P2, Row } from '~/components';
import { maskMoney } from '~/hooks/helpers';
import { useMe } from '~/services';
import { useSelectedRiskSource } from './selectedRiskSourcesContext';
import Themes from './styles'
import { useParams } from 'react-router-dom';

const Theme = ({ theme, newWorkflow }) => {
  const { user } = useMe();
  const { t } = useTranslation('search');
  const { id: workflowId } = useParams();

  const [themeChecked, setThemeChecked] = useState(false);

  const {
    selectedRiskSources,
    addNewSelectedRiskSource,
    setSelectedRiskSources,
    removeSelectedRiskSource,
    selectedPersonTypes,
  } = useSelectedRiskSource();

  const allThemeIDs = Object.keys(theme.person_type)
  .map((personTypeID) => theme.person_type[personTypeID].theme_id);
  
  useEffect(() => {
    if(newWorkflow || workflowId) {
      setSelectedRiskSources(selectedRiskSources.filter(value => selectedPersonTypes.includes(parseInt(value.person_type))));
    }
  }, [selectedPersonTypes])

  useEffect(() => {
    let sum = 0;
    Object.keys(theme.person_type).map(keysPersonType => sum += theme.person_type[keysPersonType].risk_sources.length);
    setThemeChecked(selectedRiskSources.filter(rs => allThemeIDs.includes(rs.theme_id)).length === sum);
  }, [selectedRiskSources, theme, allThemeIDs]);

  const handleCheck = (check) => {
    if(!check) {
      setSelectedRiskSources(
        (oldSelectedRiskSources) => oldSelectedRiskSources.filter(({ theme_id }) => !allThemeIDs.includes(theme_id)),
      );
    } else {
      const allSources = Object.keys(theme.person_type).reduce((acc, personTypeID) => {
        const riskSources = theme.person_type[ personTypeID ].risk_sources;
        riskSources
        .filter((val) => (!selectedRiskSources.includes(selectedRiskSources.map(riskSource => riskSource.id).filter(riskFiltered => riskFiltered === val.id))))
        .forEach(({ id, price, theme_name, cost_group }) => {
          acc.push({
            id,
            theme_id: theme.person_type[ personTypeID ].theme_id,
            price,
            person_type: personTypeID,
            theme_name,
            cost_group,
          });
        });
        return acc;
      }, [])
      .filter(source => !selectedRiskSources.find(rs => rs.id === source.id))
      setSelectedRiskSources((oldSelectedRiskSources) => [...oldSelectedRiskSources, ...allSources]);
    }
  };
  
  return (
    <Container>
      <Row style={{padding: "0.5rem"}} rowGap={'lg'}>
        <Checkbox
          label={ t('select_all') }
          selectedProp={ themeChecked }
          onSelected={ (evt) => handleCheck(evt.target.checked) }
        />
      </Row>
      
      <Row rowGap={'sm'} align={'start'} flex={'between'}>
        <Themes>
          { Object.keys(theme.person_type).map((personTypeID) => {
            const personType = theme.person_type[ personTypeID ].risk_sources;
            if(!personType?.length) {
              return null;
            }

            return (
              <Column columnGap={'lg'} align={ 'start' } flex={ 'center' } key={ personTypeID }>
                <P2 style={{margin: '1.5rem 0'}} isBold>{ t(`person_types.${ personTypeID - 1 }`) }</P2>
                {
                  Object.values(
                    personType.sort((a, b) => a.group - b.group || a.order - b.order || a.name.localeCompare(b.name)).
                      reduce((acc, curr) => {
                        acc[ curr.group ] = acc[ curr.group ] || [];
                        acc[ curr.group ].push(curr);
                        return acc;
                      }, {})).map((group) => {
                    return (
                      group.map((riskSource) => {
                        const isChecked = !!selectedRiskSources.find(
                          ({ id, theme_id }) => id === riskSource.id
                            && theme_id === theme.person_type[ personTypeID ].theme_id
                        );
                        return (
                          <Row columnGap={ 'lg' } rowGap={ 'lg' }>
                            <Checkbox
                              space={'0.5rem'}
                              key={ riskSource.id }
                              label={ ( user?.isMaster() || user?.usuario?.viewWorkflowCosts === 1 )
                                ? `${ riskSource.name } - ${ maskMoney(riskSource.price) }`
                                : riskSource.name }
                              defaultChecked={ isChecked }
                              onSelected={ (evt) => {
                                if(evt.target.checked) {
                                  addNewSelectedRiskSource(
                                    theme.person_type[ personTypeID ].theme_id,
                                    riskSource.id,
                                    riskSource.price,
                                    personTypeID,
                                    riskSource.theme_name,
                                    riskSource.cost_group,
                                  );
                                }
                                else {
                                  removeSelectedRiskSource(
                                    theme.person_type[ personTypeID ].theme_id, riskSource.id,
                                  );
                                }
                              } }
                              styles={{paddingBottom: "1rem"}}
                            />
                          </Row>
                        );
                      })
                    );
                  })
                }
              </Column>
            );
          }) }
        </Themes>
      </Row>
    </Container>
  );
};

Theme.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: object.isRequired,
};

export default Theme;

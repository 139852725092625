import React from 'react';

import { bool, func, oneOf, oneOfType, string } from 'prop-types';

import View from './styles';

const Flag = ({
  size,
  suffix,
  status,
  isLink,
  action,
}) => {
  const formatedLabel = `${ status } ${ suffix ? `(${ suffix })` : '' }`;
  
  return (
    <View
      size={ size }
      status={ status }
      isLink={ isLink }
      onClick={ () => isLink && action() }
    >
      { formatedLabel }
    </View>
  );
};

Flag.propTypes = {
  size: oneOf([ 'sm', 'md' ]),
  suffix: oneOfType([ bool, string ]),
  status: string.isRequired,
  isLink: bool,
  action: func,
};

Flag.defaultProps = {
  size: 'md',
  suffix: false,
  isLink: false,
  action: () => {},
};

export default Flag;

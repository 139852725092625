export const headings = (t) => [
  {
    route: 'painel-de-gestao',
    label: t('helmet.control'),
  },
  {
    route: 'painel-de-risco',
    label: t('helmet.risk'),
  },
];

export const table = {};

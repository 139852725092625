import React, { useRef } from 'react';
import { Main } from '~/components';
import { Logo, View } from '~/layout/Head/styles';
import LogoWhite from '~/assets/logo-white.svg';
import { Outlet } from 'react-router-dom';

const UnAuthHead = () => {
  const ref = useRef(null);

  return (
    <Main>
      <View ref={ ref }>
        <Logo to="/">
          <img src={ LogoWhite } alt="" />
        </Logo>
      </View>

      <Outlet />
    </Main>
  );
};

export default UnAuthHead;

export const routes = (t, isAdmin = false) => {
  if (isAdmin) {
    return [
      {
        type: "DROPDOWN",
        path: "resultados",
        label: t("routes.results.name"),
        items: [
          {
            name: t("routes.results.childs.diligence"),
            query: "?q=diligencias",
          },
          {
            name: t("routes.results.childs.entities"),
            query: "?q=entidades",
          },
        ],
      },

      {
        type: "LINK",
        route: "plano-de-acao",
        label: t("routes.action_plan"),
      },
    ];
  }

  return [
    {
      type: "DROPDOWN",
      path: "pesquisa",
      label: t("routes.search.name"),
      // noVisualizer: true,
      items: [
        {
          name: t("routes.search.childs.cadastral"),
          query: "?q=cadastral",
          noVisualizer: true,
        },
        {
          name: t("routes.search.childs.diligence"),
          query: "?q=diligencia",
        },
        {
          name: t("routes.search.childs.batches"),
          query: "?q=batches",
          noVisualizer: true,
        },
      ],
    },

    {
      type: "DROPDOWN",
      path: "resultados",
      label: t("routes.results.name"),
      items: [
        {
          name: t("routes.results.childs.diligence"),
          query: "?q=diligencias",
        },
        {
          name: t("routes.results.childs.entities"),
          query: "?q=entidades",
        },
      ],
    },

    { // Dashboard
      type: 'DROPDOWN',
      path: 'dashboard',
      label: t('routes.dashboard.name'),
      items: [
        {
          name: t('routes.dashboard.childs.management-panel'),
          query: '?q=painel-de-gestao',
        },
        {
          name: t('routes.dashboard.childs.risk-panel'),
          query: '?q=painel-de-risco',
        },
      ],
    },
    
    {
      type: "LINK",
      route: "monitoramento",
      label: t("routes.monitoring"),
      noVisualizer: true,
    },

    {
      type: "LINK",
      route: "plano-de-acao",
      label: t("routes.action_plan"),
    },

    {
      type: "DROPDOWN",
      label: t("routes.administration.name"),
      path: "administracao",
      items: [
        {
          name: t("routes.administration.childs.tags"),
          query: "?q=tags",
          noVisualizer: true,
        },

        {
          name: t("routes.administration.childs.workflows"),
          query: "?q=workflows",
          noVisualizer: true,
        },

        {
          name: t("routes.administration.childs.users"),
          query: "?q=usuarios",
          masterOnly: true,
        },

        {
          name: t("routes.administration.childs.historic"),
          query: "?q=historico",
        },

        {
          name: "CNAEs",
          query: "?q=cnaes",
          masterOnly: true,
        },

        {
          name: t("routes.administration.childs.media-terms"),
          query: "?q=terms",
          noVisualizer: true,
        },

        {
          name: t("routes.administration.childs.pricing"),
          query: "?q=pricing",
          masterOnly: true,
        },

        {
          name: t("routes.administration.childs.risk-sources"),
          query: "?q=risk-sources",
          masterOnly: true,
        },

        {
          name: t("routes.administration.childs.settings"),
          query: "?q=settings",
        },
      ],
    },
  ];
};

export const data = [];

import React, { useEffect, useState } from "react";
import { X, Eye, Trash2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { bool, func } from "prop-types";
import styled from "styled-components";

import { reduce, useApi } from "~/hooks";
import useNotifications from "~/hooks/useNotifications";
import { colors } from "~/styles";
import { saveAs } from "file-saver";
import { useAlert } from "react-alert";
import Spinner from "../Spinner";
import Container from "./styles";

import { H5, H6, P2, P3 } from "..";
import { data } from "~/layout/Head/routes";

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationContainer = styled.section`
  max-height: calc(100vh - 170px);
  overflow-y: auto;
`;

const Notifications = ({ show, onShow }) => {
  const [shouldClear, setShouldClear] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const alert = useAlert();

  const { t } = useTranslation("routes");
  const { t: tN } = useTranslation("notifications");
  const api = useApi();
  const navigate = useNavigate();

  const {
    notifications,
    revalidateNotifications,
    notificationCount,
    deleteNotification: localDeleteNotification,
  } = useNotifications();

  const viewNotification = async (notification) => {
    const notificationTypeToLink = {
      "App\\Notifications\\ProcessWaitingForAnalysisNotification": `pesquisa/diligencia/${notification.data?.process_id}`,
      "App\\Notifications\\MonitoringChangeValues": `pesquisa/diligencia/${notification.data?.process_id}`,
      "App\\Notifications\\ProcessStatusChange": `pesquisa/diligencia/${notification.data?.process_id}`,
      "App\\Notifications\\PendingActionPlanNotification": `/plano-de-acao/answer?jwt=${notification.data?.access_token}`,
      "App\\Notifications\\AnsweredActionPlanNotification": `/plano-de-acao/${notification.data?.action_plan_id}`,
      "App\\Notifications\\ReadyReportNotification": `/downloadReport/${notification.data?.report_id}`,
    };

    const link = notificationTypeToLink[notification.type];

    if (link) {
      onShow(false);
      if (link.includes("downloadReport")) {
        await api()
          .get(link, { responseType: "blob" })
          .then((response) => {
            saveAs(response.data, notification.data?.report_name);
          })
          .catch(() => {
            alert.error(t("notifications.not-found"));
          });
        deleteNotification(notification.id);
      } else {
        navigate(link);
      }
    }
  };

  const clearNotificationsHandler = async () => {
    if (!showSpinner) {
      setShowSpinner(true);
      await api().delete("/user/notifications");
      revalidateNotifications();
      setShowSpinner(false);
      onShow(false);
    }
  };

  const deleteNotification = async (id) => {
    if (!showSpinner) {
      setShowSpinner(true);
      await api().delete(`/user/notifications?id=${id}`);
      revalidateNotifications();
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (show) {
      if (notificationCount > 0 && shouldClear) {
        setShouldClear(false);
        api()
          .put("/user/notifications")
          .then(() => {
            revalidateNotifications();
          });
      }
    } else {
      setShouldClear(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, revalidateNotifications]);

  return (
    <Container show={show}>
      <section>
        <H5 isBold>
          <X onClick={() => onShow(false)} />
          {t("notifications.title")}
        </H5>
        <P2 onClick={clearNotificationsHandler}>
          {t("notifications.clear_list")}
        </P2>
      </section>

      <NotificationContainer>
        {showSpinner && (
          <SpinnerContainer>
            <Spinner appearance="secondary" />
          </SpinnerContainer>
        )}
        {notifications?.map((notification) => (
          <div key={notification.id}>
            <H6 isBold>
              <span style={{ display: "flex", alignItems: "center" }}>
                <Trash2
                  color={colors.status.error}
                  onClick={() => deleteNotification(notification.id)}
                />
                {notification.title}
              </span>{" "}
              {!!notification.read_at && <Eye />}
            </H6>
            <P3>
              {notification.type ===
              "App\\Notifications\\ProcessStatusChange" ? (
                <i>
                  {reduce(
                    `${notification.description} ${
                      notification.data.new_status_id
                        ? `: ${tN(
                            `${notification.type}.description.${notification.data.new_status_id}`
                          )}`
                        : ""
                    }`,
                    100
                  )}
                </i>
              ) : (
                <i>{reduce(notification.description, 100)}</i>
              )}
              <b>{notification.when}</b>
            </P3>
            {notification.type !==
              "App\\Notifications\\ProcessConflictSpreadSheetDone" && (
              <P2 onClick={() => viewNotification(notification)}>
                {t("notifications.view")}
              </P2>
            )}
          </div>
        ))}
        {notifications.length === 0 && <div>{t("notifications.empty")}</div>}
      </NotificationContainer>
    </Container>
  );
};

Notifications.propTypes = {
  show: bool,
  onShow: func,
};

Notifications.defaultProps = {
  show: false,
  onShow: () => {},
};

export default Notifications;

import styled, { css } from "styled-components";
import { breakpoints, colors, easing, typograph } from "~/styles";

const { gray, support, secondary, green, primary, purple } = colors;

export const CommentsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem 2rem;
  padding-bottom: 2rem;
`;

export const CommentRow = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
`;

export const CommentAttachment = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
`;

export const CommentBubble = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-shrink: 0;
  max-width: 75%;
  width: fit-content;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 2px;
  padding: 2rem;
  background-color: #f2edf7;
  font-size: 12px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #d5c8e4;
    `}
`;

export const CommentOwner = styled.div`
  font-size: 12px;
  padding: 0.5rem;
  max-width: 75%;
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const CommentOptionsDropdownItem = styled.li`
  flex-shrink: 0;
  border: none;
  padding: 5px 10px;
  width: 100%;
  text-align: start;
  font-size: 12px;

  &:hover {
    color: ${gray._500};
  }

  &.active {
    color: ${primary._000};
  }
`;

export const CommentOptionsDropdown = styled.ul`
  position: absolute;
  top: 6px;
  left: 6px;
  display: none;
  opacity: 0;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 6px;
  z-index: 1;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  flex-direction: column;
`;

export const CommentOptions = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  :focus-within {
    ${CommentOptionsDropdown} {
      display: flex;
      opacity: 1;
      pointer-events: all;
    }
  }
`;

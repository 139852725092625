import styled from "styled-components";

import { Layout } from "~/components";
import { breakpoints, colors } from "~/styles";

const { gray } = colors;

export const View = styled(Layout)`
  margin-bottom: 6.4rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionsHeader = styled.div`
  display: flex;
  align-items: center;

  & > div {
    min-width: 25rem;
    margin-left: 1.6rem;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 3.2rem;
    flex-direction: column;
    display: flex;
    width: 100%;
    padding: 1rem;
    box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193, 193, 193, 0.29);
    align-items: center;
    border-radius: 0.4rem;
    background-color: ${gray._000};

    & > div {
      margin: 0.8rem 0 !important;
      width: 100%;
    }
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

import React, { useContext } from 'react';
import { element } from 'prop-types';
import { useKeycloak } from '@react-keycloak/web';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const { keycloak } = useKeycloak();

  keycloak.onTokenExpired = () => {
    keycloak.updateToken()
      .then((refreshed) => {
        if (refreshed) {
          localStorage.setItem('dd-token', keycloak.token);
        }
        else
          throw Error("Failed");
      })
      .catch(() => {
        keycloak.logout();
      })
  }
  
  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  } else {
    localStorage.setItem('dd-token', keycloak.token);
  }

  keycloak.onAuthLogout = () => {
    localStorage.removeItem('dd-token');
  }

  const values = {
    logout: () => { keycloak.logout(); },
    login: () => { keycloak.login(); },
    isAuthenticated: keycloak.authenticated,
    token: keycloak.token,
  };

  return (
    <AuthContext.Provider value={ values }>
      { children }
    </AuthContext.Provider>
  );
};

/**
 * @returns {{ logout: function, login: function, isAuthenticated: boolean }}
 */
export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: element.isRequired,
};

import { Form as Unform } from '@unform/web';
import styled from 'styled-components';

import { H6 } from '~/components';
import { rgba } from '~/hooks';
import { colors, typograph } from '~/styles';

const {
  gray,
  primary,
} = colors;

const {
  size,
  weight,
} = typograph;

export const View = styled(Unform)`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 1.6rem;
  }
`;

export const CheckboxLabel = styled.div`
  color: ${gray._500};
  display: flex;

  & > div {
    width: 3.2rem;
    margin-left: .6rem;

    input {
      color: ${gray._500};
      font-weight: ${weight.semiBold};
      background-color: transparent;
    }
  }
`;

export const CheckboxInput = styled.div`
  display: flex;

      color: ${gray._500};
      font-weight: ${weight.semiBold};
      background-color: transparent;
      width:40px;
      input{
        width:40px;
      }
  }
`;

export const CheckboxRisk = styled.div`
  display: none;
  }
`;

export const CancelModal = styled.div`
  display: flex;
  margin-top: 2.4rem;
  align-items: center;
  flex-direction: column;

  & > span {
    width: 10.0rem;
    height: 10.0rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, '.1')};

    svg {
      width: 4.0rem;
      height: 4.0rem;
      stroke: ${primary._000};
    }
  }

  & > ${H6} {
    max-width: 32.0rem;
    text-align: center;
    margin-top: 3.2rem;
    line-height: ${size.m2};
    font-weight: ${weight.semiBold};
  }
`;

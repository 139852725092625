import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import {
  Plus,
  Unlock,
  Lock,
  CheckCircle,
  Info,
  ChevronDown,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form } from "@unform/web";
import { format } from "date-fns";
import styled from "styled-components";
import * as Yup from "yup";

import {
  P3,
  P2,
  Button,
  Tooltip,
  Modal,
  DatePicker,
  Input,
  Upload,
  Select,
  Radio,
  H6,
} from "~/components";
import { formatErrorMessage, parseISO, useFetch, useApi } from "~/hooks";
import { CreateTag, DeleteMonitoring, ModalMonitoring } from "~/layout";
import { error, useMe } from "~/services";
import { breakpoints, colors } from "~/styles";

import {
  AnalysisButton,
  AnalysisIcon,
  ReportsModal,
  ReportsValuesModal,
} from "../../../utils/Card/styles";
import { useResultData } from "../../resultContext";
import { CardView, ModalView } from "../../styles";
import {LongTextInput} from "~/components/LongTextInput";

const Title = styled(P2)`
  display: flex;
  flex-direction: column;
  color: ${colors.gray._500};
  justify-content: center;
`;

const ReportButton = styled(AnalysisButton)``;

const View = styled(CardView)`
  article {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
    
    ${Title} {
      grid-column: span 4;
    }
    
    > div {
      grid-column: span 8;

      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    article {
      ${Title},
      > div {
        grid-column: span 12;
        justify-content: start;
      }
    }
  }
`;

const DiligenceActionPanel = () => {
  const [showTag, setShowTag] = useState(null);
  const [monitoring, setMonitoring] = useState(null);
  const [showActionPlanModal, setShowActionPlanModal] = useState(false);
  const [showSeem, setShowSeem] = useState(false);
  const [showRisks, setShowRisks] = useState(false);
  const [analysisItem, setAnalysisItem] = useState(null);
  const [showAnalysisModal, setShowAnalysisModal] = useState(false);
  const [changeValuesModal, onChangeValuesModal] = useState(false);
  const [reportModal, onReportModal] = useState(false);
  const [hasAssociatedDiligences, setIfHasAssociatedDiligences] =
    useState(false);
  const api = useApi();
  const reportsRef = useRef(null);
  const actionPlanRef = useRef(null);
  const analysisFormRef = useRef(null);
  const analysisButtonRef = useRef(null);
  const seemRef = useRef(null);
  const riskRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { id: processID } = useParams();
  const { user } = useMe();
  const alert = useAlert();
  const {
    headers,
    mutateHeaders,
    seem,
    addMutation,
    mutateSeem,
    themes,
    surveysItemsRisk,
    mutateRisks,
  } = useResultData();
  
  const changedProcessValues = useFetch({
    url: processID ? `/process/${processID}/changedvalues` : null,
  });

  useEffect(() => {
    if (!showAnalysisModal) {
      setAnalysisItem(null);
    }
  }, [showAnalysisModal]);

  const { data: analysisButtonItemsData, mutate } = useFetch({
    url: "/analysis",
  });
  const analysisButtonItems = analysisButtonItemsData?.data;

  useEffect(() => {
    addMutation(mutate);
  }, [addMutation, mutate]);

  const hasTags = headers?.tag?.length > 0;

  const { t: tComponents } = useTranslation("components");
  const { t: T } = useTranslation("card");
  const { t, i18n } = useTranslation("diligence");
  const { t: tAuth } = useTranslation("auth");

  const lockEdit = useCallback(async () => {
    try {
      if (headers?.data?.analyst_editor) {
        await api().delete(`/process/${processID}/analyst`);
      } else {
        await api().post(`/process/${processID}/analyst`);
      }

      mutateHeaders();
    } catch ({ response }) {
      alert.error(response.data.message);
    }
  }, [alert, headers?.data?.analyst_editor, processID, mutateHeaders]);

  const { data: tags, mutateTags } = useFetch({
    url: `/tag?clientId=${headers?.data.idcliente}`,
  });

  useEffect(() => {
    if (themes?.length > 1) {
      setIfHasAssociatedDiligences(
        themes.findIndex((theme) => {
          return (
            [
              "Entidades Associadas (PF)",
              "Associated Entities (Individuals)",
              "Entidades Asociadas (PF)",
              "Entidades Associadas (PJ)",
              "Associated Entities (Legal Entity)",
              "Entidades Asociadas (PJ)"
            ].indexOf(
              theme.name
            ) > -1
          );
        }) > -1
      );
    }
  }, [themes]);

  const ckEditorConfig = {
    language: i18n.language === 'pt' ? 'pt-br' : i18n.language,
    toolbar: {
      items: [
        'undo', 'redo', '|', 'heading', '|', 'aligment', '|', 'bold',
        'italic', 'numberedList', 'outdent', 'indent', 'removeFormat',
      ],
      height:'500',
      shouldNotGroupWhenFull: true
    },
  };

  const [actionPlanDescriptionValue, setActionPlanDescriptionValue] = useState('');
  const [seemNoteValue, setSeemNoteValue] = useState('');

  const handleActionPlanSubmit = useCallback(
    (data) => {
      console.log(data);
      /*data.description = actionPlanDescriptionValue*/
      Yup.object()
        .shape({
          due_date: Yup.string().required(
            tComponents("tablerow.modal.alerts.due_date")
          ),
          responsible_name: Yup.string().required(
            tComponents("tablerow.modal.alerts.responsible_name")
          ),
          responsible_email: Yup.string()
            .email(tComponents("tablerow.modal.alerts.responsible_email.0"))
            .required(tComponents("tablerow.modal.alerts.responsible_email.1")),
          description: Yup.string().required(
            tComponents("tablerow.modal.alerts.description")
          ),
        })
        .validate(data, {
          abortEarly: false,
        })
        .then(async () => {
          const { upload, due_date: dueDate, ...restData } = data;

          if (data.description.length > 1000) {
            alert.error(t("seem.characteres_over_limit"));
            setLoading(false);
          } else {
            const due_date = format(dueDate, "yyyy-MM-dd");
            const formattedData = {
              ...restData,
              due_date,
              subject: tComponents("tablerow.modal.subject"),
              process_id: Number(processID),
            };

            setLoading(true);
            await api()
              .post("/actionplan", formattedData)
              .then(({ data: res }) => {
                if (upload) {
                  const form = new FormData();
                  form.append("attachment", upload);
                  api()
                    .post(`/actionplan/${res.id}/attachment`, form)
                    .catch(({ response }) => {
                      alert.error(
                        formatErrorMessage(
                          response,
                          tComponents("tablerow.modal.alerts.upload"),
                          tAuth("expired")
                        )
                      );
                    });
                }
                setShowActionPlanModal(false);
                alert.success(tComponents("tablerow.modal.alerts.success"));
              })
              .catch(({ response }) => {
                alert.error(
                  formatErrorMessage(
                    response,
                    tComponents("tablerow.modal.alerts.error"),
                    tAuth("expired")
                  )
                );
              });
            setLoading(false);
          }
        })
        .catch((err) => error(err, actionPlanRef));
    },
    [alert, processID, tComponents, tAuth, actionPlanDescriptionValue]
  );

  const handleRiskSubmit = useCallback(
    (data) => {
      Yup.object()
        .shape({
          status: Yup.string().required(T("risks.modal.alerts.status")),
          note: Yup.string()
            .max(150, T("risks.modal.alerts.max_caracters"))
            .required(T("risks.modal.alerts.note_required")),
        })
        .validate(data, {
          abortEarly: false,
        })
        .then(async () => {
          setLoading(true);
          await api()
            .post(`/process/${processID}/customRisk`, {
              status: data.status,
              note: data.note,
            })
            .then(async () => {
              alert.success(T("risks.modal.alerts.success"));
              await mutateHeaders();
              await mutateSeem();
              await mutateRisks();
            })
            .catch(({ response }) => {
              alert.error(
                formatErrorMessage(
                  response,
                  T("risks.modal.alerts.error"),
                  tAuth("expired")
                )
              );
            });

          setShowRisks(false);
          setLoading(false);
        })
        .catch((err) => error(err, riskRef));
    },
    [alert, mutateHeaders, processID, T, mutateSeem, tAuth]
  );

  const handleSeemSubmit = useCallback(
    (data) => {
      /*data.note = seemNoteValue ?? '';*/
      Yup.object()
        .shape({
          status: Yup.string().required(T("seem.modal.alerts.status")),
          note: Yup.string()
        })
        .validate(data, {
          abortEarly: false,
        })
        .then(async () => {
          setLoading(true);
          if (data.note.length > 2000) {
            alert.error(t("seem.characteres_over_limit"));
            setLoading(false);
          } else {
            await api()
              .patch(`/process/${processID}/conclude`, {
                note: data.note,
                status: data.status,
              })
              .then(async ({ data: res }) => {
                const archive = seemRef.current.getFieldValue("document");

                if (archive) {
                  const form = new FormData();
                  form.append("attachment", archive);

                  await api().post(
                    `/process/${processID}/seem/${res.feedback.id}/attachment`,
                    form
                  );
                }

                mutateHeaders();
                mutateSeem();
                alert.success(T("seem.modal.alerts.success"));
              })
              .catch(({ response }) =>
                alert.error(
                  formatErrorMessage(
                    response,
                    T("seem.modal.alerts.error"),
                    tAuth("expired")
                  )
                )
              );

            setShowSeem(false);
            setLoading(false);
          }
        })
        .catch((err) => error(err, seemRef));
    },
    [alert, mutateHeaders, processID, T, mutateSeem, tAuth, seemNoteValue]
  );

  const handleAnalysisSubmit = useCallback(
    (data) => {
      Yup.object()
        .shape({
          note: Yup.string().required(T("analysis.modal.alerts.note")),
        })
        .validate(data, {
          abortEarly: false,
        })
        .then(() => {
          api()
            .post(`/process/${processID}/analysis/${analysisItem.id}`, data)
            .then(() => {
              mutateHeaders();
              alert.success(T("analysis.modal.alerts.success"));
              setShowAnalysisModal(false);
            })
            .catch(({ response }) =>
              alert.error(
                formatErrorMessage(
                  response,
                  T("analysis.modal.alerts.error"),
                  tAuth("expired")
                )
              )
            );
        })
        .catch((err) => error(err, analysisFormRef));
    },
    [alert, analysisItem, processID, T, mutateHeaders, tAuth]
  );

  const handleReportsSubmit = useCallback(
    async (datad) => {
      if (datad.langs === null || datad.general === null) {
        alert.error(T("reports.alerts.error.0"));
        return;
      }

      const checked = (word) =>
        datad.general.indexOf(word) !== -1 ? 1 : undefined;
      setLoading(true);
      try {
        const res = await api().get(`/process/${processID}/report`, {
          params: {
            language: datad.langs,
            type: datad.general,
          },
        });

        onReportModal(false);
        alert.success(res?.data?.message);
      } catch ({ response }) {
        alert.error(
          formatErrorMessage(
            response,
            T("reports.alerts.error.1"),
            tAuth("expired")
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [alert, processID, T, tAuth]
  );
  
  const canCurrentUserCloseDiligence = !(
    !!headers?.client[0]?.processes_should_be_closed_by_icts &&
    user?.isAdminICTS()
  );

  const list_reports = [
    {
      name: "general",
      options: [
        {
          id: "executive",
          value: "executive",
          placeholder: "asd",
          label: T("reports.items.10"),
          description: T("description.executive"),
          reveal: true,
          selectedByDefault: true,
        },
        {
          id: "summary",
          value: "summary",
          label: T("reports.items.12"),
          description: T("description.summary"),
          reveal: true,
        },
        {
          id: "full",
          value: "full",
          label: T("reports.items.14"),
          description: T("description.full"),
          reveal: true,
        },
      ],
    },
  ];
  const list_langs = [
    {
      name: "langs",
      options: [
        {
          id: "pt",
          value: "pt",
          placeholder: "asd",
          label: "PT",
          reveal: true,
          selectedByDefault: true,
        },
        {
          id: "en",
          value: "en",
          label: "EN",
          reveal: true,
        },
        {
          id: "es",
          value: "es",
          label: "ES",
          reveal: true,
        },
      ],
    },
  ];

  const validProcessClient = !!headers;

  return (
    <>
      <View>
        <article>
          <Title isBold>
            <span>{`${t("card.2")}: ${headers?.data?.requester?.nome}`}</span>
            <span>
              {`${t("card.3")}: ${
                headers?.client?.map((client) => client.cliente)?.join(" / ") ??
                t("card.1")
              }`}
            </span>
            <span>
              {`${t("card.0")}: ${
                headers?.data?.dataabertura
                  ? format(
                      parseISO(headers?.data?.dataabertura),
                      "dd/MM/yyyy  HH:mm"
                    )
                  : t("card.1")
              }`}
            </span>
            <span>
              {`${t("card.4")}: ${
                headers?.data?.dataencerramento
                  ? format(
                      parseISO(headers?.data?.dataencerramento),
                      "dd/MM/yyyy  HH:mm"
                    )
                  : t("card.1")
              }`}
            </span>
          </Title>
          <div>
            {user?.isAdminICTS() && (
              <Tooltip
                label={`${T("admin_operations.being_edited_by")} ${
                  headers?.data?.analyst?.nome
                } (${headers?.data?.analyst?.id})`}
                width={300}
                isActive={
                  headers?.data?.analyst_editor &&
                  user?.usuario?.id !== headers?.data?.analyst_editor
                }
              >
                <Button
                  label={
                    headers?.data?.analyst_editor
                      ? T("admin_operations.stop_edit")
                      : T("admin_operations.edit")
                  }
                  onClick={lockEdit}
                  iconLeft={
                    headers?.data?.analyst_editor ? <Unlock /> : <Lock />
                  }
                  disabled={
                    !!headers?.data?.analyst_editor &&
                    user?.usuario?.id !== headers?.data?.analyst_editor
                  }
                  appearance="tertiary"
                />
              </Tooltip>
            )}
            <Button
              disabled={user?.isVisualizador() || !validProcessClient}
              label={hasTags ? T("tag.label.0") : T("tag.label.1")}
              onClick={() =>
                setShowTag({
                  id: hasTags ? headers?.tag[0].tag.id : null,
                  type: hasTags ? "replace" : "assign",
                  reveal: true,
                })
              }
              appearance="tertiary"
            />

            <Tooltip
              label={T("monitoring.tooltip")}
              width={416}
              isActive={
                !headers?.monitoring?.deleted_by &&
                !user.isVisualizador() &&
                validProcessClient
              }
            >
              <Button
                label={(() => {
                  if (!headers?.monitoring) {
                    return T("monitoring.label.1");
                  }
                  if (!headers?.monitoring?.deleted_by) {
                    return T("monitoring.label.0");
                  }
                  return T("monitoring.label.1");
                })()}
                onClick={() => {
                  // eslint-disable-next-line
                  headers?.monitoring
                    ? setMonitoring({ delete: true })
                    : setMonitoring({ create: true });
                }}
                appearance="tertiary"
                disabled={
                  !!headers?.monitoring?.deleted_by ||
                  user.isVisualizador() ||
                  !validProcessClient
                }
              />
            </Tooltip>

            <Button
              label={T("action_plan")}
              onClick={() => {
                setActionPlanDescriptionValue('');
                setShowActionPlanModal(true);
              }}
              disabled={user?.isVisualizador() || !validProcessClient}
              iconLeft={<Plus />}
              appearance="tertiary"
            />
            <Button
              label={T("risks.label")}
              onClick={() => setShowRisks(true)}
              appearance="tertiary"
              disabled={user?.isVisualizador() || !validProcessClient}
            />

            <AnalysisButton>
              <Button
                label={T("analysis.label")}
                iconLeft={<Plus />}
                appearance="tertiary"
                disabled={user?.isVisualizador() || user?.isRevisor() || !validProcessClient}
                ref={analysisButtonRef}
              />
              <div>
                {analysisButtonItems?.map((item) => {
                  const hasBeenSent = headers?.data.analysis?.find(
                    (sentAnalysisItem) => sentAnalysisItem.id === item.id
                  );

                  return (
                    <P3
                      as="button"
                      key={item.id + item.title}
                      title={item.text}
                      onClick={() => {
                        if (hasBeenSent) {
                          alert.error(T("analysis.modal.alerts.error"));
                        } else {
                          setAnalysisItem(item);
                          setShowAnalysisModal(true);
                        }
                      }}
                    >
                      <AnalysisIcon hasBeenSent={hasBeenSent}>
                        {hasBeenSent ? <CheckCircle /> : <Info />}
                      </AnalysisIcon>

                      {item.title}
                    </P3>
                  );
                })}
              </div>
            </AnalysisButton>

            <Button
              label={T("seem.label")}
              onClick={() => {
                setSeemNoteValue('');
                setShowSeem(true);
              }}
              disabled={
                !!seem?.data?.id ||
                user?.isVisualizador() ||
                user?.isRevisor() ||
                !validProcessClient
              }
              appearance="tertiary"
            />

            <ReportButton>
              <Button
                label={T("reports.label")}
                disabled={user?.isVisualizador() || !validProcessClient}
                iconLeft={<Plus />}
                // onClick={() => onReportModal(! reportModal)}
                appearance="tertiary"
              />
              <div>
                <P3
                  as="button"
                  title={T("reports.title")}
                  onClick={() => {
                    onChangeValuesModal(!changeValuesModal);
                  }}
                >
                  <AnalysisIcon>
                    <Info />
                  </AnalysisIcon>
                  {T("reports.title")}
                </P3>
                <P3
                  as="button"
                  title={T("reports.label")}
                  onClick={() => onReportModal(!reportModal)}
                >
                  <AnalysisIcon>
                    <Info />
                  </AnalysisIcon>
                  {T("reports.label")}
                </P3>
              </div>
            </ReportButton>
          </div>
        </article>
      </View>

      {showTag?.reveal && (
        <CreateTag
          hasDelete
          show={showTag}
          render={mutateHeaders}
          onShow={setShowTag}
          options={tags?.data?.map((item) => ({
            label: [item.name],
            value: item.id,
          }))}
          clientId={headers?.data?.idcliente}
          processId={Number(processID)}
          renderList={mutateTags}
          hasAssociatedDiligences={hasAssociatedDiligences}
        />
      )}

      {monitoring?.create && (
        <ModalMonitoring
          show
          render={mutateHeaders}
          onShow={setMonitoring}
          processId={Number(processID)}
        />
      )}

      {monitoring?.delete && (
        <DeleteMonitoring
          id={headers?.monitoring?.id}
          show={monitoring.delete}
          render={mutateHeaders}
          onShow={setMonitoring}
        />
      )}

      {showActionPlanModal && (
        <Modal
          show
          onShow={setShowActionPlanModal}
          title={tComponents("tablerow.modal.title")}
          action={{
            label: tComponents("tablerow.modal.action"),
            loading,
            onClick: () => actionPlanRef?.current.submitForm(),
          }}
        >
          <ModalView>
            <P2>{tComponents("tablerow.modal.text")}</P2>

            <Form ref={actionPlanRef} onSubmit={handleActionPlanSubmit}>
              <DatePicker
                name="due_date"
                label={tComponents("tablerow.modal.datepicker.label")}
                minDate={new Date()}
                appearance="secondary"
                placeholder={tComponents(
                  "tablerow.modal.datepicker.placeholder"
                )}
              />
              <Input
                name="responsible_name"
                label={tComponents("tablerow.modal.responsible_name.label")}
                appearance="secondary"
                placeholder={tComponents(
                  "tablerow.modal.responsible_name.placeholder"
                )}
              />
              <Input
                name="responsible_email"
                label={tComponents("tablerow.modal.responsible_email.label")}
                appearance="secondary"
                placeholder={tComponents(
                  "tablerow.modal.responsible_email.placeholder"
                )}
              />
              <LongTextInput
                name="description"
                id="action-plan-description-ck"
                label={tComponents("tablerow.modal.description.label")}
                language={i18n.language === 'pt' ? 'pt-br' : i18n.language}
              />
              
              <Upload
                name="upload"
                  label={tComponents("tablerow.modal.upload.label")}
                  isDocument
                />
            </Form>
          </ModalView>
        </Modal>
      )}

      {showRisks && (
        <Modal
          show
          onShow={setShowRisks}
          title={T("risks.modalLabel")}
          action={{
            label: T("risks.modal.action"),
            loading,
            onClick: () => riskRef?.current.submitForm(),
          }}
        >
          <Form ref={riskRef} onSubmit={handleRiskSubmit} className="form-seem">
            <Select
              icon={ChevronDown}
              name="status"
              label={T("risks.label")}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99 }) }}
              options={[
                {
                  value: 1,
                  label: T("risks.modal.status.options.0"),
                },
                {
                  value: 2,
                  label: T("risks.modal.status.options.1"),
                },
                {
                  value: 3,
                  label: T("risks.modal.status.options.2"),
                },
                {
                  value: 4,
                  label: T("risks.modal.status.options.3"),
                },
              ]}
              appearance="primary"
              placeholder={T("risks.modal.status.placeholder")}
            />

            <Input
              textarea
              name="note"
              label={"Motivo"}
              maxLength={150}
              appearance="secondary"
              placeholder={"Por favor, insira o motivo da alteração."}
            />
          </Form>
        </Modal>
      )}

      {showSeem && (
        <Modal
          show
          onShow={setShowSeem}
          title={T("seem.label")}
          action={{
            label: T("seem.modal.action"),
            loading,
            onClick: () => seemRef?.current.submitForm(),
          }}
        >
          <Form ref={seemRef} onSubmit={handleSeemSubmit} className="form-seem">
            <Select
              icon={ChevronDown}
              name="status"
              label={T("seem.modal.status.label")}
              options={[
                {
                  value: 3,
                  label: T("seem.modal.status.options.0"),
                },
                {
                  value: 7,
                  label: T("seem.modal.status.options.1"),
                },
                {
                  value: 8,
                  label: T("seem.modal.status.options.2"),
                },
              ]}
              appearance="secondary"
              placeholder={T("seem.modal.status.placeholder")}
            />
            
            <LongTextInput
              name="note"
              id="seem-note-ck"
              label={T("seem.modal.note.label")}
              language={i18n.language === 'pt' ? 'pt-br' : i18n.language}
            />

            <Upload
              name="document"
              label={T("seem.modal.document.label")}
              isDocument
            />
          </Form>
        </Modal>
      )}

      {showAnalysisModal && (
        <Modal
          show
          onShow={setShowAnalysisModal}
          title={analysisItem.title}
          action={{
            label: T("analysis.modal.action"),
            onClick: () => analysisFormRef?.current.submitForm(),
          }}
        >
          <P2 as="p">{analysisItem.text}</P2>
          <Form ref={analysisFormRef} onSubmit={handleAnalysisSubmit}>
            <Input
              name="note"
              label={T("analysis.modal.note.label")}
              appearance="secondary"
              placeholder={T("analysis.modal.note.placeholder")}
              textarea
            />
          </Form>
        </Modal>
      )}

      {reportModal && (
        <Modal
          show
          onShow={onReportModal}
          hasOverflow={false}
          title={T("reports.modal.title")}
          action={{
            label: T("reports.modal.action"),
            loading,
            onClick: () => reportsRef?.current.submitForm(),
          }}
          size="lg"
        >
          <ReportsModal ref={reportsRef} onSubmit={handleReportsSubmit}>
            <P2>{T("reports.modal.select_report_type")}</P2>
            <div style={{ display: "flex", width: '100%' }}>
              {list_reports?.map((item) => {
                if (item.options?.length > 0) {
                  const initialValues = item.options
                    .filter((o) => !!o.selectedByDefault)
                    .map((o) => o.id);

                  return (
                    <Radio
                      name={item.name}
                      options={item.options}
                      initialValueSelected={initialValues}
                      containerDir="row"
                    />
                  );
                }
                return null;
              })}
            </div>
            <P2>{T("reports.modal.select_report_language")}</P2>
            <div style={{ display: "flex", width: '100%' }}>
              {list_langs?.map((item) => {
                if (item.options?.length > 0) {
                  const initialValues = item.options
                    .filter((o) => !!o.selectedByDefault)
                    .map((o) => o.id);

                  return (
                    <Radio
                      name={item.name}
                      options={item.options}
                      initialValueSelected={initialValues}
                      containerDir="row"
                    />
                  );
                }
                return null;
              })}
            </div>
          </ReportsModal>
        </Modal>
      )}

      {changeValuesModal && (
        <Modal
          show
          onShow={onChangeValuesModal}
          title={T("reports.title")}
          hasActions={false}
          size="lg"
        >
          <ReportsValuesModal>
            {changedProcessValues?.data?.data.length === 0 ? (
              <H6 style={{ textAlign: "center" }}>
                {T("reports.changeInformation")}
              </H6>
            ) : (
              <table style={{ fontSize: "1.5rem" }}>
                <tr>
                  <th style={{ width: "30%" }}>{T("reports.change")}</th>
                  <th>{T("reports.information")}</th>
                </tr>
                {changedProcessValues?.data?.data?.map((changeValue, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={changeValue.document + index}>
                    {changeValue.name === "Exclusão" ? (
                      <td style={{ textAlign: "center", color: "#0D79DE" }}>
                        {changeValue.name}
                      </td>
                    ) : (
                      <td style={{ textAlign: "center", color: "#F68720" }}>
                        {changeValue.name}
                      </td>
                    )}
                    <td>{changeValue.document}</td>
                  </tr>
                ))}
              </table>
            )}
          </ReportsValuesModal>
        </Modal>
      )}
    </>
  );
};

export default DiligenceActionPanel;

import { Form as Unform } from "@unform/web";
import styled, { css } from "styled-components";

import { P2, H6, Layout } from "~/components";
import { colors, typograph, breakpoints } from "~/styles";
import { rgba } from "~/hooks";
const { gray, support, secondary, primary, green } = colors;
const { weight, size } = typograph;

export const View = styled(Layout)`
  @media screen and (max-width: ${breakpoints.sm}) {
    .sc-tYoTV.hISMaO {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      white-space: nowrap;

      & > div,
      & > a {
        margin: 0 0 1.6rem !important;
        display: flex;
        justify-content: flex-start;
        font-size: ${size.s3};

        :nth-of-type(even) {
          padding-left: 3.2rem;
        }
      }
    }
  }
`;

export const Spacer = styled.div`
  justify-content: space-around;
  margin: auto;
  width: 100%;
  color: #fff !important;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 40rem);
  flex: 1;
`;

export const DiligenceSearch = styled.div`
  flex-direction: column;

  & > div {
    width: 100%;
    display: grid;
    column-gap: 1.6rem;
    grid-template-columns: repeat(4, 1fr);

    &:first-of-type > div {
      max-width: 32rem;
    }

    &:not(:first-of-type) {
      display: flex;
      flex-direction: column;

      ${P2} {
        color: ${gray._000};
        margin: 1.6rem 0;
        border-top: 0.1rem solid ${support._000};
        padding-top: 2.4rem;
      }

      & > div {
        display: grid;
        row-gap: 1.6rem;
        column-gap: 1.6rem;
        margin-left: 0 !important;
        grid-template-columns: repeat(2, 1fr);

        @media screen and (max-width: ${breakpoints.sm}) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      & > .modal-advanced-options {
        display: unset;
      }
    }
  }

  ${({ hasMoreField }) =>
    hasMoreField &&
    css`
      & > div {
        grid-template-columns: repeat(5, 1fr);

        &:first-of-type > div {
          max-width: 25rem;
        }
      }
    `}

  @media screen and (max-width: ${breakpoints.lg}) {
    & > div:first-of-type > div {
      max-width: 22rem;
    }

    ${({ hasMoreField }) =>
      hasMoreField &&
      css`
        & > div:first-of-type > div {
          max-width: 20rem;
        }
      `}
  }

  @media screen and (max-width: ${breakpoints.md}) {
    & > div:first-of-type > div {
      max-width: 100%;
    }

    ${({ hasMoreField }) =>
      hasMoreField &&
      css`
        & > div:first-of-type > div {
          max-width: 100%;
        }
      `}
  }
`;

export const BatchSearch = styled.div`
  flex-direction: column;
  gap: 1rem;

  & > div {
    width: 100%;
    display: flex;
    column-gap: 1.6rem;
    grid-template-rows: repeat(4, 1fr);
  }

  & > div.advanced-options {
    margin-left: auto;
  }

  div.button {
    margin-top: 1.6rem;
    flex-direction: row-reverse;
  }

  ${({ hasMoreField }) =>
    hasMoreField &&
    css`
      & > div {
        grid-template-columns: repeat(5, 1fr);

        &:first-of-type > div {
          max-width: 25rem;
        }
      }
    `}

  @media screen and (max-width: ${breakpoints.lg}) {
    & > div:first-of-type > div {
      max-width: 22rem;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    & > div:first-of-type > div {
      max-width: 100%;
    }
  }
`;

export const Search = styled(Unform)`
  display: flex;
  padding: 2.4rem;
  margin: 0 0 4rem 0;
  align-items: flex-start;
  border-radius: 0.4rem;
  background-color: ${primary._000};

  & > div {
    width: 100%;
    margin-right: 1.6rem;
  }
  /* & > button {
    background-color: ${secondary} ;
  }
    button:hover {
      background-color: ${green._500};
    } */
  ${({ type, hasJurisdiction }) =>
    (type === "simplificada" || type === "cadastral") &&
    css`
      & > div {
        display: grid;
        column-gap: 1.6rem;
        grid-template-columns: ${!hasJurisdiction
          ? "repeat(2, 1fr)"
          : "repeat(4, 1fr)"};

        & > div {
          max-width: ${!hasJurisdiction ? "390px" : "289px"};
        }
      }

      button {
        margin-top: 0 !important;
      }
    `}

  ${({ type }) =>
    type === "diligencia" ||
    (type === "batches" &&
      css`
        & > div {
          display: flex;
          align-items: center;
        }
      `)}

  button {
    height: fit-content;
    margin-top: auto;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;

    & > div {
      margin-right: 0;
    }

    & > button {
      width: 100%;
      margin-top: 1.6rem !important;
      justify-content: center;
    }

    ${({ type }) =>
      type === "diligencia" &&
      css`
        & > div > div:first-of-type {
          row-gap: 1.6rem;
          grid-template-columns: repeat(2, 1fr);
        }
      `}
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: -3.2rem;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    ${({ type, hasJurisdiction }) =>
      (type === "simplificada" || type === "cadastral") &&
      css`
        & > div {
          row-gap: 1.4rem;
          grid-template-columns: repeat(2, 1fr);

          & > div:not(:first-child) {
            margin: 0;
          }

          & > div:last-of-type {
            max-width: 100% !important;
            grid-column: span ${hasJurisdiction ? 1 : 2};
          }
        }
      `}
  }

  @media screen and (max-width: ${breakpoints.xxs}) {
    ${({ type }) =>
      (type === "simplificada" || type === "cadastral") &&
      css`
        & > div {
          display: grid;
          row-gap: 1.6rem;
          column-gap: 0;
          grid-template-columns: 1fr;

          > * {
            grid-column: span 1 !important;
          }
        }
      `}

    ${({ type }) =>
      type === "diligencia" &&
      css`
        & > div > div:first-of-type {
          row-gap: 1.6rem;
          column-gap: 0;
          grid-template-columns: 1fr;

          > * {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    `}
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    ${({ type }) =>
      (type === "simplificada" || type === "cadastral") &&
      css`
        & > div > div {
          max-width: 100%;
        }
      `}
  }
`;

export const Body = styled.div`
  display: flex;
  margin-bottom: 3.2rem;
  flex-direction: column;

  ${H6} {
    color: ${gray._500};
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;

    & > div {
      top: 0.2rem;
      position: relative;
      margin-left: 1.4rem;
    }
  }
`;

export const ActionHeader = styled.div`
  max-width: 32.4rem;

  @media screen and (max-width: ${breakpoints.sm}) {
    grid-column: span 2;
    flex-direction: column;
    display: flex;
    padding: 1rem;
    box-shadow: 0rem 0rem 0.4rem 0.1rem rgba(193, 193, 193, 0.29);
    align-items: center;
    border-radius: 0.4rem;
    background-color: ${gray._000};
    max-width: 100%;
  }
`;

export const ModalView = styled.div`
  display: flex !important;
  flex-direction: column;

  & > span {
    margin: 0 auto 2rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: ${rgba(primary._000, ".1")};

    svg {
      width: 4rem;
      height: 4rem;
      stroke: ${primary._000};
    }
  }

  & > ${H6} {
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
    text-align: left !important;
  }

  & > p {
    color: ${gray._600};
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      margin-top: 2.4rem;
    }
  }
  &.modal-sm {
    min-height: 20rem;
  }
  &.modal-md {
    min-height: 26rem;
  }
  &.modal-lg {
    min-height: 32rem;
  }
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

import React, { useCallback, useEffect, useState } from "react";
import { Info } from "react-feather";

import { format } from "date-fns";

import {
  Container,
  Empty,
  Flag,
  H6,
  Helmet,
  SimpleLoading,
  Table,
  Tooltip,
} from "~/components";
import ClientSelect from "~/components/ClientSelect";
import { masked } from "~/hooks";
import { useMe } from "~/services";
import { parseISO } from "~/hooks/helpers";
import { CreateTag, Header } from "~/layout";
import { head, headings, title } from "../data";

import { useSearch } from "../../context/SearchProvider";
import { SearchHeader } from "../SearchHeader";
import { useTranslateText } from "./useTranslateText";

import { ActionHeader, Body, View, Wrapper } from "../styles";

export const Quick = () => {
  const { user, group, newGroup } = useMe();

  const {
    t,
    tC,
    rows,
    modal,
    query,
    setTag,
    onModal,
    batches,
    processes,
    render_tag,
    relationships,
    isLoadingProcess,
    isLoadingBatches,
    isLoadingRelationShips,
  } = useSearch();

  const { helmetText, tooltipLabelText } = useTranslateText({ query, t });

  const [rowsBatch, onRowsBatch] = useState([]);
  const [rowsSimple, onRowsSimple] = useState([]);
  const [rowsCadastral, onRowsCadastral] = useState([]);
  const [rowsDiligence, onRowsDiligence] = useState([]);

  const isLoading =
    isLoadingProcess || isLoadingBatches || isLoadingRelationShips;

  const table = {
    diligencia: {
      head: head(t).diligencia,
      body: rowsDiligence,
      route: "/pesquisa/diligencia",
      items: processes?.data?.length,
    },
    simplificada: {
      head: head(t).simplificada,
      body: rowsSimple,
      route: "/pesquisa/simplificada",
      items: rows?.length,
    },
    cadastral: {
      head: head(t).cadastral,
      body: rowsCadastral,
      route: "/pesquisa/cadastral",
      items: relationships?.length,
    },
    batches: {
      head: head(t).batches,
      body: rowsBatch,
      route: "/pesquisa/batches",
      items: batches?.data?.length,
    },
  };

  const row_table = useCallback(async () => {
    let row = [];
    if (rows !== undefined && rows.length <= 0) {
      row = [];
      return;
    }
    row = rows?.map((item) => {
      const users = item.entity.company || item.entity.person;
      return {
        id: item.id,
        items: [
          {
            label: format(parseISO(item.created_at), "dd/MM/yyyy"),
          },
          {
            label: users?.company_name || users?.name || "-",
            isBold: true,
          },
          {
            label: masked(users?.document || "-"),
          },
          {
            isArrow: true,
          },
        ],
      };
    });

    onRowsSimple(row);
  }, [rows]);

  const relationship_row_table = useCallback(() => {
    if (!relationships || relationships.length === 0) {
      return;
    }

    const row = relationships.data.map((relationship) => {
      const { id, created_at, root_node } = relationship;

      const labelCreatedAt = created_at
        ? format(parseISO(created_at), "dd/MM/yyyy")
        : "";
      const labelCompany = root_node?.company_id
        ? root_node.company?.company_name
        : "";
      const labelPerson = root_node?.person?.name || "";
      const labelDocument = root_node?.document || "";

      return {
        id,
        items: [
          {
            label: labelCreatedAt,
          },
          {
            label: labelCompany || labelPerson,
            isBold: true,
          },
          {
            label: masked(labelDocument),
          },
          {
            isArrow: true,
          },
        ],
      };
    });

    onRowsCadastral(row);
  }, [relationships]);

  const row_diligence = useCallback(async () => {
    if (processes !== undefined && processes.data?.length <= 0) {
      onRowsDiligence([]);
      return;
    }

    const row = processes?.data?.map((item) => {
      const isArrow =
        !user?.isAdminICTS() ||
        item.analyst_editor === null ||
        item.analyst_editor === user.usuario.id;

      return {
        id: item.id,
        items: [
          {
            label: format(parseISO(item.dataabertura), "dd/MM/yyyy"),
          },
          {
            label: item.razaosocial || item.nomefantasia || "-",
            isBold: true,
          },
          {
            label: item.cnpj,
          },
          {
            label: item?.workflows.map((child) => child.name),
            isMulti: true,
          },
          {
            label: <Flag status={item.new_status.name} />,
          },
          {
            isArrow,
            notArrowMessage:
              !isArrow && item?.analyst?.nome
                ? `${tC("admin_operations.being_edited_by")} ${
                    item?.analyst?.nome
                  } (${item?.analyst?.id})`
                : null,
          },
        ],
      };
    });
    onRowsDiligence(row);
  }, [processes]);

  const row_batch = useCallback(async () => {
    if (batches !== undefined && batches.data?.length <= 0) {
      onRowsBatch([]);
      return;
    }

    const row = batches?.data?.map((item) => {
      return {
        id: item?.id,
        items: [
          { label: item.client.cliente },
          { label: item.workflow.name, isBold: true },
          { label: item.entity.description },
          { label: Object.entries(item.metrics), isManyResults: true },
          { label: item.processes.length },
          { label: item.created_at },
        ],
      };
    });
    onRowsBatch(row);
  }, [batches]);

  useEffect(() => {
    row_table();
  }, [row_table]);

  useEffect(() => {
    relationship_row_table();
  }, [relationship_row_table]);

  useEffect(() => {
    row_diligence();
  }, [row_diligence]);

  useEffect(() => {
    row_batch();
  }, [row_batch]);

  return (
    <View>
      <Helmet title={helmetText} />
      <Container>
        
        <Header head={headings(t)} query={query}>
          {(query === "diligencia" ||
            query === "batches" ||
            query === "cadastral") && (
            <ActionHeader>
              <ClientSelect
                user={user}
                value={group}
                onChange={(val) => newGroup(val)}
                placeholder={t("components.group")}
              />
            </ActionHeader>
          )}
        </Header>
        {!user.isVisualizador() && (
          <SearchHeader />
        )}
        <Body>
          <H6>
            {title(t)[query]}
            <Tooltip width={250} label={tooltipLabelText} direction={'null'}>
              <Info size="2rem" />
            </Tooltip>
          </H6>

          <Wrapper>
            {!isLoading &&
            (!table[query]?.body || table[query]?.body?.length === 0) ? (
              <Empty />
            ) : (
              <>
                {!isLoading ? (
                  <Table
                    body={table[query]?.body}
                    route={table[query]?.route}
                    heading={table[query]?.head}
                    newTab
                  />
                ) : (
                  <SimpleLoading />
                )}
              </>
            )}
          </Wrapper>
        </Body>
      </Container>

      <CreateTag
        isReplace
        updateTag={setTag}
        show={modal}
        render={render_tag}
        onShow={onModal}
        clientId={group.value}
      />
    </View>
  );
};

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { Form } from "@unform/web";
import { ChevronDown } from "react-feather";
import { useTranslation } from "react-i18next";
import { Modal, P2, Select } from "~/components";
import { ModalView } from "../../styles";
import { error } from "~/services";
import { useApi, formatErrorMessage } from "~/hooks";
import { useResultData } from "../../resultContext";
import { isEmpty } from "lodash";

const ModalForeign = () => {
  const { id: processID } = useParams();
  const { t } = useTranslation("diligence");
  const { t: tAuth } = useTranslation("auth");
  const { headers, mutateEverything } = useResultData();

  const api = useApi();
  const alert = useAlert();
  const navigate = useNavigate();
  const foreignModalRef = useRef();

  const [loading, setLoading] = useState(false);
  const [nameOptions, setNameOptions] = useState([]);
  const [showPickDiligence, onShowPickDiligence] = useState(false);

  useEffect(() => {
    if (headers?.options && headers?.options.length > 0) {
      const formattedNameOptions = headers.options.map((option) => ({
        value: option.entityID,
        label: option.name,
      }));

      setNameOptions(formattedNameOptions);
      onShowPickDiligence(true);
    }
  }, [headers]);

  const onPickNameFormSubmit = useCallback(
    async (pickedData) => {
      console.log("teste")
      console.log(pickedData.nameOptions)
      if (isEmpty(pickedData.nameOptions)) {
        return alert.error(t("toggle.alerts.select"));
      }

      setLoading(true);

      await api()
        .post(`/process/${processID}/pickForeignEntity`, {
          entities: [pickedData.nameOptions],
        })
        .then(() => {
          mutateEverything();
          onShowPickDiligence(false);
        })
        .catch(({ response }) => {
          alert.error(
            formatErrorMessage(
              response,
              t("detail.messages.upload.error"),
              tAuth("expired")
            )
          );
          error(response);
        })
        .finally(() => setLoading(false));

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [t, alert, processID, tAuth]
  );

  return (
    <Modal
      size="lg"
      loading={loading}
      show={showPickDiligence}
      onShow={onShowPickDiligence}
      title={t("modal_foreign.title")}
      goBackOnCancel={() => navigate("/pesquisa?q=diligencia")}
      action={{
        loading,
        label: t("include_item.modal.action"),
        onClick: foreignModalRef?.current?.submitForm,
      }}
    >
      <ModalView className="modal-lg">
        <Form ref={foreignModalRef} onSubmit={onPickNameFormSubmit}>
          <div
            style={{
              gap: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <P2>{t("modal_foreign.new_text_cial")}</P2>
            <Select
              name="nameOptions"
              icon={ChevronDown}
              menuPosition="fixed"
              options={nameOptions}
              placeholder={t("modal_foreign.placeholder")}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
            />
          </div>
        </Form>
      </ModalView>
    </Modal>
  );
};
export default ModalForeign;

import React from 'react';
import Charts from 'react-apexcharts';

import { arrayOf, number, string } from 'prop-types';

import { colors, typograph } from '~/styles';

import View from './styles';

const { gray } = colors;
const { size, family } = typograph;

const StackedChart = ({ data, categories, ...rest }) => {
  const items = [];

  const yaxis = data?.reduce((acc, curr) => {
    acc[curr.name] = {
      name: curr.name,
      data: [],
      ...acc[curr.name],
    };

    acc[curr.name].data.push(curr.data);

    return acc;
  }, []);

  // eslint-disable-next-line
  for (const index in yaxis) {
    items.push({
      name: yaxis[index].name,
      data: yaxis[index].data,
    });
  }

  const options = () => ({
    colors: ['#00a28f', '#f6871f', '#0076de', '#d74b29'],
    foreColor: '#B6BACD',
    grid: {
      borderColor: gray._100,
    },
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      style: {
        colors: [gray._200],
        fontSize: size.s2,
        fontFamily: family,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    xaxis: {
      categories,
      // type: 'datetime',
      // datetimeUTC: false,
      // format: 'dd/MM',
      // datetimeFormatter: {
      //   year: 'MM / yyyy',
      //   month: 'MM / yyyy',
      //   day: 'dd / MM',
      //   hour: 'HH:mm',
      // },
      style: {
        colors: '#B6BACD',
      },
      labels: {
        style: {
          colors: '#B6BACD',
        },
      },
    },
    yaxis: {
      style: {
        colors: '#B6BACD',
      },
      labels: {
        style: {
          colors: '#B6BACD',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: 'top',
      offsetY: 10,
    },
    fill: {
      opacity: 1,
    },
  });

  return (
    <View>
      <Charts
        options={options()}
        series={items}
        type="bar"
        {...rest}
      />
    </View>
  );
};

StackedChart.propTypes = {
  data: arrayOf(number),
  categories: arrayOf(string),
};

StackedChart.defaultProps = {
  data: [],
  categories: [],
};

export default StackedChart;

import React from 'react';
import { arrayOf, number, object, string } from 'prop-types';
import { Item, TR, View } from '~/components/Table/styles';
import { reduce } from '~/hooks';

export const Table = ({ columns, title, items }) => {
  return (
    <View>
      { title && (
        <thead>
        <TR>
          <Item as="th" colSpan={ columns } isBold size="xxl" align="center" fontSize="m1">{ title }</Item>
        </TR>
        </thead>
      ) }
      <tbody>
      {
        items && items.map((rowColumns, idx) => {
          console.log("items: ", items);
          return (
            <TR key={ idx.toString() }>
              {
                rowColumns && rowColumns.map(
                  (column, columnIdx) => {
                    return (
                      <Item
                        key={ columnIdx.toString() }
                        height={ 4 }
                      >
                        {
                          column && Array.isArray(column) &&
                          column.map(value => <p strong>{ value.text }</p>)
                        }
                        {
                          column &&
                          ( column.text || <a target="_blank" href={ column.link }>{ reduce(column.link, 64) }</a> )
                        }
                      </Item>
                    );
                  }) 
              }
            </TR>
          );
        })
      }
      </tbody>
    </View>
  );
};

Table.defaultProps = {
  title: null,
};

Table.propTypes = {
  columns: number.isRequired,
  title: string,
  items: arrayOf(arrayOf(object)).isRequired,
};

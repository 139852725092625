import React from 'react';
import Lottie from 'react-lottie';

import { oneOf } from 'prop-types';

import Animate from '~/assets/animations/loader.json';

import View from './styles';

const lottie = {
  loop: true,
  autoplay: true,
  animationData: Animate,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid',
  },
};

const SimpleLoading = ({ size, ...rest }) => (
  <View {...rest}>
    <Lottie
      options={lottie}
      height={size === 'lg' ? '20.0rem' : '12.0rem'}
    />
  </View>
);

SimpleLoading.propTypes = {
  size: oneOf(['lg', 'md']),
};

SimpleLoading.defaultProps = {
  size: 'lg',
};

export default SimpleLoading;
